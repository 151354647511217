import React, { CSSProperties, ReactNode } from 'react';
import { memoize } from 'proxy-memoize';

import { FieldLabel } from '../Label';
import {
  ToggleGroupContext,
  ToggleGroupContextValue,
} from './components/ToggleGroupContext';
import { ToggleMultiGroup } from './components/ToggleMultiGroup';
import { ToggleSingleGroup } from './components/ToggleSingleGroup';
import { ToggleButton } from './ToggleButton';
import {
  ToggleAppearance,
  ToggleColor,
  ToggleSize,
  ToggleValue,
} from './types';

import * as styles from './styles.css';

export type ToggleSingleGroupProps<V extends ToggleValue> = {
  type?: 'single' | undefined;
  value?: V;
  defaultValue?: V;
  disableUnselect?: boolean;
  onChange?: (value: V | undefined) => void;
};

export type ToggleMultiGroupProps<V extends ToggleValue> = {
  type: 'multiple';
  value?: V[];
  defaultValue?: V[];
  onChange?: (value: V[]) => void;
};

export type ToggleGroupOption<V extends ToggleValue> = {
  value: V;
  subLabel?: ReactNode;
  label: ReactNode;
  disabled?: boolean;
};

export type ToggleGroupBaseProps<V extends ToggleValue> = {
  appearance?: ToggleAppearance;
  children?: ReactNode;
  className?: string;
  color?: ToggleColor;
  disabled?: boolean;
  hideIfReadOnlyAndNotChecked?: boolean;
  label?: ReactNode;
  options?: ToggleGroupOption<V>[];
  optionStyle?: CSSProperties;
  readOnly?: boolean;
  size?: ToggleSize;
  style?: CSSProperties;
  'aria-labelledby'?: string;
};

export type ToggleGroupProps<V extends ToggleValue> = ToggleGroupBaseProps<V> &
  (ToggleSingleGroupProps<V> | ToggleMultiGroupProps<V>);

const mapPropsToContextValue = memoize(
  <V extends ToggleValue>(
    props: ToggleGroupProps<V>,
  ): ToggleGroupContextValue => {
    const { color = 'default', size = 'medium', appearance = 'pill' } = props;
    const multi = props.type === 'multiple';

    return {
      appearance,
      color,
      disableUnselect: Boolean(!multi && props.disableUnselect),
      hideIfReadOnlyAndNotChecked: Boolean(props.hideIfReadOnlyAndNotChecked),
      readOnly: Boolean(props.readOnly),
      size,
    };
  },
);

export function ToggleGroup<V extends ToggleValue>(props: ToggleGroupProps<V>) {
  const ctx = mapPropsToContextValue(props);

  const children =
    props.children ||
    props.options?.map((o) => (
      <ToggleButton
        key={JSON.stringify(o.value)}
        value={o.value}
        subLabel={o.subLabel}
        style={props.optionStyle}
        disabled={o.disabled}
      >
        {o.label}
      </ToggleButton>
    ));

  return (
    <ToggleGroupContext.Provider value={ctx}>
      <div className={props.className} style={props.style}>
        {props.label && (
          <FieldLabel style={{ marginBottom: 11 }}>{props.label}</FieldLabel>
        )}
        {props.type === 'multiple' ? (
          <ToggleMultiGroup<V>
            defaultValue={props.defaultValue}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            readOnly={props.readOnly}
            aria-labelledby={props['aria-labelledby']}
            className={styles.toggleGroup({
              gap: props.size === 'tiny' ? 'tiny' : 'default',
            })}
          >
            {children}
          </ToggleMultiGroup>
        ) : (
          <ToggleSingleGroup<V>
            defaultValue={props.defaultValue}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            readOnly={props.readOnly}
            disableUnselect={props.disableUnselect}
            aria-labelledby={props['aria-labelledby']}
            className={styles.toggleGroup({
              gap: props.size === 'tiny' ? 'tiny' : 'default',
            })}
          >
            {children}
          </ToggleSingleGroup>
        )}
      </div>
    </ToggleGroupContext.Provider>
  );
}
