import { t } from '@lingui/macro';
import { get, isEmpty } from 'lodash';

import {
  ADD_ROLE,
  ADD_ROLE_FAILURE,
  ADD_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,
  FETCH_ROLES,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_SUCCESS,
  ROLES_BULK_UPDATED,
  SORT_ROLES,
  UPDATE_ROLE,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
} from '@float/constants/roles';
import { RawRole, Role, RolesAction } from '@float/types';
import { CostRateHistoryPayload } from '@float/types/costRate';

import api from '../api3';
import { RoleApiPayload } from '../api3/roles';
import { trackEvent } from '../lib/gtm';
import { ReduxState, ReduxStateStrict } from '../reducers/lib/types';
import { getRoles } from '../selectors/roles';
import { AppDispatch, AppDispatchStrict } from '../store';
import { trackCostRateChanges } from './lib/trackCostRateChanges';
import { trackRateChanges } from './lib/trackRateChanges';

export {
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  SORT_ROLES,
  ROLES_BULK_UPDATED,
};

export const ensureRolesLoaded =
  () =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { loadState: currentLoadState } = getState().roles;

    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: FETCH_ROLES });

      const roles = await api.getRoles();

      dispatch({ type: FETCH_ROLES_SUCCESS, entities: roles });

      return roles;
    } catch (e) {
      dispatch({ type: FETCH_ROLES_FAILURE });
    }
  };

export type AddRoleOptions = {
  from?: 'roles-management' | 'people-management-bulk' | 'people-management';
  trackEvent?: boolean;
};

export const addRole = (payload: RoleApiPayload, options?: AddRoleOptions) => {
  return async (dispatch: AppDispatchStrict) => {
    const response = await api.createRole({ data: payload });

    dispatch(addRoleSuccess(response, options));

    return response;
  };
};

export const addRoleSuccess = (
  payload: RawRole,
  options?: AddRoleOptions,
): RolesAction => {
  if (options?.trackEvent) {
    trackEvent('Role added', {
      name: payload.name,
      from: options?.from,
    });
  }

  return {
    type: ADD_ROLE_SUCCESS,
    payload,
  };
};

type UpdateRoleOptions = {
  from?: string;
  trackEvent?: boolean;
};

export const updateRole = (
  id: Role['id'],
  payload: RoleApiPayload,
  options?: UpdateRoleOptions,
) => {
  return async (dispatch: AppDispatchStrict) => {
    const response = await api.updateRole({ id, data: payload });

    dispatch(updateRoleSuccess(response, options, payload));

    return response;
  };
};

export const updateRoleSuccess = (
  role: RawRole,
  options?: UpdateRoleOptions,
  payload?: RoleApiPayload,
) => {
  return (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    if (options?.trackEvent) {
      trackEvent('Role updated', {
        name: role.name,
        role_id: role.id,
        modified_by: role.modified_by,
      });

      const from_rate =
        getState().roles.roles[role.id]?.default_hourly_rate || null;

      const to_rate = role.default_hourly_rate || null;

      trackRateChanges({
        from_rate,
        to_rate,
        scope: 'role',
        page: 'roles management',
        role_id: role.id,
      });

      if (payload?.cost_rate_history) {
        trackCostRateChanges({
          costRateHistory: payload.cost_rate_history,
          scope: 'role',
        });
      }
    }

    return dispatch({
      type: UPDATE_ROLE_SUCCESS,
      payload: role,
    });
  };
};

type DeleteRoleOptions = {
  trackEvent?: boolean;
};

export const deleteRole = (id: Role['id'], options?: DeleteRoleOptions) => {
  return async (dispatch: AppDispatchStrict) => {
    await api.deleteRole({ id });

    dispatch(deleteRoleSuccess(id));
  };
};

export const deleteRoleSuccess = (
  id: Role['id'],
  options?: DeleteRoleOptions,
) => {
  return (dispatch: AppDispatchStrict) => {
    if (options?.trackEvent) {
      trackEvent('Role deleted', {
        id,
      });
    }

    return dispatch({
      type: DELETE_ROLE_SUCCESS,
      id,
    });
  };
};

export const assignRoleToJobTitleWithDeps =
  (addRoleFunc: (payload: { name: string; from: string }) => unknown) =>
  (person: { role_id?: string | number | null; job_title?: string | null }) =>
  async (dispatch: AppDispatch, getState: () => ReduxState) => {
    const roles = getRoles(getState());
    const roleId = get(person, 'role_id');

    if (typeof roleId === 'number') {
      person.job_title = roles[roleId].name;
    } else if (typeof roleId === 'string') {
      try {
        await dispatch(addRoleFunc({ name: roleId, from: 'people-modal' }));
        person.job_title = roleId;
      } catch {
        throw new Error(`Error: "${roleId}" already exists`);
      }
    } else if (roleId === null) {
      person.job_title = null;
    }
  };

export const assignRoleToJobTitle = assignRoleToJobTitleWithDeps(addRole);

export const bulkUpdateRoles = (
  ids: Role['id'][],
  fields: {
    default_hourly_rate?: Role['default_hourly_rate'];
    cost_rate_history?: CostRateHistoryPayload;
  },
) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    if (isEmpty(fields)) {
      return Promise.reject({ message: t`No changes made.` });
    }

    try {
      const response = await api.bulkUpdate({ type: 'roles', ids, fields });

      const wasBillRateBulkUpdated = fields.default_hourly_rate !== undefined;

      // Currently, we only support bulk updating role's bill and cost rates.
      // As cost rate is a point-in-time attribute, the "currently applicable"
      // cost rate is retrieved by refetching roles, and not from the bulk
      // update payload. Effectively, this code path only updates the bill rate
      // for roles in the redux store.
      if (wasBillRateBulkUpdated) {
        const roles = getRoles(getState());
        dispatch({
          type: ROLES_BULK_UPDATED,
          roles: ids.map((id) => roles[id]),
          fields: { default_hourly_rate: fields.default_hourly_rate },
        });
      }

      return response;
    } catch (error) {
      return Promise.reject({ message: t`An error occurred.` });
    }
  };
};
