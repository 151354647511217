import jwt_decode from 'jwt-decode';

import { trackEvent } from '@float/common/lib/analytics';
import { FloatStorageKey, storage } from '@float/libs/storage';

function getKey(user: { admin_id: string | number; cid: string | number }) {
  const { admin_id, cid } = user;
  return `${cid || ''}::${admin_id || ''}::interactions`;
}

export async function trackInteraction(
  feature: string,
  user: { admin_id: string | number; cid: string | number },
  { pushToAnalytics = false } = {},
) {
  const key = getKey(user);
  const data = JSON.parse((await storage.UNSAFE_getItem(key)) ?? '[]');

  if (!data.includes(feature)) {
    storage.UNSAFE_setItem(key, JSON.stringify([...data, feature]));

    if (pushToAnalytics) {
      trackEvent(`Used ${feature}`);
    }
  }
}

export async function trackSignedInEvent(accessToken: string) {
  if (!accessToken) return;

  const currentCompanyId = await storage.getItem(
    FloatStorageKey.CurrentCompanyId,
  );

  if (currentCompanyId) {
    trackEvent('Already signed in');
  } else {
    const types = ['other', 'password', 'google', 'saml', 'magic'];
    try {
      const tokenData = jwt_decode<{
        account: {
          login_method: number;
        };
        company: {
          id: string;
        };
      }>(accessToken);

      const typeId = tokenData.account.login_method;

      storage.setItem(FloatStorageKey.CurrentCompanyId, tokenData.company.id);
      trackEvent('Signed in', { type: types[typeId] });
    } catch (_) {
      // Ignore
    }
  }
}
