import React from 'react';
import { t, Trans } from '@lingui/macro';

import {
  AlertBar,
  AlertBarVariantType,
} from '@float/ui/components/AlertBar/AlertBar';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { IconWarningTriangle } from '@float/ui/icons/essentials/IconWarningTriangle';

import { usePersonTypes } from './hooks/usePersonTypes';
import { PersonTypeType } from './types';

import * as styles from './styles.css';

export const PersonType = (props: PersonTypeType) => {
  const {
    initialPerson,
    onPersonTypeChange,
    person,
    personTypeOptions,
    personTypeErrors,
  } = props;

  const { personTypes, showPlaceholderLegacyAlert } = usePersonTypes({
    basePersonTypeOptions: personTypeOptions,
    initialPerson,
    person,
  });

  return (
    <div className={styles.personType}>
      <VirtualSelect
        // @ts-expect-error VirtualSelect does not have types
        clearInputOnDropdownOpen={false}
        data-testid="personType"
        label={t`Type`}
        nonNullable
        onChange={onPersonTypeChange}
        options={personTypes}
        value={person.people_type_id}
        visibleItems={6}
        errors={personTypeErrors}
      />

      {showPlaceholderLegacyAlert && (
        <div className={styles.alertWrapper}>
          <AlertBar
            icon={IconWarningTriangle}
            role="alert"
            type={AlertBarVariantType.Learn}
            message={
              <span>
                <Trans>
                  Legacy Placeholders changed to an Employee or Contractor
                  cannot be changed back to Legacy Placeholder.{' '}
                  <a
                    href="https://support.float.com/en/articles/9214442-legacy-placeholders"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </Trans>
              </span>
            }
          />
        </div>
      )}
    </div>
  );
};
