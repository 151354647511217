import { getCostRatePayload } from '@float/common/actions/lib/getCostRatePayload';
import { isAmountEmpty } from '@float/common/lib/budget';
import { CostRatePayload } from '@float/types/costRate';

import {
  hasRoleBillRateChanged,
  hasRoleCostRateChanged,
  RoleChanges,
} from './getRoleChanges';

export type RoleSubmitPayload = CostRatePayload & {
  name: string;
  default_hourly_rate?: string | null;
};

function getRateValueForPayload(rate?: string | null): string | null {
  if (rate === undefined) return null;

  return isAmountEmpty(rate) ? null : rate;
}

export function getRoleSubmitPayload(changes: RoleChanges): RoleSubmitPayload {
  const payload: RoleSubmitPayload = { name: changes.roleName };
  if (hasRoleBillRateChanged(changes)) {
    payload.default_hourly_rate = getRateValueForPayload(changes.billRateTo);
  }

  let costRatePayload: CostRatePayload = {};
  if (hasRoleCostRateChanged(changes)) {
    costRatePayload = getCostRatePayload({
      cost_rate: getRateValueForPayload(changes.costRateTo),
      cost_rate_effective_date: changes.costRateEffectiveDate,
    });
  }

  return { ...payload, ...costRatePayload };
}
