import React from 'react';
import { t } from '@lingui/macro';
import { isNil } from 'es-toolkit';

import { RoleRates } from '@float/types';

import { PersonRoleRate } from '../PersonRoleRate/PersonRoleRate';

import * as styles from './PersonRoleRates.styles.css';

export const PersonRoleRates = (props: RoleRates) => {
  const { billRate, costRate } = props;
  const hasCostRateAccess = costRate !== undefined;
  const hasBillRateAccess = billRate !== undefined;

  if (isNil(costRate) && isNil(billRate)) return null;

  return (
    <div className={styles.wrapper}>
      {hasCostRateAccess && (
        <PersonRoleRate name={t`Cost rate`} hourlyRate={costRate ?? '0'} />
      )}

      {hasBillRateAccess && (
        <PersonRoleRate name={t`Bill rate`} hourlyRate={billRate ?? '0'} />
      )}
    </div>
  );
};
