import React, { forwardRef } from 'react';

import { AvatarImage } from '@float/common/components/Avatar';
import { AVATAR_SIZES } from '@float/common/components/Avatar/styles';
import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { map } from '@float/common/lib/math';
import { isNewPlaceholderType } from '@float/common/lib/people/isNewPlaceholderType';
import { Person } from '@float/types';

import {
  StyledAvatarGroup,
  StyledContainer,
  StyledHit,
  StyledMore,
} from './styles';

// @todo: convert in a more generic component e.g. StackGroup
// that stacks children up to a max number

type AvatarGroupProps = {
  className?: string;
  people?: {
    personId?: number;
    accountId?: number;
    name?: string;
    avatar?: string | null;
    onClick?: () => void;
  }[];
  size?: 'xs' | 'sm' | 'mobile' | 'md' | 'lg' | 'xl';
  max?: number;
  stackPercent?: number;
  avatarImage?: boolean;
};

const AvatarGroup = forwardRef<HTMLDivElement, AvatarGroupProps>(
  (props, ref) => {
    const {
      className,
      people = [],
      size = 'xs',
      max = 999,
      stackPercent = 0.7,
      avatarImage = false,
    } = props;

    const { width } = AVATAR_SIZES[size];
    const more = people.length - max;
    const withTranslateOnHover = people.length > 1;

    return (
      <StyledAvatarGroup className={className} ref={ref}>
        {people.map((person, index) => {
          if (index < max) {
            const id =
              person.personId || person.accountId || `${person.name}_${index}`;
            const percent = map(index, 0, max - 1, stackPercent, 0.4);

            const dims = index < people.length - 1 ? width * percent : width;

            const isPersonNewPlaceholderType = isNewPlaceholderType({
              person: person as Person,
            });

            return (
              <StyledContainer
                key={id}
                style={{
                  width: dims,
                  height: dims,
                }}
              >
                <StyledHit
                  style={{ width, height: width }}
                  data-index={index}
                  onClick={person.onClick}
                  $withTranslateOnHover={withTranslateOnHover}
                >
                  {!avatarImage && (
                    <PersonAvatar {...person} size={size} readOnly />
                  )}
                  {avatarImage && (
                    <AvatarImage
                      name={person.name}
                      size={size}
                      imageUrl={person.avatar}
                      isNewPlaceholderType={isPersonNewPlaceholderType}
                    />
                  )}
                </StyledHit>
              </StyledContainer>
            );
          }

          return null;
        })}

        {more > 0 && (
          <StyledMore style={{ width: width, height: width }}>
            +{more}
          </StyledMore>
        )}
      </StyledAvatarGroup>
    );
  },
);

export default AvatarGroup;
