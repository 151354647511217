import React from 'react';

import { useAppSelector } from '@float/common/store';
import { Tag } from '@float/ui/components/Tag';
import { getOnboardingSurveyConfig } from '@float/web/store/onboardingManager/selectors/survey';
import { OnboardingSurveyStatus } from '@float/web/store/onboardingManager/types';

import * as styles from './Steps.css';

export const Steps = ({
  navigation,
}: Omit<OnboardingSurveyStatus, 'completed'>) => {
  const onboardingSurveyConfig = useAppSelector(getOnboardingSurveyConfig);
  const onboardingSteps = Object.entries(onboardingSurveyConfig)
    .filter(([_, config]) => !config?.excludeFromNavigation)
    .map(([step]) => step);

  const currentStep = navigation.page + 1;
  const totalSteps = onboardingSteps.length;

  if (currentStep > totalSteps) {
    return null;
  }

  return (
    <div className={styles.stepsContainer}>
      <h4>
        {currentStep}/{totalSteps}
      </h4>
      <Tag>{onboardingSteps[navigation.page]}</Tag>
    </div>
  );
};

Steps.displayName = 'Steps';
