import { Role } from '@float/types';

import { roleTableRowDisplayConfig as view } from './roleTableRowDisplayConfig';
import { roleTableRowEditConfig as edit } from './roleTableRowEditConfig';
import { sanitizeRate } from './roleTableRowUtils';

export function getRoleTableRowWithCostRate(role: Role) {
  const data = [
    role.name,
    sanitizeRate(role.cost_rate),
    sanitizeRate(role.default_hourly_rate),
    role.people_count,
  ];

  const display = [
    view.roleName(role),
    view.costRate(role),
    view.billRate(role),
    view.peopleCount(role),
  ];

  const editConfig = [
    edit.roleName(),
    edit.costRate(),
    edit.billRate(),
    edit.peopleCount(role),
  ];

  return { data, display, editConfig };
}
