import React, { useRef } from 'react';

import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { ScheduleViewType } from '@float/constants/schedule';
import {
  LoggedTimeCell,
  Person,
  PersonCell,
  Project,
  ProjectCell,
} from '@float/types';

import { areEqualExtractStyle } from '../../util/diff';
import PersonCard from './PersonCard';
import ProjectCard from './ProjectCard';
import ProjectPersonCard from './ProjectPersonCard';
import { SingleProjectHeaders } from './SingleProject/SingleProjectHeaders';
import { SingleProjectPersonCard } from './SingleProject/SingleProjectPersonCard';
import { usePrintStylesOverride } from './usePrintStylesOverride';

import * as styles from './styles.css';

export type SideCellProps = {
  key?: string;
  row: PersonRow | ProjectRow | PersonProjectRow;
  style?: React.CSSProperties;
  hourHeight: number;
  rowHeight: number;
  rowGroupTop: number;
  currentWeekCell: PersonCell | LoggedTimeCell | ProjectCell;
  dayOfWeek: number;
  logTimeView: boolean;
  isTimer?: boolean;
  actions: {
    showAddTaskModal: (person: Person) => void;
    showPersonModal: (person: Person) => void;
    removeFromProjects: (person: Person, projects: Project[]) => void;
    showSidebarMenu: (
      position: React.MutableRefObject<unknown>,
      options: unknown,
    ) => void;
    setDragItem: (...args: unknown[]) => void;
    showSingleProjectView: (projectId: Project['project_id']) => void;
    showSwitchPersonModal: (...args: unknown[]) => void;
    showProjectModal: (...args: unknown[]) => void;
    showProjectShiftModal: (...args: unknown[]) => void;
    showProjectDuplicateModal: (...args: unknown[]) => void;
    showEditPhaseModal: (...args: unknown[]) => void;
    updateProject: (...args: unknown[]) => void;
    toggleCollapsedProject: (...args: unknown[]) => void;
    resetProjectPeopleSort: (...args: unknown[]) => void;
  };
  timeRangeSettings?: { insightsPreferredUnit?: string };
  viewType: ScheduleViewType;
};

function SideCell({
  row,
  style,
  hourHeight,
  rowHeight,
  rowGroupTop,
  actions,
  currentWeekCell,
  dayOfWeek,
  logTimeView,
  timeRangeSettings,
  isTimer,
  viewType,
}: SideCellProps) {
  const isSingleProjectView = viewType === ScheduleViewType.SingleProject;
  let Inner = null;

  if (row.type === 'person') {
    if ('projectId' in row) {
      Inner = isSingleProjectView ? (
        <SingleProjectPersonCard
          actions={actions}
          person={row.data}
          currentWeekCell={currentWeekCell}
          dayOfWeek={dayOfWeek}
          projectId={row.projectId}
        />
      ) : (
        <ProjectPersonCard
          row={row}
          rowGroupTop={rowGroupTop}
          rowHeight={rowHeight}
          person={row.data}
          actions={actions}
          currentWeekCell={currentWeekCell}
          dayOfWeek={dayOfWeek}
          logTimeView={logTimeView}
          timeRangeSettings={timeRangeSettings}
          isTimer={isTimer}
        />
      );
    } else {
      Inner = (
        <PersonCard
          person={row.data}
          actions={actions}
          row={row}
          rowGroupTop={rowGroupTop}
          rowHeight={rowHeight}
          currentWeekCell={currentWeekCell}
          dayOfWeek={dayOfWeek}
          logTimeView={logTimeView}
          timeRangeSettings={timeRangeSettings}
        />
      );
    }
  }

  if (row.type === 'project') {
    Inner = isSingleProjectView ? (
      <SingleProjectHeaders project={row.data} rowHeight={rowHeight} />
    ) : (
      <ProjectCard
        row={row}
        project={row.data}
        actions={actions}
        hourHeight={hourHeight}
        rowHeight={rowHeight}
        logTimeView={logTimeView}
      />
    );
  }

  const ref = useRef<HTMLDivElement>(null);

  usePrintStylesOverride(
    ref,
    (el) => {
      // We want the left position relative to the schedule scroll wrapper element
      // which is positioned after the left sidebar
      const leftSidebarSize = 68;
      el.style.position = 'absolute';

      const x = el.getBoundingClientRect().x;

      el.style.transform = `translateX(${x * -1 + leftSidebarSize}px)`;
    },
    (el) => {
      el.style.position = '';
      el.style.transform = '';
    },
  );

  return (
    <div className={styles.sideCellWrapper} ref={ref} style={style}>
      {Inner}
    </div>
  );
}

export default React.memo(SideCell, areEqualExtractStyle);
