import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Project } from '@float/types/project';
import { useApplyProjectPlanFilter } from '@float/web/components/panelViews/Project/hooks/useApplyProjectPlanFilter';
import { ProjectPlanViewSource } from '@float/web/lib/tracking/trackProjectPlanView';

import { useOpenSingleProjectView } from './useOpenSingleProjectView';

export const useProjectPlanAction = () => {
  const applyProjectPlanFilter = useApplyProjectPlanFilter();
  const openSingleProjectView = useOpenSingleProjectView();

  const openProjectPlanAction = (
    projectName: string,
    projectId: Project['project_id'],
  ) => {
    const isSPVEnabled = featureFlags.isFeatureEnabled(
      FeatureFlag.SingleProjectView,
    );
    if (isSPVEnabled) {
      openSingleProjectView(projectId);
    } else {
      applyProjectPlanFilter(projectName, ProjectPlanViewSource.Snackbar);
    }
  };

  return openProjectPlanAction;
};
