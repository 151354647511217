import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import type { Timeoff } from '@float/types';

import { getPeopleMap } from './people';
import { getTimeoffTypesMap } from './timeoffTypes';
import type { TimeoffsState } from '../reducers/timeoffs';

export const getTimeoffsMapRaw = (state: { timeoffs: TimeoffsState }) =>
  state.timeoffs.timeoffs;

// OPTIMIZATION: Object.values might become slow for big inputs.
export const getTimeoffsListRaw = createSelector(
  [(state: { timeoffs: TimeoffsState }) => state.timeoffs.timeoffs],
  (timeoffs) => Object.values(timeoffs),
);

// OPTIMIZATION: By removing the search timeoffs from the task list the app logic
// will handle only the timeoffs related to the current range
export const getFullTimeoffsList = createSelector(
  [getTimeoffsListRaw],
  (timeoffs) =>
    timeoffs.filter((timeoff) => timeoff.data_type !== 'search') as Timeoff[],
);

export const getFullTimeoffsMap = createSelector(
  [getFullTimeoffsList],
  (timeoffs) => {
    const result: Record<number, Timeoff> = Object.create(null);

    for (const timeoff of timeoffs) {
      result[timeoff.timeoff_id] = timeoff;
    }

    return result;
  },
);

export const getTimeoffs = createSelector(
  [getFullTimeoffsList, getPeopleMap, getTimeoffTypesMap],
  (timeoffs, allPeopleMap, timeoffTypesMap) =>
    timeoffs.map((timeoff) => {
      const peopleItems = timeoff.people_ids.map((id) => allPeopleMap[id]);
      return {
        ...timeoff,
        isTimeoff: true,
        people: peopleItems[0],
        peopleItems, // for exim data structure compatibility
        timeoffType: {
          ...timeoffTypesMap[timeoff.timeoff_type_id],
          oneOffDays: [], // todo: question - should this be derived?
        },
      };
    }),
);

export const getTimeoffsMap = createSelector([getTimeoffs], (timeoffs) =>
  keyBy(timeoffs, 'timeoff_id'),
);
