import { Media } from './media.class';

export * from './media.hooks';
export type * from './media.types';

/**
 * Implementation agnostic singleton used for screen size and viewport size.
 * Must be initialized with a mediaClientAdapter before being used.
 */
export const media = new Media();
