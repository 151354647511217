import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

import type { ReportTotals } from '../../types';

// These functions needs to be updated once we implement the profit margins in multi project report
// https://linear.app/float-com/issue/COR-434/profit-margin-updates-for-multi-project-reports-view

export const getScheduledProfitAndCostTotals = (totals: ReportTotals) => {
  const profit = totals.feeCents - totals.costCents;
  const percentage = profit / (totals.feeCents || 1);
  return {
    profit,
    percentage,
  };
};

export const getLoggedProfitAndCostTotals = (totals: ReportTotals) => {
  const fee = totals.logged.feeCents;
  const cost = totals.logged.costCents;
  const profit = fee - cost;
  const percentage = profit / (fee || 1);
  return {
    percentage,
    profit,
  };
};

export const getCombinedProfitAndCostTotals = (totals: ReportTotals) => {
  const profit =
    totals.feeCents +
    totals.logged.feeCents -
    totals.costCents -
    totals.logged.costCents;
  const percentage = profit / (totals.feeCents + totals.logged.feeCents || 1);
  return { profit, percentage };
};

export const getProfitAndCostTotals = (
  totals: ReportTotals,
  mode: ChartComparisonModes,
) => {
  if (mode === ChartComparisonModes.COMPARE) {
    return {
      profit: 0,
      percentage: 0,
    };
  }

  if (mode === ChartComparisonModes.SCHEDULED) {
    return getScheduledProfitAndCostTotals(totals);
  }

  if (mode === ChartComparisonModes.COMBINED) {
    return getCombinedProfitAndCostTotals(totals);
  }

  return getLoggedProfitAndCostTotals(totals);
};
