import React from 'react';

import { SingleProjectSortColumn } from '@float/types/account';
import { IconChevronDown } from '@float/ui/icons/essentials/IconChevronDown';
import { IconChevronUp } from '@float/ui/icons/essentials/IconChevronUp';

import { useUpdateColumnSort } from './HeaderColumn.hooks';

const SortIcon = ({ sortDir }: { sortDir?: 'asc' | 'desc' }) => {
  const IconChevron = sortDir === 'asc' ? IconChevronDown : IconChevronUp;
  return (
    <IconChevron
      data-testid={`single-project-sort-icon-${sortDir}`}
      size={20}
    />
  );
};

export const HeaderColumn = ({
  children,
  className,
  column,
}: {
  children: React.ReactNode;
  className?: string;
  column: SingleProjectSortColumn;
}) => {
  const { updateColumnSort, sortBy, sortDir } = useUpdateColumnSort();

  return (
    <div className={className} onClick={() => updateColumnSort(column)}>
      {children}
      {sortBy === column && <SortIcon sortDir={sortDir} />}
    </div>
  );
};
