import { useFormContext } from 'react-hook-form';

import { PanelType } from '@float/web/sidePanel/types';
import { useSidePanel } from '@float/web/sidePanel/useSidePanel';

import { useIsTemplate } from '../../../hooks/useIsTemplate';
import { ProjectFormData } from '../../../types';
import { getUnsavedProjectFieldsForPhasePanel } from '../ProjectPhasesSection.helpers';
import { usePhaseDetailUpdateHandler } from './usePhaseDetailUpdateHandler';

export const useShowPhasePanel = (index: number) => {
  const isTemplate = useIsTemplate();
  const { addPanel } = useSidePanel(PanelType.ProjectPanel);
  const { formState, getValues, trigger } = useFormContext<ProjectFormData>();
  const handlePhaseDetailUpdate = usePhaseDetailUpdateHandler(index);

  const showPhasePanel = async () => {
    const projectPhaseRecord = getValues(`phases.${index}`);
    const projectId = getValues('projectId') ?? 0;
    const isEditingProject = Boolean(projectId);
    const phaseId = isTemplate ? 0 : projectPhaseRecord.phase_id;

    // When editing a project, if the project form is invalid, disallow user
    // from navigating to the phase form. This is because when they try to
    // persist the phase form, and if we need to PATCH a few project attributes
    // prior to that (e.g. if budget type is changed), then the project form
    // needs to be valid for the project PATCH to succeed. One scenario of this
    // failing is if the project team contains a user without an hourly rate.
    const isFormInvalid = isEditingProject && (await trigger()) === false;
    if (isFormInvalid) {
      return;
    }

    const unsavedProject = getUnsavedProjectFieldsForPhasePanel({
      projectId,
      isTemplate,
      currentValues: getValues(),
      defaultValues: formState.defaultValues as ProjectFormData,
    });

    addPanel({
      panelType: PanelType.PhasePanel,
      panelPayload: {
        isTemplate,
        phaseId,
        projectId,
        projectPhaseRecord,
        unsavedProject,
        afterSave: handlePhaseDetailUpdate,
      },
    });
  };

  return showPhasePanel;
};
