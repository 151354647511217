import {
  LEGACY_FILTERS_THAT_SUPPORT_NONE_VALUE,
  LegacyFilterSpecialValue,
  SearchResolveFilterSpecialValue,
} from '@float/common/api3/search.constants';
import {
  FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING,
  VIRTUAL_FILTER_CATEGORIES_EXCLUDING_SAVED_SEARCH,
} from '@float/constants/search';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import type {
  BaseFilterToken,
  LegacyFilterType,
  VirtualFilterCategoryExcludingSavedSearch,
  VirtualFilterTypes,
} from '@float/types/view';

import { LEGACY_FILTER_TYPES } from './_index';
import { LEGACY_FILTER_TYPE_TO_VIRTUAL_FILTER_TYPE_MAPPING } from './constants';
import { getValueAndOpFromEncodedStr } from './getValueAndOpFromStr';

export {
  getValueAndOpFromEncodedStr,
  getValueAndOpFromStr,
} from './getValueAndOpFromStr';

export * from './_index';

/**
 * @deprecated Use FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING from `search/helpers/constants.ts` instead
 */
export const TYPE_TO_CATEGORY = FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING;

/**
 * @deprecated Use VIRTUAL_FILTER_CATEGORIES_EXCLUDING_SAVED_SEARCH from `search/helpers/constants.ts` instead
 */
export const TYPES = VIRTUAL_FILTER_CATEGORIES_EXCLUDING_SAVED_SEARCH;

const getIsLegacyFilterType = (key: string): key is LegacyFilterType =>
  LEGACY_FILTER_TYPES.includes(key as LegacyFilterType);

const getIsVirtualFilterCategoryExcludingSavedSearch = (
  key: string,
): key is VirtualFilterCategoryExcludingSavedSearch =>
  VIRTUAL_FILTER_CATEGORIES_EXCLUDING_SAVED_SEARCH.includes(
    key as VirtualFilterCategoryExcludingSavedSearch,
  );

/**
 * @test-export
 */
export function sanitizeQueryStringFilterValues(
  values: string | string[],
  type: VirtualFilterTypes | LegacyFilterType,
  isSBLEnabled: boolean,
) {
  let sanitizedValues = values.length === 1 ? values[0] : values;

  // NOTE: Even when SBL is permanently enabled, we'll still need to
  // keep this logic so that old links that use the `None` filter value
  // dont' break.
  if (
    isSBLEnabled &&
    LEGACY_FILTERS_THAT_SUPPORT_NONE_VALUE.includes(type) &&
    sanitizedValues === LegacyFilterSpecialValue.None
  ) {
    sanitizedValues = SearchResolveFilterSpecialValue.None;
  }

  return sanitizedValues;
}

export function getFiltersFromQueryString(qs: string | undefined) {
  const filters: BaseFilterToken[] = [];

  if (!qs?.length) return filters;

  const parts = (qs.startsWith('?') ? qs.slice(1) : qs).split('&');

  let i = 0;

  parts.forEach((part) => {
    const pair = part.split('=');
    if (pair.length < 2) return;
    const [key, rawVal] = pair;

    let type: VirtualFilterTypes | undefined;

    const isVirtualFilterCategoryExcludingSavedSearch =
      getIsVirtualFilterCategoryExcludingSavedSearch(key);
    const isLegacyFilterType = getIsLegacyFilterType(key);

    if (isLegacyFilterType) {
      type = LEGACY_FILTER_TYPE_TO_VIRTUAL_FILTER_TYPE_MAPPING[key];
    } else if (isVirtualFilterCategoryExcludingSavedSearch) {
      type = key;
    }

    if (!type) return;

    const isSBLEnabled = featureFlags.isFeatureEnabled(
      FeatureFlag.SearchBeyondLimits,
    );

    const { val: valStr, operator } = getValueAndOpFromEncodedStr(rawVal);

    const values = valStr.split(',').map(decodeURIComponent);

    const sanitizedValues = sanitizeQueryStringFilterValues(
      values,
      type,
      isSBLEnabled,
    );

    filters.push({
      type,
      val: sanitizedValues,
      operator,
      key: `${i++}-${type}-${values.join(',')}`,
    });
  });

  return filters;
}
