import React from 'react';
import { isNil } from 'es-toolkit';

import { RateText } from '@float/common/components/Rate';
import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import { isCostRateFeatureActiveForUser } from '@float/common/lib/rates/rates';
import { useAppSelectorStrict } from '@float/common/store';
import { getUser } from '@float/web/selectors';
import type { CurrencyProps, RoleOption } from '@float/types';
import type { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';

import { PersonRoleRates } from '../components/PersonRoleRates/PersonRoleRates';

export type UseRoleOptionsWithRateElements = {
  rolesOptions: RoleOption[];
  currencyConfig: CurrencyProps;
};
export const useRoleOptionsWithRateElements = (
  props: UseRoleOptionsWithRateElements,
) => {
  const { rolesOptions } = props;
  const currentUser = useAppSelectorStrict(getUser);
  const canSeeBillRate = getCanCurrentUserSeeBudgets(currentUser);
  const canSeeCostRate = isCostRateFeatureActiveForUser(currentUser);

  return rolesOptions?.map((role): VirtualSelectOption => {
    const option: VirtualSelectOption = {
      label: role.label,
      value: role.value,
    };

    // If user can see both rates, show them as children
    if (canSeeBillRate && canSeeCostRate) {
      const areRatesEmpty = isNil(role.costRate) && isNil(role.billRate);

      option.children = areRatesEmpty ? undefined : (
        <PersonRoleRates billRate={role.billRate} costRate={role.costRate} />
      );

      return option;
    }

    // If user can see only bill rate, show that as iconRight
    if (canSeeBillRate) {
      option.iconRight = <RateText rate={role.billRate} />;
    }

    // If user can see only cost rate, show that as iconRight
    if (canSeeCostRate) {
      option.iconRight = <RateText rate={role.costRate} />;
    }

    return option;
  });
};
