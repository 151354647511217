import { keyBy, omitBy } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { Department, RawDepartment } from '@float/types';

import {
  ADD_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  DEPARTMENT_LOAD_FAILED,
  DEPARTMENT_LOAD_FINISH,
  DEPARTMENT_LOAD_START,
  DEPARTMENTS_BULK_UPDATED,
  UPDATE_DEPARTMENT_SUCCESS,
} from '../actions';
import { LoadState, RehydratePartialStateAction } from './lib/types';

export type DepartmentsState = {
  departments: Record<number, Department>;
  loadState: LoadState;
  departmentsLoaded: boolean;
};

const REDUCER_NAME = 'departments';
const DEFAULT_STATE: DepartmentsState = {
  departments: {},
  loadState: LoadState.UNLOADED,
  departmentsLoaded: false,
};

export type DepartmentsAction =
  | {
      type: typeof DEPARTMENT_LOAD_START;
    }
  | {
      type: typeof DEPARTMENT_LOAD_FAILED;
    }
  | {
      type: typeof DEPARTMENT_LOAD_FINISH;
      departments: RawDepartment[];
    }
  | {
      type: typeof UPDATE_DEPARTMENT_SUCCESS;
      payload: {
        department_id: number;
        name: string;
        parent_id?: number;
      };
    }
  | {
      type: typeof ADD_DEPARTMENT_SUCCESS;
      payload: {
        department_id: number;
        name: string;
        parent_id?: number;
      };
    }
  | {
      type: typeof DELETE_DEPARTMENT_SUCCESS;
      payload: {
        department_id: number;
      };
    }
  | {
      type: typeof DELETE_DEPARTMENT_SUCCESS;
      id: number;
    }
  | {
      type: typeof DEPARTMENTS_BULK_UPDATED;
      result?: {
        department_id: string;
        department_name: string;
      }[];
    };

const departments = (
  state = DEFAULT_STATE,
  action?:
    | DepartmentsAction
    | RehydratePartialStateAction<DepartmentsState, typeof REDUCER_NAME>,
): DepartmentsState => {
  if (!action) return state;

  switch (action.type) {
    case DEPARTMENT_LOAD_START: {
      return {
        ...state,
        loadState: LoadState.LOADING,
      };
    }

    case DEPARTMENT_LOAD_FAILED: {
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
      };
    }

    case DEPARTMENT_LOAD_FINISH: {
      const { departments = [] } = action;

      return {
        ...state,
        loadState: LoadState.LOADED,
        departmentsLoaded: true,
        departments: keyBy(
          departments.map((department) => ({
            ...department,
            id: department.department_id,
          })),
          'id',
        ),
      };
    }

    case UPDATE_DEPARTMENT_SUCCESS:
    case ADD_DEPARTMENT_SUCCESS: {
      const departments = { ...state.departments };

      const departmentId = action.payload.department_id;
      departments[departmentId] = {
        id: departmentId,
        ...action.payload,
      };

      return {
        ...state,
        departments,
      };
    }

    case DELETE_DEPARTMENT_SUCCESS: {
      let id: number | undefined;

      if ('payload' in action) {
        id = action.payload.department_id;
      } else if ('id' in action) {
        id = action.id;
      }

      if (id !== undefined) {
        return {
          ...state,
          departments: omitBy(state.departments, (department) => {
            return department.id === id || department.parent_id === id;
          }),
        };
      }

      return state;
    }

    case DEPARTMENTS_BULK_UPDATED: {
      const { result } = action;
      if (!result || !result.length) {
        return state;
      }

      const update: DepartmentsState['departments'] = {};

      for (const d of result) {
        const id = +d.department_id;

        update[id] = { id, name: d.department_name };
      }

      return {
        ...state,
        departments: {
          ...state.departments,
          ...update,
        },
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.departmentsLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
};

export default departments;
