import { cloneDeep, isEmpty, min, omit, pull } from 'lodash';

import { updateMultiUserPrefs } from '@float/common/actions';
import { getUser } from '@float/common/selectors/currentUser';
import { AppStore } from '@float/common/store';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
  ScheduleRow,
} from '@float/types/rows';

async function updateProjectPersonCustomPosition({
  store,
  rows,
  row,
  destIdx,
}: {
  store: AppStore;
  rows: (ProjectRow | PersonProjectRow)[];
  row: PersonProjectRow;
  destIdx: number;
}) {
  const { data: person } = row;
  const personId = person.people_id;

  const projectId = row.projectId;

  // Start with a blank slate if the user just triggered custom sorting from a
  // view where they weren't custom sorting.
  const { prefs } = getUser(store.getState());
  const people_order = cloneDeep(prefs.projview_people_order || {});

  const custom_priority = cloneDeep(
    prefs.projview_people_custom_priority || {},
  );

  // Remove the person from the custom priority map if he was present
  if (
    person.projectOrder?.[projectId] != null &&
    custom_priority[projectId]?.[person.order]
  ) {
    pull(custom_priority[projectId][person.order], personId);
    if (isEmpty(custom_priority[projectId][person.order])) {
      delete custom_priority[projectId][person.order];
    }
  }

  // Assign the new order to this person
  const toTop = rows[destIdx - 1].type === 'project';
  const order = toTop
    ? // @ts-expect-error projectOrder is a mutated property that is not typed into ProjectRow
      rows[destIdx + 1].data.projectOrder[projectId] - 1
    : // @ts-expect-error projectOrder is a mutated property that is typed as optional into PersonProjectRow
      rows[destIdx - 1].data.projectOrder[projectId];

  if (!people_order[projectId]) people_order[projectId] = {};
  people_order[projectId][personId] = order;

  if (!custom_priority[projectId]) {
    custom_priority[projectId] = {};
  }

  // Add the person above the target person to custom_priority so that we have
  // a target to place our target below.
  if (!custom_priority[projectId][order]) {
    custom_priority[projectId][order] = [];
    if (!toTop) {
      const abovePerson = rows[destIdx - 1];

      if (abovePerson.type === 'person') {
        custom_priority[projectId][order].push(abovePerson.data.people_id);
      }
    }
    custom_priority[projectId][order].push(personId);
  } else {
    const abovePerson = rows[destIdx - (toTop ? 0 : 1)];

    if (abovePerson.type === 'person') {
      const aboveIndex = custom_priority[projectId][order].indexOf(
        abovePerson.data.people_id,
      );
      custom_priority[projectId][order].splice(aboveIndex + 1, 0, personId);
    }
  }

  await store.dispatch(
    updateMultiUserPrefs({
      projview_people_order: people_order,
      projview_people_custom_priority: custom_priority,
    }),
  );
}

export function resetProjectPeopleSort({ store }: { store: AppStore }) {
  return (projectId: number) => {
    const { prefs } = getUser(store.getState());
    return store.dispatch(
      updateMultiUserPrefs({
        projview_people_order: omit(prefs.projview_people_order, [projectId]),
        projview_people_custom_priority: omit(
          prefs.projview_people_custom_priority,
          [projectId],
        ),
      }),
    );
  };
}

export function updatePersonCustomPosition({
  store,
  getCurrentRows,
}: {
  store: AppStore;
  getCurrentRows: () => ScheduleRow[];
}) {
  return async (rowKey: string, destIdx: number) => {
    const rows = getCurrentRows();
    const row = rows.find(
      (r): r is PersonProjectRow | PersonRow =>
        r.type === 'person' && r.key === rowKey,
    );

    if (!row) {
      return;
    }

    if ('projectId' in row) {
      return await updateProjectPersonCustomPosition({
        store,
        // Rows are either "Project" related or "Person" focused
        // So if one of them has a projectId, we can safely cast the array to that type
        rows: rows as (PersonProjectRow | ProjectRow)[],
        row,
        destIdx,
      });
    }

    const { data: person } = row;
    const id = person.people_id;
    // We can safely cast the array to PersonRow[] because we already determined that we are not in the Project plan view
    const peopleRows = rows as PersonRow[];

    // Start with a blank slate if the user just triggered custom sorting from a
    // view where they weren't custom sorting.
    const { prefs } = getUser(store.getState());
    let people_order: Record<number, number> = {};
    let custom_priority: Record<number, number[]> = {};
    if (prefs.sked_custom_sort) {
      people_order = cloneDeep(prefs.people_order || {});
      custom_priority = cloneDeep(prefs.custom_priority || {});
    }

    // Remove the person from the custom priority map if he was present
    if (person.order != null) {
      pull(custom_priority[person.order], id);
      if (isEmpty(custom_priority[person.order])) {
        delete custom_priority[person.order];
      }
    }

    // Assign the new order to this person
    const toTop = destIdx === 0;
    const order = toTop
      ? min(peopleRows.map((r) => r.data.order))! - 1
      : peopleRows[destIdx - 1].data.order;
    people_order[id] = order;

    // Add the person above the target person to custom_priority so that we have
    // a target to place our target below.
    if (!custom_priority[order]) {
      custom_priority[order] = [];
      if (!toTop) {
        custom_priority[order].push(peopleRows[destIdx - 1].data.people_id);
      }
      custom_priority[order].push(id);
    } else {
      const aboveIndex = custom_priority[order].indexOf(
        peopleRows[destIdx - (toTop ? 0 : 1)].data.people_id,
      );
      custom_priority[order].splice(aboveIndex + 1, 0, id);
    }

    await store.dispatch(
      updateMultiUserPrefs({
        people_order,
        custom_priority,
        custom_sort_order: prefs.sked_sort_dir,
        custom_sort_type: prefs.sked_sort_by,
        sked_custom_sort: 1,
      }),
    );
  };
}
