import { t } from '@lingui/macro';

import { Goals } from './types';

export const getSurveyGoalsConfig = () => {
  return {
    goals: [
      {
        label: t`Resourcing`,
        desc: t`I need to schedule my people against ongoing or net new work`,
        data: Goals.Resourcing,
      },
      {
        label: t`Reporting`,
        desc: t`I need to review my team's utilization and forecast availability`,
        data: Goals.Reporting,
      },
      {
        label: t`Project scoping`,
        desc: t`I need to scenario plan resources for upcoming and potential projects`,
        data: Goals.ProjectScoping,
      },
      {
        label: t`Time tracking`,
        desc: t`I need to log time against a client or project to track my hours`,
        data: Goals.TimeTracking,
      },
    ],
  };
};
