import React from 'react';
import { t } from '@lingui/macro';

import { StepBase } from '../../StepBase';
import {
  StyledImage,
  StyledParagraph,
  StyledTitle,
} from '../../StepBase/styles';

import scheduleDesktopImageSource from './images/ScheduleDesktop.png';
import scheduleDesktopNoTTImageSource from './images/ScheduleDesktopNoTT.png';
import scheduleMobileImageSource from './images/ScheduleMobile.png';
import scheduleMobileNoTTImageSource from './images/ScheduleMobileNoTT.png';

function getDescription(hasTimeTracking) {
  return hasTimeTracking
    ? t`Change your view from people, to the time logged on tasks.`
    : t`Schedule work based on your team’s capacity.`;
}

function getImageSource(hasTimeTracking, isMobile) {
  if (isMobile) {
    return hasTimeTracking
      ? scheduleMobileImageSource
      : scheduleMobileNoTTImageSource;
  }

  return hasTimeTracking
    ? scheduleDesktopImageSource
    : scheduleDesktopNoTTImageSource;
}

const ScheduleContent = (props) => {
  const { anchor, userSpecs } = props;

  const imageSource = getImageSource(
    userSpecs.hasTimeTracking,
    anchor.id === 'schedule-mobile',
  );

  return (
    <>
      <StyledImage>
        <img src={imageSource} width="100%" height="100%" alt="Schedule" />
      </StyledImage>
      <StyledTitle>Set your focus</StyledTitle>
      <StyledParagraph>
        {getDescription(userSpecs.hasTimeTracking)}
      </StyledParagraph>
      <StyledParagraph>See what you need, without distraction.</StyledParagraph>
    </>
  );
};

const Schedule = (props) => {
  return (
    <StepBase {...props}>
      <ScheduleContent anchor={props.anchor} userSpecs={props.userSpecs} />
    </StepBase>
  );
};

export default Schedule;
