import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import cn from 'classnames';
import type { VirtualItem } from '@tanstack/react-virtual';

import { FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING } from '@float/constants/search';
import { logger } from '@float/libs/logger';
import type {
  SearchAutocompleteResultItem,
  SearchAutocompleteSavedSearchItem,
} from '@float/common/search/selectors/getSearchAutocompleteResults';
import type { FilterCategory } from '@float/common/search/types';

import { getHasSubVal } from '../../helpers/getHasSubVal';
import { Item } from './components/Item';
import { ItemCategory } from './components/ItemCategory';
import { ItemDepartment } from './components/ItemDepartment';
import type { GetIsValueDisabled } from '../../types';
import type { ResultsListProps } from '../SearchFilterResults/components/ResultsList';

import * as styles from './styles.css';

type SearchFilterItemProps = ResultsListProps & {
  virtualItem: VirtualItem;
  isValueDisabled: GetIsValueDisabled;
};

const getIsSavedSearch = (
  item: SearchAutocompleteResultItem,
): item is SearchAutocompleteSavedSearchItem => {
  return item.type === 'savedSearch';
};

export const SearchFilterItem = (props: SearchFilterItemProps) => {
  const {
    addFilter,
    addValue,
    categoryIndices,
    data,
    deleteSavedSearch,
    disableMultiSelect,
    activateCategory,
    expandedCategory,
    highlightedIndex,
    measureElement,
    removeValue,
    replaceValue,
    selectedValues,
    setHighlightedIndex,
    virtualItem,
    isValueDisabled,
  } = props;

  const { index } = virtualItem;

  const item = data[index];

  if (!item) return null;

  const category = FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING[
    item.type
  ] as FilterCategory;
  const isFirstInCategory =
    !expandedCategory && categoryIndices[category] === index;
  const highlighted = highlightedIndex === index;

  const isSelectable = Boolean(
    !getIsSavedSearch(item) &&
      expandedCategory &&
      expandedCategory !== 'savedSearches' &&
      !disableMultiSelect,
  );

  const isSelected = isSelectable && selectedValues.includes(item.val);
  const isGlobSelected = item.val !== '*' && selectedValues.includes('*');
  const isCheckboxDisabled = isValueDisabled(item);

  const toggleCheckBox = () => {
    if (isCheckboxDisabled || getIsSavedSearch(item)) return;
    if (isSelected) {
      removeValue(item);
    } else {
      addValue(item);
    }
  };

  const onDelete = (item: SearchAutocompleteResultItem) => {
    deleteSavedSearch(item);
  };

  const onSelect = () => {
    if (isCheckboxDisabled) return;

    addFilter(item);
  };

  return (
    <li
      ref={measureElement}
      className={styles.item}
      data-index={index}
      onMouseEnter={() => {
        setHighlightedIndex(index);
      }}
    >
      <div
        className={cn(styles.itemSpacer, {
          [styles.separator]:
            'showSeparatorAfter' in item && item.showSeparatorAfter,
        })}
      >
        {isFirstInCategory && (
          <ItemCategory type={category} onClick={activateCategory} />
        )}

        {/* Workaround for https://linear.app/float-com/issue/CS-961/when-filtering-a-specific-project-number-it-gives-weve-got-a-problem */}
        <ErrorBoundary
          FallbackComponent={() => null}
          onError={logger.getReactErrorBoundaryHandler('SearchFilterItem', {
            searchItem: true,
          })}
        >
          {item.type === 'department' && (
            <ItemDepartment
              checked={isSelected || isGlobSelected}
              className={cn({ [styles.ident]: !expandedCategory })}
              highlighted={highlighted}
              isSelectable={isSelectable}
              isSelected={isSelected}
              item={item}
              onDelete={onDelete}
              onDeselect={() => {
                removeValue(item);
              }}
              onReplace={replaceValue}
              onSelect={() => {
                addFilter(item);
              }}
              selectedValues={selectedValues}
            />
          )}

          {item.type !== 'department' && (
            <Item
              checked={isSelected || isGlobSelected}
              className={cn({
                [styles.ident]: !expandedCategory,
                [styles.projectCode]: getHasSubVal(item),
              })}
              highlighted={highlighted}
              isCheckboxDisabled={isCheckboxDisabled}
              isCheckboxReadOnly={isGlobSelected}
              isDeletable={category === 'savedSearches'}
              isSelectable={isSelectable}
              item={item}
              onClickCheckBox={toggleCheckBox}
              onDelete={onDelete}
              onSelect={onSelect}
            />
          )}
        </ErrorBoundary>
      </div>
    </li>
  );
};
