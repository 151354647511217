import React from 'react';
import { t } from '@lingui/macro';

import { getBillRateLabel, getRateSuffix } from '@float/common/lib/rates/rates';

import { CurrencyInputField } from '../components/CurrencyInputField';

type DefaultHourlyRateFieldProps = {
  name?: 'project.default_hourly_rate' | 'phase.default_hourly_rate';
  readOnly?: boolean;
};

export function DefaultHourlyRateField({
  name = 'project.default_hourly_rate',
  readOnly,
}: DefaultHourlyRateFieldProps) {
  return (
    <CurrencyInputField
      name={name}
      label={getBillRateLabel()}
      suffix={getRateSuffix()}
      placeholder={t`0`}
      readOnly={readOnly}
    />
  );
}
