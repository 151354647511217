import { t } from '@lingui/macro';

import { Role } from '../Role';
import { getRoleChangeConfirmData } from '../Role.helpers';

export function getBulkEditRoleIdField() {
  return {
    label: t`Role`,
    render: Role,
    getConfirmData: getRoleChangeConfirmData,
  };
}
