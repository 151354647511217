import { t } from '@lingui/macro';

import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { IconCloseCircle } from '@float/ui/icons/essentials/IconCloseCircle';
import { IconSwitch } from '@float/ui/icons/essentials/IconSwitch';
import { IconUserCircle } from '@float/ui/icons/essentials/IconUserCircle';

export const useSingleProjectPersonMenu = (
  person: Person,
  project: Project,
  actions: {
    showPersonModal: (person: Person) => void;
    showSwitchPersonModal: (modalSettings: {
      person: Person;
      project: Project;
    }) => void;
    removeFromProjects: (person: Person, projects: Project[]) => void;
  },
) => {
  const items = [
    {
      value: t`View person profile`,
      icon: IconUserCircle,
      onClick: () => actions.showPersonModal(person),
    },
    {
      value: t`Switch person`,
      icon: IconSwitch,
      onClick: () => actions.showSwitchPersonModal({ person, project }),
    },
    {
      value: t`Remove from project`,
      icon: IconCloseCircle,
      onClick: () => actions.removeFromProjects(person, [project]),
    },
  ];

  return items;
};
