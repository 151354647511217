import React from 'react';
import { useWatch } from 'react-hook-form';
import { Trans } from '@lingui/macro';

import { useIsSmallMediaSizeActive } from '@float/libs/hooks/media';
import { BudgetPriority, BudgetType } from '@float/types/project';

import { SidePanelTableHeaderRow } from '../../components/SidePanelTableSection/SidePanelTableHeaderRow';
import { SidePanelTableHeading } from '../../components/SidePanelTableSection/SidePanelTableHeading';
import { SidePanelTableRows } from '../../components/SidePanelTableSection/SidePanelTableRows';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { useProjectACL } from '../../hooks/useProjectACL';
import { ProjectFormData } from '../../types';
import { ProjectPhasesRow } from './ProjectPhasesRow';

import * as styles from './styles.css';

export function ProjectPhasesTable({ addDisabled }: { addDisabled: boolean }) {
  const budgetPriority = useWatch<ProjectFormData, 'project.budget_priority'>({
    name: 'project.budget_priority',
  });

  const budgetPerPhase = budgetPriority === BudgetPriority.Phase;

  const budgetType = useWatch<ProjectFormData, 'project.budget_type'>({
    name: 'project.budget_type',
  });
  const isTemplate = useIsTemplate();
  const isSmallBreakpointActive = useIsSmallMediaSizeActive();
  const rowStyle = isTemplate
    ? styles.templatePhaseGridTemplateColumns
    : styles.phaseGridTemplateColumns;

  const hoursBudget = budgetType === BudgetType.TotalHours;
  const feeBudget = budgetType === BudgetType.TotalFee;
  const acl = useProjectACL();

  const showBudget =
    Boolean(budgetPerPhase) && (hoursBudget || (acl.canSeeBudget && feeBudget));

  return (
    <div role="table">
      <SidePanelTableHeaderRow
        className={rowStyle({
          gridTemplateColumns: showBudget ? 'headerBudget' : 'headerDefault',
        })}
      >
        <SidePanelTableHeading>
          <Trans>Phase</Trans>
        </SidePanelTableHeading>
        <SidePanelTableHeading active={showBudget}>
          <Trans>Budget</Trans>
        </SidePanelTableHeading>
        {isTemplate && (
          <>
            <SidePanelTableHeading>
              <Trans>Start after</Trans>
            </SidePanelTableHeading>
            <SidePanelTableHeading>
              <Trans>Duration</Trans>
            </SidePanelTableHeading>
          </>
        )}
        <SidePanelTableHeading active={!isTemplate && !isSmallBreakpointActive}>
          <Trans>Dates</Trans>
        </SidePanelTableHeading>
      </SidePanelTableHeaderRow>
      <SidePanelTableRows
        name={'phases'}
        watchUpdate={'phase_name'}
        rowRenderer={(field, index) => (
          <ProjectPhasesRow
            key={field.id}
            index={index}
            budgetType={showBudget ? budgetType : undefined}
            addDisabled={addDisabled}
          />
        )}
      />
    </div>
  );
}
