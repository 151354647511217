import React, { PropsWithChildren } from 'react';
import { t, Trans } from '@lingui/macro';

import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';
import { getBillRateLabel } from '@float/common/lib/rates/rates';
import { Person } from '@float/types/person';
import { formatNumber } from '@float/ui/helpers/number/numberParser';

import { ProjectTeamMemberRateField } from '../../fields/ProjectTeamMemberRateField';
import { useProjectOrPhaseFieldValue } from '../../hooks/useProjectOrPhaseFieldValue';

import * as styles from './styles.css';

const ReadOnlyRate: React.FC<PropsWithChildren> = ({ children }) => {
  const currencyProps = useCurrencyProps();
  if ('prefix' in currencyProps) {
    return (
      <div className={styles.defaultRateContainer}>
        {currencyProps.prefix} {children}
      </div>
    );
  }
  return (
    <div className={styles.defaultRateContainer}>
      {children} {currencyProps.suffix}
    </div>
  );
};

export const ProjectTeamMemberRate: React.FC<{
  index: number;
  person?: Person;
  toggleDefaultRate: boolean;
}> = ({ index, person, toggleDefaultRate }) => {
  const defaultHourlyRate = useProjectOrPhaseFieldValue('default_hourly_rate');

  const personName = person?.name;
  const billRateLabel = getBillRateLabel();

  return toggleDefaultRate ? (
    <ReadOnlyRate>
      {formatNumber(defaultHourlyRate || 0)}
      <span>
        <Trans>/hr</Trans>
      </span>
    </ReadOnlyRate>
  ) : (
    <ProjectTeamMemberRateField
      disabled={!person}
      name={`team.${index}.hourly_rate`}
      label={t`${billRateLabel} for ${personName}`}
    />
  );
};
