import { ComponentPropsWithRef } from 'react';

import { ModalProps } from '@float/ui/primitives/Modal';
import { SVGIconComponent } from '@float/ui/primitives/SVGIcon';

export enum ModalConfirmAppearance {
  Info = 'info',
  Danger = 'danger',
  SoftDanger = 'soft-danger',
}

export enum ConfirmCheckboxAppearance {
  Danger = 'danger',
  Warning = 'warning',
}

export type ConfirmCheckboxProps = {
  checked?: boolean;
  className?: string;
  appearance?: ConfirmCheckboxAppearance;
  icon?: SVGIconComponent;
  onClick?: () => void;
};

export type ModalConfirmProps = {
  appearance?: ModalConfirmAppearance;
  confirmCheckboxAppearance?: ConfirmCheckboxAppearance;
  confirmCheckboxIcon?: SVGIconComponent;
  description?: string;
  modalProps: ModalProps;
  onClickConfirm?: () => Promise<void> | void;
  onOpenChange?: (state: boolean) => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  title: string;
  twoStepConfirmation?: boolean;
} & ComponentPropsWithRef<'div'>;
