import { t } from '@lingui/macro';
import { amountFormatter } from 'reports/helpers/tableFormatters';

import { formatAmount } from '@float/common/lib/budget';
import {
  ProjectBudgetPriority,
  ProjectBudgetType,
} from '@float/constants/projects';
import { getBudgetDataObject } from '@float/web/reports/ProjectsOverview/parser/table/helpers/budget';
import type { RawTableData } from '@float/web/reports/useReportsStateReducer';

import { getTotalsByPhase } from './getTotalsByPhase';
import type { ParseTableContext, TableDataRowItem } from '../../table.types';

export function getPhasesCompareTable(
  ctx: ParseTableContext,
  raw: RawTableData,
) {
  const { project, phases } = ctx;

  const isSecondaryTable = !project.non_billable && !ctx.billable;
  const hasBudgetColumns =
    project.budget_priority === ProjectBudgetPriority.Phase &&
    !isSecondaryTable;
  const hasFeeColumns =
    !isSecondaryTable &&
    (project.budget_type === ProjectBudgetType.TotalFee ||
      project.budget_type === ProjectBudgetType.HourlyFee);

  const headers = [
    {
      label: ctx.billable ? t`Billable` : t`Non-billable`,
      align: 'flex-start',
      grow: 1,
    },
    { label: t`Budget`, width: 130, formatter: amountFormatter },
    {
      label: '',
      width: 140,
      formatter: (x: string) =>
        x === '' ? '' : formatAmount(project.budget_type, x),
    },
    {
      label: t`Logged`,
      width: 80,
      allowOverflow: true,
      formatter: (x: string) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x: string) =>
        x === '' ? '' : formatAmount(project.budget_type, x),
    },
    {
      label: t`Scheduled`,
      width: 80,
      allowOverflow: true,
      formatter: (x: string) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x: string) =>
        x === '' ? '' : formatAmount(project.budget_type, x),
    },
    {
      label: t`Difference`,
      width: 80,
      allowOverflow: true,
      formatter: (x: string) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (!hasBudgetColumns) headers.splice(1, 1);

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPhase = getTotalsByPhase(ctx, raw);

  const totals = {
    budget: 0,
    hours: 0,
    feeCents: 0,
    costCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows: TableDataRowItem[] = [];

  Object.entries(byPhase).forEach(([phaseIdUnsafe, o]) => {
    const phaseId = parseInt(phaseIdUnsafe);

    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = o.scheduled;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = o.logged.scheduled;
    const diffFee = hasFeeColumns ? (o.feeCents - o.logged.feeCents) / 100 : '';
    const diffHours = hours - loggedHours;

    totals.budget += o.budget ?? 0;
    totals.hours += o.scheduled;
    totals.loggedHours += o.logged.scheduled;
    if (hasFeeColumns) {
      totals.feeCents += o.feeCents;
      totals.loggedFeeCents += o.logged.feeCents;
    }

    const phase = phases[phaseId];
    const phaseVal = {
      val: phase?.phase_name ?? t`No Phase`,
      sortVal: phase?.phase_name ?? '0000',
    };

    const data = [
      phaseVal,
      getBudgetDataObject(o),
      loggedFee,
      loggedHours,
      fee,
      hours,
      diffFee,
      diffHours,
    ];

    if (!hasBudgetColumns) data.splice(1, 1);

    const rowChildren = Object.entries(o.children)
      .map(([taskName, c]) => {
        if (!ctx.billable && c.logged.scheduled === 0 && c.scheduled === 0)
          return null;

        const childBudget = getBudgetDataObject(
          {
            budget_type: project.budget_type,
          },
          {
            entityType: 'task',
          },
        );
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = c.scheduled;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = c.logged.scheduled;
        const childDiffFee = hasFeeColumns
          ? (c.feeCents - c.logged.feeCents) / 100
          : '';
        const childDiffHours = childHours - childLoggedHours;
        const childData = [
          taskName,
          childBudget,
          childLoggedFee,
          childLoggedHours,
          childFee,
          childHours,
          childDiffFee,
          childDiffHours,
        ];

        if (!hasBudgetColumns) childData.splice(1, 1);

        return {
          data: childData,
        };
      })
      .filter((item) => !!item);

    rows.push({
      id: phaseId,
      data,
      children: rowChildren,
    });
  });

  const footer = [
    { label: '' },
    {
      label: getBudgetDataObject({
        budget_type: project.budget_type,
        budget: totals.budget,
      }),
    },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents - totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours - totals.loggedHours },
  ];

  if (!hasBudgetColumns) footer.splice(1, 1);

  return { headers, rows, footer };
}
