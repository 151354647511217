import { omit } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { TimeoffType } from '@float/types';

import {
  ADD_TIMEOFF_TYPE_SUCCESS,
  DELETE_TIMEOFF_TYPE_SUCCESS,
  TIMEOFF_TYPES_LOAD_FAILED,
  TIMEOFF_TYPES_LOAD_FINISH,
  UPDATE_TIMEOFF_TYPE_SUCCESS,
} from '../actions';
import { RehydratePartialStateAction } from './lib/types';

export type TimeoffTypesState = {
  timeoffTypes: Record<string, TimeoffType>;
  loadState: 'UNLOADED' | 'LOADED' | 'LOAD_FAILED';
  timeoffTypesLoaded: boolean;
};

const REDUCER_NAME = 'timeoffTypes';
const DEFAULT_STATE: TimeoffTypesState = {
  timeoffTypes: {},
  loadState: 'UNLOADED',
  timeoffTypesLoaded: false,
};

export type TimeoffTypesAction =
  | {
      type: typeof TIMEOFF_TYPES_LOAD_FAILED;
    }
  | {
      type: typeof TIMEOFF_TYPES_LOAD_FINISH;
      timeoffTypes: Record<string, TimeoffType>;
    }
  | {
      type: typeof UPDATE_TIMEOFF_TYPE_SUCCESS;
      payload: TimeoffType;
    }
  | {
      type: typeof ADD_TIMEOFF_TYPE_SUCCESS;
      payload: TimeoffType;
    }
  | {
      type: typeof DELETE_TIMEOFF_TYPE_SUCCESS;
      id: number;
    };

const timeoffTypes = (
  state = DEFAULT_STATE,
  action:
    | TimeoffTypesAction
    | RehydratePartialStateAction<TimeoffTypesState, typeof REDUCER_NAME>,
): TimeoffTypesState => {
  switch (action.type) {
    case TIMEOFF_TYPES_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
        timeoffTypesLoaded: false,
        timeoffTypes: {},
      };
    }

    case TIMEOFF_TYPES_LOAD_FINISH: {
      return {
        ...state,
        loadState: 'LOADED',
        timeoffTypesLoaded: true,
        timeoffTypes: action.timeoffTypes,
      };
    }

    case UPDATE_TIMEOFF_TYPE_SUCCESS:
    case ADD_TIMEOFF_TYPE_SUCCESS: {
      const { payload: timeoffType } = action;
      if (!timeoffType || !timeoffType.timeoff_type_id) {
        return state;
      }

      return {
        ...state,
        timeoffTypes: {
          ...state.timeoffTypes,
          [timeoffType.timeoff_type_id]: timeoffType,
        },
      };
    }

    case DELETE_TIMEOFF_TYPE_SUCCESS: {
      const { id = null } = action;
      if (!id) {
        return state;
      }

      return {
        ...state,
        timeoffTypes: omit(state.timeoffTypes, id),
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.timeoffTypesLoaded ? 'LOADED' : 'UNLOADED';

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
};

export default timeoffTypes;
