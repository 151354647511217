import { Dispatch, useEffect, useMemo } from 'react';

import { fetchReport } from '@float/common/lib/request';
import { useReportsFetchUrl } from '@float/common/search/useReportsFetchUrl';
import { config } from '@float/libs/config';
import { logger } from '@float/libs/logger';

import { ReportSettings } from './types';
import { sanitizeReportsApiResponse } from './useReportsFetcher.helpers';
import { ReportsLocalReducerAction } from './useReportsStateReducer';

const SPINNER_SHOW_DELAY_MS = 300;

function loadData(
  dispatch: Dispatch<ReportsLocalReducerAction>,
  url: string,
  chartTimeout: ReturnType<typeof setTimeout>,
  tableTimeout: ReturnType<typeof setTimeout>,
) {
  const tableUrl = new URL(url);
  const tableViews = [
    'chart',
    'legacy.budgets',
    'legacy.capacity',
    'legacy.overtime',
    'legacy.timeoff',
    'legacy.totals',
  ];
  // add view query params
  tableUrl.search += tableViews.map((view) => `&view=${view}`).join('');

  fetchReport(tableUrl)
    .then((response) => {
      const data = sanitizeReportsApiResponse(response);
      dispatch({
        type: 'TABLE_LOAD_FINISH',
        data: {
          capacity: data['legacy.capacity'],
          // legacy.budgets can be undefined for some teams
          // https://linear.app/float-com/issue/CS-1469/reports-data-is-not-loading-as-expected-company-id-170474#comment-c47a879f
          budgets: data['legacy.budgets']?.projects,
          phases: data['legacy.budgets']?.phases,
          tasks: data['legacy.budgets']?.tasks,
          overtime: data['legacy.overtime'],
          timeoff: data['legacy.timeoff'],
          totals: data['legacy.totals'] ?? [],
        },
      });

      if (data.chart) {
        dispatch({
          type: 'CHART_LOAD_FINISH',
          data: {
            budgets: data['legacy.budgets'],
            baseline: data.chart.origin,
            datapoints: data.chart.datapoints,
          },
        });
      } else {
        logger.error('No chart data', new Error('No chart data'));
        dispatch({ type: 'CHART_LOAD_FAIL', err: new Error('No chart data') });
      }
    })
    .catch((err) => {
      logger.error('Error fetching reports data', err);
      dispatch({ type: 'TABLE_LOAD_FAIL', err });
      dispatch({ type: 'CHART_LOAD_FAIL', err });
    })
    .finally(() => {
      clearTimeout(tableTimeout);
      clearTimeout(chartTimeout);
    });
}

export type ReportsFetcherOpts = {
  settings: ReportSettings;
  loggedTimeUpdateCount: number;
  enabled?: boolean;
  dispatch: Dispatch<ReportsLocalReducerAction>;
  endpoint: string;
};

export function useReportsFetcher(opts: ReportsFetcherOpts) {
  const { dispatch, settings, enabled, endpoint, loggedTimeUpdateCount } = opts;

  const baseURL = useMemo(
    () =>
      new URL(
        config.reports.getPath(endpoint),
        config.reports.domain,
      ).toString(),
    [endpoint],
  );

  const url = useReportsFetchUrl(settings, baseURL, enabled);

  useEffect(() => {
    if (url === null) return;

    const tableTimeout = setTimeout(() => {
      dispatch({ type: 'SHOW_TABLE_SPINNER' });
    }, SPINNER_SHOW_DELAY_MS);

    const chartTimeout = setTimeout(() => {
      dispatch({ type: 'SHOW_CHART_SPINNER' });
    }, SPINNER_SHOW_DELAY_MS);

    dispatch({ type: 'START_FETCH' });

    if (enabled !== false) {
      loadData(dispatch, url, chartTimeout, tableTimeout);
    }

    // Note: The URL contains the full query we are going to send to the reports service
    // so we are going to refetch data only when the url changes
    // eslint-disable-next-line
  }, [url, enabled, loggedTimeUpdateCount]);
}
