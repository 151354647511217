import { isNil } from 'es-toolkit';

import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import { useAppSelectorStrict } from '@float/common/store';
import { BudgetType, Project } from '@float/types';
import { getUser } from '@float/web/selectors';

export function useShouldShowRates(project: Project) {
  const currentUser = useAppSelectorStrict(getUser);
  const hasAccessToBillRates = getCanCurrentUserSeeBudgets(currentUser);

  // Don't show bill rates rates if user doesn't have access.
  if (hasAccessToBillRates === false) {
    return false;
  }

  const isProjectBudgetTypeMonetary =
    project.budget_type === BudgetType.TotalFee ||
    project.budget_type === BudgetType.HourlyFee;

  // No point showing bill rates if project budget type is not monetary.
  if (isProjectBudgetTypeMonetary === false) {
    return false;
  }

  const isMonetaryBudgetWithDefaultRate =
    isProjectBudgetTypeMonetary && !isNil(project.default_hourly_rate);

  // If project has the same rate for everyone, swapping a person
  // will not change the rate. So, we don't need to show the rates.
  if (isMonetaryBudgetWithDefaultRate) {
    return false;
  }

  return true;
}
