import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, mapValues, max, min } from 'lodash';

import { getSearchFilteredProjects } from '@float/common/search/selectors/projects';
import { getUser } from '@float/common/selectors/currentUser';
import {
  getPeopleMap,
  getSearchFilteredActivePeopleMap,
} from '@float/common/selectors/people';
import { getWorkHours } from '@float/common/selectors/schedule/getWorkHours';
import { getActiveFilters } from '@float/common/selectors/views';
import { hasCollapsedProject } from '@float/common/serena/util/hasCollapsedProject';
import { useAppSelectorStrict } from '@float/common/store';
import { ScheduleViewType } from '@float/constants/schedule';
import {
  CellsMap,
  CurrentUser,
  Person,
  PersonProjectRow,
  PersonRow,
  Project,
  ProjectRow,
} from '@float/types';
import { Placeholder } from '@float/types/placeholder';
import type { SearchState } from '@float/common/reducers/search';

import {
  sortPeople,
  sortPeopleCustom,
  sortProjects,
  stringCompare,
} from '../../lib/sort';
import { useScheduleContext } from '../ScheduleContext';
import { getIsSingleProjectPlanView } from '../util/getIsSingleProjectPlanView';
import { InsightsEntry } from './insights/types';
import { NON_WORK_DAY, WORK_DAY } from './useCells/_helpers';
import { getSingleProjectViewRows } from './useScheduleRows.helpers';

export type { PersonProjectRow, PersonRow, ProjectRow };

function useSortPrefs() {
  const prefs = useAppSelectorStrict((state) => getUser(state).prefs);

  return useMemo(
    () => ({
      sked_sort_by: prefs.sked_sort_by || 'name',
      sked_sort_dir: prefs.sked_sort_dir || 'asc',
      sked_custom_sort: prefs.sked_custom_sort,
      custom_sort_type: prefs.custom_sort_type,
      custom_sort_order: prefs.custom_sort_order,
      people_order: prefs.people_order,
      custom_priority: prefs.custom_priority,
      projview_people_order: prefs.projview_people_order,
      projview_people_custom_priority: prefs.projview_people_custom_priority,
      sked_projview_sort_by: prefs.sked_projview_sort_by || 'name',
      sked_projview_sort_dir: prefs.sked_projview_sort_dir || 'asc',
      single_project_view_sort_by: prefs.single_project_view_sort_by,
      single_project_view_sort_dir: prefs.single_project_view_sort_dir,
    }),
    [
      prefs.sked_sort_by,
      prefs.sked_sort_dir,
      prefs.sked_custom_sort,
      prefs.custom_sort_type,
      prefs.custom_sort_order,
      prefs.people_order,
      prefs.custom_priority,
      prefs.projview_people_order,
      prefs.projview_people_custom_priority,
      prefs.sked_projview_sort_by,
      prefs.sked_projview_sort_dir,
      prefs.single_project_view_sort_by,
      prefs.single_project_view_sort_dir,
    ],
  );
}

type PreEarhartAvailabilityProps = {
  people: Record<number, Person>;
  startCol: number;
  endCol: number;
  cells: CellsMap;
  numDays: number;
  dates: DatesManager;
  user: CurrentUser;
};

type PreEarhartAvailability = Record<number, number>;

function getPreEarhartAvailability({
  people,
  startCol,
  endCol,
  cells,
  numDays,
  dates,
  user,
}: PreEarhartAvailabilityProps): PreEarhartAvailability {
  return mapValues(people, (person) => {
    const personStartDay = person.start_date;
    const personEndDay = person.end_date;

    let availableHours = 0;
    let usedHours = 0;

    for (let col = startCol; col < endCol; col++) {
      const cellKey = `person-${person.people_id}:${col}` as const;
      const cell = cells[cellKey];

      for (let j = 0; j < numDays; j++) {
        const day = dates.fromDescriptor(col, j);

        if (
          (personStartDay && personStartDay > day) ||
          (personEndDay && personEndDay < day)
        ) {
          continue;
        }

        const workHours = getWorkHours(dates, user, person, day);
        availableHours += workHours;

        if (cell) {
          const dayType = cell.workDays[j];
          const dayHours = cell.dayHours?.[j];

          if (dayType !== WORK_DAY && dayType !== NON_WORK_DAY) {
            // If dayType doesn't hold one of those two symbols, it
            // means that it's a full-day timeoff, so consider it
            // fully used.
            usedHours += workHours;
          } else {
            if (dayHours) {
              usedHours += dayHours;
            }
          }
        }
      }
    }

    return availableHours - usedHours;
  });
}

export type SortPrefs = ReturnType<typeof useSortPrefs>;

type PeopleSortedRowsProps = {
  fetcher: {
    hasFetchedRange: boolean;
    fetchedRanges: number[];
    weeksPerFetch: number;
  };
  sortPrefs: ReturnType<typeof useSortPrefs>;
  availabilities: React.MutableRefObject<Record<number, number> | null>;
  availabilitiesDir: React.MutableRefObject<unknown>;
  cells: CellsMap;
  people: Record<number, Person>;
  dates: DatesManager;
  numDays: number;
  logTimeView: boolean;
  skipInsights?: boolean;
  peopleInsights?: Record<string, InsightsEntry>;
  user: CurrentUser;
};

function getPeopleSortedRows(props: PeopleSortedRowsProps): PersonRow[] {
  const {
    fetcher,
    sortPrefs,
    availabilities,
    availabilitiesDir,
    cells,
    people,
    dates,
    numDays,
    logTimeView,
    skipInsights,
    user,
  } = props;
  let { sked_sort_by, sked_sort_dir } = sortPrefs;

  if (sortPrefs.sked_custom_sort == 1) {
    // Custom sort stores deltas of positions, not an absolute order, which
    // means that it has to be based on a predictable sort. Therefore, we
    // need to store the sort they were in when custom sort was triggered so
    // that we can base future custom sorts on that type.
    sked_sort_by = sortPrefs.custom_sort_type || sked_sort_by;
    sked_sort_dir = sortPrefs.custom_sort_order || sked_sort_dir;
  }

  if (
    cells._allLoaded &&
    fetcher.hasFetchedRange &&
    sortPrefs.sked_sort_by === 'avail'
  ) {
    // We only want to calculate availabilities when they engage the sort option
    // or change its direction. This keeps the sort order static while they
    // assign tasks between people.
    if (sked_sort_dir !== availabilitiesDir.current) {
      availabilitiesDir.current = sked_sort_dir;
      availabilities.current = null;
    }

    // Using availabilities.current to not re-sort if insights change
    // to prevent rows from jumping around.
    if (availabilities.current === null) {
      if (skipInsights) {
        const startCol = min(fetcher.fetchedRanges)!;
        const endCol = max(fetcher.fetchedRanges)! + fetcher.weeksPerFetch;
        availabilities.current = getPreEarhartAvailability({
          people,
          startCol,
          endCol,
          cells,
          numDays,
          dates,
          user,
        });
      } else {
        const { peopleInsights } = props;
        if (!isEmpty(peopleInsights)) {
          availabilities.current = mapValues(people, (person) => {
            const personStats = peopleInsights[person.people_id];
            return personStats?.capacity || personStats?.overtime || 0;
          });
        }
      }
    }
  }

  const sortedPeople = Object.values(people).sort(
    sortPeople({
      type: sked_sort_by,
      dir: sked_sort_dir,
      availabilities: availabilities.current,
    }),
  );

  if (skipInsights) {
    sortedPeople.forEach((person, i) => {
      person.order = i;
    });
  }

  if (sortPrefs.sked_custom_sort == 1) {
    const { people_order = {}, custom_priority = {} } = sortPrefs;

    sortedPeople.forEach((person, i) => {
      const id = person.people_id;
      const order = people_order[person.people_id];

      person.priority = order == null;
      person.order = order != null ? Number(order) : i;

      if (order != null) {
        // See "Incremental DB correction" comment in `common/actions/currentUser.js`.
        // For people having a negative `people_order value`, we sometimes won't have
        // `custom_priority` data stored in db due to a previous bug. So, for custom
        // sorting to work henceforth, we assume custom_priority values based on the
        // order you see on the screen.
        if (!custom_priority[order]) custom_priority[order] = [];
        let customPriority = custom_priority[order].indexOf(id);
        if (customPriority === -1) {
          custom_priority[order].push(id);
          customPriority = custom_priority[order].length - 1;
        }
        person.customPriority = customPriority;
      }
    });

    sortedPeople.sort(
      sortPeopleCustom(['order', 'priority', 'customPriority', 'name']),
    );
  } else {
    sortedPeople.forEach((person, i) => {
      person.order = i;
    });
  }

  const prefix = logTimeView ? 'logged_time' : 'person';
  return sortedPeople.map((p) => ({
    type: 'person',
    id: `${prefix}-${p.people_id}`,
    key: `${prefix}-${p.people_id}`,
    darkBackground:
      p.people_type_id === 3 && p.new_role_placeholder !== Placeholder.New,
    data: p,
    isLogTimeRow: logTimeView,
    peopleId: p.people_id,
  }));
}

type ProjectSortInfo = {
  datesFetched?: boolean;
  by?: string;
  dir?: 'desc' | 'asc';
  filters?: SearchState['filters'];
  dates?: Record<number, { start_date?: string; end_date?: string }>;
};

type ProjectSortedRowsProps = {
  projectSortInfo: React.MutableRefObject<ProjectSortInfo>;
  searchFilteredProjects: Project[];
  people: Record<number, Person>;
  expandedProjectIds: Record<number, boolean>;
  sortPrefs: ReturnType<typeof useSortPrefs>;
  filters: SearchState['filters'];
  logTimeView: boolean;
};

function getProjectSortedRows(
  props: ProjectSortedRowsProps,
): Array<ProjectRow | PersonProjectRow> {
  const {
    projectSortInfo: projectSortInfoRef,
    searchFilteredProjects: projects,
    people,
    expandedProjectIds,
    sortPrefs,
    filters,
    logTimeView,
  } = props;

  const { sked_projview_sort_by, sked_projview_sort_dir } = sortPrefs;

  const datesFetched = projects.some((p) => p.start_date || p.end_date);

  const projectSortInfo = projectSortInfoRef.current;

  // If we're currently sorting by dates, we want to keep the original start
  // and end dates so that projects don't move up and down as tasks change.
  if (
    (!projectSortInfo.datesFetched && datesFetched) ||
    projectSortInfo.by !== sked_projview_sort_by ||
    projectSortInfo.dir !== sked_projview_sort_dir ||
    projectSortInfo.filters !== filters
  ) {
    projectSortInfo.datesFetched = datesFetched;
    projectSortInfo.by = sked_projview_sort_by;
    projectSortInfo.dir = sked_projview_sort_dir;
    projectSortInfo.filters = filters;
    projectSortInfo.dates = projects.reduce(
      (acc, project) => {
        acc[project.project_id] = {
          start_date: project.start_date,
          end_date: project.end_date,
        };
        return acc;
      },
      {} as NonNullable<ProjectSortInfo['dates']>,
    );
  }

  const filterTentativeIfLogTimeView = (p: Project) =>
    logTimeView ? !p.tentative : true;

  const sortedProjects: Project[] = sortProjects(
    projects.filter((p) => p.active && filterTentativeIfLogTimeView(p)),
    sked_projview_sort_dir,
    sked_projview_sort_by,
    projectSortInfo.dates,
  );

  const rows: Array<ProjectRow | PersonProjectRow> = [];

  const prefix = logTimeView ? 'logged_time' : 'person';

  for (const project of sortedProjects) {
    const projectId = project.project_id;

    // @ts-expect-error all_people_ids is filled by a selector
    const projectPeopleIds = project.all_people_ids as number[];

    const projectPeople = projectPeopleIds
      .map((id) => people[id])
      .filter(Boolean);
    const isSingleProjectPlanView = getIsSingleProjectPlanView(
      ScheduleViewType.Projects,
      filters,
      sortedProjects.length,
    );
    const isProjectCollapsed = isSingleProjectPlanView
      ? false
      : hasCollapsedProject({
          expandedProjectIds,
          projectId,
        });

    const projectRow: ProjectRow = {
      type: 'project',
      id: `project-${projectId}`,
      darkBackground: true,
      isCollapsed: isProjectCollapsed,
      data: project,
      numActivePeople: projectPeople.length,
      numTotalPeople: projectPeopleIds.length,
    };

    rows.push(projectRow);

    if (isProjectCollapsed) continue;

    const {
      projview_people_order: people_order = {},
      projview_people_custom_priority: custom_priority = {},
    } = sortPrefs;

    projectPeople.sort((a, b) => {
      return stringCompare(a.name.toLowerCase(), b.name.toLowerCase());
    });

    const projectPeopleOrder = people_order[projectId];

    projectPeople.forEach((person, i) => {
      const order = projectPeopleOrder?.[person.people_id] ?? null;

      const projectPriority =
        person.projectPriority || (person.projectPriority = {});
      const projectOrder = person.projectOrder || (person.projectOrder = {});

      projectPriority[projectId] = order === null;
      projectOrder[projectId] = order === null ? i : Number(order);

      if (order !== null) {
        const customPriority = custom_priority[projectId]?.[
          order as number
        ]?.indexOf(person.people_id);

        if (customPriority > -1) {
          const projectCustomPriority =
            person.projectCustomPriority || (person.projectCustomPriority = {});

          projectCustomPriority[projectId] = customPriority;
        }
      }
    });

    if (projectPeopleOrder) {
      projectPeople.sort(
        sortPeopleCustom([
          `projectOrder.${projectId}`,
          `projectPriority.${projectId}`,
          `projectCustomPriority.${projectId}`,
          'name',
        ]),
      );
    }

    for (const person of projectPeople) {
      rows.push({
        type: 'person',
        id: `${prefix}-${person.people_id}`,
        key: `person-${projectId}-${person.people_id}`,
        projectId,
        peopleId: person.people_id,
        data: person,
        isLogTimeRow: logTimeView,
        darkBackground:
          person.people_type_id === 3 &&
          person.new_role_placeholder !== Placeholder.New,
      });
    }
  }

  return rows;
}

type Rows = PersonRow[] | Array<ProjectRow | PersonProjectRow>;

export function useScheduleRows(params: {
  expandedProjectIds?: Record<number, boolean> | null;
  fetcher: PeopleSortedRowsProps['fetcher'];
  draggedRowId?: string | null;
  animations?: { disable: () => void; enable: () => void } | null;
  suvPersonId?: number | null;
  skipInsights?: boolean;
  singleProjectViewProject?: Project;
}) {
  const {
    expandedProjectIds,
    fetcher,
    animations,
    draggedRowId,
    skipInsights,
    suvPersonId,
    singleProjectViewProject,
  } = params;

  const {
    viewType,
    logTimeView,
    numDays,
    dates,
    cellsWrapper: { cells },
  } = useScheduleContext();

  const user = useAppSelectorStrict(getUser);

  const sortPrefs = useSortPrefs();

  const filters = useAppSelectorStrict(getActiveFilters);
  const searchFilteredPeople = useAppSelectorStrict(
    getSearchFilteredActivePeopleMap,
  );
  const searchFilteredProjects = useAppSelectorStrict(
    getSearchFilteredProjects,
  );
  const allPeople = useAppSelectorStrict(getPeopleMap);

  // We need people insights only when sorting by capacity
  const needsPeopleInsights =
    sortPrefs.sked_sort_by === 'avail' && !skipInsights;
  const peopleInsights = useAppSelectorStrict((state) =>
    needsPeopleInsights ? state.timeRange?.insights?.byPerson : undefined,
  );

  const availabilitiesDir = useRef(null);
  const availabilities = useRef(null);
  const projectSortInfo = useRef({});

  const createRows = useCallback(() => {
    let res: Rows = [];

    if (viewType === 'people') {
      if (suvPersonId) {
        const person = allPeople[suvPersonId];
        if (person) {
          const prefix = logTimeView ? 'logged_time' : 'person';
          res = [
            {
              type: 'person',
              id: `${prefix}-${person.people_id}`,
              key: `${prefix}-${person.people_id}`,
              darkBackground:
                person.people_type_id === 3 &&
                person.new_role_placeholder !== Placeholder.New,
              data: person,
              peopleId: person.people_id,
              isLogTimeRow: logTimeView,
            },
          ];
        }
      } else {
        res = getPeopleSortedRows({
          fetcher,
          sortPrefs,
          availabilities,
          availabilitiesDir,
          cells,
          dates,
          numDays,
          people: searchFilteredPeople,
          logTimeView,
          peopleInsights,
          skipInsights,
          user,
        });
      }
    }

    if (viewType === 'projects') {
      res = getProjectSortedRows({
        projectSortInfo,
        sortPrefs,
        expandedProjectIds: expandedProjectIds || {},
        people: searchFilteredPeople,
        searchFilteredProjects,
        filters,
        logTimeView,
      });
    }

    if (
      viewType === ScheduleViewType.SingleProject &&
      Boolean(singleProjectViewProject)
    ) {
      res = getSingleProjectViewRows(
        singleProjectViewProject!,
        searchFilteredPeople,
        sortPrefs,
      );
    }

    return res;
  }, [
    allPeople,
    cells,
    expandedProjectIds,
    dates,
    fetcher,
    filters,
    logTimeView,
    numDays,
    peopleInsights,
    searchFilteredPeople,
    searchFilteredProjects,
    singleProjectViewProject,
    skipInsights,
    sortPrefs,
    suvPersonId,
    viewType,
    user,
  ]);

  const [rows, setRows] = useState(createRows);

  const rowsRef = useRef(rows);

  // using useLayoutEffect to time the rows update before the cells update
  // https://linear.app/float-com/issue/FT-1522/staging-there-is-a-small-glitch-when-opening-me-filter
  useLayoutEffect(() => {
    if (draggedRowId) {
      // If the user is currently dragging a row, we're going to be manually
      // adjusting the transform Y coordinates to provide responsive feedback.
      // In this case, we don't want to allow the rows array to be updated until
      // after they've let go.
      return;
    }

    animations?.disable();

    const newRows = createRows();
    rowsRef.current = newRows;
    setRows(newRows);

    if (animations) {
      const timer = setTimeout(animations.enable, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [createRows, draggedRowId, animations]);

  const getCurrentRows = useCallback(() => rowsRef.current, []);

  return { rows, getCurrentRows };
}
