import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'es-toolkit';

import { LoadState } from '@float/common/reducers/lib/types';

import {
  ensurePhaseEstimatesLoaded,
  ensureProjectEstimatesLoaded,
  ensureTaskEstimatesLoaded,
} from './estimates.actions';
import {
  DEFAULT_ESTIMATES_STATE,
  ESTIMATES_REDUCER_NAME,
} from './estimates.constants';

const estimatesSlice = createSlice({
  name: ESTIMATES_REDUCER_NAME,
  initialState: DEFAULT_ESTIMATES_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Project estimates
      .addCase(ensureProjectEstimatesLoaded.pending, (state, action) => {
        const { projectId } = action.meta.arg;
        state.projectEstimates[projectId] = {
          loadState: LoadState.LOADING,
          map: state.projectEstimates[projectId]?.map ?? {},
        };
      })
      .addCase(ensureProjectEstimatesLoaded.rejected, (state, action) => {
        const { projectId } = action.meta.arg;
        state.projectEstimates[projectId] = {
          loadState: LoadState.LOAD_FAILED,
          map: state.projectEstimates[projectId]?.map ?? {},
        };
      })
      .addCase(ensureProjectEstimatesLoaded.fulfilled, (state, action) => {
        const { projectId } = action.meta.arg;
        const estimates = action.payload;
        state.projectEstimates[projectId] = {
          loadState: LoadState.LOADED,
          map: keyBy(estimates, (estimate) => estimate.id),
        };
      })
      // Phase estimates
      .addCase(ensurePhaseEstimatesLoaded.pending, (state, action) => {
        const { projectEstimateId } = action.meta.arg;
        state.phaseEstimates[projectEstimateId] = {
          loadState: LoadState.LOADING,
          map: state.phaseEstimates[projectEstimateId]?.map ?? {},
        };
      })
      .addCase(ensurePhaseEstimatesLoaded.rejected, (state, action) => {
        const { projectEstimateId } = action.meta.arg;
        state.phaseEstimates[projectEstimateId] = {
          loadState: LoadState.LOAD_FAILED,
          map: state.phaseEstimates[projectEstimateId]?.map ?? {},
        };
      })
      .addCase(ensurePhaseEstimatesLoaded.fulfilled, (state, action) => {
        const { projectEstimateId } = action.meta.arg;
        const estimates = action.payload;
        state.phaseEstimates[projectEstimateId] = {
          loadState: LoadState.LOADED,
          map: keyBy(estimates, (estimate) => estimate.id),
        };
      })
      // Task estimates
      .addCase(ensureTaskEstimatesLoaded.pending, (state, action) => {
        const { projectEstimateId } = action.meta.arg;
        state.taskEstimates[projectEstimateId] = {
          loadState: LoadState.LOADING,
          map: state.taskEstimates[projectEstimateId]?.map ?? {},
        };
      })
      .addCase(ensureTaskEstimatesLoaded.rejected, (state, action) => {
        const { projectEstimateId } = action.meta.arg;
        state.taskEstimates[projectEstimateId] = {
          loadState: LoadState.LOAD_FAILED,
          map: state.taskEstimates[projectEstimateId]?.map ?? {},
        };
      })
      .addCase(ensureTaskEstimatesLoaded.fulfilled, (state, action) => {
        const { projectEstimateId } = action.meta.arg;
        const estimates = action.payload;
        state.taskEstimates[projectEstimateId] = {
          loadState: LoadState.LOADED,
          map: keyBy(estimates, (estimate) => estimate.id),
        };
      });
  },
});

export const estimatesReducer = estimatesSlice.reducer;
export const estimatesReducerPath = estimatesSlice.reducerPath;
