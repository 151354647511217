import { normalize } from '@float/common/search/helpers';
import { SearchReducerContext } from '@float/common/search/types';
import { FilterToken } from '@float/types';

import { matchStringWithoutNormalize } from '../../core/filters/lib/matchers';
import {
  addGlobalFilters,
  getAsQuerystringTuple,
  parsePersonType,
  parseProjectStatus,
  parseTaskStatus,
  parseTimeOffStatus,
  toArray,
} from './getReportQueryFromFilters.helpers';
import { ReportQueryField, ReportQuerySettings } from './types';

const convertManagerFilterToPeopleFilter = (
  filter: FilterToken,
  context: SearchReducerContext,
): FilterToken[] => {
  const assignOperator = () => {
    const isOR = filter.operator && filter.operator.startsWith('|');
    const isNOT = filter.operator && filter.operator.endsWith('-');

    if (isNOT) {
      if (isOR) return '|-';
      return '-';
    }

    if (isOR) {
      return '|';
    }

    return '';
  };

  const filterVal = toArray(filter.val);
  const managedPeopleIds = new Set(
    context.managers
      .filter((account) => filterVal.includes(account.val)) // Retrieve every manager in the filter
      .flatMap((account) => context.accountManagedPeople[account.account_id]), // Unfold each manager with all their managed people_ids
  );

  const managedPeopleName = context.people
    .filter((person) => managedPeopleIds.has(person.ids[0]))
    .flatMap((person) => person.val);

  return [
    {
      type: 'person',
      val: managedPeopleName,
      operator: assignOperator(),
    },
  ];
};

function convertToCompatibleTimeoffFilter(
  filter: FilterToken,
  context: SearchReducerContext,
): FilterToken[] {
  const filterValues = toArray(filter.val);
  const isMatchingAnyTimeoffType = filterValues.includes('*');

  if (!isMatchingAnyTimeoffType) {
    return [filter]; // Do Nothing
  }

  const { operator: rawOperator = '' } = filter;
  const shouldIncludeAllTimeoffType = rawOperator === '';
  if (shouldIncludeAllTimeoffType) {
    // Reports implements a filter algorithm. The equivalent of the FE "*" glob filter
    // on reports is to NOT filter any timeoff_type.
    return [];
  }

  const shoouldNotIncludeAnyTimeoffs = rawOperator.endsWith('-');
  if (shoouldNotIncludeAnyTimeoffs) {
    const allTimeoffNames = Object.values(context.timeoffs).map(
      (filter) => filter.val,
    );
    if (allTimeoffNames.length) {
      return [
        {
          type: 'timeoff',
          operator: rawOperator,
          val: allTimeoffNames,
        },
      ];
    }
  }

  return [filter];
}

function convertToSupportedReportsAPIFilters(
  searchFilters: FilterToken[],
  context: SearchReducerContext,
) {
  return searchFilters.flatMap((filter) => {
    if (filter.type === 'manager') {
      return convertManagerFilterToPeopleFilter(filter, context);
    }

    if (filter.type === 'timeoff') {
      return convertToCompatibleTimeoffFilter(filter, context);
    }

    return [filter];
  });
}

export function getReportQueryFromFilters(
  searchFilters: FilterToken[],
  searchContext: SearchReducerContext,
  settings: ReportQuerySettings,
  meFilter: boolean,
) {
  const queries: ReportQueryField[][] = [];
  let currentQuery: ReportQueryField[] = [];

  const unfoldedSearchfilter = convertToSupportedReportsAPIFilters(
    searchFilters,
    searchContext,
  );

  for (let i = 0; i < unfoldedSearchfilter.length; i++) {
    const filter = unfoldedSearchfilter[i];
    const { type, operator: rawOperator = '' } = filter;

    // ensure "val" is always an array
    const val = toArray(filter.val);
    // convert operator to expected value for API call
    const operator = rawOperator.includes('-') ? 'nin' : 'in';

    // check for OR operator and prepare current query, if applicable
    const isOR = rawOperator.includes('|');
    if (isOR) {
      if (currentQuery.length) queries.push(currentQuery);
      currentQuery = [];
    }
    switch (type) {
      // IDs in context
      case 'department': {
        const value = searchContext.departments
          .filter((department) => val.includes(department.val))
          .map((department) => department.id || -1);
        currentQuery.push({ field: 'department_id', operator, value });
        break;
      }
      case 'client': {
        const value = searchContext.clients
          .filter((client) => val.includes(client.val))
          .map((client) => client.id);
        currentQuery.push({ field: 'client_id', operator, value });
        break;
      }
      case 'person': {
        const value = searchContext.people
          .filter((person) => val.includes(person.val))
          .flatMap((person) => person.ids);
        currentQuery.push({ field: 'people_id', operator, value });
        break;
      }
      case 'projectOwner': {
        const value = searchContext.projectOwners
          .filter((person) => val.includes(person.val))
          .flatMap((person) => person.accountIds);
        currentQuery.push({ field: 'project_creator_id', operator, value });
        break;
      }
      case 'project': {
        const nVal = val.map(normalize);
        const value = searchContext.projects
          .filter((project) => {
            // flexible matching to include projects with project codes in the normalized `val`
            return nVal.some((normalizedVal) =>
              matchStringWithoutNormalize(
                project.normalizedVal,
                normalizedVal,
                Boolean(project.subVal),
              ),
            );
          })
          .flatMap((project) => (project.ids.length ? project.ids : -1));
        currentQuery.push({ field: 'project_id', operator, value });
        break;
      }
      case 'phase': {
        const value = searchContext.phases
          .filter((project) => val.includes(project.val))
          .flatMap((project) => project.ids);
        currentQuery.push({ field: 'phase_id', operator, value });
        break;
      }
      case 'jobTitle': {
        if (val[0] === 'None') {
          // Querying by role_id allows returning results where role_id is null
          currentQuery.push({ field: 'role_id', operator, value: [-1] });
          break;
        }
        // Strings - no need to change it
        currentQuery.push({ field: 'job_title', operator, value: val });
        break;
      }

      // logic shared with global queries
      case 'taskStatus': {
        currentQuery.push(
          ...Object.values(parseTaskStatus(filter, settings.taskStatus)),
        );
        break;
      }
      // logic shared with global queries
      case 'timeoffStatus': {
        currentQuery.push(
          ...Object.values(
            parseTimeOffStatus(filter, [], settings.timeoffApprovalsEnabled),
          ),
        );
        break;
      }
      case 'personType': {
        currentQuery.push(...Object.values(parsePersonType(filter)));
        break;
      }
      // one filter but can lead to different fields on API level
      case 'projectStatus': {
        currentQuery.push(parseProjectStatus(operator, val));
        break;
      }
      // Strings - should be replaced to ids as we have the information available
      case 'task': {
        const metaName = val.includes('No task used') ? ['No Task Name'] : val;
        currentQuery.push({
          field: 'task_meta_name',
          operator,
          value: metaName,
        });
        break;
      }
      case 'projectTag': {
        currentQuery.push({ field: 'project_tag_name', operator, value: val });
        break;
      }
      case 'personTag': {
        currentQuery.push({ field: 'people_tag_name', operator, value: val });
        break;
      }
      case 'timeoff': {
        currentQuery.push({ field: 'timeoff_type_name', operator, value: val });
        break;
      }
    }
  }

  // apply current query, if required
  if (currentQuery.length) {
    queries.push(currentQuery);
  }

  addGlobalFilters(queries, settings, meFilter);

  return getAsQuerystringTuple(queries, settings);
}
