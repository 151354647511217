import { pick } from 'lodash';
import { getActivePmIntegration } from 'selectors';

import { getJWTAccessToken } from '@float/common/actions/jwt';
import {
  CREATE_TASK,
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  formatTaskForCreateRequest,
  startUpdateTask,
  updateTaskFailure,
  updateTaskSuccess,
} from '@float/common/actions/tasks';
import API3 from '@float/common/api3';
import { batch } from '@float/common/lib/reduxBatch';
import request from '@float/common/lib/request';
import { generateId } from '@float/common/lib/utils';
import { PROMPTS } from '@float/constants/prompts';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';

import integrationsApi from '../integrations/api';

const startCreateTask = (task) => ({
  type: CREATE_TASK,
  item: task,
});

const createTaskSuccess = (task) => ({
  type: CREATE_TASK_SUCCESS,
  item: task,
});

const createTaskFailure = (task) => ({
  type: CREATE_TASK_FAILURE,
  item: task,
});

export const createTask =
  (task, isDuplicating) => async (dispatch, getState) => {
    // TODO: generate temp id in a better way.
    const createdTs = task.temporaryId || `${Date.now()}${Math.random()}`;

    dispatch(startCreateTask({ ...task, task_id: createdTs, createdTs }));
    let createRequest;
    const body = formatTaskForCreateRequest(task);
    if (task.pmKey) {
      const { coIntId } = getActivePmIntegration(getState());
      if (coIntId) {
        const accessToken = await dispatch(getJWTAccessToken());
        const extResourceId = task.pmKey;
        createRequest = integrationsApi(accessToken).createTask(
          coIntId,
          body,
          extResourceId,
        );
      }
    }

    if (!createRequest) {
      let url = 'tasks';

      if (isDuplicating) {
        const originalId = task.originalId;
        url = `tasks/duplicate/${originalId}`;
      }
      createRequest = request.post(url, body, {
        version: 'f3',
      });
    }

    try {
      const response = await createRequest;

      const { legacyOnboarding } = getState();

      if (!legacyOnboarding.prompts.includes(PROMPTS.welcomeAddTask)) {
        dispatch(updatePrompts([PROMPTS.welcomeAddTask]));
      }

      dispatch(createTaskSuccess({ ...response, createdTs }));

      return response;
    } catch (error) {
      dispatch(createTaskFailure({ ...task, createdTs }));

      throw error;
    }
  };

const prepareTaskStatusUpdatePayload = (change) => {
  const updateableFields = [
    'status',
    'repeat_state',
    'repeat_end_date',
    'start_date',
    'end_date',
  ];

  return change.updates.reduce((acc, { entity, isCreate }) => {
    const updatePayload = pick(entity, updateableFields);
    updatePayload.repeat_end = updatePayload.repeat_end_date;

    if (isCreate) {
      acc.create = acc.create || [];
      acc.create.push(updatePayload);
    } else {
      if (acc.update) {
        console.error('Only one update is allowed');
        throw new Error('Faile to update task');
      }

      acc.update = updatePayload;
    }

    return acc;
  }, {});
};
export const updateTaskStatusForRepeatingTask =
  (task, change) => async (dispatch, getState) => {
    const tasksMap = getState().tasks.tasks;
    const originalTask = tasksMap[task.task_id];
    const payload = prepareTaskStatusUpdatePayload(change);

    try {
      dispatch(startUpdateTask(task));
      const response = await API3.updateTaskStatusForRepeatingTask(
        payload,
        originalTask.task_id,
      );

      batch(() => {
        response.forEach((updatedTask) => {
          const taskExists = !!tasksMap[updatedTask.task_id];
          updatedTask.createdTs = generateId();

          if (taskExists) {
            return dispatch(updateTaskSuccess(updatedTask, originalTask));
          }

          return dispatch(createTaskSuccess(updatedTask));
        });
      });
    } catch (error) {
      console.error(error);
      dispatch(updateTaskFailure(error, task, originalTask));
    }
  };

function retainIntegrationLinkIfApplicable(task, taskLinks = {}) {
  if (!task.originalId) return;

  const pmKey = taskLinks?.[task.originalId]?.ext_resource_id;
  if (pmKey) {
    task.pmKey = pmKey;
  }
}

export const duplicateTask = (task) => (dispatch, getState) => {
  retainIntegrationLinkIfApplicable(task, getState().pmSidebar?.taskLinks);
  return dispatch(createTask(task));
};

export const duplicateTaskFromDrag = (task) => async (dispatch, getState) => {
  retainIntegrationLinkIfApplicable(task, getState().pmSidebar?.taskLinks);
  return dispatch(createTask(task, true));
};
