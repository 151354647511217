import { ProjectBudgetType } from '@float/constants/projects';
import { Project } from '@float/types/project';
import { parseNumber } from '@float/ui/helpers/number/numberParser';

export const useValueOverride = (project: Project) => {
  const defaultHourlyRate = parseNumber(project.default_hourly_rate);
  const readOnlyValue =
    typeof defaultHourlyRate === 'number'
      ? project.default_hourly_rate || ''
      : undefined;
  const disabledValue =
    project.budget_type === ProjectBudgetType.Disabled ? '0' : undefined;
  return {
    valueOverride: disabledValue || readOnlyValue,
    isDisabled: Boolean(disabledValue),
  };
};
