import React from 'react';
import { Trans } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { AvatarImage } from '@float/common/components/Avatar';
import { getProjectById } from '@float/common/selectors/projects';
import { useAppSelectorWithParams } from '@float/common/store';
import { LoggedTimeCell, PersonCell, ProjectCell } from '@float/types/cells';
import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { IconHat } from '@float/ui/icons/essentials/IconHat';

import { getPersonStatusToday } from '../PersonCard';
import { PersonRateField } from './PersonRateField';
import { SingleProjectPersonCardMenu } from './SingleProjectPersonCardMenu';

import * as projectCardStyles from '../ProjectCard.css';
import * as singleProjectHeaderStyles from './SingleProjectHeader.css';

export const SingleProjectPersonCard = ({
  person,
  currentWeekCell,
  dayOfWeek,
  projectId,
  actions,
}: {
  person: Person;
  currentWeekCell: ProjectCell | PersonCell | LoggedTimeCell | undefined;
  dayOfWeek: number;
  projectId: number;
  actions: {
    showPersonModal: (person: Person) => void;
    showSwitchPersonModal: (modalSettings: {
      person: Person;
      project: Project;
    }) => void;
    removeFromProjects: (person: Person, projects: Project[]) => void;
  };
}) => {
  const project = useAppSelectorWithParams(getProjectById, projectId);
  if (!project) return null;
  const projectColor = project.color.startsWith('#')
    ? project.color
    : `#${project.color}`;
  const personStatusToday = getPersonStatusToday(currentWeekCell, dayOfWeek);

  return (
    <div className={projectCardStyles.wrapper} data-interactive={true}>
      <div
        className={singleProjectHeaderStyles.content}
        style={assignInlineVars({
          [singleProjectHeaderStyles.contentBgColorVar]: projectColor,
        })}
      >
        <div className={singleProjectHeaderStyles.teamMemberColumn}>
          <div className={singleProjectHeaderStyles.avatarImage}>
            <AvatarImage
              name={person.name}
              imageUrl={person.avatar_file}
              size="xs2"
              statusText={personStatusToday.name}
              statusColor={personStatusToday.color}
            />
          </div>
          <span className={singleProjectHeaderStyles.personName}>
            {person.name}
          </span>
        </div>
        <div className={singleProjectHeaderStyles.roleColumn}>
          <div className={singleProjectHeaderStyles.roleIcon}>
            <IconHat size={16} />
          </div>
          <Trans>Role</Trans>
        </div>
        <div className={singleProjectHeaderStyles.costRateColumn}>
          <Trans>$ 0</Trans>
        </div>
        <div className={singleProjectHeaderStyles.billRateColumn}>
          <PersonRateField project={project} person={person} />
        </div>
        <div className={singleProjectHeaderStyles.allocationColumn}>
          <Trans>0 h</Trans>
        </div>
        <div>
          <SingleProjectPersonCardMenu
            person={person}
            project={project}
            actions={actions}
          />
        </div>
      </div>
    </div>
  );
};
