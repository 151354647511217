import styled, { css } from 'styled-components';

import * as Colors from '@float/common/earhart/colors';
import { MediaSize } from '@float/constants/media';
import EH from '@float/ui/deprecated/Earhart';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

export const StyledContainer = styled.div`
  --layout-safe-margins: 36px;

  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 100%;

  background-color: ${Colors.FIN.Lt.Surface.Surf2};

  box-sizing: border-box;

  ${respondTo(
    MediaSize.Small,
    '<=',
  )(css`
    --layout-safe-margins: 20px;
  `)}

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  header {
    position: absolute;

    top: 0;
    left: 0;

    z-index: 100;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  --top-padding: calc(var(--layout-safe-margins) * 2);

  // 40px because it's the StyledHeaderLeft and StyledHeaderRight height
  padding: calc(var(--top-padding) + 40px) var(--layout-safe-margins);
`;

export const StyledCompanyGroup = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1100px;

  & + & {
    margin-top: 80px;
  }

  > p {
    ${EH.Typography.Label14.M500};

    color: ${EH.Colors.FIN.Lt.Emphasis.Medium};

    & + * {
      margin-top: 10px;
    }
  }
`;

export const StyledCompanyList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 32px;

  ${respondTo(MediaSize.Medium)(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${respondTo(
    MediaSize.Small,
    '<=',
  )(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
