import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const R400 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
`;

export const R400NoUnderline = css`
  ${R400}
  border-bottom-color: transparent !important;
`;

export const M500 = css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0px;
`;

export const SB600 = css`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0px;
`;

export const VE_R400: StyleRule = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1.43,
};

export const VE_M500: StyleRule = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.43,
  letterSpacing: 0,
};

export const VE_SB600: StyleRule = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 1.43,
  letterSpacing: 0,
};
