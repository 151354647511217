import { useIsSingleProjectPlanView } from '@float/common/serena/util/getIsSingleProjectPlanView';
import { ScheduleViewType } from '@float/constants/schedule';
import { Project } from '@float/types/project';

export const useCTAState = (
  viewType: ScheduleViewType,
  showProjectsToggle: boolean,
  singleProjectViewProject: Project | undefined,
) => {
  const isSingleProjectView = viewType === ScheduleViewType.SingleProject;
  const isProjectsView = viewType === ScheduleViewType.Projects;
  const { isSingleProjectPlanView, project: singleProjectPlanProject } =
    useIsSingleProjectPlanView();
  const hasAssignTeamMemberDropdown =
    (isSingleProjectPlanView && singleProjectPlanProject?.canEdit) ||
    (isSingleProjectView && singleProjectViewProject?.canEdit);
  const assignTeamMemberDropdownProject = hasAssignTeamMemberDropdown
    ? singleProjectViewProject || singleProjectPlanProject
    : undefined;
  const hasCollapseToggle =
    isProjectsView &&
    !hasAssignTeamMemberDropdown &&
    showProjectsToggle &&
    !isSingleProjectView;
  return {
    hasCollapseToggle,
    assignTeamMemberDropdownProject,
  };
};
