import React from 'react';

import { MODES } from '@float/ui/deprecated/Chart';

import { SummaryBarCombined } from './SummaryBarCombined/SummaryBarCombined';
import { SummaryBarLogged } from './SummaryBarLogged/SummaryBarLogged';
import { SummaryBarLoggedVsScheduled } from './SummaryBarLoggedVsScheduled/SummaryBarLoggedVsScheduled';
import { SummaryBarScheduled } from './SummaryBarScheduled/SummaryBarScheduled';
import type { SummaryBarProps } from './types';

const SummaryBar = (props: SummaryBarProps) => {
  if (props.mode === MODES.COMBINED) {
    return <SummaryBarCombined {...props} />;
  }

  if (props.mode === MODES.SCHEDULED) {
    return <SummaryBarScheduled {...props} />;
  }

  if (props.mode === MODES.LOGGED) {
    return <SummaryBarLogged {...props} />;
  }

  if (props.mode === MODES.COMPARE) {
    return <SummaryBarLoggedVsScheduled {...props} />;
  }

  return null;
};

export default SummaryBar;
