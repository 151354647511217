import {
  createListenerMiddleware,
  Dispatch,
  ListenerEffectAPI,
} from '@reduxjs/toolkit';
import client from 'socketcluster-client';
import { createActor, Subscription } from 'xstate';

import {
  GET_JWT_SUCCESS,
  SEARCH_ADD_FILTERS,
  SEARCH_CLEAR_REMOVED_FILTERS,
  SEARCH_REMOVE_ALL_FILTERS,
  SEARCH_REMOVE_FILTER,
  SEARCH_SET_FILTERS,
  USER_PREFS_MULTI_UPDATE,
  USER_PREFS_UPDATE,
} from '@float/common/actions';
import {
  LOGGED_TIME_BULK_CREATED,
  LOGGED_TIME_CREATED,
} from '@float/common/actions/loggedTimes';
import { CREATE_TASK_SUCCESS, SPLIT_TASK } from '@float/common/actions/tasks';
import { CREATE_TIMEOFF_SUCCESS } from '@float/common/actions/timeoffsConstants';
import { VIEW_APPLIED, VIEW_UNAPPLY } from '@float/common/actions/views';
import { SearchResolveContext } from '@float/common/api3/search.types';
import { searchServiceReady } from '@float/common/reducers/search';
import { getIsLogTimeView } from '@float/common/selectors/appInfo/scheduleView';
import { getUser } from '@float/common/selectors/currentUser';
import { selectIsSearchContextLoaded } from '@float/common/selectors/search';
import { getActiveFilters } from '@float/common/selectors/views';
import {
  searchFiltersClearedAction,
  searchResolveStartAction,
  searchResultsUpdateAction,
} from '@float/common/store/searchResults/searchResults.actions';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import type { AllActionsStrict } from '@float/common/reducers';

import { SearchWorkerReduxState } from '../worker/searchStore';
import { SearchRemoteResolveMachine } from './SearchRemoteResolveMachine';

// @see: https://redux-toolkit.js.org/api/createListenerMiddleware
export const searchRemoteResolveListener = createListenerMiddleware<
  SearchWorkerReduxState,
  Dispatch<AllActionsStrict>
>();

const listenedActions = new Set<string>([
  SEARCH_ADD_FILTERS,
  SEARCH_CLEAR_REMOVED_FILTERS,
  SEARCH_REMOVE_ALL_FILTERS,
  SEARCH_REMOVE_FILTER,
  SEARCH_SET_FILTERS,
  VIEW_APPLIED,
  VIEW_UNAPPLY,
  USER_PREFS_UPDATE, // To capture changes to the "me" filter
  USER_PREFS_MULTI_UPDATE, // To capture changes to the "me" filter
  searchResolveStartAction.type, // To run initial resolve after the user is fetched
]);

const listenedActionsForResolveRevalidation = new Set<string>([
  CREATE_TASK_SUCCESS,
  SPLIT_TASK,
  CREATE_TIMEOFF_SUCCESS,
  LOGGED_TIME_BULK_CREATED,
  LOGGED_TIME_CREATED,
]);

function createSocket() {
  return client.create({
    secure: true,
    path: '/svc/search-context-ws/socketcluster/',
    autoReconnect: true,
  });
}

export function startListeningSearchRemoteResolve() {
  const searchRemoteResolveActor = createActor(SearchRemoteResolveMachine, {
    input: {
      socket: createSocket(),
    },
  });
  searchRemoteResolveActor.start();

  const unsubscribeAuthUpdates = searchRemoteResolveListener.startListening({
    predicate: (action) => action.type === GET_JWT_SUCCESS,
    effect: (_, listenerApi) => {
      const state = listenerApi.getState();
      const accessToken = state.jwt.accessToken;

      if (!accessToken) return;

      searchRemoteResolveActor.send({
        type: 'authTokenReceived',
        payload: accessToken,
      });
    },
  });

  let subscription: Subscription | null = null;

  // Listen to all the search filters related actions to trigger
  // a search resolution whenever a the filters changes and subscribe
  // to the search context WebSocket
  const unsubscribeSearchUpdates = searchRemoteResolveListener.startListening({
    // Check if an action should trigger the effect
    predicate: (action, currentState, originalState) => {
      if (listenedActions.has(action.type)) return true;

      if (getIsLogTimeView(currentState) !== getIsLogTimeView(originalState)) {
        return true;
      }

      return false;
    },

    // The effect stops the previous runs and requests a new search resolution
    effect: async (_, listenerApi) => {
      const state = listenerApi.getState();
      const filters = getActiveFilters(state);
      const isLogTimeView = getIsLogTimeView(state);

      if (subscription) {
        // We unsubscribe to avoid causing memory leaks
        subscription.unsubscribe();
      }

      // We subscribe here to get access to the Redux dispatch
      subscription = searchRemoteResolveActor.on(
        'searchResultsUpdated',
        ({ payload }) => {
          listenerApi.dispatch(searchResultsUpdateAction(payload));
          markSearchServiceAsReady(listenerApi);
        },
      );

      if (filters.length === 0) {
        searchRemoteResolveActor.send({
          type: 'searchParamsUpdatedWithNoFilters',
        });
        markSearchServiceAsReady(listenerApi);
        listenerApi.dispatch(searchFiltersClearedAction());
      } else {
        searchRemoteResolveActor.send({
          type: 'searchParamsUpdated',
          payload: {
            filters,
            context: isLogTimeView
              ? SearchResolveContext.LoggedTime
              : SearchResolveContext.Schedule,
            user: getUser(state),
          },
        });
      }
    },
  });

  // Listen to all the search filters related actions to trigger
  // a search resolution whenever a the filters changes and subscribe
  // to the search context WebSocket
  const unsubscribeResolveRevalidation =
    searchRemoteResolveListener.startListening({
      predicate: (action) =>
        listenedActionsForResolveRevalidation.has(action.type),

      // Forces the search context to be re-fetched
      effect: async () => {
        if (
          featureFlags.isFeatureEnabled(
            FeatureFlag.SearchBeyondLimitsDisableRevalidation,
          )
        ) {
          return;
        }

        // The revalidation is required because when the new entity doesn't match
        // the filters the search context won't send us an update (because nothing changed in the results).
        // Since we are optimistically adding the new entity to the search results we need to revalidate
        // to verify if the entity matches the active filters or not.
        searchRemoteResolveActor.send({
          type: 'searchContextRevalidationRequested',
        });
      },
    });

  function markSearchServiceAsReady(
    listenerApi: ListenerEffectAPI<
      SearchWorkerReduxState,
      Dispatch<AllActionsStrict>,
      unknown
    >,
  ) {
    if (!selectIsSearchContextLoaded(listenerApi.getState())) {
      listenerApi.dispatch(searchServiceReady());
    }
  }

  // Unsubscribe function
  return () => {
    subscription?.unsubscribe();
    searchRemoteResolveActor.stop();
    unsubscribeSearchUpdates();
    unsubscribeAuthUpdates();
    unsubscribeResolveRevalidation();
  };
}
