import { t } from '@lingui/macro';

import Department from '../Department';

export function getBulkEditDepartmentField() {
  return {
    label: t`Department`,
    render: Department,
  };
}
