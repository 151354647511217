import React, { StrictMode, useRef } from 'react';
import { Trans } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';
import type { ReactNode } from 'react';

import { useEventListenerOnElement } from '@float/libs/hooks/useEventListenerOnElement';
import { Tag } from '@float/ui/components/Tag';
import { Tooltip } from '@float/ui/components/Tooltip';
import { IconCollapse } from '@float/ui/icons/essentials/IconCollapse';
import { IconExpand } from '@float/ui/icons/essentials/IconExpand';
import { IconPlusCircle } from '@float/ui/icons/essentials/IconPlusCircle';
import { IconUsers } from '@float/ui/icons/essentials/IconUsers';

import * as styles from './ProjectCard.css';

type TeamCountProps = {
  visiblePeople: number;
  totalPeople: number;
};

export type ProjectCardTeamProps = TeamCountProps & {
  isCollapsed: boolean;
  isCollapseDisabled?: boolean;
  isCompact: boolean;
  isSharedLink: boolean;
  rowHeight: number;
  onAdd: EventListener;
  onToggle: EventListener;
};

type TeamTooltipProps = TeamCountProps & {
  children: ReactNode;
};

function TeamTooltip(props: TeamTooltipProps) {
  const { children, visiblePeople, totalPeople } = props;

  if (visiblePeople === totalPeople) return children;

  return (
    <Tooltip
      placement="right"
      content={
        <Trans>
          {visiblePeople} of the {totalPeople} people on the project team are
          visible here
        </Trans>
      }
    >
      {children}
    </Tooltip>
  );
}

export function ProjectCardTeam(props: ProjectCardTeamProps) {
  const toggleRef = useRef<HTMLDivElement>(null);
  const addButtonRef = useRef<HTMLDivElement>(null);
  const { visiblePeople, totalPeople } = props;
  const textColor = !visiblePeople ? 'disabled' : 'primary';
  const iconClassName = styles.collapsedMenuBtnIcon({ color: textColor });
  const peopleLabelVars = {
    [styles.rowHeightVar]: `${props.rowHeight}px`,
    [styles.fontSizeVar]: props.isCompact ? '12px' : '13px',
  };

  // using onClick directly on the menu button element does not prevent the propagation
  // to the parent elements event listener which is registered by addEventListener.
  // so we need to do the same on the menu icon to stop the propagation.
  useEventListenerOnElement(toggleRef, 'click', props.onToggle);
  useEventListenerOnElement(addButtonRef, 'click', props.onAdd);
  return (
    <StrictMode>
      <div
        className={cn(styles.collapsedMenuBtn)}
        style={assignInlineVars(peopleLabelVars)}
        ref={toggleRef}
      >
        <TeamTooltip visiblePeople={visiblePeople} totalPeople={totalPeople}>
          <div className={styles.projectTeam}>
            <IconUsers className={iconClassName} size={20} />
            <Tag
              data-testid="project-people-tag"
              color={textColor}
              size="small"
            >
              {visiblePeople}
            </Tag>
          </div>
        </TeamTooltip>

        {props.isCollapsed ? (
          <IconExpand className={iconClassName} />
        ) : (
          <IconCollapse
            className={cn(
              iconClassName,
              styles.collapsedMenuBtnIcon({
                color: props.isCollapseDisabled ? 'disabled' : 'primary',
              }),
            )}
          />
        )}
      </div>

      {!props.isSharedLink && !visiblePeople && (
        <div
          className={styles.assignPeopleMenuBtn}
          style={assignInlineVars(peopleLabelVars)}
          ref={addButtonRef}
        >
          <IconPlusCircle /> <Trans>Assign people</Trans>
        </div>
      )}
    </StrictMode>
  );
}
