import { Context, useContext } from 'use-context-selector';

/**
 * Drop in replacement for React's `useContext`. Used to reduce the need
 * for null checks and to give error feedback when context are used with
 * their defaults values (i.e. before being initialized with a provider)
 */
export function useSafeContext<T>(
  context: Context<T | null>,
  contextName: string = 'unknown',
): T {
  const something = useContext(context);

  if (something === null) {
    throw new Error(
      `Attempted to access a context, "${contextName}", that has not been fully initialized — this should not happen. 
Check the React provider tree to make sure context providers are being applied correctly.
If you are seeing this error in a test environment, try using the \`render\` method exported from \`testUtils\``,
    );
  }

  return something;
}

/**
 * Only for rare cases where running the context with default values is not a concern
 */
export const useUnsafeContext = useContext;
