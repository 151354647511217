import { t } from '@lingui/macro';

import PublicHolidays from '../PublicHolidays';

export function getBulkEditRegionField() {
  return {
    label: t`Public holidays`,
    render: PublicHolidays,
  };
}
