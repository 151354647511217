import React from 'react';
import { Trans } from '@lingui/macro';
import cs from 'classnames';

import * as styles from '../../EditTaskModal.css';

export type InputAllocationTimeRangeHeaderProps = {
  allocationTypeSelect: React.ReactNode;
};

export const InputAllocationTimeRangeHeader = (
  props: InputAllocationTimeRangeHeaderProps,
) => {
  const { allocationTypeSelect } = props;

  const hasAllocationTypeControlInHeader =
    typeof allocationTypeSelect !== 'undefined';

  return (
    <div
      className={cs(
        styles.timeSectionColumnHeader,
        hasAllocationTypeControlInHeader &&
          styles.timeSectionColumnHeaderWithLeadingButton,
      )}
    >
      {hasAllocationTypeControlInHeader && (
        <div className={styles.timeSectionColumnHeaderCell}>
          {allocationTypeSelect}
        </div>
      )}
      <div className={styles.timeSectionColumnHeaderCell}>
        <label className={styles.fieldLabel}>
          <Trans>Time</Trans>
        </label>
      </div>
    </div>
  );
};
