import { USER_PREFS_MULTI_UPDATE } from '@float/common/actions';
import { getPrefs } from '@float/common/selectors/currentUser';
import {
  useAppDispatchStrict,
  useAppSelectorStrict,
} from '@float/common/store';
import { SingleProjectSortColumn } from '@float/types/account';

export const useUpdateColumnSort = () => {
  const dispatch = useAppDispatchStrict();
  const { single_project_view_sort_by, single_project_view_sort_dir } =
    useAppSelectorStrict(getPrefs);

  const updateColumnSort = (column: SingleProjectSortColumn) => {
    dispatch({
      type: USER_PREFS_MULTI_UPDATE,
      prefsObj: {
        single_project_view_sort_by: column,
        single_project_view_sort_dir:
          single_project_view_sort_dir === 'asc' ? 'desc' : 'asc',
      },
    });
  };

  return {
    updateColumnSort,
    sortBy: single_project_view_sort_by,
    sortDir: single_project_view_sort_dir,
  };
};
