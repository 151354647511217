import React, { StrictMode, useMemo } from 'react';

import { useScheduleContext } from '@float/common/serena/ScheduleContext';

import { buildMonths } from './TopRowMonths.helpers';

import * as styles from './TopRowMonths.css';

export type TowRowMonthsProps = {
  startDate: DateString;
  endDate: DateString;
  colWidth: number;
  dayWidth: number;
  baseColOffset: number;
  topColumnStart: number;
  topColumnEnd: number;
  numDaysDisplayedInWeek: number;
};

export const TopRowMonths: React.FC<TowRowMonthsProps> = ({
  startDate,
  endDate,
  numDaysDisplayedInWeek,
  baseColOffset,
  colWidth,
  dayWidth,
}) => {
  const { dates } = useScheduleContext();

  const months = useMemo(
    () =>
      buildMonths({
        dates,
        startDate,
        endDate,
        numDaysDisplayedInWeek,
        baseColOffset,
        colWidth,
        dayWidth,
      }),
    [
      dates,
      startDate,
      endDate,
      numDaysDisplayedInWeek,
      baseColOffset,
      colWidth,
      dayWidth,
    ],
  );

  return (
    <StrictMode>
      <div
        className={styles.allMonthsWrapper}
        data-testid="TopRowMonthsWrapper"
      >
        {months.map((month, index) => (
          <div
            key={index}
            style={{
              left: month.left,
              width: month.width,
            }}
            className={styles.monthWrapper}
            role="label"
          >
            {month.name}
          </div>
        ))}
      </div>
    </StrictMode>
  );
};
