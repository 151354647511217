import React from 'react';
import { Trans } from '@lingui/macro';

import { useGetCellStylesConfig } from '@float/common/lib/cellStylesManager/utils/useGetCellStylesConfig';
import { CellItem } from '@float/types/cells';
import { CompanyPreferences } from '@float/types/companyPreferences';

import { TextElements } from '../../../MainCell/Item/box/BoxElements/TextElements';
import { ScheduleActions } from '../../../MainCell/types';

import * as styles from './EntitiesGroupItem.css';

export type EntitiesGroupItemProps = {
  item: CellItem<'task' | 'timeoff'>;
  actions: ScheduleActions;
};

export const EntitiesGroupItem = (props: EntitiesGroupItemProps) => {
  const { item, actions } = props;

  const { color } = useGetCellStylesConfig({
    item,
    actionMode: actions.actionMode,
    getOverrideCursor: actions.getOverrideCursor,
    isItemSelected: actions.isItemSelected,
    linkInfo: actions.linkInfo,
    isPrintMode: false,
  });

  const entityHours = item.entity.hours || 0;

  return (
    <button
      className={styles.entityWrapper}
      onClick={() => actions.onItemClick(item)}
    >
      <dt className={styles.entity}>
        {item.type === 'task' && (
          <span
            className={styles.entityDot}
            style={{ background: `#${color}` }}
          ></span>
        )}

        <TextElements
          entity={item.entity}
          numRows={1}
          logTimeView={false}
          iconGroupWidth={0}
          companyPrefs={
            {
              showTaskIcons: false,
            } as unknown as CompanyPreferences
          }
        />
      </dt>
      <dd>{entityHours > 0 && <Trans>{entityHours}h</Trans>}</dd>
    </button>
  );
};
