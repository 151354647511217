import React, { useCallback, useEffect, useRef } from 'react';
import { t } from '@lingui/macro';

import { DEFAULT_AVATAR_URL } from '@float/constants/avatar';
import { getInitials } from '@float/libs/utils/strings';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import { StatusIcon } from '../StatusIcon/StatusIcon';
import AvatarDefault from './avatar-default';
import { PlaceholderAvatar } from './PlaceholderAvatar/PlaceholderAvatar';
import { AVATAR_SIZES, StatusIconContainer, StyledAvatar } from './styles';

const getAvatarWidth = (size) => {
  const opt = AVATAR_SIZES[size];
  if (!opt) {
    return AVATAR_SIZES.sm.width;
  }

  return opt.width;
};

export const AvatarImage = ({
  className = '',
  name,
  overrideInitials = '',
  imageUrl,
  size = 'xs',
  style = {},
  tooltip = null,
  statusText = '',
  statusColor = '',
  disabled = false,
  isNewPlaceholderType = false,
}) => {
  const imageRef = useRef();

  const initials = overrideInitials || getInitials(name);
  const width = getAvatarWidth(size);
  const isDefaultAvatar = imageUrl === DEFAULT_AVATAR_URL;
  const useAvatarUrl = imageUrl && !isDefaultAvatar;

  let avatarComponent;

  const colorComponent = !statusColor ? null : (
    <StatusIconContainer color="#f7f7f7" size={8}>
      <StatusIcon color={statusColor} size={6} />
    </StatusIconContainer>
  );

  const setAvatarLoaded = useCallback(() => {
    imageRef.current.classList.add('loaded');
  }, []);

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setAvatarLoaded();
    }
  }, [setAvatarLoaded]);

  if (isNewPlaceholderType) {
    avatarComponent = (
      <StyledAvatar
        title={t`Placeholder`}
        size={size}
        style={style}
        disabled={disabled}
      >
        <PlaceholderAvatar />
      </StyledAvatar>
    );
  } else if (useAvatarUrl || initials) {
    avatarComponent = (
      <StyledAvatar
        className={className}
        size={size}
        title={tooltip ? name : ''}
        style={style}
        disabled={disabled}
      >
        {useAvatarUrl && (
          <img
            ref={imageRef}
            src={imageUrl}
            loading="lazy"
            onLoad={setAvatarLoaded}
          />
        )}
        <span className="initials">{initials}</span>
        {colorComponent}
      </StyledAvatar>
    );
  } else {
    avatarComponent = (
      <StyledAvatar
        className={className}
        size={size}
        style={style}
        disabled={disabled}
      >
        <AvatarDefault size={width} />
        {colorComponent}
      </StyledAvatar>
    );
  }

  if (statusText) {
    return (
      <TextTooltip
        className="hint"
        content={statusText}
        placement="bottom"
        distance={10}
      >
        {avatarComponent}
      </TextTooltip>
    );
  }

  return avatarComponent;
};

export default AvatarImage;
