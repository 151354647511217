import { t } from '@lingui/macro';
import { isUndefined, sortBy } from 'lodash';

import { toCents } from '@float/common/lib/budget';
import { formatAmount } from '@float/common/lib/budget/helpers/formatAmount';
import { moment } from '@float/libs/moment';
import { MODES } from '@float/ui/deprecated/Chart';

import tippyContentCreator from '../components/LineChartTippy';

const MAX_TICKS = 16;

const hasReferenceLine = (type) => type === 1 || type === 2;

function shouldIncludeLoggedTime(item) {
  const today = moment();
  return moment(item.day).isBefore(today, 'day');
}

function getLoggedChartItem(item, pastOnly = false) {
  if (pastOnly && !shouldIncludeLoggedTime(item)) {
    return null;
  }

  const {
    total = 0,
    budget_used = 0,
    budget_remaining = 0,
    hours_remaining,
  } = item.logged_time || {};
  const data = {
    value: +budget_used,
    logged_hours: +total,
    budget_remaining: +budget_remaining,
  };

  if (!isUndefined(hours_remaining)) {
    data.hours_remaining = +(hours_remaining || 0);
  }

  return data;
}

function getScheduledChartItem(item) {
  const data = {
    value: +item.budget_used,
    scheduled_hours: +item.scheduled,
    budget_remaining: +item.budget_remaining,
  };

  if (!isUndefined(item.hours_remaining)) {
    data.hours_remaining = +(item.hours_remaining || 0);
  }

  return data;
}

function getCompareChartItem(item) {
  const scheduled = getScheduledChartItem(item);
  const logged = getLoggedChartItem(item);
  let loggedData = {};

  if (logged) {
    loggedData = {
      logged_hours: logged.logged_hours || 0,
      logged_budget_remaining: logged.budget_remaining || 0,
    };

    if (!isUndefined(logged.hours_remaining)) {
      loggedData.logged_hours_remaining = logged.hours_remaining;
    }
  }

  const data = {
    values: [scheduled.value, (logged && logged.value) || 0],
    scheduled_hours: scheduled.scheduled_hours,
    scheduled_budget_remaining: scheduled.budget_remaining,
    ...loggedData,
  };

  if (!isUndefined(scheduled.hours_remaining)) {
    data.scheduled_hours_remaining = scheduled.hours_remaining;
  }

  return data;
}

function getCombinedChartItem({ item, futureAdjustment, isMoneyBudgetType }) {
  const scheduled = getScheduledChartItem(item);
  const logged = getLoggedChartItem(item, true);
  if (logged) {
    return logged;
  }

  if (isMoneyBudgetType) {
    return {
      value: (toCents(scheduled.value) + toCents(futureAdjustment.value)) / 100,
      scheduled_hours:
        scheduled.scheduled_hours + futureAdjustment.scheduled_hours,
      budget_remaining:
        (toCents(scheduled.budget_remaining) +
          toCents(futureAdjustment.budget_remaining)) /
        100,
      hours_remaining:
        scheduled.hours_remaining + futureAdjustment.hours_remaining,
    };
  }

  return {
    value: scheduled.value + futureAdjustment.value,
    scheduled_hours:
      scheduled.scheduled_hours + futureAdjustment.scheduled_hours,
    budget_remaining:
      scheduled.budget_remaining + futureAdjustment.budget_remaining,
  };
}

function getChartItemByMode({
  item,
  mode,
  futureAdjustment,
  isMoneyBudgetType,
}) {
  switch (mode) {
    case MODES.COMBINED:
      return getCombinedChartItem({
        item,
        futureAdjustment,
        isMoneyBudgetType,
      });
    case MODES.LOGGED:
      return getLoggedChartItem(item);
    case MODES.SCHEDULED:
      return getScheduledChartItem(item);
    case MODES.COMPARE:
      return getCompareChartItem(item);
    default:
      return {};
  }
}

function getFutureAdjustment({ data, mode, isMoneyBudgetType }) {
  const adjust = {
    value: 0,
    scheduled_hours: 0,
    budget_remaining: 0,
    hours_remaining: 0,
  };
  if (mode !== MODES.COMBINED) {
    return adjust;
  }

  const yesterday = moment().add(-1, 'days');
  const soFar = data.chartItems.find((d) =>
    moment(d.day).isSame(yesterday, 'day'),
  );
  if (!soFar) {
    return adjust;
  }

  const pastScheduled = getScheduledChartItem(soFar);
  const pastLogged = getLoggedChartItem(soFar) || {};

  adjust.scheduled_hours =
    pastLogged.logged_hours - pastScheduled.scheduled_hours;

  if (isMoneyBudgetType) {
    adjust.value =
      (toCents(pastLogged.value) - toCents(pastScheduled.value)) / 100;
    adjust.budget_remaining =
      (toCents(pastLogged.budget_remaining) -
        toCents(pastScheduled.budget_remaining)) /
      100;
    adjust.hours_remaining =
      pastLogged.hours_remaining - pastScheduled.hours_remaining;
  } else {
    adjust.value = pastLogged.value - pastScheduled.value;
    adjust.budget_remaining =
      pastLogged.budget_remaining - pastScheduled.budget_remaining;
  }

  return adjust;
}

function getFeeOrHoursBasedOnAccess(fee, hours, hasBudgetAccess) {
  const fallback = hasBudgetAccess ? 0 : hours;
  return toCents(fee || fallback);
}

const getLineChartData = (project, budgets, data, mode, currencySymbol) => {
  if (!project || !data || !data.chartItems) return {};

  const datapoints = [];
  const { isMoneyBudgetType } = project;
  const futureAdjustment = getFutureAdjustment({
    data,
    mode,
    isMoneyBudgetType,
  });

  data.chartItems.forEach((i) => {
    const itemData = getChartItemByMode({
      item: i,
      mode,
      isMoneyBudgetType,
      futureAdjustment,
    });
    if (itemData) {
      datapoints.push({
        key: i.day,
        date: moment(i.day).toDate(),
        ...itemData,
      });
    }
  });

  data.chartItems = sortBy(data.chartItems, 'date');

  if (!datapoints.length) {
    const firstItem = data.chartItems.length
      ? data.chartItems[0]
      : { day: moment().format('YYYY-MM-DD') };

    // because LineChart component relies on at least one datapoint existing
    datapoints.push({
      key: firstItem.day,
      date: moment(firstItem.day).toDate(),
      value: 0,
    });
  }

  const chartData = {
    xTickFormat: (d) => moment(d).format('DD MMM'),
    xMaxTicks: MAX_TICKS,
    yTickFormat: (v) => formatAmount(null, v), // passing null budget type because we don't want to show currency in y-axis label
    TippyContent: tippyContentCreator(project, mode),
    datapoints,
  };

  if (hasReferenceLine(project.budget_type)) {
    const projectBudgetData = budgets.projects[project.project_id] || {};
    const projectBudgetTotal = projectBudgetData.budget_total;

    const projectBudgetTotalFormatted = formatAmount(null, projectBudgetTotal);
    const referenceLineText = isMoneyBudgetType
      ? t`Project budget: ${projectBudgetTotalFormatted} ${currencySymbol}`
      : t`Project budget: ${projectBudgetTotalFormatted} h`;

    chartData.referenceLine = {
      value: projectBudgetTotal,
      text: referenceLineText,
    };
  }

  return chartData;
};

// This method's purpose is to transform the bar chart data into the format we
// used to use for the line chart. While the bar chart has data per day, the
// line chart is a continuous sum of that data. We're using the old format in
// the interest of time to not have to refactor the LineChart. Target format:
// "{
//   "day": "2020-06-19",
//   "scheduled": 16,
//   "budget_used": "16.0000",
//   "budget_remaining": "1095.0000",
//   "logged_time": {
//     "total": 0,
//     "budget_used": "0.0000",
//     "budget_remaining": "1111.0000",
//   },
// }"
function toSeriesSum(
  project,
  chartData,
  mode,
  firstDate,
  timeTrackingEnabled,
  hasBudgetAccess,
  budgets,
) {
  let { baseline: bl, datapoints: data } = chartData;

  const projectBudgetData = budgets.projects[project.project_id];
  const projectBudgetTotal = projectBudgetData?.budget_total ?? 0;

  data = sortBy(data, 'date');
  const chartItems = [];

  const baseline = {
    scheduled: 0,
    budget_used: 0,
    logged_time: {
      total: 0,
      budget_used: 0,
    },
  };

  // budget_used should reflect the FEE incurred and not the BILLABLE HOURS
  // falling back to billable hours causes unexpected results if the fee is legitimately 0
  // but there are billable hours scheduled. (ie. if only one person with a rate of $0/h is
  // scheduled for 5 hours, the budget used is $0 but falling back to billable hours would
  // result in adding $5 to the budget used)
  // However, if budget and rates are inaccessible by user, we fall back to billable hours
  // to show hours-based line chart.
  baseline.budget_used += getFeeOrHoursBasedOnAccess(
    bl.fees.scheduled,
    bl.billable + bl.tentative.billable,
    hasBudgetAccess,
  );
  baseline.scheduled += bl.billable + bl.tentative.billable;

  if (timeTrackingEnabled) {
    baseline.logged_time.budget_used = getFeeOrHoursBasedOnAccess(
      bl.fees.logged,
      bl.logged.billable,
      hasBudgetAccess,
    );
    baseline.logged_time.total += bl.logged.billable;
  }

  if (project.non_billable) {
    baseline.scheduled += bl.nonbillable + bl.tentative.nonbillable;
    if (timeTrackingEnabled) {
      baseline.logged_time.total += bl.logged.nonbillable;
    }
  }

  if (!data.length) {
    return { chartItems };
  }

  chartItems.push({
    day: firstDate,
    scheduled: baseline.scheduled,
    budget_used: baseline.budget_used,
    budget_remaining: toCents(projectBudgetTotal) - baseline.budget_used,
    logged_time: {
      total: baseline.logged_time.total,
      budget_used: baseline.logged_time.budget_used,
      budget_remaining:
        toCents(projectBudgetTotal) - baseline.logged_time.budget_used,
    },
  });

  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    const prev = chartItems[chartItems.length - 1];

    // scheduledFee should be the scheduled fee and not the billable hours
    // unless budget and rates are inaccessible by user
    const scheduledFee = getFeeOrHoursBasedOnAccess(
      d.fees.scheduled,
      d.billable + d.tentative.billable,
      hasBudgetAccess,
    );

    const item = {
      day: d.date,
      scheduled: prev.scheduled + d.billable + d.tentative.billable,
      budget_used: prev.budget_used + scheduledFee,
      budget_remaining: prev.budget_remaining - scheduledFee,
    };

    if (timeTrackingEnabled) {
      const loggedFee = getFeeOrHoursBasedOnAccess(
        d.fees.logged,
        d.logged.billable,
        hasBudgetAccess,
      );
      item.logged_time = {
        total: prev.logged_time.total + d.logged.billable,
        budget_used: prev.logged_time.budget_used + loggedFee,
        budget_remaining: prev.logged_time.budget_remaining - loggedFee,
      };
    }

    if (project.non_billable) {
      item.scheduled += d.nonbillable + d.tentative.nonbillable;
      if (timeTrackingEnabled) {
        item.logged_time.total += d.logged.nonbillable;
      }
    }

    chartItems.push(item);
  }

  chartItems.forEach((item) => {
    item.budget_used = String(item.budget_used / 100);
    item.budget_remaining = String(item.budget_remaining / 100);
    if (timeTrackingEnabled) {
      item.logged_time.budget_used = String(item.logged_time.budget_used / 100);
      item.logged_time.budget_remaining = String(
        item.logged_time.budget_remaining / 100,
      );
    }
  });

  return { chartItems };
}

export default function parseLineChartData(
  project,
  rawChartData,
  mode,
  firstDate,
  timeTrackingEnabled,
  budgets,
  hasBudgetAccess,
  currencySymbol,
) {
  return getLineChartData(
    project,
    budgets,
    toSeriesSum(
      project,
      rawChartData,
      mode,
      firstDate,
      timeTrackingEnabled,
      hasBudgetAccess,
      budgets,
    ),
    mode,
    currencySymbol,
  );
}
