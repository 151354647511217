import * as Intercom from '@intercom/messenger-js-sdk';

import { RouteUnsupportedError } from '@float/web/lib/routing';

export const onChatClick = () => {
  Intercom.show();
};

export function getFriendlyLocation(pathname?: string) {
  if (!pathname) return null;

  if (pathname === '/') return 'timeline';
  if (pathname === '/project-plan') return 'timeline';
  if (pathname === '/log-time') return 'log-time';
  if (pathname === '/manage') return 'people';
  if (pathname === '/people') return 'people';
  if (pathname === '/projects') return 'projects';
  if (pathname === '/report') return 'report';
  if (pathname.startsWith('/project/')) return 'timeline';
  if (pathname.startsWith('/admin')) return 'settings';
  if (pathname.startsWith('/share')) return 'timeline';

  // TODO: The `getFriendlyLocation` method is called sometimes called outside the component context
  // https://linear.app/float-com/issue/PI-325/find-alternative-to-routeunsupportederror-in-getfriendlylocation
  throw new RouteUnsupportedError(`Unknown location ${pathname}`);
}
