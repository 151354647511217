import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { getCurrentPlan, getUser } from 'selectors';

import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import { isPaidPlan } from '@float/common/selectors/companyPrefs';
import { useAppSelector } from '@float/common/store';
import EH from '@float/ui/deprecated/Earhart';
import EHIconActivity from '@float/ui/deprecated/Earhart/Icons/Icon/IconActivity';
import EHIconBell from '@float/ui/deprecated/Earhart/Icons/Icon/IconBell';
import EHIconBriefcase from '@float/ui/deprecated/Earhart/Icons/Icon/IconBriefcase';
import EHIconCalendar from '@float/ui/deprecated/Earhart/Icons/Icon/IconCalendar';
import EHIconCog from '@float/ui/deprecated/Earhart/Icons/Icon/IconCog';
import EHIconCreditCard from '@float/ui/deprecated/Earhart/Icons/Icon/IconCreditCard';
import EHIconShieldTick from '@float/ui/deprecated/Earhart/Icons/Icon/IconShieldTick';
import EHIconSlider from '@float/ui/deprecated/Earhart/Icons/Icon/IconSlider';
import EHIconTag from '@float/ui/deprecated/Earhart/Icons/Icon/IconTag';
import EHIconTerminal from '@float/ui/deprecated/Earhart/Icons/Icon/IconTerminal';
import { IconWatch as EHIconWatch } from '@float/ui/deprecated/Earhart/Icons/Icon/IconWatch';
import { IconBuilding } from '@float/ui/icons/essentials/IconBuilding';
import { IconHat } from '@float/ui/icons/essentials/IconHat';
import { IconStatus } from '@float/ui/icons/essentials/IconStatus';
import { IconUsers } from '@float/ui/icons/essentials/IconUsers';

import { getIsStarterPlan } from '../Billing/TeamAccountFeaturesNew/helpers/getIsStarterPlan';
import { FreeTrialUpgrade } from '../FreeTrialUpgrade';
import { AddonTag } from './AddonTag';
import {
  StyledFreeTrialUpgradeWrapper,
  StyledNav,
  StyledScroll,
  StyledScrollContent,
} from './styles';

export const SideNav = ({ className, userCanSee }) => {
  const plan = useAppSelector(getCurrentPlan);
  const user = useAppSelector(getUser);
  const isPlanPaid = useAppSelector(isPaidPlan);
  const showTag = getIsStarterPlan(plan.plan_id, {
    isPaidPlan: isPlanPaid,
    isProTrial: user.is_pro_trial,
    plusPack: user.plus_pack,
    proTrialEnd: user.pro_trial_end,
    timeTracking: user.time_tracking,
  });
  const location = useLocation();

  const { isProjectCodesFeatureFlagEnabled } = useProjectCodesPreference();

  const [currentPathname, setCurrentPathname] = useState(location.pathname);

  const isActive = (pathname) => currentPathname.includes(pathname);

  useEffect(() => {
    setCurrentPathname(location.pathname);
  }, [location]);

  const canSeeSettingsSection =
    userCanSee.billing ||
    userCanSee.general ||
    userCanSee.api ||
    userCanSee.security;

  return (
    <StyledNav className={className}>
      <header>
        <h2>
          <Trans>Team settings</Trans>
        </h2>
      </header>

      <StyledScroll>
        <StyledScrollContent>
          {canSeeSettingsSection && (
            <ul>
              <li>
                <h5>
                  <Trans>Settings</Trans>
                </h5>
              </li>

              {userCanSee.billing && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EHIconCreditCard}
                    to="/admin/billing"
                    active={isActive('/admin/billing')}
                  >
                    <Trans>Plans & billing</Trans>
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}

              {userCanSee.general && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EHIconCog}
                    to="/admin/general"
                    active={isActive('/admin/general')}
                  >
                    <Trans>General</Trans>
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}

              {userCanSee.general && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EHIconBell}
                    to="/admin/notifications"
                    active={isActive('/admin/notifications')}
                  >
                    <Trans>Notifications</Trans>
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}

              {userCanSee.api && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EHIconTerminal}
                    to="/admin/api"
                    active={isActive('/admin/api')}
                  >
                    <Trans>Integrations</Trans>
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}

              {userCanSee.security && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EHIconShieldTick}
                    to="/admin/security"
                    active={isActive('/admin/security')}
                  >
                    <Trans>Security</Trans>
                    {showTag && <AddonTag />}
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}
            </ul>
          )}

          <ul>
            <li>
              <h5>
                <Trans>Admin</Trans>
              </h5>
            </li>

            <li>
              <EH.Buttons.SettingsNavButton
                icon={EHIconSlider}
                to="/admin/preferences"
                active={isActive('/admin/preferences')}
              >
                <Trans>Hours & currency</Trans>
              </EH.Buttons.SettingsNavButton>
            </li>

            {userCanSee.lockLoggedTime && (
              <li>
                <EH.Buttons.SettingsNavButton
                  icon={EHIconWatch}
                  to="/admin/time-tracking"
                  active={isActive('/admin/time-tracking')}
                >
                  <Trans>Time tracking</Trans>
                  {showTag && <AddonTag />}
                </EH.Buttons.SettingsNavButton>
              </li>
            )}

            <li>
              <EH.Buttons.SettingsNavButton
                icon={IconUsers}
                to="/admin/guests"
                active={isActive('/admin/guests')}
              >
                <Trans>Guests</Trans>
              </EH.Buttons.SettingsNavButton>
            </li>

            <li>
              <EH.Buttons.SettingsNavButton
                icon={EHIconCalendar}
                to="/admin/timeoffs"
                active={isActive('/admin/timeoffs')}
                style={{ position: 'relative' }}
              >
                <Trans>Time off</Trans>
              </EH.Buttons.SettingsNavButton>
            </li>

            <li>
              <EH.Buttons.SettingsNavButton
                icon={IconStatus}
                to="/admin/status"
                active={isActive('/admin/status')}
                style={{ position: 'relative' }}
              >
                <Trans>Statuses</Trans>
              </EH.Buttons.SettingsNavButton>
            </li>

            {userCanSee.adminForTags && (
              <li>
                <EH.Buttons.SettingsNavButton
                  icon={EHIconTag}
                  to="/admin/tags"
                  active={isActive('/admin/tags')}
                >
                  <Trans>Tags</Trans>
                </EH.Buttons.SettingsNavButton>
              </li>
            )}

            <li>
              <EH.Buttons.SettingsNavButton
                icon={IconBuilding}
                to="/admin/departments"
                active={isActive('/admin/departments')}
              >
                <Trans>Departments</Trans>
              </EH.Buttons.SettingsNavButton>
            </li>

            {userCanSee.roles && (
              <li data-callout-id="roles-management">
                <EH.Buttons.SettingsNavButton
                  icon={IconHat}
                  to="/admin/roles"
                  active={isActive('/admin/roles')}
                >
                  <Trans>Roles & rates</Trans>
                </EH.Buttons.SettingsNavButton>
              </li>
            )}

            <li>
              <EH.Buttons.SettingsNavButton
                icon={EHIconBriefcase}
                to="/admin/clients"
                active={isActive('/admin/clients')}
              >
                {isProjectCodesFeatureFlagEnabled
                  ? t`Clients & Project codes`
                  : t`Clients`}
              </EH.Buttons.SettingsNavButton>
            </li>

            {userCanSee.teamActivity && (
              <li>
                <EH.Buttons.SettingsNavButton
                  icon={EHIconActivity}
                  to="/admin/activity"
                  active={isActive('/admin/activity')}
                >
                  <Trans>Activity</Trans>
                  {showTag && <AddonTag />}
                </EH.Buttons.SettingsNavButton>
              </li>
            )}
          </ul>
        </StyledScrollContent>
      </StyledScroll>

      <StyledFreeTrialUpgradeWrapper>
        <FreeTrialUpgrade />
      </StyledFreeTrialUpgradeWrapper>
    </StyledNav>
  );
};

export default SideNav;
