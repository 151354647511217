import React, { CSSProperties } from 'react';
import { compact, isArray, isEmpty } from 'lodash';

import { TYPE_TO_CATEGORY } from '@float/common/search/helpers';
import { FilterOperators } from '@float/types/filters';
import {
  SearchAutocompleteCategory,
  VirtualFilterTypes,
} from '@float/types/view';
import { SearchFilterDropdown } from '@float/web/components/SearchFilterDropdown';
import type { FilteredContext } from '@float/common/search/selectors/getSearchAutocompleteResults/types';
import type {
  ActivateCategory,
  AddFilter,
  DeleteSavedSearch,
  EditableFilter,
} from '@float/web/components/SearchFilterDropdown/types';

interface AutocompleteProps {
  addFilter: AddFilter;
  currentInput: string;
  editingFilter: EditableFilter;
  activateCategory: ActivateCategory;
  expandedCategory: SearchAutocompleteCategory | undefined;
  fetchNextPage: () => void;
  filteredContext: FilteredContext;
  isLoading: boolean;
  getPlaceholder: () => string;
  totalResultsCount: number;
  inputRef: React.RefObject<HTMLInputElement>;
  listPopoverRef: React.RefObject<HTMLDivElement>;
  onInputKeyDown: (event: React.KeyboardEvent) => void;
  setInputValue: (value: string) => void;
  style?: CSSProperties;
  deleteSavedSearch: DeleteSavedSearch;
  highlightedIndex: number | undefined;
  setHighlightedIndex: (index: number) => void;
  isMeFilterVisible: boolean;
  isPeopleFilterVisible: boolean;
  isSavedSearchesFilterVisible: boolean;
  removeLastValueFromFilter: (filter: {
    type: VirtualFilterTypes;
    val: string[];
  }) => void;
  setOperator: (operator?: FilterOperators | '') => void;
}

interface AutocompleteState {
  selectedValues: string[];
}

class Autocomplete extends React.Component<
  AutocompleteProps,
  AutocompleteState
> {
  private disableMultiSelect: boolean;

  constructor(props: AutocompleteProps) {
    super(props);
    this.state = {
      selectedValues: this.getSelectedValues(props),
    };

    this.disableMultiSelect = false;
    this.checkForMultiSelectSupport();
  }

  // NOTE: There's no reason for this to be called on mount.
  // When we recactor to a functional component, we can run
  // it as part of the render process.
  componentDidMount(): void {
    this.checkForMultiSelectSupport();
  }

  componentDidUpdate(prevProps: AutocompleteProps): void {
    const newState: Partial<AutocompleteState> = {};

    const { editingFilter, expandedCategory } = this.props;
    if (
      prevProps.editingFilter !== editingFilter ||
      prevProps.expandedCategory !== expandedCategory
    ) {
      newState.selectedValues = this.getSelectedValues();
      this.checkForMultiSelectSupport();
    }

    if (!isEmpty(newState)) this.setState(newState as AutocompleteState);
  }

  checkForMultiSelectSupport = (): void => {
    this.disableMultiSelect =
      this.props.expandedCategory === 'projectStatuses' &&
      window.location.pathname === '/report';
  };

  getSelectedValues = (props: AutocompleteProps = this.props): string[] => {
    let selectedValues: string[] | undefined = [];
    const { type, val } = props.editingFilter || {};

    if (TYPE_TO_CATEGORY[type] === props.expandedCategory) {
      selectedValues = isArray(val) ? val : [val];
    }

    return compact(selectedValues);
  };

  replaceValue = ({
    type,
    add,
    remove,
  }: {
    type: VirtualFilterTypes;
    add: string[];
    remove: string[];
  }): void => {
    const filter = {
      type,
      val: [...this.state.selectedValues],
    };

    remove.forEach((val) => {
      const valIdx = filter.val.indexOf(val);
      if (valIdx === -1) return;
      filter.val.splice(valIdx, 1);
    });

    add.forEach((val) => {
      filter.val.push(val);
    });

    filter.val.sort();
    this.props.addFilter(filter, undefined, undefined, true);
  };

  addValue = (item?: { type: VirtualFilterTypes; val: string }): void => {
    if (!item) return;

    const { type, val } = item;
    const filter = {
      type,
      val: [...this.state.selectedValues, val],
    };
    filter.val.sort();
    this.props.addFilter(filter, undefined, undefined, true);
  };

  removeValue = (item?: { type: VirtualFilterTypes; val: string }): void => {
    if (!item) return;

    const { type, val } = item;
    const filter = {
      type,
      val: this.state.selectedValues.filter((x) => x !== val),
    };
    if (filter.val.length)
      this.props.addFilter(filter, undefined, undefined, true);
    else {
      this.props.removeLastValueFromFilter(filter);
    }
  };

  onOperatorChange = (operator?: FilterOperators | ''): void => {
    this.props.setOperator(operator);
    if (this.state.selectedValues.length) {
      this.props.addFilter(this.props.editingFilter, operator, undefined, true);
    }
  };

  render(): React.ReactNode {
    const {
      addFilter,
      currentInput,
      editingFilter,
      activateCategory,
      expandedCategory,
      fetchNextPage,
      filteredContext,
      isLoading,
      getPlaceholder,
      totalResultsCount,
      inputRef,
      listPopoverRef,
      onInputKeyDown,
      setInputValue,
      style,
      deleteSavedSearch,
      highlightedIndex,
      setHighlightedIndex,
      isMeFilterVisible,
      isPeopleFilterVisible,
      isSavedSearchesFilterVisible,
    } = this.props;

    const { selectedValues } = this.state;

    return (
      <SearchFilterDropdown
        addFilter={addFilter}
        addValue={this.addValue}
        currentInput={currentInput}
        deleteSavedSearch={deleteSavedSearch}
        disableMultiSelect={this.disableMultiSelect}
        editingFilter={editingFilter}
        activateCategory={activateCategory}
        expandedCategory={expandedCategory}
        filteredContext={filteredContext}
        isLoading={isLoading}
        isMeFilterVisible={isMeFilterVisible}
        isPeopleFilterVisible={isPeopleFilterVisible}
        isSavedSearchesFilterVisible={isSavedSearchesFilterVisible}
        getPlaceholder={getPlaceholder}
        totalResultsCount={totalResultsCount}
        highlightedIndex={highlightedIndex}
        inputRef={inputRef}
        listPopoverRef={listPopoverRef}
        onInputKeyDown={onInputKeyDown}
        onOperatorChange={this.onOperatorChange}
        removeValue={this.removeValue}
        replaceValue={this.replaceValue}
        selectedValues={selectedValues}
        setHighlightedIndex={setHighlightedIndex}
        setInputValue={setInputValue}
        fetchNextPage={fetchNextPage}
        style={style}
      />
    );
  }
}

export default Autocomplete;
