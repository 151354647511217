import React, { useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { compact } from 'es-toolkit';
import { getUser } from 'selectors';
import styled from 'styled-components';

import { Rights } from '@float/common/lib/acl';
import {
  getManagerAccess,
  getManagerAccessBit,
  getMemberAccess,
  setManagerAccess,
  setMemberAccess,
} from '@float/common/lib/acl/access';
import { useAppSelector } from '@float/common/store';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect/DropdownSelect';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import EHIconEdit from '@float/ui/deprecated/Earhart/Icons/Icon/IconEdit';
import EHIconEye from '@float/ui/deprecated/Earhart/Icons/Icon/IconEye';
import EHIconFolder from '@float/ui/deprecated/Earhart/Icons/Icon/IconFolder';
import EHIconGlob from '@float/ui/deprecated/Earhart/Icons/Icon/IconGlob';
import IconBg from '@float/ui/deprecated/Earhart/Icons/IconBg';
import {
  TextToggle,
  TextToggleStyles,
} from '@float/ui/deprecated/Earhart/Toggles';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { Hr } from '@float/ui/deprecated/Hr/Hr';
import { Col, Row, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { IconUsers } from '@float/ui/icons/essentials/IconUsers';

import { getBudgetAccessLabel } from '../PersonInfo.helpers';
import { CanView } from './CanView';
import { DepartmentsAccess } from './DepartmentsAccess';
import {
  getDepartmentOption,
  getEveryoneOption,
  getEverythingBillingOption,
  getEveryThingOption,
  getNoOneOption,
  getThemselfOption,
} from './PersonAccessOptions';

const TextToggleField = styled(TextToggle)`
  ${TextToggleStyles.label} {
    min-width: 72px;
    text-align: center;
  }
`;

const AccessLabel = styled.span`
  ${Typography.Label16.M500};
  color: ${Colors.Core.Lt.Emp.High};
`;

const AccessLabelDescription = styled.span`
  ${Typography.Label12.R400};
  color: ${Colors.Core.Text.Secondary};
`;

const AccessDisplay = styled.span`
  display: inline-flex;
  align-items: center;
  ${Typography.Label14.R400};
  color: ${Colors.Core.Text.Default};

  svg {
    --svg-icon-color: ${Colors.TCore.Emp.Medium11};
  }
`;

const AccessSectionRow = styled(Row)`
  min-height: 40px;
`;

export const MemberRightsControls = (props) => {
  const { access = 0 } = props.account;

  const memberAccess = getMemberAccess(props.account);
  const memberlimitedByDept = props.account?.department_filter_set;

  const user = useAppSelector(getUser);
  const actorLimitedByDept = user.department_filter?.length > 0;
  const initialAcc = props.initialPerson?.account;
  const initialCanViewEveryone =
    initialAcc &&
    (!initialAcc.department_filter || initialAcc.department_filter.length == 0);

  const DepartmentOption = getDepartmentOption();
  const EveryoneOption = getEveryoneOption();
  const ThemselfOption = getThemselfOption();
  const NoOneOption = getNoOneOption();

  const canViewOptions = compact([
    (initialCanViewEveryone || !actorLimitedByDept) && EveryoneOption,
    DepartmentOption,
    !props.guestMode && ThemselfOption,
  ]);

  // cannot give what he doesn't have
  // can downgrade what he has

  const value = props.account.department_filter_set
    ? DepartmentOption.value
    : memberAccess.canView
      ? EveryoneOption.value
      : ThemselfOption.value;

  const onChange = (nextOption) => {
    switch (nextOption.value) {
      case EveryoneOption.value:
        props.onChange({
          department_filter: [],
          prev_department_filter: props.account?.department_filter,
          department_filter_set: false,
          access: setMemberAccess(access, 'canView', true),
        });
        break;
      case DepartmentOption.value:
        props.onChange({
          department_filter: props.account?.prev_department_filter || [],
          prev_department_filter: [],
          department_filter_set: true,
          access: setMemberAccess(access, 'canView', true),
        });
        break;
      case ThemselfOption.value:
        props.onChange({
          department_filter: [],
          prev_department_filter: props.account?.department_filter,
          department_filter_set: false,
          access: setMemberAccess(access, 'canView', false),
        });
        break;
    }
  };

  useEffect(() => {
    if (!props.guestMode) return;
    // if (props.account.account_id) return;

    const access = setMemberAccess(0, 'canView', true);
    props.onChange({
      access: access,
    });
  }, []); // eslint-disable-line

  return (
    <Col>
      <Col>
        <Row justifyContent="space-between" alignItems="center">
          <IconBg>
            <EHIconEye />
          </IconBg>
          <Spacer size={16} />
          <Col alignItems="flex-start">
            <AccessLabel>
              <Trans>Can view</Trans>
            </AccessLabel>
            <Spacer size={6} />
            <AccessLabelDescription>
              <Trans>Specify which People they can see</Trans>
            </AccessLabelDescription>
          </Col>
          <DropdownSelect
            appearance="default"
            withIconLeft={true}
            minSelectWidth={260}
            value={value}
            options={canViewOptions}
            onChange={onChange}
          />
        </Row>
        {memberlimitedByDept && (
          <>
            <Spacer size={16} />
            <Row>
              <DepartmentsAccess
                account={props.account}
                formErrors={props.formErrors}
                onChange={props.onChange}
              />
            </Row>
          </>
        )}
      </Col>
      {!props.guestMode && (
        <>
          <Hr style={{ margin: '16px 0px' }} />
          <AccessSectionRow justifyContent="space-between" alignItems="center">
            <IconBg>
              <EHIconEdit />
            </IconBg>
            <Spacer size={16} />
            <Col alignItems="flex-start">
              <AccessLabel>
                <Trans>Can edit</Trans>
              </AccessLabel>
              <Spacer size={6} />
              <AccessLabelDescription>
                <Trans>Specify which People they can edit</Trans>
              </AccessLabelDescription>
            </Col>
            <DropdownSelect
              value={
                memberAccess.canEdit ? ThemselfOption.value : NoOneOption.value
              }
              appearance="default"
              withIconLeft={true}
              minSelectWidth={260}
              options={[ThemselfOption, NoOneOption]}
              onChange={(nextOption) => {
                props.onChange({
                  access: setMemberAccess(
                    access,
                    'canEdit',
                    nextOption.value === ThemselfOption.value ? true : false,
                  ),
                });
              }}
            />
          </AccessSectionRow>
        </>
      )}
    </Col>
  );
};

export const ManagerRightsControls = (props) => {
  const { account, initialPerson, formErrors, onChange } = props;
  const { access = 0 } = account;

  const user = useAppSelector(getUser);
  const managerAccess = getManagerAccess(account);

  const createUpdatedAccess = (attribute, bool) => {
    return {
      access: setManagerAccess(access, attribute, bool),
    };
  };

  const canEnable = (attribute) => {
    return Rights.canUpdatePeople(user, {
      accessCheck: getManagerAccessBit(attribute),
      account: {
        ...account,
        ...createUpdatedAccess(attribute, true),
      },
    });
  };

  return (
    <Col>
      <CanView
        account={account}
        initialPerson={initialPerson}
        formErrors={formErrors}
        onChange={onChange}
      />
      {canEnable('isPeopleManager') && (
        <>
          <Hr style={{ margin: '16px 0px' }} />
          <AccessSectionRow justifyContent="space-between" alignItems="center">
            <IconBg>
              <IconUsers />
            </IconBg>
            <Spacer size={16} />
            <Col alignItems="flex-start">
              <AccessLabel>
                <Trans>People Manager</Trans>
              </AccessLabel>
              <Spacer size={6} />
              <AccessLabelDescription>
                <Trans>Can schedule and approve time off requests</Trans>
              </AccessLabelDescription>
            </Col>
            <TextToggleField
              name="people_manager"
              value={managerAccess.isPeopleManager ? 1 : 0}
              options={[
                { label: 'No', value: 0 },
                { label: 'Yes', value: 1 },
              ]}
              onChange={(o) => {
                onChange(createUpdatedAccess('isPeopleManager', o.value));
              }}
            />
          </AccessSectionRow>
        </>
      )}
      {canEnable('isProjectManager') && (
        <>
          <Hr style={{ margin: '16px 0px' }} />
          <AccessSectionRow justifyContent="space-between" alignItems="center">
            <IconBg>
              <EHIconFolder />
            </IconBg>
            <Spacer size={16} />
            <Col alignItems="flex-start">
              <AccessLabel>
                <Trans>Project Manager</Trans>
              </AccessLabel>
              <Spacer size={6} />
              <AccessLabelDescription>
                <Trans>Can schedule tasks and manage projects</Trans>
              </AccessLabelDescription>
            </Col>
            <TextToggleField
              name="project_manager"
              value={managerAccess.isProjectManager ? 1 : 0}
              options={[
                { label: 'No', value: 0 },
                { label: 'Yes', value: 1 },
              ]}
              onChange={(o) => {
                onChange(createUpdatedAccess('isProjectManager', o.value));
              }}
            />
          </AccessSectionRow>
        </>
      )}
      <Hr style={{ margin: '16px 0px' }} />
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <Col alignItems="flex-start">
          <AccessLabel>
            <Trans>Additional permissions</Trans>
          </AccessLabel>
          <Spacer size={16} />
          <Checkbox
            label={t`Add and edit people`}
            value={managerAccess.canCreateEditPeople}
            onChange={(next) => {
              onChange(createUpdatedAccess('canCreateEditPeople', next));
            }}
          />
          {canEnable('canBudget') && (
            <>
              <Spacer size={12} />
              <Checkbox
                label={getBudgetAccessLabel()}
                value={managerAccess.canBudget}
                onChange={(next) => {
                  onChange(createUpdatedAccess('canBudget', next));
                }}
              />
            </>
          )}
        </Col>
      </AccessSectionRow>
    </Col>
  );
};

export const AdminViewRights = () => {
  const EveryoneOption = getEveryoneOption();
  const EveryThingOption = getEveryThingOption();

  return (
    <Col>
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <IconBg>
          <EHIconEye />
        </IconBg>
        <Spacer size={16} />
        <Col alignItems="flex-start">
          <AccessLabel>
            <Trans>Can view</Trans>
          </AccessLabel>
          <Spacer size={6} />
          <AccessLabelDescription>
            <Trans>Specify which People they can see</Trans>
          </AccessLabelDescription>
        </Col>
        <AccessDisplay>
          <Spacer xSize={8} />
          {EveryoneOption.label}
        </AccessDisplay>
      </AccessSectionRow>
      <Hr style={{ margin: '16px 0px' }} />
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <IconBg>
          <EHIconGlob />
        </IconBg>
        <Spacer size={16} />
        <Col alignItems="flex-start">
          <AccessLabel>
            <Trans>Manages</Trans>
          </AccessLabel>
        </Col>
        <AccessDisplay>
          <Spacer xSize={8} />
          {EveryThingOption.label}
        </AccessDisplay>
      </AccessSectionRow>
    </Col>
  );
};

export const BillingViewRights = () => {
  const EveryoneOption = getEveryoneOption();
  const EverythingBilling = getEverythingBillingOption();

  return (
    <Col>
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <IconBg>
          <EHIconEye />
        </IconBg>
        <Spacer size={16} />
        <Col alignItems="flex-start">
          <AccessLabel>
            <Trans>Can view</Trans>
          </AccessLabel>
          <Spacer size={6} />
          <AccessLabelDescription>
            <Trans>Specify which People they can see</Trans>
          </AccessLabelDescription>
        </Col>
        <AccessDisplay>
          <Spacer xSize={8} />
          {EveryoneOption.label}
        </AccessDisplay>
      </AccessSectionRow>
      <Hr style={{ margin: '16px 0px' }} />
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <IconBg>
          <EHIconGlob />
        </IconBg>
        <Spacer size={16} />
        <Col alignItems="flex-start">
          <AccessLabel>
            <Trans>Manages</Trans>
          </AccessLabel>
        </Col>
        <AccessDisplay>
          <Spacer xSize={8} />
          {EverythingBilling.label}
        </AccessDisplay>
      </AccessSectionRow>
    </Col>
  );
};
