import { FilterOperators } from '@float/types/filters';
import { VirtualFilterTypes } from '@float/types/view';

export type FilterCombination = {
  type: VirtualFilterTypes;
  operator: FilterOperators | '';
  value?: string | string[];
};

/**
 * List of filter combinations that are not allowed.
 * Each combination consists of:
 * - type: The filter type (e.g., 'timeoff')
 * - operator: The filter operator (e.g., '-' for NOT)
 * - value: The filter value(s) that are forbidden with this type/operator combination
 */
export const FORBIDDEN_FILTER_COMBINATIONS: FilterCombination[] = [
  { type: 'timeoff', operator: '-', value: ['*'] },
];

/**
 * Checks if a given filter combination is forbidden based on predefined rules.
 *
 * @param combination - The filter combination to check
 * @param combination.type - The filter type (e.g., 'timeoff')
 * @param combination.value - The filter value, can be a single value or array
 * @param combination.operator - The filter operator (e.g., '-' for NOT, '|-' for OR NOT)
 * @returns boolean - True if the combination is forbidden, false otherwise
 *
 * @example
 * // Returns true - cannot exclude all timeoffs
 * isFilterCombinationForbidden({ type: 'timeoff', value: '*', operator: '-' })
 */
export const isFilterCombinationForbidden = ({
  type,
  value,
  operator,
}: {
  type: VirtualFilterTypes;
  value: string | string[] | undefined;
  operator: FilterOperators | '' | undefined;
}): boolean => {
  const compareValues = (
    ruleValue: string | string[] | undefined,
    filterValue: string | string[] | undefined = '',
  ): boolean => {
    if (Array.isArray(ruleValue)) {
      return Array.isArray(filterValue)
        ? ruleValue.every((ruleValueItem) =>
            filterValue.includes(ruleValueItem),
          )
        : ruleValue.includes(filterValue);
    }

    if (Array.isArray(filterValue) && typeof ruleValue !== 'undefined') {
      return filterValue.includes(ruleValue);
    }

    return ruleValue === filterValue;
  };

  return FORBIDDEN_FILTER_COMBINATIONS.some(
    (rule) =>
      rule.type === type &&
      compareValues(rule.value, value) &&
      rule.operator === operator,
  );
};
