import React from 'react';
import { t } from '@lingui/macro';

import { CheckboxField } from '../components/CheckboxField';

export function LockedTaskListField() {
  return (
    <CheckboxField
      name="project.locked_task_list"
      label={t`Managers with ‘manage projects’ permission can add to this list`}
    />
  );
}
