import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import * as styled from './styles';

import { hoverLinkIconWrapper } from './HoverLinkIcon.styles.css';

type HoverLinkIconProps = {
  to: Location | string;
  className?: string;
  icon?: ReactNode;
  children?: ReactNode;
  label?: ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

export const HoverLinkIcon: React.FC<HoverLinkIconProps> = ({
  to,
  className,
  icon,
  label,
  children,
  disabled,
  onClick,
}) => {
  if (children && to) {
    throw new Error('Cannot specify both children and to props');
  }

  const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    stopPropagation(e);
    if (onClick) onClick(e);
  };

  const renderWrapper = (content: ReactNode) =>
    to ? (
      <Link to={to} className={cn(disabled && 'disabled')}>
        {content}
      </Link>
    ) : (
      <div className={cn(hoverLinkIconWrapper, disabled && 'disabled')}>
        {content}
      </div>
    );

  const renderContent = () =>
    children ||
    renderWrapper(
      <>
        {icon}
        {label && <span className="mobile">{label}</span>}
      </>,
    );

  const content = (
    <styled.HoverLinkIcon
      className={cn(className, { disabled })}
      onClick={handleClick}
    >
      {renderContent()}
    </styled.HoverLinkIcon>
  );

  return disabled ? (
    content
  ) : (
    <TextTooltip content={label} className="hint" delay={250}>
      {content}
    </TextTooltip>
  );
};
