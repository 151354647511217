import { config } from '@float/libs/config';
import {
  AnyCell,
  CellKey,
  CellsMap,
  CellsMapData,
  DataMap,
} from '@float/types';

import { now } from '../../../util/timer';
import { RowMetas } from '../../useRowMetas.helpers';
import { BiMaps, EntityId } from '../types';
import { getCellKeyColIdx } from './getCellKeyColIdx';
import { getCellKeyRowId } from './getCellKeyRowId';

type BuildCellsProps = {
  maps: DataMap;
  bimaps: BiMaps;
  rowMetas: RowMetas;
  timeIncrementUnit: unknown;
  numVisibleWeeks: number;
  excludeNonWorkDaysGaps: boolean;
};

type BuildCellsHelpers = {
  buildCell: (
    cells: CellsMap,
    cellKey: CellKey,
    opts: BuildCellsOptions,
  ) => void;
  calcHeight: (cells: CellsMap, rowId: string, boundaryCol: number) => void;
  calcWorkDays: (rowId: string, cell: AnyCell) => void;
};

type BuildCellsOptions = {
  lastAffectedCellKeyByTaskId?: Record<EntityId, CellKey>;
  timeIncrementUnit?: unknown;
  dataTypesAffected?: Record<string, boolean>;
  // Instead of directly build the cells we process them lazily
  // for better performance. Useful when loading data into cells.
  lazyProcessing?: boolean;
};

export function createBuildCells(
  props: BuildCellsProps,
  helpers: BuildCellsHelpers,
) {
  const { bimaps, maps, rowMetas, numVisibleWeeks, timeIncrementUnit } = props;

  function clearTaskLengths(cellKeys: Set<CellKey>) {
    const taskIds = new Set<EntityId>();
    const lastAffectedCellKeyByTaskId: Record<EntityId, CellKey> = {};

    for (const cellKey of cellKeys) {
      bimaps.task.getRev(cellKey).forEach((id) => {
        taskIds.add(id);
        if (cellKey.startsWith('person')) {
          lastAffectedCellKeyByTaskId[id] = cellKey;
        }
      });
    }

    taskIds.forEach((id) => {
      if (maps.task[id]) {
        // @entity.length
        delete maps.task[id].length;
      }
    });

    return lastAffectedCellKeyByTaskId;
  }

  return function buildCells(
    cells: CellsMap,
    cellKeys: CellKey[],
    opts: BuildCellsOptions = {},
  ) {
    // We don't recreate the cells object on each dispatch because it's
    // expensive. Instead, we just change its members and set a _lastUpdatedAt
    // field so that other hooks can use that as a dependency to update with.
    cells._lastUpdatedAt = now();

    const uniqueCellKeys = new Set(cellKeys);

    // Project phases and milestones don't split on cells - they render as a
    // solid chunk across all of the cells they cross. Therefore, when sorting
    // these items, we have to consider overlaps across more than one cell.
    // Ensure we do that sorting before cells are built so that the contained
    // items can apply a correct y value.
    const { projectIds } = getProjectRowSortData(uniqueCellKeys, cells);

    for (const id of projectIds) {
      // OPTIMIZATION: Invalidate the _projectRowSortData for the target projects
      // to delay the execution of buildProjectRowSortData to when we read the project
      // cells
      cells._projectRowSortData[id] = undefined;
    }

    // Whenever we rebuild a group of cells, we want to recalculate the lengths
    // of the entiies contained in those cells, e.g. creating a timeoff that
    // goes on top of an existing multi-day task.
    opts.lastAffectedCellKeyByTaskId = clearTaskLengths(uniqueCellKeys);

    // Temporarily disable lazy processing on mobile regardless of the opts.lazyProcessing
    const lazyProcessing = opts.lazyProcessing === true && !config.isMobileApp;

    let mainCellsAffected = false;
    // console.time(`buildCells (${uniqueCellKeys.size} cells)`);
    for (const cellKey of uniqueCellKeys) {
      const rowId = getCellKeyRowId(cellKey);
      const colIdx = getCellKeyColIdx(cellKey);

      const cell = {
        colIdx,
        rowId,
        items: [],
        dependsOnCols: [],
        height: 0,
        workDays: [],
      } as CellsMapData[CellKey];

      if (rowId.startsWith('person') && !rowMetas.get(rowId)) {
        // If there is no rowMeta, we're not allowed to view the current row,
        // likely due to a department filter. Don't attempt to build the cell.
        // @ts-expect-error Dynamic assignement over a typed record
        cells[cellKey] = cell;
        continue;
      }

      if (rowId.startsWith('person') || rowId.startsWith('logged_time')) {
        // We have to compute work days per cell because it factors in timeoffs
        // and oneoff days along with their normal work days.
        helpers.calcWorkDays(rowId, cell);
      }

      if (!opts.timeIncrementUnit) {
        opts.timeIncrementUnit = timeIncrementUnit;
      }

      if (lazyProcessing) {
        if (rowId !== 'top') {
          mainCellsAffected = true;
        }

        cells._markAsStale(cellKey, () => {
          // @ts-expect-error Dynamic assignement over a typed record
          cells[cellKey] = cell;

          helpers.buildCell(cells, cellKey, opts);

          if (rowId !== 'top') {
            const boundaryCol = toBoundaryCol(colIdx, numVisibleWeeks);
            helpers.calcHeight(cells, rowId, boundaryCol);
          }
        });
      } else {
        // @ts-expect-error Dynamic assignement over a typed record
        cells[cellKey] = cell;
        helpers.buildCell(cells, cellKey, opts);
      }
    }

    if (!lazyProcessing) {
      const computedHeights: Record<string, boolean> = {};
      for (const cellKey of uniqueCellKeys) {
        const rowId = getCellKeyRowId(cellKey);

        if (rowId === 'top') continue;

        const colIdx = getCellKeyColIdx(cellKey);

        mainCellsAffected = true;
        const boundaryCol = toBoundaryCol(colIdx, props.numVisibleWeeks);
        const heightsKey = `${rowId}:${boundaryCol}`;

        if (!computedHeights[heightsKey]) {
          computedHeights[heightsKey] = true;
          helpers.calcHeight(cells, rowId, boundaryCol);
        }
      }
    }

    const dataTypesAffected = opts?.dataTypesAffected;

    if (
      mainCellsAffected &&
      dataTypesAffected &&
      ['task', 'timeoff', 'loggedTime'].some((x) => dataTypesAffected[x])
    ) {
      // avoid triggering insights calculation unless necessary
      cells._lastUpdatedInsightsSourceAt = now();
    }
  };
}

function getProjectRowSortData(cellKeys: Set<CellKey>, cells: CellsMap) {
  const projectIds = new Set<string>();

  for (const ck of cellKeys) {
    if (ck.startsWith('project-')) {
      projectIds.add(ck.substring('project-'.length, ck.indexOf(':')));
    }
  }

  // An update on a project cell may trigger a change in the phases position
  for (const ck of cells._getCellKeys()) {
    if (ck.startsWith('project-')) {
      const projectId = ck.substring('project-'.length, ck.indexOf(':'));
      if (projectIds.has(projectId)) {
        cellKeys.add(ck as CellKey);
      }
    }
  }

  return {
    projectIds,
  };
}

function toBoundaryCol(col: number, numVisibleWeeks: number) {
  return Math.floor(col / numVisibleWeeks) * numVisibleWeeks;
}
