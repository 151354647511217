import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { groupBy, isEmpty, mapValues } from 'lodash';
import {
  getAllHolidays,
  getDepartments,
  getParentDepartments,
  getPeopleMap,
  getPhasesMapRaw,
  getProjectsMap,
  getRoles,
  getTeamCapacityHighlights,
  getTimeoffTypes,
  getUser,
  selectIsMeFilterActive,
} from 'selectors';

import { setPlaceholder } from '@float/common/actions/search';
import api3 from '@float/common/api3';
import Loader from '@float/common/components/elements/Loader';
import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import { trackEvent } from '@float/common/lib/analytics';
import { handleFail } from '@float/common/lib/errors';
import { useSearchFiltersAnalyticsTracking } from '@float/common/lib/hooks/useSearchFiltersAnalyticsTracking';
import { trackInteraction } from '@float/common/lib/trackInteraction';
import { isFilteredBySinglePerson } from '@float/common/search/helpers';
import { getActiveFilters } from '@float/common/selectors/views';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import AccordionTable from '@float/ui/deprecated/AccordionTable/AccordionTable';
import { BarChart } from '@float/ui/deprecated/Chart/BarChart/BarChart';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { useSnackbar } from '@float/ui/deprecated/Snackbar';
import { Tab } from '@float/ui/deprecated/Tab/Tab';

import { NoResults } from '../components/ErrorPages';
import SectionError from '../components/SectionError';
import { getLoggedTimeBoundary } from '../helpers/getLoggedTimeBoundary';
import getSearchFiltersString from '../helpers/getSearchFiltersString';
import { useEnsureReportContextLoaded } from '../helpers/useEnsureReportContextLoaded';
import ReportGlobalControls from '../ReportGlobalControls';
import { LoaderContainer, TabContainer } from '../styles';
import { useReportsFetcher } from '../useReportsFetcher';
import { useReportsStateReducer } from '../useReportsStateReducer';
import SummaryBar from './components/SummaryBar';
import { TimeoffTable } from './components/TimeoffTable';
import parseBarChartData from './parser/chart';
import exportChartCsv from './parser/csv/chart';
import exportTableCsv from './parser/csv/table';
import exportTimetrackingCsv from './parser/csv/timetracking';
import parseTableData from './parser/table';
import {
  getPaidTimeoffs,
  useAggregatedTimeoffs,
  useReportTab,
} from './TeamCapacity.helpers';

function TeamCapacity(props) {
  const {
    width,
    wrapperRef,
    reportsSettings: { settings, updateSettings, createSortConfig },
    setCsvExportConfig,
    searchFilters,
    meFilter,
    confirm,
    printMode,
    setPrintModeHeader,
    setHideTeamModeFilters,
    timeoffApprovalsEnabled,
    user,
  } = props;

  const isSinglePersonView =
    meFilter || isFilteredBySinglePerson(searchFilters);

  let singlePerson = '';
  if (isSinglePersonView) {
    if (meFilter) {
      singlePerson = props.people[meFilter]?.name;
    } else {
      singlePerson = searchFilters.find((f) => f.type === 'person')?.val;
    }
  }

  const searchFiltersString = getSearchFiltersString(
    searchFilters.filter((f) => f.type !== 'person' || !isSinglePersonView),
  );

  const { dates } = useScheduleContext();
  const [state, dispatch] = useReportsStateReducer();
  const { isProjectCodesEnabled } = useProjectCodesPreference();

  const allHolidays = useAppSelector(getAllHolidays);
  const { activeTab, setActiveTab } = useReportTab({ isSinglePersonView });

  const aggregatedTimeoffData = useAggregatedTimeoffs(allHolidays);
  const ref = useRef();

  useEffect(() => {
    trackInteraction('team capacity', user);
  }, [user]);

  useEffect(() => {
    if (settings.comparisonMode === ChartComparisonModes.SCHEDULED) return;

    if (!props.timeTrackingEnabled) {
      updateSettings({
        comparisonMode: ChartComparisonModes.SCHEDULED,
      });
    }
  }, [props.timeTrackingEnabled, updateSettings, settings.comparisonMode]);

  useEffect(() => {
    setHideTeamModeFilters(meFilter);
  }, [meFilter, setHideTeamModeFilters]);

  // Fetch lifecycle -----------------------------------------------------------

  const reportParams = useMemo(
    () => ({
      ...settings,
      timeoffApprovalsEnabled,
    }),
    [settings, timeoffApprovalsEnabled],
  );

  useReportsFetcher({
    endpoint: 'people',
    dispatch,
    settings: reportParams,
    loggedTimeUpdateCount: props.loggedTimeUpdateCount,
  });

  useEnsureReportContextLoaded(settings.startDate, settings.endDate);

  useEffect(() => {
    if (!state.isTableTimeout && !state.isChartTimeout) return;

    const err = state.isTableTimeout ? state.tableError : state.chartError;

    confirm({
      title: err.message || 'Report taking too long',
      message: 'Please narrow the date range and try again.',
      hideCancel: true,
    });
  }, [state, confirm]);

  // Parse table data ----------------------------------------------------------

  const { domLoggedTimeBoundary, csvLoggedTimeBoundary } =
    getLoggedTimeBoundary({
      timeTrackingEnabled: props.timeTrackingEnabled,
      comparisonMode: settings.comparisonMode,
    });

  const reduxDispatch = props.dispatch;
  const [timeoffBalance, setTimeoffBalance] = useState({});
  useEffect(() => {
    async function loadBalance() {
      const response = await api3.getAllTimeoffBalance({
        date: moment(settings.endDate).format('YYYY-MM-DD'),
      });

      // For some reason, reports grouping time off by time off type name, but not id
      setTimeoffBalance(
        mapValues(groupBy(response, 'name'), ([item]) => ({
          ...item,
          peopleMap: mapValues(
            groupBy(item.people, 'id'),
            ([person]) => person,
          ),
        })),
      );
    }
    loadBalance();
  }, [reduxDispatch, settings.startDate, settings.endDate]);

  const tableData = useMemo(() => {
    return parseTableData(
      {
        accounts: props.accounts,
        dates,
        departments: props.departments,
        isSinglePersonView,
        loggedTimeBoundary: domLoggedTimeBoundary,
        mode: settings.comparisonMode,
        parentDepartments: props.parentDepartments,
        people: props.people,
        percentageMode: settings.percentageMode,
        phases: props.phases,
        projects: props.projects,
        reduxDispatch,
        roles: props.roles,
        timeoffBalance,
        timeoffTypes: props.timeoffTypes,
        updateSettings,
        user: props.user,
        isProjectCodesEnabled,
      },
      state.rawTableData,
    );
  }, [
    dates,
    isSinglePersonView,
    domLoggedTimeBoundary,
    props.accounts,
    props.departments,
    props.parentDepartments,
    props.people,
    props.phases,
    props.projects,
    props.roles,
    props.timeoffTypes,
    props.user,
    reduxDispatch,
    settings.comparisonMode,
    settings.percentageMode,
    state.rawTableData,
    timeoffBalance,
    updateSettings,
    isProjectCodesEnabled,
  ]);

  // Refresh row heights when any table data changes
  // to keep tables the correct size
  useEffect(() => {
    const heightsRefreshTimer = setTimeout(() => {
      ref.current?.updateHeightsCache?.();
    }, 200);

    return () => clearTimeout(heightsRefreshTimer);
  }, [tableData]);

  // Parse chart data ----------------------------------------------------------

  const { chartData, chartDataKey } = useMemo(() => {
    const domBarChartData = parseBarChartData(
      { dates, settings, loggedTimeBoundary: domLoggedTimeBoundary },
      state.rawChartData?.datapoints,
      props.highlights,
    );
    const csvBarChartData = parseBarChartData(
      { dates, settings, loggedTimeBoundary: csvLoggedTimeBoundary },
      state.rawChartData?.datapoints,
      props.highlights,
    );

    return {
      chartData: {
        dom: {
          barChart: {
            loggedTimeBoundary: domLoggedTimeBoundary,
            loggedTimeBoundaryIdx: domBarChartData.loggedTimeBoundaryIdx,
            chartData: domBarChartData.chartData,
            chartTotals: domBarChartData.chartTotals,
          },
        },
        csv: {
          barChart: {
            loggedTimeBoundary: csvLoggedTimeBoundary,
            loggedTimeBoundaryIdx: csvBarChartData.loggedTimeBoundaryIdx,
            chartData: csvBarChartData.chartData,
            chartTotals: csvBarChartData.chartTotals,
          },
        },
      },
      chartDataKey: String(Date.now()),
    };
  }, [
    dates,
    settings,
    state.rawChartData,
    props.highlights,
    domLoggedTimeBoundary,
    csvLoggedTimeBoundary,
  ]);

  // Bind export CSV and print hooks -------------------------------------------

  const { showSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const ctx = {
      settings,
      singlePerson,
      timeTrackingEnabled: props.timeTrackingEnabled,
      user: props.user,
      searchFiltersString,
      people: props.people,
      projects: props.projects,
      phases: props.phases,
      departments: props.departments,
      parentDepartments: props.parentDepartments,
      loggedTimeBoundary: csvLoggedTimeBoundary,
    };

    const handleExport =
      (fn, ...rest) =>
      async () => {
        const id = showSnackbar('Exporting', { loader: true, persist: true });
        try {
          await fn(ctx, ...rest);
        } catch (e) {
          console.error(e);
          handleFail(
            null,
            'There was an error exporting the requested data. Please refresh the page and try again.',
          );
        } finally {
          closeSnackbar(id);
        }
      };

    const options = [
      {
        title: 'Export chart data',
        fn: handleExport(
          exportChartCsv,
          chartData.csv.barChart.chartData,
          chartData.csv.barChart.chartTotals,
        ),
      },
      {
        title: 'Export table data',
        fn: handleExport(exportTableCsv, {
          ...state.rawTableData,
          timeoff: aggregatedTimeoffData,
          paidTimeoff: getPaidTimeoffs(state.rawTableData?.timeoff),
        }),
      },
    ];

    if (props.timeTrackingEnabled) {
      options.push({
        title: 'Export time tracking data',
        fn: handleExport(exportTimetrackingCsv, state.rawTableData),
      });
    }

    setCsvExportConfig({ options });
  }, [
    isSinglePersonView,
    singlePerson,
    chartData.csv.barChart,
    state.rawTableData,
    aggregatedTimeoffData,
    state.rawTableData?.timeoff,
    settings,
    showSnackbar,
    closeSnackbar,
    setCsvExportConfig,
    props.user,
    props.timeTrackingEnabled,
    props.projects,
    props.phases,
    props.people,
    props.departments,
    props.parentDepartments,
    searchFiltersString,
    csvLoggedTimeBoundary,
  ]);

  useEffect(() => {
    setPrintModeHeader({
      title: singlePerson || 'People',
      searchFiltersString,
    });
  }, [singlePerson, searchFiltersString, setPrintModeHeader]);

  useEffect(() => {
    const noun = tableData.people.rows.length == 1 ? 'person' : 'people';
    reduxDispatch(setPlaceholder(`${tableData.people.rows.length} ${noun}`));
  }, [tableData.people.rows.length]); // eslint-disable-line

  useEffect(() => {
    const type = singlePerson ? 'Person' : 'People';
    trackEvent('report-viewed', { type });
  }, [singlePerson]);

  // Collapses table rows on percentage mode change
  // to prevent empty row spaces
  useEffect(() => {
    setTimeout(() => {
      ref.current?.updateHeightsCache?.();
    }, 200);
  }, [settings.percentageMode, ref]);

  useSearchFiltersAnalyticsTracking('report-filtered', {
    additionalProps: {
      type: singlePerson ? 'Person' : 'People',
    },
  });

  // ---------------------------------------------------------------------------

  if (
    !state.isChartLoading &&
    !state.isTableLoading &&
    !state.isChartTimeout &&
    !state.isTableTimeout &&
    !state.chartError &&
    !state.tableError &&
    isEmpty(chartData.dom.barChart.chartData)
  ) {
    return <NoResults />;
  }

  return (
    <>
      <div>
        {!printMode && (
          <ReportGlobalControls
            hideTeamModeFilters={props.hideTeamModeFilters}
            memberViewSelf={props.memberViewSelf}
            csvExportConfig={props.csvExportConfig}
            reportsSettings={props.reportsSettings}
            timeTrackingEnabled={props.timeTrackingEnabled}
          />
        )}
        {state.chartError && <SectionError height="268" topMargin />}
        {!state.chartError &&
          (state.isChartLoading && state.showChartSpinner ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <BarChart
              key={`${chartDataKey}:${printMode}:${width}`}
              width={printMode ? 1000 : width}
              unit={settings.timeUnit}
              mode={settings.comparisonMode}
              items={chartData.dom.barChart.chartData}
              loggedTimeBoundary={chartData.dom.barChart.loggedTimeBoundary}
              loggedTimeBoundaryIdx={
                chartData.dom.barChart.loggedTimeBoundaryIdx
              }
            />
          ))}
      </div>

      <SummaryBar
        totals={chartData.dom.barChart.chartTotals}
        mode={settings.comparisonMode}
        minWidth={printMode ? 1000 : width}
      />

      {state.tableError && <SectionError height="100" />}
      {!state.tableError &&
        (state.isTableLoading && state.showTableSpinner ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <div style={{ minWidth: printMode ? 1000 : width }}>
            <TabContainer>
              {!isSinglePersonView && (
                <>
                  <Tab
                    noBorder
                    color="charcoalGrey"
                    label="People"
                    onClick={() => setActiveTab('people')}
                    active={activeTab === 'people'}
                    counter={tableData.people.rows.length}
                  />

                  <Tab
                    noBorder
                    color="charcoalGrey"
                    label="Roles"
                    onClick={() => setActiveTab('roles')}
                    active={activeTab === 'roles'}
                    counter={tableData.roles.rows.length}
                  />

                  <Tab
                    noBorder
                    color="charcoalGrey"
                    label="Departments"
                    onClick={() => setActiveTab('departments')}
                    active={activeTab === 'departments'}
                    counter={tableData.departments.rows.length}
                  />
                </>
              )}
              <Tab
                noBorder
                color="charcoalGrey"
                label="Projects"
                onClick={() => setActiveTab('projects')}
                active={activeTab === 'projects'}
                counter={tableData.projects.rows.length}
              />
              <Tab
                noBorder
                color="charcoalGrey"
                label="Tasks"
                onClick={() => setActiveTab('tasks')}
                active={activeTab === 'tasks'}
                counter={tableData.tasks.rows.length}
              />
              <Tab
                noBorder
                color="charcoalGrey"
                label="Time off"
                onClick={() => setActiveTab('timeoffs')}
                active={activeTab === 'timeoffs'}
                counter={
                  tableData.timeoffs[0].rows.length +
                  tableData.timeoffs[1].rows.length
                }
              />
              {props.timeTrackingEnabled && (
                <Tab
                  noBorder
                  color="charcoalGrey"
                  label="Time tracking"
                  onClick={() => setActiveTab('timetracking')}
                  active={activeTab === 'timetracking'}
                  counter={tableData.timetracking.rows.length}
                />
              )}
            </TabContainer>

            {activeTab === 'people' && (
              <AccordionTable
                ref={ref}
                wrapperRef={wrapperRef}
                style={{ zoom: printMode ? 0.77 : 1 }}
                data={tableData.people}
                sortConfig={createSortConfig('tc-people')}
                noResultsMessage={
                  !state.isTableLoading &&
                  'No people scheduled in this date range'
                }
              />
            )}
            {activeTab === 'roles' && (
              <AccordionTable
                ref={ref}
                wrapperRef={wrapperRef}
                style={{ zoom: printMode ? 0.77 : 1 }}
                data={tableData.roles}
                sortConfig={createSortConfig('tc-roles')}
                noResultsMessage={
                  !state.isTableLoading &&
                  'No Roles scheduled in this date range'
                }
              />
            )}
            {activeTab === 'departments' && (
              <AccordionTable
                ref={ref}
                wrapperRef={wrapperRef}
                style={{ zoom: printMode ? 0.77 : 1 }}
                data={tableData.departments}
                sortConfig={createSortConfig('tc-departments')}
                noResultsMessage={
                  !state.isTableLoading &&
                  'No departments scheduled in this date range'
                }
              />
            )}
            {activeTab === 'projects' && (
              <AccordionTable
                ref={ref}
                wrapperRef={wrapperRef}
                style={{ zoom: printMode ? 0.77 : 1 }}
                data={tableData.projects}
                sortConfig={createSortConfig('tc-projects')}
                noResultsMessage={
                  !state.isTableLoading &&
                  'No projects scheduled in this date range'
                }
              />
            )}
            {activeTab === 'tasks' && (
              <AccordionTable
                ref={ref}
                wrapperRef={wrapperRef}
                style={{ zoom: printMode ? 0.77 : 1 }}
                data={tableData.tasks}
                sortConfig={createSortConfig('tc-tasks')}
                noResultsMessage={
                  !state.isTableLoading &&
                  'No tasks scheduled in this date range'
                }
              />
            )}
            {activeTab === 'timeoffs' && (
              <TimeoffTable
                createSortConfig={createSortConfig}
                isTableLoading={state.isTableLoading}
                printMode={printMode}
                tableData={tableData}
                wrapperRef={wrapperRef}
              />
            )}
            {activeTab === 'timetracking' && (
              <AccordionTable
                ref={ref}
                wrapperRef={wrapperRef}
                style={{ zoom: printMode ? 0.77 : 1 }}
                data={tableData.timetracking}
                sortConfig={createSortConfig('tc-timetracking')}
                disableAccordion={isSinglePersonView}
                noResultsMessage={
                  !state.isTableLoading && 'No logged times in this date range'
                }
              />
            )}
          </div>
        ))}
    </>
  );
}

const mapStateToProps = (state) => ({
  accounts: state.accounts.accounts,
  departments: getDepartments(state),
  highlights: getTeamCapacityHighlights(state),
  meFilter: selectIsMeFilterActive(state),
  searchFilters: getActiveFilters(state),
  parentDepartments: getParentDepartments(state),
  people: getPeopleMap(state),
  phases: getPhasesMapRaw(state),
  projects: getProjectsMap(state),
  roles: getRoles(state),
  timeoffApprovalsEnabled: !!state.companyPrefs.timeoff_approvals,
  timeoffTypes: getTimeoffTypes(state),
  timeTrackingEnabled: state.companyPrefs.time_tracking > 0,
  user: getUser(state),
});

export default connect(mapStateToProps)(withConfirm(TeamCapacity));
