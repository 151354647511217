import { createSelector } from 'reselect';

import { Phase } from '@float/types';

import { ReduxState, ReduxStateStrict } from '../reducers/lib/types';
import { getFilteredEntities } from '../search/selectors/filteredEntities';
import { getSearchFilteredProjects } from '../search/selectors/projects';
import { getUser } from './currentUser';
import { getLockPeriodDates } from './lockLoggedTime';
import { selectProjectFilteredMilestones } from './milestones';
import {
  getDepartmentFilteredActivePeople,
  getPeopleMap,
  getSearchFilteredActivePeopleMap,
} from './people';
import { getPhasesMapRaw, getProjectPhases } from './phases';
import { getDefaultDropdownProject, getProjectsMap } from './projects';
import { getStatuses } from './statuses';
import { getFullTasksMap } from './tasks';
import { getFullTimeoffsMap } from './timeoffs';
import {
  getTimersWithExternalMeta,
  getTimersWithExternalMetaMap,
} from './timer';
import { getActiveFilters } from './views';

export const getSerenaCellEntities = createSelector(
  [
    getFullTasksMap,
    (state: ReduxStateStrict) => state.tasks.fullyHydrated,
    getFullTimeoffsMap,
    (state: ReduxStateStrict) => state.timeoffs.fullyHydrated,
  ],
  (tasks, tasksAreHydrated, timeoffs, timeoffsAreHydrated) => ({
    /**
     * null means that the entities are not yet initialized.
     * It is different than {} which means that the state is empty.
     *
     * This is used to detect the fetching state, because when the tasks state
     * is not initialized, fetches the initial range which is 2x than the subsequent fetches
     */
    tasks: tasksAreHydrated ? tasks : null,
    timeoffs: timeoffsAreHydrated ? timeoffs : null,
  }),
);

const getSerenaReduxDataWithoutCellEntities = createSelector(
  [
    (state: ReduxState) => state.app?.actionToTrigger,
    getProjectsMap,
    getPhasesMapRaw,
    getProjectPhases,
    getSearchFilteredActivePeopleMap,
    getPeopleMap,
    getDepartmentFilteredActivePeople,
    (state: ReduxState) => state.holidays.holidays,
    (state: ReduxState) => state.oneOffs.oneOffs,
    (state: ReduxState) => state.timeoffTypes.timeoffTypes,
    (state: ReduxState) => state.statusTypes.statusTypes,
    getStatuses,
    selectProjectFilteredMilestones,
    (state: ReduxState) => state.loggedTimes.fetchedLoggedTime,
    (state: ReduxState) => state.schedule,
    getUser,
    getActiveFilters,
    (state: ReduxState) => state.search.removedFilters,
    getSearchFilteredProjects,
    getFilteredEntities,
    getDefaultDropdownProject,
    getLockPeriodDates,
    getTimersWithExternalMetaMap,
    getTimersWithExternalMeta,
    (state: ReduxState) => state.timeRange,
  ],
  (
    actionToTrigger,
    projects,
    phases,
    projectPhases,
    people,
    allPeople,
    activePeople,
    holidays,
    oneOffs,
    timeoffTypes,
    statusTypes,
    statuses,
    milestones,
    fetchedLoggedTime,
    schedule,
    user,
    filters,
    removedFilters,
    searchFilteredProjects,
    filteredEntities,
    defaultDropdownProject,
    loggedTimeLockPeriodDates,
    timers,
    timersList,
    timeRange,
  ) => {
    return {
      actionToTrigger,
      projects,
      phases: phases as Record<number, Phase>,
      projectPhases,
      people,
      allPeople,
      activePeople,
      holidays,
      oneOffs,
      timeoffTypes,
      statusTypes,
      statuses,
      milestones,
      fetchedLoggedTime,
      schedule,
      user,
      filters,
      removedFilters,
      searchFilteredProjects,
      filteredEntities,
      defaultDropdownProject,
      loggedTimeLockPeriodDates,
      timers,
      timersList,
      timeRange,
    };
  },
);

export type SerenaState = ReturnType<typeof getSerenaReduxData>;
export const getSerenaReduxData = createSelector(
  [getSerenaReduxDataWithoutCellEntities, getSerenaCellEntities],
  (data, entities) => ({ ...data, ...entities }),
  {
    devModeChecks: {
      /**
       * This dev check verifies that the selector doesn't directly return it's dependencies.
         It's a known issue here, `reduxData` is used on too many parts of the codebase and we are gradually dismissing it.
       */
      identityFunctionCheck: 'never',
    },
  },
);
