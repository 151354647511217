import React from 'react';
import { t } from '@lingui/macro';

import { CheckboxField } from '../components/CheckboxField';
import { FormFieldRow } from '../components/FormFieldsSection';
import { useProjectACL } from '../hooks/useProjectACL';

import * as styles from './ManagersEditRightsField.css';

export function ManagersEditRightsField() {
  const acl = useProjectACL();

  if (!acl.canChangeEditRights) return null;

  return (
    <FormFieldRow>
      <hr className={styles.divider} />
      <CheckboxField
        label={t`Managers with ‘manage projects’ permission can edit this project`}
        name="project.common"
      />
    </FormFieldRow>
  );
}
