import { isAfter, isEqual, parseISO, startOfDay } from 'date-fns';
import { isNil } from 'es-toolkit';

import { trackEvent } from '@float/common/lib/analytics';
import type { CostRateHistoryPayload } from '@float/types/costRate';

type CostRateChangeScope = 'role' | 'person';

type EffectiveDate = 'today' | 'future' | 'past';

export type TrackCostRateChangesParams = {
  costRateHistory?: CostRateHistoryPayload;
  isBulk?: boolean;
  scope: CostRateChangeScope;
};

export type TrackCostRateChangesEventPayload = {
  effectiveDate?: EffectiveDate;
  // isAdd and isRemove can both be true at the same time. E.g. user can add
  // a point-in-time cost rate and remove one or more entries before saving.
  isAdd: boolean;
  isBulk: boolean;
  isRemove: boolean;
  scope: CostRateChangeScope;
};

function getEffectiveDate(
  costRateHistory: CostRateHistoryPayload,
): EffectiveDate | undefined {
  if (!costRateHistory.add?.length) return undefined;

  const dateString = costRateHistory.add[0].effective_date;
  if (isNil(dateString)) return undefined;

  const date = startOfDay(parseISO(dateString));
  const today = startOfDay(new Date());

  if (isEqual(date, today)) {
    return 'today';
  } else if (isAfter(date, today)) {
    return 'future';
  } else {
    return 'past';
  }
}

export function trackCostRateChanges(params: TrackCostRateChangesParams) {
  const { costRateHistory, isBulk, scope } = params;

  if (isNil(costRateHistory)) return;

  const eventPayload: TrackCostRateChangesEventPayload = {
    scope,
    isBulk: Boolean(isBulk),
    isAdd: Boolean(costRateHistory.add?.length),
    isRemove: Boolean(costRateHistory.del?.length),
    effectiveDate: getEffectiveDate(costRateHistory),
  };

  if (!eventPayload.isAdd && !eventPayload.isRemove) return;

  trackEvent('Set cost rate', eventPayload);
}
