import { css } from 'styled-components';

import { AllMediaSizeKeys, MediaSize } from '@float/constants/media';
import { CSS_MEDIA_RULE_BUILDERS_BY_BREAKPOINT } from '@float/ui/deprecated/constants/mediaRuleBuilders';

// @test-export
export const buildCurrentBreakpointCssVariableMediaRule = (
  breakpointName: MediaSize,
) => {
  const getBreakpointMediaRule =
    CSS_MEDIA_RULE_BUILDERS_BY_BREAKPOINT[breakpointName];

  const styles = css({ '--current-breakpoint': `'${breakpointName}'` });
  return getBreakpointMediaRule(styles);
};

export const MEDIA_RULES_FOR_CURRENT_BREAKPOINT_STYLE_VARIABLE =
  AllMediaSizeKeys.map(buildCurrentBreakpointCssVariableMediaRule);
