export const getUpgradePrice = ({
  upgradeFrom,
  isMonthly,
  isEnterpriseUpgrade,
}: {
  upgradeFrom: string;
  isMonthly?: boolean;
  isEnterpriseUpgrade?: boolean;
}) => {
  // upgrading from "Starter" to "Pro"
  if (!isEnterpriseUpgrade) {
    return isMonthly ? '$5' : '$4';
  }
  // upgrading from "Pro" to "Enterprise"
  if (upgradeFrom === 'pro' && isEnterpriseUpgrade) {
    return isMonthly ? '$3.50' : '$6';
  }
  // upgrading from "Starter" to "Enterprise"
  if (upgradeFrom === 'starter' && isEnterpriseUpgrade) {
    return isMonthly ? '$8.50' : '$10';
  }
};
