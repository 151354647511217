import { createSelector } from 'reselect';

import { AccountsState } from '@float/common/reducers/accounts';
import { DepartmentsState } from '@float/common/reducers/departments';
import { ViewsState } from '@float/common/reducers/views';
import { BaseFilterToken, CompanyPreferences, CurrentUser } from '@float/types';

import { getViewById } from './views';

export const isViewApplied = (state: { views?: ViewsState }) =>
  Boolean(state?.views?.appliedViewId);

export const getCurrentViewId = (state: { views?: ViewsState }) =>
  state?.views?.appliedViewId ?? null;

export const getCurrentView = (state: {
  accounts: AccountsState;
  companyPrefs: CompanyPreferences;
  currentUser: CurrentUser;
  departments: DepartmentsState;
  views?: ViewsState;
}) => {
  const currentViewId = getCurrentViewId(state);

  if (!currentViewId) return null;

  return getViewById(state, currentViewId) ?? null;
};

const EMPTY_FILTERS: BaseFilterToken[] = [];

export const getCurrentViewFilters = (state: {
  accounts: AccountsState;
  companyPrefs: CompanyPreferences;
  currentUser: CurrentUser;
  departments: DepartmentsState;
  views?: ViewsState;
}) => {
  const currentView = getCurrentView(state);

  return currentView ? currentView.filters : EMPTY_FILTERS;
};

export const getCurrentViewContainsMeFilter = createSelector(
  [getCurrentViewFilters],
  (filters) => {
    return filters.some((filter) => filter.type === 'me');
  },
);
