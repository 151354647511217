import { addWeeks } from 'date-fns';

import { formatToFloatDate } from '@float/libs/dates';

import {
  ProjectMilestoneRecord,
  ProjectPhaseRecord,
  ProjectTaskRecord,
} from '../types';

export const createEmptyTeamMemberRecord = () => ({
  people_id: null,
  hourly_rate: null,
  phase_id: 0,
});

export const createEmptyTaskRecordCreator =
  (isNonBillable?: boolean) => (): ProjectTaskRecord => ({
    task_name: '',
    billable: isNonBillable ? 0 : 1,
    budget: null,
    task_meta_id: 0,
    count_logged_time: 0,
    count_tasks: 0,
    isSelected: false,
  });

export const createEmptyMilestoneRecord = () =>
  ({
    milestone_id: 0,
    name: '',
    date: formatToFloatDate(new Date()),
    end_date: formatToFloatDate(new Date()),
    duration: null,
    parent_offset: null,
  }) satisfies ProjectMilestoneRecord;

export const createEmptyPhaseRecord = () =>
  ({
    phase_id: undefined,
    budget_total: null,
    color: null,
    end_date: formatToFloatDate(addWeeks(new Date(), 4)),
    start_date: formatToFloatDate(new Date()),
    phase_name: '',
    active: true,
    temp_id: Math.random(),
    duration: null,
    parent_offset: null,
    non_billable: false,
  }) satisfies ProjectPhaseRecord;
