import {
  LARGE_BREAKPOINT_MAX_WIDTH,
  LARGE_BREAKPOINT_MIN_WIDTH,
  MediaSize,
  MEDIUM_BREAKPOINT_MAX_WIDTH,
  MEDIUM_BREAKPOINT_MIN_WIDTH,
  SMALL_BREAKPOINT_MAX_WIDTH,
} from '@float/constants/media';

export function getBreakpointFromWidth(width: number): MediaSize {
  if (width <= SMALL_BREAKPOINT_MAX_WIDTH) {
    return MediaSize.Small;
  }

  if (
    width >= MEDIUM_BREAKPOINT_MIN_WIDTH &&
    width <= MEDIUM_BREAKPOINT_MAX_WIDTH
  ) {
    return MediaSize.Medium;
  }

  if (
    width >= LARGE_BREAKPOINT_MIN_WIDTH &&
    width <= LARGE_BREAKPOINT_MAX_WIDTH
  ) {
    return MediaSize.Large;
  }

  return MediaSize.XLarge;
}
