import { createSelector } from 'reselect';

import { getUser } from '@float/common/selectors/currentUser';
import { createSelectorWithShallowEqualSetResultCheck } from '@float/common/selectors/lib/createSelectorWithShallowEqualSetResultCheck';
import { getPeopleMapRaw } from '@float/common/selectors/people';
import { getProjects } from '@float/common/selectors/projects';
import { selectIsMeFilterActive } from '@float/common/selectors/search';
import { getActiveFilters } from '@float/common/selectors/views';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import type { AccountsState } from '@float/common/reducers/accounts';
import type { ClientsState } from '@float/common/reducers/clients';
import type { DepartmentsState } from '@float/common/reducers/departments';
import type { PhasesState } from '@float/common/reducers/phases';
import type { ProjectsState } from '@float/common/reducers/projects';
import type { SearchState } from '@float/common/reducers/search';
import type { TasksState } from '@float/common/reducers/tasks';
import type { SearchResultsState } from '@float/common/store/searchResults/searchResults.types';
import type { Person, Phase } from '@float/types';

import { getSearchResults } from '../../store/searchResults/searchResults.selectors';
import { forProjects } from '../core';
import { isAllowedToViewProject } from '../permissions/isAllowedToViewProject';
import { getSearchDerivedContext } from './derivedContext';
import type { SearchReducerContext } from '../../search/types';

/**
 * @todo(PI-448) Review this logic when we implement the Me filter in SBL
 * @see https://linear.app/float-com/issue/PI-448/spike-explore-unifying-me-filter-with-sbl
 * @test-export
 */
export const selectAccessibleProjectsList = createSelector(
  [getProjects, getUser, getPeopleMapRaw, selectIsMeFilterActive],
  (projects, user, people, meFilter) => {
    if (meFilter) {
      const projectsWhereCurrentUserIsOnTeam = projects.filter((project) =>
        project.all_people_ids.includes(user.people_id ?? NaN),
      );

      return projectsWhereCurrentUserIsOnTeam;
    }
    return projects.filter((project) =>
      isAllowedToViewProject({ user, people }, project),
    );
  },
);

const selectAccessibleProjectsIdsSet = createSelector(
  [selectAccessibleProjectsList],
  (projects) => new Set(projects.map((project) => project.project_id)),
);

// OPTIMIZATION: When the results of consecutive calls are equals
// keep the same referential identity to reduce the recoputations of the consumers
const selectSearchFilteredProjectsIdsFromSearchCore =
  createSelectorWithShallowEqualSetResultCheck(
    [
      getUser,
      getSearchDerivedContext,
      getActiveFilters,
      selectIsMeFilterActive,
      (state: { clients: ClientsState }) => state.clients.clients,
      getPeopleMapRaw,
      (state: { projects: ProjectsState }) => state.projects.projects,
      (state: { departments: DepartmentsState }) =>
        state.departments.departments,
      selectAccessibleProjectsList,
      (state: { accounts: AccountsState }) => state.accounts.accounts,
      (state: { phases: PhasesState }) => state.phases.phases,
      (state: { tasks: TasksState }) => state.tasks.tasks,
    ],
    (
      user,
      search: SearchReducerContext,
      filters,
      me: boolean,
      clients,
      people: Record<number, Person>,
      projects,
      departments,
      projectsArray,
      accounts,
      phases: Record<number, Phase>,
      tasks,
    ) => {
      const context = {
        user,
        clients,
        search,
        people,
        departments,
        projects,
        me,
        accounts,
        phases,
        tasks,
      };

      return forProjects(context, projectsArray, filters);
    },
  );

const selectSearchFilteredProjectsIdsFromSearchWorkerResults =
  createSelectorWithShallowEqualSetResultCheck(
    [getSearchResults],
    (searchResults) => {
      // Check on the searchResults state
      // It is undefined when the search is processed with selectors (Search worker disabled)
      // or when this selector is executed inside the Search worker
      if (searchResults) {
        return searchResults.projects;
      }

      return null;
    },
  );

const selectSearchFilteredProjectsIdsFromSearchResolve =
  createSelectorWithShallowEqualSetResultCheck(
    [getActiveFilters, getSearchResults, selectAccessibleProjectsIdsSet],
    (filters, searchResults, accessibleProjectsSet) => {
      if (filters.length && searchResults) {
        // ACL checks are not executed by SBL
        // So we intersect the SBL results with our ACL results
        return accessibleProjectsSet.intersection(searchResults.projects);
      }

      // If there are no filters we want to return all the projects
      // that the user can read
      return accessibleProjectsSet;
    },
  );

// OPTIMIZATION: Don't use `createSelector` here to run only the selector that we need
// instead of running al the dependecies eagerly
export const getSearchFilteredProjectsIds = (state: {
  search: SearchState;
  searchResults?: SearchResultsState;
}) => {
  if (
    // On the WebWorker the featureFlags are not available
    // and we don't need to check the FF there because with SBL
    // the search won't run there
    featureFlags.isReady &&
    featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)
  ) {
    return selectSearchFilteredProjectsIdsFromSearchResolve(state);
  }

  const searchWorkerResults =
    selectSearchFilteredProjectsIdsFromSearchWorkerResults(state);

  if (searchWorkerResults) {
    return searchWorkerResults;
  }

  return selectSearchFilteredProjectsIdsFromSearchCore(state);
};

export const getSearchFilteredProjects = createSelector(
  [getSearchFilteredProjectsIds, getProjects],
  (ids, projects) => {
    return projects.filter((project) => ids.has(project.project_id));
  },
);
