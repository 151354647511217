import { formatAmount } from '@float/common/lib/budget/helpers/formatAmount';
import { ProjectBudgetType } from '@float/constants/projects';
import { config } from '@float/libs/config';
import type { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

import { getCost } from './getCost';

export const getEntityProfitMargin = (
  entity: {
    fee: number;
    cost: {
      scheduled: number;
      logged: number;
      future: number;
    };
    budget_type?: ProjectBudgetType | null;
  },
  mode: ChartComparisonModes,
  currency: string,
) => {
  const cost = getCost(mode, entity.cost);
  const profit = entity.fee - cost;
  const isMoneyBudgetType =
    entity.budget_type === ProjectBudgetType.HourlyFee ||
    entity.budget_type === ProjectBudgetType.TotalFee;

  return {
    profit: isMoneyBudgetType
      ? (formatAmount(entity.budget_type, profit, {
          locale: config.locale,
          currency,
        }) as string)
      : 0,
    percentage: profit / (entity.fee || 1),
  };
};
