import React from 'react';
import { t } from '@lingui/macro';
import cn from 'classnames';

import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import { getDisplayNotesExcerpt } from '@float/common/lib/notes';
import {
  normalizeTime,
  TIME_FORMAT,
  toFriendlyTime,
} from '@float/common/lib/time';
import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { getPhaseById } from '@float/common/selectors/phases';
import { selectEnhancedProjectById } from '@float/common/selectors/projects/selectEnhancedProjectById';
import { RowMetaItem } from '@float/common/serena/Data/useRowMetas.helpers';
import { useAppSelectorStrict } from '@float/common/store';
import { config } from '@float/libs/config';
import { CellItem, CompanyPreferences, RepeatStateEnum } from '@float/types';
import Link from '@float/ui/deprecated/Icons/icon-link';
import IconSerenaComplete from '@float/ui/deprecated/Icons/icon-serena-complete';
import IconSerenaRepeat from '@float/ui/deprecated/Icons/icon-serena-repeat';
import IconSyncWhite from '@float/ui/deprecated/Icons/icon-sync-white';

import { addStartTimeElement } from './box/BoxElements/addStartTimeElement';
import { isArchived } from './box/utils/isArchived';

import * as styles from './EntityItemTooltip.css';

const getTotalHours = (hoursPd: number, length: number) =>
  parseFloat((hoursPd * length).toFixed(2));

enum LinkIneligbleReasons {
  NotTask = 'NotTask',
  IsSelf = 'IsSelf',
  HasParent = 'HasParent',
  IsSynced = 'IsSynced',
  IsRepeat = 'IsRepeat',
  IsInPast = 'IsInPast',
  DifferentPhase = 'DifferentPhase',
  DifferentProject = 'DifferentProject',
}

const getReasonLinkIsIneligble = (
  reason: keyof typeof LinkIneligbleReasons,
) => {
  const LINK_INELIGIBLE_REASONS: Record<
    keyof typeof LinkIneligbleReasons,
    string | null
  > = {
    [LinkIneligbleReasons.NotTask]: t`You can only link tasks together`,
    [LinkIneligbleReasons.IsSelf]: null,
    [LinkIneligbleReasons.HasParent]: t`This task already has a parent`,
    [LinkIneligbleReasons.IsSynced]: t`Synced tasks cannot be linked`,
    [LinkIneligbleReasons.IsRepeat]: t`Repeating tasks cannot be linked`,
    [LinkIneligbleReasons.IsInPast]: t`Tasks with an earlier start date cannot be linked`,
    [LinkIneligbleReasons.DifferentPhase]: t`Linked tasks must be in the same phase`,
    [LinkIneligbleReasons.DifferentProject]: t`Linked tasks must be in the same project`,
  };

  return LINK_INELIGIBLE_REASONS[reason];
};

export const LinkIneligibleTooltip = ({
  error,
}: {
  error: string | boolean;
}) => {
  const message = getReasonLinkIsIneligble(
    error as keyof typeof LinkIneligbleReasons,
  );
  if (!message) return null;

  return <div className={styles.linkEligibilityTooltip}>{message}</div>;
};

export interface EntityItemTooltipContentProps {
  item: CellItem<'timeoff'> | CellItem<'task'>;
  rowMeta: RowMetaItem | undefined;
  companyPrefs: CompanyPreferences;
}

export const TooltipIcons = ({
  integrationStatus,
  repeatState,
  rootTaskId,
  status,
  hours,
}: {
  integrationStatus?: number;
  repeatState: RepeatStateEnum | null | undefined;
  rootTaskId?: number;
  status?: -1 | 0 | 1 | 2 | 3;
  hours: number;
}) => {
  const formattedTime = normalizeTime(
    hours,
    config.isNativeTimerApp ? TIME_FORMAT.time : TIME_FORMAT.decimal,
  );

  return (
    <div className={styles.tooltipRight}>
      {integrationStatus === 1 && (
        <IconSyncWhite
          className={styles.tooltipRightSvg}
          key="integration"
          color="#868686"
        />
      )}
      {Boolean(repeatState && repeatState > 0) && (
        <IconSerenaRepeat
          className={styles.tooltipRightSvg}
          key="repeat"
          color="#868686"
        />
      )}
      {Boolean(rootTaskId && rootTaskId > 0) && (
        <Link
          className={styles.tooltipRightSvg}
          key="link"
          color="#868686"
          size={12}
        />
      )}
      {status === 3 && (
        <IconSerenaComplete
          className={styles.tooltipRightSvg}
          key="complete"
          color="#868686"
        />
      )}
      <div className={styles.tooltipHours}>{t`${formattedTime}h`}</div>
    </div>
  );
};

export const TooltipContent = ({
  elements,
  entityNotes,
  projectCode,
}: {
  elements: string[];
  entityNotes: string;
  projectCode?: string;
}) => {
  const { isProjectCodesEnabled } = useProjectCodesPreference();
  const shouldShowProjectCode = isProjectCodesEnabled && projectCode;
  const shouldHideOverflow = !(entityNotes || shouldShowProjectCode);

  return (
    <>
      {elements.map((el, idx) => {
        const isFirstElement = idx === 0;
        const isLastElement = idx === elements.length - 1;

        return (
          <div
            className={cn({
              [styles.bold]: isFirstElement,
              [styles.hideOverflow]: isLastElement && shouldHideOverflow,
            })}
            key={el}
          >
            {el}
          </div>
        );
      })}

      {entityNotes ? (
        <p
          className={cn(styles.tooltipNotes, {
            [styles.tooltipNotesWithoutProjectCode]: !shouldShowProjectCode,
          })}
        >
          {getDisplayNotesExcerpt({ notes: entityNotes })}
        </p>
      ) : null}
      {shouldShowProjectCode && (
        <>
          <div className={styles.separator}></div>
          <div className={styles.projectCode}>{projectCode}</div>
        </>
      )}
    </>
  );
};

export const EntityItemTooltipContent: React.FC<
  EntityItemTooltipContentProps
> = ({ item, rowMeta, companyPrefs }) => {
  const elements = [];

  const timeoffType = useAppSelectorStrict((state) => {
    if (item.type === 'timeoff') {
      return state.timeoffTypes.timeoffTypes[item.entity.timeoff_type_id];
    }
    return undefined;
  });

  if (timeoffType) {
    const { entity } = item;

    let name = timeoffType.timeoff_type_name;
    if (!timeoffType.active) {
      name = t`${name} (Archived)`;
    }

    if (entity.start_time) {
      name = `${toFriendlyTime(entity.start_time, companyPrefs)} - ${name}`;
    }

    elements.push(name);
  }

  const project = useAppSelectorStrict((state) => {
    if (item.type === 'task') {
      return selectEnhancedProjectById(state, item.entity.project_id);
    }
    return undefined;
  });

  const phase = useAppSelectorStrict((state) => {
    if (item.type === 'task' && item.entity.phase_id) {
      return getPhaseById(state, item.entity.phase_id) ?? undefined;
    }
    return undefined;
  });

  if (item.type === 'task') {
    const { entity } = item;

    let clientElement = null;
    if (project) {
      clientElement =
        project.client_name !== t`No Client` ? project.client_name : null;
      if (isArchived(project, phase)) {
        if (clientElement) {
          clientElement += t` (Archived)`;
        } else {
          clientElement = t`(Archived)`;
        }
      }
    }
    if (entity.name) {
      elements.push(entity.name);
    } else if (phase && phase.phase_name) {
      elements.push(phase.phase_name);
    }
    if (project && project.project_name) elements.push(project.project_name);
    if (clientElement) elements.push(clientElement);
    addStartTimeElement(entity, elements, companyPrefs);
  }

  const { entity } = item;

  let hours = 0;

  if (entity.hours) {
    hours = entity.hours;
  }

  if (isFullDayTimeoff(entity) && rowMeta && typeof item.x === 'number') {
    hours = rowMeta.getDailyWorkHours(entity.start_date)[item.x];
  }

  // @entity.length
  const length = entity.length ?? 1;
  hours = getTotalHours(hours, length);
  let entityNotes = '';

  if ('notes' in entity && entity.notes) {
    entityNotes = entity.notes;
  } else if ('timeoff_notes' in entity && entity.timeoff_notes) {
    entityNotes = entity.timeoff_notes;
  }

  const integrationStatus =
    'integration_status' in entity ? entity.integration_status : undefined;
  const repeatState =
    'repeat_state' in entity ? entity.repeat_state : undefined;
  const rootTaskId =
    // @ts-expect-error root_task_id is defined as string, but we are using it as a number here
    'root_task_id' in entity ? (entity.root_task_id as number) : undefined;
  const status = 'status' in entity ? entity.status : undefined;

  return (
    <div
      className={styles.bottomHoverTooltip}
      data-isTimeoff={item.type === 'timeoff'}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={styles.tooltipLeft}>
        <TooltipContent
          elements={elements}
          entityNotes={entityNotes}
          projectCode={project?.project_code}
        ></TooltipContent>
      </div>
      <TooltipIcons
        integrationStatus={integrationStatus}
        repeatState={repeatState}
        rootTaskId={rootTaskId}
        status={status}
        hours={hours}
      />
    </div>
  );
};
