/**
 * @deprecated
 *
 * This file is deprecated and should not be used.
 * Once the cost rates feature flag is removed this file will be removed.
 */

import { forEach, map } from 'lodash';
import { getEmptyTaskLabel } from 'reports/TeamCapacity/parser/table/getEmptyTaskLabel';

import { formatAmount, toCents } from '@float/common/lib/budget';

function breakdown(ctx, raw) {
  const { people, project, user } = ctx;
  const defByPerson = JSON.stringify({
    scheduled: 0,
    feeCents: 0,
    logged: { scheduled: 0, feeCents: 0 },
    future: { scheduled: 0, feeCents: 0 },
    children: {},
  });
  const peopleIds = project?.people_ids || [];
  const byPerson = Object.fromEntries(
    peopleIds
      .filter((pid) => {
        const person = people[pid];
        const deptIds = user.department_filter;
        return (
          person &&
          !!person.active &&
          (!deptIds?.length || deptIds.includes(person.department_id))
        );
      })
      .map((pid) => [pid, JSON.parse(defByPerson)]),
  );

  raw.totals.forEach((item) => {
    if (item.type !== 'task' && item.type !== 'logged_time') return;

    if (!people[item.person_id]) {
      console.error('No person found', item);
      return;
    }

    if (!item.name) {
      item.name = getEmptyTaskLabel(ctx);
    }

    if (!byPerson[item.person_id]) {
      byPerson[item.person_id] = JSON.parse(defByPerson);
    }

    const record = byPerson[item.person_id];

    if (!record.children[item.name]) {
      record.children[item.name] = {
        scheduled: 0,
        feeCents: 0,
        logged: {
          scheduled: 0,
          feeCents: 0,
        },
        future: {
          scheduled: 0,
          feeCents: 0,
        },
      };
    }

    const child = record.children[item.name];

    if (item.type === 'task' || item.type === 'logged_time') {
      if (item.type === 'logged_time' && item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      const childRoot = item.type === 'task' ? child : child.logged;
      const recordRoot = item.type === 'task' ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;
      record.future.scheduled += item.hours.future;

      recordRoot.feeCents += toCents(item.rate) * item.hours.scheduled;
      childRoot.feeCents += toCents(item.rate) * item.hours.scheduled;

      const rateCents = toCents(item.rate);
      record.future.feeCents += rateCents * item.hours.future;

      if (item.type === 'task') {
        child.future.scheduled += item.hours.future;
        child.future.feeCents += rateCents * item.hours.future;
      }

      if (item.type === 'task') {
        child.future.nonbillable += item.hours.future;
      }
    }
  });

  return byPerson;
}

export function getScheduledTable(ctx, raw) {
  const { project, people } = ctx;
  const hasFeeColumns = project.budget_type === 2 || project.budget_type === 3;

  const headers = [
    {
      label: 'Name',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Scheduled',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPerson = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
  };

  const rows = [];
  forEach(byPerson, (o, personId) => {
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = o.scheduled;
    const person = people[personId];

    totals.hours += o.scheduled;
    if (hasFeeColumns) totals.feeCents += o.feeCents;

    rows.push({
      id: personId,
      data: [person.name, fee, hours],
      children: map(o.children, (c, taskName) => {
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = c.scheduled;

        return {
          data: [taskName, childFee, childHours],
        };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
  ];

  return { headers, rows, footer };
}

export function getLoggedTable(ctx, raw) {
  const { project, people } = ctx;
  const hasFeeColumns = project.budget_type === 2 || project.budget_type === 3;

  const headers = [
    {
      label: 'Name',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Logged',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPerson = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
  };

  const rows = [];
  forEach(byPerson, (o, personId) => {
    const fee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const hours = o.logged.scheduled;
    const person = people[personId];

    totals.hours += o.logged.scheduled;
    if (hasFeeColumns) totals.feeCents += o.logged.feeCents;

    rows.push({
      id: personId,
      data: [person.name, fee, hours],
      children: map(o.children, (c, taskName) => {
        const childFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childHours = c.logged.scheduled;

        return {
          data: [taskName, childFee, childHours],
        };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
  ];

  return { headers, rows, footer };
}

export function getCompareTable(ctx, raw) {
  const { project, people } = ctx;
  const hasFeeColumns = project.budget_type === 2 || project.budget_type === 3;

  const headers = [
    {
      label: 'Name',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Logged',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Scheduled',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Difference',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPerson = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows = [];
  forEach(byPerson, (o, personId) => {
    const person = people[personId];
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = o.scheduled;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = o.logged.scheduled;
    const diffFee = hasFeeColumns ? (o.feeCents - o.logged.feeCents) / 100 : '';
    const diffHours = hours - loggedHours;

    totals.hours += o.scheduled;
    totals.loggedHours += o.logged.scheduled;
    if (hasFeeColumns) {
      totals.feeCents += o.feeCents;
      totals.loggedFeeCents += o.logged.feeCents;
    }

    rows.push({
      id: personId,
      data: [
        person.name,
        loggedFee,
        loggedHours,
        fee,
        hours,
        diffFee,
        diffHours,
      ],
      children: map(o.children, (c, taskName) => {
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = c.scheduled;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = c.logged.scheduled;
        const childDiffFee = hasFeeColumns
          ? (c.feeCents - c.logged.feeCents) / 100
          : '';
        const childDiffHours = childHours - childLoggedHours;

        return {
          data: [
            taskName,
            childLoggedFee,
            childLoggedHours,
            childFee,
            childHours,
            childDiffFee,
            childDiffHours,
          ],
        };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents - totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours - totals.loggedHours },
  ];

  return { headers, rows, footer };
}

export function getCombinedTable(ctx, raw) {
  const { project, people } = ctx;
  const hasFeeColumns = project.budget_type === 2 || project.budget_type === 3;

  const headers = [
    {
      label: 'Name',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 200,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Past logged',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 200,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Future scheduled',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 200,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Total',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPerson = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows = [];
  forEach(byPerson, (o, personId) => {
    const person = people[personId];
    const fee = hasFeeColumns ? o.future.feeCents / 100 : '';
    const hours = o.future.scheduled;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = o.logged.scheduled;
    const totalFee = hasFeeColumns
      ? (o.future.feeCents + o.logged.feeCents) / 100
      : '';
    const totalHours = hours + loggedHours;

    totals.hours += o.future.scheduled;
    totals.loggedHours += o.logged.scheduled;
    if (hasFeeColumns) {
      totals.feeCents += o.future.feeCents;
      totals.loggedFeeCents += o.logged.feeCents;
    }

    rows.push({
      id: personId,
      data: [
        person.name,
        loggedFee,
        loggedHours,
        fee,
        hours,
        totalFee,
        totalHours,
      ],
      children: map(o.children, (c, taskName) => {
        const childFee = hasFeeColumns ? c.future.feeCents / 100 : '';
        const childHours = c.future.scheduled;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = c.logged.scheduled;
        const childTotalFee = hasFeeColumns
          ? (c.future.feeCents + c.logged.feeCents) / 100
          : '';
        const childTotalHours = childHours + childLoggedHours;

        return {
          data: [
            taskName,
            childLoggedFee,
            childLoggedHours,
            childFee,
            childHours,
            childTotalFee,
            childTotalHours,
          ],
        };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents + totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours + totals.loggedHours },
  ];

  return { headers, rows, footer };
}
