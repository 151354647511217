import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getUser } from 'selectors';

import { usePrintContext } from '@float/common/contexts/PrintContext';
import useContainerSize from '@float/common/lib/useContainerSize';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getSearchFilteredProjects } from '@float/common/search/selectors/projects';
import { getActiveFilters } from '@float/common/selectors/views';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { getIsSingleProjectPlanView } from '@float/common/serena/util/getIsSingleProjectPlanView';
import { AppDispatchStrict } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { BaseFilterToken, Project } from '@float/types';
import { PageBody } from '@float/ui/deprecated/Layout/PageBody';
import { LogTimeModal } from 'components/modals/LogTimeModal';
import { useNavUpdater } from 'components/Nav/NavContext';

import { getTaskStatusString } from './components/TabBarFilters/StatusFilter';
import { getTeamModeString } from './components/TabBarFilters/TeamMode';
import { onUpdateLoggedTime } from './helpers/loggedTime';
import useReportsSettings from './helpers/useReportsSettings';
import ProjectDetails from './ProjectDetails/ProjectDetails';
import ProjectsOverview from './ProjectsOverview/ProjectsOverview';
import { ExportButton } from './ReportGlobalControls';
import * as styled from './styles';
import TeamCapacity from './TeamCapacity/TeamCapacity';
import { CsvExportConfig } from './types';

type ReportsProps = {
  searchFilteredProjects: Project[];
  filters: BaseFilterToken[];
  dispatch: AppDispatchStrict;
} & RouteComponentProps;

function ReportsComponent(props: ReportsProps) {
  const { viewType } = useScheduleContext();
  const { searchFilteredProjects, filters } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const reportsContentRef = useRef<HTMLDivElement>(null);

  const [width] = useContainerSize(reportsContentRef);
  const [csvExportConfig, setCsvExportConfig] = useState<CsvExportConfig>({
    options: [],
  });
  const reportsSettings = useReportsSettings();
  const printMode = usePrintContext().isPrinting;
  const [loggedTimeUpdateCount, setLoggedTimeUpdateCount] = useState(0);
  const [printModeHeader, setPrintModeHeader] = useState<{
    title: string;
    searchFiltersString: string;
  }>({
    title: '',
    searchFiltersString: '',
  });
  const [hideTeamModeFilters, setHideTeamModeFilters] = useState(false);

  const chartWidth = Math.max(1160, width);

  // should contain a single inclusive project filter
  // and also should result to single filtered project result
  // (if no projects returned still show the overview)
  const isSingleProjectView = getIsSingleProjectPlanView(
    viewType,
    filters,
    searchFilteredProjects.length,
  );

  const globalReportControlProps = {
    hideTeamModeFilters: hideTeamModeFilters,
    // `memberViewSelf` property isn't used in the component tree
    // instead the hideTeamModeFilters prop is explicitly defined
    memberViewSelf: undefined,
    csvExportConfig: csvExportConfig,
  };

  const reportPageProps = {
    width: chartWidth,
    reportsSettings: reportsSettings,
    setCsvExportConfig: setCsvExportConfig,
    printMode: printMode,
    loggedTimeUpdateCount: loggedTimeUpdateCount,
    setPrintModeHeader: setPrintModeHeader,
    setHideTeamModeFilters: setHideTeamModeFilters,
    wrapperRef,
    ...globalReportControlProps,
  };

  const navCtx = useNavUpdater();

  useEffect(() => {
    navCtx.registerQuickActionCtrls([
      <ExportButton key="export" options={csvExportConfig.options} />,
    ]);
    return () => {
      navCtx.registerQuickActionCtrls([]);
    };
  }, [csvExportConfig]);

  return (
    <>
      {printMode && <styled.ReportsPrintGlobalStyles />}
      <styled.BackgroundColor />
      <PageBody ref={wrapperRef}>
        <styled.ReportsContent ref={reportsContentRef}>
          {printMode && (
            <styled.PrintSummaryBar>
              <div className="title">
                <div className="report-name">{printModeHeader.title}</div>
                <div className="filters">
                  {printModeHeader.searchFiltersString}
                </div>
              </div>
              <div className="settings">
                <div className="date">
                  {moment(reportsSettings.settings.startDate).format(
                    'DD MMM YYYY',
                  )}{' '}
                  -{' '}
                  {moment(reportsSettings.settings.endDate).format(
                    'DD MMM YYYY',
                  )}
                </div>
                <div className="filters">
                  People: {getTeamModeString(reportsSettings.settings)}{' '}
                  &nbsp;Tasks: {getTaskStatusString(reportsSettings.settings)}
                </div>
              </div>
            </styled.PrintSummaryBar>
          )}

          {viewType === 'people' && <TeamCapacity {...reportPageProps} />}
          {viewType === 'projects' && !isSingleProjectView && (
            <ProjectsOverview {...reportPageProps} />
          )}
          {viewType === 'projects' && isSingleProjectView && (
            <ProjectDetails
              {...reportPageProps}
              projectId={searchFilteredProjects[0].project_id}
            />
          )}
        </styled.ReportsContent>
      </PageBody>
      <LogTimeModal
        pathname={props.location?.pathname}
        onSave={(e: unknown) => {
          onUpdateLoggedTime(props.dispatch, e).then(() =>
            setLoggedTimeUpdateCount((n) => n + 1),
          );
        }}
        isFullDayTimeoff={() => {
          // TODO: This needs to hook into the Serena workflow to properly
          // set the full day flag.
          return false;
        }}
      />
    </>
  );
}

const mapStateToProps = (state: ReduxStateStrict) => ({
  user: getUser(state),
  searchFilteredProjects: getSearchFilteredProjects(state),
  filters: getActiveFilters(state),
});

const mapDispatchToProps = (dispatch: AppDispatchStrict) => ({
  dispatch,
});

const Reports = connect(mapStateToProps, mapDispatchToProps)(ReportsComponent);

export { Reports };
