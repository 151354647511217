import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from 'react';
import classNames from 'classnames';

import { Tooltip, TooltipProps } from '@float/ui/components/Tooltip';
import {
  TooltipWithWarning,
  TooltipWithWarningProps,
} from '@float/ui/deprecated/Tooltip/TooltipWithWarning';

import { baseLayer } from './styles.css';

export type BaseEntityLayerProps = {
  tooltipWithWarningProps?: Omit<TooltipWithWarningProps, 'children'>;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  rounded?: boolean;
  roundedLarge?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
  transparent?: boolean;
  type?: 'ghost' | 'base';
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  'data-floatid'?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
};

// @test-export
export function getBaseEntityLayerClassName(props: BaseEntityLayerProps) {
  const roundedType = props.isStart
    ? props.isEnd
      ? 'both'
      : 'start'
    : props.isEnd
      ? 'end'
      : 'none';

  return classNames(
    baseLayer({
      rounded: props.rounded ? roundedType : 'none',
      roundness: props.roundedLarge ? 'large' : 'default',
      background: props.transparent ? 'transparent' : 'white',
      type: props.type,
    }),
    props.className,
  );
}

export const BaseEntityLayer = forwardRef(
  (props: BaseEntityLayerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      style,
      'data-floatid': dataFloatid,
      onClick,
      onMouseDown,
      tooltipProps,
      tooltipWithWarningProps,
    } = props;

    const content = (
      <div
        ref={ref}
        style={style}
        className={getBaseEntityLayerClassName(props)}
        data-floatid={dataFloatid}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        {children}
      </div>
    );

    if (tooltipWithWarningProps) {
      return (
        <TooltipWithWarning {...tooltipWithWarningProps}>
          {content}
        </TooltipWithWarning>
      );
    }

    if (tooltipProps) {
      return <Tooltip {...tooltipProps}>{content}</Tooltip>;
    }

    return content;
  },
);
