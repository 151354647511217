import { t } from '@lingui/macro';

export function getRoleTableHeadings(
  isRolesRatesFeatureEnabled: boolean,
  isCostRateFeatureActive: boolean,
) {
  let tableHeadNames: string[];
  let tableHeadNameKeys: string[];
  let tableColumnsWidth: string[] | undefined;

  if (!isRolesRatesFeatureEnabled) {
    tableHeadNames = [t`Roles`, t`People`];
    tableHeadNameKeys = ['name', 'people_count'];
    tableColumnsWidth = undefined;
  } else if (isCostRateFeatureActive) {
    tableHeadNames = [t`Roles`, t`Cost rate`, t`Bill rate`, t`People`];
    tableHeadNameKeys = [
      'name',
      'cost_rate',
      'default_hourly_rate',
      'people_count',
    ];
    tableColumnsWidth = ['40%', '22%', '22%', '10%'];
  } else {
    tableHeadNames = [t`Roles`, t`Hourly rate`, t`People`];
    tableHeadNameKeys = ['name', 'default_hourly_rate', 'people_count'];
    tableColumnsWidth = ['60%', '30%', '10%'];
  }

  return { tableHeadNames, tableHeadNameKeys, tableColumnsWidth };
}
