import { createSelector } from 'reselect';

import type { TasksState } from '@float/common/reducers/tasks';

// OPTIMIZATION: Object.values might become slow for big inputs.
export const selectTasksList = createSelector(
  [(state: { tasks: TasksState }) => state.tasks.tasks],
  (tasks) => {
    const tasksList = Object.values(tasks);

    return tasksList;
  },
);
