import { Role } from '@float/types';

import { roleTableRowDisplayConfig as view } from './roleTableRowDisplayConfig';
import { roleTableRowEditConfig as edit } from './roleTableRowEditConfig';
import { sanitizeRate } from './roleTableRowUtils';

export function getRoleTableRowWithoutCostRate(role: Role) {
  const data = [
    role.name,
    sanitizeRate(role.default_hourly_rate),
    role.people_count,
  ];

  const display = [
    view.roleName(role),
    view.billRate(role),
    view.peopleCount(role),
  ];

  const editConfig = [edit.roleName(), edit.billRate(), edit.peopleCount(role)];

  return { data, display, editConfig };
}
