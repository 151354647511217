import { omit } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { OneOffDay } from '@float/types';

import {
  ONEOFF_CREATED,
  ONEOFF_DELETED,
  ONEOFFS_LOAD_FAILED,
  ONEOFFS_LOAD_FINISH,
  ONEOFFS_LOAD_START,
} from '../actions';
import { LoadState, RehydratePartialStateAction } from './lib/types';

export type OneOffsState = {
  oneOffs: Record<number, OneOffDay>;
  loadState: LoadState;
  oneOffsLoaded: boolean;
};

const REDUCER_NAME = 'oneOffs';
export const DEFAULT_STATE: OneOffsState = {
  oneOffs: {},
  loadState: LoadState.UNLOADED,
  oneOffsLoaded: false,
};

export type OneOffsAction =
  | {
      type: typeof ONEOFFS_LOAD_START;
    }
  | {
      type: typeof ONEOFFS_LOAD_FAILED;
    }
  | {
      type: typeof ONEOFFS_LOAD_FINISH;
      rebuild?: boolean;
      oneOffs: Record<number, OneOffDay>;
    }
  | {
      type: typeof ONEOFF_CREATED;
      oneOff: OneOffDay;
    }
  | {
      type: typeof ONEOFF_DELETED;
      oneOff: OneOffDay;
    };

export default function reducer(
  state = DEFAULT_STATE,
  action:
    | OneOffsAction
    | RehydratePartialStateAction<OneOffsState, typeof REDUCER_NAME>,
): OneOffsState {
  switch (action.type) {
    case ONEOFFS_LOAD_START: {
      return {
        ...state,
        loadState: LoadState.LOADING,
      };
    }

    case ONEOFFS_LOAD_FAILED: {
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
      };
    }

    case ONEOFFS_LOAD_FINISH: {
      return {
        ...state,
        loadState: LoadState.LOADED,
        oneOffsLoaded: true,
        oneOffs: action.rebuild
          ? action.oneOffs
          : {
              ...state.oneOffs,
              ...action.oneOffs,
            },
      };
    }

    case ONEOFF_CREATED: {
      return {
        ...state,
        oneOffs: {
          ...state.oneOffs,
          [action.oneOff.oneoff_id]: action.oneOff,
        },
      };
    }

    case ONEOFF_DELETED: {
      return {
        ...state,
        oneOffs: omit(state.oneOffs, action.oneOff.oneoff_id),
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.oneOffsLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
}
