import { getBulkEditAccessField } from './getBulkEditAccessField';
import { getBulkEditBillRateField } from './getBulkEditBillRateField';
import { getBulkEditCostRateField } from './getBulkEditCostRateField';
import { getBulkEditDepartmentField } from './getBulkEditDepartmentField';
import { getBulkEditManagesField } from './getBulkEditManagesField';
import { getBulkEditPeopleTypeField } from './getBulkEditPeopleTypeField';
import { getBulkEditRegionField } from './getBulkEditRegionField';
import { getBulkEditRoleIdField } from './getBulkEditRoleIdField';
import { getBulkEditTagsField } from './getBulkEditTagsField';

export function getBulkEditPeopleFields(hasCostRateAccess = false) {
  const role_id = getBulkEditRoleIdField();
  const department_id = getBulkEditDepartmentField();
  const tags = getBulkEditTagsField();
  const people_type_id = getBulkEditPeopleTypeField();
  const default_hourly_rate = getBulkEditBillRateField();
  const region_id = getBulkEditRegionField();
  const access = getBulkEditAccessField();
  const management_group = getBulkEditManagesField();

  if (hasCostRateAccess) {
    const cost_rate_history = getBulkEditCostRateField();
    return {
      role_id,
      department_id,
      tags,
      people_type_id,
      cost_rate_history,
      default_hourly_rate,
      region_id,
      access,
      management_group,
    };
  }

  return {
    role_id,
    department_id,
    tags,
    people_type_id,
    default_hourly_rate,
    region_id,
    access,
    management_group,
  };
}
