import { isEmpty, isUndefined, omit } from 'lodash';

import { bulkUpdate } from '@float/common/api3/bulkUpdate';
import { getProjectsMap } from '@float/common/selectors/projects';
import { ensureBudgetsLoaded } from '@float/common/store/budgets/budgets.actions';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { moment } from '@float/libs/moment';
import { BudgetPriority, BudgetType, ProjectStatus } from '@float/types';
import type { ReduxStateStrict } from '@float/common/reducers/lib/types';
import type {
  AppDispatch,
  AppDispatchStrict,
  AppStoreStrict,
} from '@float/common/store';
import type { Project, ProjectTeamMemberData, RawProject } from '@float/types';

import { projects as ProjectsAPI } from '../../api3/projects';
import { getTrackingFields, trackEvent } from '../../lib/analytics';
import { getBudgetTypeText } from '../../lib/budget';
import { handleFail } from '../../lib/errors';
import { batch } from '../../lib/reduxBatch';
import { MILESTONES_UPDATE_START } from '../milestones/types';
import { sanitizeFetchedProject } from '../projects.helpers';
import { prefetchTaskMetas } from '../taskMetas';
import { UPDATE_TASK_BATCH } from '../tasks';
import {
  PROJECT_LOADED,
  PROJECTS_BULK_DELETED,
  PROJECTS_BULK_UPDATED,
  PROJECTS_DELETED,
  PROJECTS_UPDATED,
} from './actionTypes';
import type {
  AsyncProjectApiPayload,
  ProjectApiPayload,
  ProjectCreateOptions,
  ProjectInputData,
  ProjectTeamPayload,
} from './types';

export * from './actionTypes';
export * from './projects';
export * from './templates';
export type * from './types';
export * from './updateProjectFromSidePanel';

const getProjectExpandParam = (
  list: ('project_team' | 'project_dates' | 'set_dates' | 'estimates')[],
) => {
  const expandList = [...list];
  if (featureFlags.isFeatureEnabled(FeatureFlag.EstimateCapture)) {
    expandList.push('estimates');
  }
  return expandList.join(',');
};

async function fetchProject(projectId: Project['project_id']) {
  const res = await ProjectsAPI.getProject({
    id: projectId,
    query: {
      expand: getProjectExpandParam([
        'project_team',
        'project_dates',
        'set_dates',
      ]),
    },
  });
  return sanitizeFetchedProject(res);
}

const mapProjectTeam = (
  projectTeam?: ProjectTeamMemberData[] | { set: ProjectTeamMemberData[] },
) => {
  if (!projectTeam) return null;
  return 'set' in projectTeam
    ? projectTeam
    : {
        set: projectTeam.filter((pt) => pt.people_id),
      };
};

export const mapProjectToV3 = (
  newProject: ProjectInputData & {
    project_team?: ProjectTeamMemberData[];
  },
): ProjectApiPayload => ({
  client_id:
    newProject.client_id === 0 ? null : (newProject.client_id as number),
  color: newProject.color,
  tags: newProject.tags,
  project_manager: Number(newProject.project_manager),
  name: newProject.project_name,
  notes: newProject.description,
  notes_meta: newProject.notes_meta,
  active: newProject.active ? 1 : 0,
  all_pms_schedule: newProject.common ? 1 : 0,
  non_billable: newProject.non_billable ? 1 : 0,
  status: newProject.status,
  tentative: newProject.status === ProjectStatus.Tentative ? 1 : 0,
  locked_task_list: newProject.locked_task_list ? 1 : 0,
  project_team: mapProjectTeam(newProject.project_team),
  budget_priority: newProject.budget_priority || BudgetPriority.Project,
  budget_type: newProject.budget_type,
  budget_total:
    newProject.budget_priority === BudgetPriority.Project
      ? newProject.budget_total || null
      : null,
  start_date: newProject.start_date,
  end_date: newProject.end_date,
  default_hourly_rate:
    newProject.budget_type === BudgetType.Disabled ||
    newProject.budget_type === BudgetType.TotalHours
      ? null
      : newProject.default_hourly_rate,
  project_code: newProject.project_code || null,
});

const trackingFields = [
  'nonBillable',
  'tentative',
  'tasksLocked',
  'budgetType',
];

const projectToTracking = (
  project: RawProject | Project,
): {
  nonBillable: 1 | 0;
  tentative: 1 | 0;
  tasksLocked: boolean;
  budgetType: string | undefined;
  createdWithTemplate: number | null;
} => {
  return {
    nonBillable: project.non_billable ? 1 : 0,
    tentative: project.tentative ? 1 : 0,
    tasksLocked: !!project.locked_task_list,
    budgetType: getBudgetTypeText(project),
    createdWithTemplate: null,
  };
};

export function createProject(
  newProject: ProjectInputData & { project_team?: ProjectTeamMemberData[] },
  options?: ProjectCreateOptions,
) {
  return async (
    dispatch: AppDispatchStrict,
  ): Promise<RawProject | undefined> => {
    // We should make a specialized function to map ProjectInputData into the project api payload
    // TODO: https://linear.app/float-com/issue/FT-2130/tech-debt-make-a-specialized-function-to-map-projectinputdata-into-the
    const data = mapProjectToV3(newProject);
    try {
      const response = await ProjectsAPI.createProject({
        data,
        query: {
          expand: getProjectExpandParam([
            'project_team',
            'project_dates',
            'set_dates',
          ]),
        },
      });
      const project = sanitizeFetchedProject(response) as RawProject;
      dispatch({ type: PROJECTS_UPDATED, project });
      const trackingData = projectToTracking(project);
      trackingData.createdWithTemplate = options?.fromTemplate ?? null;
      trackEvent('Project added', trackingData);

      return project;
    } catch (e) {
      console.error(e);
      const { Error } = e as { Error: string };
      if (Error) {
        handleFail(null, Error);
      }
    }
  };
}

export function createProjectAsync(data: AsyncProjectApiPayload) {
  return async (_dispatch: AppDispatchStrict) => {
    try {
      const processId: string = await ProjectsAPI.createProjectFromTemplate({
        data,
      });
      return processId;
    } catch (e) {
      console.error(e);
      const { Error } = e as { Error: string };
      if (Error) {
        handleFail(null, Error);
      }
    }
  };
}

export function updateProject(
  id: Project['project_id'],
  changes: Partial<
    ProjectInputData & {
      project_team: ProjectTeamMemberData[] | ProjectTeamPayload;
    }
  >,
  isPatch = true,
) {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    // @ts-expect-error We should make a specialized function to map ProjectInputData into the project api payload
    // TODO: https://linear.app/float-com/issue/FT-2130/tech-debt-make-a-specialized-function-to-map-projectinputdata-into-the
    const data = isPatch ? changes : mapProjectToV3(changes);
    const response = await ProjectsAPI.updateProject({
      id,
      data: data as ProjectApiPayload,
      query: {
        expand: getProjectExpandParam(['project_team', 'project_dates']),
      },
    });
    const project = sanitizeFetchedProject(response) as RawProject;
    const prevProject = getState().projects.projects[id];
    dispatch({
      type: PROJECTS_UPDATED,
      project,
      prevProject: getState().projects.projects[id],
    });

    trackEvent(
      'Project updated',
      getTrackingFields(
        projectToTracking(prevProject),
        projectToTracking(project),
        trackingFields,
      ),
    );

    return project;
  };
}

export function deleteProjectV2(id: Project['project_id']) {
  return async (dispatch: AppDispatch) => {
    await ProjectsAPI.deleteProject({ id });
    dispatch({ type: PROJECTS_DELETED, projectId: +id });
  };
}

export function cloneProject(id: Project['project_id']) {
  return async (dispatch: AppDispatch) => {
    const res = await ProjectsAPI.cloneProject({ id });
    const clonedProjects = res && res.result && res.result[id];
    const clonedProjectId =
      clonedProjects.length && clonedProjects[0].project_id;
    if (clonedProjectId) {
      const project = await fetchProject(clonedProjectId);
      return dispatch({ type: PROJECTS_UPDATED, project });
    }
    return false;
  };
}

// Not really an action at the moment but it should probably be refactored
// and modularized somewhere here.
export function duplicateProject(
  newProject: ProjectInputData & {
    source_project_id: number;
    durationInDays: number;
    start_date?: string | null;
    end_date?: string | null;
  },
  initialProject: RawProject,
) {
  const projectId = newProject.source_project_id;
  const data: {
    start_date: string | null;
    end_date: string | null;
    attributes: Omit<ProjectApiPayload, 'all_pms_schedule' | 'project_team'>;
  } = {
    start_date: null,
    end_date: null,
    attributes: omit(mapProjectToV3(newProject), [
      'all_pms_schedule',
      'project_team',
      'project_code',
    ]),
  };
  if (
    !isUndefined(newProject.durationInDays) &&
    (newProject.start_date !== initialProject.start_date ||
      newProject.end_date !== initialProject.end_date)
  ) {
    data.start_date = newProject.start_date!;
    data.end_date = newProject.end_date!;
  }

  // Override to prevent duplication errors from unique constraint on project_code
  data.attributes.project_code = null;

  return ProjectsAPI.duplicateProject({ id: projectId, data });
}

const formatDate = (date: Moment) => {
  return date ? moment(date).format('YYYY-MM-DD') : null;
};

export const shiftProject = (
  id: Project['project_id'],
  startDate: Moment,
  endDate: Moment,
) => {
  const input = {
    id,
    data: {
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
    },
  };

  return async (dispatch: AppDispatch) => {
    const res = await ProjectsAPI.shiftProject(input);
    const project = await fetchProject(id);

    batch(() => {
      dispatch({ type: UPDATE_TASK_BATCH, tasks: res.task });
      res.milestone.forEach((milestone) =>
        dispatch({
          type: MILESTONES_UPDATE_START,
          milestone: {
            ...milestone,
            // start: new Date(milestone.start),
            // end_date: new Date(milestone.end_date),
          },
        }),
      );
      dispatch({ type: PROJECTS_UPDATED, project });
    });
  };
};

export const fetchBudget = (
  projectIds: Project['project_id'][],
  opts: {
    forceLoad?: boolean | undefined;
    notifyIfExceeded?: boolean | undefined;
    includeArchived?: boolean | undefined;
  },
) => {
  return async (dispatch: AppDispatch) => {
    await dispatch(
      ensureBudgetsLoaded(projectIds, { ...(opts || {}), forceLoad: true }),
    );
  };
};

export const ensureProjectLoaded = (projectId: Project['project_id']) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: AppStoreStrict['getState'],
  ) => {
    const project = await fetchProject(projectId);
    dispatch({ type: PROJECT_LOADED, project });

    return getProjectsMap(getState())[projectId];
  };
};

export const ensureProjectAndTasksLoaded = (
  projectId: Project['project_id'],
) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: AppStoreStrict['getState'],
  ) => {
    const [project] = await Promise.all([
      fetchProject(projectId),
      dispatch(prefetchTaskMetas(projectId)),
    ]);
    dispatch({ type: PROJECT_LOADED, project });
    return getProjectsMap(getState())[projectId];
  };
};

export const bulkUpdateProjects = (
  ids: Project['project_id'][],
  fields: Partial<Project>,
) => {
  return async (dispatch: AppDispatch) => {
    if (isEmpty(fields)) {
      return Promise.reject({ message: 'No changes made.' });
    }
    const response = await bulkUpdate({ type: 'projects', ids, fields });
    dispatch({ type: PROJECTS_BULK_UPDATED, ids, fields });
    return response;
  };
};

export const bulkDeleteProjects = (ids?: Project['project_id'][]) => {
  return async (dispatch: AppDispatch) => {
    if (!ids?.length) return Promise.resolve(true);
    if (ids.length === 1) {
      return dispatch(deleteProjectV2(ids[0]));
    }
    const response = await bulkUpdate({
      type: 'projects',
      ids,
      action: 'bulk-delete',
    });
    dispatch({ type: PROJECTS_BULK_DELETED, ids });
    return response;
  };
};

export function fetchOffsets(projectId: Project['project_id']) {
  return ProjectsAPI.getOffsets(projectId);
}
