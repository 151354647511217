import React, { useMemo } from 'react';
import { getPeopleMap, getUser } from 'selectors';

import { Access as AccessACL } from '@float/common/lib/acl';
import { isCostRateFeatureActiveForUser } from '@float/common/lib/rates/rates';
import { useAppSelectorStrict } from '@float/common/store';
import { AccountType } from '@float/constants/accounts';
import BulkEditModal from 'components/elements/BulkEditModal/BulkEditModal';

import { checkIsPeopleManager } from '../PersonModal/access/PersonAccess';
import { getBulkEditPeopleFields } from './getBulkEditPeopleFields';

export const BulkEditPeopleModal = (props) => {
  const currentUser = useAppSelectorStrict(getUser);
  const people = useAppSelectorStrict(getPeopleMap);

  const hasCostRateAccess = isCostRateFeatureActiveForUser(currentUser);
  const fields = useMemo(
    () => getBulkEditPeopleFields(hasCostRateAccess),
    [hasCostRateAccess],
  );

  const bulkEditPeople = useMemo(() => {
    return props.ids.map((pid) => people[pid]);
  }, [people, props.ids]);

  const allowedFields = useMemo(() => {
    const nextFields = { ...fields };

    for (const person of bulkEditPeople) {
      const canUpdateManages =
        checkIsPeopleManager(person.account) &&
        AccessACL.canUpdateAccountManagementGroup(currentUser, person);
      if (!canUpdateManages) {
        delete nextFields.management_group;
      }

      const hasAccount = !!person.account;
      const isAccountOwner =
        hasAccount && person.account.account_type === AccountType.AccountOwner;
      const canUpdateAccess = AccessACL.canUpdateBulkAccountAccess(
        currentUser,
        person,
      );
      if (!hasAccount || !canUpdateAccess || isAccountOwner) {
        delete nextFields.access;
      }

      const canSkip = !nextFields.access && !nextFields.management_group;
      if (canSkip) {
        break;
      }
    }

    return nextFields;
  }, [currentUser, bulkEditPeople, fields]);

  return (
    <BulkEditModal
      {...props}
      type="person"
      plural="people"
      entities={people}
      fields={allowedFields}
    />
  );
};
