/**
 * The version of getReportQueryFromFilters implemented using the Search Beyond Limits
 * services instead of the local search data
 */

import { SearchResolveFilterSpecialValue } from '@float/common/api3/search.constants';
import type { FilterToken } from '@float/types';

import { lookupFilter } from '../../api/lookupFilter';
import {
  addGlobalFilters,
  getAsQuerystringTuple,
  parsePersonType,
  parseProjectStatus,
  parseTaskStatus,
  parseTimeOffStatus,
  toArray,
} from './getReportQueryFromFilters.helpers';
import type { ReportQueryField, ReportQuerySettings } from './types';

function isValidTimeoffFilterForReports(filter: FilterToken) {
  const filterValues = toArray(filter.val);
  const isMatchingAnyTimeoffType = filterValues.includes('*');

  const { operator: rawOperator = '' } = filter;
  const shouldIncludeAllTimeoffType = rawOperator === '';

  if (isMatchingAnyTimeoffType && shouldIncludeAllTimeoffType) {
    // Reports implements a filter algorithm. The equivalent of the FE "*" glob filter
    // on reports is to NOT filter any timeoff_type.
    return false;
  }

  return true;
}

export async function getReportQueryFromFiltersRemote(
  searchFilters: FilterToken[],
  settings: ReportQuerySettings,
  meFilter: boolean,
  extra: { getPeopleManagedByAccounts: (ids: number[]) => number[] },
) {
  const queries: ReportQueryField[][] = [];
  let currentQuery: ReportQueryField[] = [];

  const validFilters = searchFilters.filter(isValidTimeoffFilterForReports);

  for (const filter of validFilters) {
    const { type, operator: rawOperator = '' } = filter;

    // ensure "val" is always an array
    const val = toArray(filter.val);
    // convert operator to expected value for API call
    const operator = rawOperator.includes('-') ? 'nin' : 'in';

    // check for OR operator and prepare current query, if applicable
    const isOR = rawOperator.includes('|');
    if (isOR) {
      if (currentQuery.length) queries.push(currentQuery);
      currentQuery = [];
    }
    switch (type) {
      // IDs in context
      case 'department': {
        const value = await lookupFilter(filter);

        currentQuery.push({ field: 'department_id', operator, value });
        break;
      }
      case 'client': {
        const value = await lookupFilter(filter);

        currentQuery.push({ field: 'client_id', operator, value });
        break;
      }
      case 'manager': {
        const managerIds = await lookupFilter(filter);

        const peopleIds = extra.getPeopleManagedByAccounts(managerIds);

        currentQuery.push({ field: 'people_id', operator, value: peopleIds });
        break;
      }
      case 'person': {
        const value = await lookupFilter(filter);

        currentQuery.push({ field: 'people_id', operator, value });
        break;
      }
      case 'projectOwner': {
        const value = await lookupFilter(filter);

        currentQuery.push({ field: 'project_creator_id', operator, value });
        break;
      }
      case 'project': {
        const value = await lookupFilter(filter);

        currentQuery.push({ field: 'project_id', operator, value });
        break;
      }
      case 'phase': {
        const value = await lookupFilter(filter);

        currentQuery.push({ field: 'phase_id', operator, value });
        break;
      }

      case 'jobTitle': {
        const firstValue = val.at(0);
        const isNone =
          firstValue &&
          ['None', SearchResolveFilterSpecialValue.None].includes(firstValue);

        if (isNone) {
          const value = await lookupFilter({
            ...filter,
            val: [SearchResolveFilterSpecialValue.None],
          });

          // Querying by role_id allows returning results where role_id is null
          currentQuery.push({ field: 'role_id', operator, value });

          break;
        }

        currentQuery.push({ field: 'job_title', operator, value: val });

        break;
      }

      // logic shared with global queries
      case 'taskStatus': {
        currentQuery.push(
          ...Object.values(parseTaskStatus(filter, settings.taskStatus)),
        );
        break;
      }
      // logic shared with global queries
      case 'timeoffStatus': {
        currentQuery.push(
          ...Object.values(
            parseTimeOffStatus(filter, [], settings.timeoffApprovalsEnabled),
          ),
        );
        break;
      }
      case 'personType': {
        currentQuery.push(...Object.values(parsePersonType(filter)));
        break;
      }
      // one filter but can lead to different fields on API level
      case 'projectStatus': {
        currentQuery.push(parseProjectStatus(operator, val));
        break;
      }
      // Strings - should be replaced to ids as we have the information available
      case 'task': {
        const metaName = val.includes('No task used') ? ['No Task Name'] : val;
        currentQuery.push({
          field: 'task_meta_name',
          operator,
          value: metaName,
        });
        break;
      }
      case 'projectTag': {
        currentQuery.push({ field: 'project_tag_name', operator, value: val });
        break;
      }
      case 'personTag': {
        currentQuery.push({ field: 'people_tag_name', operator, value: val });
        break;
      }
      case 'timeoff': {
        currentQuery.push({ field: 'timeoff_type_name', operator, value: val });
        break;
      }
    }
  }

  // apply current query, if required
  if (currentQuery.length) {
    queries.push(currentQuery);
  }

  addGlobalFilters(queries, settings, meFilter);

  return getAsQuerystringTuple(queries, settings);
}
