import React, { Component } from 'react';
import {
  formatActivityType,
  refineActionName,
} from '@floatschedule/activity-format-npm';
import { t, Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { getTimeAgo } from '@float/common/lib/utils';
import { capitalize } from '@float/libs/utils/string/capitalize';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import IconActivity from '@float/ui/deprecated/Icons/icon-activity';
import Theme from '@float/ui/deprecated/Theme';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';

import taskActivityConnect from '../actions/taskActivityConnect';
import { ActivityAvatar } from './ActivityAvatar';
import {
  buildDisplayHistoryItems,
  getUserNameByAccountId,
} from './TaskModalActivity.helpers';
import { ActivityListItem } from './TaskModalActivity.styles';

const ActivityFeed = styled.div`
  display: flex;
  position: relative;
  ${(p) =>
    p.readOnly && !p.isPreviewingBeforeEdit
      ? css`
          top: 0;
          margin-bottom: 60px;
        `
      : css`
          top: -6px;
          margin-top: ${p.isStatusMode ? '17px' : '0'};
          margin-bottom: 26px;
        `}
`;

const FirstActivity = styled.div`
  flex-grow: 1;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.blueGrey};
  display: flex;
  align-items: center;
  padding-right: 10px;

  .icon-activity {
    margin-right: 2px;
  }
`;

const ViewActivity = styled.div`
  margin-left: auto;
`;

const ActivityList = styled.div`
  width: 350px;
  max-height: 300px;
  overflow-y: auto;
  margin: -9px -14px -8px;
  padding-top: 8px;
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: ${Theme.blueGrey};
  text-align: left;

  .with-bold b {
    color: ${Theme.blueGrey};
  }

  strong,
  b {
    margin: 0 1px;
  }
`;

const getAddedLabel = (task) => {
  return task.timeoff_id && (task.status_creator_id || task.status_request)
    ? 'Requested'
    : 'Added';
};

class TaskModalActivity extends Component {
  UNSAFE_componentWillMount() {
    const { task_id, timeoff_id, status_id, logged_time_id } = this.props.task;
    const itemId = this.getItemId(
      logged_time_id || task_id || timeoff_id || status_id,
    );
    if (logged_time_id) {
      this.props.requestTaskIdActivity({ 'logged-time-ids': [itemId] });
    } else if (task_id) {
      this.props.requestTaskIdActivity({ 'task-ids': [itemId] });
    } else if (timeoff_id) {
      this.props.requestTaskIdActivity({ 'timeoff-ids': [itemId] });
    } else if (status_id) {
      this.props.requestTaskIdActivity({ 'status-ids': [itemId] });
    }
  }

  getItemId = (id) => (id || '').toString().split('_')[0];

  renderActivity(item, i, task) {
    let items = [];
    if (i !== 0 && item.activity_type === 'update') {
      const userName = getUserNameByAccountId(
        this.getCurrentActivities(),
        this.props.accounts,
        this.props.people,
        item.actioned_by.account_id,
        item.actioned_by.name,
      );

      items.push(
        <ActivityListItem key={`${item.activity_id}${i}`}>
          <ActivityAvatar accountId={item.actioned_by.account_id} />
          <span className="ignore-bold">Updated by</span>
          <strong>{` ${userName} `}</strong>
          <span className="ignore-bold">
            <strong>{getTimeAgo(item.actioned_timestamp)}</strong>
          </span>
        </ActivityListItem>,
      );
    } else if (i !== 0) {
      const userName = getUserNameByAccountId(
        this.getCurrentActivities(),
        this.props.accounts,
        this.props.people,
        item.actioned_by.account_id,
        item.actioned_by.name,
      );

      items.push(
        <ActivityListItem key={`${item.activity_id}${i}`}>
          <ActivityAvatar accountId={item.actioned_by.account_id} />
          <strong>{`${userName} `}</strong>
          <span className="ignore-bold">{`${refineActionName(
            formatActivityType(item, this.props.user),
          )} `}</span>
          <span className="ignore-bold">
            <strong>{item.people_name}</strong>
          </span>
        </ActivityListItem>,
      );
    }

    const displayHistoryItems = buildDisplayHistoryItems(
      item.activity_type,
      item.activity_id,
      item.displayHistory,
      (id, name) =>
        getUserNameByAccountId(
          this.getCurrentActivities(),
          this.props.accounts,
          this.props.people,
          id,
          name,
        ),
    );

    items = items.concat(displayHistoryItems);

    if (items.length) {
      const creatorName = getUserNameByAccountId(
        this.getCurrentActivities(),
        this.props.accounts,
        this.props.people,
        task.created_by ?? task.creator_id,
        this.getCreatedByName(),
      );

      items = items.concat(
        <ActivityListItem key={`${item.activity_id}${i}_created`}>
          <ActivityAvatar accountId={task.created_by} />
          <span className="ignore-bold">{getAddedLabel(task)} by</span>
          <strong>{` ${creatorName} `}</strong>
          <span className="ignore-bold">{getTimeAgo(task.created, true)}</span>
        </ActivityListItem>,
      );
    }
    return items;
  }

  getCurrentActivities() {
    return this.props.parseCurrentActivity(
      this.props.task,
      this.props.modalActivity,
    );
  }

  getCreatedByName() {
    const activites = this.props.parseCurrentActivity(
      this.props.task,
      this.props.allModalActivity,
    );

    const activity = activites.find(
      (activity) => activity.activity_type === 'create',
    );

    return activity?.actioned_by.name || t`Automatic`;
  }

  getActivitiesToRender() {
    const { task } = this.props;
    let firstActivity = null;
    const wrapper = [];

    const currentActivity = this.getCurrentActivities();

    const lastActivity = currentActivity.find(
      (item) => item.displayHistory.length,
    );

    if (
      !this.props.composed &&
      currentActivity.length &&
      lastActivity &&
      lastActivity.activity_type !== 'create'
    ) {
      const userName = getUserNameByAccountId(
        this.getCurrentActivities(),
        this.props.accounts,
        this.props.people,
        lastActivity.actioned_by.account_id,
        lastActivity.actioned_by.name,
      );

      wrapper.push(
        <ActivityListItem id="pop-add_by" key="pop-add_by_by">
          <ActivityAvatar accountId={lastActivity.actioned_by.account_id} />
          <span id="pop-mod_status">
            <span>{`${capitalize(
              refineActionName(
                formatActivityType(lastActivity, this.props.user),
              ),
            )} by `}</span>
            <strong key="last-updated">{userName}</strong>
            <span className="ignore-bold">{` ${getTimeAgo(
              lastActivity.actioned_timestamp,
            )}`}</span>
          </span>
        </ActivityListItem>,
      );
      firstActivity = (
        <span>
          {capitalize(
            refineActionName(formatActivityType(lastActivity, this.props.user)),
          )}{' '}
          by <strong>{userName}</strong>{' '}
          {getTimeAgo(lastActivity.actioned_timestamp)}
        </span>
      );
    }

    if (!this.props.composed) {
      wrapper.push(
        currentActivity.map((activity, i) =>
          this.renderActivity(activity, i, task),
        ),
      );
    }

    if (
      (!lastActivity || !lastActivity.displayHistory.length) &&
      !this.props.composed
    ) {
      const creatorName = getUserNameByAccountId(
        this.getCurrentActivities(),
        this.props.accounts,
        this.props.people,
        task.created_by ?? task.creator_id,
        this.getCreatedByName(),
      );
      wrapper.push(
        <ActivityListItem key="added_key">
          <ActivityAvatar accountId={task.created_by} />
          <span className="ignore-bold">{getAddedLabel(task)} by</span>
          <strong>{` ${creatorName} `}</strong>
          <span className="ignore-bold">{getTimeAgo(task.created, true)}</span>
        </ActivityListItem>,
      );
      firstActivity = (
        <span>
          {getAddedLabel(task)} by <strong>{creatorName}</strong>{' '}
          {getTimeAgo(task.created, true)}
        </span>
      );
    }

    return { firstActivity, activities: wrapper };
  }

  render() {
    const {
      assignedToExtra,
      readOnly,
      isStatusMode,
      isPreviewingBeforeEdit,
      style = {},
    } = this.props;
    const { firstActivity, activities } = this.getActivitiesToRender();
    const showViewActivity = !!(
      activities &&
      activities.length > 1 &&
      activities[1].length
    );

    return (
      <ActivityFeed
        isStatusMode={isStatusMode}
        readOnly={readOnly}
        isPreviewingBeforeEdit={isPreviewingBeforeEdit}
        style={style}
      >
        {firstActivity && (
          <FirstActivity>
            <IconActivity />
            {firstActivity}
          </FirstActivity>
        )}
        {showViewActivity && (
          <ViewActivity>
            <Popover
              placement="top-end"
              content={
                <ActivityList className="list--key-value list--assigned">
                  {assignedToExtra ? (
                    <ActivityListItem id="pop-assigned_by">
                      {assignedToExtra}
                    </ActivityListItem>
                  ) : null}
                  {activities}
                </ActivityList>
              }
            >
              <TextButton appearance="flue-fill" tabIndex={-1}>
                <Trans>View changes</Trans>
              </TextButton>
            </Popover>
          </ViewActivity>
        )}
      </ActivityFeed>
    );
  }
}
const ConnectedTaskModalActivity = taskActivityConnect(TaskModalActivity);
export default ConnectedTaskModalActivity;
