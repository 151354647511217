import { STARTER_MONTHLY, STARTER_YEARLY } from '@float/common/lib/pricing';
import { AddOnStatus } from '@float/types/account';

type GetIsStarterPlanParams = {
  isPaidPlan: boolean;
  isProTrial: AddOnStatus | null;
  plusPack: AddOnStatus;
  proTrialEnd: string | null;
  timeTracking: AddOnStatus;
};

export const getIsStarterPlan = (
  id: string | number | undefined,
  params: GetIsStarterPlanParams,
) => {
  const { isPaidPlan, isProTrial, plusPack, proTrialEnd, timeTracking } =
    params;

  const planId = Number(id);
  const hasPlusPack = plusPack > 0;
  const hasTimeTracking = timeTracking > 0;
  const hasNoAddons = !hasTimeTracking && !hasPlusPack;

  if (isPaidPlan) {
    // Customer on paid Starter plan, but is trialing Pro
    if (
      (timeTracking === AddOnStatus.TRIAL && plusPack === AddOnStatus.TRIAL) ||
      (isProTrial === AddOnStatus.TRIAL && proTrialEnd)
    ) {
      return false;
    }

    if (
      planId === Number(STARTER_MONTHLY.plan_id) ||
      planId === Number(STARTER_YEARLY.plan_id)
    ) {
      return true;
    }

    // Special case for customers with RP + PP (Beta)
    if (!hasTimeTracking && Number(plusPack) === AddOnStatus.BETA) {
      return true;
    }
  }

  // Legacy paid plans and Trial plans considered "Starter" when neither TT nor PP enabled
  return Boolean(hasNoAddons);
};
