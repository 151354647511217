import React from 'react';
import cn from 'classnames';

import { getRateSuffix } from '@float/common/lib/rates/rates';

import { useRateTextParts } from './useRateText';

import * as styles from './RateText.styles.css';

type RateTextProps = {
  className?: string;
  isHoursBased?: boolean;
  rate: string | null | undefined;
};

export function RateText(props: RateTextProps) {
  const { className, isHoursBased = true, rate } = props;

  const { formattedRate, prefix, suffix } = useRateTextParts(rate);

  if (!formattedRate) return null;

  const perHourSuffix = getRateSuffix();

  const hasCurrencyPrefix = Boolean(prefix);
  const hasCurrencySuffix = Boolean(suffix);
  const hasPerHourSuffix = isHoursBased && Boolean(perHourSuffix);

  return (
    <span className={cn(styles.rateText, className)}>
      {hasCurrencyPrefix && <span className={styles.subdued}>{prefix} </span>}
      <span>{formattedRate}</span>{' '}
      {hasCurrencySuffix && <span className={styles.subdued}>{suffix} </span>}
      {hasPerHourSuffix && (
        <span className={styles.subdued}>{perHourSuffix}</span>
      )}
    </span>
  );
}
