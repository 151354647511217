import { createSelector } from 'reselect';

import { accumulateDecimalHours } from '@float/common/lib/timer/accumulateDecimalHours';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import { getSearchFilteredActivePeople } from '@float/common/selectors/people';

import { createInsightsEntry } from '../helpers/createInsightsEntry';
import {
  getPersonInsightRange,
  isOutsideRange,
} from '../helpers/isOutsideRange';
import { InsightsEntry, TimeRangeInsightsParams } from '../types';
import { getTimeRangeLoggedTimesByPerson } from './getTimeRangeLoggedTimesByPerson';
import { selectIteratorOverAllLoggedTimeSuggestions } from './selectIteratorOverAllLoggedTimeSuggestions';

export const getLoggedTimeRangeInsights = createSelector(
  [
    (
      _: unknown,
      params: Pick<TimeRangeInsightsParams, 'endDate' | 'startDate'>,
    ) => params,
    selectDatesManager,
    getSearchFilteredActivePeople,
    getTimeRangeLoggedTimesByPerson,
    selectIteratorOverAllLoggedTimeSuggestions,
  ],
  // Using a named function to have it visible in the performance profiling traces
  function getLoggedTimeRangeInsights(
    params,
    dates,
    people,
    loggedTimes,
    forEachLoggedTimeSuggestion,
  ) {
    const { startDate, endDate } = params;

    const total = createInsightsEntry();
    const byPerson: Record<number, InsightsEntry> = {};

    for (const person of people) {
      const data = createInsightsEntry();

      const range = getPersonInsightRange(
        dates,
        person,
        dates.toNum(startDate),
        dates.toNum(endDate),
      );

      for (const entity of loggedTimes.get(person.people_id) || []) {
        if (!isOutsideRange(range, dates.toNum(entity.date))) {
          data.logged = accumulateDecimalHours(data.logged, entity.hours);
        }
      }

      forEachLoggedTimeSuggestion(person, (entity) => {
        data.h = accumulateDecimalHours(data.h, entity.hours);
      });

      byPerson[person.people_id] = data;
      total.h = accumulateDecimalHours(total.h, data.h);
      total.logged = accumulateDecimalHours(total.logged, data.logged);
    }

    return {
      byPerson,
      byProject: {} as Record<
        number,
        InsightsEntry & Record<number, InsightsEntry>
      >,
      total,
    };
  },
);
