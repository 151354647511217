import { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';

import {
  AllMediaSizeKeys,
  MediaSize,
  MediaSizeQueries,
} from '@float/constants/media';

// A record of media rule builders for each breakpoint.
// When passed `Breakpoint` returns a function that that accepts a styles object and
// returns an `@media` rule for the given breakpoint.
export const CSS_MEDIA_RULE_BUILDERS_BY_BREAKPOINT = AllMediaSizeKeys.reduce(
  (
    accumulator: Record<
      MediaSize,
      (
        styles: TemplateStringsArray | FlattenSimpleInterpolation,
      ) => ReturnType<typeof css>
    >,
    label: MediaSize,
  ) => {
    accumulator[label] = (
      styles: TemplateStringsArray | FlattenSimpleInterpolation,
    ) => {
      return css`
        @media ${MediaSizeQueries[label as keyof typeof MediaSizeQueries]} {
          ${styles.join('')}
        }
      `;
    };

    return accumulator;
  },
  {} as Record<
    MediaSize,
    (
      styles: TemplateStringsArray | FlattenSimpleInterpolation,
    ) => ReturnType<typeof css>
  >,
);
