// TODO: Use better floatig point precision datatype.
// E.g. http://jsfromhell.com/classes/bignumber

import { isNil } from 'es-toolkit';

import { isAmountEmpty } from './isAmountEmpty';

// Do not use parseInt below. E.g. parseInt(16.83 * 100, 10) is 1682,
// which throws off currency calculations.
export const toCents = (str: number | string | null | undefined) => {
  if (isNil(str)) return 0;

  if (isAmountEmpty(str)) return 0;

  if (typeof str === 'string') {
    const num = Number(str);

    if (isNaN(num)) return 0;

    return Math.round(num * 100);
  }

  return Math.round(str * 100);
};
