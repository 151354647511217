import { useCallback, useMemo, useState } from 'react';
import { get, omit } from 'lodash';

import {
  ActivityFeedDateRange,
  ActivityFeedFilters,
  areAllFiltersEmpty,
  EMPTY_FILTER,
  getFilterLabel,
  getFiltersDisplayValues,
  getFiltersForApiQuery,
  getFiltersOnPageLoad,
} from './filters.helpers';

type FilterChangeOption = {
  value: string;
  label?: string;
  selectedLabel?: string;
};

export function useActivityFeedFilters(
  rangeFilter: ActivityFeedDateRange,
  isFilterFeatureEnabled: boolean,
) {
  const [filters, setFilters] = useState(() =>
    getFiltersOnPageLoad(isFilterFeatureEnabled),
  );

  const [shouldShowFilters, setShouldShowFilters] = useState(
    () => !areAllFiltersEmpty(filters),
  );

  const [filtersDisplayValues, setFiltersDisplayValues] = useState<
    Record<string, string>
  >(() => getFiltersDisplayValues(filters));

  const filtersForApiQuery = useMemo(
    () => getFiltersForApiQuery(rangeFilter, filters, shouldShowFilters),
    [rangeFilter, filters, shouldShowFilters],
  );

  const onFilterChange = (
    key: keyof ActivityFeedFilters,
    e: FilterChangeOption,
  ) => {
    const value = get(e, 'value', EMPTY_FILTER);
    setFilters((prev) => ({ ...prev, [key]: value }));

    const optionLabel = getFilterLabel(key);
    if (!optionLabel) return;

    const optionText = e.selectedLabel || e.label;

    setFiltersDisplayValues((prev) =>
      value === EMPTY_FILTER
        ? omit(prev, optionLabel)
        : { ...prev, [optionLabel]: optionText },
    );
  };

  const clearFilters = useCallback(() => {
    setFilters({
      project: EMPTY_FILTER,
      person: EMPTY_FILTER,
      action: EMPTY_FILTER,
      actionedBy: EMPTY_FILTER,
      itemType: EMPTY_FILTER,
    });
    setFiltersDisplayValues({});
  }, []);

  const isFilterApplied = !areAllFiltersEmpty(filters);

  return {
    filters,
    filtersDisplayValues,
    filtersForApiQuery,
    isFilterApplied,
    shouldShowFilters,
    clearFilters,
    onFilterChange,
    setShouldShowFilters,
  };
}
