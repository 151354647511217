import React, { useMemo, useState } from 'react';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { noop } from '@float/libs/utils/noop';
import { Input } from '@float/ui/deprecated/Input/Input';
import {
  createNumberParser,
  useNumericInput,
} from '@float/ui/hooks/useNumericInput';

import { useFormattedCurrency } from './hooks/useFormattedCurrency';

export type InputCurrencyProps = {
  autoFocus?: boolean;
  className?: string;
  currency: string;
  currencySymbol?: string;
  errors?: string[];
  isHoursBased?: boolean;
  label?: string;
  locale: string;
  onChange?: (value: number | null) => void;
  onFocus?: () => void;
  placeholder?: number;
  placeholderOverrideEnabled?: boolean;
  required?: boolean;
  value?: number;
};

export const InputCurrency = (props: InputCurrencyProps) => {
  const {
    autoFocus,
    className,
    currency,
    currencySymbol,
    errors,
    isHoursBased,
    label,
    locale,
    onChange = noop,
    onFocus = noop,
    placeholder = 0,
    required = false,
    value: inputValue,
  } = props;

  const { format } = useMemo(() => createNumberParser(locale), [locale]);

  const [currentValue, setCurrentValue] = useState<number | null>(
    inputValue === undefined ? null : Number(inputValue),
  );

  const {
    onBlur,
    onChange: handleOnChange,
    value,
  } = useNumericInput({
    value: currentValue,
    onChange: setCurrentValue,
    locale,
  });

  const { prefix, suffix } = useFormattedCurrency({
    currency,
    currencySymbol,
    isHoursBased,
    locale,
  });

  const placeholderOverrideEnabled = Boolean(
    props.placeholderOverrideEnabled && value && placeholder > 0,
  );

  useUpdateEffect(() => {
    onChange(currentValue);
  }, [value]);

  useUpdateEffect(() => {
    setCurrentValue(Number(inputValue));
    onChange(Number(inputValue));
  }, [inputValue]);

  return (
    <Input
      autoFocus={autoFocus}
      ariaLabel={label}
      className={className}
      errors={errors}
      label={label}
      onBlur={onBlur}
      onChange={handleOnChange}
      onFocus={onFocus}
      placeholder={format(placeholder)}
      placeholderOverrideEnabled={placeholderOverrideEnabled}
      prefix={prefix}
      required={required}
      suffix={suffix}
      value={value}
    />
  );
};
