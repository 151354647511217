import { t } from '@lingui/macro';
import { create, enforce, test } from 'vest';

import { config } from '@float/libs/config';

export const MAX_RATE = 1_000_000;

export const getMaxValueError = (
  value: number,
  opts: {
    inclusive?: boolean;
    type: 'value' | 'length';
  } = { inclusive: false, type: 'value' },
) => {
  const maxValue = value.toLocaleString(config.locale);

  if (opts.type === 'length') {
    return opts.inclusive
      ? t`You can't submit a value longer than ${maxValue} characters`
      : t`You can't submit a value longer than or equal to ${maxValue} characters`;
  }

  return opts.inclusive
    ? t`You can't submit a value higher than ${maxValue}`
    : t`You can't submit a value higher than or equal to ${maxValue}`;
};

export const rateValidationSuite = create((props: { rate: number }) => {
  test('rate', t`Required`, () => {
    enforce(props.rate).isNumeric();
  });

  test('rate', getMaxValueError(MAX_RATE), () => {
    enforce(props.rate).lessThan(MAX_RATE);
  });
});
