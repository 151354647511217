import { normalizeRateString } from '@float/common/lib/rates/rates';

import { PersonFormData } from '../people/helpers/mapV3PersonToSave';

export function getBillRatePayload(formData?: Partial<PersonFormData>) {
  const billRate = formData?.default_hourly_rate;

  if (billRate === undefined) {
    return {};
  }

  return { default_hourly_rate: normalizeRateString(billRate) };
}
