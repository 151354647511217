import { t } from '@lingui/macro';
import {
  amountFormatter,
  feeAndHoursFromatter,
  marginFormatter,
} from 'reports/helpers/tableFormatters';
import { getBudgetDataObject } from 'reports/ProjectsOverview/parser/table/helpers/budget';

import { formatAmount, toCents } from '@float/common/lib/budget';
import {
  ProjectBudgetPriority,
  ProjectBudgetType,
} from '@float/constants/projects';
import { getIsMoneyBudgetType } from '@float/web/reports/helpers/getIsMoneyBudgetType';
import type { RawTableData } from '@float/web/reports/useReportsStateReducer';

import { getTotalsByPhase } from './getTotalsByPhase';
import type { ParseTableContext, TableDataRowItem } from '../../table.types';

export function getPhasesScheduledTable(
  ctx: ParseTableContext,
  raw: RawTableData,
) {
  const { project, phases, hasBudgetsAccess, hasCostsAccess } = ctx;
  const isMoneyBudgetType = getIsMoneyBudgetType(project.budget_type);

  const isSecondaryTable = !project.non_billable && !ctx.billable;

  const hasBudgetColumns =
    hasBudgetsAccess && project.budget_priority === ProjectBudgetPriority.Phase;

  const hasFeeColumns =
    hasBudgetsAccess && isMoneyBudgetType && !isSecondaryTable;

  const hasCostColumns = hasCostsAccess;
  const hasBudgetRemainingColumns = hasBudgetColumns;
  const hasMarginColumns = false; // uncomment when we implement margins  isHourlyFeeBudgetType && hasBudgetsAccess && hasCostColumns;

  const headerNameColumns = [
    {
      label: ctx.billable ? t`Billable` : t`Non-billable`,
      align: 'flex-start',
      grow: 1,
    },
  ];

  const headerBudgetColumns = hasBudgetColumns
    ? [
        {
          label: isSecondaryTable ? '' : t`Budget`,
          width: 130,
          formatter: isSecondaryTable ? () => '' : amountFormatter,
        },
      ]
    : [];

  const headerScheduledColumns = [
    {
      label:
        hasBudgetsAccess && isMoneyBudgetType && !isSecondaryTable
          ? t`Scheduled billable`
          : t`Scheduled hours`,
      width: 200,
      formatter: feeAndHoursFromatter,
    },
  ];

  const headerCostColumns = hasCostColumns
    ? [
        {
          label: t`Scheduled cost`,
          width: 160,
          formatter: (x: string) =>
            x === '' ? '' : formatAmount(ProjectBudgetType.HourlyFee, x),
        },
      ]
    : [];

  const headerBudgetRemainingColumns = hasBudgetRemainingColumns
    ? [
        {
          label: isSecondaryTable ? '' : t`Budget remaining`,
          width: 180,
          formatter: isSecondaryTable ? () => '' : amountFormatter,
        },
      ]
    : [];

  const headerMarginColumns = hasMarginColumns
    ? [
        {
          label: isSecondaryTable ? '' : t`Margin`,
          width: 180,
          formatter: isSecondaryTable ? () => '' : marginFormatter,
        },
      ]
    : [];

  const headers = [
    ...headerNameColumns,
    ...headerBudgetColumns,
    ...headerScheduledColumns,
    ...headerCostColumns,
    ...headerBudgetRemainingColumns,
    ...headerMarginColumns,
  ].filter((item) => typeof item !== 'undefined');

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPhase = getTotalsByPhase(ctx, raw);

  const totals = {
    budget: 0,
    hours: 0,
    fee: 0,
    cost: 0,
  };

  const rows: TableDataRowItem[] = [];

  Object.entries(byPhase).forEach(([phaseIdUnsafe, o]) => {
    const phaseId = parseInt(phaseIdUnsafe);

    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const cost = hasCostColumns ? o.costCents / 100 : '';
    const hours = o.scheduled;

    totals.hours += o.scheduled;
    totals.budget += o.budget ?? 0;

    if (hasFeeColumns) totals.fee += fee || 0;
    if (hasCostColumns) totals.cost += cost || 0;

    const phase = phases[phaseId];
    const phaseVal = {
      val: phase?.phase_name ?? t`No Phase`,
      sortVal: phase?.phase_name ?? '0000',
    };

    const scheduledVal = {
      val: {
        fee,
        hours,
      },
      sortVal: hours,
    };

    const dataNameValues = [phaseVal];
    const dataBudgetValues = hasBudgetColumns ? [getBudgetDataObject(o)] : [];
    const dataScheduledValues = [scheduledVal];
    const dataCostValues = hasCostColumns ? [cost] : [];
    const dataBudgetRemainigValues = hasBudgetRemainingColumns
      ? [
          getBudgetDataObject({
            budget_type: o.budget_type,
            budget: (toCents(o.budget) - o.feeCents) / 100,
          }),
        ]
      : [];
    const marginValues = hasMarginColumns
      ? [
          {
            val: {
              fee,
              cost,
            },
            sortVal: ((fee || 0) - (cost || 0)) / (cost || 1),
          },
        ]
      : [];

    const data = [
      ...dataNameValues,
      ...dataBudgetValues,
      ...dataScheduledValues,
      ...dataCostValues,
      ...dataBudgetRemainigValues,
      ...marginValues,
    ].filter((item) => typeof item !== 'undefined');

    const rowChildren = Object.entries(o.children)
      .map(([taskName, c]) => {
        if (!ctx.billable && c.scheduled == 0) return null;

        const childBudget = getBudgetDataObject(
          {
            budget_type: project.budget_type,
          },
          {
            entityType: 'task',
          },
        );
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childCost = hasCostColumns ? c.costCents / 100 : '';
        const childHours = c.scheduled;
        const childScheduledVal = {
          val: {
            fee: childFee,
            hours: childHours,
          },
          sortVal: childHours,
        };

        const childDataNameValues = [taskName];
        const childDataBudgetValues = hasBudgetColumns ? [childBudget] : [];
        const childDataScheduledValues = [childScheduledVal];
        const childDataCostValues = hasCostColumns ? [childCost] : [];
        const childDataBudgetRemainingValues = hasBudgetRemainingColumns
          ? ['']
          : [];
        const childDataMarginValues = hasMarginColumns
          ? [
              {
                val: {
                  fee: childFee,
                  cost: childCost,
                },
                sortVal:
                  ((childFee || 0) - (childCost || 0)) / (childCost || 1),
              },
            ]
          : [];

        const childData = [
          ...childDataNameValues,
          ...childDataBudgetValues,
          ...childDataScheduledValues,
          ...childDataCostValues,
          ...childDataBudgetRemainingValues,
          ...childDataMarginValues,
        ].filter((item) => typeof item !== 'undefined');

        return {
          data: childData,
        };
      })
      .filter((item) => !!item);

    rows.push({
      id: phaseId,
      data,
      children: rowChildren,
    });
  });

  const budgetRemaining = hasFeeColumns
    ? totals.budget - totals.fee
    : totals.budget - totals.hours;

  const footerNameColumns = [{ label: ' ' }];
  const footerBudgetColumns = hasBudgetColumns
    ? [
        {
          label: getBudgetDataObject({
            budget_type: project.budget_type,
            budget: totals.budget,
          }),
        },
      ]
    : [];

  const footerScheduledColumns = [
    {
      label: feeAndHoursFromatter({
        sortVal: totals.hours,
        val: {
          fee: hasFeeColumns ? totals.fee : '',
          hours: totals.hours,
        },
      }),
    },
  ];
  const footerCostColumns = hasCostColumns ? [{ label: totals.cost }] : [];

  const footerBudgetRemainingColumns = hasBudgetRemainingColumns
    ? [
        {
          label: getBudgetDataObject({
            budget_type: project.budget_type,
            budget: budgetRemaining,
          }),
        },
      ]
    : [];

  const footerMarginColumns = hasMarginColumns
    ? [
        {
          label: '',
        },
      ]
    : [];

  const footer = [
    ...footerNameColumns,
    ...footerBudgetColumns,
    ...footerScheduledColumns,
    ...footerCostColumns,
    ...footerBudgetRemainingColumns,
    ...footerMarginColumns,
  ].filter((item) => typeof item !== 'undefined');

  return { headers, rows, footer };
}
