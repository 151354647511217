import { useHistory } from 'react-router-dom';

import { getProjectById } from '@float/common/selectors/projects';
import { useAppSelectorWithParams } from '@float/common/store';
import { ScheduleViewType } from '@float/constants/schedule';

export const useSingleProjectView = (viewType: ScheduleViewType) => {
  const history = useHistory();
  const isSingleProjectView = viewType === ScheduleViewType.SingleProject;
  const projectId = isSingleProjectView
    ? Number(history.location.pathname.split('/')?.[2])
    : undefined;
  const singleProjectViewProject = useAppSelectorWithParams(
    getProjectById,
    projectId,
  );
  const isSingleProjectNav =
    isSingleProjectView && Boolean(singleProjectViewProject);
  return {
    isSingleProjectNav,
    singleProjectViewProject,
  };
};
