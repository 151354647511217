import { t } from '@lingui/macro';

import {
  createPhase as createPhaseAction,
  deletePhase,
  storePhaseRecord,
  updatePhaseFromSidePanel,
} from '@float/common/actions';
import {
  diffEntityListPayload,
  diffEntityPayload,
} from '@float/common/lib/diffEntityPayload';
import { useAppDispatchStrict } from '@float/common/store';
import { ProjectTeamMemberData } from '@float/types';

import { ProjectFormData, ProjectPhaseRecord } from '../types';

type SaveProps = Pick<
  ProjectFormData,
  'project' | 'projectId' | 'phase' | 'team'
>;

function mapTeamRecordsToPhaseTeam(
  team: ProjectFormData['team'],
): ProjectTeamMemberData[] {
  const phaseTeam: ProjectTeamMemberData[] = [];
  for (const member of team) {
    if (!member.people_id) continue;
    phaseTeam.push({
      people_id: member.people_id,
      hourly_rate: member.hourly_rate,
    });
  }
  return phaseTeam;
}

export const getDefaultPhaseName = () => t`* New Phase`;

export function usePhaseSave() {
  const dispatch = useAppDispatchStrict();

  async function handleBulkUpsert(
    projectId: number,
    phases: Partial<ProjectPhaseRecord>[],
    projectTeam: ProjectTeamMemberData[],
  ) {
    const errors = [];

    // The upsert calls are being processed sequentially in order to retain the given order
    // (The api3 response is sorted sequentially by id, which is incrementals)
    for (const phase of phases) {
      try {
        await dispatch(
          storePhaseRecord(
            {
              project_id: projectId,
              ...phase,
            },
            projectTeam,
          ),
        );
      } catch (_err) {
        errors.push(_err);
      }
    }

    if (errors.length) {
      throw new AggregateError(errors, 'Bulk phases upsert failed');
    }
  }

  function handleBulkSoftDelete(phases: number[]) {
    return Promise.all(phases.map((id) => dispatch(deletePhase(id))));
  }

  function handleBulkUpdate(
    projectId: number,
    update: ProjectPhaseRecord[],
    currentValue: ProjectPhaseRecord[],
    projectTeam: ProjectTeamMemberData[],
  ) {
    const diff = diffEntityListPayload(update, currentValue, 'phase_id');

    if (diff) {
      const promises = [];

      if (diff.add) {
        promises.push(handleBulkUpsert(projectId, diff.add, projectTeam));
      }

      if (diff.del) {
        promises.push(handleBulkSoftDelete(diff.del));
      }
    }
  }

  async function handleCreate(props: SaveProps) {
    const { phase, projectId, team } = props;
    if (phase === undefined) throw new Error(t`Failed to create phase`);

    const res = await dispatch(
      createPhaseAction({
        ...phase,
        project_id: phase.project_id || projectId || null,
        phase_name: phase?.phase_name || getDefaultPhaseName(),
        phase_team: mapTeamRecordsToPhaseTeam(team),
      }),
    );

    if (res) return res.phase_id;

    throw new Error(t`Failed to create phase`);
  }

  async function handleUpdate(
    phaseId: number,
    update: SaveProps,
    currentValues: SaveProps,
  ) {
    if (update.phase === undefined || currentValues.phase === undefined) return;

    const phaseDiff = diffEntityPayload(update.phase, currentValues.phase);

    /**
     * Project dates can be either user set or determined based on allocation dates.
     * E.g. if the user set end date is January 10th and there is a task allocated on January 13th,
     * the end date set on the calendar is Jan 13th. If you try to update only the start date to say
     * January 11th, and only send that in the payload to the API, you get an error because Jan 11th is
     * after the end date set by the user (Jan 10th). This code mitigates that problem by always setting
     * both dates when updating either date. The rest of the validation is ensured by the calendar logic.
     * https://linear.app/float-com/issue/CS-2180/float-team-171588-marketing-playground-phase-dates-update-failed-422
     */
    if (phaseDiff?.start_date || phaseDiff?.end_date) {
      phaseDiff.start_date = update.phase.start_date;
      phaseDiff.end_date = update.phase.end_date;
    }

    const teamDiff = diffEntityListPayload(
      update.team,
      currentValues.team,
      'people_id',
    );

    if (!phaseDiff && !teamDiff) return;

    const payload = phaseDiff || {};

    await dispatch(updatePhaseFromSidePanel(phaseId, payload, teamDiff));
  }

  return {
    handleBulkUpsert,
    handleBulkUpdate,
    handleCreate,
    handleUpdate,
  };
}
