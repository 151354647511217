import React, { useRef } from 'react';
import classNames from 'classnames';

import { areEqualExtractStyle } from '@float/common/components/Schedule/util/diff';
import { getIsShortCellItem } from '@float/common/serena/Data/useCells/helpers/getIsShortCellItem';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { ScheduleViewType } from '@float/constants/schedule';
import { CellItem, CellItemWithoutEntity } from '@float/types/cells';
import { TimeoffStatuses } from '@float/types/timeoffStatus';

import { useMainCellMouseHandlers } from '../hooks/useMainCellMouseHandlers';
import EntityItem from '../MainCell/Item/EntityItem';
import HolidayItem from '../MainCell/Item/HolidayItem';
import MilestoneItem from '../MainCell/Item/MilestoneItem';
import NonWorkDayItem from '../MainCell/Item/NonWorkDayItem';
import OvertimeItem from '../MainCell/Item/OvertimeItem';
import PhaseItem from '../MainCell/Item/PhaseItem';
import PlaceholderItem from '../MainCell/Item/PlaceholderItem';
import { MainCellProps } from '../types';
import { Footer } from './components/Footer/Footer';
import {
  CellItemsGrouppedByWeekday,
  getCellItemsGrouppedByWeekDay,
} from './helpers/getCellItemsGrouppedByWeekDay';
import { getCellOvertimeItems } from './helpers/getCellOvertimeItems';

import * as styles from './index.css';

// -- IMPORTANT NOTE [APA 2019-06-20] ------------------------------------------
// Be very careful with adding any hooks inside this function. A simple useState
// introduces a memory leak. I didn't track it down further than that.
function MainCellQuarter(props: MainCellProps) {
  const {
    cell,
    rowIdx,
    colIdx,
    style,
    row,
    rowMeta,
    dayWidth,
    hourHeight,
    viewType,
    actions,
    isColumnFetched,
    rowGroupTop,
  } = props;

  const {
    dates,
    cellsWrapper: { cells },
    boundaryRef,
    scrollWrapperRef,
    mondayStart,
    suvSingleDay,
  } = useScheduleContext();

  const isProjectPlanView = viewType === ScheduleViewType.Projects;

  const firstCellDay = dates.fromDescriptor(colIdx, 0);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { handleMouseDown, handleMouseEnter, handleMouseMove } =
    useMainCellMouseHandlers(
      actions,
      row,
      colIdx,
      rowGroupTop,
      rowIdx,
      scrollWrapperRef,
      wrapperRef,
    );

  const cellItemsSelection =
    cell.items?.filter((item) => {
      return item.type === 'selection';
    }) || [];

  const cellItemsAllocation =
    cell.items?.filter((item) => {
      return item.type === 'task';
    }) || [];

  const cellItemsAllocationShort = cellItemsAllocation.filter((item) =>
    getIsShortCellItem(item),
  );

  const cellItemsTimeoff =
    cell.items
      ?.filter((item) => {
        return item.type === 'timeoff';
      })
      .filter((item) => !item.entity.region_holiday_id) || [];

  const cellItemsTimeoffVisibleOnSchedule = cellItemsTimeoff.filter(
    (item) =>
      item.entity.full_day && item.entity.status === TimeoffStatuses.APPROVED,
  );

  const cellItemsTimeofToBeGroupped = cellItemsTimeoff.filter(
    (item) =>
      !item.entity.full_day || item.entity.status !== TimeoffStatuses.APPROVED,
  );

  const cellItemsHolidays =
    cell.items
      ?.filter((item) => item.type === 'timeoff' || item.type === 'holiday')
      .filter((item) => {
        return (
          item.type === 'holiday' ||
          (item.type === 'timeoff' &&
            (item as CellItem<'timeoff'>).entity.region_holiday_id)
        );
      }) || [];

  const cellItemsPhases =
    cell.items?.filter((item) => {
      return item.type === 'phase';
    }) || [];

  const cellItemsMilestones =
    cell.items?.filter((item) => {
      return item.type === 'milestone';
    }) || [];

  const cellItemsAllocationSpanningMoreThanAWeek = cellItemsAllocation.filter(
    (item) => {
      const isShortCellItem = getIsShortCellItem(item);

      return !isShortCellItem;
    },
  );

  const isPersonCell = row.type === 'person' && 'dayHours' in cell;

  let cellItemsGrouppedByWeekday: CellItemsGrouppedByWeekday = null;
  let cellItemsOvertime: CellItem<'overtime'>[] = [];

  const hasCellItemsBeingVerticallyResized = cell.items?.some(
    (item) =>
      'isResizingVertically' in item.entity && item.entity.isResizingVertically,
  );

  if (isPersonCell) {
    const cellItemsToBeGroupped = [
      ...cellItemsAllocationShort,
      ...cellItemsTimeofToBeGroupped,
    ];

    const dailyWorkHours = rowMeta?.getDailyWorkHours(firstCellDay);
    const allocatedDailyHours = cell.dayHours;

    cellItemsOvertime = getCellOvertimeItems(
      dailyWorkHours,
      allocatedDailyHours,
      hasCellItemsBeingVerticallyResized,
    );

    cellItemsGrouppedByWeekday = getCellItemsGrouppedByWeekDay(
      cellItemsToBeGroupped,
      mondayStart,
      dailyWorkHours,
      allocatedDailyHours,
    );
  }

  let cellItemsNonWorkDays: CellItemWithoutEntity<'nonWorkDay'>[] = [];

  if (rowMeta?.getNonWorkDayItems && rowMeta.getNonWorkDayItems(firstCellDay)) {
    cellItemsNonWorkDays = rowMeta
      .getNonWorkDayItems(firstCellDay)
      .map((item, index) => {
        return {
          type: 'nonWorkDay',
          cellKey: `nonWorkDay:${index}`,
          key: `nonWorkDay:${index}`,
          entityId: `nonWorkDay:${index}`,
          x: item.x,
          w: 1,
        };
      });
  }

  return (
    <div
      ref={wrapperRef}
      style={style}
      className={classNames('MainCell-Wrapper', styles.wrapper)}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
    >
      <div className={styles.positioner} data-visible={!isColumnFetched}>
        {cellItemsSelection?.map((item) => (
          <PlaceholderItem
            key={item.key}
            item={item}
            dayWidth={dayWidth}
            hourHeight={hourHeight}
            fullDay
            suvSingleDay={suvSingleDay}
            cells={cells}
            actions={actions}
            linkedArrowTargetRef={props.linkedArrowTargetRef}
            wrapperRef={wrapperRef}
            row={row}
          />
        ))}

        {cellItemsTimeoffVisibleOnSchedule.map((item) => (
          <EntityItem
            key={item.key}
            item={item}
            actions={actions}
            dayWidth={dayWidth}
            hourHeight={hourHeight}
            row={row}
            rowMeta={rowMeta}
            boundaryRef={boundaryRef}
            suvSingleDay={suvSingleDay}
            wrapperRef={wrapperRef}
            linkedArrowTargetRef={props.linkedArrowTargetRef}
            cells={cells}
            isProjectPlanView={isProjectPlanView}
          />
        ))}

        {cellItemsMilestones.map((item) => (
          <MilestoneItem
            key={item.key}
            item={item}
            dayWidth={dayWidth}
            hourHeight={hourHeight}
            actions={actions}
            boundaryRef={boundaryRef}
            wrapperRef={wrapperRef}
            scrollWrapperRef={scrollWrapperRef}
          />
        ))}

        {cellItemsPhases.map((item) => (
          <PhaseItem
            key={item.key}
            item={item}
            dayWidth={dayWidth}
            hourHeight={hourHeight}
            actions={actions}
            boundaryRef={boundaryRef}
            wrapperRef={wrapperRef}
            scrollWrapperRef={scrollWrapperRef}
          />
        ))}

        {cellItemsOvertime.map((item) => (
          <OvertimeItem
            key={item.key}
            item={item}
            dayWidth={dayWidth}
            hourHeight={hourHeight}
            suvSingleDay={suvSingleDay}
            isForced={item.h === 0}
          />
        ))}

        {cellItemsNonWorkDays.map((item) => (
          <NonWorkDayItem
            key={item.key}
            item={item}
            dayWidth={dayWidth}
            actions={actions}
            suvSingleDay={suvSingleDay}
            row={row}
          />
        ))}

        {cellItemsHolidays.map((item) => (
          <HolidayItem
            key={item.key}
            item={item}
            dayWidth={dayWidth}
            actions={actions}
            suvSingleDay={suvSingleDay}
            row={row}
          />
        ))}

        {/* Allocations longer than a week */}
        {cellItemsAllocationSpanningMoreThanAWeek.map((item) => (
          <EntityItem
            key={item.key}
            item={item}
            actions={actions}
            dayWidth={dayWidth}
            hourHeight={hourHeight}
            row={row}
            boundaryRef={boundaryRef}
            suvSingleDay={undefined}
            wrapperRef={wrapperRef}
            linkedArrowTargetRef={props.linkedArrowTargetRef}
            cells={cells}
            isProjectPlanView={false}
          />
        ))}

        {cellItemsGrouppedByWeekday && (
          <Footer
            actions={actions}
            cellItemsGrouppedByWeekday={cellItemsGrouppedByWeekday}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(MainCellQuarter, areEqualExtractStyle);
