import { t } from '@lingui/macro';

import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

export const getMarginHeaderLabel = (mode: ChartComparisonModes) => {
  if (mode === ChartComparisonModes.LOGGED) {
    return t`Margin`;
  }
  if (mode === ChartComparisonModes.SCHEDULED) {
    return t`Scheduled margin`;
  }
  return t`Projected margin`;
};
