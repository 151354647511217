import React from 'react';
import { Trans } from '@lingui/macro';

import {
  formatAmount,
  getExpandedInfo,
  toCents,
} from '@float/common/lib/budget';
import { ProjectBudgetType } from '@float/constants/projects';
import { getHasFixedBudgetSupport } from '@float/web/reports/helpers/getHasFixedBudgetSupport';
import { getHasProjectBudgetAccess } from '@float/web/reports/helpers/getHasProjectBudgetAccess';
import { getIsMoneyBudgetType } from '@float/web/reports/helpers/getIsMoneyBudgetType';

import {
  ItemHeader,
  ItemLabel,
  ItemSubLabel,
  ReportsTotalsCard,
  ReportsTotalsContainer,
  ReportsTotalsItem,
} from '../../../styles';
import {
  SummaryItemCost,
  SummaryItemCostCalculationType,
} from '../SummaryItemCost/SummaryItemCost';
import {
  SummaryItemMargin,
  SummaryItemTotalBudgetMarginCalculationType,
} from '../SummaryItemMargin/SummaryItemMargin';
import { SummaryItemTotalBudget } from '../SummaryItemTotalBudget/SummaryItemTotalBudget';
import type { SummaryBarProps } from '../types';

import * as summaryBarStyles from '../SummaryBar.css';

export function SummaryBarCombined({
  project,
  projectBudget,
  totals,
  hasBudgetsAccess,
  hasCostsAccess,
  hasPeopleWithMissingCostRates,
  projectMarginType,
  onProjectMarginTypeChange,
}: SummaryBarProps) {
  const { budget_type, non_billable } = project;

  const isMoneyBudgetType = getIsMoneyBudgetType(budget_type);
  const hasProjectBudgetAccess = getHasProjectBudgetAccess(
    hasBudgetsAccess,
    budget_type,
  );

  const hasFixedBudgetSupport = getHasFixedBudgetSupport(budget_type);
  const isDisabledBudget = budget_type === ProjectBudgetType.Disabled;

  const budget_total = projectBudget?.budget_total ?? 0;

  const logged_hours = non_billable
    ? totals.logged.nonbillable
    : totals.logged.billable;

  const logged_budget_used = isMoneyBudgetType
    ? totals.logged.feeCents / 100
    : logged_hours;

  const combined_hours = non_billable
    ? totals.combined.nonbillable
    : totals.combined.billable;

  const combined_budget_used = isMoneyBudgetType
    ? totals.combined.feeCents / 100
    : combined_hours;

  const combined_budget_remaining = isMoneyBudgetType
    ? (toCents(budget_total) - toCents(combined_budget_used)) / 100
    : budget_total - combined_budget_used;

  const future_budget_used = +combined_budget_used - +logged_budget_used;
  const future_hours = +combined_hours - +logged_hours;

  const { overBudget, percentageUsed } = getExpandedInfo({
    budget_total,
    budget_remaining: combined_budget_remaining,
    budget_used: combined_budget_used,
  });

  return (
    <ReportsTotalsContainer data-testid="summary-bar-combined">
      {!isDisabledBudget && hasProjectBudgetAccess && (
        <ReportsTotalsCard borderRight flexGrow={1}>
          <SummaryItemTotalBudget
            project={project}
            projectBudget={projectBudget}
            totals={totals}
          />
        </ReportsTotalsCard>
      )}
      <ReportsTotalsCard flexGrow={1}>
        <ReportsTotalsItem /* width="200px" */>
          <ItemLabel>
            <Trans>Past logged</Trans>
          </ItemLabel>
          {isMoneyBudgetType && hasProjectBudgetAccess ? (
            <ItemHeader>
              {formatAmount(budget_type, logged_budget_used)}
              <ItemSubLabel>{formatAmount(1, logged_hours)}</ItemSubLabel>
            </ItemHeader>
          ) : (
            <ItemHeader>{formatAmount(1, logged_hours)}</ItemHeader>
          )}
        </ReportsTotalsItem>
      </ReportsTotalsCard>
      <ReportsTotalsCard borderRight flexGrow={1}>
        <ReportsTotalsItem /* width="200px" */>
          <ItemLabel>
            <Trans>Future scheduled</Trans>
          </ItemLabel>
          {isMoneyBudgetType && hasProjectBudgetAccess ? (
            <ItemHeader>
              {!isNaN(future_budget_used) &&
                formatAmount(budget_type, future_budget_used)}
              <ItemSubLabel>
                {!isNaN(future_hours) && formatAmount(1, future_hours)}
              </ItemSubLabel>
            </ItemHeader>
          ) : (
            <ItemHeader>
              {!isNaN(future_hours) && formatAmount(1, future_hours)}
            </ItemHeader>
          )}
        </ReportsTotalsItem>
      </ReportsTotalsCard>

      <ReportsTotalsCard flexGrow={1}>
        <ReportsTotalsItem /* width="200px" */>
          <ItemLabel>
            {isMoneyBudgetType && hasProjectBudgetAccess ? (
              <Trans>Projected billable</Trans>
            ) : (
              <Trans>Projected hours</Trans>
            )}
          </ItemLabel>
          {hasFixedBudgetSupport &&
            hasProjectBudgetAccess &&
            percentageUsed &&
            percentageUsed !== 'NaN' &&
            percentageUsed !== 'Infinity' && (
              <ItemSubLabel>
                <span
                  className={overBudget ? summaryBarStyles.valueCritical : ''}
                >
                  <Trans>{percentageUsed}% budget</Trans>
                </span>
              </ItemSubLabel>
            )}
          {isMoneyBudgetType && hasProjectBudgetAccess ? (
            <ItemHeader>
              {formatAmount(budget_type, combined_budget_used)}
              <ItemSubLabel>{formatAmount(1, combined_hours)}</ItemSubLabel>
            </ItemHeader>
          ) : (
            <ItemHeader>{formatAmount(1, combined_hours)}</ItemHeader>
          )}
        </ReportsTotalsItem>
      </ReportsTotalsCard>

      {hasCostsAccess && (
        <ReportsTotalsCard flexGrow={1}>
          <SummaryItemCost
            project={project}
            totals={totals}
            projectBudget={projectBudget}
            costCalculationType={SummaryItemCostCalculationType.COMBINED}
            hasPeopleWithMissingCostRates={hasPeopleWithMissingCostRates}
          />
        </ReportsTotalsCard>
      )}

      {hasCostsAccess && hasBudgetsAccess && isMoneyBudgetType && (
        <ReportsTotalsCard borderLeft flexGrow={1}>
          <SummaryItemMargin
            project={project}
            totals={totals}
            projectBudget={projectBudget}
            marginCalculationType={
              SummaryItemTotalBudgetMarginCalculationType.COMBINED
            }
            hasPeopleWithMissingCostRates={hasPeopleWithMissingCostRates}
            projectMarginType={projectMarginType}
            onProjectMarginTypeChange={onProjectMarginTypeChange}
          />
        </ReportsTotalsCard>
      )}
    </ReportsTotalsContainer>
  );
}
