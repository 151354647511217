export enum MediaSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

export const SMALL_BREAKPOINT_MAX_WIDTH = 767;
export const MEDIUM_BREAKPOINT_MIN_WIDTH = 768;
export const MEDIUM_BREAKPOINT_MAX_WIDTH = 1109;
export const LARGE_BREAKPOINT_MIN_WIDTH = 1110;
export const LARGE_BREAKPOINT_MAX_WIDTH = 1459;
export const X_LARGE_BREAKPOINT_MIN_WIDTH = 1460;

export const MediaSizeQueries: Record<MediaSize, string> = {
  [MediaSize.Small]: `screen and (max-width: ${SMALL_BREAKPOINT_MAX_WIDTH}px)`,
  [MediaSize.Medium]: `screen and (min-width: ${MEDIUM_BREAKPOINT_MIN_WIDTH}px) and (max-width: ${MEDIUM_BREAKPOINT_MAX_WIDTH}px)`,
  [MediaSize.Large]: `screen and (min-width: ${LARGE_BREAKPOINT_MIN_WIDTH}px) and (max-width: ${LARGE_BREAKPOINT_MAX_WIDTH}px)`,
  [MediaSize.XLarge]: `screen and (min-width: ${X_LARGE_BREAKPOINT_MIN_WIDTH}px)`,
} as const;

export const AllMediaSizeKeys = Object.keys(MediaSizeQueries) as MediaSize[];
