import { isArray } from 'lodash';

import API3 from '@float/common/api3';
import { PromptId } from '@float/types/prompts';
import { WebAppDispatchStrict } from '@float/web/lib/store';

export const UPDATE_PROMPTS_SUCCESS = 'UPDATE_PROMPTS_SUCCESS' as const;
export const SET_PROMPT = 'SET_PROMPT' as const;
export const SET_PROMPT_DATA = 'SET_PROMPT_DATA' as const;
export const REPLAY_PRODUCT_TOUR = 'REPLAY_PRODUCT_TOUR' as const;

export type OnboardingActions =
  | {
      type: typeof UPDATE_PROMPTS_SUCCESS;
      prompts: PromptId[];
    }
  | {
      type: typeof SET_PROMPT;
      prompt: PromptId;
    }
  | {
      type: typeof SET_PROMPT_DATA;
      prompt: PromptId;
      data: unknown;
    }
  | {
      type: typeof REPLAY_PRODUCT_TOUR;
    };

export const updatePrompts = (
  prompts: PromptId[] | PromptId,
  saveOnServer = true,
) => {
  return async (dispatch: WebAppDispatchStrict) => {
    prompts = isArray(prompts) ? prompts : [prompts];
    dispatch({ type: UPDATE_PROMPTS_SUCCESS, prompts });

    if (saveOnServer) await API3.saveUserPrompts(prompts);
  };
};

export const setPrompt = (prompt: PromptId) => {
  return {
    type: SET_PROMPT,
    prompt,
  };
};

export const setPromptData = (prompt: PromptId, data: unknown) => {
  return {
    type: SET_PROMPT_DATA,
    prompt,
    data,
  };
};

export const replayProductTour = () => {
  return {
    type: REPLAY_PRODUCT_TOUR,
  };
};
