import { connect } from 'react-redux';
import { shouldShowPmSidebar } from 'selectors';

import { fetchCompanyPrefs } from '@float/common/actions/companyPrefs';
import { fetchCompanySso, fetchUser } from '@float/common/actions/currentUser';
import { fetchCompanies } from '@float/common/actions/currentUser/fetchCompanies';
import { ensureDepartmentsLoaded } from '@float/common/actions/departments';
import { getJWTAccessToken } from '@float/common/actions/jwt';
import { fetchLockLoggedTimeConfig } from '@float/common/actions/lockLoggedTime';
import { ensureSearchContextLoaded } from '@float/common/actions/search';
import {
  ACCOUNT_SETTINGS_GUESTS_TABLE,
  getEntityById,
} from '@float/web/settingsV2/reducers/reducerHelpers';

import { fetchBillingInfo } from '../actions/account/billingInfo';
import { fetchAccounts } from '../actions/accounts';
import { fetchCurrencies } from '../actions/currencies';
import { fetchTimezones } from '../actions/timezones';
import AccountSettings from '../components/AccountSettings';
import withOnMount from '../decorators/withOnMount';

const mapStateToProps = (state) => {
  const {
    currentUser,
    settingsAccounts: { entities },
    companyPrefs: { company_name: teamName },
    companyPrefs,
  } = state;

  return {
    currentAccount:
      getEntityById(currentUser.admin_id, entities, 'account_id') || {},
    teamName,
    currentUser,
    companyPrefs,
    shouldShowPmSidebar: shouldShowPmSidebar(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onComponentDidMount: async () => {
      await dispatch(getJWTAccessToken());
      await Promise.all([
        dispatch(fetchUser()),
        dispatch(fetchCompanies()),
        dispatch(fetchAccounts(ACCOUNT_SETTINGS_GUESTS_TABLE)),
        dispatch(fetchCompanyPrefs()),
        dispatch(fetchBillingInfo()),
        dispatch(fetchTimezones()),
        dispatch(fetchCurrencies()),
        dispatch(fetchCompanySso()),
        dispatch(fetchLockLoggedTimeConfig()),
        dispatch(ensureSearchContextLoaded()),
        dispatch(ensureDepartmentsLoaded()),
      ]);
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(withOnMount(AccountSettings));
