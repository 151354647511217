import React from 'react';
import * as Select from '@radix-ui/react-select';
import cn from 'classnames';

import { IconChevronDropdown } from '@float/ui/icons/essentials/IconChevronDropdown';
import * as ScrollArea from '@float/ui/primitives/ScrollArea';

import { Option } from './Option';
import { Separator } from './Separator';
import { SelectProps } from './types';

import * as styles from './styles.css';

// TODO https://linear.app/float-com/issue/FT-1593/technical-debt-refactor-the-dropdown-to-use-the-radix-primitive

function preventSubmit(event: React.KeyboardEvent) {
  if (event.key === 'Enter') {
    event.preventDefault();
    event.stopPropagation();
  }
}

export function SelectComponent(props: SelectProps) {
  const { icon: Icon, onChange } = props;

  const handleOptionSelect = React.useCallback(
    (newValue: string) => {
      const shouldReset = newValue === props.value;
      if (shouldReset) {
        onChange('');
      }
    },
    [props.value, onChange],
  );

  return (
    <React.StrictMode>
      <Select.Root value={props.value} onValueChange={props.onChange}>
        <Select.Trigger
          className={cn(styles.button, props.className)}
          aria-label={props['aria-label']}
        >
          {Icon && <Icon size={20} />}
          {!props.hideLabel && (
            <span className={styles.buttonContent}>
              <Select.Value placeholder={props.placeholder} />
            </span>
          )}
          {!props.hideCaret && (
            <Select.Icon className={styles.icon}>
              <IconChevronDropdown size={16} />
            </Select.Icon>
          )}
        </Select.Trigger>
        <Select.Portal>
          <Select.Content
            align="end"
            className={styles.content}
            position="popper"
            onKeyDown={preventSubmit}
          >
            <Select.Viewport>
              <Select.Group>
                {props.optionsLabel && (
                  <Select.Label className={styles.optionsLabel}>
                    {props.optionsLabel}
                  </Select.Label>
                )}
                <ScrollArea.Root type="auto">
                  <ScrollArea.Content className={styles.scrollAreaContent}>
                    <div data-testid="options">
                      {props.options.map((option, index) => {
                        if ('separator' in option) {
                          return <Separator key={`separator_${index}`} />;
                        }
                        return (
                          <Option
                            key={option.value}
                            {...option}
                            onSelect={handleOptionSelect}
                          />
                        );
                      })}
                    </div>
                  </ScrollArea.Content>
                </ScrollArea.Root>
              </Select.Group>
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </React.StrictMode>
  );
}
