import { getWeekDayNameFromDayNumber } from '@float/libs/dates/getWeekDayNameFromDayNumber';
import { subtractOperation } from '@float/libs/utils/floats';
import { CellItem } from '@float/types/cells';

export type CellItemsGrouppedByWeekday = Array<{
  name: string;
  items: CellItem<'task' | 'timeoff'>[];
  overtime: number;
}> | null;

export const getCellItemsGrouppedByWeekDay =
  function getCellItemsGrouppedByWeekDay(
    cellItemsToBeGroupped: (CellItem<'task'> | CellItem<'timeoff'>)[],
    mondayStart: boolean,
    dailyWorkHours: number[] | undefined,
    allocatedDailyHours: number[],
  ) {
    const cellItemsGrouppedByWeekday: CellItemsGrouppedByWeekday = new Array(7)
      .fill(null)
      .map((_, index) => {
        const name = getWeekDayNameFromDayNumber(index, mondayStart);

        const dailyWorkHoursOnThisDay = dailyWorkHours?.[index] || 0;
        const hoursAllocatedOnThisDay = allocatedDailyHours[index];

        const hasDailyWorkHoursOnThisDay = dailyWorkHoursOnThisDay > 0;

        const overtime = hasDailyWorkHoursOnThisDay
          ? Math.max(
              subtractOperation(
                hoursAllocatedOnThisDay,
                dailyWorkHoursOnThisDay,
              ),
              0,
            )
          : 0;

        return {
          name,
          items: [],
          overtime,
        };
      });

    cellItemsToBeGroupped.forEach((item) => {
      if (typeof item.x !== 'number') return;

      for (let i = item.x; i < item.w + item.x; i++) {
        if (!Array.isArray(cellItemsGrouppedByWeekday?.[i].items)) continue;

        cellItemsGrouppedByWeekday[i].items.push(item);
      }
    });

    return cellItemsGrouppedByWeekday;
  };
