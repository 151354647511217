import { FilterCategory } from '@float/common/search/types';
import type { SearchAutocompleteCategory } from '@float/types';

import { SearchFilterDropdownData } from '../data';
import {
  ExpandableSearchFilterDropdownCategoryItem,
  SearchFilterDropdownCategoryItem,
} from '../types';

export function getCategoryConfig(
  type:
    | SearchAutocompleteCategory
    | FilterCategory
    | ExpandableSearchFilterDropdownCategoryItem
    | undefined,
): SearchFilterDropdownCategoryItem {
  const category = SearchFilterDropdownData.find((item) => item.value === type);

  if (category) return category;

  // to debug the occurence of https://rollbar.com/float/fe-web-app/items/4879/
  // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-plus-operands
  throw new Error(`No category found for` + type);
}
