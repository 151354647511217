import React from 'react';
import { t, Trans } from '@lingui/macro';

import { formatPercentage } from '@float/common/lib/budget/helpers/getPercentage';
import { formatCurrency } from '@float/common/lib/currency';
import { getCurrencyProps } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { config } from '@float/libs/config';
import { Tooltip, TooltipExplainerContent } from '@float/ui/components/Tooltip';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';
import { IconInformation } from '@float/ui/icons/essentials/IconInformation';

import { getProfitAndCostTotals } from '../helpers/getProfitAndCostTotals';
import type { ReportTotals } from '../../types';

import { colorPercentage } from '../../helpers/styles.css';
import * as styles from './styles.css';

export const ProfitSummary = ({
  totals,
  mode,
}: {
  totals: ReportTotals;
  mode: ChartComparisonModes;
}) => {
  const { percentage, profit } = getProfitAndCostTotals(totals, mode);
  const currencyConfig = useAppSelectorStrict(getCurrencyProps);
  const percentageValue = formatPercentage(percentage);
  const profitValue = formatCurrency(
    profit / 100,
    config.locale,
    currencyConfig.currency,
  );
  return (
    <div
      className={styles.profitSummaryContainer({
        mode: mode === ChartComparisonModes.COMBINED ? 'combined' : 'single',
      })}
    >
      <div className={styles.profitSummaryTitle}>
        <Trans>Profit Summary</Trans>{' '}
        <Tooltip
          content={
            <TooltipExplainerContent>
              {t`Calculated based on the projected billable amount and People costs`}
            </TooltipExplainerContent>
          }
        >
          <IconInformation size={20} />
        </Tooltip>
      </div>
      <div className={styles.profitSummaryRow}>
        <span className={styles.profitSummaryPercentage}>
          <span
            className={colorPercentage({
              color: percentage < 0 ? 'negative' : 'positive',
            })}
          >
            {percentageValue}
          </span>
        </span>
        <span className={styles.profitSummaryValue}>{profitValue}</span>
      </div>
    </div>
  );
};
