import React from 'react';
import safeStringify from 'fast-safe-stringify';
import { cloneDeep, forEach, groupBy, isUndefined } from 'lodash';
import manageModal from 'modalManager/manageModalActionCreator';

import {
  createMilestone,
  updateMilestone,
} from '@float/common/actions/milestones';
import { createOneOff, removeOneOff } from '@float/common/actions/oneOffs';
import {
  createPhase,
  shiftPhaseTimeline,
  updatePhase,
} from '@float/common/actions/phases';
import {
  createStatus,
  removeStatus,
  updateStatus,
} from '@float/common/actions/statuses';
import {
  deleteTask,
  insertTask,
  replaceTask,
  updateLinkedTasks,
  updateTask,
} from '@float/common/actions/tasks';
import { splitTask } from '@float/common/actions/tasks/splitTask';
import {
  createTimeoff,
  deleteTimeoff,
  insertTimeoff,
  replaceTimeoff,
  updateTimeoff,
} from '@float/common/actions/timeoffs';
import {
  removeTimeRange,
  updateTimeRange,
} from '@float/common/actions/timeRange';
import { trackAnalytics } from '@float/common/components/Schedule/actions/analytics';
import { persistLoggedTimeChanges } from '@float/common/components/Schedule/actions/persistLoggedTimeChanges';
import { TASK_EDIT_MODES } from '@float/common/components/Schedule/util/ContextMenu';
import {
  getTaskMaxLength,
  getTempId,
  isRepeatOverlap,
  maxEntityLength,
  maxYearlyEntityLength,
} from '@float/common/lib/scheduleUtils';
import { fieldsChanged, getAllChangedFields } from '@float/common/lib/utils';
import {
  createTask,
  duplicateTask,
  duplicateTaskFromDrag,
  updateTaskStatusForRepeatingTask,
} from '@float/web/actions/tasks';
import { getUser } from '@float/web/selectors';
import {
  CANCEL,
  ONLY_THIS,
  THIS_AND_FUTURE,
} from 'components/popups/UpdateRepeating';

import { isRequestMode } from '../../../../common/src/lib/timeoffRequest';
import {
  isRejectedTimeoff,
  isTentativeTimeoff,
} from '../../../../common/src/lib/timeoffs';
import { trackFirstTaskDuringOnboarding } from './helpers/trackFirstTaskDuringOnboarding';

function getMethod(actionMode, change) {
  const { type, isCreate, isDuplication, isRemove, entity, trigger } = change;

  if (type === 'milestone') {
    if (isCreate) return createMilestone;
    return updateMilestone;
  }

  if (type === 'phase') {
    if (isCreate) return createPhase;
    if (trigger === 'DRAG_ENTITY_STOP') {
      return shiftPhaseTimeline;
    }
    change.opts = { isResize: true };
    return updatePhase;
  }

  if (type === 'oneOff') {
    if (isCreate) return createOneOff;
    if (isRemove) return removeOneOff;
  }

  if (type === 'linkedTasks') {
    return updateLinkedTasks;
  }

  if (type === 'task') {
    if (actionMode === TASK_EDIT_MODES.SPLIT) {
      entity.actionMode = actionMode;
    }
    if (isRemove) return deleteTask;
    if (isDuplication) {
      if (trigger === 'DRAG_ENTITY_STOP') return duplicateTaskFromDrag;

      return duplicateTask;
    }
    if (change.isOnlyTaskStatusUpdate) return updateTaskStatusForRepeatingTask;
    if (isCreate) {
      if (actionMode === TASK_EDIT_MODES.INSERT) return insertTask;
      if (actionMode === TASK_EDIT_MODES.REPLACE) return replaceTask;
      return createTask;
    }
    return updateTask;
  }

  if (type === 'timeoff') {
    if (isRemove) return deleteTimeoff;
    if (isCreate) {
      if (actionMode === TASK_EDIT_MODES.INSERT) return insertTimeoff;
      if (actionMode === TASK_EDIT_MODES.REPLACE) return replaceTimeoff;
      return createTimeoff;
    }
    return updateTimeoff;
  }

  if (type === 'status') {
    if (isRemove) return removeStatus;
    if (isCreate) return createStatus;
    return updateStatus;
  }

  if (type === 'timeRange') {
    if (isRemove) return removeTimeRange;
    return updateTimeRange;
  }

  if (type === 'loggedTime') {
    // We need to handle this action when the user creates a full day timeoff
    // and we find a conflicting logged time.
    // The remove is automatically handled by the live updates
    // So we just return a no-op here
    if (isRemove) return () => () => Promise.resolve();
  }

  throw Error(`Unknown method ${type}, ${isCreate}, ${isRemove}`);
}

const getRepeatMeta = (change) => {
  const parentEntity = change.originalEntity.allInstances[0];
  const { allInstances } = parentEntity;

  const instanceCount = isUndefined(change.instanceCount)
    ? change.entity.instanceCount
    : change.instanceCount;

  const isFirstRepeatUpdate = instanceCount === 0;
  const isLastReapeatUpdate = instanceCount === allInstances.length - 1;
  const nextInstance = allInstances[instanceCount + 1];
  const previousInstance = allInstances[instanceCount - 1];

  return {
    instanceCount,
    allInstances,
    parentEntity,
    isFirstRepeatUpdate,
    isLastReapeatUpdate,
    nextInstance,
    previousInstance,
  };
};

function markChangeAsStatusUpdate(change, repeatPromptAnswer) {
  const changes = appendRepeatChanges(change, repeatPromptAnswer);

  change.isOnlyTaskStatusUpdate = true;
  change.updates = changes;
  return [change];
}

function appendRepeatChanges(change, repeatPromptAnswer) {
  const { type } = change;
  const {
    parentEntity,
    allInstances,
    instanceCount,
    isFirstRepeatUpdate,
    isLastReapeatUpdate,
    nextInstance,
    previousInstance,
  } = getRepeatMeta(change);

  const isUpdateAllInstances =
    instanceCount === 0 && repeatPromptAnswer === THIS_AND_FUTURE;
  if (isUpdateAllInstances) {
    return [change];
  }

  if (isFirstRepeatUpdate && repeatPromptAnswer === ONLY_THIS) {
    change.entity.repeat_state = 0;

    const newEntity = cloneDeep(change.originalEntity);
    newEntity.start_date = nextInstance.start_date;
    newEntity.end_date = nextInstance.end_date;
    newEntity.repeat_state =
      allInstances.length > 2 ? parentEntity.repeat_state : 0;

    return [
      change,
      {
        type,
        entity: newEntity,
        isCreate: true,
      },
    ];
  }

  // If we're dealing with the last instance, we just adjust without prompt
  if (isLastReapeatUpdate && change.isRemove) {
    change.isRemove = undefined;
    change.entity = {
      ...parentEntity,
      repeat_end_date: previousInstance?.end_date,
    };
    return [change];
  }

  if (isLastReapeatUpdate) {
    const newEntity = cloneDeep(change.entity);

    change.entity = {
      ...parentEntity,
      repeat_state: allInstances.length > 2 ? parentEntity.repeat_state : 0,
      repeat_end_date: previousInstance?.end_date,
    };

    if (newEntity.repeat_end_date === parentEntity.repeat_end_date) {
      // If the user didn't change the repeat_end_date and they're modifying
      // the last instance, the new entity will not need to repeat.
      delete newEntity.repeat_state;
      delete newEntity.repeat_end_date;
    }

    return [
      change,
      {
        type,
        entity: newEntity,
        isCreate: true,
      },
    ];
  }

  // If we've reached this line, we're dealing with a repeat that isn't the
  // start or end. In this case, we first always want to adjust the parent
  // repeat values.
  const newEntity = cloneDeep(change.entity);

  change.entity = {
    ...parentEntity,
    repeat_state: instanceCount > 1 ? parentEntity.repeat_state : 0,
    repeat_end_date: previousInstance.end_date,
  };

  let newEntity2 = null;
  if (repeatPromptAnswer === ONLY_THIS) {
    // if it's a status change, we'll the specific API for this.

    // If the user chose to only change this instance, create a new entity to
    // hold the future versions.
    newEntity.repeat_state = 0;

    newEntity2 = cloneDeep(parentEntity);
    newEntity2.start_date = nextInstance.start_date;
    newEntity2.end_date = nextInstance.end_date;

    if (instanceCount + 1 === allInstances.length - 1) {
      newEntity2.repeat_state = 0;
      newEntity2.repeat_end_date = '';
    }
  }

  const changes = [change];

  // This block happens when the user chose to delete one of the repeating
  // entities, and it wasn't the first or the last instance.
  // When this happens, we don't want to create that single middle task.
  if (change.isRemove) {
    change.isRemove = undefined;
  } else {
    changes.push({
      type,
      entity: newEntity,
      isCreate: true,
    });
  }

  if (newEntity2) {
    changes.push({
      type,
      entity: newEntity2,
      isCreate: true,
    });
  }

  return changes;
}

function promptForMultiAssignDelete(store, change) {
  return new Promise((resolve, reject) => {
    store.dispatch(
      manageModal({
        visible: true,
        modalType: 'deleteMultiAssignModal',
        modalSettings: {
          isTimeoff: change.type === 'timeoff',
          async callback(answer) {
            resolve(answer);
          },
        },
        skipSidePanelAutoClose: true,
      }),
    );
  });
}

function validateChanges(dates, cells, changes, user = {}) {
  for (const change of changes) {
    if (change.type === 'milestone') {
      // Milestones need no validation
      continue; // eslint-disable-line
    }

    if (change.type === 'oneOff') {
      // Oneoffs need no validation
      continue; // eslint-disable-line
    }

    if (change.isRemove) {
      // We can freely delete things as our validations are only on adding time
      continue; // eslint-disable-line
    }

    // Updating non-tentative timeoff will change status to tentative
    if (
      change.type === 'timeoff' &&
      change.entity.status !== 1 &&
      isRequestMode(change.entity, user, cells._helperData.people)
    ) {
      // Convert full day to half day
      if (change.entity.full_day) {
        change.entity.hours = cells._helpers.getMinWorkHoursInRange(
          change.entity,
        );
        change.entity.full_day = null;
      }
      change.entity.status = 1;
    }

    // Timeoffs can't be greater than the smallest number of work hours
    // on any person the timeoff affects.
    if (
      change.type === 'timeoff' &&
      !change.entity.full_day &&
      !isTentativeTimeoff(change.entity) &&
      !isRejectedTimeoff(change.entity)
    ) {
      const hours = cells._helpers.getMinWorkHoursInRange(change.entity);
      if (change.entity.hours === hours) {
        change.entity.full_day = 1;
      }

      if (change.entity.hours > hours) {
        throw new Error('TOO_MANY_TIMEOFF_HOURS');
      }

      if (
        cells._helpers.overlapsFullDayTimeoff(cells, change.entity) &&
        // https://linear.app/float-com/issue/CS-767 - see issue and PR for more info
        cells._helpers.isEntityFullDayTimeoff(change.entity)
      ) {
        throw new Error('FULL_DAY_TIMEOFF_OVERLAP');
      }
    }

    if (change.entity.hours > 24) {
      throw new Error('TOO_MANY_HOURS');
    }

    const entityLength = dates.diffDays(
      change.entity.start_date,
      change.entity.end_date,
    );

    if (entityLength > 730) {
      throw new Error(`${change.type.toUpperCase()}_TOO_LONG`);
    }

    const entityLengthWithRepeat = change.entity.repeat_state
      ? dates.diffDays(change.entity.start_date, change.entity.repeat_end_date)
      : 0;

    if (
      entityLengthWithRepeat > getTaskMaxLength(change.entity.repeat_state || 0)
    ) {
      throw new Error(
        `${change.type.toUpperCase()}${
          change.entity.repeat_state === 9 ? '_YEARLY' : ''
        }_REPEAT_TOO_LONG`,
      );
    }

    if (isRepeatOverlap(change.entity.repeat_state, entityLength)) {
      throw new Error('REPEAT_OVERLAP');
    }
  }
}

function groupLinkedTaskChanges(changes) {
  const groupedChanges = groupBy(changes, (c) => !!c.entity.root_task_id);
  const res = [];

  if (groupedChanges['true']?.length) {
    res.push({
      type: 'linkedTasks',
      group: groupedChanges['true'],
      dispatchChange: true,
    });
  }

  if (groupedChanges['false']?.length) {
    res.push(...groupedChanges['false']);
  }

  return res;
}

function renderErrorMessage(error, i) {
  const { field, message } = error;
  if (!message) return null;

  if (field === 'name' && /150/gi.test(message)) {
    return (
      <p key={i}>
        Sorry, the task name is too long. Please reduce the length of the task
        name to 150 characters or less and try again.
      </p>
    );
  }

  return <p key={i}>{message}</p>;
}

function getErrors(error) {
  if (!error) return [];
  if (typeof error === 'string') {
    return [{ message: error }];
  }
  if (error.length) return error;
  if (error.message) return [{ message: error.message }];
  return error;
}

function showLoader({ method, nonScheduleActions }) {
  const isShiftTimeline = method === shiftPhaseTimeline;
  if (isShiftTimeline) {
    nonScheduleActions.showLoader('Shifting Phase');
    return true;
  }
  return false;
}

export default function persistChanges(props, nonScheduleActions) {
  const { confirm, dates, store, cells, setSelectedItems, actionMode } = props;

  function addTemporaryIds(changes) {
    changes.forEach((change) => {
      if (!change.id) {
        const tempId = getTempId();
        change.entity.temporaryId = tempId;
        change.id = tempId;

        if (change.type === 'task') {
          change.entity.task_id = tempId;
        } else if (change.type === 'timeoff') {
          change.entity.timeoff_id = tempId;
        }
      }
    });
  }

  function addTentativeChanges(changes) {
    changes.push(...cells._helpers.getTentativeChanges());
  }

  function addOneOffChanges(changes) {
    // If we originally had a oneOff day and we either moved or removed all
    // tasks that used to be on that oneOff day, we want to delete the oneOff.
    const candidates = [];
    changes.forEach((change) => {
      const { originalEntity: oe } = change;
      if (oe) {
        forEach(store.getState().oneOffs.oneOffs, (oneOff) => {
          if (
            oe.people_ids &&
            oe.people_ids.includes(oneOff.people_id) &&
            oneOff.date >= oe.start_date &&
            oneOff.date <= oe.end_date
          ) {
            candidates.push(oneOff);
          }
        });
      }
    });

    candidates.forEach((c) => {
      const [colIdx] = dates.toDescriptor(c.date);
      const cellKey = `person-${c.people_id}:${colIdx}`;
      const cell = cells[cellKey];
      const cellTasks = cell.items.filter((i) => i.type === 'task');

      const candidateStillHasTask = cellTasks.some((i) => {
        const isCurrentlyBeingDeleted = changes.some((ch) => {
          return ch.isRemove && ch.type === i.type && ch.id == i.entityId;
        });

        return (
          !isCurrentlyBeingDeleted &&
          i.entity.start_date <= c.date &&
          i.entity.end_date >= c.date
        );
      });

      if (!candidateStillHasTask) {
        changes.push({
          type: 'oneOff',
          isRemove: true,
          entity: c,
          originalEntity: c,
        });
      }
    });
  }

  function confirmChanges(changes) {
    return new Promise((resolve, reject) => {
      addTemporaryIds(changes);
      addTentativeChanges(changes);
      addOneOffChanges(changes);

      // We need to synchronously load our changes into cells to validate no
      // constraints are broken before we make a DB update. If we reject, the
      // calendar will unapply the proposed changes.
      cells._helpers.loadChanges(changes);

      try {
        validateChanges(dates, cells, changes, getUser(store.getState()));
      } catch (e) {
        switch (e.message) {
          case 'TOO_MANY_TIMEOFF_HOURS': {
            nonScheduleActions.showOverScheduledModal(true, reject);
            return;
          }

          case 'TOO_MANY_HOURS': {
            nonScheduleActions.showOverScheduledModal(false, reject);
            return;
          }

          case 'FULL_DAY_TIMEOFF_OVERLAP': {
            nonScheduleActions.showFullDayTimeoffOverlapModal(reject);
            return;
          }

          case 'TASK_TOO_LONG': {
            nonScheduleActions.showDurationLimitModal(
              false,
              false,
              maxEntityLength,
              reject,
            );
            return;
          }

          case 'TIMEOFF_TOO_LONG': {
            nonScheduleActions.showDurationLimitModal(
              true,
              false,
              maxEntityLength,
              reject,
            );
            return;
          }

          case 'TASK_REPEAT_TOO_LONG': {
            nonScheduleActions.showDurationLimitModal(
              false,
              true,
              maxEntityLength,
              reject,
            );
            return;
          }

          case 'TASK_YEARLY_REPEAT_TOO_LONG': {
            nonScheduleActions.showDurationLimitModal(
              false,
              true,
              maxYearlyEntityLength,
              reject,
            );
            return;
          }

          case 'TIMEOFF_REPEAT_TOO_LONG': {
            nonScheduleActions.showDurationLimitModal(
              true,
              true,
              maxEntityLength,
              reject,
            );
            return;
          }

          case 'TIMEOFF_YEARLY_REPEAT_TOO_LONG': {
            nonScheduleActions.showDurationLimitModal(
              false,
              true,
              maxYearlyEntityLength,
              reject,
            );
            return;
          }

          case 'STATUS_REPEAT_TOO_LONG': {
            nonScheduleActions.showDurationLimitModal(
              true,
              true,
              maxEntityLength,
              reject,
            );
            return;
          }

          case 'STATUS_YEARLY_REPEAT_TOO_LONG': {
            nonScheduleActions.showDurationLimitModal(
              false,
              true,
              maxYearlyEntityLength,
              reject,
            );
            return;
          }

          case 'REPEAT_OVERLAP': {
            nonScheduleActions.showRepeatOverlapModal(reject);
            return;
          }

          default: {
            throw e;
          }
        }
      }

      if (
        changes.length == 2 &&
        changes[1].type == 'task' &&
        changes[1].isSplit
      ) {
        // We want to split tasks (not timeoffs) using the special backend
        // endpoint so that it can adjust parent_task_ids as necessary.
        resolve(store.dispatch(splitTask(changes)));
        return;
      }

      const isLinkedTaskAdjustment =
        changes[0].entity.root_task_id &&
        (changes[0].entity.start_date !==
          changes[0].originalEntity.start_date ||
          changes[0].entity.end_date !== changes[0].originalEntity.end_date);

      if (isLinkedTaskAdjustment) {
        changes = groupLinkedTaskChanges(changes);
      }

      resolve(
        Promise.all(
          changes.map((change) => {
            const method = getMethod(actionMode, change);
            const isShowingLoader = showLoader({ method, nonScheduleActions });
            if (change.optimisticUpdate) {
              change.optimisticUpdate(change);
            }

            trackAnalytics(change);
            trackFirstTaskDuringOnboarding(change);

            if (change.dispatchChange) {
              return store.dispatch(method(change));
            }

            const promise =
              method === createTask
                ? store.dispatch(method(change.entity))
                : store.dispatch(method(change.entity, { ...change }));

            if (isShowingLoader) {
              return promise.then(() => {
                setTimeout(() => nonScheduleActions.hideLoader(), 200);
              });
            }
            return promise;
          }),
        ),
      );
    });
  }

  function undoChanges(changes) {
    changes.forEach((change) => {
      if (change.type === 'error' || change.type === 'timeRange') return;

      // To unapply a change, we just load the clone of the original
      // entity, which will override any changes we've made.
      props.dispatch({
        type: 'LOAD_DATA',
        dataType: change.type,
        isRemove: change.isCreate,
        ignoreMissing: true,
        id: change.id,
        data: change.isCreate
          ? {}
          : {
              [change.id]: change.originalEntity.allInstances[0],
            },
      });

      // We also want to ensure the Redux item is reverted
      if (change.entity && change.originalEntity) {
        Object.assign(change.entity, change.originalEntity);
      }
    });
  }

  return (changes) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const rejectAndUndo = (error) => {
        if (isUndefined(error)) {
          console.log('--- Undoing changes ---');
          console.log(changes);
          console.log('-----------------------');
        } else {
          console.error('--- Undoing changes due to error ---');
          console.error(error);
          console.error(safeStringify(changes));
          console.error('-----------------------');
        }

        undoChanges(changes);

        let handled = false;
        if (error?.length == 1 && error[0]['start_date, parent_task_id']) {
          handled = true;
          confirm({
            title: 'Invalid linked tasks',
            hideCancel: true,
            onConfirm: () => {},
            onCancel: () => {},
            message:
              "You're attempting to set a linked task start date before its parent's start date. These tasks have not been updated.",
          });
        }

        const errors = getErrors(error);

        // When data is out of sync, we may get errors without "field" value.
        // In this case, we need to check the message.
        // If it contains 'task_id' we expect data is out of sync - so user needs to reload page.
        if (
          errors?.length == 1 &&
          !errors[0].field &&
          errors[0].message &&
          errors[0].message.includes('task_id')
        ) {
          handled = true;
          confirm({
            title: 'Your schedule might be out of date.',
            hideCancel: true,
            confirmLabel: 'Reload',
            onConfirm: () => window.location.reload(),
            onCancel: () => {},
            message: 'Please reload your browser to see the latest data.',
          });
        }

        if (
          errors.length &&
          !/failed to fetch/gi.test(errors[0].message) &&
          !handled
        ) {
          const doNothing = () => {};
          const onError = changes.length === 1 && changes[0].onError;
          confirm({
            title: `We've got a problem`,
            hideCancel: true,
            onConfirm: onError || doNothing,
            onCancel: doNothing,
            message: <>{errors.map(renderErrorMessage)}</>,
          });
        }

        reject(!isUndefined(error) ? error : new Error('Validation errors'));
      };

      const handleChanges = (changes) => {
        const loggedTimeChanges = changes.every((c) => c.type === 'loggedTime');
        if (loggedTimeChanges) {
          return persistLoggedTimeChanges(props, changes)
            .then(resolve)
            .catch(rejectAndUndo);
        }

        return confirmChanges(changes).then(resolve).catch(rejectAndUndo);
      };

      // If the user is trying to remove a multi-assign task or timeoff, we want
      // to prompt them to determine if we should remove the entity for the
      // user they clicked on or all users.
      if (
        changes.length === 1 &&
        changes[0].isRemove &&
        changes[0].entity.people_ids?.length > 1 &&
        changes[0].clickedOnPersonId
      ) {
        const [change] = changes;
        const res = await promptForMultiAssignDelete(store, change);
        if (res === 'cancel') {
          rejectAndUndo();
          return;
        } else if (res === 'deleteThis') {
          change.isRemove = false;
          change.entity.people_ids = change.entity.people_ids.filter(
            (id) => id != change.clickedOnPersonId,
          );
        }
      }

      if (changes.every((c) => c.type === 'loggedTime')) {
        try {
          persistLoggedTimeChanges(props, changes)
            .then(resolve)
            .catch(rejectAndUndo);
        } catch (e) {
          rejectAndUndo(e);
        }
        return;
      }

      try {
        const preValidationErrors = changes.filter((c) => c.type === 'error');
        if (preValidationErrors.length > 1) {
          rejectAndUndo(new Error('Unknown prevalidationErrors, expected 1'));
        }
        if (
          preValidationErrors.length === 1 &&
          preValidationErrors[0].error.message === 'REMOVE_ENTITY_BEING_TOUCHED'
        ) {
          nonScheduleActions.showOverScheduledModal(true, rejectAndUndo);
          return;
        }

        const wasChanged = changes.some((c) => {
          return (
            c.isRemove ||
            (c.entity && fieldsChanged(c.entity, c.originalEntity))
          );
        });

        if (!wasChanged) {
          console.log('Nothing was changed', changes);
          undoChanges(changes);
          changes.forEach((change) => {
            if (change.nothingWasChanged) {
              change.nothingWasChanged(change);
            }
          });
          return;
        }

        const hasLinkedTaskCategoryChange = changes.some(
          (c) =>
            c.entity.root_task_id &&
            (c.entity.project_id !== c.originalEntity.project_id ||
              c.entity?.phase_id !== c.originalEntity?.phase_id),
        );
        if (hasLinkedTaskCategoryChange) {
          confirm({
            title: `Update linked task?`,
            message: (
              <p>
                Are you sure? Changing a linked task's project or phase will
                break the link.
              </p>
            ),
            onConfirm: () => {
              handleChanges(changes);
            },
            onCancel: () => {
              resolve(undoChanges(changes));
            },
          });

          return;
        }

        // We clear selected items after the wasChanged check because if the
        // user shift-clicks on the resize bar by accident, we don't want to
        // clear selection. However, we do want to clear the selection
        // regardless of whether or not the network update was successful.
        setSelectedItems({});

        const associatedChanges = changes.filter((c) => !!c.parentAssociation);
        const hasAssociatedChange = !!associatedChanges.length;
        const withUnsolvableConflicts = associatedChanges.some(
          (c) => !c.hasRights,
        );

        if (withUnsolvableConflicts) {
          const originalChange = changes[0];
          const isRequest =
            originalChange.status_request && originalChange.status === 2;
          const mode = isRequest ? 'approve' : 'schedule';
          confirm({
            title: `Conflicts need to be resolved`,
            message: (
              <p>
                Scheduled tasks, time off and logged time already on these dates
                need to be removed by a Project Manager before you can {mode}{' '}
                this time off request.
              </p>
            ),
            onConfirm: () => {
              resolve(undoChanges(changes));
            },
            hideCancel: true,
          });

          return;
        } else if (hasAssociatedChange) {
          confirm({
            title: `Conflicts will be deleted`,
            message: (
              <p>
                Are you sure? Scheduled tasks, time off and logged time already
                on these dates will be removed.
              </p>
            ),
            onConfirm: () => {
              handleChanges(changes);
            },
            onCancel: () => {
              resolve(undoChanges(changes));
            },
          });

          return;
        }

        // Note that we use the originalEntity here. If it was converted to a
        // repeating task, we don't need to prompt. Also, we'll never have a
        // repeat when we're dealing with multiselect changes as selecting
        // repeat entities is not allowed.
        const hasRepeatingEntity = changes.some((c) => {
          if (c.originalEntity && c.originalEntity.repeat_state) {
            // If the only change to the repeating entity is priority_info,
            // don't consider it a change to a repeating entity.
            const fields = getAllChangedFields(c.entity, c.originalEntity);
            return fields.some((f) => f !== 'priority_info') || c.isRemove;
          }
          return false;
        });

        // If the user is holding shift to duplicate a task, we'll skip the
        // prompt and create a new task with the same repeat configuration.
        const isDuplication = changes.length === 1 && changes[0].isCreate;
        if (isDuplication) {
          changes[0].isDuplication = true;
        }

        if (!hasRepeatingEntity || isDuplication) {
          handleChanges(changes);
          return;
        }

        if (changes.length !== 1) {
          const error = Error('unhandled scenario');

          error.cause = changes;

          throw error;
        }

        const [change] = changes;

        change.changedFields = getAllChangedFields(
          change.entity,
          change.originalEntity,
        );

        const onlyRepeatEndDateChanged =
          change.changedFields.length === 1 &&
          change.changedFields[0] === 'repeat_end_date';

        if (onlyRepeatEndDateChanged) {
          // If the user only updated the repeat_end field, go ahead and allow
          // the modification through without any prompts or repeat splitting.
          // Note that we have to trigger the update based on the original
          // instance so that we don't alter any dates.
          const { repeat_end_date } = change.entity;
          change.entity = change.entity.allInstances[0]; // eslint-disable-line
          change.entity.repeat_end_date = repeat_end_date;

          handleChanges(changes);
          return;
        }

        if (
          change.entity.integration_status === 1 &&
          change.changedFields.every(
            (field) =>
              [
                'name',
                'status',
                'end_date',
                'people',
                'project_id',
                'task_meta_id',
              ].includes(field),
            // Note: `end_date` and `people` ALWAYS change, hence included here
            //   These fields are prevented from change on the form UI for synced tasks
            //   so it's not an issue.
            //   This should probably be fixed at some stage.
          )
        ) {
          // Special case for repeating integration-synced tasks
          // if `integration_status` === 1 (synced)
          //   and changed fields are subset of [`name`, `status`, `project_id`, `task_meta_id`]
          // then don't prompt user as the change needs to occur to all instances
          // and not split the task as it will affect syncing
          const { name, status, project_id, task_meta_id } = change.entity;
          change.entity = {
            ...change.entity.allInstances[0],
            name,
            status,
            project_id,
            task_meta_id,
          };
          handleChanges(changes);
          return;
        }

        const changeMeta = getRepeatMeta(change);
        const { allInstances, instanceCount } = changeMeta;

        const handleRepeatChangeType = (repeatChangeType) => {
          if (changes.length !== 1) {
            console.error(safeStringify(changes));
            throw new Error('Unexpected condition');
          }

          const isRepeat = allInstances.length > 1;
          if (!isRepeat) {
            return handleChanges(changes); // There are no repeats to calculate
          }

          const [change] = changes;

          const isOnlyTaskStatusUpdate =
            change.type === 'task' &&
            change.changedFields.length === 1 &&
            change.changedFields[0] === 'status';

          if (isOnlyTaskStatusUpdate) {
            const updatedChanges = markChangeAsStatusUpdate(
              change,
              repeatChangeType,
            );
            return handleChanges(updatedChanges);
          }

          const updatedChanges = appendRepeatChanges(change, repeatChangeType);
          return handleChanges(updatedChanges);
        };

        const isLastInstance =
          allInstances.length > 1 && instanceCount == allInstances.length - 1;
        // If we're modifying the last instance of a repeat, there are no
        // future repeats, so there's no need to show a prompt.
        if (isLastInstance) {
          handleRepeatChangeType(ONLY_THIS);
          return;
        }

        store.dispatch(
          manageModal({
            visible: true,
            modalType: 'updateRepeatingModal',
            modalSettings: {
              changeType: change.type,
              async callback(answer) {
                if (answer === CANCEL) {
                  resolve(undoChanges(changes));
                  return;
                }

                handleRepeatChangeType(answer);
              },
            },
            skipSidePanelAutoClose: true,
          }),
        );
      } catch (e) {
        rejectAndUndo(e);
      }
    });
  };
}

export const _unitTestExports = {
  appendRepeatChanges,
  validateChanges,
};
