import React, { useState } from 'react';
import classNames from 'classnames';

import AvatarGroup from '@float/common/components/AvatarGroup';
import { ConfirmDeleteTeam } from '@float/common/components/ConfirmDeleteTeam';
import { delay } from '@float/common/lib/promise';
import request from '@float/common/lib/request';
import { redirectToSubdomainIfNeeded } from '@float/common/lib/url';
import { Company } from '@float/types/company';
import { ButtonAction } from '@float/ui/components/ButtonAction';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import IconLock from '@float/ui/deprecated/Earhart/Icons/Icon/IconLock';
import { IconTrash } from '@float/ui/deprecated/Earhart/Icons/Icon/IconTrash';

import {
  StyledBackground,
  StyledCompanyCard,
  StyledContent,
  TrashCan,
} from './styles';

async function loginToCompany(company: Company, email: string) {
  const formData = new FormData();
  formData.append('LoginForm[email]', email);
  formData.append('LoginForm[company_id]', company.company_id);

  // noRedirect prevents the request from redirecting to login on expired accounts
  await request.form('login', formData, {
    version: '',
    hostname: '',
    noRedirect: true,
  });
}

async function loginAndNavigateTo(
  company: Company,
  email: string,
  dest: string,
) {
  await loginToCompany(company, email);

  await delay(100);

  if (
    company.company_hostname &&
    redirectToSubdomainIfNeeded(company.company_hostname, false, dest)
  ) {
    return;
  }
  window.location.href = dest;
}

const STATUS_TEXT = {
  disabled: () => (
    <>
      Sorry, this account has been disabled. Contact{' '}
      <TextButton
        appearance="flay-underline"
        href="mailto:support@float.com"
        as="a"
      >
        support@float.com
      </TextButton>{' '}
      to resolve this issue.
    </>
  ),
  inactive: () => (
    <>
      Sorry, this account has been disabled. Contact{' '}
      <TextButton
        appearance="flay-underline"
        href="mailto:support@float.com"
        as="a"
      >
        support@float.com
      </TextButton>{' '}
      to resolve this issue.
    </>
  ),
  'paused-non-owner': (company: Company) => (
    <>
      Your Team Owner{company.owner ? ` (${company.owner?.name})` : ''} has
      paused your subscription. Please contact them for access.
    </>
  ),
  paused: (company: Company, email: string) => (
    <>
      You paused this subscription.
      <br />
      <TextButton
        appearance="flay-underline"
        onClick={() =>
          loginAndNavigateTo(company, email, `/me/reactivate-subscription`)
        }
      >
        Reactivate subscription
      </TextButton>
    </>
  ),
  'last-claim': (company: Company, email: string) => (
    <>
      This account has been disabled due to non-payment.
      <br />
      Please{' '}
      <TextButton
        as="a"
        appearance="flay-underline"
        onClick={() => loginAndNavigateTo(company, email, '/thankyou')}
      >
        update your card details
      </TextButton>
      .
    </>
  ),
  'last-claim-non-owner': () => (
    <>
      This account has been disabled due to non-payment.
      <br />
      Please contact your Account Owner.
    </>
  ),
  expired: (company: Company, email: string) => (
    <>
      Your free trial has ended.
      <br />
      <TextButton
        as="a"
        appearance="flay-underline"
        onClick={() => loginAndNavigateTo(company, email, '/thankyou')}
      >
        Upgrade to a paid plan
      </TextButton>
    </>
  ),
  'expired-non-owner': () => (
    <span>
      Your free trial has ended. To continue, your Account Owner needs to
      upgrade to a paid plan.
    </span>
  ),
};

const CompanyCard = ({
  className,
  company,
  email,
}: {
  className?: string;
  company: Company;
  email: string;
}) => {
  const { status, project_count, people_count, accounts } = company;

  const projects = `${project_count} Project${project_count == 1 ? '' : 's'}`;
  const people = `${people_count} ${people_count == 1 ? 'Person' : 'People'}`;

  const onClickCard = () => {
    if (status === 'active') {
      loginAndNavigateTo(company, email, '/');
    }
  };

  const [modal, setModal] = useState<'delete-team' | null>(null);

  const onClickDelete = () => {
    setModal('delete-team');
  };

  return (
    <StyledCompanyCard
      className={classNames(className, status)}
      onClick={onClickCard}
      role="button"
    >
      {modal === 'delete-team' && (
        <ConfirmDeleteTeam
          onClose={() => setModal(null)}
          companyName={company.company_name}
          onBeforeConfirm={() => loginToCompany(company, email)}
        />
      )}
      <StyledBackground />

      <StyledContent>
        {status !== 'active' && <IconLock className="icon-lock" />}

        <h2>{company.company_name}</h2>

        {status === 'active' && (
          <h3>
            {projects} / {people}
          </h3>
        )}

        <AvatarGroup
          className="avatar-stack"
          people={accounts.list}
          size="xs"
          max={2}
          avatarImage
        />

        {status !== 'active' && (
          <>
            <p>{STATUS_TEXT[status](company, email)}</p>

            {status !== 'expired-non-owner' && (
              <TrashCan>
                <ButtonAction icon={IconTrash} onClick={onClickDelete} />
              </TrashCan>
            )}
          </>
        )}
      </StyledContent>
    </StyledCompanyCard>
  );
};

export default CompanyCard;
