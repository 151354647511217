import React from 'react';
import { connect } from 'react-redux';
import { t } from '@lingui/macro';
import { isUndefined } from 'lodash';
import { getProjectsMapRaw } from 'selectors';

import { ReduxState } from '@float/common/reducers/lib/types';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';

interface EditRightsProps {
  ids: number[];
}

interface EditRightsState {
  common: boolean;
}

const mapStateToProps = (state: ReduxState) => ({
  projects: getProjectsMapRaw(state),
});

class EditRights extends React.Component<
  EditRightsProps & ReturnType<typeof mapStateToProps>,
  EditRightsState
> {
  state = {
    common: this.getDefaultValue(),
  };

  initialValue = this.state;

  getDefaultValue(): EditRightsState['common'] {
    const { projects, ids } = this.props;

    const defaultValue = projects[ids[0]]['common'];

    if (
      !isUndefined(defaultValue) &&
      ids.every((id) => projects[id]['common'] === defaultValue)
    ) {
      return defaultValue;
    }

    return false;
  }

  getFields = async () => {
    const { common } = this.state;

    return {
      common,
    };
  };

  getValue = async () => {
    return this.state;
  };

  onCommonChange = (common: boolean) => {
    this.setState({ ...this.state, common });
  };

  render() {
    return (
      <Checkbox
        label={t`Managers with ‘manage projects’ permission can edit this project`}
        value={this.state.common}
        onChange={this.onCommonChange}
      />
    );
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  EditRights,
);
