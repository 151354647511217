import { useEffect, useMemo, useRef, useState } from 'react';

import { insightsUpdated } from '@float/common/actions';
import { TimeRangeState } from '@float/common/reducers/timeRange';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import { getTimeRangeInsightsData } from '@float/common/serena/Data/insights/getTimeRangeInsightsData';
import {
  TimeRangeInsightsParams,
  TimeRangeInsightsType,
} from '@float/common/serena/Data/insights/types';
import { ScheduleDataFetcher } from '@float/common/serena/Data/ScheduleDataFetcher';
import { useAppSelectorStrict, useAppStoreStrict } from '@float/common/store';
import { ScheduleViewType } from '@float/constants/schedule';

import { getIsProjectsView } from './helpers';

export type TimeRangeInsightsProcessingProps = {
  end: string;
  fetchedRanges: number[] | undefined;
  fetcher: ScheduleDataFetcher;
  isDisabled: boolean;
  isLogTimeView: boolean;
  start: string;
  viewType: ScheduleViewType;
};

/**
 * Computes the time range insights and stores the state both on Redux and locally
 */
export function useTimeRangeInsightsProcessing({
  end,
  fetchedRanges,
  fetcher,
  isDisabled,
  isLogTimeView,
  start,
  viewType,
}: TimeRangeInsightsProcessingProps) {
  const store = useAppStoreStrict();
  const hasTimeTracking = useAppSelectorStrict(
    (state) => state.companyPrefs.time_tracking > 0,
  );

  const lastComputationMs = useRef(0);

  // Compute the debounce time based on how much slow
  // is the insights computation
  function getDebounceWaitDuration() {
    const value = lastComputationMs.current;

    if (!value) return 1000;

    if (value < 20) return 500;
    if (value < 100) return 1000;
    if (value < 1000) return 2000;
    if (value < 2000) return 3000;

    return 10000;
  }

  const dates = useAppSelectorStrict(selectDatesManager);

  // Request an additional time-range of data if the selected
  // time range is outside the current viewport
  const [rangesToFetch, setRangesToFetch] = useState<number[]>();
  useEffect(() => {
    async function fetchRanges() {
      const [colStart] = dates.toDescriptor(start);
      const [colStop] = dates.toDescriptor(end);

      const dateRanges = await fetcher.ensureRangeFetched({
        colStart,
        colStop,
        withLoggedTimes: hasTimeTracking,
      });

      if (dateRanges) {
        const rangesToFetch = dateRanges.flatMap((r) => r.ranges);
        setRangesToFetch(rangesToFetch);
      }
    }

    fetchRanges();
  }, [dates, end, fetcher, hasTimeTracking, start]);

  const isProjectsView = getIsProjectsView(viewType);

  let insightsType: TimeRangeInsightsType;

  if (isProjectsView) {
    insightsType = 'project';
  } else if (isLogTimeView) {
    insightsType = 'loggedTime';
  } else {
    insightsType = 'person';
  }

  const params = useMemo(
    () =>
      ({
        startDate: start,
        endDate: end,
        type: insightsType,
      }) satisfies TimeRangeInsightsParams,
    [end, insightsType, start],
  );

  // Loading the insights from the store to keep them on remounting
  const insights = useAppSelectorStrict((state) => state.timeRange.insights);
  const loaded = useAppSelectorStrict(
    (state) => state.timeRange.loadState === 'LOADED',
  );

  useEffect(() => {
    if (isDisabled) return;

    let lastValue: TimeRangeState['insights'];

    function updateInsights() {
      // Track the computation time of the insights
      const startMs = performance.now();

      const result = getTimeRangeInsightsData(store.getState(), params);

      if (result !== lastValue) {
        // Track only the computation time when the result is actually recomputed
        lastComputationMs.current = performance.now() - startMs;

        lastValue = result;
        store.dispatch(insightsUpdated(result));
      }
    }

    // Use a custom-made debounce to be able to clear the timer
    // when unsubscribing from the store
    let timer: ReturnType<typeof setTimeout> | null = null;

    const debouncedUpdate = () => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(updateInsights, getDebounceWaitDuration());
    };

    const unsubscribeFromStore = store.subscribe(debouncedUpdate);

    // We want to give an immediate feedback when selecting a new date range
    // or changing view
    updateInsights();

    return () => {
      unsubscribeFromStore();
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [store, params, isDisabled]);

  const hasFetchedTheRange = rangesToFetch?.every(
    (r) => fetchedRanges?.includes(r),
  );

  const isLoading = !loaded || !hasFetchedTheRange;

  return {
    isLoading,
    value: insights,
  };
}
