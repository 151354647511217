import React from 'react';
import { t, Trans } from '@lingui/macro';
import { get, isEmpty, map, omit, reduce, sortBy, without } from 'lodash';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { getManagerAccess } from '@float/common/lib/acl/access';
import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import { isAmountEmpty } from '@float/common/lib/budget';
import { formatRateWithCurrencySymbol } from '@float/common/lib/rates/rates';
import { searchService } from '@float/common/search/service';
import { MONETARY_BUDGET_TYPES } from '@float/constants/budgets';
import { ButtonAction } from '@float/ui/components/ButtonAction';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import IconBg from '@float/ui/deprecated/Earhart/Icons/IconBg';
import { bindVal } from '@float/ui/deprecated/helpers/forms';
import { Input } from '@float/ui/deprecated/Input';
import { CurrencyInput } from '@float/ui/deprecated/Input/CurrencyInput';
import { Label } from '@float/ui/deprecated/Label';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { TopGreySection as ModalTopGreySection } from '@float/ui/deprecated/Modal/ModalHeader/styles';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import RemoveTeamMember from '@float/web/components/modals/RemoveTeamMember';

import { ProjectOwnerReadOnly } from '../ProjectDetails';
import { getFilteredTeam, isCurrentUserOwner } from '../ProjectModal.helpers';
import { getPersonRate } from './helpers/getPersonRate';
import { NoTeam, ScrollableList } from './TeamFragment.styles';

const avatarProps = {
  readOnly: true,
  size: 'xs',
};

function removeTeamMember(person) {
  return async () => {
    const onDelete = () => {
      this.setState((ps) => ({
        form: {
          ...ps.form,
          people_ids: without(ps.form.people_ids, person.people_id),
          people_rates: omit(ps.form.people_rates, person.people_id),
        },
      }));
    };

    // adding project, so no existing tasks can be
    if (!this.state.form.project_id) {
      onDelete();
      return;
    }

    const taskedPeople = await searchService.getSelectorValue(
      this.props.store.getState(),
      'getTaskedPeopleByProjectId',
      [this.props.modalSettings.projectId],
    );

    const atLeastOneTaskAssigned = taskedPeople.has(String(person.people_id));

    if (!atLeastOneTaskAssigned) {
      onDelete();
      return;
    }

    this.props.confirmDelete(
      RemoveTeamMember({
        person,
        projects: [this.state.form],
        isPhase: this.isPhase,
        onDelete,
      }),
    );
  };
}

function addTeamMember(selected) {
  if (selected.groupValue === -1) {
    const people_ids = Object.keys(this.props.people).map(Number);
    const people_rates = people_ids.reduce((perople_rates_by_id, person_id) => {
      perople_rates_by_id[person_id] = getPersonRate.call(this, person_id);
    }, {});

    // User chose to add ALL people
    this.setState((ps) => ({
      form: {
        ...ps.form,
        people_ids,
        people_rates,
      },
    }));
    return;
  }

  this.setState((ps) => {
    const person_id = selected.value;
    const person_rate = getPersonRate.call(this, person_id);

    return {
      form: {
        ...ps.form,
        people_ids: [...ps.form.people_ids, person_id],
        people_rates: { ...ps.form.people_rates, [person_id]: person_rate },
      },
    };
  });
}

function addGroupTeamMember(groupOptions) {
  this.setState((ps) => ({
    form: {
      ...ps.form,
      people_ids: [
        ...ps.form.people_ids,
        ...groupOptions.map((go) => go.value),
      ],
    },
  }));
}

export default function TeamFragment() {
  const { form } = this.state;
  const { people, currentUser, currencyConfig } = this.props;
  const { department_filter_all } = currentUser;

  const activeTeam = getFilteredTeam(form, currentUser, people);
  const canSeeBudgets = getCanCurrentUserSeeBudgets(currentUser);

  const peopleByDept = sortBy(
    reduce(
      people,
      (acc, p) => {
        if (!p.active || form.people_ids?.includes(p.people_id)) return acc;
        if (
          department_filter_all?.length &&
          !department_filter_all.includes(p.department_id)
        ) {
          return acc;
        }

        const dept = get(p, 'department.name', 'No department');
        const deptId = get(p, 'department.department_id', 0);
        const defaultHourlyRate =
          p.default_hourly_rate || p.role?.default_hourly_rate || null;

        if (!acc[dept]) {
          acc[dept] = {
            name: dept,
            value: deptId,
            options: [],
          };
        }

        const formattedRate =
          canSeeBudgets && defaultHourlyRate
            ? formatRateWithCurrencySymbol(defaultHourlyRate, currencyConfig)
            : null;

        acc[dept].options.push({
          value: p.people_id,
          label: p.name,
          icon: <PersonAvatar personId={p.people_id} {...avatarProps} />,
          iconRight: formattedRate ? (
            <span>
              <Trans>
                {formattedRate}
                /hr
              </Trans>
            </span>
          ) : null,
        });

        return acc;
      },
      {},
    ),
    (g) => g.name.toLowerCase(),
  );

  peopleByDept.forEach((dept) => {
    dept.options = sortBy(dept.options, (o) => o.label.toLowerCase());
  });

  if (peopleByDept.length) {
    peopleByDept.unshift({
      name: '',
      value: -1,
      options: [
        {
          label: 'All team members',
        },
      ],
    });
  }

  const assignablePms = sortBy(
    map(this.props.accounts, (a) => ({
      value: a.account_id,
      label: a.name || '',
      icon: <PersonAvatar accountId={a.account_id} {...avatarProps} />,
    })),
    (p) => p.label.toLowerCase(),
  );

  const isVariableRate =
    MONETARY_BUDGET_TYPES.includes(form.budget_type) &&
    form.default_hourly_rate === null;

  const showRateInput = canSeeBudgets && isVariableRate;

  const hasErrors = Boolean(
    showRateInput && this.state.formErrors.people_rates?.length,
  );

  const isOwner = isCurrentUserOwner.call(this);

  return (
    <>
      <ModalTopGreySection>
        <VirtualSelect
          hideClearIcon
          keepFocusAfterSelect
          label={t`Assign a team member`}
          visibleItems={6}
          placeholder=""
          autoFocus
          groupedOptions={peopleByDept}
          onChange={addTeamMember.bind(this)}
          onSelectGroupAll={addGroupTeamMember.bind(this)}
        />
      </ModalTopGreySection>
      <ScrollableList>
        {hasErrors && (
          <Label
            className="rates-error"
            color="red"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icons.IconAlert size="18" />
            Please add hourly rates for all team members.
          </Label>
        )}
        {activeTeam.map((member, i) => {
          const personRate = getPersonRate.call(this, member.people_id);
          const personRateError = hasErrors && isAmountEmpty(personRate);
          const isManager = member.account?.account_type === 7;
          const managerAccess = isManager && getManagerAccess(member.account);

          return (
            <li key={member.people_id}>
              <span className="avatar-image">
                <PersonAvatar personId={member.people_id} {...avatarProps} />
              </span>
              <span className="name">{member.name}</span>
              <Spacer size={6} />
              {managerAccess.isProjectManager && (
                <TextTooltip
                  key="project-manager"
                  placement="top"
                  content={t`Project Manager`}
                >
                  <IconBg size={24}>
                    <Icons.IconFolder size={16} />
                  </IconBg>
                </TextTooltip>
              )}
              <div className={`controls${personRateError ? ' error' : ''}`}>
                {showRateInput && (
                  <>
                    <CurrencyInput
                      allowZero
                      currentUser={this.props.currentUser}
                      value={personRate}
                      onChange={(evt) => {
                        const rate = evt.currentTarget.value;

                        if (!Input.validators.number(6)(rate)) return;

                        this.setState((ps) => ({
                          form: {
                            ...ps.form,
                            people_rates: {
                              ...ps.form.people_rates,
                              [member.people_id]: rate,
                            },
                          },
                        }));
                      }}
                    />
                    <span className="per-hour">p/hr</span>
                  </>
                )}

                <ButtonAction
                  icon={Icons.IconClose}
                  onClick={removeTeamMember.bind(this)(member)}
                />
              </div>
            </li>
          );
        })}
        {isEmpty(activeTeam) && (
          <NoTeam>
            {this.isPhase ? (
              <Trans>There are no people assigned to this phase.</Trans>
            ) : (
              <Trans>There are no people assigned to this project.</Trans>
            )}
          </NoTeam>
        )}
      </ScrollableList>
      {!this.isPhase && isOwner && (
        <>
          <VirtualSelect
            label={t`Project Owner`}
            style={{ marginBottom: 20 }}
            nonNullable
            visibleItems={6}
            optionsPlacement="top"
            options={assignablePms}
            {...bindVal(this, 'project_manager')}
          />
          <Checkbox
            label={t`All Project Managers have edit rights`}
            {...bindVal(this, 'common')}
          />
        </>
      )}
      {!this.isPhase && !isOwner && (
        <ProjectOwnerReadOnly
          project={this.props.modalSettings.project}
          accounts={this.props.accounts}
        />
      )}
    </>
  );
}
