import { t } from '@lingui/macro';
import {
  amountFormatter,
  filterLinkFormatter,
  hoursFormatter,
  pctFormatter,
  rangeBarFormatter,
} from 'reports/helpers/tableFormatters';

import type { AccordionTableHeaderConfig } from '@float/ui/deprecated/AccordionTable/types';

import { getLoggedLabel } from '../../../helpers/getLoggedLabel';
import { getScheduledLabel } from '../../../helpers/getScheduledLabel';
import { getPctHeader } from '../PercentageSelector';
import type { ProjectsOverviewTableContext } from '../types';

const COLUMN_SPACING_GAP = { label: '', width: 140 };

export function getClientsTableHeaders(ctx: ProjectsOverviewTableContext) {
  const headers: Array<AccordionTableHeaderConfig> = [
    {
      label: t`Client`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: t`Budget`, width: 130, formatter: amountFormatter },
    COLUMN_SPACING_GAP,
    {
      label: getScheduledLabel(ctx.mode),
      width: 60,
      allowOverflow: true,
      formatter: hoursFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: getLoggedLabel(ctx.mode),
      width: 60,
      allowOverflow: true,
      formatter: hoursFormatter,
    },
    {
      label: '',
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: getPctHeader(ctx),
      width: 130,
      // @TODO(PI-91)
      // @ts-expect-error - We need to refactor the types to account for the RangeBar types
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!ctx.timeTrackingEnabled) {
    // Hide the Logged columns if the user doesn't have time tracking
    headers.splice(4, 2);
  }

  return headers;
}
