import { useHistory } from 'react-router-dom';

import { updateProject } from '@float/common/actions/projects';
import { usePrintContext } from '@float/common/contexts/PrintContext';
import { Project } from '@float/types/project';
import { useWebAppDispatchStrict } from '@float/web/lib/store';
import manageModal from '@float/web/modalManager/manageModalActionCreator';
import { showTemplateSidePanel } from '@float/web/sidePanel/actions';

export const useSingleProjectMenu = () => {
  const printContext = usePrintContext();
  const dispatch = useWebAppDispatchStrict();
  const history = useHistory();
  const viewProjectReport = (projectName: string) => {
    history.push(`/report?project=${encodeURIComponent(projectName)}`);
  };

  const showDuplicateModal = (project: Project) => {
    dispatch(
      manageModal({
        visible: true,
        modalType: 'projectModal',
        modalSettings: {
          project,
          isAdding: true,
          duplicating: true,
        },
      }),
    );
  };

  const showShiftTimelineModal = (project: Project) => {
    dispatch(
      manageModal({
        visible: true,
        modalType: 'projectShiftModal',
        modalSettings: {
          project,
          isEditable: project.canEdit,
        },
      }),
    );
  };

  const showShareLinkModal = () => {
    dispatch(
      manageModal({
        visible: true,
        modalType: 'shareLinkModal',
      }),
    );
  };

  const showExportCSVModal = () => {
    dispatch(
      manageModal({
        visible: true,
        modalType: 'scheduleCSVExportModal',
      }),
    );
  };

  const archiveProject = (projectId: number) => {
    dispatch(updateProject(projectId, { active: false }));
  };

  const createTemplateFromProject = (projectId: number) => {
    dispatch(showTemplateSidePanel({ projectId }));
  };

  const printSingleProjectView = () => {
    printContext.requestPrint();
  };

  return {
    showDuplicateModal,
    showShiftTimelineModal,
    viewProjectReport,
    showShareLinkModal,
    showExportCSVModal,
    archiveProject,
    createTemplateFromProject,
    printSingleProjectView,
  };
};
