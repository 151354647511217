import React from 'react';
import { t } from '@lingui/macro';
import { debounce } from 'es-toolkit';

import {
  getClientColors,
  getRecentColors,
} from '@float/common/selectors/colors';
import { useAppSelectorWithParams } from '@float/common/store';
import {
  ColorPickerDropdown,
  ColorTrigger,
} from '@float/ui/deprecated/ColorPicker/ColorPickerDropdown';

import { useUpdateProject } from './hooks/useUpdateProject';

export const ColorPicker = ({
  color,
  clientId,
  projectId,
}: {
  color: string;
  clientId: number;
  projectId: number;
}) => {
  const clientColors = useAppSelectorWithParams(getClientColors, clientId);
  const recentColors = useAppSelectorWithParams(getRecentColors);
  const onChangeColor = useUpdateProject();
  const onChangeHandler = (color: string): void => {
    onChangeColor(projectId, { color });
  };
  const debouncedOnChangeHandler = debounce(onChangeHandler, 500);
  return (
    <ColorPickerDropdown
      value={color}
      onChange={debouncedOnChangeHandler}
      clientColors={clientColors}
      recentColors={recentColors}
      modal
    >
      <ColorTrigger
        aria-label={t`Select project color`}
        size={'lg'}
        color={color}
      />
    </ColorPickerDropdown>
  );
};
