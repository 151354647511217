import { formatAmount, toCents } from '@float/common/lib/budget';
import { config } from '@float/libs/config';

import { getNumberSeparators } from './budget/separators';

function formatPrice(amount: string | null) {
  const { decimal } = getNumberSeparators(config.locale);
  if (amount?.endsWith(`${decimal}5`)) amount += '0';
  return amount;
}

function toDollars(cents: number) {
  const dollars = formatAmount(null, cents / 100);
  return formatPrice(dollars);
}

type Plan = {
  add_ons?: {
    pluspack?: number;
    timetracking?: number;
    tracking?: boolean;
  };
  is_monthly: boolean;
  is_per_person: boolean;
  label: string;
  plan_id: string;
  price: string;
};

const DEFAULT_MONTHLY: Plan = {
  add_ons: {
    pluspack: 7.5,
    timetracking: 5,
  },
  is_monthly: true,
  is_per_person: true,
  label: 'Monthly starter plan',
  plan_id: '23001',
  price: '7.50',
};

const DEFAULT_YEARLY: Plan = {
  add_ons: {
    pluspack: 72,
    timetracking: 48,
  },
  is_monthly: false,
  is_per_person: true,
  label: 'Annual starter plan',
  plan_id: '23002',
  price: '72.00',
};

export const STARTER_MONTHLY: Plan = {
  is_monthly: true,
  plan_id: '23018',
  is_per_person: true,
  price: '7.50',
  label: 'Annual Starter Plan',
};

export const STARTER_YEARLY: Plan = {
  is_monthly: false,
  plan_id: '23019',
  is_per_person: true,
  price: '72.00',
  label: 'Annual Starter Plan',
};

export const PRO_MONTHLY: Plan = {
  is_monthly: true,
  plan_id: '23020',
  is_per_person: true,
  price: '12.50',
  label: 'Annual Starter Plan',
};

export const PRO_YEARLY: Plan = {
  is_monthly: false,
  plan_id: '23021',
  is_per_person: true,
  price: '120.00',
  label: 'Annual Starter Plan',
};

export const DEFAULT_ENTERPRISE: Plan = {
  is_monthly: false,
  is_per_person: true,
  label: 'Annual Enterprise plan',
  plan_id: '23022',
  price: '192.00',
};

export function calculatePrice({
  peopleLimit = 1,
  hasTimeTracking,
  hasPlusPack,
  isMonthly,
  plans,
}: {
  peopleLimit?: number;
  hasTimeTracking?: boolean;
  hasPlusPack?: boolean;
  isMonthly?: boolean;
  plans: {
    monthly?: Plan;
    annual?: Plan;
    enterprise?: Plan;
  };
}) {
  const {
    monthly: monthlyPlan = DEFAULT_MONTHLY,
    annual: yearlyPlan = DEFAULT_YEARLY,
    enterprise: enterprisePlan = DEFAULT_ENTERPRISE,
  } = plans;
  // TODO: Remove after plus pack will be updated
  if ((!monthlyPlan || !yearlyPlan) && hasPlusPack) {
    return {};
  }
  const monthly = toCents(monthlyPlan.price);

  const monthlyTrackingRaw = Number(
    monthlyPlan.add_ons?.tracking ?? monthlyPlan.add_ons?.timetracking ?? 5,
  );
  const annualTrackingRaw = Number(
    yearlyPlan.add_ons?.tracking ?? yearlyPlan.add_ons?.timetracking ?? 48,
  );

  const monthlyPlusPackRaw = monthlyPlan.add_ons?.pluspack ?? 5;
  const annualPlusPackRaw = yearlyPlan.add_ons?.pluspack ?? 48;
  const monthlyTimeTracking = toCents(monthlyTrackingRaw);
  const monthlyPlusPack = toCents(monthlyPlusPackRaw);
  const annual = toCents(yearlyPlan.price);
  const annualTimeTracking = toCents(annualTrackingRaw);
  const annualPlusPack = toCents(annualPlusPackRaw);
  const annualDiscount = ((monthly - annual / 12) * 100) / monthly;

  const enterprise = toCents(enterprisePlan.price);

  let total = monthly;
  if (hasTimeTracking) total += monthlyTimeTracking;
  if (hasPlusPack) total += monthlyPlusPack;
  if (!isMonthly) total *= (100 - annualDiscount) / 100;

  total = isMonthly ? total : total * 12;

  return {
    total: toDollars(total * peopleLimit),
    perPersonMonthly: toDollars(monthly),
    perPersonPerMonthAnnual: toDollars(annual / 12),
    // Time Tracking
    perPersonMonthlyWithTimeTracking: toDollars(monthly + monthlyTimeTracking),
    perPersonPerMonthAnnualWithTimeTracking: toDollars(
      (annual + annualTimeTracking) / 12,
    ),
    // Plus Pack
    perPersonMonthlyWithPlusPack: toDollars(monthlyPlusPack),
    perPersonPerMonthAnnualWithPlusPack: toDollars(annualPlusPack / 12),
    annualDiscount,

    // Enterprise
    perPersonPerMonthAnnualEnterprise: toDollars(enterprise / 12),
  };
}
