import React from 'react';
import cn from 'classnames';

import * as styles from './PinTarget.css';

export const PinTarget = ({
  className,
  children,
  isSideMenuDisplayed,
}: React.PropsWithChildren<{
  className?: string;
  isSideMenuDisplayed?: boolean;
}>) => {
  return (
    <div
      className={cn(
        styles.pinTarget({
          left: isSideMenuDisplayed
            ? 'isSideMenuDisplayed'
            : 'isSideMenuHidden',
        }),
        className,
      )}
    >
      {children}
    </div>
  );
};
