import { getCellItemPersonId } from '@float/common/components/Schedule/Cell/MainCell/Item/box/utils/getCellItemPersonId';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { selectPersonWorkDaysCountInRange } from '@float/common/selectors/schedule/selectPersonWorkDaysCountInRange';
import { selectPersonWorkHoursInRange } from '@float/common/selectors/schedule/selectPersonWorkHoursInRange';
import { CellItem } from '@float/types/cells';

export const STEP_VALUE_IN_PERCENTAGE = 5;

export const getHourDeltaStepForPercentageAllocation = (
  item: CellItem<'task'>,
  state: ReduxStateStrict,
) => {
  const personId = getCellItemPersonId(item.rowId);

  const workHoursInRange = selectPersonWorkHoursInRange(state, {
    personId,
    startDate: item.entity.start_date,
    endDate: item.entity.end_date,
  });

  const workDaysInRange = selectPersonWorkDaysCountInRange(state, {
    personId,
    startDate: item.entity.start_date,
    endDate: item.entity.end_date,
  });

  const worhHoursPerDayInRange = workHoursInRange / workDaysInRange;

  const stepValueInHours =
    worhHoursPerDayInRange / (100 / STEP_VALUE_IN_PERCENTAGE);

  return stepValueInHours;
};
