import { useMemo, useRef } from 'react';

import { useCellsHeights } from '@float/common/serena/Data/useCellsHeights';
import { ScheduleViewType } from '@float/constants/schedule';
import { CellsMap } from '@float/types/cells';
import { ScheduleRowList } from '@float/types/rows';

import { createRowHeightGetter } from './helpers/getRowHeight';
import { createRangeGetter } from './helpers/getRowRange';
import { RowsPositionEntry } from './types';

export const TIME_RANGE_HEIGHT_PX = 24;

export type RowManagerProps = {
  boundaryCol: number;
  hourHeight: number;
  containerHeight: number;
  containerY: number;
  scrollbarSize: number;
  rows: ScheduleRowList;
  cells: CellsMap;
  numVisibleWeeks: number;
  singleUserView: boolean;
  scrollTop: number;
  viewType?: string | undefined;
  suvWeek?: number | undefined;
};

function getAnchorPositionDelta(
  rowsPositionList: RowsPositionEntry[],
  prevRowsPosition: RowsPositionEntry[],
  anchorIdx: number,
) {
  const oldAnchor = prevRowsPosition[anchorIdx];
  const newAnchor = rowsPositionList[anchorIdx];

  return newAnchor && oldAnchor ? newAnchor.offset - oldAnchor.offset : 0;
}

export function useRowsPositionManager({
  boundaryCol,
  hourHeight,
  containerHeight,
  containerY,
  scrollbarSize,
  rows,
  cells,
  numVisibleWeeks,
  singleUserView,
  scrollTop,
  viewType,
  suvWeek,
}: RowManagerProps) {
  const rowsPositionListRef = useRef<RowsPositionEntry[]>([]);
  const isSingleProjectView = viewType === ScheduleViewType.SingleProject;
  const currentList = rowsPositionListRef.current;

  const anchorIdx = Math.max(
    0,
    // Find the fist row that is placed above the scroll top
    currentList.findIndex((cb) => cb.offset >= scrollTop),
  );

  const cellsHeights = useCellsHeights(cells);

  const rowsPositionManager = useMemo(() => {
    const getRowHeight = createRowHeightGetter({
      boundaryCol,
      cellsHeights,
      containerY,
      hourHeight,
      numVisibleWeeks,
      scrollbarSize,
      singleUserView,
      isSingleProjectView,
    });

    // Calculate the position list with a stacking offset value
    let lastOffset = 0;

    const prevPositions = rowsPositionListRef.current;
    const rowsPositionList = rows.map((row, i) => {
      // In some cases when scrolling horizontally the offsets can get huge updates.
      // That's because when having many rows above the changes on the height might
      // be significant when scrolling horizontally and the delta adjustment might not be correct
      // because we virtualize the rows rendering.
      //
      // To fix this problem we don't update the non-visible rows height and we pick the entries
      // above the current viewport from the last computation
      if (i < anchorIdx - 1 && row.id === prevPositions[i]?.id) {
        const entry = prevPositions[i];

        // This is a false positive
        // eslint-disable-next-line react-compiler/react-compiler
        lastOffset = entry.offset + entry.size;

        return entry;
      }

      const entry = { offset: lastOffset, size: getRowHeight(row), id: row.id };

      lastOffset = entry.offset + entry.size;

      return entry;
    });

    rowsPositionListRef.current = rowsPositionList;

    return {
      bounds: rowsPositionList,
      getRowRange: createRangeGetter(rowsPositionList, containerHeight),
      delta: getAnchorPositionDelta(rowsPositionList, prevPositions, anchorIdx),
      totalHeight: lastOffset,
    };
    // Disabled to allow the inclusion of viewType and suvWeek
    // which are required to make LogMyTime work properly
    // eslint-disable-next-line
  }, [
    boundaryCol,
    cellsHeights,
    containerY,
    hourHeight,
    numVisibleWeeks,
    scrollbarSize,
    singleUserView,
    rows,
    containerHeight,
    anchorIdx,
    viewType,
    suvWeek,
  ]);

  return rowsPositionManager;
}
