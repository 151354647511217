import { get } from 'lodash';

import { Person } from '@float/types';

import { getBillRatePayload } from '../../lib/getBillRatePayload';
import { getCostRatePayload } from '../../lib/getCostRatePayload';

export type PersonFormData = Omit<
  Person,
  'people_id' | 'department_id' | 'role_id' | 'tags'
> & {
  department_id?: string | number;
  role_id?: string | number | null;
  department_filter?: number[];
  regionHolidayIds?: number[];
  people_id?: number;
  removeImage?: unknown;
  cost_rate_effective_date?: string;
  tags: {
    name: string;
    label?: string;
    type: number;
  }[];
};

export type ProjectsUpdateRecords = { add: number[]; del: number[] };
export type ProjectsUpdateRecordsPayload = {
  add?: { project_id: number }[];
  del?: number[];
};

export const mapV3PersonToSave = (
  p: PersonFormData,
  projectsUpdateRecords?: ProjectsUpdateRecords,
) => {
  const accountType = get(p, 'account.account_type', 0);
  const regionId = p.region_id || null;
  const emptyDepartments: number[] = [];

  let projects: ProjectsUpdateRecordsPayload | undefined = undefined;

  if (projectsUpdateRecords) {
    const { add, del } = projectsUpdateRecords;

    // The API doesn't accept an empty array
    if (add.length > 0) {
      projects = {
        add: add.map((id) => ({
          project_id: id,
        })),
      };
    }
    if (del.length > 0) {
      if (projects) {
        projects.del = del;
      } else {
        projects = {
          del,
        };
      }
    }
  }

  return {
    access_rights: accountType,
    people_id: p.people_id,
    name: p.name || '* New Person',
    job_title: p.job_title || null,
    email: p.email,
    notes: p.notes || null,
    employee_type: p.employee_type,
    people_type_id: p.people_type_id,
    active: p.active,
    removeImage: p.removeImage,
    department: {
      department_id: get(p, 'department_id', 0),
    },
    start_date: p.start_date,
    end_date: p.end_date,
    work_days_hours: p.work_days_hours,
    work_days_hours_history: p.work_days_hours_history,
    access: get(p, 'account.access', undefined),
    management_group: get(p, 'account.management_group', undefined),
    department_filter: get(p, 'account.department_filter_set', false)
      ? get(p, 'account.department_filter', emptyDepartments)
      : emptyDepartments,
    region_id: typeof p.region_id === 'undefined' ? undefined : regionId,
    tags:
      p.tags &&
      p.tags.map((t) => ({
        name: t.name || t.label,
        type: t.type,
      })),
    projects,
    new_role_placeholder: p.new_role_placeholder,
    ...getCostRatePayload(p),
    ...getBillRatePayload(p),
  };
};
