import type {
  SEARCH_RESOLVE_WILDCARD_VALUE,
  SearchResolveFilterOperator,
  SearchResolveMatchMode,
  SearchResolveQueryLogicalOperator,
} from './search.constants';

export type RemoteEntityType =
  | 'client'
  | 'department'
  | 'manager'
  | 'people'
  | 'peopleTag'
  | 'peopleType'
  | 'role'
  | 'timeOffStatus'
  | 'project'
  | 'phase'
  | 'projectOwner'
  | 'projectTag'
  | 'projectStatus'
  | 'task'
  | 'timeOffType'
  | 'taskStatus';

export type EntityMeta = {
  parent_department_id?: null | number;
  isActive?: 0 | 1;
  code?: string;
};

export type SearchQueryApiResult = {
  ids: number[];
  name: string;
  type: RemoteEntityType;
  score: number;
  meta?: Partial<Record<string, EntityMeta>>;
};

export type SearchQueryApiParams = {
  query: string;
  filter?: {
    key: 'category';
    value: string;
  };
  pagination?: {
    page: number;
    pageSize: number;
  };
  attributes?: Partial<Record<RemoteEntityType, string[]>>;
};

export type PaginatedQueryResponse = [SearchQueryApiResult[], number, number];

interface SearchResolveContainsFilter {
  operator: 'contains';
  field: null;
  value: string;
}

interface SearchResolveIsFilter {
  operator: SearchResolveFilterOperator;
  field: RemoteEntityType;
  value: number | number[] | typeof SEARCH_RESOLVE_WILDCARD_VALUE;
  skip?: SearchResolveFieldValues[];
  matchMode?: SearchResolveMatchMode;
}

export type SearchResolveFilter =
  | SearchResolveContainsFilter
  | SearchResolveIsFilter;

type SearchResolveLogicalOperator = SearchResolveQueryLogicalOperator;

export interface SearchResolveQuery {
  filters: (SearchResolveFilter | SearchResolveQuery)[];
  logicalOperator: SearchResolveLogicalOperator;
}

export enum SearchResolveContext {
  Schedule = 'schedule',
  LoggedTime = 'logged_time',
}

export const SearchResolveField = {
  PEOPLE: 'people',
  PROJECT: 'project',
  LOGGED_TIME: 'loggedTime',
  TASK: 'task',
  TIME_OFF: 'timeOff',
} as const;

export type SearchResolveFieldValues =
  (typeof SearchResolveField)[keyof typeof SearchResolveField];

export interface SearchResolveApiParams {
  query: SearchResolveQuery;
  context: SearchResolveContext;
  fields?: SearchResolveFieldValues[];
  contextId?: string;
}

export type SearchResolveEntityKey = keyof SearchResolveResponse['result'];

export interface SearchResolveResponse {
  contextId: string; // UUID
  result: {
    loggedTime: string[];
    people: number[];
    project: number[];
    task: number[];
    timeOff: number[];
  };
}
