import React from 'react';
import { t } from '@lingui/macro';

import { getBudgetTypeText } from '@float/common/lib/budget/helpers/getBudgetTypeText';
import { ProjectBudgetType } from '@float/constants/projects';
import { Tag } from '@float/ui/components/Tag';
import { Tooltip } from '@float/ui/components/Tooltip';
import { IconInformation } from '@float/ui/icons/essentials/IconInformation';
import type { ProjectBudgetPriority } from '@float/constants/projects';

import * as styles from './BudgetTypeTag.css';

export type BudgetTypeTagProps = {
  budgetType: ProjectBudgetType;
  budgetPriority?: ProjectBudgetPriority;
};

export const BudgetTypeTag = ({
  budgetType,
  budgetPriority,
}: BudgetTypeTagProps) => {
  if (budgetType === ProjectBudgetType.Disabled) return null;

  const hasInfoIcon = budgetType === ProjectBudgetType.HourlyFee;

  return (
    <div className={styles.wrapper}>
      <Tag size="small" color="primary">
        {getBudgetTypeText({
          budget_type: budgetType,
          budget_priority: budgetPriority,
        })}
      </Tag>
      {hasInfoIcon && (
        <Tooltip
          content={t`The total sum of the scheduled hours multiplied by bill rates`}
        >
          <span
            className={styles.infoIconWrapper}
            aria-label={t`Budget information icon`}
          >
            <IconInformation size={20} />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
