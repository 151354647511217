import { createSelector } from 'reselect';

import { getProjectsMap } from './projects';

export const selectEnhancedProjectById = createSelector(
  [getProjectsMap, (_: unknown, id: number | undefined) => id],
  (projects, id) => {
    if (!id) return undefined;

    return projects[id];
  },
);
