import { createSelector } from 'reselect';

import { getSearchFilteredPeopleIds } from '@float/common/search/selectors/people';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import { getFullTasksList } from '@float/common/selectors/tasks';
import { Task } from '@float/types/task';

import { isRepeatableEntityWithinTheTimeRange } from '../helpers/isRepeatableEntityWithinTheTimeRange';
import { TimeRangeInsightsParams } from '../types';

export const getTimeRangeTasksByPerson = createSelector(
  [
    getFullTasksList,
    getSearchFilteredPeopleIds,
    selectDatesManager,
    (_: unknown, params: Omit<TimeRangeInsightsParams, 'type'>) => params,
  ],
  (tasks, peopleIds, dates, params) => {
    const result = new Map<number, Task[]>();

    for (const task of tasks) {
      if (!isRepeatableEntityWithinTheTimeRange(task, dates, params)) {
        continue;
      }

      for (const peopleId of task.people_ids) {
        if (peopleIds.has(peopleId)) {
          const list = result.get(peopleId);

          if (list) {
            list.push(task);
          } else {
            result.set(peopleId, [task]);
          }
        }
      }
    }

    return result;
  },
);
