import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  ProjectPlanViewSource,
  trackProjectPlanView,
} from '@float/web/lib/tracking/trackProjectPlanView';

export const useApplyProjectPlanFilter = () => {
  const history = useHistory();

  const applyProjectPlanFilter = useCallback(
    (projectName: string, ref: ProjectPlanViewSource) => {
      const queryString = `?project=${encodeURIComponent(projectName)}`;
      trackProjectPlanView(ref);
      history.push(`/project-plan${queryString}`);
    },
    [history],
  );

  return applyProjectPlanFilter;
};
