import { OnboardingSurveyOption } from '../../types';

export type GetToKnownYouDataType = Record<
  'teamSize' | 'supportEffort',
  OnboardingSurveyOption[]
>;

export enum SupportEffort {
  Low = 'Low',
  High = 'High',
  No = 'No',
}

export type SupportEffortType = keyof typeof SupportEffort;
