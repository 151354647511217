import { TaskMeta } from '@float/types/task';

export const createEmptyTaskRecord = (task: TaskMeta) => {
  return {
    name: task.task_name,
    phase_id: task.phase_id,
    isBillable: Boolean(task.billable),
    budget: 0,
    scheduled: 0,
    billable: 0,
    nonbillable: 0,
    feeCents: 0,
    costCents: 0,
    logged: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      feeCents: 0,
      costCents: 0,
    },
    combined: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      feeCents: 0,
      costCents: 0,
    },
    future: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      feeCents: 0,
      costCents: 0,
      tentative: {
        billable: 0,
        nonbillable: 0,
      },
    },
    tentative: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
    },
    children: {},
  };
};
