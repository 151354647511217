import React from 'react';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { Tooltip } from '@float/ui/components/Tooltip';

export const ProjectManagerAvatar = ({
  id,
  name,
}: {
  id: number;
  name: string;
}) => {
  return (
    <Tooltip placement="bottom" content={name}>
      <PersonAvatar accountId={id} readOnly size="xs" />
    </Tooltip>
  );
};
