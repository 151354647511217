import React from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash';
import { DEFAULT_TAG_COLOR } from 'selectors/tags';

import { RateText } from '@float/common/components/Rate';
import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import {
  getBillRateLabel,
  isCostRateFeatureActiveForUser,
} from '@float/common/lib/rates/rates';
import { getPersonTypeToLabelMap } from '@float/constants/people';
import { ReadOnlyField } from '@float/ui/deprecated/Field/ReadOnlyField';
import { Row } from '@float/ui/deprecated/Layout/Layout';

import { ReadOnlyManagedBy } from './PersonInfo';

import * as styles from './PersonModal.styles.css';

let self;

export default function renderPersonInfoReadOnly(that) {
  self = that;
  const { form } = self.state;
  const { currentUser } = self.props;

  const canViewBillRate = getCanCurrentUserSeeBudgets(currentUser);
  const canViewCostRate = isCostRateFeatureActiveForUser(currentUser);

  const billRate = form.default_hourly_rate || form.default_hourly_rate_role;
  const costRate = form.cost_rate || form.cost_rate_role;

  const ratesEl = (
    <div className={styles.readOnlyRatesWrapper}>
      {canViewCostRate && (
        <ReadOnlyField
          data-testid="cost-rate-read-only"
          label={t`Cost rate`}
          value={<RateText className={styles.rateText} rate={costRate} />}
          {...self.readOnlyProps}
        />
      )}

      {canViewBillRate && (
        <ReadOnlyField
          data-testid="bill-rate-read-only"
          label={getBillRateLabel()}
          value={<RateText className={styles.rateText} rate={billRate} />}
          {...self.readOnlyProps}
        />
      )}
    </div>
  );

  const hasRole = Boolean(form.job_title);

  return (
    <>
      {hasRole && (
        <Row justifyContent="space-between">
          <ReadOnlyField
            label="Role"
            value={form.job_title}
            {...self.readOnlyProps}
          />
          {ratesEl}
        </Row>
      )}
      {!form.department_id ? null : (
        <ReadOnlyField
          label="Department"
          value={self.props.departments[form.department_id].name}
          {...self.readOnlyProps}
        />
      )}
      {!isEmpty(form.tags) && (
        <ReadOnlyField
          type="tags"
          label="Tags"
          value={form.tags.map((tag) => {
            const { peopleTagByLabel } = self.props;
            const color = peopleTagByLabel[tag.label]?.color
              ? `#${peopleTagByLabel[tag.label].color}`
              : DEFAULT_TAG_COLOR;

            return {
              ...tag,
              color,
            };
          })}
          {...self.readOnlyProps}
        />
      )}

      <Row justifyContent="space-between">
        <ReadOnlyField
          label="Type"
          value={getPersonTypeToLabelMap()[form.people_type_id]}
          {...self.readOnlyProps}
        />
        {hasRole ? null : ratesEl}
      </Row>

      {!isEmpty(form.managers) && (
        <ReadOnlyManagedBy personId={form.people_id} />
      )}
    </>
  );
}
