import { Role } from '@float/types/role';

import { getRoleTableRowWithCostRate } from './getRoleTableRowWithCostRate';
import { getRoleTableRowWithoutCostRate } from './getRoleTableRowWithoutCostRate';
import { getRoleTableRowWithoutRates } from './getRoleTableRowWithoutRates';

type GetRoleTableRowProps = {
  role: Role;
  isRolesRatesFeatureEnabled: boolean;
  isCostRateFeatureActive: boolean;
};

export function getRoleTableRow(props: GetRoleTableRowProps) {
  const { role, isCostRateFeatureActive, isRolesRatesFeatureEnabled } = props;

  const result = {
    entity: role,
    id: role.id,
    isDeletable: true,
    isEdited: false,
  };

  if (!isRolesRatesFeatureEnabled) {
    return { ...result, ...getRoleTableRowWithoutRates(role) };
  }

  if (isCostRateFeatureActive) {
    return { ...result, ...getRoleTableRowWithCostRate(role) };
  }

  return { ...result, ...getRoleTableRowWithoutCostRate(role) };
}
