import { t } from '@lingui/macro';
import {
  amountFormatter,
  avatarFormatter,
  coloredPercentageFormatter,
  filterLinkFormatter,
  hoursFormatter,
  pctFormatter,
  rangeBarFormatter,
  tooltipFormatter,
} from 'reports/helpers/tableFormatters';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';
import type { AccordionTableData } from '@float/ui/deprecated/AccordionTable/types';

import { getLoggedLabel } from '../../../helpers/getLoggedLabel';
import { getScheduledLabel } from '../../../helpers/getScheduledLabel';
import { getPctHeader } from '../PercentageSelector';
import { getCostHeaderLabel } from './getCostHeaderLabel';
import { getMarginHeaderLabel } from './getMarginHeaderLabel';
import type { ProjectsOverviewTableContext } from '../types';

const COLUMN_SPACING_GAP = { label: '', width: 16 };

export function getProjectsTableHeaders(ctx: ProjectsOverviewTableContext) {
  const headers: AccordionTableData['headers'] = [
    {
      label: t`Project`,
      align: 'flex-start',
      width: 230,
      formatter: filterLinkFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: t`Client`,
      align: 'flex-start',
      width: 100,
      formatter: filterLinkFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: t`Owner`,
      align: 'flex-start',
      width: 50,
      allowOverflow: true,
      formatter: avatarFormatter,
    },
    { label: '', grow: 0 },
    {
      label: t`Budget`,
      grow: 1,
      allowOverflow: true,
      formatter: amountFormatter,
    },
    COLUMN_SPACING_GAP,

    {
      label: getScheduledLabel(ctx.mode),
      grow: 1,
      allowOverflow: true,
      formatter: hoursFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: getLoggedLabel(ctx.mode),
      grow: 1,
      allowOverflow: true,
      formatter: hoursFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: '',
      width: 90,
      formatter: pctFormatter,
    },
    {
      label: getPctHeader(ctx),
      width: 120,
      align: 'flex-start',
      // @TODO(PI-91)
      // @ts-expect-error - We need to refactor the types to account for the RangeBar types
      formatter: rangeBarFormatter,
    },
  ];

  if (!ctx.timeTrackingEnabled) {
    // Hide the Logged columns if the user doesn't have time tracking
    headers.splice(9, 2);
  }

  if (ctx.isProjectCodesEnabled) {
    headers.splice(
      2,
      0,
      {
        label: t`Project code`,
        align: 'flex-start',
        width: 100,
        formatter: tooltipFormatter,
      },
      COLUMN_SPACING_GAP,
    );
  }

  const canSeeCosts =
    ctx.hasCostsAccess &&
    ctx.mode !== ChartComparisonModes.COMPARE &&
    featureFlags.isFeatureEnabled(FeatureFlag.CostRate);
  if (canSeeCosts) {
    headers.push({
      label: getCostHeaderLabel(ctx.mode),
      width: 140,
    });
  }

  const canSeeProfitMargin =
    canSeeCosts &&
    ctx.hasBudgetAccess &&
    featureFlags.isFeatureEnabled(FeatureFlag.CostRatesMultiProjectReport);
  if (canSeeProfitMargin) {
    headers.push(
      {
        label: '',
        width: 60,
        align: 'flex-start',
        allowOverflow: true,
      },
      {
        label: getMarginHeaderLabel(ctx.mode),
        allowOverflow: true,
        width: 60,
        isPercent: true,
        formatter: (cell) => coloredPercentageFormatter(cell),
      },
    );
  }

  return headers;
}
