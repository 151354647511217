import React, { memo, useCallback, useState } from 'react';
import { getVisibleSettings } from 'selectors/currentUser';

import { PRO_MONTHLY, PRO_YEARLY } from '@float/common/lib/pricing';
import { useAppSelector } from '@float/common/store';
import { CurrentUser } from '@float/types';
import Button from '@float/ui/deprecated/Button/Button';

import { getIsStarterPlan } from '../Billing/TeamAccountFeaturesNew/helpers/getIsStarterPlan';
import { getUpgradePrice } from '../Billing/TeamAccountFeaturesNew/helpers/getUpgradePrice';
import { TrialModal } from '../Billing/TimeTrackingSection/TrialModal';
import UpgradeModal from '../Billing/TimeTrackingSection/UpgradeModal';
import {
  StyledColumn,
  StyledContainer,
  StyledLabel,
  StyledSection,
  StyledSVGIconContainer,
} from './styles';

import proIcon from '../Billing/TeamAccountFeaturesNew/assets/proIcon.svg';

const StyledProPlanIcon = () => (
  <StyledSVGIconContainer>
    <img src={proIcon} alt="Pro Plan Icon" />
  </StyledSVGIconContainer>
);

export type PlanUpsellBannerProps = {
  header: string;
  subheader: string;
  canModifySubheader?: boolean;
  user: CurrentUser;
  updateBillingPlan: (formData: FormData) => void;
  startProTrial: () => Promise<void>;
  learnMoreUrl: string;
  billingInfo: {
    plan: {
      plan_id?: string;
      is_monthly?: boolean;
    };
  };
};

export const PlanUpsellBanner = memo((props: PlanUpsellBannerProps) => {
  const { user, updateBillingPlan, learnMoreUrl, startProTrial, billingInfo } =
    props;
  const {
    is_paid_plan: isPaidPlan,
    time_tracking: timeTracking,
    plus_pack: plusPack,
    // @ts-expect-error getUser selector has incorrect ReturnType
    is_pro_trial: isProTrial,
    // @ts-expect-error getUser selector has incorrect ReturnType
    pro_trial_end: proTrialEnd,
  } = user;
  const userCanSee = useAppSelector(getVisibleSettings);
  const { plan } = billingInfo;

  const isStarterPlan = getIsStarterPlan(plan.plan_id, {
    isPaidPlan,
    timeTracking,
    plusPack,
    isProTrial,
    proTrialEnd,
  });

  const [modalOpened, setModalOpened] = useState<null | string>(null);

  const upgradePricePerMonth = getUpgradePrice({
    upgradeFrom: 'starter', // We only show this CTA on Starter
    isMonthly: plan.is_monthly,
    isEnterpriseUpgrade: false, // Upgrade to Pro only
  });
  const pricingUnit = 'per person, per month';
  const subheader = `${props.subheader} ${
    !isPaidPlan && props.canModifySubheader ? 'for free' : ''
  }`;

  const updateBillingPlanWithForm = useCallback(async () => {
    const formData = new FormData();
    formData.append('Company[time_tracking]', '1');
    formData.append('Company[plus_pack]', '1');
    if (plan.is_monthly) {
      formData.append('Company[plan_id]', PRO_MONTHLY.plan_id);
    } else {
      formData.append('Company[plan_id]', PRO_YEARLY.plan_id);
    }
    return updateBillingPlan(formData);
  }, [updateBillingPlan, plan.is_monthly]);

  if (!isStarterPlan) {
    return null;
  }

  return (
    <>
      {modalOpened === 'upgrade' && (
        <UpgradeModal
          user={user}
          onSubmit={updateBillingPlanWithForm}
          close={() => {
            setModalOpened(null);
          }}
          upgradeFrom="starter"
          isMonthly={plan.is_monthly}
        />
      )}
      {modalOpened === 'trial' && (
        <TrialModal
          user={user}
          onSubmit={startProTrial}
          close={() => {
            setModalOpened(null);
          }}
        />
      )}
      <StyledSection>
        <StyledContainer>
          <StyledProPlanIcon />

          <StyledColumn>
            <h4>{props.header}</h4>
            <p>{subheader}</p>
            <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          </StyledColumn>
        </StyledContainer>

        <StyledContainer>
          <StyledLabel>
            <p>+{upgradePricePerMonth} USD</p>
            <p>{pricingUnit}</p>
          </StyledLabel>

          <StyledLabel>
            {userCanSee.billing && isPaidPlan && (
              <Button onClick={() => setModalOpened('upgrade')}>Upgrade</Button>
            )}
            {userCanSee.billing && !isPaidPlan && (
              <Button onClick={() => setModalOpened('trial')}>
                Try for free
              </Button>
            )}
            {!userCanSee.billing && (
              <Button disabled>Talk to your account owner</Button>
            )}
          </StyledLabel>
        </StyledContainer>
      </StyledSection>
    </>
  );
});
