import React from 'react';
import { Trans } from '@lingui/macro';
import cx from 'classnames';
import { compact } from 'lodash';
import { modalManagerConnect } from 'modalManager/modalManagerHoc';

import { signOut } from '@float/common/lib/url';
import { useIsSmallMediaSizeActive } from '@float/libs/hooks/media';
import EH from '@float/ui/deprecated/Earhart';
import {
  IconAnnouncement,
  IconBell,
  IconCalendar,
  IconLogout,
  IconUser,
} from '@float/ui/deprecated/Earhart/Icons';

import { MenuSection, MenuSectionRow, MenuTitle, MenuWrapper } from '../styles';

const PersonalMenu = ({ showModal, user, onClick }) => {
  const isSmallBreakpointActive = useIsSmallMediaSizeActive();
  const personalLinks = compact([
    {
      icon: <IconUser />,
      name: 'Profile settings',
      onClick: () => {
        if (onClick) onClick();
        showModal('myInfoModal');
      },
    },
    !isSmallBreakpointActive &&
      user.people_id && {
        icon: <IconCalendar />,
        name: 'Calendar integrations',
        onClick: () => {
          if (onClick) onClick();
          showModal('calendarIntegrationsModal');
        },
      },
    {
      icon: <IconBell />,
      name: 'Notifications',
      onClick: () => {
        if (onClick) onClick();
        showModal('notificationSettingsModal');
      },
    },
    !isSmallBreakpointActive && {
      icon: <IconAnnouncement />,
      name: "What's new",
      onClick: () => {
        if (onClick) onClick();
        window.open('https://www.float.com/whats-new/?utm_medium=nav', {
          target: '_blank',
        });
      },
    },
  ]);

  if (isSmallBreakpointActive) {
    return (
      <MenuWrapper className={cx({ mobile: true })}>
        <MenuTitle>
          <Trans>Personal</Trans>
        </MenuTitle>
        <MenuSection>
          {personalLinks.map((row) => {
            return (
              <MenuSectionRow key={row.name} onClick={row.onClick}>
                {row.name}
              </MenuSectionRow>
            );
          })}
        </MenuSection>
      </MenuWrapper>
    );
  }

  personalLinks.push(EH.List.Separator, {
    icon: <IconLogout />,
    name: 'Sign out',
    onClick: () => {
      if (onClick) onClick();
      signOut();
    },
  });

  return (
    <EH.List
      title="Personal"
      options={personalLinks}
      onClick={({ onClick }) => onClick()}
      minWidth="262px"
    />
  );
};

export default modalManagerConnect(PersonalMenu);
