import { isNil } from 'es-toolkit';

import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';
import {
  formatRate,
  formatRateWithCurrencySymbol,
} from '@float/common/lib/rates/rates';

export function useRateTextParts(rate?: string | null) {
  const currencyProps = useCurrencyProps();

  if (isNil(rate)) return { formattedRate: null, prefix: null, suffix: null };

  const formattedRate = formatRate(rate);

  return { formattedRate, ...currencyProps };
}

export function useRateText(rate?: string | null) {
  const currencyProps = useCurrencyProps();

  if (isNil(rate)) return { formattedRate: null };

  const formattedRate = formatRateWithCurrencySymbol(rate, currencyProps);

  return { formattedRate };
}
