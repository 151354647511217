import { config } from '@float/libs/config';
import { sumOperation } from '@float/libs/utils/floats';
import { CellItem, CellsMap, PersonRowId } from '@float/types/cells';
import { Status } from '@float/types/status';
import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

import { getEntityMeta } from './entityMeta';
import { addSelection } from './helpers/addSelection';
import { addTeamHolidays } from './helpers/addTeamHolidays';
import { getIsShortCellItem } from './helpers/getIsShortCellItem';
import { getMinHeightHours } from './helpers/getMinHeightHours';
import { getNonWorkSubCols } from './helpers/getNonWorkSubCols';
import { sortCellItemsPerTypeAndPriority } from './helpers/sortCellItemsPerTypeAndPriority';
import { sortCellItemsY } from './helpers/sortCellItemsY';
import { CellFns, CellHelpersProps, CellOpts, CellRenderArgs } from './types';

// Using a collator is a bit faster than directly calling .localeCompare
const collator = new Intl.Collator(config.locale);

const normalizeHeight = (hours: number, minItemHeightHours: number) => {
  const result = Math.max(hours, minItemHeightHours);
  return Math.round(result * 4) / 4;
};

const DAY_HOURS = [0, 0, 0, 0, 0, 0, 0];

export function buildPersonCell(
  props: CellHelpersProps,
  cells: CellsMap,
  cellKey: PersonRowId,
  opts: CellOpts,
  fns: CellFns, // Move these functions in a module
) {
  const {
    dates,
    maps,
    rowMetas,
    bimaps,
    leftHiddenDays,
    rightHiddenDays,
    hourHeight,
    singleUserView,
    suvSingleDay,
    excludeNonWorkDaysGaps,
    excludeShortAllocationsDimensionsCalculation,
    excludeTimeoffsDimensionsCalculation,
  } = props;

  const {
    task: tasksMap,
    timeoff: timeoffsMap,
    oneOff: oneOffsMap,
    status: statusesMap,
  } = maps;

  const {
    task: tasksBimap,
    timeoff: timeoffsBimap,
    oneOff: oneOffsBimap,
    status: statusesBimap,
  } = bimaps;

  const {
    getHorizontalDimensions,
    calcEntityLength,
    getAllInstances,
    getPriority,
    overlaps,
    shouldComputeTaskLength,
  } = fns;

  const cell = cells[cellKey];
  const { colIdx, rowId } = cell;
  const rowMeta = rowMetas.get(rowId)!;

  const minItemHeightHours = getMinHeightHours({
    hourHeight,
    singleUserView,
    suvSingleDay,
  });
  const firstCellDay = colIdx * 7 + leftHiddenDays;
  const lastCellDay = (colIdx + 1) * 7 - 1 - rightHiddenDays;

  cell.dayHours = DAY_HOURS.slice(0, 7 - leftHiddenDays - rightHiddenDays);

  oneOffsBimap.getRev(cellKey).forEach((id) => {
    const o = oneOffsMap[id];
    const { x, w } = getHorizontalDimensions(o, firstCellDay, lastCellDay);

    const cellItem: CellItem<'oneOff'> = {
      cellKey,
      key: `${cellKey}:oneOff:${o.oneoff_id}`,
      type: 'oneOff',
      entity: o,
      entityId: o.oneoff_id,
      x,
      w,
    };

    if (w > 0) cell.items.push(cellItem);
  });

  const cellRenderArgs: CellRenderArgs = {
    cell,
    cellKey,
    cells,
    firstCellDay,
    fns,
    lastCellDay,
    props,
  };

  addTeamHolidays(cellRenderArgs);

  const statuses = statusesBimap.getRev(cellKey).map((id) => statusesMap[id]);
  statuses.forEach((status) => {
    const statusInstances = getAllInstances(status) as Status[];

    statusInstances.forEach((h: Status, instanceCount: number) => {
      const { x, w } = getHorizontalDimensions(h, firstCellDay, lastCellDay);
      const cellItem: CellItem<'status'> = {
        cellKey,
        key: `${cellKey}:status:${h.status_id}`,
        type: 'status',
        entity: h,
        entityId: h.status_id,
        w: 1,
      };

      const nonWorks = getNonWorkSubCols(
        rowId,
        firstCellDay,
        x,
        w,
        dates,
        cells,
        fns.isWorkDay,
      );

      for (
        let i = Math.max(firstCellDay, dates.toNum(h.start_date));
        i <= Math.min(lastCellDay, dates.toNum(h.end_date));
        i++
      ) {
        const curX = i - firstCellDay;
        if (!nonWorks.includes(curX)) {
          cell.items.push({
            ...cellItem,
            key: `${cellKey}:status:${h.status_id}:${curX}:${instanceCount}`,
            x: curX,
          });
        }
      }
    });
  });

  const timeoffs = timeoffsBimap.getRev(cellKey).map((id) => timeoffsMap[id]);
  timeoffs.forEach((timeoff) => {
    // @entity.length
    if (!timeoff.length) {
      // @entity.length
      timeoff.length = calcEntityLength(cells, rowId, timeoff);
    }

    const timeoffInstances = getAllInstances(timeoff) as Timeoff[];

    timeoffInstances.forEach((t: Timeoff, instanceCount: number) => {
      const { x, w } = getHorizontalDimensions(t, firstCellDay, lastCellDay);

      // Timeoffs with region_holiday_id are treated as holidays, not timeoffs
      if (t.region_holiday_id) {
        const cellItem: CellItem<'timeoff'> = {
          cellKey,
          rowId,
          key: `${cellKey}:timeoff:${t.timeoff_id}:${instanceCount}`,
          isPlaceholder: getEntityMeta(t, 'isPlaceholder'),
          type: 'timeoff',
          entity: t,
          entityId: t.timeoff_id,
          x,
          w,
        };

        if (w > 0) cell.items.push(cellItem);
        return;
      }
      // @entity.length
      t.length = timeoff.length;

      const nonWorks = getNonWorkSubCols(
        rowId,
        firstCellDay,
        x,
        w,
        dates,
        cells,
        fns.isWorkDay,
        timeoff,
      );

      let curX = x;
      let curW = 0;
      let part = 0;

      // Tasks get split over non-work days, holidays, timeoffs, etc, which
      // means that any given task might be associated with more than one
      // cell item.
      do {
        while (nonWorks.includes(curX) && curX < x + w) {
          curX++;
        }

        while (curX + curW < x + w && !nonWorks.includes(curX + curW)) {
          curW++;
        }

        if (curW > 0) {
          const cellItem: CellItem<'timeoff'> = {
            cellKey,
            rowId,
            key: `${cellKey}:timeoff:${t.timeoff_id}:${curX}:${instanceCount}`,
            isPlaceholder: getEntityMeta(t, 'isPlaceholder'),
            type: 'timeoff',
            entity: t,
            entityId: t.timeoff_id,
            isStart: part === 0 && dates.toNum(t.start_date) >= firstCellDay,
            isEnd:
              dates.toNum(t.end_date) <= lastCellDay &&
              !nonWorks.includes(curX + curW),
            x: curX,
            w: curW,
          };

          // Part day or Tentative
          if (t.status === 1) {
            const firstCellDate = dates.fromNum(firstCellDay);

            const hours =
              t.hours ?? rowMeta.getDailyWorkHours(firstCellDate)[curX];
            cellItem.h = excludeTimeoffsDimensionsCalculation
              ? 0
              : normalizeHeight(hours, minItemHeightHours);

            for (let i = curX; i < curX + curW; i++) {
              cell.dayHours[i] = sumOperation(
                cell.dayHours[i],
                hours ?? rowMeta.getDailyWorkHours(firstCellDate)[i],
              );
            }
          } else if (!t.full_day) {
            cellItem.h = excludeTimeoffsDimensionsCalculation
              ? 0
              : normalizeHeight(t.hours!, minItemHeightHours);

            for (let i = curX; i < curX + curW; i++) {
              cell.dayHours[i] = sumOperation(cell.dayHours[i], t.hours!);
            }
          }

          cell.items.push(cellItem);
        }

        curX = curX + curW + 1;
        curW = 0;
        part++;
      } while (curX < 7 - leftHiddenDays - rightHiddenDays);
    });
  });

  const tasks = tasksBimap.getRev(cellKey).map((id) => tasksMap[id]);
  tasks.forEach((task) => {
    // sometimes task is undefined
    // https://rollbar.com/float/fe-web-app/items/2204/
    if (!task) return;

    // @entity.length
    if (!task.length && shouldComputeTaskLength(cellKey, task, opts)) {
      task.length = calcEntityLength(cells, rowId, task);
    }
    const taskInstances = getAllInstances(task) as Task[];

    taskInstances.forEach((t: Task, instanceCount: number) => {
      const { x, w } = getHorizontalDimensions(t, firstCellDay, lastCellDay);
      // @entity.length
      t.length = task.length;

      const nonWorks = excludeNonWorkDaysGaps
        ? []
        : getNonWorkSubCols(
            rowId,
            firstCellDay,
            x,
            w,
            dates,
            cells,
            fns.isWorkDay,
          );

      let curX = x;
      let curW = 0;
      let part = 0;

      // Tasks get split over non-work days, holidays, timeoffs, etc, which
      // means that any given task might be associated with more than one
      // cell item.
      do {
        while (nonWorks.includes(curX) && curX < x + w) {
          curX++;
        }

        while (curX + curW < x + w && !nonWorks.includes(curX + curW)) {
          curW++;
        }

        if (curW > 0) {
          const cellItem: CellItem<'task'> = {
            cellKey,
            rowId,
            key: `${cellKey}:task:${t.task_id}:${curX}:${instanceCount}`,
            isPlaceholder: getEntityMeta(t, 'isPlaceholder'),
            type: 'task',
            entity: t,
            entityId: t.task_id,
            isStart: part === 0 && dates.toNum(t.start_date) >= firstCellDay,
            isEnd:
              dates.toNum(t.end_date) <= lastCellDay &&
              !nonWorks.includes(curX + curW),
            x: curX,
            w: curW,
            h: 0,
          };

          const isShortItem = getIsShortCellItem(cellItem);

          cellItem.h =
            excludeShortAllocationsDimensionsCalculation && isShortItem
              ? 0
              : normalizeHeight(t.hours, minItemHeightHours);

          for (let i = curX; i < curX + curW; i++) {
            cell.dayHours[i] = sumOperation(cell.dayHours[i], t.hours);
          }

          cell.items.push(cellItem);
        }

        curX = curX + curW + 1;
        curW = 0;
        part++;
      } while (curX < 7 - leftHiddenDays - rightHiddenDays);
    });
  });

  addSelection(cellRenderArgs, excludeNonWorkDaysGaps);

  // First, sort the cell items according to their type and priority
  sortCellItemsPerTypeAndPriority(
    'person',
    cell,
    opts,
    getPriority,
    collator,
    dates,
  );

  // Then, set the appropriate y values for the cell items
  sortCellItemsY(cell, cells, overlaps);

  // Providing a stable-sorted version of cell.items results in smoother
  // animations since the DOM element positions will be unchanged.
  cell.items.sort((a, b) => collator.compare(a.key, b.key));

  return cell;
}
