import React from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';

import { formatAmount } from '@float/common/lib/budget';
import { ProjectBudgetType } from '@float/constants/projects';
import { Tooltip, TooltipVariants } from '@float/ui/components/Tooltip';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { IconWarningTriangle } from '@float/ui/deprecated/Earhart/Icons';
import type { ProjectBudgetUsage } from '@float/types/project';

import { ItemHeader, ItemLabel, ReportsTotalsItem } from '../../../styles';
import type { ProjectSummaryInfo, TotalsData } from '../types';

import * as styles from './SummaryItemCost.css';

export enum SummaryItemCostCalculationType {
  LOGGED = 'logged',
  SCHEDULED = 'scheduled',
  COMBINED = 'combined',
}

export type SummaryItemTotalBudgetProps = {
  project: ProjectSummaryInfo;
  projectBudget: ProjectBudgetUsage;
  totals: TotalsData;
  costCalculationType: SummaryItemCostCalculationType;
  hasPeopleWithMissingCostRates: boolean;
};

export const SummaryItemCost = ({
  project,
  projectBudget,
  totals,
  costCalculationType,
  hasPeopleWithMissingCostRates,
}: SummaryItemTotalBudgetProps) => {
  let costLabel;
  let costValue = 0;

  if (costCalculationType === SummaryItemCostCalculationType.LOGGED) {
    costLabel = t`Logged costs`;
    costValue = totals.logged.costCents / 100;
  } else if (costCalculationType === SummaryItemCostCalculationType.SCHEDULED) {
    costLabel = t`Scheduled costs`;
    costValue = totals.costCents / 100;
  } else if (costCalculationType === SummaryItemCostCalculationType.COMBINED) {
    costLabel = t`Projected costs`;
    costValue = totals.combined.costCents / 100;
  }

  const costValueFormatted = formatAmount(
    ProjectBudgetType.TotalFee,
    costValue,
  );

  const editRateLink = `/people${location.search}`;

  return (
    <ReportsTotalsItem>
      <ItemLabel>
        <div className={styles.labelWrapper}>
          {costLabel}

          {hasPeopleWithMissingCostRates && (
            <Tooltip
              content={
                <div className={styles.tooltipContentWrapper}>
                  <Trans>
                    Cost rates are missing for some People on this Project
                  </Trans>
                </div>
              }
              variant={TooltipVariants.error}
            >
              <span
                className={styles.infoIconWrapper}
                aria-label={t`Project people missing cost rates warning icon`}
              >
                <IconWarningTriangle size={20} />
              </span>
            </Tooltip>
          )}
        </div>
      </ItemLabel>

      <ItemHeader>
        {!hasPeopleWithMissingCostRates && costValueFormatted}

        {hasPeopleWithMissingCostRates && (
          <TextButton
            appearance="flue-fill"
            className={styles.editRatesButton}
            as={Link}
            to={editRateLink}
          >
            <Trans>Edit cost rates</Trans>
          </TextButton>
        )}
      </ItemHeader>
    </ReportsTotalsItem>
  );
};
