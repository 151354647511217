import React, { useMemo } from 'react';

import { appendFilter } from '@float/common/lib/url';
import { Tag } from '@float/ui/deprecated/Table/Tag';

type Props = {
  name: string;
  count: number;
};

export function RoleTablePeopleCount(props: Props) {
  const { name, count } = props;

  const tag = useMemo(
    () => ({
      label: count || '0',
      to:
        count > 0
          ? appendFilter({ jobTitle: name }, { pathname: '/people' })
          : undefined,
    }),
    [count, name],
  );

  return <Tag appearance="dynamic" color="flay" tag={tag} />;
}
