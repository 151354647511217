import { useMemo, useRef, useState } from 'react';
import { isEqual, throttle } from 'lodash';

import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { SIDE_CELL_WIDTH } from '@float/constants/schedule';
import { formatToFloatDate } from '@float/libs/dates';
import { useEventListenerOnElement } from '@float/libs/hooks/useEventListenerOnElement';

export type UseVisibleDateRangeOnScheduleProps = {
  today?: Date;
  leftStickyBoundary?: number;
  disabled?: boolean;
  throttleInterval?: number;
  margin?: number;
};

type GetVisibleDateProps = {
  dates: DatesManager;
  numDays: number;
  rootMargin: number;
  weekWidth: number;
};

type GetFirstVisibleDateProps = GetVisibleDateProps & {
  baseColOffset: number;
  scrollLeft: number;
};

type GetLastVisibleDateProps = GetVisibleDateProps & {
  firstWeekIndex: number;
  scheduleWidth: number;
};

// TODO: Revisit for a cleaner solution - https://linear.app/float-com/issue/SCN-812/follow-up-dates-overlap
// This function deduces the offset required for calculating
// first and last visible dates with a margin applied, similar
// to IntersectionObserver's rootMargin
function getRootMargin(margin: number, dayWidth: number) {
  if (!margin) return 0;

  const daysRequiredToFitMargin = Math.round(margin / dayWidth);

  if (daysRequiredToFitMargin > 1) {
    // desired margin requires more than a day's width to fit
    return dayWidth * (daysRequiredToFitMargin + 0.5);
  }

  const rootMargin = dayWidth / 2 - 10;

  if (daysRequiredToFitMargin === 1) {
    // desired margin requires more than half a day's width to fit
    return rootMargin;
  }

  // desired margin fits in less than half a day's width
  return rootMargin * -1;
}

function getDefaultDateRange(today: Date) {
  const defaultDate = formatToFloatDate(today);
  return {
    firstVisibleDate: defaultDate,
    lastVisibleDate: defaultDate,
    firstVisibleDateWithMargin: defaultDate,
    lastVisibleDateWithMargin: defaultDate,
  };
}

function getFirstVisibleDate(props: GetFirstVisibleDateProps) {
  const { dates } = props;

  const getDateAndIndex = (offset: number) => {
    const weekIndex = props.baseColOffset + offset / props.weekWidth;
    const dayOfWeekIndex = Math.floor((weekIndex % 1) * props.numDays);
    const date = dates.fromDescriptor(Math.floor(weekIndex), dayOfWeekIndex);
    return { visibleDate: date, weekIndex };
  };

  const result = getDateAndIndex(props.scrollLeft);
  const resultWithMargin = getDateAndIndex(props.scrollLeft + props.rootMargin);

  return {
    firstVisibleDate: result.visibleDate,
    firstVisibleDateWithMargin: resultWithMargin.visibleDate,
    firstWeekIndex: result.weekIndex,
  };
}

function getLastVisibleDate(props: GetLastVisibleDateProps) {
  const { dates, rootMargin, scheduleWidth } = props;

  const getDateAndIndex = (width: number) => {
    const weekIndex = props.firstWeekIndex + width / props.weekWidth;
    const dayOfWeekIndex = Math.floor((weekIndex % 1) * props.numDays);
    return dates.fromDescriptor(Math.floor(weekIndex), dayOfWeekIndex);
  };

  return {
    lastVisibleDate: getDateAndIndex(scheduleWidth),
    lastVisibleDateWithMargin: getDateAndIndex(scheduleWidth - rootMargin),
  };
}

export const useVisibleDateRangeOnSchedule = ({
  today = new Date(),
  leftStickyBoundary = SIDE_CELL_WIDTH,
  disabled = false,
  throttleInterval = 100,
  margin = 0,
}: UseVisibleDateRangeOnScheduleProps = {}) => {
  const [dateRange, setDateRange] = useState(() => getDefaultDateRange(today));
  const { baseColOffset, dates, dayWidth, numDays, scrollWrapperRef } =
    useScheduleContext();

  const rootMargin = getRootMargin(margin, dayWidth);
  const weekWidth = numDays * dayWidth;

  const handleScroll = () => {
    const wrapper = scrollWrapperRef?.current;
    if (!wrapper) return;

    const scrollLeft = wrapper.scrollLeft ?? 0;
    const wrapperWidth = wrapper.clientWidth ?? 0;
    const scheduleWidth = wrapperWidth - leftStickyBoundary;

    const { firstVisibleDate, firstVisibleDateWithMargin, firstWeekIndex } =
      getFirstVisibleDate({
        baseColOffset,
        dates,
        numDays,
        rootMargin,
        scrollLeft,
        weekWidth,
      });
    const { lastVisibleDate, lastVisibleDateWithMargin } = getLastVisibleDate({
      dates,
      firstWeekIndex,
      numDays,
      rootMargin,
      scheduleWidth,
      weekWidth,
    });

    const newState = { ...dateRange };
    // `fromDescriptor` can return undefined, even though it's not currently
    // reflected in the type signature
    if (firstVisibleDate) {
      newState.firstVisibleDate = firstVisibleDate;
    }
    if (lastVisibleDate) {
      newState.lastVisibleDate = lastVisibleDate;
    }
    if (firstVisibleDateWithMargin) {
      newState.firstVisibleDateWithMargin = firstVisibleDateWithMargin;
    }
    if (lastVisibleDateWithMargin) {
      newState.lastVisibleDateWithMargin = lastVisibleDateWithMargin;
    }
    if (!isEqual(dateRange, newState)) {
      setDateRange(newState);
    }
  };

  const handler = useRef(handleScroll);
  handler.current = handleScroll; // Updates the function ref to the current one

  const throttledScrollHandler = useMemo(
    () =>
      // the throttle gets recreated only when the interval changes
      throttle(() => handler.current(), throttleInterval, { trailing: true }),
    [throttleInterval],
  );

  useEventListenerOnElement(
    scrollWrapperRef,
    'scroll',
    throttledScrollHandler,
    { passive: true },
    { disabled },
  );

  return dateRange;
};
