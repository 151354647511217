import { t } from '@lingui/macro';
import { compact, intersection, uniq } from 'lodash';

import { BitsHandler } from '@float/common/lib/acl';
import { Person } from '@float/types/person';

import { AccountWithAccess } from '../../PersonModal/access/PersonAccess.types';
import Access from '../Access';

export function getBulkEditAccessField() {
  return {
    label: t`Access`,
    render: Access,
    getPayloadFromState: (data: Partial<AccountWithAccess>) => {
      if (!data) {
        return {};
      }

      const payload = {
        access: data.access,
        account_type_id: data.account_type,
        department_filter: data.department_filter_set
          ? data.department_filter
          : [],
      };

      return payload;
    },
    getDefaultValue: (entity: Person) => {
      const account = entity.account;
      const department_filter = account?.department_filter || [];

      return {
        account_type: account?.account_type || 4,
        access: account?.access || 0,
        department_filter,
        force_department_filter: compact([entity.department_id]),
        department_filter_set: department_filter.length > 0,
      };
    },
    handleMismatch: {
      account_type: (
        initialEntity: Person,
        currentEntity: Person,
        initialValue: Partial<AccountWithAccess>,
      ) => {
        const order = [1, 2, 7, 4];
        const leftTypeIdx = order.indexOf(
          initialEntity.account?.account_type || 4,
        );
        const rightTypeIdx = order.indexOf(
          currentEntity.account?.account_type || 4,
        );

        if (leftTypeIdx > rightTypeIdx) {
          initialValue.account_type = order[leftTypeIdx];
        } else {
          initialValue.account_type = order[rightTypeIdx];
        }
      },
      department_filter: (
        initialEntity: Person,
        currentEntity: Person,
        initialValue: Partial<AccountWithAccess>,
      ) => {
        const departmentFilter = uniq(
          compact([
            ...(initialValue.department_filter || []),
            currentEntity.department_id,
            initialEntity.department_id,
            ...intersection(
              initialEntity.account?.department_filter,
              currentEntity.account?.department_filter,
            ),
          ]),
        );

        initialValue.department_filter = departmentFilter;
      },
      force_department_filter: (
        initialEntity: Person,
        currentEntity: Person,
        initialValue: Partial<AccountWithAccess> & {
          force_department_filter: number[];
        },
      ) => {
        initialValue.force_department_filter = uniq(
          compact([
            ...initialValue.force_department_filter,
            currentEntity.department_id,
            initialEntity.department_id,
          ]),
        );
      },
      access: (
        initialEntity: Person,
        currentEntity: Person,
        initialValue: Partial<AccountWithAccess>,
      ) => {
        const initialAccess = initialEntity.account?.access || 0;
        const currentAccess = currentEntity.account?.access || 0;
        const commonBits = BitsHandler.getCommonBitsHandler(
          initialValue.access || 0,
          initialAccess,
          currentAccess,
        );
        initialValue.access = commonBits.toNumber();
      },
    },
  };
}
