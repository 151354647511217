import { t } from '@lingui/macro';
import { isNil, map, some } from 'lodash';

import { getDoesBudgetUseFees } from '@float/common/lib/acl/getDoesBudgetUseFees';
import { formatAmount, toCents } from '@float/common/lib/budget';
import { ProjectBudgetType } from '@float/constants/projects';
import type { AccordionTableData } from '@float/ui/deprecated/AccordionTable/types';

import {
  getBudgetDataObject,
  getFeeOrHoursAsPercentageOfBudget,
} from './helpers/budget';
import { getClientsTableHeaders } from './helpers/getClientsTableHeaders';
import { getRangeBar } from './helpers/getRangeBar';
import { aggregateByProject } from './projects';
import type {
  ProjectsOverviewTableContext,
  ProjectsOverviewTableDataRaw,
  ReportsProject,
  SimplifiedReportsProject,
} from './types';

function breakdownByClient(
  ctx: ProjectsOverviewTableContext,
  raw: ProjectsOverviewTableDataRaw | null,
) {
  const { projects } = ctx;

  if (!raw) return {};

  const byProject = aggregateByProject(ctx, raw);
  const byClient: Record<string, SimplifiedReportsProject> = {};

  map(byProject, (proj: ReportsProject) => {
    const { client_name, non_billable } = projects[proj.id];
    const billableProject = !non_billable;

    if (!byClient[client_name]) {
      byClient[client_name] = {
        budget: 0,
        budget_type: null,
        scheduled: { fee: 0, hours: 0, billableHours: 0, billableFee: 0 },
        logged: { fee: 0, hours: 0, billableHours: 0, billableFee: 0 },
        children: {},
        fee: 0,
        cost: {
          scheduled: 0,
          logged: 0,
          future: 0,
        },
      };
    }

    const record = byClient[client_name];
    record.children[proj.id] = proj;

    if (record.budget_type === null) {
      record.budget_type = proj.budget_type;
    }

    record.scheduled.hours += billableProject
      ? proj.scheduled.billableHours
      : proj.scheduled.hours;
    if (ctx.timeTrackingEnabled) {
      record.logged.hours += billableProject
        ? proj.logged.billableHours
        : proj.logged.hours;
    }

    if (record.budget_type !== proj.budget_type) {
      // We can't aggregate budgets if they're not all of the same type.
      // We'll use Omitted to signify this.
      record.budget_type = ProjectBudgetType.Omitted;
    } else {
      record.budget = (toCents(record.budget) + toCents(proj.budget)) / 100;

      const fee = billableProject
        ? proj.scheduled.billableFee
        : proj.scheduled.fee;
      record.scheduled.fee =
        (toCents(record.scheduled.fee) + toCents(fee)) / 100;

      if (ctx.timeTrackingEnabled) {
        const loggedFee = billableProject
          ? proj.logged.billableFee
          : proj.logged.fee;
        record.logged.fee =
          (toCents(record.logged.fee) + toCents(loggedFee)) / 100;
      }
    }
  });

  return byClient;
}

export function getClientsTable(
  ctx: ProjectsOverviewTableContext,
  rawData: ProjectsOverviewTableDataRaw | null,
): AccordionTableData {
  const { projects } = ctx;

  const headers = getClientsTableHeaders(ctx);

  const byClient = breakdownByClient(ctx, rawData);

  const hasFeeColumns = some(byClient, (o) =>
    getDoesBudgetUseFees(o.budget_type),
  );

  const rows = map(byClient, (o, clientName) => {
    const { budget_type } = o;

    const clientLink =
      clientName === 'No Client' ? t`No Client` : `client::${clientName}`;

    const clientBudget = getBudgetDataObject(o);
    const clinetFee =
      hasFeeColumns &&
      !isNil(budget_type) &&
      budget_type !== ProjectBudgetType.Omitted
        ? formatAmount(o.budget_type, o.scheduled.fee)
        : '';
    const clientScheduledHours = o.scheduled.hours;
    const clientBudgetUsagePercent =
      budget_type === ProjectBudgetType.Omitted
        ? ''
        : getFeeOrHoursAsPercentageOfBudget(o, {
            percentageMode: ctx.projectsPercentageMode,
            useBillable: false,
            useFees: hasFeeColumns,
          });

    const clientBudgetUsageBar =
      budget_type === ProjectBudgetType.Omitted
        ? ''
        : getRangeBar(ctx.projectsPercentageMode, o, false);

    const data = [
      clientLink,
      clientBudget,
      clinetFee,
      clientScheduledHours,
      // We've already aggregated the client data based on whether the project
      // was billable or non-billable above, so we pass in false to use those
      // values instead of an explicit billable key.
      clientBudgetUsagePercent,
      clientBudgetUsageBar,
    ];

    if (ctx.timeTrackingEnabled) {
      const clientLoggedFee =
        hasFeeColumns &&
        !isNil(budget_type) &&
        budget_type !== ProjectBudgetType.Omitted
          ? formatAmount(o.budget_type, o.logged.fee)
          : '';

      const clientLoggedHours = o.logged.hours;

      // Add the logged columns if the user has time tracking
      data.splice(4, 0, clientLoggedFee, clientLoggedHours);
    }

    const children = map(o.children, (c, _projectId) => {
      const projectId = Number(_projectId);
      const isBillableProject = !projects[projectId].non_billable;

      const childData = [
        `project-report-link::${c.name}`,
        getBudgetDataObject(c),
        hasFeeColumns
          ? formatAmount(
              c.budget_type,
              isBillableProject ? c.scheduled.billableFee : c.scheduled.fee,
            )
          : '',
        isBillableProject ? c.scheduled.billableHours : c.scheduled.hours,
        getFeeOrHoursAsPercentageOfBudget(c, {
          percentageMode: ctx.projectsPercentageMode,
          useBillable: false,
          useFees: getDoesBudgetUseFees(c.budget_type),
        }),
        getRangeBar(ctx.projectsPercentageMode, c, isBillableProject),
      ];

      if (ctx.timeTrackingEnabled) {
        // Add the logged columns if the user has time tracking
        childData.splice(
          4,
          0,
          hasFeeColumns ? formatAmount(c.budget_type, c.logged.fee) : '',
          c.logged.hours,
        );
      }

      return { data: childData };
    });

    return { id: clientName, data, children };
  });

  // @TODO(PI-91)
  // @ts-expect-error - We need to refactor the types to account for the RangeBar types
  return { headers, rows };
}
