import type { MediaClientAdapter } from '@float/libs/media';

const getScreenHeightCSSPropertyUpdater =
  (documentInstance: Document = document) =>
  () => {
    const { documentElement } = documentInstance;

    documentElement.style.setProperty(
      '--screen-height',
      `${window.innerHeight}px`,
    );
  };

export class WebMediaClientAdapter implements MediaClientAdapter {
  private allWindowResizeHandlers: Array<() => void> = [];

  private lastWindowSize: { width: number; height: number };

  private window: {
    innerHeight: number;
    innerWidth: number;
    screen: {
      height: number;
      width: number;
    };
    getComputedStyle: (element: Element) => CSSStyleDeclaration;
    addEventListener: (
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | AddEventListenerOptions,
    ) => void;
  };

  // Updates the "--screen-height" CSS property on resize, which is
  // consumed by some styled-components.
  // We can remove this when those components are replaced.
  private initializeWindowResizeHandlers(documentInstance: Document) {
    const updateScreenHeightCSSProperty =
      getScreenHeightCSSPropertyUpdater(documentInstance);
    updateScreenHeightCSSProperty();

    this.allWindowResizeHandlers.push(updateScreenHeightCSSProperty);
  }

  constructor(windowInstance = window, documentInstance = document) {
    this.window = windowInstance;

    this.lastWindowSize = {
      width: this.window.innerWidth,
      height: this.window.innerHeight,
    };

    this.initializeWindowResizeHandlers(documentInstance);

    const onWindowResize = () => {
      const nextWindowDimensions = {
        width: this.window.innerWidth,
        height: this.window.innerHeight,
      };

      if (
        nextWindowDimensions.width === this.lastWindowSize.width &&
        nextWindowDimensions.height === this.lastWindowSize.height
      ) {
        // dont't run the handlers if there's no change
        return;
      }

      this.lastWindowSize = nextWindowDimensions;

      this.allWindowResizeHandlers.map((handler) => handler());
    };

    this.window.addEventListener('resize', onWindowResize, { passive: true });
  }

  public get windowDimensions() {
    return {
      width: this.window.innerWidth,
      height: this.window.innerHeight,
    };
  }

  public get screenDimensions() {
    return {
      width: this.window.screen.width,
      height: this.window.screen.height,
    };
  }

  public addWindowResizeListener(handler: () => void) {
    this.allWindowResizeHandlers.push(handler);
  }

  public removeWindowResizeListener(handler: () => void) {
    this.allWindowResizeHandlers.splice(
      this.allWindowResizeHandlers.indexOf(handler) >>> 0,
      1,
    );
  }
}
