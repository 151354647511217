import type {
  EntityMeta,
  SearchQueryApiResult,
} from '@float/common/api3/search.types';

import { normalize } from '../../helpers';
import { REMOTE_ENTITY_TYPE_KEY_TO_FILTER_TYPE_KEY } from '../constants';
import type { SearchQueryItem } from '../queryApi.types';

type FormatQueryApiResultItemOptions = {
  projectCodesEnabled: boolean;
};

function isItemMarkedAsActiveInMeta(entityMetaValues: EntityMeta[]) {
  return entityMetaValues.some(({ isActive }) => isActive === 1);
}

function getFormattedBaseItem(item: SearchQueryApiResult) {
  return {
    ids: item.ids,
    val: item.name,
    // `normalizedVal` is used in `sortFilteredCandidates`
    normalizedVal: normalize(item.name),
  };
}

export function ungroupProject(item: SearchQueryApiResult) {
  const type = REMOTE_ENTITY_TYPE_KEY_TO_FILTER_TYPE_KEY[item.type];

  if (type !== 'project') {
    throw new Error(
      `ungroupProject can only be used with project types, received: ${type}`,
    );
  }

  const baseItem = getFormattedBaseItem(item);

  const withCode: SearchQueryItem[] = [];
  const projectIdsWithoutCode: number[] = [];

  for (const id of item.ids) {
    const meta = item.meta?.[id];
    const isActive = meta?.isActive === 1;
    const code = meta?.code ?? null;

    if (code) {
      withCode.push({
        ...baseItem,
        type,
        ids: [id],
        isActive,
        code,
      });
    } else {
      projectIdsWithoutCode.push(id);
    }
  }

  const result = withCode;

  if (projectIdsWithoutCode.length) {
    const isAnyActive = projectIdsWithoutCode.some((id) => {
      const meta = item.meta?.[id];

      return meta?.isActive === 1;
    });

    result.unshift({
      ...baseItem,
      type,
      ids: projectIdsWithoutCode,
      isActive: isAnyActive,
      code: null,
    });
  }

  return result;
}

export function formatQueryApiResultItem(
  item: SearchQueryApiResult,
  { projectCodesEnabled }: FormatQueryApiResultItemOptions,
): SearchQueryItem | SearchQueryItem[] {
  const base = getFormattedBaseItem(item);
  const type = REMOTE_ENTITY_TYPE_KEY_TO_FILTER_TYPE_KEY[item.type];

  const entityMetaValues = Object.values(item.meta ?? {}).filter(
    (meta) => meta !== undefined,
  );
  const firstEntityMetaValue = entityMetaValues.at(0);
  const [firstId] = item.ids;

  switch (type) {
    case 'person':
    case 'timeoff':
      return {
        ...base,
        type,
        isActive: isItemMarkedAsActiveInMeta(entityMetaValues),
      };

    case 'project':
      return projectCodesEnabled
        ? ungroupProject(item)
        : {
            ...base,
            type,
            ids: item.ids,
            isActive: isItemMarkedAsActiveInMeta(entityMetaValues),
            code: null,
          };

    case 'department':
      return {
        ...base,
        type,
        id: firstId,
        parent_id: firstEntityMetaValue?.parent_department_id ?? null,
      };

    default:
      return {
        ...base,
        type,
      };
  }
}
