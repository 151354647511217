import React, { ReactNode, useCallback, useState } from 'react';
import { createContext } from 'use-context-selector';

import { useSafeContext } from '@float/libs/hooks/useSafeContext';
import { darkThemeClass, lightThemeClass } from '@float/theme';

export type ColorThemeContextType = {
  activeColorTheme: ColorTheme;
  activeColorThemeClass: string;
  setActiveColorTheme: (theme: ColorTheme) => void;
  toggleActiveColorTheme: () => void;
};

export const ColorThemeContext = createContext<ColorThemeContextType | null>(
  null,
);

export enum ColorTheme {
  Light = 'Light',
  Dark = 'Dark',
}

export const THEME_CLASS_MAP: Record<ColorTheme, string> = {
  [ColorTheme.Light]: lightThemeClass,
  [ColorTheme.Dark]: darkThemeClass,
};

export const ColorThemeContextProvider: React.FC<{
  children?: ReactNode;
  overrides?: Partial<ColorThemeContextType>;
}> = ({ children, overrides }) => {
  const [activeColorTheme, setActiveColorTheme] = useState<ColorTheme>(
    overrides?.activeColorTheme ?? ColorTheme.Light,
  );

  const activeColorThemeClass = THEME_CLASS_MAP[activeColorTheme];

  const toggleActiveColorTheme = useCallback(() => {
    if (activeColorTheme === ColorTheme.Light) {
      setActiveColorTheme(ColorTheme.Dark);
    } else {
      setActiveColorTheme(ColorTheme.Light);
    }
  }, [activeColorTheme]);

  return (
    <ColorThemeContext.Provider
      value={{
        activeColorTheme,
        setActiveColorTheme,
        activeColorThemeClass,
        toggleActiveColorTheme,
        ...overrides,
      }}
    >
      {children}
    </ColorThemeContext.Provider>
  );
};

export const useColorThemeContext = () =>
  useSafeContext(ColorThemeContext, 'ColorThemeContext');
