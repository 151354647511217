import { t } from '@lingui/macro';
import {
  amountFormatter,
  feeAndHoursFromatter,
} from 'reports/helpers/tableFormatters';
import { getBudgetDataObject } from 'reports/ProjectsOverview/parser/table/helpers/budget';

import { formatAmount } from '@float/common/lib/budget';
import {
  ProjectBudgetPriority,
  ProjectBudgetType,
} from '@float/constants/projects';
import { getIsMoneyBudgetType } from '@float/web/reports/helpers/getIsMoneyBudgetType';
import type { RawTableData } from '@float/web/reports/useReportsStateReducer';

import { getTotalsByPhase } from './getTotalsByPhase';
import type { ParseTableContext, TableDataRowItem } from '../../table.types';

export function getPhasesCombinedTable(
  ctx: ParseTableContext,
  raw: RawTableData,
) {
  const { project, phases, hasBudgetsAccess, hasCostsAccess } = ctx;
  const isMoneyBudgetType = getIsMoneyBudgetType(project.budget_type);

  const isSecondaryTable = !project.non_billable && !ctx.billable;

  const hasBudgetColumns =
    hasBudgetsAccess && project.budget_priority === ProjectBudgetPriority.Phase;

  const hasFeeColumns =
    hasBudgetsAccess && isMoneyBudgetType && !isSecondaryTable;

  const hasCostColumns = hasCostsAccess;

  const headerNameColumns = [
    {
      label: ctx.billable ? t`Billable` : t`Non-billable`,
      align: 'flex-start',
      grow: 1,
    },
  ];

  const headerBudgetColumns = hasBudgetColumns
    ? [
        {
          label: isSecondaryTable ? '' : t`Budget`,
          width: 130,
          formatter: isSecondaryTable ? () => '' : amountFormatter,
        },
      ]
    : [];

  const headerPastLoggedColumns = [
    {
      label: t`Past logged`,
      width: 220,
      allowOverflow: true,
      formatter: feeAndHoursFromatter,
    },
  ];

  const headerFutureScheduledColumns = [
    {
      label: t`Future scheduled`,
      width: 220,
      allowOverflow: true,
      formatter: feeAndHoursFromatter,
    },
  ];

  const headerProjectedBillableColumns = [
    {
      label: hasFeeColumns ? t`Projected billable` : t`Projected hours`,
      width: 220,
      allowOverflow: true,
      formatter: feeAndHoursFromatter,
    },
  ];

  const headerProjectedCostColumns = hasCostColumns
    ? [
        {
          label: t`Projected cost`,
          width: 180,
          formatter: (x: string) =>
            x === '' ? '' : formatAmount(ProjectBudgetType.HourlyFee, x),
        },
      ]
    : [];

  const headers = [
    ...headerNameColumns,
    ...headerBudgetColumns,
    ...headerPastLoggedColumns,
    ...headerFutureScheduledColumns,
    ...headerProjectedBillableColumns,
    ...headerProjectedCostColumns,
  ].filter((item) => typeof item !== 'undefined');

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPhase = getTotalsByPhase(ctx, raw);

  const totals = {
    budget: 0,
    cost: 0,

    pastLoggedHours: 0,
    pastLoggedFee: 0,

    futureScheduledHours: 0,
    futureScheduledFee: 0,
  };

  const rows: TableDataRowItem[] = [];

  Object.entries(byPhase).forEach(([phaseIdUnsafe, o]) => {
    const phaseId = parseInt(phaseIdUnsafe);

    const phasePastLoggedHours = o.logged.scheduled;
    const phasePastLoggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';

    const phaseFutureScheduledHours = o.future.scheduled;
    const phaseFutureScheduledFee = hasFeeColumns
      ? o.future.feeCents / 100
      : '';

    const phaseTotalHours = phasePastLoggedHours + phaseFutureScheduledHours;
    const phaseTotalCost = hasCostColumns
      ? (o.logged.costCents || 0) / 100 + (o.future.costCents || 0) / 100
      : 0;

    const phaseTotalFee =
      hasFeeColumns &&
      typeof phasePastLoggedFee === 'number' &&
      typeof phaseFutureScheduledFee === 'number'
        ? phasePastLoggedFee + phaseFutureScheduledFee
        : '';

    if (!ctx.billable && phaseTotalHours === 0) {
      return;
    }

    totals.budget += o.budget ?? 0;
    totals.futureScheduledHours += phaseFutureScheduledHours;
    totals.pastLoggedHours += phasePastLoggedHours;

    if (hasFeeColumns) {
      totals.pastLoggedFee += phasePastLoggedFee || 0;
      totals.futureScheduledFee += phaseFutureScheduledFee || 0;
    }

    if (hasCostColumns) {
      totals.cost += phaseTotalCost || 0;
    }

    const phase = phases[phaseId];
    const phaseVal = {
      val: phase?.phase_name ?? t`No Phase`,
      sortVal: phase?.phase_name ?? '0000',
    };
    const phaseBudget = getBudgetDataObject(o);

    const pastLoggedVal = {
      val: {
        fee: phasePastLoggedFee,
        hours: phasePastLoggedHours,
      },
      sortVal: phasePastLoggedHours,
    };

    const futureScheduledVal = {
      val: {
        fee: phaseFutureScheduledFee,
        hours: phaseFutureScheduledHours,
      },
      sortVal: phaseFutureScheduledHours,
    };

    const projectedBillableVal = {
      val: {
        fee: phaseTotalFee,
        hours: phaseTotalHours,
      },
      sortVal: phaseTotalHours,
    };

    const dataNameValues = [phaseVal];
    const dataBudgetValues = hasBudgetColumns ? [phaseBudget] : [];
    const dataPastLoggedValues = [pastLoggedVal];
    const dataFutureScheduledValues = [futureScheduledVal];
    const dataProjectedBillableValues = [projectedBillableVal];
    const dataProjectedCostValues = hasCostColumns ? [phaseTotalCost] : [];

    const data = [
      ...dataNameValues,
      ...dataBudgetValues,
      ...dataPastLoggedValues,
      ...dataFutureScheduledValues,
      ...dataProjectedBillableValues,
      ...dataProjectedCostValues,
    ].filter((item) => typeof item !== 'undefined');

    const rowChildren = Object.entries(o.children)
      .map(([taskName, c]) => {
        if (
          !ctx.billable &&
          c.future.scheduled === 0 &&
          c.logged.scheduled === 0
        )
          return null;

        const childBudget = getBudgetDataObject(
          {
            budget_type: project.budget_type,
          },
          {
            entityType: 'task',
          },
        );

        const childPastLoggedHours = c.logged.scheduled;
        const childPastLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childPastLoggedCost = hasCostColumns
          ? c.logged.costCents / 100
          : '';

        const childFutureScheduledHours = c.future.scheduled;
        const childFutureScheduledFee = hasFeeColumns
          ? c.future.feeCents / 100
          : '';
        const childFutureScheduledCost = hasCostColumns
          ? c.future.costCents / 100
          : '';

        const childTotalHours =
          phasePastLoggedHours + phaseFutureScheduledHours;

        const childTotalFee = hasFeeColumns
          ? (childPastLoggedFee || 0) + (childFutureScheduledFee || 0)
          : '';

        const childTotalCost = hasCostColumns
          ? (childPastLoggedCost || 0) + (childFutureScheduledCost || 0)
          : '';

        const childDataNameValues = [taskName];
        const childDataBudgetValues = hasBudgetColumns ? [childBudget] : [];
        const childDataPastLoggedValues = [
          {
            val: {
              fee: childPastLoggedFee,
              hours: childPastLoggedHours,
            },
            sortVal: childPastLoggedHours,
          },
        ];
        const childDataFutureScheduledValues = [
          {
            val: {
              fee: childFutureScheduledFee,
              hours: childFutureScheduledHours,
            },
            sortVal: childFutureScheduledHours,
          },
        ];
        const childDataProjectedBillableValues = [
          {
            val: {
              fee: childTotalFee,
              hours: childTotalHours,
            },
            sortVal: childTotalHours,
          },
        ];
        const childDataProjectedCostValues = hasCostColumns
          ? [childTotalCost]
          : [];

        const childData = [
          ...childDataNameValues,
          ...childDataBudgetValues,
          ...childDataPastLoggedValues,
          ...childDataFutureScheduledValues,
          ...childDataProjectedBillableValues,
          ...childDataProjectedCostValues,
        ].filter((item) => typeof item !== 'undefined');

        return {
          data: childData,
        };
      })
      .filter((item) => !!item);

    rows.push({
      id: phaseId,
      data,
      children: rowChildren,
    });
  });

  const footerNameColumns = [{ label: ' ' }];
  const footerBudgetColumns = hasBudgetColumns
    ? [
        {
          label: getBudgetDataObject({
            budget_type: project.budget_type,
            budget: totals.budget,
          }),
        },
      ]
    : [];

  const footerPastLoggedColumns = [
    {
      label: feeAndHoursFromatter({
        sortVal: totals.pastLoggedHours,
        val: {
          fee: hasFeeColumns ? totals.pastLoggedFee : '',
          hours: totals.pastLoggedHours,
        },
      }),
    },
  ];

  const footerFutureScheduledColumns = [
    {
      label: feeAndHoursFromatter({
        sortVal: totals.futureScheduledHours,
        val: {
          fee: hasFeeColumns ? totals.futureScheduledFee : '',
          hours: totals.futureScheduledHours,
        },
      }),
    },
  ];

  const footerProjectedBillableColumns = [
    {
      label: feeAndHoursFromatter({
        sortVal: totals.pastLoggedHours + totals.futureScheduledHours,
        val: {
          fee: hasFeeColumns
            ? totals.pastLoggedFee + totals.futureScheduledFee
            : '',
          hours: totals.pastLoggedHours + totals.futureScheduledHours,
        },
      }),
    },
  ];

  const footerProjectedCostColumns = hasCostColumns
    ? [{ label: totals.cost }]
    : [];

  const footer = [
    ...footerNameColumns,
    ...footerBudgetColumns,
    ...footerPastLoggedColumns,
    ...footerFutureScheduledColumns,
    ...footerProjectedBillableColumns,
    ...footerProjectedCostColumns,
  ].filter((item) => typeof item !== 'undefined');

  return { headers, rows, footer };
}
