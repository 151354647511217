import { MouseEventHandler, useRef } from 'react';
import useDoubleClickHook from 'use-double-click';

export const useDoubleClick = ({
  onSingleClick,
  onDoubleClick,
}: {
  onSingleClick: MouseEventHandler;
  onDoubleClick: MouseEventHandler;
}) => {
  const ref = useRef(null);
  useDoubleClickHook({
    onSingleClick,
    onDoubleClick,
    ref,
    latency: 250,
  });
  return ref;
};
