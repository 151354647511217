import { forEach, keyBy, omit } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { RolesAction, RolesState } from '@float/types';

import {
  ADD_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  ROLES_BULK_UPDATED,
  UPDATE_ROLE_SUCCESS,
} from '../actions';

const REDUCER_NAME = 'roles';
export const DEFAULT_STATE: RolesState = {
  roles: {},
  loadState: 'UNLOADED',
  rolesLoaded: false,
};

type ActionsFromPersist = {
  type: typeof REHYDRATE;
  payload?: {
    [REDUCER_NAME]?: Partial<RolesState>;
  };
};

export const roles = (
  state = DEFAULT_STATE,
  action: RolesAction | ActionsFromPersist,
): RolesState => {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        loadState: 'LOADING',
      };

    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: keyBy(action.entities, 'id'),
        loadState: 'LOADED',
        rolesLoaded: true,
      };

    case ADD_ROLE_SUCCESS:
    case UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
        loadState: 'LOADED',
        rolesLoaded: true,
        roles: {
          ...state.roles,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    }

    case DELETE_ROLE_SUCCESS: {
      const { id } = action;
      if (!id) {
        return state;
      }
      return {
        ...state,
        roles: omit(state.roles, id),
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.rolesLoaded ? 'LOADED' : 'UNLOADED';

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    case ROLES_BULK_UPDATED: {
      const { roles, fields } = action;

      const newRoles = { ...state.roles };

      forEach(roles, (role) => {
        const id = role.id;

        if (newRoles[id]) {
          newRoles[id] = {
            ...newRoles[id],
            default_hourly_rate: fields.default_hourly_rate,
          };
        }
      });

      return { ...state, roles: newRoles };
    }

    default: {
      return state;
    }
  }
};
