import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import {
  CHART_TYPES,
  MODES,
  PCT_MODES,
  PROJ_PCT_MODES,
  UNITS,
} from '@float/ui/deprecated/Chart/constants';

import { ProjectMarginType } from '../types';

const REPORTS_SETTINGS_KEYS = [
  'mode',
  'startDate',
  'endDate',
  'rangeMode', // DatePicker range option
  'lastRangeMode', // Previous DatePicker range option
  'teamMode',
  'activeTeamModes',
  'activeMode',
  'taskStatus',
  'timeOffStatus',
  // 'currentTableTab',
  // 'sorterPreferences',
  'comparisonMode', // Logged + Future scheduled / etc dropdown
  'timeUnit', // Day / week / month
  'percentageMode',
  'projectMarginType',
  'projectsPercentageMode',
  'chartType',
  'tableSorts',
];

const DEFAULT_SETTINGS = {
  mode: 'week',
  startDate: moment().startOf('week').format('YYYY-MM-DD'),
  endDate: moment()
    .startOf('week')
    .add(12, 'weeks')
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  rangeMode: 0, // Next 12 weeks
  lastRangeMode: 0,
  teamMode: 2,
  activeTeamModes: [0, 1],
  activeMode: 1,
  taskStatus: ['Tentative', 'Confirmed', 'Completed'],
  timeOffStatus: ['Declined', 'Tentative', 'Approved'],
  comparisonMode: MODES.SCHEDULED,
  timeUnit: UNITS.WEEK,
  projectMarginType: ProjectMarginType.MarginOnFixedFee,
  projectsPercentageMode: PROJ_PCT_MODES.SCHEDULED,
  percentageMode: PCT_MODES.SCHEDULED_CAPACITY,
  chartType: CHART_TYPES.LINE,
  tableSorts: {},
};

function useReportsSettings() {
  const timeoffApprovalsEnabled = useAppSelector(
    (state) => state.companyPrefs.timeoff_approvals,
  );

  const [settings, setSettings] = useState(() => {
    const saved = JSON.parse(localStorage.getItem('reportsSettings')) ?? {};
    let timeOffStatus = saved.timeOffStatus ?? DEFAULT_SETTINGS.timeOffStatus;

    if (!timeoffApprovalsEnabled) {
      timeOffStatus = timeOffStatus
        .filter((status) => status !== 'Declined')
        .map((status) => (status === 'Approved' ? 'Confirmed' : status));
    }
    return {
      ...DEFAULT_SETTINGS,
      ...saved,
      timeOffStatus,
    };
  });

  const updateSettings = useCallback(function updateSettings(toUpdate) {
    if (
      Object.keys(toUpdate).some((key) => !REPORTS_SETTINGS_KEYS.includes(key))
    ) {
      throw Error('Unknown key in object', toUpdate);
    }

    if (toUpdate.startDate && toUpdate.startDate._isAMomentObject) {
      toUpdate.startDate = toUpdate.startDate.format('YYYY-MM-DD');
    }

    if (toUpdate.endDate && toUpdate.endDate._isAMomentObject) {
      toUpdate.endDate = toUpdate.endDate.format('YYYY-MM-DD');
    }

    setSettings((oldSettings) => {
      return {
        ...oldSettings,
        ...toUpdate,
      };
    });
  }, []);

  const getTableSort = useCallback(
    function getTableSort(key) {
      return settings.tableSorts[key];
    },
    [settings.tableSorts],
  );

  const updateTableSort = useCallback(function updateTableSort(key, col, dir) {
    setSettings((oldSettings) => {
      return {
        ...oldSettings,
        tableSorts: {
          ...oldSettings.tableSorts,
          [key]: { col, dir },
        },
      };
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('reportsSettings', JSON.stringify(settings));
  }, [settings]);

  return useMemo(
    () => ({
      settings,
      updateSettings,
      createSortConfig(key) {
        return {
          get() {
            return getTableSort(key);
          },
          set(col, dir) {
            updateTableSort(key, col, dir);
          },
        };
      },
      getTableSort,
      updateTableSort,
    }),
    [settings, updateSettings, getTableSort, updateTableSort],
  );
}

export default useReportsSettings;
