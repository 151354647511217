export type TimeoffType = {
  active: 0 | 1;
  balance_days: number | null;
  balance_type: TimeoffBalanceType;
  carryover_days: number | null;
  color: string | null;
  effective_date: string | null;
  timeoff_type_id: number;
  timeoff_type_name: string;
};

export type TimeoffTypeBalance = {
  balanceType: TimeoffBalanceType;
  daysAvailable: number;
  id: TimeoffType['timeoff_type_id'];
};

export enum TimeoffBalanceType {
  Unlimited = 0,
  AccruedPerYear = 1,
  AccruedPerAnniversary = 2,
  GrantedPerYear = 3,
  GrantedPerAnniversary = 4,
}

export enum TimeoffPreviewMode {
  Today = 'today',
  Future = 'future',
}
