import { getBulkEditBillRateConfig } from '@float/web/components/elements/BulkEditModal/BulkEditBillRate';

import { getBillRateChangeConfirmData } from '../Rate.helpers';

export function getBulkEditBillRateField() {
  return {
    ...getBulkEditBillRateConfig(),
    getConfirmData: getBillRateChangeConfirmData,
  };
}
