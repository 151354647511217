export const FETCH_ROLES = 'FETCH_ROLES' as const;
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS' as const;
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE' as const;

export const ADD_ROLE = 'ADD_ROLE' as const;
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS' as const;
export const ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE' as const;

export const UPDATE_ROLE = 'UPDATE_ROLE' as const;
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS' as const;
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE' as const;

export const DELETE_ROLE = 'DELETE_ROLE' as const;
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS' as const;
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE' as const;

export const SORT_ROLES = 'SORT_ROLES' as const;

export const ROLES_BULK_UPDATED = 'ROLES_BULK_UPDATED' as const;
