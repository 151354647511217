import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import { getTaskMetasOptions } from '@float/common/selectors/taskMetas';
import { useAppSelectorStrict } from '@float/common/store';
import { Phase } from '@float/types/phase';
import { Project } from '@float/types/project';
import { IconDownload } from '@float/ui/icons/essentials/IconDownload';
import { IconFilter } from '@float/ui/icons/essentials/IconFilter';
import { IconPencil } from '@float/ui/icons/essentials/IconPencil';
import { IconTrash2 } from '@float/ui/icons/essentials/IconTrash2';
import { IconUpload } from '@float/ui/icons/essentials/IconUpload';
import { ListItemData } from '@float/ui/primitives/List/types';

import { useProjectScheduleFilter } from '../../hooks/useProjectScheduleFilter';
import { ProjectFormData } from '../../types';

export type PhaseMenuItemsType = {
  isTemplate: boolean;
  isPhasePanel?: boolean;
  phaseActive?: boolean;
  phaseId?: number;
  phaseName?: string;
  handleActivate: () => void;
  handleArchive: () => void;
  handleDelete: () => void;
  handleEditPhaseDetails?: () => void;
  index: number;
};

export const usePhaseMenuItems = (props: PhaseMenuItemsType) => {
  const { getValues } = useFormContext<ProjectFormData>();
  const showOnSchedule = useProjectScheduleFilter({
    key: 'phase',
    value: props.phaseId ? props.phaseName : '',
  });
  const hidePhasePanelOption = props.isPhasePanel;
  const items: ListItemData[] = useMemo(
    () =>
      [
        {
          value: t`Edit phase details`,
          onClick: props.handleEditPhaseDetails,
          hide: hidePhasePanelOption,
          icon: IconPencil,
        },
        {
          value: t`View using filter`,
          onClick: showOnSchedule,
          disabled: !props.phaseId,
          hide: props.isTemplate,
          icon: IconFilter,
        },
        {
          value: t`Move to active`,
          onClick: props.handleActivate,
          hide: props.phaseActive || props.isTemplate,
          disabled: !getValues('project.active'),
          icon: IconUpload,
        },
        {
          value: t`Archive`,
          onClick: props.handleArchive,
          hide: !props.phaseActive || !props.phaseId || props.isTemplate,
          icon: IconDownload,
        },
        {
          value: t`Delete`,
          onClick: props.handleDelete,
          hide: props.phaseActive && props.phaseId && !props.isTemplate,
          disabled: !getValues('project.active'),
          icon: IconTrash2,
        },
      ].filter((i) => !i.hide),
    [
      hidePhasePanelOption,
      getValues,
      props.handleActivate,
      props.handleArchive,
      props.handleDelete,
      props.handleEditPhaseDetails,
      props.isTemplate,
      props.phaseActive,
      props.phaseId,
      showOnSchedule,
    ],
  );

  return items;
};

export const usePhaseTasks = (
  projectId: Project['project_id'] = 0,
  phaseId: Phase['phase_id'],
) => {
  const phaseTasks = useAppSelectorStrict((state) =>
    getTaskMetasOptions(state.taskMetas.taskMetas, projectId, phaseId, null),
  );
  return phaseTasks;
};
