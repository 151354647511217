import React, { useRef } from 'react';
import cn from 'classnames';
import type { MouseEvent } from 'react';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Checkbox } from '@float/ui/components/Checkbox';
import EHIconClose from '@float/ui/deprecated/Earhart/Icons/Icon/IconClose';
import { List } from '@float/ui/primitives/List';
import { getHasSubVal } from '@float/web/components/SearchFilterDropdown/helpers/getHasSubVal';
import {
  FilterSubValue,
  FilterValue,
} from 'components/SearchFilters/components/FilterValue';
import type { SearchAutocompleteResultItem } from '@float/common/search/selectors/getSearchAutocompleteResults';
import type { VirtualFilterTypes } from '@float/types/view';

import * as styles from './styles.css';

export type ItemProps = {
  checked?: boolean;
  className?: string;
  highlighted?: boolean;
  isCheckboxDisabled: boolean;
  isCheckboxReadOnly: boolean;
  isDeletable?: boolean;
  isSelectable?: boolean;
  item: SearchAutocompleteResultItem;
  onClickCheckBox?: () => void;
  onDelete?: (item: SearchAutocompleteResultItem) => void;
  onSelect?: (value: string) => void;
};

export function Item(props: ItemProps) {
  const {
    checked = false,
    className,
    highlighted = false,
    isCheckboxDisabled,
    isCheckboxReadOnly,
    isDeletable,
    isSelectable,
    item,
    onClickCheckBox,
    onDelete,
    onSelect,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (onSelect) onSelect(item.val);
  };

  const handleCheckboxClick = (e: MouseEvent) => {
    prevent(e);

    ref.current?.blur();

    if (onClickCheckBox) onClickCheckBox();
  };

  const hasSubVal = getHasSubVal(item);

  return (
    <List.Item.Root
      className={className}
      ref={ref}
      onClick={handleClick}
      role="option"
      selected={highlighted}
    >
      <List.Item.Value
        className={cn({
          [styles.itemValueFlex]: hasSubVal,
          [styles.itemValue]: !hasSubVal,
        })}
      >
        <div className={styles.textOverflowEllipsis}>
          <FilterValue type={item.type as VirtualFilterTypes} val={item.val} />
        </div>

        {hasSubVal && <FilterSubValue val={item.subVal} />}
      </List.Item.Value>

      {isSelectable && (
        <List.Item.Icon className={cn({ [styles.checkboxTop]: hasSubVal })}>
          <Checkbox
            checked={checked}
            disabled={isCheckboxDisabled}
            onClick={handleCheckboxClick}
            readOnly={isCheckboxReadOnly}
            size="small"
          />
        </List.Item.Icon>
      )}

      {isDeletable && (
        <List.Item.Icon>
          <EHIconClose
            onClick={(e: MouseEvent) => {
              prevent(e);
              if (onDelete) onDelete(item);
            }}
          />
        </List.Item.Icon>
      )}
    </List.Item.Root>
  );
}
