import { useCallback } from 'react';

import { Input } from '@float/ui/deprecated/Input';

const isValid = Input.validators.number(6);

export function useEditBillRate(onChange: (value: string) => void) {
  const handleBillRateChange = useCallback(
    (newValue: string) => {
      if (!isValid(newValue)) return;

      onChange(newValue);
    },
    [onChange],
  );

  return { handleBillRateChange };
}
