import { search } from '@float/common/api3/search';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import type { SearchQueryApiParams } from '@float/common/api3/search.types';

import {
  DEFAULT_PAGE_SIZE,
  LOCAL_CATEGORY_TYPE_TO_REMOTE_CATEGORY_TYPE,
} from './constants';
import { formatQueryApiResultItem } from './helpers/formatQueryApiResultItem';
import type {
  AutocompleteQueryApiReturnValue,
  QueryApiOptions,
  QueryApiParams,
} from './queryApi.types';

export async function queryApi(
  apiParams: QueryApiParams,
  options?: QueryApiOptions,
): Promise<AutocompleteQueryApiReturnValue> {
  const { projectCodesEnabled = true } = options ?? {};

  const minQueryLength = featureFlags.isFeatureEnabled(
    FeatureFlag.SearchBeyondLimitsMinQueryLength,
  )
    ? 2
    : 0;

  if (apiParams.query.length <= minQueryLength && !apiParams.category) {
    return {
      items: [],
      count: 0,
      hasNextPage: () => false,
      fetchNextPage: async () => Promise.resolve([]),
    };
  }

  if (apiParams.category === 'savedSearches') {
    return {
      items: [],
      count: 0,
      hasNextPage: () => false,
      fetchNextPage: async () => Promise.resolve([]),
    };
  }

  const category =
    apiParams.category &&
    LOCAL_CATEGORY_TYPE_TO_REMOTE_CATEGORY_TYPE[apiParams.category];

  const searchQueryApiParams: SearchQueryApiParams = {
    query: apiParams.query,
    attributes: apiParams.attributes,
  };

  if (category) {
    searchQueryApiParams.filter = {
      key: 'category',
      value: category,
    };
    searchQueryApiParams.pagination = {
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    };
  }

  const [items, pageCount, count] = await search.query(searchQueryApiParams);

  let currentPage = 1;
  let allItems = items.flatMap((item) =>
    formatQueryApiResultItem(item, { projectCodesEnabled }),
  );

  function hasNextPage() {
    return currentPage < pageCount;
  }

  async function fetchNextPage() {
    if (hasNextPage()) {
      currentPage += 1;

      searchQueryApiParams.pagination = {
        page: currentPage,
        pageSize: DEFAULT_PAGE_SIZE,
      };

      const [items] = await search.query(searchQueryApiParams);

      allItems = allItems.concat(
        items.flatMap((item) =>
          formatQueryApiResultItem(item, { projectCodesEnabled }),
        ),
      );
    }

    return allItems;
  }

  return {
    items: allItems,
    hasNextPage,
    fetchNextPage,
    // The API has a bug where it returns a count that is less than the number of items
    // https://linear.app/float-com/issue/PS-1471/query-return-pagination-data-by-default-if-filter-is-provided-but-no
    count: Math.max(count, allItems.length),
  };
}
