import Rollbar from 'rollbar';

import { FloatDeploymentEnvironment } from '@float/constants/environment';

import { LoggerClientAdapter } from '../logger.types';
import { getCheckIgnoreHandler } from './helpers';

const ROLLBAR_ACCESS_TOKEN = 'b3056db3182d4e388e8d7e68cb8c8f53';

enum RollbarEnvironment {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
}

const ROLLBAR_ENVIRONMENT_MAP: Record<
  FloatDeploymentEnvironment,
  RollbarEnvironment
> = {
  [FloatDeploymentEnvironment.Develop]: RollbarEnvironment.Development,
  [FloatDeploymentEnvironment.Local]: RollbarEnvironment.Development,
  [FloatDeploymentEnvironment.Prod]: RollbarEnvironment.Production,
  [FloatDeploymentEnvironment.Staging]: RollbarEnvironment.Staging,
  [FloatDeploymentEnvironment.Preview]: RollbarEnvironment.Staging,
};

export class RollbarWebLoggerAdapter implements LoggerClientAdapter {
  private client: Rollbar;
  private environment: FloatDeploymentEnvironment;
  private gitSha: string;
  private supportedBrowsers?: RegExp;

  private _isReady = false;

  public get isReady() {
    return this._isReady;
  }

  constructor(
    client: Rollbar,
    environment: FloatDeploymentEnvironment,
    gitSha: string,
    supportedBrowsers?: RegExp,
  ) {
    this.client = client;
    this.environment = environment;
    this.supportedBrowsers = supportedBrowsers;
    this.gitSha = gitSha;
  }

  public async initialize() {
    this.client.configure({
      accessToken: ROLLBAR_ACCESS_TOKEN,
      verbose: false,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: ROLLBAR_ENVIRONMENT_MAP[this.environment],
        client: {
          javascript: {
            code_version: this.gitSha,
            source_map_enabled: true,
            uncaught_frames: true,
          },
        },
      },
      checkIgnore: getCheckIgnoreHandler(this.supportedBrowsers),
      // ignoring this error as it is an harmless warning that is flooding rollbar
      // see https://github.com/WICG/resize-observer/issues/38#issuecomment-334774158
      // https://app.rollbar.com/a/float/fix/item/fe-web-app/111
      ignoredMessages: ['ResizeObserver loop limit exceeded'],
    });

    this._isReady = true;
  }

  public identify({
    id,
    companyId,
  }: {
    id: string | number;
    companyId?: string | number;
    companyName?: string | number;
  }) {
    this.client.configure({
      payload: {
        person: {
          id,
          company_id: companyId,
          // Adding the company_id as username because the username field can
          // be used as a search filter
          username: companyId?.toString(),
        },
      },
    });
  }

  public log(message: string | Error, ...args: unknown[]) {
    // Rollbar args don't support unknown[], but do support any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.client.log(message, ...(args as any[]));
  }

  public info(message: string | Error, ...args: unknown[]) {
    // Rollbar args don't support unknown[], but do support any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.client.info(message, ...(args as any[]));
  }

  public warn(message: string | Error, ...args: unknown[]) {
    // Rollbar args don't support unknown[], but do support any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.client.warning(message, ...(args as any[]));
  }

  public error(message: string | Error, ...args: unknown[]) {
    // Rollbar args don't support unknown[], but do support any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.client.error(message, ...(args as any[]));
  }

  public debug(message: string | Error, ...args: unknown[]) {
    // Rollbar args don't support unknown[], but do support any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.client.debug(message, ...(args as any[]));
  }
}
