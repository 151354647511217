import { FeatureFlags } from './FeatureFlags.class';
import { FeatureFlag } from './FeatureFlags.constants';

export type * from './FeatureFlags.types';
export * from './FeatureFlags.constants';

/**
 * Implementation agnostic singleton used to access feature flags.
 * Must be initialized with a FeatureFlagClientAdapter before being used.
 */
export const featureFlags = new FeatureFlags();

/**
 * @deprecated - import `featureFlags` instance directly and call `featureFlags.isFeatureEnabled`
 */
export function isFeatureEnabled(key: FeatureFlag) {
  return featureFlags.isFeatureEnabled(key);
}
