import React from 'react';
import { t } from '@lingui/macro';

import { Button } from '@float/ui/components/Button';
import { Tooltip } from '@float/ui/components/Tooltip';
import { IconSpv } from '@float/ui/icons/essentials/IconSpv';

import { useOpenSPVFromSidePanel } from './ProjectActionsMenu/hooks/useOpenSPVFromSidePanel';

export const OpenSPVButton = () => {
  const onOpenSPVClick = useOpenSPVFromSidePanel();
  return (
    <Tooltip content={t`Open project detail view`}>
      <Button
        size="xsmall"
        appearance="clear-flay"
        iconOnlyLabel={t`Open project detail view`}
        iconEnd={IconSpv}
        onClick={onOpenSPVClick}
      />
    </Tooltip>
  );
};
