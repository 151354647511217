import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { t } from '@lingui/macro';
import Linkify from 'linkify-react';
import { forEach, get, isEqual, sortBy } from 'lodash';
import { bindActionCreators, compose } from 'redux';

import {
  updateMultiUserPrefs,
  updateUserPref,
} from '@float/common/actions/currentUser';
import { ensureDepartmentsLoaded } from '@float/common/actions/departments';
import * as phaseActions from '@float/common/actions/phases';
import * as projectActions from '@float/common/actions/projects';
import {
  ensureSearchContextLoaded,
  setPlaceholder,
} from '@float/common/actions/search';
import * as tagsActions from '@float/common/actions/tags';
import Loader from '@float/common/components/elements/Loader';
import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { Rights } from '@float/common/lib/acl';
import { sortByDateAndName } from '@float/common/lib/itemSort';
import { getDisplayNotesExcerpt } from '@float/common/lib/notes';
import { appendFilter } from '@float/common/lib/url';
import { getSearchFilteredProjects } from '@float/common/search/selectors/projects';
import { getActiveFilters } from '@float/common/selectors/views';
import { provideStoreHoc } from '@float/common/store';
import * as budgetActions from '@float/common/store/budgets/budgets.actions';
import { DEFAULT_COLOR } from '@float/constants/colors';
import { PROMPTS } from '@float/constants/prompts';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { moment } from '@float/libs/moment';
import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import { BudgetPriority } from '@float/types/project';
import {
  IconArchive,
  IconBolt,
  IconPencil,
  IconTrash,
} from '@float/ui/deprecated/Earhart/Icons';
import IconNotesLarge from '@float/ui/deprecated/Icons/icon-notes-large';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { PageBody } from '@float/ui/deprecated/Layout/PageBody';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { withSnackbar } from '@float/ui/deprecated/Snackbar';
import { Table } from '@float/ui/deprecated/Table/Table';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';
import { IconPlus } from '@float/ui/icons/essentials/IconPlus';
import { IconProjectPlan } from '@float/ui/icons/essentials/IconProjectPlan';
import { IconReport } from '@float/ui/icons/essentials/IconReport';
import { IconSidebar } from '@float/ui/icons/essentials/IconSidebar';
import ImportPrompt from '@float/web/components/legacyOnboarding/InAppNotifications/ImportPrompt';
import * as integrationsCommonActions from '@float/web/integrations/actions/common';
import {
  ProjectPlanViewSource,
  trackProjectPlanView,
} from '@float/web/lib/tracking/trackProjectPlanView';
import { withModalConfirmDelete } from '@float/web/modalManager/hoc/withModalConfirmDelete';
import manageModal from '@float/web/modalManager/manageModalActionCreator';
import {
  getActiveIntegrations,
  getHaveProjectsWithContextBeenLoaded,
  getProjectPhases,
  getProjectsMap,
  getProjectTagByLabel,
  getTagsLoaded,
  getUser,
  selectIsProjectCodesEnabled,
} from '@float/web/selectors';
import {
  hideSidePanelIfNotPrevented,
  showPhaseSidePanel,
  showProjectSidePanel,
} from '@float/web/sidePanel/actions';
import { sidePanelConnect } from '@float/web/sidePanel/sidePanelConnect';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';

import SyncIcon from '../../integrations/SyncIcon';
import { EntityStatusTag } from './components/EntityStatusTag/EntityStatusTag';
import { PhaseExpand } from './components/PhaseExpand/PhaseExpand';
import {
  getBudgetSortString,
  ProjectBudgetBar,
} from './components/ProjectBudgetBar/ProjectBudgetBar';
import { ProjectQuickFilters } from './components/ProjectQuickFilters';
import { ErrorNoProjects } from './ErrorNoProjects';
import { getFilteredProjects } from './helpers/getFilteredProjects';
import { getPlanLink } from './helpers/getPlanLink';
import { showModalConfirmProjectDelete } from './helpers/showModalConfirmProjectDelete';
import ImportProjectsModal from './import-projects/ImportProjectsModal';
import BulkEditProjectsModal from './modals/BulkEditProjectsModal/BulkEditProjectsModal';
import { ProjectQuickActions } from './ProjectQuickActions';
import {
  PROJECT_COLUMNS,
  PROJECT_DEFAULT_SORT_VALS,
  PROJECT_STATUS,
} from './Projects.constants';
import { checkSelectedDeleteAccess } from './Projects.helpers';
import * as styled from './styles';
import ManageTemplatesModal from './templates/ManageTemplatesModal';

import ImgImportProjects from './import-projects/import-projects.png';

const PROJECT_IMPORT_PromptId = PROMPTS.projectImport;

const formatDate = (d) =>
  d && d !== '0000-00-00' && moment(d).format('DD MMM YYYY');

class Projects extends React.Component {
  state = {
    filteredProjects: [],
    filteredProjectsCount: { active: 0, archived: 0 },
    projectView: 1,
    modal: {},
    expandedProjects: {},
  };

  static getDerivedStateFromProps(props) {
    const s = {
      byMy: Number(get(props, 'location.query.my', 0)),
      sort: {
        type: 'project_name',
        dir: 'asc',
      },
    };

    if (get(props, 'currentUser.prefs.proj_sort_by')) {
      s.sort = {
        type: props.currentUser.prefs.proj_sort_by,
        dir: 'asc',
      };
    }

    if (get(props, 'currentUser.prefs.proj_sort_dir')) {
      s.sort.dir = props.currentUser.prefs.proj_sort_dir;
    }

    if (get(props, 'currentUser.prefs.proj_view')) {
      s.projectView = Number(props.currentUser.prefs.proj_view);
    }

    return s;
  }

  isLoaded = () => {
    // Note that we only want to render the loader on the very first page load.
    // Subsequent renders might trigger a full project update, but we don't want
    // to unmount any components (like the import modal) to show the spinner.
    return this.props.hasBeenLoaded;
  };

  // ---------------------------------------------------------------------------
  // !!! React Lifecycle -------------------------------------------------------
  // ---------------------------------------------------------------------------

  componentDidMount() {
    const expandedProjects = this.loadExpandedPhasesFromLocalStorage();
    this.fetchAllData(expandedProjects);

    // load integration project meta data for each active integration
    for (const integration of this.props.activeIntegrations) {
      this.props.actions.fetchIntegrationProjectMeta(integration.coIntId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const propChangesToDetect = [
      'filters',
      'projects',
      'searchFilteredProjects',
      'projectPhases',
      'currentUser.prefs.me_filter',
      'hasBeenLoaded',
    ];

    const stateChangesToDetect = [
      'sort',
      'projectView',
      'byMy',
      'expandedProjects',
    ];

    const propsChanged = propChangesToDetect.some(
      // These are from Redux so we can rely on immutability
      (path) => get(this.props, path) !== get(prevProps, path),
    );

    const stateChanged = stateChangesToDetect.some(
      (path) => !isEqual(get(this.state, path), get(prevState, path)),
    );

    if (this.isLoaded() && (propsChanged || stateChanged)) {
      if (stateChanged && this.state.projectView !== prevState.projectView) {
        this.fetchAllData();
        return;
      }

      this.filterProjects();
    }
  }

  // ---------------------------------------------------------------------------
  // !!! Actions ---------------------------------------------------------------
  // ---------------------------------------------------------------------------

  changeSortType = async (type, dir) => {
    this.props.actions.updateMultiUserPrefs({
      proj_sort_by: type,
      proj_sort_dir: dir,
    });
  };

  changeSortDir = (value) => {
    if (!['asc', 'desc'].includes(value)) {
      return;
    }
    if (value !== this.state.sort.dir) {
      this.props.actions.updateUserPref('proj_sort_dir', value);
    }
  };

  changeByMy = (byMy) => {
    const {
      location: { pathname, query = {} },
    } = this.props;

    if (byMy) {
      query.my = 1;
    } else {
      delete query.my;
    }

    this.setState({ byMy }, () => {
      this.props.history.replace({ pathname, query });
    });
  };

  onChangeFilterActive = (isSelected) => {
    let newValue = PROJECT_STATUS.ACTIVE_ARCHIVED;
    if (!isSelected) {
      if (this.state.projectView !== PROJECT_STATUS.ACTIVE_ARCHIVED) {
        return;
      }
      newValue = PROJECT_STATUS.ARCHIVED;
    }
    this.props.actions.updateUserPref('proj_view', `${newValue}`);
  };

  onChangeFilterArchived = (isSelected) => {
    let newValue = PROJECT_STATUS.ACTIVE_ARCHIVED;
    if (!isSelected) {
      if (this.state.projectView !== PROJECT_STATUS.ACTIVE_ARCHIVED) {
        return;
      }
      newValue = PROJECT_STATUS.ACTIVE;
    }
    this.props.actions.updateUserPref('proj_view', `${newValue}`);
  };

  onChangeSort = ({ value }) => {
    const [sort, order] = value.split('-');
    this.changeSortType(sort, order);
  };

  onImport = () => {
    if (!this.props.importPromptDismissed) {
      this.props.dismissPrompt();
    }
    this.hideModal();
  };

  toggleProjectExpanded = (projectId) => {
    this.setState(
      (ps) => ({
        expandedProjects: {
          ...ps.expandedProjects,
          [projectId]: !ps.expandedProjects[projectId],
        },
      }),
      this.storeExpandedPhasesInLocalStorage,
    );
  };

  expandProject = (projectId) => {
    if (!this.state.expandedProjects[projectId]) {
      this.toggleProjectExpanded(projectId);
    }
  };

  // ---------------------------------------------------------------------------
  // !!! Helpers ---------------------------------------------------------------
  // ---------------------------------------------------------------------------

  getProjectColor = (project) => {
    const color = /^[a-fA-F0-9]{3,6}$/.test(project.color)
      ? project.color
      : DEFAULT_COLOR;

    return `#${color}`;
  };

  checkIsProjectExpanded = (project) => {
    const isExpanded = this.state.expandedProjects[project.project_id];
    return isExpanded;
  };

  getProjectProps = (project) => {
    const clientName = get(
      this.props,
      `clients.clients.${project.client_id}.client_name`,
    );

    const pm = get(this.props, `accounts.accounts.${project.project_manager}`);

    const canEdit = Rights.canUpdateProject(this.props.currentUser, {
      project,
    });

    return {
      project: {
        label: this.renderProjectName(project),
        value: project.project_name || '',
      },
      projectCode: this.renderProjectCode(project),
      phaseExpand: this.renderPhaseExpand(project),
      client: {
        value: clientName || 'No Client',
        label: this.renderClientName({
          clientId: project.client_id,
          isActive: project.active,
        }),
      },
      tags: this.renderTags(project),
      status: this.renderStatusTag(project),
      notes: this.renderNotes(project),
      budget: {
        label: this.renderBudget(project),
        value: project.budget_total || 0,
      },
      nonBillableTag: this.renderNonBillableTag(project),
      start: {
        label: this.renderStart(project),
        value: this.getDateGroupValue(project.start_date),
      },
      end: {
        label: this.renderEnd(project),
        value: this.getDateGroupValue(project.end_date, 'End'),
      },
      pm: {
        label: this.renderPM({
          isActive: project.active,
          accountId: project.project_manager,
        }),
        value: pm?.name || 'No project owner',
      },
      color: this.getProjectColor(project),
      key: project.project_id,
      canEdit,
      entity: this.props.projects[project.project_id],
      onClick: (evt) => {
        evt.preventDefault();
        if (featureFlags.isFeatureEnabled(FeatureFlag.SingleProjectView)) {
          this.props.history.push(`/project/${project.project_id}`);
        } else {
          this.showEditProjectModal(project);
        }
      },
    };
  };

  getPhaseProps(phase) {
    const { projects, currentUser } = this.props;
    const project = projects[phase.project_id];

    const canPossiblyEditPhases = Rights.canUpdatePhase(currentUser);
    const canUpdatePhase =
      canPossiblyEditPhases &&
      Rights.canUpdatePhase(currentUser, {
        phase,
        project,
      });
    const color = `#${phase.color || project.color || DEFAULT_COLOR}`;

    const ret = {
      selectable: false,
      preventSelect: true,
      selectablePadding: canPossiblyEditPhases,
      className: 'phase-row',
      key: `phase:${phase.phase_id}`,
      color,
      project: {
        label: () => (
          <>
            <Spacer size={40} />
            <Table.Text disabled={!phase.active} primary>
              {phase.phase_name}
            </Table.Text>
          </>
        ),
      },
      status: this.renderStatusTag(phase),
      notes: this.renderNotes(phase),
      budget: { label: this.renderBudget(phase) },
      nonBillableTag: this.renderNonBillableTag(phase),
      start: { label: this.renderStart(phase) },
      end: { label: this.renderEnd(phase) },
      canEdit: canUpdatePhase,
      parent: project,
      entity: phase,
      onClick: (evt) => {
        preventDefaultAndStopPropagation(evt);
        this.showEditPhaseModal(phase);
      },
      startDate: phase.start_date,
    };
    return ret;
  }

  getRowProps = (rowData) => {
    if (rowData.render) {
      return rowData;
    }

    if (rowData.phase_id) {
      return this.getPhaseProps(rowData);
    }

    return this.getProjectProps(rowData);
  };

  filterProjects = (expandedProjects) => {
    const { searchFilteredProjects, currentUser } = this.props;
    const { projectView, byMy } = this.state;
    const accountId = Number(currentUser.admin_id);

    const { filteredProjects, filteredProjectsCount } = getFilteredProjects(
      searchFilteredProjects,
      projectView.toString(),
      byMy,
      accountId,
    );

    const sorted = sortBy(filteredProjects, (p) => {
      const sortVal = (() => {
        if (this.state.sort.type === 'client') {
          return get(
            this.props,
            `clients.clients.${p.client_id}.client_name`,
            PROJECT_DEFAULT_SORT_VALS.client,
          ).toLowerCase();
        }

        if (this.state.sort.type === 'pm') {
          return get(
            this.props,
            `accounts.accounts.${p.project_manager}.name`,
            PROJECT_DEFAULT_SORT_VALS.pm,
          ).toLowerCase();
        }

        if (this.state.sort.type === 'status') {
          return get(p, 'status');
        }

        if (this.state.sort.type === 'start') {
          return moment(get(p, 'start_date', new Date(2100, 1, 1))).valueOf();
        }

        if (this.state.sort.type === 'end') {
          return moment(get(p, 'end_date', new Date(2100, 1, 1))).valueOf();
        }

        if (this.state.sort.type === 'budget') {
          const projectBudget = this.props.budgets.projects[p.project_id] || {};

          return getBudgetSortString(p, projectBudget, this.state.sort.dir);
        }

        if (this.state.sort.type === 'created') {
          return get(p, 'created');
        }

        if (this.state.sort.type === 'projectCode') {
          // empty project codes listed first when sort order is ascending
          return (
            get(p, 'project_code') || PROJECT_DEFAULT_SORT_VALS.projectCode
          );
        }

        return '';
      })();

      return `${sortVal}-${get(p, 'project_name', '').toLowerCase()}`;
    });

    if (this.state.sort.type === 'budget') {
      if (this.state.sort.dir === 'asc') {
        sorted.reverse();
      }
    } else if (this.state.sort.dir === 'desc') {
      sorted.reverse();
    }

    // After we've sorted the projects page by the appropriate project
    // attribute, we want to splice in the phase rows for any phases that
    // are currently open.
    forEach(
      expandedProjects || this.state.expandedProjects,
      (isOpen, projectId) => {
        if (!isOpen) return;

        const idx = sorted.findIndex((s) => s.project_id == projectId);
        if (idx === -1) {
          // If we couldn't find the index of the project, it was filtered out
          // in the filteredProjects object. In this case, we'll treat the
          // expanded project as closed.
          return;
        }

        // If we've loaded a stored expandedProjects state from local storage,
        // we may not have fetched phases yet, so default to an empty array.
        const phases = this.props.projectPhases[projectId] || [];
        const toAdd = sortByDateAndName(phases, 'start_date', 'phase_name');

        sorted.splice(idx + 1, 0, ...toAdd);
      },
    );

    const searchFiltersApplied = !!this.props.filters.length;
    const projectsCount = filteredProjects.length;
    const isLoaded = this.isLoaded();
    const showImportPrompt =
      isLoaded &&
      !this.props.importPromptDismissed &&
      !searchFiltersApplied &&
      projectsCount &&
      Rights.canCreateProject(this.props.currentUser);

    if (showImportPrompt) {
      sorted.push({
        key: 'import-prompt',
        preventSelect: true,
        render: this.renderImportPrompt,
        style: {
          height: 340,
          padding: 20,
          background: 'transparent',
          cursor: 'initial',
        },
      });
    }

    this.setState({ filteredProjects: sorted, filteredProjectsCount });
    this.setPlaceholder(filteredProjects);
  };

  setPlaceholder = (filteredProjects) => {
    const active = filteredProjects.filter((p) => p.active).length;
    const archived = filteredProjects.length - active;

    if (this.state.projectView === 1) {
      this.props.actions.setPlaceholder(
        `${active} ${active === 1 ? 'project' : 'projects'}`,
      );
    }

    if (this.state.projectView === 0) {
      this.props.actions.setPlaceholder(
        `${archived} ${archived === 1 ? 'project' : 'projects'}`,
      );
    }

    if (this.state.projectView === 2) {
      const text = `${active} active, ${archived} archived`;
      this.props.actions.setPlaceholder(text);
    }
  };

  clearSelected = () => {
    this.table.toggleSelectAll();
  };

  isFilteredByMy = () => {
    const {
      location: { query = {} },
    } = this.props;
    return !!query.my;
  };

  fetchAllData = (expandedProjects) => {
    this.props.actions.ensureDepartmentsLoaded();
    this.props.actions.ensureTagsLoaded();
    this.filterProjects(expandedProjects);
    this.fetchAllBudgetUsage();
  };

  fetchAllBudgetUsage = () => {
    const includeArchived = this.state.projectView !== 1;
    if (includeArchived) {
      this.props.actions.ensureBudgetsLoaded(null, {
        forceLoad: true,
        includeArchived: true,
      });
    }
  };

  getExpandedPhasesLocalStorageKey = () => {
    return `${this.props.currentUser.cid}|${this.props.currentUser.account_id}`;
  };

  storeExpandedPhasesInLocalStorage = () => {
    const key = this.getExpandedPhasesLocalStorageKey();
    localStorage.setItem(key, JSON.stringify(this.state.expandedProjects));
  };

  loadExpandedPhasesFromLocalStorage = () => {
    const key = this.getExpandedPhasesLocalStorageKey();
    const res = JSON.parse(localStorage.getItem(key) || '{}');
    this.setState({ expandedProjects: res });
    return res;
  };

  getProjectColumns = () => {
    if (!this.props.isProjectCodesEnabled) {
      return PROJECT_COLUMNS.filter((c) => c.key !== 'projectCode');
    }

    return PROJECT_COLUMNS;
  };

  // ---------------------------------------------------------------------------
  // !!! Modal / Multi Actions -------------------------------------------------
  // ---------------------------------------------------------------------------

  afterBulkUpdate = ({ clearSelection = false } = {}) => {
    if (clearSelection) {
      this.clearSelected();
    }
    this.props.confirmClose();
    this.props.confirmDeleteClose();
    this.setState({ modal: {} });
  };

  bulkUpdate = ({ ids, fields, messageSuffix = 'updated', updateFn }) => {
    const title =
      ids.length > 1
        ? `${ids.length} projects`
        : `"${this.props.projects[ids[0]].project_name}"`;

    let fn = updateFn;

    if (!fn) {
      switch (messageSuffix) {
        case 'deleted':
          fn = this.props.actions.bulkDeleteProjects;
          break;
        default:
          fn = this.props.actions.bulkUpdateProjects;
          break;
      }
    }

    return fn(ids, fields)
      .then(() => {
        this.afterBulkUpdate({ clearSelection: messageSuffix !== 'updated' });
        this.showMessage(`${title} ${messageSuffix}.`);
      })
      .catch((err) => {
        this.afterBulkUpdate();
        this.showMessage((err && err.message) || 'An error occurred.');
      });
  };

  showMessage = (message) => {
    this.props.showSnackbar(message);
  };

  hideModal = (clearSelection) => {
    this.setState({ modal: {} });
    if (clearSelection === true) {
      this.clearSelected();
    }
  };

  archive = (ids) => {
    const itemsTitle =
      ids.length > 1
        ? `${ids.length} projects`
        : this.props.projects[ids[0]].project_name;

    this.props.confirm({
      title: 'Move to archive',
      confirmLabel: 'Move to archive',
      showLoaderOnConfirm: true,
      message: (
        <p>
          <span>Archive </span>
          <strong>{itemsTitle}</strong>
          <span>?</span>
        </p>
      ),
      onConfirm: () => {
        this.bulkUpdate({
          ids,
          fields: { active: 0 },
          messageSuffix: 'archived',
        });
      },
    });
  };

  activate = (ids) => {
    const itemsTitle =
      ids.length > 1
        ? `${ids.length} projects`
        : this.props.projects[ids[0]].project_name;

    this.props.confirm({
      title: 'Move to active',
      confirmLabel: 'Move to active',
      showLoaderOnConfirm: true,
      message: (
        <p>
          <span>Move </span>
          <strong>{itemsTitle}</strong>
          <span> to active?</span>
        </p>
      ),
      onConfirm: () => {
        this.bulkUpdate({
          ids,
          fields: { active: 1 },
          messageSuffix: 'activated',
        });
      },
    });
  };

  delete = async (ids) => {
    const { bulkUpdate, props } = this;
    const { projects, store, confirmDelete } = props;

    showModalConfirmProjectDelete(
      ids,
      projects,
      store,
      confirmDelete,
      bulkUpdate,
    );
  };

  getModal = () => {
    const { type, data } = this.state.modal;
    if (!type) return null;

    switch (type) {
      case 'bulkEdit':
        return (
          <BulkEditProjectsModal
            ids={data}
            onCancel={this.hideModal}
            onUpdate={this.bulkUpdate}
          />
        );
      case 'import':
        return (
          <ImportProjectsModal
            projects={this.props.projects}
            onSave={this.onImport}
            onClose={this.hideModal}
          />
        );
      case 'manage-templates':
        return <ManageTemplatesModal onClose={this.hideModal} />;
      default:
        console.error(`Unknown modal type ${type}`);
        return null;
    }
  };

  checkAndHideSidePanel = (callback) => {
    return (...data) => {
      const successful = this.props.actions.hideSidePanelIfNotPrevented();

      if (successful) {
        callback(...data);
      }
    };
  };

  getMultiSelectActions = (selected) => {
    const actions = [
      {
        label: 'Edit',
        icon: IconPencil,
        action: this.checkAndHideSidePanel((data) => {
          const isEditingSingleItem = data.length === 1;
          if (isEditingSingleItem) {
            const projectId = data[0];
            const project = this.props.projects[projectId];
            if (project) {
              this.showEditProjectModal(project);
            }
            return;
          }

          this.setState({ modal: { type: 'bulkEdit', data } });
        }),
      },
    ];

    this.hasActiveSelected = selected.some((id) => {
      const p = this.props.projects[id];
      return p && p.active;
    });

    if (this.hasActiveSelected) {
      actions.push({
        label: 'Archive',
        icon: IconArchive,
        action: this.checkAndHideSidePanel(this.archive),
      });
    }

    const hasArchivedSelected = selected.some((id) => {
      const p = this.props.projects[id];
      return p && !p.active;
    });

    if (hasArchivedSelected) {
      actions.push({
        label: 'Activate',
        icon: IconBolt,
        action: this.checkAndHideSidePanel(this.activate),
      });
    }

    const hasDeleteAccess = checkSelectedDeleteAccess(
      selected,
      this.props.projects,
      this.props.currentUser,
    );

    if (hasDeleteAccess) {
      actions.push({
        label: 'Delete',
        icon: IconTrash,
        action: this.checkAndHideSidePanel(this.delete),
      });
    }

    return actions;
  };

  showAddProjectModal = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (
      Rights.canViewProjectTemplate(this.props.currentUser) &&
      !this.props.currentUser.prefs?.project_from_scratch
    ) {
      this.props.actions.manageModal({
        visible: true,
        modalType: 'ModalProjectFromTemplate',
      });
      return;
    }

    this.props.actions.showProjectSidePanel();
  };

  showAddPhaseModal = (evt, project) => {
    preventDefaultAndStopPropagation(evt);

    this.props.actions.showPhaseSidePanel({
      projectId: project.project_id,
      afterSave: () => this.expandProject(project.project_id),
    });
  };

  showEditProjectModal = (project) => {
    this.props.showProjectSidePanel({
      projectId: project.project_id,
      hideDelete: true,
      hideTasksTab: false,
    });
  };

  showEditPhaseModal = (phase) => {
    this.props.actions.showPhaseSidePanel({
      phaseId: phase.phase_id,
      projectId: phase.project_id,
    });
  };

  showImportProjectModal = (evt) => {
    evt.preventDefault();
    this.setState({ modal: { type: 'import' } });
  };

  showManageTemplatesModal = (evt) => {
    evt.preventDefault();
    this.setState({ modal: { type: 'manage-templates' } });
  };

  getDateGroupValue = (date, prefix = 'Start') => {
    if (!date) {
      return 'Unscheduled';
    }

    const days = moment().diff(date, 'days');
    if (days <= 0) {
      return `${prefix}s in the future`;
    }

    if (days < 30) {
      return `${prefix}ed in the last 30 days`;
    }

    if (days > 30) {
      return `${prefix}ed more than 30 days ago`;
    }

    return 'Unscheduled';
  };

  // ---------------------------------------------------------------------------
  // !!! Render ----------------------------------------------------------------
  // ---------------------------------------------------------------------------

  renderProjectName = (project) => () => {
    const name = project.project_name || '';
    const isSPVEnabled = featureFlags.isFeatureEnabled(
      FeatureFlag.SingleProjectView,
    );
    const planLink = getPlanLink(isSPVEnabled, name, project.project_id);

    const activeIntegration = this.props.activeIntegrations.find(
      ({ coIntId }) => +project.integrations_co_id === coIntId,
    );

    // we only display sync icon if project is in sync
    const displaySyncIcon =
      activeIntegration &&
      get(
        this.props.syncIconState,
        `${activeIntegration.coIntId}.data.${project.project_id}`,
        false,
      );

    return (
      <styled.ProjectNameContainer isActive={project.active}>
        <Table.Text disabled={!project.active} primary>
          {name}
        </Table.Text>
        {activeIntegration && displaySyncIcon && (
          <SyncIcon
            itemType="project"
            integrationType={activeIntegration.label || activeIntegration.type}
            disabled={!project.active}
          />
        )}

        <Spacer axis="x" />
        <Spacer size={6} />
        <Table.HoverLinks>
          {Rights.canUpdateProject(this.props.currentUser, { project }) && (
            <Table.HoverLinkIcon
              label="Add Phase"
              icon={<IconPlus />}
              className="project desktop"
              disabled={!project.active}
              onClick={
                !project.active
                  ? undefined
                  : (evt) => this.showAddPhaseModal(evt, project)
              }
            />
          )}
          <Table.HoverLinkIcon
            to={planLink}
            icon={<IconProjectPlan />}
            label={t`Plan`}
            className="project"
            onClick={() => {
              if (isSPVEnabled) return;
              trackProjectPlanView(ProjectPlanViewSource.ProjectsList);
            }}
          />
          <Table.HoverLinkIcon
            to={`/report?project=${encodeURIComponent(name)}`}
            icon={<IconReport />}
            className="project desktop"
            label={t`Report`}
          />
          <Table.HoverLinkIcon
            icon={<IconSidebar />}
            onClick={() => this.showEditProjectModal(project)}
            className="project desktop"
            label={t`Open side panel`}
          />
        </Table.HoverLinks>
      </styled.ProjectNameContainer>
    );
  };

  renderPhaseExpand = (project) => () => {
    return (
      <PhaseExpand
        project={project}
        phases={this.props.projectPhases[project.project_id]}
        isOpen={this.state.expandedProjects[project.project_id]}
        onExpand={this.toggleProjectExpanded}
      />
    );
  };

  renderClientName =
    ({ clientId, isActive }) =>
    () => {
      const clientName = get(
        this.props,
        `clients.clients.${clientId}.client_name`,
      );

      if (!clientName) {
        return <Table.EmptyCell disabled={!isActive} />;
      }

      return (
        <Table.HoverLink
          disabled={!isActive}
          to={
            clientName &&
            appendFilter({ client: clientName }, this.props.location)
          }
        >
          {clientName}
        </Table.HoverLink>
      );
    };

  renderProjectCode =
    ({ project_id, active }) =>
    () => {
      const projectCode = get(
        this.props,
        `projects.${project_id}.project_code`,
      );

      if (!projectCode) {
        return <Table.EmptyCell disabled={!active} />;
      }

      return <Table.Text disabled={!active}>{projectCode}</Table.Text>;
    };

  renderTags = (p) => () => {
    const tags = sortBy(p.tags, (t) => t.toLowerCase()).map((t) => ({
      to: appendFilter({ projectTag: t }, this.props.location),
      color: this.props.tagByLabel[t]?.color,
      label: t,
    }));
    return <Table.Tags tags={tags} shouldResize appearance="dynamic" />;
  };

  renderStatusTag = (p) => () => {
    return <EntityStatusTag entity={p} location={this.props.location} />;
  };

  renderNotes = (p) => () => {
    const notes = p.description || p.notes || '';
    if (!notes) {
      return null;
    }
    return (
      <TextTooltip
        key="more-tags"
        placement="top"
        content={
          <styled.Notes onClick={stopPropagation}>
            <Linkify
              options={{
                attributes: {
                  contentEditable: false,
                  tabIndex: -1,
                },
              }}
            >
              {getDisplayNotesExcerpt({ notes, max: 100 })}
            </Linkify>
          </styled.Notes>
        }
      >
        <span style={{ height: 16, outline: 'none' }}>
          <IconNotesLarge />
        </span>
      </TextTooltip>
    );
  };

  renderBudget = (projectOrPhase) => () => {
    const project = projectOrPhase.phase_id
      ? this.props.projects[projectOrPhase.project_id]
      : projectOrPhase;

    if (!project) return null;

    const phase = projectOrPhase.phase_id ? projectOrPhase : null;

    if (phase && project?.budget_priority !== BudgetPriority.Phase) return null;

    return (
      <ProjectBudgetBar
        project={project}
        phase={phase}
        budgets={this.props.budgets}
      />
    );
  };

  renderNonBillableTag = (entity) => () => {
    if (!entity.non_billable) return null;
    const tags = [
      {
        label: t`Non-billable`,
        to: appendFilter(
          { projectStatus: 'Non-billable' },
          this.props.location,
        ),
      },
    ];
    return (
      <Table.Tags
        tags={tags}
        ellipsis={false}
        shouldResize
        appearance="dynamic"
      />
    );
  };

  renderStart = (p) => () => {
    const start = formatDate(p.start_date);
    return (
      <Table.Text
        disabled={!p.active}
        style={{
          overflow: 'visible',
        }}
      >
        {start}
      </Table.Text>
    );
  };

  renderEnd = (p) => {
    const start = formatDate(p.start_date);
    const end = formatDate(p.end_date);

    if (end && start !== end) {
      const hyphenColumnSeparator = (
        <Table.Text
          disabled={!p.active}
          style={{
            position: 'absolute',
            left: -8,
          }}
        >
          -
        </Table.Text>
      );

      return (
        <>
          {start && hyphenColumnSeparator}
          <Table.Text disabled={!p.active} ellipsis={false}>
            {end}
          </Table.Text>
        </>
      );
    }

    return '';
  };

  renderPM =
    ({ isActive, accountId }) =>
    () => {
      const pm = get(this.props, `accounts.accounts.${accountId}`);
      if (!pm) {
        return <Table.EmptyCell style={{ marginLeft: 8 }} />;
      }

      return (
        <TextTooltip content={pm.name} className="hint">
          <div style={{ outline: 'none' }}>
            <PersonAvatar
              readOnly
              accountId={pm.account_id}
              size="xs"
              tooltip={false}
              disabled={!isActive}
            />
          </div>
        </TextTooltip>
      );
    };

  renderActions = () => {
    const { filteredProjectsCount, projectView, sort } = this.state;
    const { currentUser } = this.props;

    return (
      <>
        <ProjectQuickFilters
          activeCount={filteredProjectsCount.active}
          archivedCount={filteredProjectsCount.archived}
          canCreate={Rights.canCreateProject(currentUser)}
          currentCheckboxValue={this.isFilteredByMy()}
          currentFilterValue={projectView.toString()}
          onCheckboxChange={this.changeByMy}
          onClickCreate={this.showAddProjectModal}
        />

        <Table.ActionsGroup>
          <Table.Sort
            value={sort}
            columns={this.getProjectColumns()}
            onChange={this.onChangeSort}
          />
        </Table.ActionsGroup>
      </>
    );
  };

  renderImportPrompt = () => {
    return (
      <ImportPrompt
        className="projects"
        img={ImgImportProjects}
        label="Import your projects"
        onClick={this.showImportProjectModal}
        onClose={this.props.dismissPrompt}
      />
    );
  };

  render() {
    const hasSearchFiltersApplied = !!this.props.filters.length;
    const noProjects = !this.state.filteredProjects.length;
    const isLoaded = this.isLoaded();
    const canCreateProjects = Rights.canCreateProject(this.props.currentUser);

    const canEditSomeProjects =
      this.state.filteredProjects.filter((project) => project.canEdit).length >
      0;

    return (
      <styled.ProjectsSection aria-label={t`Manage Projects Page`}>
        {canCreateProjects && (
          <ProjectQuickActions
            showManageTemplatesModal={this.showManageTemplatesModal}
            showImportProjectModal={this.showImportProjectModal}
          />
        )}
        <PageBody>
          {!isLoaded ? (
            <Loader />
          ) : (
            <Table
              ref={(el) => {
                this.table = el;
              }}
              selectable={canCreateProjects && canEditSomeProjects}
              columns={this.getProjectColumns()}
              rows={this.state.filteredProjects}
              rowRenderer={this.getRowProps}
              sortBy={this.state.sort.type}
              sortOrder={this.state.sort.dir}
              nonGroupKeys={['project', 'budget']}
              printMode={this.props.printMode}
              onSortByChange={this.changeSortType}
              onSortOrderChange={this.changeSortDir}
              renderActions={this.renderActions}
              getMultiSelectActions={this.getMultiSelectActions}
            />
          )}
          {isLoaded && noProjects && (
            <ErrorNoProjects
              canCreateProjects={canCreateProjects}
              hasSearchFiltersApplied={hasSearchFiltersApplied}
              onClickAddProject={this.showAddProjectModal}
            />
          )}
        </PageBody>
        {this.getModal()}
      </styled.ProjectsSection>
    );
  }
}

const mapStateToProps = (state) => ({
  budgets: state.budgets,
  projects: getProjectsMap(state),
  tagByLabel: getProjectTagByLabel(state),
  accounts: state.accounts,
  clients: state.clients,
  searchFilteredProjects: getSearchFilteredProjects(state),
  currentUser: getUser(state),
  hasBeenLoaded:
    getHaveProjectsWithContextBeenLoaded(state) && getTagsLoaded(state),
  printMode: state.app.printMode,
  activeIntegrations: getActiveIntegrations(state),
  archivedProjectsLoaded: state.projects.archivedProjectsLoaded,
  importPromptDismissed:
    +state.currentUser.account_tid !== 1 ||
    state.legacyOnboarding.prompts.includes(PROJECT_IMPORT_PromptId),
  projectPhases: getProjectPhases(state),
  syncIconState: state.projects.syncIcon,
  filters: getActiveFilters(state),
  isProjectCodesEnabled: selectIsProjectCodesEnabled(state),
});
const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        setPlaceholder,
        ensureSearchContextLoaded,
        ensureDepartmentsLoaded,
        updateUserPref,
        updateMultiUserPrefs,
        manageModal,
        showPhaseSidePanel,
        showProjectSidePanel,
        hideSidePanelIfNotPrevented,
      },
      dispatch,
    ),
    ...bindActionCreators(projectActions, dispatch),
    ...bindActionCreators(tagsActions, dispatch),
    ...bindActionCreators(phaseActions, dispatch),
    ...bindActionCreators(integrationsCommonActions, dispatch),
    ...bindActionCreators(budgetActions, dispatch),
  },
  dismissPrompt: () => dispatch(updatePrompts(PROJECT_IMPORT_PromptId)),
});

const Component = compose(
  withRouter,
  withConfirm,
  withModalConfirmDelete,
  withSnackbar,
)(Projects);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(sidePanelConnect(provideStoreHoc(Component)));
