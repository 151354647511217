import { t } from '@lingui/macro';

import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import { getCanCurrentUserSeeFeesForProject } from '@float/common/lib/acl/getCanCurrentUserSeeFees';
import { getDoesBudgetUseFees } from '@float/common/lib/acl/getDoesBudgetUseFees';
import { formatAmount } from '@float/common/lib/budget/helpers/formatAmount';
import { formatCurrency } from '@float/common/lib/currency';
import { config } from '@float/libs/config';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';
import type { AccordionTableCellValue } from '@float/ui/deprecated/AccordionTable/types';

import {
  getBudgetDataObject,
  getFeeOrHoursAsPercentageOfBudget,
} from './budget';
import { getCost } from './getCost';
import { getEntityProfitMargin } from './getEntityProfitMargin';
import { getRangeBar } from './getRangeBar';
import type { ProjectsOverviewTableContext, ReportsProject } from '../types';

export function getProjectTableRowData(
  ctx: ProjectsOverviewTableContext,
  projects: ProjectsOverviewTableContext['projects'],
  project: ReportsProject,
) {
  const canSeeBudget = getCanCurrentUserSeeBudgets(ctx.user);
  const budgetUsesFees = getDoesBudgetUseFees(project.budget_type);

  const isBillableProject = !projects[project.id].non_billable;
  const canSeeFees = getCanCurrentUserSeeFeesForProject(
    ctx.user,
    project.budget_type,
  );

  const managerId = projects[project.id].project_manager;
  const manager = ctx.accounts[managerId];

  const projectLink = `project-report-link::${project.name}`;
  const projectCode = `project-code::${project.project_code || '-'}`;
  const projectClient =
    projects[project.id].client_name === 'No Client'
      ? t`No Client`
      : `client::${projects[project.id].client_name}`;
  const projectManagerAvatar = `project-report-manager-avatar::${
    projects[project.id].project_manager
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  }::${manager?.name}`;
  const projectBudget = canSeeBudget ? getBudgetDataObject(project) : '';
  const projectFees = canSeeFees
    ? formatAmount(
        project.budget_type,
        isBillableProject
          ? project.scheduled.billableFee
          : project.scheduled.fee,
        {
          currency: ctx.currency,
        },
      )
    : '';
  const projectScheduledHours = project.scheduled.hours;
  const projectBudgetUsagePercent = getFeeOrHoursAsPercentageOfBudget(project, {
    percentageMode: ctx.projectsPercentageMode,
    useBillable: isBillableProject,
    useFees: budgetUsesFees,
  });

  const projectScheduledCell = canSeeFees
    ? {
        // As above, we should probably be leveraging `tableFormatters`, rather than
        // using the display property
        // Worth revisiting when we refactor Reports
        display: `${projectFees} / ${projectScheduledHours}h`,
        val: projectScheduledHours,
        sortVal: projectScheduledHours,
      }
    : projectScheduledHours;
  const rangeBar = getRangeBar(
    ctx.projectsPercentageMode,
    project,
    isBillableProject,
  );

  const data: AccordionTableCellValue[] = [
    projectLink,
    '',
    projectClient,
    '',
    projectManagerAvatar,
    '',
    projectBudget,
    '',
    projectScheduledCell,
    '',
    projectBudgetUsagePercent,
    // @TODO(PI-91)
    // @ts-expect-error - We need to refactor the types to account for the RangeBar types
    rangeBar,
  ];

  if (ctx.timeTrackingEnabled) {
    const projectLoggedFee = canSeeFees
      ? formatAmount(project.budget_type, project.logged.fee, {
          currency: ctx.currency,
        })
      : '';
    const projectLoggedHours = project.logged.hours;

    // Add the logged columns if the user has time tracking
    const projectLoggedHoursCell = canSeeFees
      ? {
          // As above, we should probably be leveraging `tableFormatters`, rather than
          // using the display property
          // Worth revisiting when we refactor Reports
          display: `${projectLoggedFee} / ${projectLoggedHours}h`,
          val: projectLoggedHours,
          sortVal: projectLoggedHours,
        }
      : projectLoggedHours;

    data.splice(10, 0, projectLoggedHoursCell, '');
  }

  if (ctx.isProjectCodesEnabled) {
    data.splice(2, 0, projectCode, '');
  }

  const canSeeCosts =
    ctx.hasCostsAccess &&
    ctx.mode !== ChartComparisonModes.COMPARE &&
    featureFlags.isFeatureEnabled(FeatureFlag.CostRate);

  if (canSeeCosts) {
    const cost = formatCurrency(
      getCost(ctx.mode, project.cost),
      config.locale,
      ctx.currency,
    );
    data.push(cost);
  }

  const canSeeProfitMargin =
    canSeeCosts &&
    ctx.hasBudgetAccess &&
    featureFlags.isFeatureEnabled(FeatureFlag.CostRatesMultiProjectReport);

  if (canSeeProfitMargin) {
    const { profit, percentage } = getEntityProfitMargin(
      project,
      ctx.mode,
      ctx.currency,
    );
    data.push(profit);
    data.push(percentage);
  }

  return data;
}
