export enum ScheduleViewType {
  People = 'people',
  Projects = 'projects',
  SingleProject = 'singleProject',
}

export enum RowType {
  Project = 'project',
  Person = 'person',
}

export const SIDE_CELL_WIDTH = 260;
export const SIDE_CELL_WIDTH_SMALL = 64;
export const SINGLE_PROJECT_SIDE_CELL_WIDTH = 470;
