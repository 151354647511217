import { t } from '@lingui/macro';

import { config } from '@float/libs/config';

export const download = (uri: string, name: string) => {
  if (!config.isInternetExplorer) {
    const link = document.createElement('a');

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(uri);
  }
};

export function getUpdateWarningText(warningText?: string) {
  if (warningText) {
    return warningText;
  }

  return t`This will update the attributes based on your CSV.`;
}
