import {
  FloatStorageClientAdapter,
  FloatStorageKey,
} from '@float/libs/storage';

export class WebStorageClientAdapter implements FloatStorageClientAdapter {
  // We need to pass the localStorage object to the constructor
  // eslint-disable-next-line no-restricted-properties
  constructor(private storage: Storage = window.localStorage) {}

  async getItem(key: FloatStorageKey): Promise<string | null> {
    return this.storage.getItem(key);
  }

  async setItem(key: FloatStorageKey, value: string): Promise<void> {
    return this.storage.setItem(key, value);
  }

  async removeItem(key: FloatStorageKey): Promise<void> {
    return this.storage.removeItem(key);
  }

  async clear(): Promise<void> {
    return this.storage.clear();
  }
}
