import React from 'react';
import { t, Trans } from '@lingui/macro';

import {
  formatAmount,
  getExpandedInfo,
  getPercentage,
  toCents,
} from '@float/common/lib/budget';
import { getBudgetTypeText } from '@float/common/lib/budget/helpers/getBudgetTypeText';
import {
  BudgetPriority,
  BudgetType,
  ProjectBudgetUsage,
} from '@float/types/project';
import { MODES } from '@float/ui/deprecated/Chart';

import {
  ItemHeader,
  ItemLabel,
  ItemSubLabel,
  ReportsTotalsCard,
  ReportsTotalsContainer,
  ReportsTotalsItem,
} from '../../styles';

export type ProjectSummaryInfo = {
  isMoneyBudgetType: boolean;
  budget_type: BudgetType;
  non_billable: boolean;
  budget_priority: BudgetPriority;
};

export type TotalsData = {
  logged: {
    nonbillable: number;
    billable: number;
    feeCents: number;
  };
  combined: {
    nonbillable: number;
    billable: number;
    feeCents: number;
  };
  nonbillable: number;
  billable: number;
  feeCents: number;
};

export type SummaryMode = (typeof MODES)[keyof typeof MODES];

export type SummaryBarProps = {
  project: ProjectSummaryInfo;
  projectBudget: ProjectBudgetUsage;
  totals: TotalsData;
  mode: SummaryMode;
  changeMode: (mode: SummaryMode) => void;
};

const getHasBudgetSupport = (budget_type: BudgetType) =>
  budget_type === BudgetType.TotalHours || budget_type === BudgetType.TotalFee;

function LoggedSummaryBar({ project, projectBudget, totals }: SummaryBarProps) {
  const { isMoneyBudgetType, budget_type, non_billable } = project;

  const budget_total = projectBudget?.budget_total || 0;

  // Budget used needs to react to the active filters
  const budget_used = totals.logged.feeCents ? totals.logged.feeCents / 100 : 0;

  const billable = non_billable
    ? totals.logged.nonbillable
    : totals.logged.billable;

  // Budget remaining needs to react to the active filters
  const budget_remaining = isMoneyBudgetType
    ? budget_total - budget_used
    : budget_total - billable;

  const label = non_billable ? t`Non-billable` : t`Logged`;
  const hasBudgetSupport = getHasBudgetSupport(budget_type);
  const hasBudgetTotal = budget_total > 0;

  const perc = hasBudgetSupport
    ? getPercentage(budget_used, budget_total)
    : null;
  const { overBudget = false, percentage: pct_remaining = 0 } =
    !hasBudgetSupport
      ? {}
      : getExpandedInfo({
          budget_total,
          budget_remaining,
        });

  const budgetTypeText =
    budget_type === BudgetType.Disabled
      ? ''
      : getBudgetTypeText({
          budget_type: project.budget_type,
          budget_priority: project.budget_priority,
        });

  const budgetValue = hasBudgetSupport
    ? formatAmount(budget_type, budget_total)
    : t`No budget`;

  return (
    <ReportsTotalsContainer>
      <ReportsTotalsCard borderRight flexGrow={1}>
        <ReportsTotalsItem width="200px">
          <ItemLabel>
            <Trans>Project budget</Trans>
          </ItemLabel>
          <ItemSubLabel>{budgetTypeText}</ItemSubLabel>
          <ItemHeader data-testid="project-budget-total">
            {budgetValue}
          </ItemHeader>
        </ReportsTotalsItem>
      </ReportsTotalsCard>

      <ReportsTotalsCard borderRight={hasBudgetSupport} flexGrow={1}>
        {isMoneyBudgetType ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>{label}</ItemLabel>
            {perc ? <ItemSubLabel>{perc}%</ItemSubLabel> : null}
            <ItemHeader data-testid="project-budget-used">
              {formatAmount(budget_type, budget_used)}
              <ItemSubLabel>{formatAmount(1, billable)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>{label}</ItemLabel>
            {perc ? <ItemSubLabel>{perc}%</ItemSubLabel> : null}
            <ItemHeader data-testid="project-budget-used">
              {formatAmount(1, billable)}
            </ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      {hasBudgetSupport && (
        <ReportsTotalsCard flexGrow={1}>
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Budget remaining</Trans>
            </ItemLabel>
            {hasBudgetTotal && (
              <ItemSubLabel>
                {overBudget ? '-' : ''}
                {pct_remaining}%
              </ItemSubLabel>
            )}
            <ItemHeader
              data-testid="project-budget-remaining"
              color={overBudget ? 'red' : ''}
            >
              {formatAmount(budget_type, budget_remaining)}
            </ItemHeader>
          </ReportsTotalsItem>
        </ReportsTotalsCard>
      )}
    </ReportsTotalsContainer>
  );
}

function ScheduledSummaryBar({
  project,
  projectBudget,
  totals,
}: SummaryBarProps) {
  const { isMoneyBudgetType, budget_type, non_billable } = project;

  const budget_total = projectBudget?.budget_total || 0;
  // Budget used needs to react to the active filters
  const budget_used = totals.feeCents ? totals.feeCents / 100 : 0;

  const billable = non_billable ? totals.nonbillable : totals.billable;

  // Budget remaining needs to react to the active filters
  const budget_remaining = isMoneyBudgetType
    ? budget_total - budget_used
    : budget_total - billable;

  const { overBudget, percentage: pct_remaining } = getExpandedInfo({
    budget_total,
    budget_remaining,
  });

  const hasBudgetSupport = getHasBudgetSupport(budget_type);
  const hasBudgetTotal = budget_total > 0;

  const budgetTypeText =
    budget_type === BudgetType.Disabled
      ? ''
      : getBudgetTypeText({
          budget_type: project.budget_type,
          budget_priority: project.budget_priority,
        });

  const budgetValue = hasBudgetSupport
    ? formatAmount(budget_type, budget_total)
    : t`No budget`;

  const label = non_billable ? t`Non-billable` : t`Billable`;

  return (
    <ReportsTotalsContainer>
      <ReportsTotalsCard borderRight flexGrow={1}>
        <ReportsTotalsItem width="200px">
          <ItemLabel>
            <Trans>Project budget</Trans>
          </ItemLabel>
          <ItemSubLabel>{budgetTypeText}</ItemSubLabel>
          <ItemHeader data-testid="project-budget-total">
            {budgetValue}
          </ItemHeader>
        </ReportsTotalsItem>
      </ReportsTotalsCard>

      <ReportsTotalsCard borderRight={hasBudgetSupport} flexGrow={1}>
        {isMoneyBudgetType ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              {label} <Trans>fee</Trans>
            </ItemLabel>
            <ItemHeader data-testid="project-budget-used">
              {formatAmount(budget_type, budget_used)}
              <ItemSubLabel>{formatAmount(1, billable)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>{label}</ItemLabel>
            <ItemHeader data-testid="project-budget-used">
              {formatAmount(1, billable)}
            </ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      {hasBudgetSupport && (
        <ReportsTotalsCard flexGrow={1}>
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Budget remaining</Trans>
            </ItemLabel>
            {hasBudgetTotal && (
              <ItemSubLabel>
                {overBudget ? '-' : ''}
                {pct_remaining}%
              </ItemSubLabel>
            )}
            <ItemHeader
              data-testid="project-budget-remaining"
              color={overBudget ? 'red' : ''}
            >
              {formatAmount(budget_type, budget_remaining)}
            </ItemHeader>
          </ReportsTotalsItem>
        </ReportsTotalsCard>
      )}
    </ReportsTotalsContainer>
  );
}

function CombinedSummaryBar({
  project,
  projectBudget,
  totals,
  changeMode,
}: SummaryBarProps) {
  const { isMoneyBudgetType, budget_type, non_billable } = project;

  const budget_total = projectBudget?.budget_total || 0;

  const logged_budget_used = totals.logged.feeCents / 100;
  const logged_hours = non_billable
    ? totals.logged.nonbillable
    : totals.logged.billable;

  const combined_budget_used = totals.combined.feeCents / 100;
  const combined_hours = non_billable
    ? totals.combined.nonbillable
    : totals.combined.billable;
  const combined_budget_remaining =
    (toCents(budget_total) - toCents(combined_budget_used)) / 100;

  const future_budget_used = +combined_budget_used - +logged_budget_used;
  const future_hours = +combined_hours - +logged_hours;

  const { percentageUsed, overBudget } = getExpandedInfo({
    budget_total,
    budget_remaining: combined_budget_remaining,
    budget_used: combined_budget_used,
  });

  const hasBudgetSupport = getHasBudgetSupport(budget_type);

  const budgetTypeText =
    budget_type === BudgetType.Disabled
      ? ''
      : getBudgetTypeText({
          budget_type: project.budget_type,
          budget_priority: project.budget_priority,
        });

  const budgetValue = hasBudgetSupport
    ? formatAmount(budget_type, budget_total)
    : t`No budget`;

  const combinedBudgetRemaining = formatAmount(
    isMoneyBudgetType ? budget_type : BudgetType.TotalHours,
    Math.abs(combined_budget_remaining),
  );

  const combinedBudgetRemainingText = overBudget
    ? t`${combinedBudgetRemaining} over.`
    : t`${combinedBudgetRemaining} remaining.`;

  return (
    <ReportsTotalsContainer>
      <ReportsTotalsCard borderRight flexGrow={1}>
        <ReportsTotalsItem width="200px">
          <ItemLabel>
            <Trans>Project budget</Trans>
          </ItemLabel>
          <ItemSubLabel>{budgetTypeText}</ItemSubLabel>
          <ItemHeader>{budgetValue}</ItemHeader>
        </ReportsTotalsItem>
      </ReportsTotalsCard>

      <ReportsTotalsCard borderRight flexGrow={1}>
        {isMoneyBudgetType ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Past logged</Trans>
            </ItemLabel>
            <ItemHeader>
              {formatAmount(budget_type, logged_budget_used)}
              <ItemSubLabel>{formatAmount(1, logged_hours)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Past logged</Trans>
            </ItemLabel>
            <ItemHeader>{formatAmount(1, logged_hours)}</ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      <ReportsTotalsCard borderRight flexGrow={1}>
        {isMoneyBudgetType ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Future scheduled</Trans>
            </ItemLabel>
            <ItemHeader>
              {!isNaN(future_budget_used) &&
                formatAmount(budget_type, future_budget_used)}
              <ItemSubLabel>
                {!isNaN(future_hours) && formatAmount(1, future_hours)}
              </ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Future scheduled</Trans>
            </ItemLabel>
            <ItemHeader>
              {!isNaN(future_hours) && formatAmount(1, future_hours)}
            </ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      <ReportsTotalsCard flexGrow={1}>
        <ReportsTotalsItem width="200px">
          <ItemLabel>
            <Trans>Total</Trans>
          </ItemLabel>
          {hasBudgetSupport &&
            percentageUsed &&
            percentageUsed !== 'NaN' &&
            percentageUsed !== 'Infinity' && (
              <ItemSubLabel>{percentageUsed}%</ItemSubLabel>
            )}
          {isMoneyBudgetType ? (
            <ItemHeader>
              {formatAmount(budget_type, combined_budget_used)}
              <ItemSubLabel>{formatAmount(1, combined_hours)}</ItemSubLabel>
            </ItemHeader>
          ) : (
            <ItemHeader>{formatAmount(1, combined_hours)}</ItemHeader>
          )}
          {overBudget && ( // TODO: confirm that this needs to be shown only when over budget
            <ItemSubLabel noMargin>
              <Trans>Projecting</Trans>{' '}
              <span className={overBudget ? 'red' : ''}>
                {combinedBudgetRemainingText}
              </span>
              <span
                className="see-why"
                onClick={() => changeMode(MODES.COMPARE)}
              >
                See why
              </span>
            </ItemSubLabel>
          )}
        </ReportsTotalsItem>
      </ReportsTotalsCard>
    </ReportsTotalsContainer>
  );
}

function CompareSummaryBar({ project, totals }: SummaryBarProps) {
  const { isMoneyBudgetType, budget_type, non_billable } = project;

  const scheduled_budget_used = totals.feeCents / 100;
  const scheduled_hours = non_billable ? totals.nonbillable : totals.billable;

  const logged_budget_used = totals.logged.feeCents / 100;
  const logged_hours = non_billable
    ? totals.logged.nonbillable
    : totals.logged.billable;

  const difference_budget_used =
    (toCents(scheduled_budget_used) - toCents(logged_budget_used)) / 100;
  const difference_hours = scheduled_hours - logged_hours;

  const difference = {
    budget_used: difference_budget_used,
    hours: difference_hours,
    is_over: difference_hours < 0,
  };

  return (
    <ReportsTotalsContainer>
      <ReportsTotalsCard borderRight flexGrow={1}>
        {isMoneyBudgetType ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Logged</Trans>
            </ItemLabel>
            <ItemHeader>
              {formatAmount(budget_type, logged_budget_used)}
              <ItemSubLabel>{formatAmount(1, logged_hours)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Logged</Trans>
            </ItemLabel>
            <ItemHeader>{formatAmount(1, logged_hours)}</ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      <ReportsTotalsCard borderRight flexGrow={1}>
        {isMoneyBudgetType ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Scheduled</Trans>
            </ItemLabel>
            <ItemHeader>
              {formatAmount(budget_type, scheduled_budget_used)}
              <ItemSubLabel>{formatAmount(1, scheduled_hours)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Scheduled</Trans>
            </ItemLabel>
            <ItemHeader>{formatAmount(1, scheduled_hours)}</ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      <ReportsTotalsCard flexGrow={1}>
        {isMoneyBudgetType ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Difference</Trans>
            </ItemLabel>
            <ItemHeader color={difference.is_over ? 'red' : undefined}>
              {formatAmount(budget_type, difference.budget_used)}
              <ItemSubLabel>{formatAmount(1, difference.hours)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Difference</Trans>
            </ItemLabel>
            <ItemHeader color={difference.is_over ? 'red' : undefined}>
              {formatAmount(1, difference.hours)}
            </ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>
    </ReportsTotalsContainer>
  );
}

const SummaryBarLegacy = (props: SummaryBarProps) => {
  if (props.mode === MODES.COMBINED) {
    return <CombinedSummaryBar {...props} />;
  }

  if (props.mode === MODES.SCHEDULED) {
    return <ScheduledSummaryBar {...props} />;
  }

  if (props.mode === MODES.LOGGED) {
    return <LoggedSummaryBar {...props} />;
  }

  if (props.mode === MODES.COMPARE) {
    return <CompareSummaryBar {...props} />;
  }

  return null;
};

export default SummaryBarLegacy;
