import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';

import { getBillRateLabel } from '@float/common/lib/rates/rates';
import Radio from '@float/ui/deprecated/Radio/Radio';

import { Warning } from './Warning';

import * as styles from './RateToUseAfterPersonSwitch.styles.css';

type Props = {
  newPersonName?: string;
  projectName: string;
  shouldUseNewRate: boolean;
  onChange: (value: boolean) => void;
};

export function RateToUseAfterPersonSwitch(props: Props) {
  const { projectName, shouldUseNewRate, onChange } = props;
  const newPersonName = props.newPersonName || 'new person';

  const billRateLabel = getBillRateLabel().toLowerCase();

  const options = useMemo(
    () => [
      {
        value: false,
        label: t`Keep the same ${billRateLabel} for ${projectName} and its phases`,
      },
      {
        value: true,
        label: t`Use ${newPersonName}'s ${billRateLabel} for ${projectName} and its phases`,
      },
    ],
    [billRateLabel, newPersonName, projectName],
  );

  return (
    <Warning>
      <Warning.Title>
        <Trans>
          The person you selected has a different {billRateLabel}. Which rate do
          you want to use?
        </Trans>
      </Warning.Title>
      <Warning.Body>
        <Radio
          className={styles.radio}
          iconType="essentials"
          value={shouldUseNewRate}
          options={options}
          onChange={onChange}
        />
      </Warning.Body>
    </Warning>
  );
}
