import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import classNames from 'classnames';

import { useProjectColor } from '@float/common/components/Schedule/hooks/useProjectColor';
import {
  getIsCompactDensity,
  getProjectRowEntityHeight,
  getProjectRowEntityTop,
} from '@float/common/components/Schedule/Window/projectRow.helpers';
import { getUserCanSeeProjectBudget } from '@float/common/lib/acl/getUserCanSeeProjectBudget';
import { getProjectBudgetStatusText, toNum } from '@float/common/lib/budget';
import { getUser } from '@float/common/selectors/currentUser';
import { getProjectById } from '@float/common/selectors/projects';
import {
  useAppSelectorStrict,
  useAppSelectorWithParams,
} from '@float/common/store';
import { selectPhaseBudget } from '@float/common/store/budgets/budgets.selectors';
import { useCombinedRefs } from '@float/libs/hooks/useCombinedRefs';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { BudgetType, CellItem, CurrentUser, Project } from '@float/types';
import { BudgetPhase } from '@float/types/budget';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import { ScheduleActions } from '../types';
import { TOOLTIP_DELAY, usePinnedText } from './helpers';

import * as styles from './PhaseItem.css';
import {
  projectItemHoverTooltip,
  projectItemHoverTooltipBudget,
  projectItemHoverTooltipContent,
  projectItemHoverTooltipHeader,
} from './styles.css';

export const MARGIN = 4;

const phaseColorStyles = (bgColor?: string, borderColor?: string) =>
  assignInlineVars({
    [styles.phaseColorVar]: bgColor ?? '',
    [styles.phaseBorderColorVar]: borderColor ?? '',
    // The background color for "project duration" is 'rgba(210, 210, 210, 0.3)'.
    // The phase bar background needs to match this without using an additional
    // alpha channel. And we can't use `transparent` due to the triangular edges.
    [styles.phaseTransparentBgColorVar]: '#F2F2F2',
  });

const textStyles = (props: { top?: number; color?: string }) =>
  assignInlineVars({
    [styles.textPinnedTopVar]: props.top ? `${props.top}px` : '',
    color: props.color ?? '',
  });

type Props = {
  actions: ScheduleActions;
  dayWidth: number;
  hourHeight: number;
  item: CellItem<'phase'>;
  scrollWrapperRef: React.MutableRefObject<HTMLElement | undefined>;
  wrapperRef: React.RefObject<HTMLDivElement>;
  boundaryRef: React.MutableRefObject<Element | Element[] | undefined>;
  tooltipDelay?: number;
} & React.DelHTMLAttributes<HTMLDivElement>;

const PhaseItem = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    item,
    dayWidth,
    hourHeight,
    actions,
    scrollWrapperRef,
    wrapperRef,
    boundaryRef,
    tooltipDelay,
    ...rest
  } = props;
  const boxRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const { x = 0, y = 0, w, isStart, isEnd } = item;

  const project = useAppSelectorWithParams(
    getProjectById,
    item.entity.project_id,
  );

  const left = x * dayWidth + (isStart ? MARGIN : 1);
  const top = getProjectRowEntityTop({ hourHeight, y });
  const height = getProjectRowEntityHeight({ hourHeight, margin: MARGIN * 2 });
  const isCompactDensity = getIsCompactDensity(hourHeight);
  const width = item.entity.visibleLength * dayWidth - MARGIN * 2 + 1;

  const colors = useProjectColor(project, item.entity);
  const { backgroundColor, borderColor, textColor } = colors;
  const pinned = usePinnedText({
    item,
    scrollWrapperRef,
    wrapperRef,
    boxRef,
    textRef,
  });

  const combinedRefs = useCombinedRefs([ref, boxRef]);

  if (!project) return null;
  if (!isStart) return null;

  const dragVersion = (
    <div
      className={styles.box({ status: item.entity.status })}
      style={{
        width,
        height,
        ...phaseColorStyles(backgroundColor, borderColor),
      }}
    >
      {(dayWidth !== 27 || w > 1) && (
        <div className={styles.text} style={textStyles({ color: textColor })}>
          {item.entity.phase_name}
        </div>
      )}
    </div>
  );

  const textTop = isCompactDensity && pinned ? top - 2.5 : top;
  const RawTextElement = (
    <div
      className={styles.text}
      style={textStyles({ top: textTop, color: textColor })}
      data-pinned={pinned}
      ref={textRef}
    >
      {item.entity.phase_name}
      {!item.entity.active ? ' (Archived)' : null}
    </div>
  );

  const wrapperEl = props.wrapperRef.current;

  const TextElement =
    pinned && wrapperEl
      ? createPortal(
          RawTextElement,
          wrapperEl.parentElement!.getElementsByClassName('pin-target')[0],
        )
      : RawTextElement;

  return (
    <div
      {...rest}
      className={classNames(
        styles.box({ status: item.entity.status }),
        rest.className,
      )}
      ref={combinedRefs}
      style={{
        left,
        top,
        width,
        height,
        ...phaseColorStyles(backgroundColor, borderColor),
      }}
      data-testid="phase-item"
      onMouseDown={(event) => {
        event.stopPropagation();
        if (event.button !== 0 || event.shiftKey) return;
        if (!boxRef.current) return;

        const { x: offsetX, y: offsetY } =
          boxRef.current.getBoundingClientRect();

        actions.setDragItem({
          item,
          element: dragVersion,
          offsetX,
          offsetY,
          clientX: event.clientX,
          clientY: event.clientY,
        });
      }}
    >
      {actions.isItemEditable(item) && isStart && (
        <div
          className={styles.leftResize}
          onMouseDown={(e) => {
            if (e.button !== 0) return prevent(e);
            e.stopPropagation();
            actions.onItemResizeStart(item, 'L');
          }}
        />
      )}
      {/* @entity.length */}
      {(dayWidth !== 27 || item.entity.length > 1) && isStart && TextElement}
      {actions.isItemEditable(item) && isEnd && (
        <div
          className={styles.rightResize}
          onMouseDown={(e) => {
            if (e.button !== 0) return prevent(e);
            e.stopPropagation();
            actions.onItemResizeStart(item, 'R');
          }}
        />
      )}
    </div>
  );
});

const formatDate = (date: unknown) => moment(date).format('DD MMM YYYY');

const isOverBudget = (project: Project, phaseBudgetData: BudgetPhase) => {
  if (
    project.budget_type !== BudgetType.TotalHours &&
    project.budget_type !== BudgetType.TotalFee
  ) {
    return false;
  }

  let remaining = phaseBudgetData.budget_remaining;
  if (typeof remaining === 'string') remaining = toNum(remaining);

  return Boolean(remaining && remaining < 0);
};

function getBudgetInfo(
  project: Project,
  phaseBudgetData: BudgetPhase | null,
  currentUser: CurrentUser,
) {
  if (!phaseBudgetData?.budget_remaining) return null;

  const overBudget = isOverBudget(project, phaseBudgetData);

  const canSeeProjectBudget = getUserCanSeeProjectBudget(project, currentUser);

  const text = getProjectBudgetStatusText({
    canSeeBudget: canSeeProjectBudget,
    budget_type: project.budget_type,
    budget_remaining: phaseBudgetData.budget_remaining,
    budget_total: phaseBudgetData.budget_total,
    suppressPercentage: true,
  });

  return { overBudget, text };
}

function getTooltipContent(
  item: CellItem<'phase'>,
  project: Project,
  phaseBudgetData: BudgetPhase | null,
  currentUser: CurrentUser,
) {
  const { entity: p } = item;

  const color = p.color || project.color;
  const budget = getBudgetInfo(project, phaseBudgetData, currentUser);

  return (
    <div className={projectItemHoverTooltip}>
      <header className={projectItemHoverTooltipHeader}>
        Phase: {`${formatDate(p.start_date)} - ${formatDate(p.end_date)}`}
      </header>
      <span className={projectItemHoverTooltipContent}>
        <div className={styles.phaseCircle} style={phaseColorStyles(color)} />
        {p.phase_name}
        {budget && budget.text && (
          <span
            className={projectItemHoverTooltipBudget({
              budget: budget.overBudget ? 'over' : 'default',
            })}
          >
            ({budget.text})
          </span>
        )}
      </span>
    </div>
  );
}

function PhaseItemWithTooltip(props: Props) {
  const { actions, item, boundaryRef, tooltipDelay = TOOLTIP_DELAY } = props;

  const [open, setOpen] = useState(false);

  const currentUser = useAppSelectorStrict(getUser);

  const phaseBudgetData = useAppSelectorWithParams(
    selectPhaseBudget,
    item.entity.phase_id,
  );

  const project = useAppSelectorWithParams(
    getProjectById,
    item.entity.project_id,
  );

  if (!project) {
    return null;
  }

  return (
    <TextTooltip
      content={getTooltipContent(item, project, phaseBudgetData, currentUser)}
      placement="bottom"
      delay={tooltipDelay}
      distance={5}
      collisionBoundary={boundaryRef?.current}
      open={open}
      onOpenChange={(open) => {
        if (open) {
          if (actions.isItemTooltipEnabled(item)) {
            setOpen(true);
          }
        } else {
          setOpen(false);
        }
      }}
    >
      {React.createElement(PhaseItem, props)}
    </TextTooltip>
  );
}

export default PhaseItemWithTooltip;
