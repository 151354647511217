import { LIVEUPDATES_HOSTNAMES } from '@float/constants/api';
import {
  FloatBuildMode,
  FloatDeploymentEnvironment,
} from '@float/constants/environment';

export const getSocketServer = (environment: FloatDeploymentEnvironment) => {
  const { protocol, port } = window.location;
  const isSecure = protocol === 'https:';
  const isLocal = environment === FloatDeploymentEnvironment.Local;
  const securePort = isLocal && port !== '' ? parseInt(port) : 443;
  const notifyPort = isSecure ? securePort : 8888;

  return `${LIVEUPDATES_HOSTNAMES[environment]}:${notifyPort}`;
};

export const getBuildMode = (hostname: string) =>
  /(float.local|local-app.float|dws-app.float|local.float|dws.float)/.test(
    hostname,
  )
    ? FloatBuildMode.Development
    : FloatBuildMode.Production;
