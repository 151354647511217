import { PERSON_RELATED_TYPES, PROJECT_RELATED_TYPES } from '../../helpers';
import {
  FiltersDataType,
  FiltersEntity,
  VirtualFilterTypes,
} from '../../types';

export function getIsNotOperatorAccepted<T extends FiltersDataType>(
  type: VirtualFilterTypes,
  entity: FiltersEntity<T>,
  dataType: T,
) {
  if (dataType === 'tasks' || dataType === 'timeoffs') {
    // If a multi-assign task/timeoff is on both person A and person B,
    // and we have a "NOT A" filter applied, we still want the task to
    // be included for person B. Therefore, we don't apply
    // person-related NOT filter logic to tasks or timeoffs.
    if (PERSON_RELATED_TYPES.includes(type)) {
      return (
        (entity as FiltersEntity<'tasks' | 'timeoffs'>).people_ids?.length <= 1
      );
    }

    return true;
  }

  // If we're searching by an exclusion of project related keys, we don't
  // want to completely filter out people, because we do want to show
  // them on the schedule if present on other projects.
  if (dataType === 'people' && PROJECT_RELATED_TYPES.includes(type)) {
    return false;
  }

  // If we're searching by an exclusion of person related keys, we don't
  // want to completely filter out projects, because we do want to show
  // them on the schedule with other people if any.
  if (dataType === 'projects' && PERSON_RELATED_TYPES.includes(type)) {
    return false;
  }

  // If we're searching by an exclusion of taskStatus, we don't
  // want to completely filter out projects.
  // E.g. when is filtering by "taskStatus not Completed" we want just to
  // make all the non-completed tasks more evident.
  if (dataType === 'projects' && type === 'taskStatus') {
    return false;
  }

  return true;
}
