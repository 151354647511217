import React from 'react';

import { useModalLocal } from '@float/ui/hooks/useModalLocal';
import { withModal } from '@float/web/modalManager/withModal';

import { ModalSwitchPersonConfirm } from './components/ModalSwitchPersonConfirm';
import { ModalSwitchPersonSelect } from './components/ModalSwitchPersonSelect';
import { useModalSwitchPersonController } from './hooks/useModalSwitchPersonController';
import {
  ModalSwitchPersonProps,
  PersonToSwitchIn,
} from './ModalSwitchPerson.types';

const ModalComponent = (props: ModalSwitchPersonProps) => {
  const {
    manageModal,
    modalProps,
    modalControls,
    modalSettings: { person, project, onSwitch },
  } = props;

  const { shouldShowRates, personBillRateOnProject } =
    useModalSwitchPersonController({ person, project });

  const onClickSwitchPerson = (newPerson: PersonToSwitchIn) => {
    modalConfirm.controls.openModal(newPerson);
  };

  const onSwitchComplete = () => {
    modalControls.closeModal();
    modalConfirm.controls.closeModal();
  };

  const onModalConfirmationExitCompleted = () => {
    if (!open) {
      modalControls.destroyModal();

      manageModal({
        visible: false,
        modalType: 'ModalSwitchPerson',
      });
    }
  };

  const modalConfirm = useModalLocal<PersonToSwitchIn>({
    onExitComplete: onModalConfirmationExitCompleted,
  });

  // temporarily close modal "switch person change" if modal confirm is open
  const open = Boolean(modalProps.open && !modalConfirm.props.open);

  return (
    <>
      <ModalSwitchPersonSelect
        modalProps={{
          ...modalProps,
          animateBackground:
            !modalConfirm.controls.present && modalControls.present,
          open,
        }}
        person={person}
        project={project}
        personBillRateOnProject={personBillRateOnProject}
        shouldShowRates={shouldShowRates}
        onClickSwitchPerson={onClickSwitchPerson}
      />

      {modalConfirm.controls.present && (
        <ModalSwitchPersonConfirm
          modalProps={{
            ...modalConfirm.props,
            animateBackground: !modalProps.open,
          }}
          newPerson={modalConfirm.renderProps ?? undefined}
          person={person}
          project={project}
          onSwitch={onSwitch}
          onSwitchComplete={onSwitchComplete}
        />
      )}
    </>
  );
};

export const ModalSwitchPerson = withModal({
  Comp: ModalComponent,
  modalType: 'ModalSwitchPerson',
});
