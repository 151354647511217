import { isUndefined, map, sortBy } from 'lodash';
import {
  feeFormatter,
  filterLinkFormatter,
  getLinkifiedValue,
  logTimeEditFormatter,
  tooltipFormatter,
} from 'reports/helpers/tableFormatters';

import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import { toCents } from '@float/common/lib/budget';
import { roundToTwoDecimals } from '@float/common/lib/utils';

export function breakdown(ctx, raw) {
  const { projects, phases } = ctx;
  const byPerson = {};
  const totals = {
    feeCents: 0,
    hours: 0,
    billableHours: 0,
    nonbillableHours: 0,
  };

  raw.totals.forEach((item) => {
    if (item.type !== 'logged_time') return;
    if (item.date >= ctx.loggedTimeBoundary) return;

    const project = projects[item.project_id];
    if (!project) return;

    const phase = (item.phase_id && phases[item.phase_id]) || {
      phase_id: 0,
      phase_name: 'No Phase',
    };

    if (!byPerson[item.person_id]) {
      byPerson[item.person_id] = {
        personId: item.person_id,
        totalFeeCents: 0,
        totalHours: 0,
        billableHours: 0,
        nonbillableHours: 0,
        children: [],
      };
    }

    const record = byPerson[item.person_id];
    const feeCents = isUndefined(item.fee) ? undefined : toCents(item.fee);

    const entity = {
      id: item.id,
      date: item.date,
      person_id: item.person_id,
      project_id: project.project_id,
      project: project.project_name,
      phase_id: phase.phase_id,
      phase: phase.phase_name,
      client: project.client_name,
      task: item.name,
      feeCents,
      hours: item.hours.scheduled,
      notes: item.note,
    };

    record.children.push(entity);

    if (feeCents) {
      record.totalFeeCents += feeCents;
      totals.feeCents += feeCents;
    }

    if (item.billable) {
      entity.billableHours = item.hours.scheduled;
      record.billableHours += item.hours.scheduled;
      totals.billableHours += item.hours.scheduled;
    } else {
      entity.nonbillableHours = item.hours.scheduled;
      record.nonbillableHours += item.hours.scheduled;
      totals.nonbillableHours += item.hours.scheduled;
    }

    record.totalHours = roundToTwoDecimals(
      record.totalHours + item.hours.scheduled,
    );
    totals.hours = roundToTwoDecimals(totals.hours + item.hours.scheduled);
  });

  return { byPerson, totals };
}

export function getPeopleTable(ctx, raw) {
  const { user, people, dates } = ctx;

  function dateOrFilterLinkFormatter(val) {
    if (val && !val.includes('::')) {
      return dates.inDDMMMYYYY(val);
    }

    return filterLinkFormatter(val);
  }

  const headers = [
    {
      label: 'Person',
      width: 170,
      align: 'flex-start',
      formatter: dateOrFilterLinkFormatter,
      allowOverflow: true,
    },
    { label: '', width: 190, align: 'flex-start' },
    { label: '', width: 180, align: 'flex-start' },
    { label: '', width: 180, align: 'flex-start' },
    { label: '', grow: 1, align: 'flex-start', formatter: tooltipFormatter },
    { label: 'Fee', width: 150, formatter: feeFormatter },
    { label: 'Logged', width: 120, formatter: logTimeEditFormatter(ctx) },
  ];

  if (!raw) return { headers, rows: [] };

  const { byPerson, totals } = breakdown(ctx, raw);
  const canSeeBudgets = getCanCurrentUserSeeBudgets(user);

  const rows = map(byPerson, (o, personId) => {
    const person = people[personId];
    return {
      id: personId,
      data: [
        `person::${person.name}`,
        '',
        '',
        '',
        '',
        canSeeBudgets && o.totalFeeCents ? o.totalFeeCents / 100 : '',
        { sortVal: o.totalHours },
      ],
      children: sortBy(o.children, 'date').map((c) => {
        return {
          id: c.id,
          data: [
            c.date,
            c.project,
            c.client,
            c.task,
            getLinkifiedValue(c.notes),
            canSeeBudgets && c.feeCents ? c.feeCents / 100 : '',
            { sortVal: c.hours, entity: c },
          ],
        };
      }),
    };
  });

  const footer = [
    { label: '' },
    { label: '' },
    { label: '' },
    { label: '' },
    { label: '' },
    { label: canSeeBudgets && totals.feeCents ? totals.feeCents / 100 : '' },
    { label: totals.hours },
  ];

  return { headers, rows, footer };
}

export default function getTimetrackingTable(ctx, raw) {
  const table = getPeopleTable(ctx, raw);

  if (!ctx.isSinglePersonView) return table;

  const headers = [
    {
      label: 'Date',
      width: 140,
      align: 'flex-start',
      formatter: (val) => (val === '' ? '' : ctx.dates.inDDMMMYYYY(val)),
    },

    { label: 'Project', width: 160, align: 'flex-start' },
    { label: 'Client', width: 180, align: 'flex-start' },
    { label: 'Task', width: 180, align: 'flex-start' },
    {
      label: 'Notes',
      grow: 1,
      align: 'flex-start',
      formatter: tooltipFormatter,
    },
    { label: 'Fee', width: 150, formatter: feeFormatter },
    { label: 'Logged', width: 120, formatter: logTimeEditFormatter(ctx) },
  ];

  const rows = table.rows.length ? table.rows[0].children : table.rows;

  return { headers, rows, footer: table.footer };
}
