import { isNot, isOr } from '@float/common/search/helpers';
import { getSearchFilteredProjects } from '@float/common/search/selectors/projects';
import { selectScheduleViewType } from '@float/common/selectors/appInfo/scheduleView';
import { getActiveFilters } from '@float/common/selectors/views';
import { useAppSelectorStrict } from '@float/common/store';
import { ScheduleViewType } from '@float/constants/schedule';
import { BaseFilterToken } from '@float/types';

/**
 * Returns if a single inclusive project filter is applied
 * @param {Object[]} filters
 * @returns {boolean}
 */
export const getIsSingleInclusiveProjectFilterApplied = (
  filters: BaseFilterToken[],
) => {
  let projectFilterCount = 0;

  for (const filter of filters) {
    if (filter.type === 'project') {
      if (Array.isArray(filter.val)) {
        projectFilterCount += filter.val.length;
      } else if (typeof filter.val === 'string') {
        projectFilterCount += 1;
      }

      // We want to ensure that this is a search with only one project visible
      // so if the project filter is applied with anything different than AND,
      // we can short circuit and return false
      if (isOr(filter.operator) || isNot(filter.operator)) {
        return false;
      }

      // Short circuit if we have more than one project filter
      if (projectFilterCount > 1) {
        return false;
      }
    }
  }

  return projectFilterCount === 1;
};

export const getIsSingleProjectPlanView = (
  viewType: ScheduleViewType,
  filters: BaseFilterToken[],
  projectsCount: number,
) => {
  return (
    viewType === ScheduleViewType.Projects &&
    getIsSingleInclusiveProjectFilterApplied(filters) &&
    projectsCount === 1
  );
};

// Redux hook for parts of the app that doesn't have the required props
export const useIsSingleProjectPlanView = () => {
  const viewType = useAppSelectorStrict(selectScheduleViewType);
  const filters = useAppSelectorStrict(getActiveFilters);
  const projects = useAppSelectorStrict(getSearchFilteredProjects);
  const isSingleProjectPlanView = getIsSingleProjectPlanView(
    viewType,
    filters,
    projects.length,
  );
  return {
    isSingleProjectPlanView,
    project: isSingleProjectPlanView ? projects[0] : undefined,
  };
};
