import { CurrentUser } from '@float/types/account';
import { BudgetType, Project } from '@float/types/project';

import { getCanCurrentUserSeeBudgets } from './getCanCurrentUserSeeBudgets';

export const getUserCanSeeProjectBudget = (
  project: Project,
  currentUser: CurrentUser,
) => {
  if (!currentUser) return false;

  if (project.budget_type === BudgetType.Disabled) return true; // Everyone can see disabled budgets
  if (project.budget_type === BudgetType.TotalHours) return true; // Everyone can see total hours budgets

  return getCanCurrentUserSeeBudgets(currentUser);
};
