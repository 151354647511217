import React from 'react';
import { Trans } from '@lingui/macro';

import { DatePicker } from '@float/common/components/DatePicker';
import { RateInput } from '@float/common/components/Rate';
import { useEditCostRate } from '@float/common/hooks/useEditCostRate';
import { CostRateFormData } from '@float/types/costRate';
import { IconCalendar } from '@float/ui/deprecated/Earhart/Icons';

import * as styles from './EditRoleCostRate.styles.css';

type Props = {
  className?: string;
  placeholder?: string;
  value: CostRateFormData;
  onChange: (value: CostRateFormData) => void;
};

export type EditRoleCostRateRenderProps = {
  value: CostRateFormData;
  row: string[];
  onChange: (value: CostRateFormData) => void;
};

export function EditRoleCostRate(props: Props) {
  const { className, placeholder, value, onChange } = props;
  const {
    costRate,
    effectiveDate,
    isEffectiveDateShown,
    handleCostRateChange,
    handleEffectiveDateChange,
  } = useEditCostRate(value, onChange);

  return (
    <div className={className}>
      <RateInput
        placeholder={placeholder}
        value={costRate}
        onChange={handleCostRateChange}
      />
      {isEffectiveDateShown && (
        <div className={styles.effectiveDateWrapper}>
          <label htmlFor="effective-date" className={styles.effectiveDateLabel}>
            <IconCalendar />
            <Trans>Effective date</Trans>
          </label>
          <DatePicker
            inputId="effective-date"
            appearance="outline"
            value={effectiveDate}
            onChange={handleEffectiveDateChange}
          />
        </div>
      )}
    </div>
  );
}
