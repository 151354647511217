import { ScheduleViewType } from '@float/constants/schedule';

export const SET_LOG_TIME_VIEW = 'appInfo/SET_LOG_TIME_VIEW' as const;
export const SET_VIEW_TYPE = 'appInfo/SET_VIEW_TYPE' as const;

export const setLogTimeViewAction = (isLogTimeViewType: boolean) => ({
  type: SET_LOG_TIME_VIEW,
  payload: isLogTimeViewType,
});

export const setViewTypeAction = (viewType: ScheduleViewType) => ({
  type: SET_VIEW_TYPE,
  payload: viewType,
});

export type AppInfoAction =
  | ReturnType<typeof setLogTimeViewAction>
  | ReturnType<typeof setViewTypeAction>;
