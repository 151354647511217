import { t } from '@lingui/macro';
import {
  feeFormatter,
  getLinkifiedValue,
  logTimeEditFormatter,
  tooltipFormatter,
} from 'reports/helpers/tableFormatters';

import { isCostRateFeatureActive as getIsCostRateFeatureActive } from '@float/common/lib/rates/rates';
import { moment } from '@float/libs/moment';
import { sumOperation } from '@float/libs/utils/floats';

export default function getTimetrackingTable(ctx, raw) {
  const { people, phases, hasPhases, hasBudgetsAccess, hasCostsAccess } = ctx;

  const isCostRateFeatureActive = getIsCostRateFeatureActive();

  const hasFeeColumns = hasBudgetsAccess;
  const hasCostColumns = hasCostsAccess && isCostRateFeatureActive;

  const headers = [
    {
      label: t`Date`,
      width: 140,
      align: 'flex-start',
      formatter: (val) => (val ? moment(val).format('DD MMM YYYY') : ''),
    },
    { label: t`Task`, width: 200, align: 'flex-start' },
    { label: t`Person`, width: 230, align: 'flex-start' },
    {
      label: t`Notes`,
      grow: 1,
      align: 'flex-start',
      formatter: tooltipFormatter,
    },
    { label: t`Logged`, width: 100, formatter: logTimeEditFormatter(ctx) },
  ];

  if (hasFeeColumns) {
    headers.splice(4, 0, {
      label: t`Fee`,
      width: 100,
      formatter: feeFormatter,
    });
  }

  if (hasPhases) {
    headers.splice(1, 0, {
      label: t`Phase`,
      align: 'flex-start',
      width: 170,
    });
  }

  if (hasCostColumns) {
    headers.push({ label: t`Cost`, width: 100, formatter: feeFormatter });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const rows = [];
  let totalFeeCents = 0;
  let totalCostCents = 0;
  let totalHours = 0;

  raw.totals.forEach((item) => {
    if (item.type !== 'logged_time') return;
    if (item.date >= ctx.loggedTimeBoundary) return;

    const fee =
      hasFeeColumns && item.rate ? item.rate * item.hours.scheduled : '';

    const cost =
      hasCostColumns && item.cost?.scheduled ? item.cost?.scheduled : '';

    const data = [
      item.date,
      item.name,
      people[item.person_id].name,
      getLinkifiedValue(item.note),
      { sortVal: item.hours.scheduled, entity: item },
    ];

    if (hasFeeColumns) {
      data.splice(4, 0, fee);
    }

    if (hasPhases) {
      data.splice(1, 0, phases[item.phase_id]?.phase_name || t`No Phase`);
    }

    if (hasCostColumns) {
      data.push(cost);
    }

    rows.push({ data });

    if (fee) {
      totalFeeCents = totalFeeCents + 100 * fee;
    }
    if (cost) {
      totalCostCents = totalCostCents + 100 * cost;
    }

    totalHours = sumOperation(totalHours, item.hours.scheduled);
  });

  const footer = [
    { label: '' },
    { label: '' },
    { label: '' },
    { label: '' },
    { label: totalHours },
  ];

  if (hasFeeColumns) {
    footer.splice(4, 0, { label: totalFeeCents / 100 });
  }

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasCostColumns) {
    footer.push({ label: totalCostCents / 100 });
  }

  return { headers, rows, footer };
}
