import { FloatDeploymentEnvironment } from '@float/constants/environment';

export enum FloatReservedSubdomain {
  Develop = 'develop',
  Dws = 'dws',
  Local = 'local',
  Staging = 'staging',
  Preview = 'preview',
}

const FLOAT_RESERVED_SUBDOMAINS = Object.values(
  FloatReservedSubdomain,
) as string[];

const SUBDOMAIN_TO_ENVIRONMENT_MAP: Record<
  FloatReservedSubdomain,
  FloatDeploymentEnvironment
> = {
  [FloatReservedSubdomain.Develop]: FloatDeploymentEnvironment.Develop,
  [FloatReservedSubdomain.Dws]: FloatDeploymentEnvironment.Local,
  [FloatReservedSubdomain.Local]: FloatDeploymentEnvironment.Local,
  [FloatReservedSubdomain.Staging]: FloatDeploymentEnvironment.Staging,
  [FloatReservedSubdomain.Preview]: FloatDeploymentEnvironment.Preview,
};

function isNonProductionEnvironment(
  value: string,
): value is FloatReservedSubdomain {
  return FLOAT_RESERVED_SUBDOMAINS.includes(value);
}

export const getCurrentHostname = () =>
  typeof window !== 'undefined' && typeof window.location !== 'undefined'
    ? window.location.hostname
    : '';

// Determines the deployment environment by parsing the hostname
//
// {environmentOrTeamName}.float.com
//
// If running in production this will match the team name, while
// in other environments this will match the environment name.
export const getEnvironment = (): FloatDeploymentEnvironment => {
  const splitHostname = getCurrentHostname().split('.');
  const environmentOrTeamName = splitHostname[splitHostname.length - 3] ?? '';

  return isNonProductionEnvironment(environmentOrTeamName)
    ? SUBDOMAIN_TO_ENVIRONMENT_MAP[environmentOrTeamName]
    : FloatDeploymentEnvironment.Prod;
};
