import { ChangeEvent } from 'react';
import {
  NumberFormatOptions,
  NumberFormatter,
  NumberParser,
} from '@internationalized/number';
import { useMachine, useSelector } from '@xstate/react';
import { assign, setup } from 'xstate';

import { useOnMount } from '@float/libs/hooks/useOnMount';

function truncNumber<V extends number | null>(
  value: V,
  maximumFractionDigits: number,
) {
  if (value === null) {
    return value;
  }

  const m = Math.pow(10, maximumFractionDigits);
  // Using Number.EPSILON is too precise to fix the floating-point errors
  // that user-inputted numbers are likely to encounter
  const EPSILON = 1e-10;
  return Math.trunc(value * m + EPSILON) / m;
}

export function createNumberParser(
  locale: string,
  { maximumFractionDigits = 2, ...options }: NumberFormatOptions = {},
) {
  const parser = new NumberParser(locale, options);
  const formatter = new NumberFormatter(locale, {
    ...options,
    maximumFractionDigits,
  });

  function format(value: number | null): string {
    if (value === null) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }

    return formatter.format(truncNumber(value, maximumFractionDigits));
  }

  function parse(val: string) {
    if (val === '') return null;

    return truncNumber(parser.parse(val), maximumFractionDigits);
  }

  function validate(val: string) {
    return parser.isValidPartialNumber(val);
  }

  return {
    format,
    parse,
    validate,
  };
}

const machine = setup({
  types: {} as {
    context: {
      value: string;
      valueAsNumber: number | null;
      parser: ReturnType<typeof createNumberParser>;
    };
    input: {
      value: number | null;
      options: NumberFormatOptions;
      locale: string;
    };
    events:
      | { type: 'BLUR' }
      | { type: 'CHANGE'; value: string; valueProp: number | null }
      | { type: 'FORMAT'; value: string; valueProp: number | null };
  },
  guards: {
    isValidEvent: ({ event, context }) =>
      event.type === 'CHANGE' ? context.parser.validate(event.value) : true,
  },
  actions: {
    onChange: () => {},
  },
}).createMachine({
  initial: 'initial',
  context: ({ input }) => {
    const parser = createNumberParser(input.locale, input.options);
    return {
      valueAsNumber: input.value,
      value: parser.format(input.value),
      parser,
    };
  },
  states: {
    initial: {
      on: {
        FORMAT: {
          target: 'initial',
          actions: [
            assign(({ event, context }) => ({
              valueAsNumber: context.parser.parse(event.value) ?? null,
              value: event.value,
            })),
            // Some fields like project_budget are used as the same key for more than one input field
            // Switching back and forth from these fields in the UI results in inconsistencies where a string value is saved
            // The onChange handler here calls setValue which resets the field value to a number value
            // while avoiding unnecessary renders (https://react-hook-form.com/docs/useform/setvalue)
            'onChange',
          ],
        },
        CHANGE: [
          {
            target: 'editing',
            guard: 'isValidEvent',
            actions: [
              assign(({ event, context }) => ({
                valueAsNumber: context.parser.parse(event.value) ?? null,
                value: event.value,
              })),
              'onChange',
            ],
          },
          // default transition that gets taken when none of the guard evaluates to true
          {
            target: 'editing',
            actions: [
              // Input state turns into NaN because the field value change is handled at the input level regardless of
              // whether the isValidEvent blocks the state transition. So we need this transition to reset the field value
              // when an invalid value is entered.
              assign(({ context }) => ({
                valueAsNumber: context.valueAsNumber ?? null,
                value: context.parser.format(context.valueAsNumber),
              })),
              'onChange',
            ],
          },
        ],
      },
    },
    editing: {
      on: {
        CHANGE: [
          {
            guard: 'isValidEvent',
            actions: [
              assign(({ event, context }) => ({
                valueAsNumber: context.parser.parse(event.value) ?? null,
                value: event.value,
              })),
              'onChange',
            ],
          },
          {
            target: 'editing',
            actions: [
              assign(({ context }) => ({
                valueAsNumber: context.valueAsNumber ?? null,
                value: context.parser.format(context.valueAsNumber),
              })),
              'onChange',
            ],
          },
        ],
        BLUR: [
          {
            target: 'initial',
            actions: [
              assign(({ context }) => ({
                valueAsNumber: context.valueAsNumber,
                value: context.parser.format(context.valueAsNumber),
              })),
              'onChange',
            ],
          },
        ],
      },
    },
  },
});

export function useNumericInput(props: {
  locale: string;
  onChange?: (value: number | null) => void;
  options?: NumberFormatOptions;
  value: number | null;
}) {
  const [, send, actorRef] = useMachine(
    machine.provide({
      actions: {
        onChange: ({ context }) => {
          if (props.onChange) props.onChange(context.valueAsNumber);
        },
      },
    }),
    {
      input: {
        value: props.value ?? null,
        options: props.options || {},
        locale: props.locale,
      },
    },
  );

  const value = useSelector(actorRef, ({ value, context }) => {
    if (value === 'initial') {
      return context.parser.format(props.value);
    }

    return context.value;
  });

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    send({
      type: 'CHANGE',
      value: evt.target.value,
      valueProp: props.value,
    });
  };

  const handleBlur = () => {
    send({
      type: 'BLUR',
    });
  };

  useOnMount(() => {
    // When the QI field renders, the formatting doesn't apply to the numeric value without a change first
    // So we fire this one time event when the component mounts to trigger the initial formatting update.
    send({
      type: 'FORMAT',
      value,
      valueProp: props.value,
    });
  });

  return {
    value,
    onChange: handleChange,
    onBlur: handleBlur,
  };
}
