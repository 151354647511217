import { BitsHandler } from '.';

export const getManagerAccessBit = (key) => {
  switch (key) {
    case 'isProjectManager':
      return 0;
    case 'isPeopleManager':
      return 1;
    case 'canCreateEditPeople':
      return 2;
    case 'canBudget':
      return 3;
    case 'canViewCostRate':
      return 4;
    default:
      throw new Error('Unknown Key');
  }
};

export const setManagerAccess = (access, key, bool) => {
  const bits = BitsHandler.from(32, access);
  const bitIndex = getManagerAccessBit(key);
  bits.set(bitIndex, bool);

  return bits.toNumber();
};

export const getManagerAccess = (account) => {
  const { access = 0 } = account;
  const bits = BitsHandler.from(32, access);

  const rights = {
    isProjectManager: bits.get(getManagerAccessBit('isProjectManager')),
    isPeopleManager: bits.get(getManagerAccessBit('isPeopleManager')),
    canCreateEditPeople: bits.get(getManagerAccessBit('canCreateEditPeople')),
    canBudget: bits.get(getManagerAccessBit('canBudget')),
    canViewCostRate: bits.get(getManagerAccessBit('canViewCostRate')),
  };

  return rights;
};

export const setMemberAccess = (access, key, bool) => {
  const bits = BitsHandler.from(32, access);

  switch (key) {
    case 'canView':
      bits.set(0, bool);
      break;
    case 'canEdit':
      bits.set(1, bool);
      break;
  }

  return bits.toNumber();
};

export const getMemberAccess = (account) => {
  const { access = 0 } = account;
  const bits = BitsHandler.from(32, access);
  const rights = {
    canView: bits.get(0),
    canEdit: bits.get(1),
  };

  return rights;
};

export const checkIsPeopleManager = (account) => {
  if (!account) return false;

  const isAdmin = account.account_type === 2;
  const isBilling = account.account_type === 5;
  const isAccountOwner = account.account_type === 1;
  if (isAdmin || isAccountOwner || isBilling) {
    return true;
  }

  const isManager = account.account_type === 7;
  const isPeopleManager = getManagerAccess(account).isPeopleManager;
  return isManager && isPeopleManager;
};
