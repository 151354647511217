import React from 'react';
import cx from 'classnames';

import { Row, Spacer } from '@float/ui/deprecated/Layout/Layout';

const Cell = (props) => {
  const { className, cell, style = {}, virtualized, icon } = props;
  const children = Array.isArray(cell) ? (
    <ul>
      {cell.map((cellItem, j) => (
        <li key={j}>{cellItem}</li>
      ))}
    </ul>
  ) : (
    cell
  );

  return React.createElement(
    virtualized ? 'div' : 'td',
    {
      className: cx('cell', className, { multiline: Array.isArray(cell) }),
      style,
    },
    <Row alignItems="center">
      {icon && (
        <Row flex="0">
          {icon}
          <Spacer size="8" />
        </Row>
      )}
      <span className="cell-inner">{children}</span>
    </Row>,
  );
};

export default Cell;
