import React from 'react';

import { CellItem } from '@float/types/cells';

import { ScheduleActions } from '../../../MainCell/types';
import { EntitiesGroup } from '../EntitiesGroup/EntitiesGroup';

import * as styles from './Footer.css';

export type FooterProps = {
  cellItemsGrouppedByWeekday: {
    name: string;
    items: CellItem<'task' | 'timeoff'>[];
    overtime: number;
  }[];
  actions: ScheduleActions;
};

export const Footer = (props: FooterProps) => {
  const { cellItemsGrouppedByWeekday, actions } = props;

  return (
    <div className={styles.footerWrapper}>
      {cellItemsGrouppedByWeekday.map((group, index) => (
        <div className={styles.footerGroupWrapper} key={index}>
          <EntitiesGroup
            name={group.name}
            items={group.items}
            overtime={group.overtime}
            actions={actions}
          />
        </div>
      ))}
    </div>
  );
};
