import React, { Fragment, useEffect } from 'react';
import cx from 'classnames';
import { compact } from 'lodash';
import styled from 'styled-components';

import useNavigation from '@float/common/lib/hooks/use-navigation';
import { useAppDispatch } from '@float/common/store';
import { useIsSmallMediaSizeActive } from '@float/libs/hooks/media';
import EH from '@float/ui/deprecated/Earhart';
import { Col, Flex, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { IconFloatCommunity } from '@float/ui/icons/illocons/IconFloatCommunity';
import { IconHelpCenter } from '@float/ui/icons/illocons/IconHelpCenter';
import { IconProductTour } from '@float/ui/icons/illocons/IconProductTour';
import { IconResources } from '@float/ui/icons/illocons/IconResources';
import { IconShortcuts } from '@float/ui/icons/illocons/IconShortcuts';
import { IconSupport } from '@float/ui/icons/illocons/IconSupport';
import { setHelpMenuVisible } from '@float/web/actions/app';
import { modalManagerConnect } from '@float/web/modalManager/modalManagerHoc';
import { replayProductTour } from '@float/web/store/onboardingManager/actions';

import { onChatClick } from '../helpers';
import { MenuSection, MenuSectionRow, MenuTitle, MenuWrapper } from '../styles';

const HelpMenuButtonWrapper = styled(Flex)`
  ${EH.Elevations.E100}

  position: relative;
  width: 284px;
  border-radius: 10px;
  padding: 12px 12px 14px 16px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${EH.Colors.FIN.Lt.Surface.Surf1};

  &:hover {
    ${EH.Elevations.E200}

    border-radius: 6px;

    background-color: ${EH.Colors.FIN.Lt.Surface.Surf4};
  }

  &:active {
    background-color: ${EH.Colors.FIN.Lt.Surface.Surf6};
  }

  .title {
    ${EH.Typography.Label14.SB600};
    color: ${EH.Colors.FIN.Lt.Emphasis.High};
  }

  .subtitle {
    ${EH.Typography.TextXS.R400};
    color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
    text-align: left;
  }
`;

const HelpSection = ({ icon, title, subtitle, onClick, className }) => {
  return (
    <HelpMenuButtonWrapper onClick={onClick} className={className}>
      <Col
        alignItems="flex-start"
        style={{
          maxWidth: '175px',
        }}
      >
        <div className="title">{title}</div>
        <Spacer size={4} />
        <div className="subtitle">{subtitle}</div>
      </Col>
      <Flex
        style={{
          position: 'absolute',
          right: '12px',
          top: '9px',
        }}
      >
        {icon}
      </Flex>
    </HelpMenuButtonWrapper>
  );
};

const HelpMenu = ({ showModal, onClick }) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const isSmallBreakpointActive = useIsSmallMediaSizeActive();

  const createOnClick = (callback) => (event) => {
    if (onClick) onClick();
    callback(event);
  };

  const helpMenuOptions = compact([
    {
      className: 'help-center',
      title: 'Help center',
      subtitle: 'Your Float questions answered',
      icon: <IconHelpCenter />,
      onClick: createOnClick(() =>
        window.open('https://support.float.com/en/', '_blank'),
      ),
    },
    {
      className: 'chat',
      title: 'Message support',
      subtitle: 'Ask our experts for help',
      icon: <IconSupport />,
      onClick: createOnClick(onChatClick),
    },
    {
      className: 'resources',
      title: 'Resources',
      subtitle: 'Tips to get more out of Float',
      icon: <IconResources />,
      onClick: createOnClick(() =>
        window.open('https://www.float.com/resources/', '_blank'),
      ),
    },
    {
      className: 'float-slack-community',
      title: 'Float Slack Community',
      subtitle: 'Network and knowledge share',
      icon: <IconFloatCommunity />,
      onClick: createOnClick(() =>
        window.open('https://www.float.com/community', '_blank'),
      ),
    },
    {
      className: 'videos',
      title: 'Product tour',
      subtitle: 'Highlight key menu items',
      icon: <IconProductTour />,
      onClick: createOnClick(() => {
        navigation.goTo('/');

        dispatch(replayProductTour());
      }),
    },
    !isSmallBreakpointActive && {
      className: 'shortcuts',
      title: 'Keyboard shortcuts',
      subtitle: 'Faster ways to schedule ',
      icon: <IconShortcuts />,
      onClick: createOnClick((event) => showModal('shortcutModal')),
    },
  ]);

  useEffect(() => {
    dispatch(setHelpMenuVisible(true));
    return () => {
      dispatch(setHelpMenuVisible(false));
    };
  }, [dispatch]);

  if (isSmallBreakpointActive) {
    return (
      <MenuWrapper className={cx({ mobile: isSmallBreakpointActive })}>
        <MenuTitle>Help</MenuTitle>
        <MenuSection>
          {helpMenuOptions.map((option) => {
            return (
              <MenuSectionRow key={option.title} onClick={option.onClick}>
                {option.title}
              </MenuSectionRow>
            );
          })}
        </MenuSection>
      </MenuWrapper>
    );
  }

  return (
    <MenuWrapper>
      <MenuTitle>Help</MenuTitle>
      <Spacer size={12} />
      {helpMenuOptions.map((option) => {
        return (
          <Fragment key={option.title}>
            <Spacer size={12} />
            <HelpSection
              className={option.className || ''}
              title={option.title}
              subtitle={option.subtitle}
              icon={option.icon}
              onClick={option.onClick}
            />
          </Fragment>
        );
      })}
    </MenuWrapper>
  );
};

export default modalManagerConnect(HelpMenu);
