import {
  ALL_FEATURE_FLAGS,
  FEATURE_FLAG_VARIANTS,
  FeatureFlag,
  TargetedDebugLogFlagVariants,
} from './FeatureFlags.constants';
import { FeatureFlagVariantNames } from './FeatureFlags.types';

export const isSupportedFeatureFlag = (flag: string): flag is FeatureFlag => {
  return ALL_FEATURE_FLAGS.includes(flag as FeatureFlag);
};

export const isFeatureFlagVariant = (
  string: string | FeatureFlagVariantNames,
): string is FeatureFlagVariantNames => {
  return FEATURE_FLAG_VARIANTS.includes(string as FeatureFlagVariantNames);
};

export const isTargetedDebugLogFlagVariants = (
  string: string | TargetedDebugLogFlagVariants,
): string is TargetedDebugLogFlagVariants => {
  return Object.values(TargetedDebugLogFlagVariants).includes(
    string as TargetedDebugLogFlagVariants,
  );
};
