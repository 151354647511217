import React from 'react';
import { t } from '@lingui/macro';

import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { MeatballMenu } from '@float/ui/components/MeatballMenu';

import { useSingleProjectPersonMenu } from './useSingleProjectPersonMenu';

export const SingleProjectPersonCardMenu = ({
  person,
  project,
  actions,
}: {
  person: Person;
  project: Project;
  actions: {
    showPersonModal: (person: Person) => void;
    showSwitchPersonModal: (modalSettings: {
      person: Person;
      project: Project;
    }) => void;
    removeFromProjects: (person: Person, projects: Project[]) => void;
  };
}) => {
  const items = useSingleProjectPersonMenu(person, project, actions);
  return (
    <MeatballMenu
      items={items}
      trigger={{ label: t`Project menu`, iconSize: 26 }}
      vertical
      sideOffset={5}
    />
  );
};
