import { createAsyncThunk } from '@reduxjs/toolkit';

import { LoadState } from '@float/common/reducers/lib/types';
import type { ReduxStateStrict } from '@float/common/reducers/lib/types';
import type {
  PhaseEstimate,
  ProjectEstimate,
  TaskEstimate,
} from '@float/types/estimate';
import type { Project } from '@float/types/project';

import { estimates as EstimatesAPI } from '../../api3/estimates';
import { ESTIMATES_REDUCER_NAME } from './estimates.constants';
import {
  selectPhaseEstimatesLoadState,
  selectProjectEstimatesLoadState,
  selectTaskEstimatesLoadState,
} from './estimates.selectors';

const getShouldSkipLoad = ({
  loadState,
  forceLoad,
}: {
  loadState: LoadState;
  forceLoad?: boolean;
}) => {
  // Skip if data is already loaded and no force reload is requested
  if (!forceLoad && loadState === LoadState.LOADED) {
    return true;
  }
  // Skip if there is an ongoing request to load the data
  if (loadState === LoadState.LOADING) {
    return true;
  }
  // Proceed with loading the data
  return false;
};

export const ensureProjectEstimatesLoaded = createAsyncThunk<
  ProjectEstimate[],
  { projectId: Project['project_id']; forceLoad?: boolean },
  { state: ReduxStateStrict }
>(
  `${ESTIMATES_REDUCER_NAME}/ensureProjectEstimatesLoadedStatus`,
  async ({ projectId }, { getState }) => {
    const { projects } = getState();
    const projectEstimateIds = projects.projects[projectId]?.estimates ?? [];
    const projectEstimates = await Promise.all(
      projectEstimateIds.map((id) => EstimatesAPI.getProjectEstimate({ id })),
    );
    return projectEstimates;
  },
  {
    condition: ({ projectId, forceLoad }, { getState }) => {
      const loadState = selectProjectEstimatesLoadState(getState(), projectId);
      if (getShouldSkipLoad({ loadState, forceLoad })) {
        return false;
      }
    },
  },
);

export const ensurePhaseEstimatesLoaded = createAsyncThunk<
  PhaseEstimate[],
  { projectEstimateId: ProjectEstimate['id']; forceLoad?: boolean },
  { state: ReduxStateStrict }
>(
  `${ESTIMATES_REDUCER_NAME}/ensurePhaseEstimatesLoadedStatus`,
  async ({ projectEstimateId }) => {
    const phaseEstimates = await EstimatesAPI.getPhaseEstimates({
      id: projectEstimateId,
    });
    return phaseEstimates;
  },
  {
    condition: ({ projectEstimateId, forceLoad }, { getState }) => {
      const loadState = selectPhaseEstimatesLoadState(
        getState(),
        projectEstimateId,
      );
      if (getShouldSkipLoad({ loadState, forceLoad })) {
        return false;
      }
    },
  },
);

export const ensureTaskEstimatesLoaded = createAsyncThunk<
  TaskEstimate[],
  { projectEstimateId: ProjectEstimate['id']; forceLoad?: boolean },
  { state: ReduxStateStrict }
>(
  `${ESTIMATES_REDUCER_NAME}/ensureTaskEstimatesLoadedStatus`,
  async ({ projectEstimateId }) => {
    const taskEstimates = await EstimatesAPI.getTaskEstimates({
      id: projectEstimateId,
    });
    return taskEstimates;
  },
  {
    condition: ({ projectEstimateId, forceLoad }, { getState }) => {
      const loadState = selectTaskEstimatesLoadState(
        getState(),
        projectEstimateId,
      );
      if (getShouldSkipLoad({ loadState, forceLoad })) {
        return false;
      }
    },
  },
);
