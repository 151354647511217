import { CurrentUser, Project, SavedView } from '@float/types';

import { createSpecInterpreter } from '../lib/createSpecInterpreter';
import * as rightsEnum from './rights.helpers';
import rightsJSON from './rights.json';

interface RightsInterpreter {
  canCreateView: (
    user: CurrentUser,
    ctx: { entity: Pick<SavedView, 'created_by' | 'personal'> },
  ) => boolean;
  canUpdateView: (
    user: CurrentUser,
    ctx: {
      entity: Pick<SavedView, 'created_by' | 'personal'>;
      changes?: Partial<SavedView>;
    },
  ) => boolean;
  canDeleteView: (
    user: CurrentUser,
    ctx: { entity: Pick<SavedView, 'created_by' | 'personal'> },
  ) => boolean;
  canViewBudget: (user: CurrentUser) => boolean;
  canUpdateProject: (user: CurrentUser, ctx: { project: Project }) => boolean;
  canCreateTaskMeta: (user: CurrentUser, ctx: { project?: Project }) => boolean;
  [key: string]: (user: CurrentUser, entity?: any) => boolean;
}

// @ts-expect-error - createSpecInterpreter is not typed
export const Rights: RightsInterpreter = createSpecInterpreter(
  rightsJSON,
  rightsEnum,
);
