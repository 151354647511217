import { DEFAULT_COLOR } from '@float/constants/colors';

export function rgbPartsIsBright(r, g, b) {
  return 0.2126 * r + 0.7152 * g + 0.0722 * b > 210;
}

export const hexToRgbParts = (hex = DEFAULT_COLOR) => {
  hex = hex.trim();
  if (hex.startsWith('#')) hex = hex.substring(1);
  if (hex.length < 3) {
    // Not valid colors, so we'll just use the default
    hex = DEFAULT_COLOR;
  } else if (hex.length < 6) {
    // Short form (3) doubles each character. Four and five character codes are
    // invalid, so we'll just use the first three as a best guess.
    hex = [
      hex.charAt(0).repeat(2),
      hex.charAt(1).repeat(2),
      hex.charAt(2).repeat(2),
    ].join('');
  } else if (hex.length > 6) {
    // Strip anything past 6 characters
    hex = hex.substring(0, 6);
  }
  const colorRegex = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  let result = colorRegex.exec(hex);
  if (!result) {
    // Invalid input (correct length, non-hex chars).
    // Use default instead in this case
    result = colorRegex.exec(DEFAULT_COLOR);
  }
  const [, r, g, b] = result;
  return [r, g, b];
};

export const hexToRgb = (hex = DEFAULT_COLOR, opacity = 1) => {
  const [r, g, b] = hexToRgbParts(hex, opacity);

  const rgb = `${parseInt(r, 16)},${parseInt(g, 16)},${parseInt(b, 16)}`;
  if (opacity === null) {
    return rgb;
  }

  return `${rgb},${opacity}`;
};

export function rgbPartsToHex(parts) {
  const r = Number(parts[0]).toString(16);
  const g = Number(parts[1]).toString(16);
  const b = Number(parts[2]).toString(16);
  return `#${r}${g}${b}`;
}

export function rgbToHex(rgb) {
  rgb = rgb.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i,
  );
  const r = `0${parseInt(rgb[1], 10).toString(16)}`.slice(-2);
  const g = `0${parseInt(rgb[2], 10).toString(16)}`.slice(-2);
  const b = `0${parseInt(rgb[3], 10).toString(16)}`.slice(-2);
  if (rgb && rgb.length === 4) {
    return `#${r}${g}${b}`;
  }
  return '';
}
