import { cloneDeep, keyBy } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import {
  ADD_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_SUCCESS,
  HOLIDAYS_LOAD_FAILED,
  HOLIDAYS_LOAD_FINISH,
  PEOPLE_IMPORTED,
  PEOPLE_UPDATED,
  UPDATE_HOLIDAY_SUCCESS,
} from '../actions';

const REDUCER_NAME = 'holidays';
const DEFAULT_STATE = {
  holidays: {},
  loadState: 'UNLOADED',
  holidaysLoaded: false,
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case HOLIDAYS_LOAD_FINISH: {
      return {
        ...state,
        loadState: 'LOADED',
        holidaysLoaded: true,
        holidays: keyBy(action.holidays, 'holiday_id'),
      };
    }

    case HOLIDAYS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
        holidaysLoaded: false,
        holidays: {},
      };
    }

    case ADD_HOLIDAY_SUCCESS:
    case UPDATE_HOLIDAY_SUCCESS: {
      const { payload: holiday } = action;
      const newHolidays = { ...state.holidays };
      newHolidays[holiday.holiday_id] = holiday;
      return { ...state, holidays: newHolidays };
    }

    case DELETE_HOLIDAY_SUCCESS: {
      const holidayId = action.id || action.payload?.holiday_id;
      if (!holidayId) return state;

      const newHolidays = { ...state.holidays };
      delete newHolidays[holidayId];
      return { ...state, holidays: newHolidays };
    }

    case PEOPLE_IMPORTED:
    case PEOPLE_UPDATED: {
      // Serena keys off object equality in Redux to determine when to apply an
      // update. When a person is added, we need to reload all holidays so that
      // they get associated with the new person.
      return cloneDeep(state);
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.holidaysLoaded ? 'LOADED' : 'UNLOADED';

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
}
