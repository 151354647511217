import React from 'react';

import { useBodyLoadedClass } from './hooks/useBodyLoadedClass';

export const FullPageLoader = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children?: React.ReactNode;
}) => {
  useBodyLoadedClass();

  if (isLoading) {
    return (
      <div className="app-wrapper">
        <div className="app-inner"></div>
      </div>
    );
  }

  return children ?? null;
};
