import React from 'react';
import { t, Trans } from '@lingui/macro';

import { setManagerAccess } from '@float/common/lib/acl/access';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';

import { getBudgetAccessLabel } from '../../PersonInfo.helpers';
import {
  ManagerPermissionsComponentProps,
  ManagerPermissionsProps,
} from './types';
import { useManagerPermissionsController } from './useManagerPermissionsController';

import * as styles from '../PersonAccess.styles.css';

// @test-export
export function ManagerPermissionsComponent(
  props: ManagerPermissionsComponentProps,
) {
  const {
    hasCostRateFeature,
    managerAccess,
    canUpdateAccess,
    updateAccess,
    onChange,
  } = props;

  const canUpdateProfitabilityAccess =
    hasCostRateFeature &&
    canUpdateAccess('canBudget') &&
    canUpdateAccess('canViewCostRate');

  const billRateAndCostRateElements = (
    <>
      {canUpdateAccess('canBudget') && (
        <Checkbox
          label={getBudgetAccessLabel()}
          value={managerAccess.canBudget}
          onChange={(next) => {
            onChange(updateAccess('canBudget', next));
          }}
        />
      )}

      {hasCostRateFeature && canUpdateAccess('canViewCostRate') && (
        <Checkbox
          label={t`View cost rates`}
          value={managerAccess.canViewCostRate}
          onChange={(next) => {
            onChange(updateAccess('canViewCostRate', next));
          }}
        />
      )}
    </>
  );

  return (
    <div className={styles.managerPermissions}>
      <div className={styles.managerPermissionsLabel}>
        <Trans>Manager permissions</Trans>
      </div>

      {canUpdateAccess('isProjectManager') && (
        <Checkbox
          label={t`Add and manage Projects, including budgets if viewable`}
          value={managerAccess.isProjectManager}
          onChange={(next) => {
            onChange(updateAccess('isProjectManager', next));
          }}
        />
      )}

      <Checkbox
        label={t`Add and edit People, including rates if viewable`}
        value={managerAccess.canCreateEditPeople}
        onChange={(next) => {
          onChange(updateAccess('canCreateEditPeople', next));
        }}
      />

      {canUpdateAccess('isPeopleManager') && (
        <Checkbox
          label={t`Add and approve time off for People they manage`}
          value={managerAccess.isPeopleManager}
          onChange={(next) => {
            onChange(updateAccess('isPeopleManager', next));
          }}
        />
      )}

      {canUpdateProfitabilityAccess && (
        <>
          <Checkbox
            label={t`View Project profitability`}
            value={managerAccess.canBudget && managerAccess.canViewCostRate}
            onChange={(next) => {
              // Update both - bill rate and cost rate - permissions
              const updatedAccess = setManagerAccess(
                updateAccess('canBudget', next).access,
                'canViewCostRate',
                next,
              );
              onChange({ access: updatedAccess });
            }}
          />

          <div className={styles.profitabilityAccessGroup}>
            {billRateAndCostRateElements}
          </div>
        </>
      )}

      {!canUpdateProfitabilityAccess && billRateAndCostRateElements}

      {hasCostRateFeature && (
        <div className={styles.managerRightsLearnMore}>
          <div className={styles.line}>
            <Trans>
              <a
                className={styles.link}
                href="https://support.float.com/en/articles/5215287-access-rights#h_246e4291f2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>{' '}
              about edit permissions for Managers.
            </Trans>
          </div>
        </div>
      )}
    </div>
  );
}

export function ManagerPermissions(props: ManagerPermissionsProps) {
  const { account, onChange } = props;

  const { hasCostRateFeature, managerAccess, canUpdateAccess, updateAccess } =
    useManagerPermissionsController(account);

  return (
    <ManagerPermissionsComponent
      hasCostRateFeature={hasCostRateFeature}
      managerAccess={managerAccess}
      canUpdateAccess={canUpdateAccess}
      updateAccess={updateAccess}
      onChange={onChange}
    />
  );
}
