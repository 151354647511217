import { WebAppDispatchStrict } from 'lib/store';

import {
  MODAL_MANAGER_HIDE,
  MODAL_MANAGER_SHOW,
  MODAL_MANAGER_UPDATE,
} from './modalManagerReducers';
import type { PartialModalConfig } from './types';
import type { ModalConfig } from './useManageModal';

export function updateModal(modalConfig: PartialModalConfig) {
  return (dispatch: WebAppDispatchStrict) => {
    return dispatch({
      type: MODAL_MANAGER_UPDATE,
      modalType: modalConfig.modalType,
      modalConfig,
    });
  };
}

export default function manageModal({
  visible = true,
  skipSidePanelAutoClose = false,
  modalType,
  modalSettings,
}: ModalConfig) {
  return (dispatch: WebAppDispatchStrict) => {
    if (visible) {
      return dispatch({
        type: MODAL_MANAGER_SHOW,
        skipSidePanelAutoClose,
        modalType,
        modalSettings,
      });
    } else {
      return dispatch({
        type: MODAL_MANAGER_HIDE,
        modalType,
      });
    }
  };
}
