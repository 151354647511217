import React from 'react';
import { t } from '@lingui/macro';
import { isEmpty, omit } from 'lodash';
import styled from 'styled-components';

import { DatePicker } from '@float/common/components/DatePicker';
import { TCore } from '@float/common/earhart/colors';
import { Rights } from '@float/common/lib/acl';
import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import {
  areRatesEqual,
  isCostRateFeatureActiveForUser,
} from '@float/common/lib/rates/rates';
import { useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import * as Label16 from '@float/ui/deprecated/Earhart/Typography/Label16';
import { bindVal } from '@float/ui/deprecated/helpers/forms';
import { Input } from '@float/ui/deprecated/Input';
import { FieldLabel } from '@float/ui/deprecated/Label';
import { Col, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { MultiSelect } from '@float/ui/deprecated/MultiSelect';
import {
  getAccurateNameByAccountId,
  getDirectManagersByPeopleId,
} from '@float/web/selectors/people';
import { DEFAULT_TAG_COLOR } from '@float/web/selectors/tags';

import { PersonDepartment } from './components/PersonDepartment';
import { EditPersonBillRate } from './components/PersonRates/EditPersonBillRate';
import { EditPersonCostRate } from './components/PersonRates/EditPersonCostRate';
import { EditPersonRates } from './components/PersonRates/EditPersonRates';
import { PersonRole } from './components/PersonRole/PersonRole';
import { PersonType } from './components/PersonType/PersonType';
import {
  getHasCustomRate,
  getRateInputPlaceholder,
} from './PersonInfo.helpers';

import * as styles from './PersonModal.styles.css';

let self;

function onPersonTypeChange({ value }) {
  self.setFormState({ people_type_id: value });
}

const MangerNames = styled.span`
  ${Label16.R400};
  color: ${TCore.Emp.High12};
  padding-left: 2px;
`;

export const ReadOnlyManagedBy = ({ personId }) => {
  const accurateNameByAccountId = useAppSelector(getAccurateNameByAccountId);
  const directManagersByPeopleId = useAppSelector(getDirectManagersByPeopleId);
  const managers = directManagersByPeopleId[personId] || [];

  if (isEmpty(managers)) {
    return null;
  }

  return (
    <>
      <FieldLabel>Managed by</FieldLabel>
      <Col alignItems="flex-start">
        <Spacer size={6} />
        <MangerNames>
          {managers
            .map((accountId) => accurateNameByAccountId[accountId])
            .join(', ')}
        </MangerNames>
      </Col>
    </>
  );
};

export default function renderPersonInfo(that) {
  self = that;

  const {
    currencyConfig,
    currentUser,
    departmentOptions,
    peopleTagByLabel,
    personTypeOptions,
    roles,
    rolesOptions,
    tagOptions,
  } = self.props;

  const { form } = self.state;
  const { initialPerson } = self;

  const peopleTagsWithColor = form.tags.map((tag) => {
    const color = peopleTagByLabel[tag.label]?.color
      ? `#${peopleTagByLabel[tag.label].color}`
      : DEFAULT_TAG_COLOR;

    return {
      ...tag,
      color,
    };
  });

  const { value: roleId, onChange: onRoleChange } = bindVal(
    self,
    'role_id',
    null,
  );

  const selectedRole =
    !form.role_id || !roles ? undefined : roles[form.role_id];

  const billRatePlaceholder = getRateInputPlaceholder({
    selectedRole: selectedRole,
    rateKey: 'default_hourly_rate',
  });

  const costRatePlaceholder = getRateInputPlaceholder({
    selectedRole: selectedRole,
    rateKey: 'cost_rate',
  });

  const costRateEffectiveDate = form.cost_rate_effective_date;
  const isCostRateFocused = form.cost_rate_focused;

  const {
    value: billRate,
    onChange: handleBillRateChange,
    errors: billRateErrors,
  } = bindVal(
    self,
    'default_hourly_rate',
    undefined,
    Input.validators.number(6),
  );

  const {
    value: costRate,
    onChange: onCostRateChange,
    errors: costRateErrors,
  } = bindVal(self, 'cost_rate', undefined, Input.validators.number(6));

  const handleCostRateFocusChange = (isFocused = false) => {
    self.setFormState({ cost_rate_focused: isFocused });
  };

  const handleCostRateEffectiveDateChange = (momentDate) => {
    const date = momentDate ? momentDate.format('YYYY-MM-DD') : null;
    self.setFormState({ cost_rate_effective_date: date });
  };

  const handleCostRateChange = (newCostRate) => {
    const isNewCostRateSameAsOriginalCostRate = areRatesEqual(
      newCostRate,
      self.initialPerson?.cost_rate,
    );

    if (isNewCostRateSameAsOriginalCostRate) {
      handleCostRateEffectiveDateChange(null);
    } else if (!costRateEffectiveDate) {
      // Initialize effective date to today, only if it isn't already set
      handleCostRateEffectiveDateChange(moment());
    }

    onCostRateChange(newCostRate);
  };

  const handleCostRateRemove = (date) => {
    self.setFormState({
      cost_rate_history: omit(form.cost_rate_history, date),
      cost_rate_removed_dates: [...(form.cost_rate_removed_dates || []), date],
    });
  };

  // ACT-62: Add placeholder text to People modal -
  // https://linear.app/float-com/issue/ACT-62
  //
  // Determines if custom placeholder text should be displayed in the People
  // modal. Custom placeholder text will appear if no options are available for
  // departments, tags, or roles, providing guidance for new users on how to
  // complete fields in an empty modal.
  //
  // This check is needed to make sure it runs only once per modal open (e.g.,
  // placeholder text does not change when submitting an update)
  if (self.shouldRenderInitialPlaceholder === undefined) {
    self.shouldRenderInitialPlaceholder =
      Object.values(departmentOptions).length === 0 &&
      Object.values(tagOptions).length === 0 &&
      Object.values(rolesOptions).length === 0;
  }

  const canViewCostRate = isCostRateFeatureActiveForUser(currentUser);
  const canViewBillRate = getCanCurrentUserSeeBudgets(currentUser);
  const canViewAllRates = canViewBillRate && canViewCostRate;
  const canViewOnlyBillRate = canViewBillRate && !canViewCostRate;
  const canViewOnlyCostRate = !canViewBillRate && canViewCostRate;

  const roleBillRate = self.initialPerson?.default_hourly_rate_role;
  const roleCostRate = self.initialPerson?.cost_rate_role;
  const hasCustomBillRate = getHasCustomRate({
    hasRateAccess: canViewBillRate,
    personRate: billRate,
    roleRate: roleBillRate,
  });
  const hasCustomCostRate = getHasCustomRate({
    hasRateAccess: canViewCostRate,
    personRate: costRate,
    roleRate: roleCostRate,
  });
  const costRateHistory = form.cost_rate_history;

  return (
    <div>
      <PersonRole
        roleId={roleId}
        rolesOptions={rolesOptions}
        currencyConfig={currencyConfig}
        hasCustomRate={hasCustomBillRate || hasCustomCostRate}
        onRoleChange={onRoleChange}
        placeholder={
          self.shouldRenderInitialPlaceholder
            ? t`e.g., Creative Director, Engineer, Account Manager`
            : t`No role`
        }
      />

      {canViewAllRates && (
        <EditPersonRates
          billRate={billRate}
          billRateErrors={billRateErrors}
          billRatePlaceholder={billRatePlaceholder}
          costRate={costRate}
          costRateEffectiveDate={costRateEffectiveDate}
          costRateHistory={costRateHistory}
          costRateErrors={costRateErrors}
          costRatePlaceholder={costRatePlaceholder}
          currency={currentUser.currency}
          hasCustomBillRate={hasCustomBillRate}
          hasCustomCostRate={hasCustomCostRate}
          isCostRateFocused={isCostRateFocused}
          locale={currentUser.locale}
          onBillRateChange={handleBillRateChange}
          onCostRateChange={handleCostRateChange}
          onCostRateRemove={handleCostRateRemove}
          onCostRateEffectiveDateChange={handleCostRateEffectiveDateChange}
          onCostRateFocusChange={handleCostRateFocusChange}
        />
      )}

      <div style={{ marginBottom: 22 }}>
        <PersonDepartment
          account={self.state.form.account}
          departmentId={self.state.form.department_id}
          departmentOptions={departmentOptions}
          departments={self.props.departments}
          formErrors={self.state.formErrors?.department_id}
          isLimitedByDepartment={self.isUserLimitedByDepartment()}
          onDepartmentChange={self.setFormState}
          placeholder={
            self.shouldRenderInitialPlaceholder
              ? t`e.g., Creative & Design, Engineering, Client Services`
              : t`No department`
          }
        />
      </div>
      <MultiSelect
        creatable={Rights.canCreateTags(currentUser, { tag: { type: 2 } })}
        style={{ marginBottom: 22 }}
        values={peopleTagsWithColor}
        options={tagOptions}
        visibleItems={6}
        errors={self.state.formErrors.tags}
        onAdd={(tag) => {
          self.setFormErrors({
            tags: [],
          });
          self.setFormState({
            tags: [...(form.tags || []), tag],
          });
        }}
        placeholder={
          self.shouldRenderInitialPlaceholder
            ? t`e.g., Illustration, Executive, On-Call`
            : t`No tags`
        }
        onRemove={(tag) => {
          self.setFormErrors({
            tags: [],
          });
          self.setFormState({
            tags: form.tags.filter((t) => t.value !== tag.value),
          });
        }}
      />

      <div className={styles.row}>
        <div className={styles.fields}>
          <PersonType
            currencyErrors={billRateErrors}
            currencyValue={billRate}
            currencyValuePlaceholder={billRatePlaceholder}
            currentUser={currentUser}
            initialPerson={initialPerson}
            onCurrencyChange={handleBillRateChange}
            onPersonTypeChange={onPersonTypeChange}
            person={form}
            personTypeOptions={personTypeOptions}
            personTypeErrors={self.state.formErrors.personType}
          />
          {canViewOnlyBillRate && (
            <EditPersonBillRate
              className={styles.rateInput}
              currency={currentUser.currency}
              errors={billRateErrors}
              locale={currentUser.locale}
              placeholder={billRatePlaceholder}
              value={billRate}
              onChange={handleBillRateChange}
            />
          )}
          {canViewOnlyCostRate && (
            <>
              <EditPersonCostRate
                className={styles.rateInput}
                currency={currentUser.currency}
                errors={costRateErrors}
                history={costRateHistory}
                locale={currentUser.locale}
                placeholder={costRatePlaceholder}
                value={costRate}
                onChange={handleCostRateChange}
                onRemove={handleCostRateRemove}
              />
              {costRateEffectiveDate && (
                <div className={styles.effectiveDateWrapper}>
                  <DatePicker
                    label={t`Effective date`}
                    appearance="outline"
                    value={moment(costRateEffectiveDate)}
                    onChange={handleCostRateEffectiveDateChange}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <ReadOnlyManagedBy personId={form.people_id} />
    </div>
  );
}
