import React from 'react';
import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types/project';
import { Button } from '@float/ui/components/Button';
import {
  getEntityStatusIcon,
  getEntityStatusLabel,
} from '@float/ui/helpers/label/entityStatusLabel';
import { IconCheck } from '@float/ui/icons/essentials/IconCheck';
import * as DropdownMenu from '@float/ui/primitives/DropdownMenu';
import { List } from '@float/ui/primitives/List';

import { useUpdateProject } from './hooks/useUpdateProject';

const items = [
  ProjectStatus.Draft,
  ProjectStatus.Tentative,
  ProjectStatus.Confirmed,
];

export const SingleProjectStatus = ({
  projectId,
  projectStatus,
}: {
  projectId: number;
  projectStatus: ProjectStatus;
}) => {
  const updateProject = useUpdateProject();
  const handleClick = (status: ProjectStatus) => {
    return () => updateProject(projectId, { status });
  };
  const isSelected = (status: ProjectStatus) => {
    return projectStatus === status;
  };
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          size="xsmall"
          appearance="ghost-flay"
          iconOnlyLabel={t`Project status`}
          iconEnd={getEntityStatusIcon(projectStatus)}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content collisionPadding={10}>
        {items.map((status) => (
          <DropdownMenu.Item
            key={status}
            aria-checked={isSelected(status)}
            onClick={handleClick(status)}
          >
            <List.Item.Icon icon={getEntityStatusIcon(status)} />
            <List.Item.Value>{getEntityStatusLabel(status)}</List.Item.Value>
            {isSelected(status) && <List.Item.Icon icon={IconCheck} />}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
