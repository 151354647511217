/*
  This component wraps `InputCurrency` for string-based values. It converts the
  provided string (or number) value and placeholder to numeric types for
  `InputCurrency` while converting the numeric onChange output back to a string.
  Most of the monetary values in Float are stored as strings, so this component
  is useful for handling those values without a bigger refactor.
*/
import React from 'react';
import { isNil } from 'es-toolkit';

import { isAmountEmpty } from '@float/common/lib/budget';
import {
  InputCurrency,
  InputCurrencyProps,
} from '@float/ui/components/InputCurrency/InputCurrency';

export type Props = Omit<
  InputCurrencyProps,
  'value' | 'placeholder' | 'onChange'
> & {
  onChange?: (value: string) => void;
  placeholder?: string | number | null;
  value?: string | number | null;
};

function parseNumberOrUndefined(value: Props['value']): number | undefined {
  if (isNil(value)) return undefined;

  if (typeof value === 'string' && !value.length) return undefined;

  return Number(value);
}

export function InputCurrencyString(props: Props) {
  const { value, placeholder, onChange, ...rest } = props;

  const isEmpty = isAmountEmpty(value);
  const placeholderValue = placeholder || (isEmpty ? 0 : undefined);

  const handleChange = (newValue: number | null) => {
    if (!onChange) return;

    if (newValue === undefined || newValue === null || isNaN(newValue)) {
      onChange('');
      return;
    }

    onChange(typeof newValue === 'number' ? newValue.toString() : '');
  };

  return (
    <InputCurrency
      {...rest}
      placeholder={parseNumberOrUndefined(placeholderValue)}
      value={parseNumberOrUndefined(value)}
      onChange={handleChange}
    />
  );
}
