import {
  SearchResolveEntityKey,
  SearchResolveResponse,
} from '@float/common/api3/search.types';
import type { SearchResultsState } from '@float/common/store/searchResults/searchResults.types';

import { RESOLVE_RESULT_KEY_TO_SEARCH_RESULT_KEY } from '../constants';

export function convertResolveResultsToSearchResults(
  result: SearchResolveResponse['result'],
) {
  const searchResult = {} as Omit<SearchResultsState, 'active'>;

  for (const [key, values] of Object.entries(result)) {
    const searchResultKey =
      RESOLVE_RESULT_KEY_TO_SEARCH_RESULT_KEY[key as SearchResolveEntityKey];

    if (searchResultKey === 'timeoffs' || searchResultKey === 'tasks') {
      searchResult[searchResultKey] = new Set(
        values.map((value) => String(value)),
      );
    } else if (searchResultKey === 'loggedTimes') {
      searchResult.loggedTimes = new Set(values as string[]);
    } else {
      searchResult[searchResultKey] = new Set(values as number[]);
    }
  }

  return searchResult;
}
