import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { t } from '@lingui/macro';
import cn from 'classnames';

import { selectUserHasManagerAccessRights } from '@float/common/selectors/userAccessRights';
import { ScheduleContextProvider } from '@float/common/serena/ScheduleContext';
import { useIsSmallMediaSizeActive } from '@float/libs/hooks/media';
import { Col, Flex } from '@float/ui/deprecated/Layout/Layout';
import withTitle from '@float/web/components/decorators/withTitle';
import { Modals } from '@float/web/components/modals';
import NoSession from '@float/web/components/modals/NoSession';
import Nav from '@float/web/components/Nav/Nav';
import { NavProvider } from '@float/web/components/Nav/NavContext';
import SharedLinkNav from '@float/web/components/Nav/SharedLinkNav';
import { MainSideNav } from '@float/web/components/Nav/SideNav';
import { useWebAppSelectorStrict } from '@float/web/lib/store';
import People from '@float/web/manage/people-v2/People';
import Projects from '@float/web/manage/projects-v2/Projects';
import { Reports } from '@float/web/reports/Reports';
import {
  getIsCurrentUserGuest,
  getLockPeriodDates,
  getPeopleMap,
  getPmSidebarVisibility,
  getUser,
} from '@float/web/selectors';
import { Serena as SerenaSchedule } from '@float/web/serena';

import { getRouteState } from './Portal.helpers';
import { RouteWithGuard } from './RouteWithGuard';

import * as styles from './Portal.css';

const SCHEDULE_MATCH = ['/', '/share/:token'];

export const Portal: React.FC = () => {
  const location = useLocation();
  const user = useWebAppSelectorStrict(getUser);
  const allPeople = useWebAppSelectorStrict(getPeopleMap);
  const { isSidebarOpen } = useWebAppSelectorStrict(getPmSidebarVisibility);
  const lockPeriodDates = useWebAppSelectorStrict(getLockPeriodDates);
  const userHasManagerAccessRights = useWebAppSelectorStrict(
    selectUserHasManagerAccessRights,
  );
  const isGuest = useWebAppSelectorStrict(getIsCurrentUserGuest);
  const routeState = getRouteState(location.pathname);
  const isSmallBreakpointActive = useIsSmallMediaSizeActive();

  return (
    <div className={'app-wrapper'}>
      <ScheduleContextProvider
        user={user}
        allPeople={allPeople}
        location={location}
        isSidebarOpen={isSidebarOpen}
        isSharedLink={routeState.isSharedLink}
        lockPeriodDates={lockPeriodDates}
      >
        <NoSession />
        <Modals />
        <NavProvider>
          {routeState.isSharedLink ? (
            <Flex alignItems="flex-start">
              <Col className={styles.appWrapper}>
                <SharedLinkNav />
                <div
                  className={cn(styles.appInner, routeState.route, {
                    scrollable: routeState.scrollable,
                  })}
                >
                  <Route
                    path={SCHEDULE_MATCH}
                    exact
                    render={withTitle(t`Schedule | Float`, SerenaSchedule)}
                  />
                </div>
              </Col>
            </Flex>
          ) : (
            <Flex alignItems="flex-start">
              {isSmallBreakpointActive ? (
                <div id="mobile-menu-portal" data-testid="mobile-menu-portal" />
              ) : (
                <>
                  {/* @ts-expect-error MainSideNav is not typed */}
                  <MainSideNav />
                </>
              )}
              <Col className={styles.appWrapper}>
                <Nav location={location} />
                <div
                  className={cn(styles.appInner, routeState.route, {
                    scrollable: routeState.scrollable,
                  })}
                >
                  <Switch>
                    {' '}
                    <Route
                      path={SCHEDULE_MATCH}
                      exact
                      render={withTitle(t`Schedule | Float`, SerenaSchedule)}
                    />
                    <Route
                      path="/project-plan"
                      render={withTitle(
                        t`Project plan | Float`,
                        SerenaSchedule,
                      )}
                    />
                    <Route
                      path="/project/:id"
                      render={withTitle(t`Project | Float`, SerenaSchedule)}
                    />
                    <Route
                      // Since `/manage` was defaulting to `people`
                      // We'll redirect all /manage routes to /people for backward compatibility
                      path="/manage"
                      render={() => <Redirect to="/people" />}
                    />
                    <RouteWithGuard
                      guard={userHasManagerAccessRights}
                      path="/people"
                      render={withTitle(t`People | Float`, People)}
                    />
                    <RouteWithGuard
                      guard={userHasManagerAccessRights}
                      path="/projects"
                      render={withTitle(t`Projects | Float`, Projects)}
                    />
                    <Route
                      path="/report"
                      render={withTitle(t`Report | Float`, Reports)}
                    />
                    <RouteWithGuard
                      guard={!isGuest}
                      path="/log-time"
                      render={withTitle(t`Log my time | Float`, SerenaSchedule)}
                    />
                  </Switch>
                  <img className="edge-cursor" />
                </div>
              </Col>
            </Flex>
          )}
        </NavProvider>
      </ScheduleContextProvider>
    </div>
  );
};
