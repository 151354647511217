import { Project, ProjectOption } from '@float/types';

/**
 * Transforms a Project object into a ProjectOption object.
 * ProjectOption objects are used in dropdowns and other UI components, including `VirtualSelect`.
 *
 * @param {Project} proj - The project object to transform.
 * @param {boolean} isProjectCodesEnabled - Feature flag indicating whether project codes should be included in the description.
 * @returns {ProjectOption} The transformed project option object.
 *
 * @example
 * const project = {
 *   project_id: 1,
 *   project_name: 'Project Alpha',
 *   project_code: 'PA123',
 *   client_name: 'Client A',
 *   client_id: 1,
 *   color: 'ff0000'
 * };
 * const isProjectCodesEnabled = true;
 * const projectOption = getProjectOption(project, isProjectCodesEnabled);
 * // projectOption will be:
 * // {
 * //   label: 'Project Alpha',
 * //   description: 'PA123',
 * //   value: '1',
 * //   client_name: 'Client A',
 * //   selectedLabel: 'Client A / Project Alpha',
 * //   color: 'ff0000'
 * // }
 */
export const getProjectOption = (
  proj: Project,
  isProjectCodesEnabled: boolean = false,
): ProjectOption => ({
  label: proj.project_name,
  description: isProjectCodesEnabled ? proj.project_code : '',
  value: `${proj.project_id}`,
  client_name: proj.client_name,
  selectedLabel: proj.client_id
    ? `${proj.client_name} / ${proj.project_name}`
    : proj.project_name,
  color: proj.color,
});
