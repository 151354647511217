import { useMemo } from 'react';
import { t } from '@lingui/macro';

import { moment } from '@float/libs/moment';
import { rangeOpts } from '@float/ui/deprecated/DateRangePicker/config';
import type { Project } from '@float/types/project';

type UseProjectRangeOptsParams = Pick<
  Project,
  'calculated_start_date' | 'calculated_end_date' | 'start_date' | 'end_date'
>;

export function getEffectiveDateRange(params: UseProjectRangeOptsParams) {
  const {
    calculated_end_date: calculatedEndDate,
    calculated_start_date: calculatedStartDate,
    end_date: endDate,
    start_date: startDate,
  } = params;

  // For start date, prioritize the earliest date
  let effectiveStartDate = startDate || calculatedStartDate;
  if (startDate && calculatedStartDate && calculatedStartDate < startDate) {
    effectiveStartDate = calculatedStartDate;
  }

  // For end date, prioritize the latest date
  let effectiveEndDate = endDate || calculatedEndDate;
  if (endDate && calculatedEndDate && calculatedEndDate > endDate) {
    effectiveEndDate = calculatedEndDate;
  }

  return { startDate: effectiveStartDate, endDate: effectiveEndDate };
}

export function useProjectRangeOpts(params: UseProjectRangeOptsParams) {
  const { startDate, endDate } = getEffectiveDateRange(params);

  return useMemo(() => {
    const today = moment();
    const projectStart = startDate ? moment(startDate) : today;
    const entireProjectOpt = [
      {
        label: t`Entire project`,
        value: 'entireProject',
        start: startDate ? () => moment(startDate) : () => moment(),
        end: endDate ? () => moment(endDate) : () => moment(),
      },
      {
        label: t`Project to date`,
        value: 'projectToDate',
        start: () => projectStart,
        end: () => {
          const endDate = moment().add(-1, 'days');
          return endDate.isSameOrAfter(projectStart) ? endDate : today;
        },
      },
    ];

    return {
      rangeOpts: [...entireProjectOpt, ...rangeOpts],
    };
  }, [startDate, endDate]);
}
