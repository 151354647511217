import React from 'react';
import { Trans } from '@lingui/macro';

import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect';
import EHIconEye from '@float/ui/deprecated/Earhart/Icons/Icon/IconEye';
import { Col, Row, Spacer } from '@float/ui/deprecated/Layout/Layout';

import { DepartmentsAccess } from '../DepartmentsAccess';
import { PersonAccessOption } from '../PersonAccessOptions';
import { CanViewComponentProps, CanViewProps } from './types';
import { useCanViewController } from './useCanViewController';

import * as styles from '../PersonAccess.styles.css';

export function CanViewComponent(props: CanViewComponentProps) {
  const { value, options, onChange } = props;

  return (
    <Row justifyContent="space-between" alignItems="center">
      <div className={styles.iconBg}>
        <EHIconEye />
      </div>
      <Spacer size={16} />
      <Col alignItems="flex-start">
        <div className={styles.label}>
          <Trans>Can view</Trans>
        </div>
        <Spacer size={6} />
        <div className={styles.description}>
          <Trans>Specify which People they can see</Trans>
        </div>
      </Col>
      <DropdownSelect
        appearance="default"
        withIconLeft={true}
        minSelectWidth={260}
        value={value}
        options={options}
        onChange={onChange}
      />
    </Row>
  );
}

export function CanView(props: CanViewProps) {
  const { account, formErrors } = props;
  const { value, options, onChange } = useCanViewController(props);

  return (
    <Col>
      <CanViewComponent value={value} options={options} onChange={onChange} />

      {value === PersonAccessOption.Department && (
        <>
          <Spacer size={16} />
          <Row>
            <DepartmentsAccess
              account={account}
              formErrors={formErrors}
              onChange={props.onChange}
            />
          </Row>
        </>
      )}
    </Col>
  );
}
