import React from 'react';
import { Trans } from '@lingui/macro';

import { Tag } from '@float/ui/components/Tag';
import type { TagProps } from '@float/ui/components/Tag';

export const ArchivedTag = (props: TagProps) => {
  return (
    <Tag {...props} color="secondary">
      <Trans>Archived</Trans>
    </Tag>
  );
};
