import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

type Cost = {
  scheduled: number;
  logged: number;
  future: number;
};

export const getScheduledCost = (cost: Cost) => {
  return cost.scheduled;
};

export const getLoggedCost = (cost: Cost) => {
  return cost.logged;
};

export const getCombinedCost = (cost: Cost) => {
  return cost.logged + cost.future;
};

export const getCost = (mode: ChartComparisonModes, cost: Cost) => {
  if (mode === ChartComparisonModes.COMBINED) {
    return getCombinedCost(cost);
  }
  if (mode === ChartComparisonModes.LOGGED) {
    return getLoggedCost(cost);
  }
  return getScheduledCost(cost);
};
