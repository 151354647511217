import { Dispatch } from 'redux';

import { config } from '@float/libs/config';
import { Client, RawClient } from '@float/types';

import API3 from '../api3';
import { ClientsAction, ClientsState } from '../reducers/clients';
import { LoadState } from '../reducers/lib/types';
import { AppDispatchStrict } from '../store';

export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';
export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';
export const CLIENTS_BULK_UPDATED = 'CLIENTS_BULK_UPDATED';

export const CLIENT_LOAD_START = 'CLIENT_LOAD_START';
export const CLIENT_LOAD_FAILED = 'CLIENT_LOAD_FAILED';
export const CLIENT_LOAD_FINISH = 'CLIENT_LOAD_FINISH';

export const handleClientDeletedNotification =
  (client: Client) => (dispatch: AppDispatchStrict) => {
    const { client_id: clientId } = client;
    if (!clientId) {
      return;
    }

    dispatch({ type: 'DELETE_CLIENT_SUCCESS', id: clientId, payload: client });
  };

export const ensureClientsLoaded =
  (forceLoad?: boolean) =>
  async (
    dispatch: Dispatch<ClientsAction>,
    getState: () => { clients: ClientsState },
  ) => {
    const { loadState: currentLoadState } = getState().clients;

    if (config.isSharedView) {
      return;
    }

    if (!forceLoad && currentLoadState === LoadState.LOADED) return;
    if (currentLoadState === LoadState.LOADING) return; // There's already an in-flight load request

    try {
      dispatch({ type: CLIENT_LOAD_START });
      const clients: RawClient[] = await API3.getAllClients();

      dispatch({ type: CLIENT_LOAD_FINISH, clients });
      return clients;
    } catch (e) {
      dispatch({ type: CLIENT_LOAD_FAILED });
    }
  };
