import { pick } from 'lodash';

import { trackEvent } from '../lib/analytics';
import { requestEntranceAnimation } from '../lib/animateEntrance';
import request from '../lib/request';
import { isTaskReferenceId } from '../serena/Data/useCells/helpers/getTaskReference';

export const LOGGED_TIME_LOAD_START = 'loggedTime/LOAD_START';
export const LOGGED_TIME_LOAD_FAILED = 'loggedTime/LOAD_FAILED';
export const LOGGED_TIME_LOAD_FINISH = 'loggedTime/LOAD_FINISH';
export const LOGGED_TIME_HOURS_LOAD_START = 'loggedTime/HOURS_LOAD_START';
export const LOGGED_TIME_HOURS_LOAD_FINISH = 'loggedTime/HOURS_LOAD_FINISH';
export const LOGGED_TIME_HOURS_LOAD_FAIL = 'loggedTime/HOURS_LOAD_FAIL';
export const LOGGED_TIME_CREATED = 'loggedTime/CREATED';
export const LOGGED_TIME_UPDATED = 'loggedTime/UPDATED';
export const LOGGED_TIME_DELETED = 'loggedTime/DELETED';
export const LOGGED_TIME_BULK_CREATED = 'loggedTime/BULK_CREATED';
export const LOGGED_TIME_BULK_CREATED_UNDO = 'loggedTime/BULK_DELETED';

const REQUEST_PROPS = [
  'logged_time_id',
  'hours',
  'people_id',
  'project_id',
  'phase_id',
  'date',
  'reference_date',
  'task_name',
  'task_meta_id',
  'task_id',
  'priority',
  'notes',
];

export function formatForRequest(loggedTime) {
  const data = pick(loggedTime, REQUEST_PROPS);
  if (data.task_meta_id) {
    delete data.task_name;
  } else {
    delete data.task_meta_id;
    data.task_name = data.task_name || '';
  }
  return data;
}

function trackFeatureUsage({ loggedTime, isCreate, sidebarItemType }) {
  if (isCreate && loggedTime.hours) {
    const props = sidebarItemType ? { sidebarItemType } : undefined;
    trackEvent(
      `Logged ${loggedTime.task_id ? 'Suggested' : 'New'} Task`,
      props,
    );
  } else if (!loggedTime.logged_time_id && !loggedTime.hours) {
    trackEvent('Removed Suggested Task');
  }
}

function fetchedLoggedTimes(loggedTimes, shouldRefresh) {
  return {
    type: LOGGED_TIME_LOAD_FINISH,
    loggedTimes,
    shouldRefresh,
  };
}

// TODO: earhart - Extract chunked processing logic for reuse between
// similar task, timeoff and logged time actions. The point of chunked
// and spaced out processing is to render in smaller batches of items,
// not blocking the main thread for too long on larger teams. It also
// preps us for potentially fetching from a streaming endpoint in the future.
export const fetchLoggedTimesWithDates =
  (start, end, rebuild) => (dispatch) => {
    dispatch({ type: LOGGED_TIME_LOAD_START });
    try {
      return new Promise((resolve, reject) => {
        const queue = [];
        const CHUNK_SIZE = 500;

        function processQueue() {
          const chunk = queue.splice(0, CHUNK_SIZE);
          dispatch(fetchedLoggedTimes(chunk, rebuild));

          if (queue.length) {
            setTimeout(processQueue, 10);
          } else {
            resolve();
          }
        }

        request
          .get(
            'logged-time',
            {
              start_date:
                typeof start === 'string' ? start : start.format('YYYY-MM-DD'),
              end_date:
                typeof end === 'string' ? end : end.format('YYYY-MM-DD'),
            },
            { version: 'f3', includeHeaders: true },
          )
          .then((res) => {
            queue.push(...res.data);
            processQueue();
          });
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: LOGGED_TIME_LOAD_FAILED });
    }
  };

export const fetchLoggedTime =
  (personId, start, end, shouldRefresh) => async (dispatch, getState) => {
    const { fetchedLoggedTime } = getState().loggedTimes;
    const fetchKey = `${personId}:${start}`;

    if (!personId) return;
    if (!shouldRefresh && fetchedLoggedTime[fetchKey]) return;

    try {
      dispatch({ type: LOGGED_TIME_LOAD_START });

      const res = await request.get(
        `logged-time?people_id=${personId}&start_date=${start}&end_date=${end}`,
        undefined,
        {
          version: 'f3',
        },
      );

      dispatch({
        type: LOGGED_TIME_LOAD_FINISH,
        loggedTimes: res,
        fetchKey,
        shouldRefresh,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: LOGGED_TIME_LOAD_FAILED });
    }
  };

export const createLoggedTime =
  (loggedTime, previous, isRedo = false) =>
  async (dispatch) => {
    const { logged_time_id: id } = loggedTime;

    const isCreate = !id || isTaskReferenceId(id);
    const sidebarItemType = previous && previous.sidebarItemType;

    if (isCreate) {
      delete loggedTime.logged_time_id;
    }

    const payload = [formatForRequest(loggedTime)];
    const res = await (isCreate
      ? request.post('logged-time', payload, { version: 'f3' })
      : request.put(`logged-time/${id}${isRedo ? '?redo=1' : ''}`, payload, {
          version: 'f3',
        }));

    if (isCreate) {
      // LoggedTime is loaded into the Serena cells with ignoreMissing because we
      // don't want to clobber the ephemeral TaskReferences that exist. Therefore,
      // when we create a real LoggedTime from a TaskReference, we need to remove
      // that previous TaskReference.
      requestEntranceAnimation(res[0]);
      res[0].replacesTempId = id;
    }

    trackFeatureUsage({ loggedTime, isCreate, sidebarItemType });

    return dispatch({
      type: isCreate ? LOGGED_TIME_CREATED : LOGGED_TIME_UPDATED,
      loggedTime: res[0],
      createdAt: Date.now(),
      previous: isCreate ? undefined : previous,
    });
  };

export const deleteLoggedTime = (loggedTime) => async (dispatch) => {
  const { logged_time_id: id, task_id } = loggedTime;
  await request.del(`logged-time/${id}?task_id=${task_id}`, null, {
    version: 'f3',
  });

  return dispatch({
    type: LOGGED_TIME_DELETED,
    loggedTime,
    id,
    task_id: loggedTime.task_id,
    createdAt: Date.now(),
  });
};

export const ensureLoggedHoursLoaded =
  ({ personId, start, end }) =>
  async (dispatch, getState) => {
    const fetchKey = `${personId}:${start}`;

    const { loadingLoggedHours } = getState().loggedTimes;

    if (!personId) return;
    if (
      loadingLoggedHours[fetchKey] === 'FETCHED' ||
      loadingLoggedHours[fetchKey] === 'FETCHING'
    )
      return;

    dispatch({
      type: LOGGED_TIME_HOURS_LOAD_START,
      fetchKey,
    });

    try {
      const res = await request.get(
        'logged-time/summary',
        {
          people_id: personId,
          start_date: start,
          end_date: end,
        },
        { version: 'f3' },
      );

      dispatch({
        type: LOGGED_TIME_HOURS_LOAD_FINISH,
        loggedHours: res,
        fetchKey,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: LOGGED_TIME_HOURS_LOAD_FAIL,
        fetchKey,
      });
    }
  };

export const bulkCreateLoggedTimes =
  (loggedTimes, tempIds) => async (dispatch) => {
    const payload = loggedTimes.map(formatForRequest);
    const res = await request.post('logged-time', payload, {
      version: 'f3',
      timeout: 60000,
    });

    // LoggedTime is loaded into the Serena cells with ignoreMissing because we
    // don't want to clobber the ephemeral TaskReferences that exist. Therefore,
    // when we create a real LoggedTime from a TaskReference, we need to remove
    // that previous TaskReference.
    res.forEach((item, i) => {
      item._animateEntrance = true;
      item.replacesTempId = tempIds[i];
    });

    return dispatch({
      type: LOGGED_TIME_BULK_CREATED,
      loggedTime: res,
      createdAt: Date.now(),
    });
  };

export const undoBulkCreateLoggedTimes = (ids) => async (dispatch) => {
  await request.post('logged-time/delete', ids, {
    version: 'f3',
    timeout: 60000,
  });
  return dispatch({ type: LOGGED_TIME_BULK_CREATED_UNDO, ids });
};
