import { useWebAppSelectorStrict } from 'lib/store';
import { orderBy } from 'lodash';

import { getPeopleByRoleId } from '@float/common/selectors/people/people';

import { getRoleTableHeadings } from './helpers/getRoleTableHeadings';
import { getRoleTableRow } from './helpers/getRoleTableRow';
import { TableSortOptions } from './useRolesTableSort';

type UseRolesTableDataProps = {
  isCostRateFeatureActive: boolean;
  isRolesRatesFeatureEnabled: boolean;
  tableSort?: TableSortOptions;
};

/**
 * Returns roles table data formatted for table consumption
 */
export const useRolesTableData = ({
  isCostRateFeatureActive,
  isRolesRatesFeatureEnabled,
  tableSort,
}: UseRolesTableDataProps) => {
  const stateRoles = useWebAppSelectorStrict((state) => state.roles);
  const statePeopleByRoleId = useWebAppSelectorStrict(getPeopleByRoleId);

  const { tableHeadNames, tableHeadNameKeys, tableColumnsWidth } =
    getRoleTableHeadings(isRolesRatesFeatureEnabled, isCostRateFeatureActive);

  const tableRowsUnsorted = Object.values(stateRoles.roles)
    .map((role) => ({
      ...role,
      people_count: statePeopleByRoleId[role.id]?.length || 0,
    }))
    .map((role) =>
      getRoleTableRow({
        isCostRateFeatureActive,
        isRolesRatesFeatureEnabled,
        role,
      }),
    );

  const tableRows = orderBy(
    tableRowsUnsorted,
    (row) => {
      if (!tableSort) return row.entity.name;

      const value = row.entity[tableSort['sortBy']];

      if (tableSort.sortBy === 'default_hourly_rate')
        return parseFloat((value as string | null) || '-1'); // the unset values should come befor '0' values

      if (typeof value === 'string') return value.toLowerCase();

      return value;
    },
    tableSort?.sortDir,
  );

  return {
    tableSort,
    tableRows,
    tableHeadNames,
    tableHeadNameKeys,
    tableColumnsWidth,
  };
};
