import { useCallback } from 'react';

import { useAppStoreStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { getQueryAttributes } from '../api3/search.helpers';
import { selectIsProjectCodesEnabled } from '../selectors/projects';
import { queryApi } from './api/queryApi';
import { getSearchAutocompleteResults } from './selectors/getSearchAutocompleteResults';
import { searchService } from './service';
import type { SearchAutocompleteParams } from './selectors/getSearchAutocompleteResults';

export function usePaginatedSearchAutocompleteCallback() {
  const store = useAppStoreStrict();

  return useCallback(
    async (params: SearchAutocompleteParams) => {
      if (!featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
        const state = store.getState();

        const filteredContext =
          await searchService.getSearchAutocompleteResults(state, params);

        return {
          filteredContext,
          // When the local search is enabled, we don't need to paginate
          hasNextPage: () => false,
          fetchNextPage: () => Promise.reject({}),
        };
      }

      const projectCodesEnabled = selectIsProjectCodesEnabled(store.getState());

      const queryResult = await queryApi(
        {
          query: params.rawInput,
          category: params.expandedCategory,
          attributes: getQueryAttributes({
            category: params.expandedCategory,
            projectCodesEnabled,
          }),
        },
        { projectCodesEnabled },
      );

      async function fetchNextPage() {
        return getSearchAutocompleteResults(store.getState(), {
          ...params,
          remoteQueryResult: {
            count: queryResult.count,
            items: await queryResult.fetchNextPage(),
          },
        });
      }

      return {
        filteredContext: getSearchAutocompleteResults(store.getState(), {
          ...params,
          remoteQueryResult: queryResult,
        }),
        hasNextPage: queryResult.hasNextPage,
        fetchNextPage,
      };
    },
    [store],
  );
}
