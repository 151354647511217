import {
  Account,
  Client,
  CurrentUser,
  Department,
  LoggedTime,
  LoggedTimeDeletePlaceholder,
  Person,
  Phase,
  Project,
  SearchLoggedTime,
  SearchTask,
  SearchTimeoff,
  Task,
  Timeoff,
} from '@float/types';

import {
  FiltersContext,
  ProjectOwnersSearchToken,
  ProjectsSearchToken,
  ProjectTagsSearchToken,
  TimeoffsSearchToken,
} from '../../../types';
import { getProjectPeopleIds } from './getters';
import {
  matchLoggedTaskByName,
  matchPersonByType,
  matchProjectByStatus,
  matchString,
  matchStringWithoutNormalize,
  matchTaskByName,
  matchTaskByStatus,
  matchTimeoffByStatus,
} from './matchers';

export function getAccountsIdsByName(
  { accounts }: { accounts: Record<string, Account> },
  value: string,
  partial: boolean,
) {
  const accountIds = new Set<number>();

  for (const account of Object.values(accounts)) {
    if (account && matchString(account.name, value, partial)) {
      accountIds.add(account.account_id);
    }
  }

  return accountIds;
}

export function getClientsIdsByName(
  { clients }: { clients: Record<string, Client> },
  value: string,
  partial: boolean,
  noClient = true,
) {
  const clientIds = new Set<number>();

  if (value === 'no client' && noClient) {
    return new Set([undefined]);
  }

  for (const client of Object.values(clients)) {
    if (client && matchString(client.client_name, value, partial)) {
      clientIds.add(client.client_id);
    }
  }

  return clientIds;
}

export function getDeparmentsIdsByName(
  { departments }: { departments: Record<string, Department> },
  value: string,
  partial: boolean,
) {
  const departmentIds = new Set<number>();

  if (value === 'none') {
    return new Set([undefined, 0]);
  }

  const checkIsMatch = (department: Department): boolean => {
    if (!department) return false;

    const isMatch = matchString(department.name, value, partial);
    if (isMatch) {
      return true;
    }

    if (department.parent_id) {
      const parent = departments[department.parent_id];
      return checkIsMatch(parent);
    }

    return false;
  };

  for (const department of Object.values(departments)) {
    if (department) {
      const isMatch = checkIsMatch(department);
      if (isMatch) {
        departmentIds.add(department.id);
      }
    }
  }

  return departmentIds;
}

export const getPeopleIdsByClient = (
  { search }: Pick<FiltersContext<'people'>, 'search'>,
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();

  for (const { normalizedVal, peopleIds: clientPeopleIds } of search.clients) {
    if (matchStringWithoutNormalize(normalizedVal, value, partial)) {
      for (const id of clientPeopleIds) {
        peopleIds.add(id);
      }
    }
  }

  return peopleIds;
};

export const getPeopleIdsByName = (
  { people }: Pick<FiltersContext<'tasks'>, 'people'>,
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();

  for (const person of Object.values(people)) {
    if (person && matchString(person.name, value, partial)) {
      peopleIds.add(person.people_id);
    }
  }

  return peopleIds;
};

export const getPeopleIdsByManager = (
  context: Pick<FiltersContext<'people'>, 'accounts' | 'search'>,
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();
  const { accountManagedPeople, accurateNameByAccountId } = context.search;

  for (const account of Object.values(context.accounts)) {
    const peopleIdsList = account && accountManagedPeople[account.account_id];
    const accountName = accurateNameByAccountId[account.account_id];
    if (peopleIdsList && matchString(accountName, value, partial)) {
      for (const personId of peopleIdsList) {
        peopleIds.add(personId);
      }
    }
  }

  return peopleIds;
};

export const getPeopleIdsByPhase = (
  { search }: Pick<FiltersContext<'people'>, 'search'>,
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();

  for (const { normalizedVal, allPeopleIds: phasePeopleIds } of search.phases) {
    if (matchStringWithoutNormalize(normalizedVal, value, partial)) {
      for (const id of phasePeopleIds) {
        peopleIds.add(id);
      }
    }
  }

  return peopleIds;
};

export const getPeopleIdsByProject = (
  {
    search,
    projects,
  }: {
    search: { projects: ProjectsSearchToken[] };
    projects: Record<string, Project>;
  },
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();
  const matchedProjects: Record<number, Project> = {};

  for (const {
    normalizedVal,
    subVal,
    projectId,
    allPeopleIds: itemPeopleIds,
  } of search.projects) {
    // handles partial matches also for composite keys using project name and project code (sub value).
    if (
      matchStringWithoutNormalize(
        normalizedVal,
        value,
        partial || Boolean(subVal),
      )
    ) {
      for (const id of itemPeopleIds) {
        peopleIds.add(id);
        matchedProjects[projectId] = projects[projectId];
      }
    }
  }

  return { peopleIds, matchedProjects };
};

export const getPeopleIdsByProjectOwner = (
  { search }: { search: { projectOwners: ProjectOwnersSearchToken[] } },
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();

  for (const {
    normalizedVal,
    allPeopleIds: itemPeopleIds,
  } of search.projectOwners) {
    if (matchStringWithoutNormalize(normalizedVal, value, partial)) {
      for (const id of itemPeopleIds) {
        peopleIds.add(id);
      }
    }
  }

  return peopleIds;
};

export const getPeopleIdsByProjectStatus = (
  {
    projects,
    user,
    search,
  }: {
    projects: Record<string, Project>;
    user: CurrentUser;
    search: { projectAllPeople: Record<number, Set<number>> };
  },
  value: string,
) => {
  const peopleIds = new Set<number>();
  const matchedProjects: Record<number, Project> = {};

  for (const project of Object.values(projects)) {
    if (matchProjectByStatus(project, user, value)) {
      for (const id of getProjectPeopleIds(project, search)) {
        peopleIds.add(id);
        matchedProjects[project.project_id] = project;
      }
    }
  }

  return { peopleIds, matchedProjects };
};

export const getPeopleIdsByProjectTag = (
  {
    search,
    projects,
  }: {
    search: {
      projectTags: ProjectTagsSearchToken[];
      projectAllPeople: Record<number, Set<number>>;
    };
    projects: Record<string, Project>;
  },
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();
  const matchedProjects: Record<number, Project> = {};

  for (const { normalizedVal, projectIds } of search.projectTags) {
    if (matchStringWithoutNormalize(normalizedVal, value, partial)) {
      if (projectIds) {
        for (const projectId of projectIds) {
          const project = projects[projectId];

          if (project) {
            for (const id of getProjectPeopleIds(project, search)) {
              peopleIds.add(id);
              matchedProjects[projectId] = project;
            }
          }
        }
      }
    }
  }

  return { peopleIds, matchedProjects };
};

export const getPeopleIdsByTag = (
  { people }: { people: Record<string, Person> },
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();

  for (const person of Object.values(people)) {
    if (person && person.tags) {
      for (const tag of person.tags) {
        if (matchString(tag.name, value, partial)) {
          peopleIds.add(person.people_id);
        }
      }
    }
  }

  return peopleIds;
};

export const getPeopleIdsByTask = (
  {
    tasks,
    loggedTimes,
    isLogTimeView,
  }: {
    tasks: Record<string, SearchTask | Task>;
    loggedTimes: Array<
      SearchLoggedTime | LoggedTime | LoggedTimeDeletePlaceholder
    >;
    isLogTimeView: boolean;
  },
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();
  const allTasks = Object.values(tasks);
  const taskSuggestionsDeleted = new Set<string>();

  if (isLogTimeView) {
    for (const loggedTime of loggedTimes) {
      if (loggedTime.data_type !== 'delete_placeholder') {
        const { people_id } = loggedTime;

        if (matchLoggedTaskByName(loggedTime, value, partial)) {
          if ('hours' in loggedTime && loggedTime.hours === 0) {
            if (loggedTime.task_id) {
              taskSuggestionsDeleted.add(loggedTime.task_id.toString());
            }
          } else if (people_id) {
            peopleIds.add(people_id);
          }
        }
      }
    }
  }

  for (const task of allTasks) {
    if (!task) continue;

    // In log time view we want to filter by the visible task
    // suggestions. We can detect if the user has deleted one of them
    // if the relative loggedTime has hours === 0
    if (isLogTimeView) {
      const taskId = task.task_id.toString();

      if (taskSuggestionsDeleted.has(taskId)) {
        continue;
      }
    }

    if (matchTaskByName(task, value, partial)) {
      for (const id of task.people_ids) {
        peopleIds.add(id);
      }
    }
  }

  return peopleIds;
};

export const getPeopleIdsByTimeoff = (
  { search }: { search: { timeoffs: TimeoffsSearchToken[] } },
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();

  for (const {
    normalizedVal,
    allPeopleIds: itemPeopleIds,
  } of search.timeoffs) {
    if (matchStringWithoutNormalize(normalizedVal, value, partial)) {
      for (const id of itemPeopleIds) {
        peopleIds.add(id);
      }
    }
  }

  return peopleIds;
};

export const getPeopleIdsByTimeoffStatus = (
  {
    timeoffs,
    user,
  }: {
    timeoffs: Record<string, SearchTimeoff | Timeoff>;
    user: CurrentUser;
  },
  value: string,
) => {
  const peopleIds = new Set<number>();

  for (const timeoff of Object.values(timeoffs)) {
    if (timeoff && matchTimeoffByStatus(timeoff, user, value)) {
      for (const id of timeoff.people_ids) {
        peopleIds.add(id);
      }
    }
  }

  return peopleIds;
};

export const getPeopleIdsByType = (
  { people }: { people: Record<string, Person> },
  value: string,
) => {
  const peopleIds = new Set<number>();

  for (const person of Object.values(people)) {
    if (matchPersonByType(person, value)) {
      peopleIds.add(person.people_id);
    }
  }

  return peopleIds;
};

export const getPeopleIdsByJobTitle = (
  { people }: { people: Record<string, Person> },
  value: string,
  partial: boolean,
) => {
  const peopleIds = new Set<number>();

  for (const person of Object.values(people)) {
    if (person && matchString(person.job_title, value, partial)) {
      peopleIds.add(person.people_id);
    }

    // special case for Role is None.
    if (person && !person.job_title && value === 'none') {
      peopleIds.add(person.people_id);
    }
  }

  return peopleIds;
};

export function getPhasesIdsByName(
  { phases }: { phases: Record<string, Phase> },
  value: string,
  partial: boolean,
) {
  const phaseIds = new Set<number>();

  for (const phase of Object.values(phases)) {
    if (phase && matchString(phase.phase_name, value, partial)) {
      phaseIds.add(phase.phase_id);
    }
  }

  return phaseIds;
}

export const getProjectsIdsByName = (
  { projects }: { projects: Record<string, Project> },
  value: string,
  partial: boolean,
) => {
  const projectIds = new Set<number>();

  for (const project of Object.values(projects)) {
    if (project && matchString(project.project_name, value, partial)) {
      projectIds.add(project.project_id);
    }
  }

  return projectIds;
};

export const getProjectsIdsByTag = (
  { projects }: { projects: Record<string, Project> },
  value: string,
  partial: boolean,
) => {
  const projectIds = new Set<number>();

  for (const project of Object.values(projects)) {
    if (project && project.tags) {
      for (const tag of project.tags) {
        if (matchString(tag, value, partial)) {
          projectIds.add(project.project_id);
        }
      }
    }
  }

  return projectIds;
};

export const getTaskIdsByName = (
  { tasks }: { tasks: Record<string, SearchTask | Task> },
  value: string,
  partial: boolean,
) => {
  const taskIds = new Set<string>();

  for (const task of Object.values(tasks)) {
    if (task && matchTaskByName(task, value, partial)) {
      taskIds.add(task.task_id);
    }
  }

  return taskIds;
};

export const getTaskIdsByStatus = (
  { tasks }: { tasks: Record<string, SearchTask | Task> },
  value: string,
) => {
  const taskIds = new Set<string>();

  for (const task of Object.values(tasks)) {
    if (task && matchTaskByStatus(task, value)) {
      taskIds.add(task.task_id);
    }
  }

  return taskIds;
};
