import React from 'react';
import { t } from '@lingui/macro';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { trackEvent } from '@float/common/lib/analytics';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import EHIconCalendar from '@float/ui/deprecated/Earhart/Icons/Icon/IconCalendar';
import EhIconChevronDropdown from '@float/ui/deprecated/Earhart/Icons/Icon/IconChevronDropdown';
import { List } from '@float/ui/deprecated/Earhart/List';
import { TooltipWithHintAndMenu } from '@float/ui/deprecated/Tooltip/TooltipWithHintAndMenu';

type HorizontalZoomModeOption = {
  value: number;
  name: string;
  width: number;
};

function getHorizontalZoomModeOptions() {
  const MODES: HorizontalZoomModeOption[] = [
    { value: 0, name: t`Days`, width: 245 },
    { value: 1, name: t`Weeks`, width: 90 },
    { value: 2, name: t`Months`, width: 27 },
  ];

  if (featureFlags.isFeatureEnabled(FeatureFlag.WeekGrid)) {
    MODES.push({ value: 3, name: t`Quarters`, width: 9 });
  }

  return MODES;
}

function Menu({
  value,
  onChange,
}: {
  value: number;
  onChange: (mode: HorizontalZoomModeOption) => void;
}) {
  const MODES = getHorizontalZoomModeOptions();

  return (
    <List
      // @ts-expect-error List component isn't typed
      appearance="flay"
      options={MODES}
      value={Number(value)}
      minWidth="185px"
      onClick={onChange}
    />
  );
}

type HorizontalZoomProps = {
  actions: {
    updatePrefs: (prefs: { sked_view: number }) => void;
  };
};

export default function HorizontalZoom({ actions }: HorizontalZoomProps) {
  const MODES = getHorizontalZoomModeOptions();
  const { dayWidth, setDayWidth } = useScheduleContext();

  const onChange = (mode: HorizontalZoomModeOption) => {
    // Optimistically update the width and save afterwards
    setDayWidth(mode.width);
    setTimeout(() => {
      actions.updatePrefs({ sked_view: mode.value });
      trackEvent('Horizontal zoom set', { type: mode.name });
    }, 750);
  };

  const currentMode = MODES.find((m) => m.width === dayWidth) || MODES[0];

  return (
    <TooltipWithHintAndMenu
      key="zoom-horizontal"
      distance={5}
      arrow={false}
      placement="bottom"
      hint="Zoom"
      menu={() => <Menu value={currentMode.value} onChange={onChange} />}
    >
      {(_, showMenu) => (
        <NavIconBtn
          isPrimary={false}
          onClick={showMenu}
          iconLeft={<EHIconCalendar />}
          iconRight={<EhIconChevronDropdown size={16} />}
        >
          {currentMode.name}
        </NavIconBtn>
      )}
    </TooltipWithHintAndMenu>
  );
}
