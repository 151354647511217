import {
  ProjectInputData,
  updateProject,
} from '@float/common/actions/projects';
import { useWebAppDispatchStrict } from '@float/web/lib/store';

export const useUpdateProject = () => {
  const dispatch = useWebAppDispatchStrict();
  const updateProjectColor = (
    projectId: number,
    payload: Partial<ProjectInputData>,
  ) => {
    dispatch(updateProject(projectId, payload));
  };
  return updateProjectColor;
};
