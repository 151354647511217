import React from 'react';
import { Plural, Trans } from '@lingui/macro';
import cs from 'classnames';

import { InputAllocationDateRangeSelect } from '../InputAllocationDateRangeSelect/InputAllocationDateRangeSelect';

import * as styles from '../../EditTaskModal.css';

export type InputAllocationDateRangeHeaderProps = {
  numberOfAllocationDays: number;
  hasIntervalSelect: boolean;
  startDate: Date;
  endDate: Date;
  onChange: (data: { startDate: Date; endDate: Date }) => void;
};

export const InputAllocationDateRangeHeader = (
  props: InputAllocationDateRangeHeaderProps,
) => {
  const {
    numberOfAllocationDays,
    hasIntervalSelect,
    startDate,
    endDate,
    onChange,
  } = props;

  return (
    <div
      className={cs(
        styles.dateRangeSectionColumnHeader,
        hasIntervalSelect && styles.timeSectionColumnHeaderWithLeadingButton,
      )}
    >
      {hasIntervalSelect && (
        <InputAllocationDateRangeSelect
          startDate={startDate}
          endDate={endDate}
          onSelect={onChange}
        />
      )}
      <label className={styles.fieldLabel}>
        <Trans>
          Duration:{' '}
          <Plural
            value={numberOfAllocationDays}
            one="# working day"
            other="# working days"
          />
        </Trans>
      </label>
    </div>
  );
};
