import { filter as filterMap, groupBy, keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { personEditable } from '@float/common/lib/rights';
import { LoadState } from '@float/common/reducers/lib/types';
import { PeopleState } from '@float/common/reducers/people';
import { Person } from '@float/types';

import { getUser } from '../currentUser';
import { getDepartments, getParentDepartments } from '../departments';
import { getOneOffDays } from '../oneOffs';
import { getRoles } from '../roles';
import {
  getAccounts,
  getDepartmentForPerson,
  getPeopleMapRaw,
  getRoleForPerson,
} from './helpers';
import { getProjectIdsForPerson } from './projectIds';

export const getPeopleListRaw = createSelector([getPeopleMapRaw], (people) =>
  Object.values(people),
);

export const getPeople = createSelector(
  [
    getUser,
    getPeopleListRaw,
    getOneOffDays,
    getDepartments,
    getAccounts,
    getProjectIdsForPerson,
    getRoles,
  ],
  (
    currentUser,
    people,
    oneOffDays,
    departments,
    accounts,
    projectIdForPerson,
    roles,
  ) => {
    return people.map((person) => {
      const currentWorkDaysHours = person.work_days_hours_history
        ? person.work_days_hours_history[0][1]
        : person.work_days_hours;

      const p: Person = {
        ...person,
        ...(projectIdForPerson[person.people_id] ?? {
          project_ids: [],
          active_project_ids: [],
        }),
        oneOffDays: oneOffDays.filter((d) => d.people_id === person.people_id),
        department: getDepartmentForPerson(person, departments),
        role: getRoleForPerson(person, roles),
        department_id: person.department_id || 0,
        work_days_hours: currentWorkDaysHours,
      };

      if (p.account_id) {
        p.account = accounts[p.account_id];
      }

      p.canEdit = personEditable(p, currentUser);

      return p;
    });
  },
);

export const getPeopleMap = createSelector([getPeople], (people) =>
  keyBy(people, 'people_id'),
);

export const getPeopleByAccountId = createSelector([getPeople], (people) => {
  // filter out people without account
  const peopleWithAccountId = filterMap(
    people,
    (person) => !!person.account_id,
  );

  // group people by account_id
  return keyBy(peopleWithAccountId, 'account_id');
});

export const getPeopleByRoleId = createSelector([getPeople], (people) => {
  return groupBy(people, 'role_id');
});

export const getPeopleWithAccount = createSelector(
  [getPeopleMapRaw, getAccounts],
  (people, accounts) => {
    const peopleAccountsIDs: number[] = [];

    // get people with accounts
    const userAccounts = Object.values(people).reduce((prev, person) => {
      const hasAccount = person.account_id && accounts[person.account_id];

      if (person.account_id) peopleAccountsIDs.push(person.account_id);

      if (hasAccount) {
        return prev.concat(person);
      }

      return prev;
    }, [] as Person[]);

    // get the remaining accounts from the accounts entity
    // e.g., guest accounts that are not on the people entity
    const guestAccounts = Object.values(accounts).filter(
      (account) => !peopleAccountsIDs.includes(account.account_id),
    );

    // merge both accounts
    const peopleWithAccounts = [...userAccounts, ...guestAccounts];
    return peopleWithAccounts;
  },
);

export const getActivePeopleWithEmailMap = createSelector(
  [getPeopleMapRaw],
  (people) =>
    keyBy(
      filterMap(people, (p) => Boolean(p.active && p.email)),
      'people_id',
    ),
);

export const getPeopleByDepartment = createSelector(
  [getPeopleMapRaw, getParentDepartments],
  (people, parentDepartments) =>
    Object.values(people).reduce(
      (acc, person: Person) => {
        const departmentId = person.department_id;
        if (!departmentId) {
          return acc;
        }

        [departmentId]
          .concat(Number(parentDepartments[departmentId]) || [])
          .forEach((depId) => {
            acc[depId] = acc[depId] || [];
            acc[depId].push(person);
          });

        return acc;
      },
      {} as Record<number, Person[]>,
    ),
);

export const selectPersonById = createSelector(
  [getPeopleMapRaw, (_: unknown, id: number) => id],
  (people, id) => people[id],
);

export const selectEnhancedPersonById = createSelector(
  [getPeopleMap, (_: unknown, id: number) => id],
  (people, id) => people[id],
);

export const selectArePeopleLoaded = (state: { people: PeopleState }) =>
  state.people.loadState === LoadState.LOADED;
