import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import * as Intercom from '@intercom/messenger-js-sdk';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ConnectedRouter } from 'connected-react-router';
import useMount from 'react-use/esm/useMount';
import { Store } from 'redux';

import { UIContextProviders } from '@float/common/contexts';
import { ColorThemeInjector } from '@float/common/contexts/ColorTheme/ColorThemeInjector';
import { QueryProvider } from '@float/common/contexts/QueryProvider';
import history from '@float/common/lib/history';
import { PageData } from '@float/types/pageData';
import { SnackbarProvider } from '@float/ui/deprecated/Snackbar';
import { ThemeProvider } from '@float/ui/deprecated/Theme/ThemeProvider';
import { TooltipProvider } from '@float/ui/deprecated/Tooltip/TextTooltip';
import { FontsPrefetch } from '@float/web/components/FontsPrefetch';

import { AppErrorBoundary } from '../components/AppErrorBoundary';
import { MountDevToolsIfEnabled } from '../devtools';
import { PageNotFound } from '../views/PageNotFound';
import { AppRouter, ShareAppRouter } from './AppRouter';
import { AuthRouter } from './AuthRouter';
import { Routes } from './constants';

function useIntercomNavigationTracker() {
  useMount(() => {
    return history.listen(() => {
      Intercom.update({});
    });
  });
}

export const RootRouter = (props: { store: Store; data?: PageData }) => {
  useIntercomNavigationTracker();

  return (
    <ThemeProvider>
      <I18nProvider i18n={i18n}>
        <AppErrorBoundary>
          <UIContextProviders>
            <ColorThemeInjector />
            <Provider store={props.store}>
              <QueryProvider>
                <FontsPrefetch />
                <ConnectedRouter history={history}>
                  <TooltipProvider>
                    <SnackbarProvider>
                      <Switch>
                        <Route
                          path={Routes.SignupExist}
                          component={AuthRouter}
                        />
                        <Route
                          path={Routes.SelectCompany}
                          component={AuthRouter}
                        />
                        <Route
                          path={Routes.ReactivateSubscription}
                          component={AuthRouter}
                        />
                        <Route
                          path={Routes.SharedLinkView}
                          component={ShareAppRouter}
                        />
                        <Route
                          path="/page-not-found"
                          component={PageNotFound}
                        />
                        <Route
                          path="/"
                          render={() =>
                            props.data ? <AppRouter data={props.data} /> : null
                          }
                        />
                        <Route component={PageNotFound} />
                      </Switch>
                      <MountDevToolsIfEnabled />
                    </SnackbarProvider>
                  </TooltipProvider>
                </ConnectedRouter>
              </QueryProvider>
            </Provider>
          </UIContextProviders>
        </AppErrorBoundary>
      </I18nProvider>
    </ThemeProvider>
  );
};
