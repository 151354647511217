import { t } from '@lingui/macro';
import { memoizeWithArgs } from 'proxy-memoize';
import { createSelector } from 'reselect';

import { stringCompare } from '@float/common/lib/sort';
import { projectStatuses } from '@float/common/reducers/search/getDerivedContext';
import { getUser } from '@float/common/selectors/currentUser';
import { selectIsMeFilterActive } from '@float/common/selectors/search';
import {
  FILTERABLE_ENTITY_KEYS,
  SEARCH_CATEGORY_TO_FILTER_TYPE_MAPPING,
} from '@float/constants/search';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import type { AccountsState } from '@float/common/reducers/accounts';
import type { ClientsState } from '@float/common/reducers/clients';
import type { DepartmentsState } from '@float/common/reducers/departments';
import type { PeopleState } from '@float/common/reducers/people';
import type { PhasesState } from '@float/common/reducers/phases';
import type { ProjectsState } from '@float/common/reducers/projects';
import type { SearchState } from '@float/common/reducers/search';
import type { TagsState } from '@float/common/reducers/tags';
import type {
  CompanyPreferences,
  CurrentUser,
  FilterOperators,
  FilterToken,
  SearchAutocompleteCategory,
  UserPrefs,
  VirtualFilterTypes,
} from '@float/types';

import {
  getLocalFilters,
  getValueAndOpFromStr,
  normalize,
  PERSON_RELATED_KEYS,
  savedSearchesToFilters,
} from '../../helpers';
import { getSearchDerivedContext } from '../derivedContext';
import { getCategoryTotalSize } from './getCategoryTotalSize';
import {
  addParentDepartmentToCandidates,
  getOrderedDepartments,
} from './getOrderedDepartments';
import { isCandidateVisibleForUser } from './isCandidateVisible';
import { isRemoteResultVisibleForUser } from './isRemoteResultVisibleForUser';
import { truncateCandidates } from './truncateCandidates';
import type { SearchQueryItem } from '../../api/queryApi.types';
import type { SearchWorkerReduxState } from '../../service/worker/searchStore';
import type { Candidate } from './types';

const selectFilterableKeys = createSelector(
  [
    selectIsMeFilterActive,
    (_: unknown, isLogTimeView?: boolean) => Boolean(isLogTimeView),
  ],
  (meFilter, isLogTimeView) => {
    // We hide the person related categories when the user is on a
    // single person view (me filter or log my time view)
    if (!meFilter && !isLogTimeView) {
      return FILTERABLE_ENTITY_KEYS;
    }

    const result: SearchAutocompleteCategory[] = [];

    for (const key of FILTERABLE_ENTITY_KEYS) {
      if (PERSON_RELATED_KEYS.includes(key)) {
        continue;
      }

      result.push(key);
    }

    return result;
  },
);

const selectTimeoffStatuses = createSelector(
  [
    (state: { companyPrefs: CompanyPreferences }) =>
      state.companyPrefs.timeoff_approvals,
  ],
  (isTimeoffApprovalsEnabled): SearchAutocompleteQueryItem[] => {
    const timeoffStatuses = getLocalFilters().filter(
      (f) => f.type === 'timeoffStatus',
    );

    if (isTimeoffApprovalsEnabled) {
      // We can't use ids to filter the values because Approved and Confirmed
      // have the same id
      return timeoffStatuses.filter(
        (f) =>
          f.val === t`Declined` ||
          f.val === t`Approved` ||
          f.val === t`Tentative`,
      );
    }

    return timeoffStatuses.filter(
      (f) => f.val === t`Confirmed` || f.val === t`Tentative`,
    );
  },
);

function sortFilteredCandidates(
  key: string,
  candidates: SearchAutocompleteQueryItem[],
  subDepartments?: boolean,
) {
  if (key === 'people' || key === 'projects' || key === 'timeoffs') {
    const values = candidates as Extract<
      SearchAutocompleteQueryItem,
      { type: 'person' | 'project' | 'timeoff' }
    >[];

    values.sort((a, b) => {
      if (a.isActive !== b.isActive) {
        if (a.isActive) return -1;
        if (b.isActive) return 1;
      }

      return stringCompare(
        (a.sortVal ?? a.normalizedVal).toUpperCase(),
        (b.sortVal ?? b.normalizedVal).toUpperCase(),
      );
    });

    return values;
  }

  // sub-departments are placed right below the parent departments
  if (key === 'departments' && subDepartments) {
    return getOrderedDepartments(
      candidates as Extract<
        SearchAutocompleteQueryItem,
        { type: 'department' }
      >[],
    );
  }

  // statuses are listed in a custom order, not alphabetical
  if (key === 'taskStatuses' || key === 'projectStatuses') {
    return candidates;
  }

  candidates.sort((a, b) =>
    stringCompare(
      (a.sortVal ?? a.val).toUpperCase(),
      (b.sortVal ?? b.val).toUpperCase(),
    ),
  );

  return candidates;
}

const getFilterableKeyCandidates = (
  state: {
    accounts: AccountsState;
    clients: ClientsState;
    companyPrefs: CompanyPreferences;
    currentUser: CurrentUser;
    departments: DepartmentsState;
    people: PeopleState;
    phases: PhasesState;
    projects: ProjectsState;
    search: SearchState;
    tags: TagsState;
  },
  params: {
    key: SearchAutocompleteCategory;
    input: string;
    subDepartments?: boolean;
    remoteQueryItems?: SearchQueryItem[];
  },
) => {
  const { key, input } = params;

  let filtered: SearchAutocompleteQueryItem[] = [];

  const isSearchBeyondLimitsEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.SearchBeyondLimits,
  );

  if (isSearchBeyondLimitsEnabled) {
    if (isLocallyResolved(key)) {
      switch (key) {
        case 'savedSearches':
          filtered = savedSearchesToFilters(state.currentUser.savedSearches);
          break;
        case 'projectStatuses':
          filtered = projectStatuses;
          break;
        case 'timeoffStatuses':
          filtered = selectTimeoffStatuses(state);
          break;
      }

      filtered = filtered.filter((c) => {
        // We never want to show the empty string in the dropdown
        if (c.normalizedVal === '') return false;

        if (!input) return true;

        return c.normalizedVal.includes(input);
      });
    } else {
      filtered = filtered.concat(params.remoteQueryItems ?? []);

      const type = SEARCH_CATEGORY_TO_FILTER_TYPE_MAPPING[key];

      filtered = filtered.filter((item) => {
        if (item.type !== type) return false;
        // We never want to show the empty string in the dropdown
        if (item.normalizedVal === '') return false;

        // @ts-expect-error – There is a type mismatch between the SearchAutocompleteQueryItem
        // and the SearchQueryItem expected by isRemoteResultVisibleForUser
        return isRemoteResultVisibleForUser(item, state);
      });

      /**
       * Check if there are any locally defined filters that we should add to the results
       */
      for (const locallyDefinedFilter of getLocalFilters()) {
        if (
          locallyDefinedFilter.type === type &&
          locallyDefinedFilter.normalizedVal.includes(input)
        ) {
          filtered.push(locallyDefinedFilter);
        }
      }
    }
  } else {
    const user = getUser(state);

    const context = getSearchDerivedContext(state);
    const candidates = context[key] as Candidate[];

    // @ts-expect-error – There is a type mismatch between the SearchAutocompleteQueryItem and Candidate
    filtered = candidates.filter((c) => {
      // We never want to show the empty string in the dropdown
      if (c.normalizedVal === '') return false;

      // Suppress filters that the user shouldn't see
      if (
        !isCandidateVisibleForUser(c, user, {
          people: state.people,
          projects: state.projects,
          peopleTasks: context.peopleTasks,
          userPrefs: user.prefs as UserPrefs,
        })
      ) {
        return false;
      }

      if (!input) return true;

      return c.normalizedVal?.includes(input) ?? false;
    });
  }

  const size = getCategoryTotalSize(filtered);

  // Root departments are visible when one of their sub departments
  // matches the input filter
  if (key === 'departments' && params.subDepartments) {
    addParentDepartmentToCandidates(state.departments.departments, filtered);
  }

  return {
    candidates: sortFilteredCandidates(key, filtered, params.subDepartments),
    size,
  };
};

const MAX_PER_CATEGORY = 4;

export type SearchAutocompleteParams = {
  rawInput: string;
  expandedCategory?: SearchAutocompleteCategory;
  excludeDraftStatus?: boolean;
  isLogTimeView: boolean; // On Mobile we can't derive the log time view from the state
  myProjectsItem: boolean; // An extra item added by Mobile
  subDepartments: boolean; // On mobile sub departments aren't supported yet
  containsItem: boolean;
  truncateResults: boolean;
  remoteQueryResult?: {
    items: SearchQueryItem[];
    count: number;
  };
};

type BaseSearchQueryItem = {
  normalizedVal: string; // Required for display purposes and sorting, not used externally to getSearchAutocompleteResults
  sortVal?: string; // Required for the internal sorting, not used externally to getSearchAutocompleteResults
  displayVal?: string;
  val: string;
  subVal?: string; // Used to display multi-line search results
  showSeparatorAfter?: boolean;
  id?: number;
  lookupValue?: string;
};

export type SearchAutocompleteQueryItemExcludingSavedSearch =
  BaseSearchQueryItem &
    (
      | {
          type:
            | 'client'
            | 'contains'
            | 'jobTitle'
            | 'manager'
            | 'personTag'
            | 'personType'
            | 'phase'
            | 'projectOwner'
            | 'projectTag'
            | 'task'
            | 'taskStatus'
            | 'timeoffStatus';
        }
      | {
          type: 'person' | 'timeoff';
          isActive: boolean;
        }
      | {
          type: 'project';
          isActive: boolean;
          code?: string | null;
        }
      | {
          type: 'department';
          parent_id?: number | null;
          id?: number;
        }
      | {
          type: 'projectStatus';
          hideCategoryName?: boolean; // Required by the Mobile
        }
    );

export type SavedSearchQueryItem = BaseSearchQueryItem & {
  type: 'savedSearch';
  filters: FilterToken[];
};

export type SearchAutocompleteQueryItem =
  | SearchAutocompleteQueryItemExcludingSavedSearch
  | SavedSearchQueryItem;

function isLocallyResolved(key: SearchAutocompleteCategory) {
  return (
    key === 'savedSearches' ||
    key === 'projectStatuses' ||
    key === 'timeoffStatuses'
  );
}

export type SearchAutocompleteSavedSearchItem = {
  id?: number;
  type: 'savedSearch';
  filters: FilterToken[];
  val: string;
};

export type SearchAutocompleteResultItem =
  | {
      id?: number;
      type: Exclude<VirtualFilterTypes, 'projectStatus' | 'savedSearch' | 'me'>;
      val: string;
      subVal?: string | null;
      showSeparatorAfter?: boolean;
    }
  | {
      id?: number;
      type: 'projectStatus';
      val: string;
      hideCategoryName?: boolean; // Required by the Mobile
      showSeparatorAfter?: boolean;
    }
  | SearchAutocompleteSavedSearchItem;

export const getSearchAutocompleteResults = memoizeWithArgs(
  (state: SearchWorkerReduxState, params: SearchAutocompleteParams) => {
    const { rawInput, excludeDraftStatus, expandedCategory } = params;
    const { operator, val } = getValueAndOpFromStr(rawInput);
    const input = normalize(val);

    let myProjectsAdded = false;

    const result: SearchAutocompleteResultItem[] = [];
    const categoryIndices: Record<string, number> = {};
    const categorySizes: Record<string, { shown: number; total: number }> = {};

    function addToResults(values: SearchAutocompleteQueryItem[]) {
      // Using for..of to avoid to incur with "RangeError: Maximum call stack size exceeded"
      for (const value of values) {
        if (excludeDraftStatus && value.normalizedVal === 'draft') continue;

        if (value.type !== 'savedSearch') {
          if (value.type === 'project') {
            result.push({
              type: value.type,
              val: value.val,
              subVal: value.subVal ?? value.code,
            });
          } else {
            result.push({
              type: value.type,
              val: value.val,
              ...(value.showSeparatorAfter
                ? { showSeparatorAfter: value.showSeparatorAfter }
                : {}),
            });
          }
        } else {
          result.push({
            type: value.type,
            val: value.val,
            filters: value.filters,
          });
        }
      }
    }

    const keys = expandedCategory
      ? [expandedCategory]
      : selectFilterableKeys(state, params.isLogTimeView);

    for (const key of keys) {
      if (operator && key === 'savedSearches') {
        // We don't support adding modifiers directly to a saved search
        continue;
      }

      if (params.myProjectsItem) {
        // On mobile a virtual "My projects" filter is added
        // right below the saved searches
        if (key !== 'savedSearches' && !myProjectsAdded) {
          myProjectsAdded = true;
          result.push({
            type: 'projectStatus',
            val: 'My projects',
            hideCategoryName: true,
          });
          categoryIndices.projectStatuses = result.length - 1;
          categorySizes.projectStatuses = { shown: 1, total: 1 };
        }
      }

      const { candidates, size } = getFilterableKeyCandidates(state, {
        key,
        input,
        subDepartments: params.subDepartments,
        remoteQueryItems: params.remoteQueryResult?.items,
      });

      if (
        !featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits) &&
        expandedCategory === 'timeoffs'
      ) {
        // When the timeoffs category is expanded
        // we show the "Any" filter
        candidates.unshift({
          type: 'timeoff',
          val: '*',
          normalizedVal: '*',
          isActive: true,
        });
      }

      if (candidates.length) {
        categoryIndices[key] = result.length;

        // On the web app, when a category is not expanded
        // we limit the results per category to MAX_PER_CATEGORY

        if (
          params.remoteQueryResult &&
          expandedCategory &&
          !isLocallyResolved(key)
        ) {
          // We need to adjust the count to account for the items that
          // are being hidden because of the ACL and added because of the locally defined
          // filters
          const diff =
            candidates.length - params.remoteQueryResult.items.length;

          const count = params.remoteQueryResult.count + diff;

          categorySizes[key] = {
            shown: count,
            total: count,
          };
          addToResults(candidates);
        } else if (params.truncateResults && !expandedCategory) {
          const truncated = truncateCandidates({
            key,
            candidates,
            expandedCategory,
            maxPerCategory: MAX_PER_CATEGORY,
          });
          categorySizes[key] = { shown: truncated.length, total: size };
          addToResults(truncated);
        } else {
          categorySizes[key] = { shown: candidates.length, total: size };
          addToResults(candidates);
        }
      }
    }

    // When the category is not expanded we show a "contains" item
    // to run a contains search on all the possible categories
    if (!operator && !expandedCategory && input && params.containsItem) {
      categoryIndices.contains = result.length;
      categorySizes.contains = { shown: 1, total: 1 };
      result.push({
        type: 'contains',
        val: input,
      });
    }

    return { result, categoryIndices, categorySizes, input: rawInput };
  },
);

export type SearchAutocompleteResults = ReturnType<
  typeof getSearchAutocompleteResults
> & {
  operator?: FilterOperators | '';
};
