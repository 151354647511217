import { createSelector } from 'reselect';

import type { ProjectsState } from '@float/common/reducers/projects';

export const getProjectsMapRaw = (state: { projects: ProjectsState }) =>
  state.projects.projects;

export const getProjectById = createSelector(
  [getProjectsMapRaw, (_: unknown, id: number | undefined) => id],
  (projects, id) => {
    if (!id) return undefined;

    return projects[id];
  },
);
