import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import gsap from 'gsap';
import Flip from 'gsap/Flip';

import { usePrevious } from '@float/common/lib/hooks/usePrevious';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { OnboardingSurveyStatus } from '@float/web/store/onboardingManager/types';

import { LegacySteps } from './Steps/LegacySteps';
import { Steps } from './Steps/Steps';
import { StyledHeader, StyledHeaderContainer, StyledList } from './styles';

export const BreadcrumbNavigation = forwardRef<
  unknown,
  Omit<OnboardingSurveyStatus, 'completed'> & { className?: string }
>((props, ref) => {
  const { className, navigation } = props;

  const elRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isSignUpRevampEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.SignUpRevamp,
  );

  const previousPage = usePrevious(navigation.page);

  const onAppear = useCallback(
    ({
      delay,
      duration,
      ease,
    }: {
      delay: number;
      duration: number;
      ease: string;
    }) => {
      return new Promise((success) => {
        gsap.set(elRef.current, { x: -20, alpha: 0 });

        window.requestIdleCallback(() => {
          gsap.to(elRef.current, {
            x: 0,
            alpha: 1,
            duration,
            ease,
            delay,
            clearProps: 'all',
            onComplete: success,
          });
        });
      });
    },
    [],
  );

  useEffect(() => {
    let anim;

    // reaching last page (success)
    if (navigation.page === navigation.totalPages - 1) {
      anim = 'to-center';
    }

    // leaving success page (backwards)
    if (
      previousPage === navigation.totalPages - 1 &&
      navigation.page === navigation.totalPages - 2
    ) {
      anim = 'from-center';
    }

    if (anim) {
      window.requestIdleCallback(() => {
        const state = Flip.getState(containerRef.current);

        if (elRef.current) {
          if (anim === 'to-center') elRef.current.classList.add('centered');
          if (anim === 'from-center')
            elRef.current.classList.remove('centered');
        }

        Flip.from(state, { duration: 1, ease: 'power3.inOut' });
      });
    }
  }, [navigation, previousPage]);

  useImperativeHandle(ref, () => ({
    onAppear,
  }));

  return (
    <StyledHeader ref={elRef} className={className}>
      <StyledHeaderContainer ref={containerRef}>
        <StyledList>
          {isSignUpRevampEnabled && <Steps navigation={navigation} />}
          {!isSignUpRevampEnabled && <LegacySteps navigation={navigation} />}
        </StyledList>
      </StyledHeaderContainer>
    </StyledHeader>
  );
});
