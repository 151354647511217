import { t } from '@lingui/macro';
import { forEach, isUndefined, map } from 'lodash';
import { getEmptyTaskLabel } from 'reports/TeamCapacity/parser/table/getEmptyTaskLabel';

import { formatAmount, toCents } from '@float/common/lib/budget';
import { ProjectBudgetType } from '@float/constants/projects';
import { BudgetPriority, BudgetType } from '@float/types/project';
import { getIsMoneyBudgetType } from '@float/web/reports/helpers/getIsMoneyBudgetType';

import { createTaskRowDataMap } from './createTaskRowDataMap';

export function breakdown(ctx, raw) {
  const byTask = createTaskRowDataMap(ctx.tasks, raw.tasks);

  raw.totals.forEach((item) => {
    if (item.type !== 'task' && item.type !== 'logged_time') return;

    if (!item.name) {
      item.name = getEmptyTaskLabel(ctx);
    }

    const key = `${item.name}::${item.phase_id}`;
    const record = byTask[key];
    if (!record) return;

    if (!record.children[item.person_id]) {
      record.children[item.person_id] = {
        person_id: item.person_id,
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        feeCents: 0,
        costCents: 0,
        tentative: 0,
        logged: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          feeCents: 0,
          costCents: 0,
        },
        combined: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          feeCents: 0,
          costCents: 0,
        },
        future: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          tentative: 0,
          feeCents: 0,
          costCents: 0,
        },
      };
    }

    const child = record.children[item.person_id];

    if (item.type === 'task' || item.type === 'logged_time') {
      if (item.type === 'logged_time' && item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      const childRoot = item.type === 'task' ? child : child.logged;
      const recordRoot = item.type === 'task' ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;
      record.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;
      record.future.scheduled += item.hours.future;
      child.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;

      recordRoot.feeCents += toCents(item.rate) * item.hours.scheduled;
      childRoot.feeCents += toCents(item.rate) * item.hours.scheduled;

      recordRoot.costCents += toCents(item.cost?.scheduled);
      childRoot.costCents += toCents(item.cost?.scheduled);

      const rateCents = toCents(item.rate);

      record.combined.feeCents +=
        rateCents *
        (item.type === 'task' ? item.hours.future : item.hours.scheduled);

      record.combined.costCents += toCents(item.cost?.future);

      record.future.feeCents += rateCents * item.hours.future;
      record.future.costCents += toCents(item.cost?.future);

      child.combined.scheduled +=
        rateCents *
        (item.type === 'task' ? item.hours.future : item.hours.scheduled);

      if (item.type === 'task') {
        child.future.scheduled += item.hours.future;
        child.future.feeCents += rateCents * item.hours.future;
        child.future.costCents += toCents(item.cost?.future);
      }

      if (item.tentative) {
        child.tentative += item.hours.scheduled;

        if (item.type === 'task') {
          child.future.tentative += item.hours.future;
        }
      }

      // Do not throw on billable mismatch, instead take task billable
      // status as source of truth.
      if (isUndefined(record.isBillable) || item.type === 'task') {
        record.isBillable = item.billable;
      }

      if (item.billable) {
        childRoot.billable += item.hours.scheduled;
        recordRoot.billable += item.hours.scheduled;
        record.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future.billable += item.hours.future;
        child.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;

        if (item.tentative) {
          record.tentative.scheduled += item.hours.scheduled;
          record.tentative.billable += item.hours.scheduled;
          record.future.tentative.billable +=
            item.type === 'task' ? item.hours.future : item.hours.scheduled;
        }

        if (item.type === 'task') {
          child.future.billable += item.hours.future;
        }
      } else {
        childRoot.nonbillable += item.hours.scheduled;
        recordRoot.nonbillable += item.hours.scheduled;
        record.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future.nonbillable += item.hours.future;
        child.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;

        if (item.tentative) {
          record.tentative.scheduled += item.hours.scheduled;
          record.tentative.nonbillable += item.hours.scheduled;
          record.future.tentative.nonbillable +=
            item.type === 'task' ? item.hours.future : item.hours.scheduled;
        }

        if (item.type === 'task') {
          child.future.nonbillable += item.hours.future;
        }
      }
    }
  });

  return byTask;
}

export function getScheduledTable(ctx, raw) {
  const {
    billable,
    project,
    people,
    phases,
    hasPhases,
    hasBudgetsAccess,
    hasCostsAccess,
  } = ctx;

  const hasBudgetSupport = project.budget_priority === BudgetPriority.Task;
  const hasFeeColumns = hasBudgetsAccess;
  const hasCostColumns = hasCostsAccess;

  const headers = [
    {
      label: billable ? t`Billable` : t`Non-billable`,
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Scheduled`,
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasBudgetSupport) {
    headers.splice(1, 0, {
      label: t`Budget`,
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    });
  }

  if (hasPhases) {
    headers.splice(1, 0, {
      label: t`Phase`,
      align: 'flex-start',
      grow: 1,
    });
  }

  if (hasCostColumns) {
    headers.push({
      label: t`Scheduled cost`,
      width: 160,
      formatter: (x) =>
        x === '' ? '' : formatAmount(ProjectBudgetType.TotalFee, x),
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byTask = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    costCents: 0,
  };

  const rows = [];

  forEach(byTask, (o, key) => {
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const cost = hasCostColumns ? o.costCents / 100 : '';
    const hours = billable ? o.billable : o.nonbillable;

    if (o.isBillable != billable) return;

    totals.hours += hours;

    if (hasFeeColumns) totals.feeCents += fee * 100;
    if (hasCostColumns) totals.costCents += cost * 100;

    const data = [o.name || getEmptyTaskLabel(), fee, hours];

    if (hasBudgetSupport) {
      data.splice(1, 0, o.budget);
    }

    if (hasPhases) {
      data.splice(1, 0, phases[o.phase_id]?.phase_name || 'No Phase');
    }

    if (hasCostColumns) {
      data.push(cost);
    }

    rows.push({
      id: key,
      data,
      children: map(o.children, (c, personId) => {
        const childBudget = '';
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childCost = hasCostColumns ? c.costCents / 100 : '';
        const childHours = billable ? c.billable : c.nonbillable;

        const childData = [people[personId].name, childFee, childHours];

        if (hasBudgetSupport) {
          childData.splice(1, 0, childBudget);
        }

        if (hasPhases) {
          childData.splice(1, 0, '');
        }

        if (hasCostColumns) {
          childData.push(childCost);
        }

        return { data: childData };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
  ];

  if (hasBudgetSupport) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasCostColumns) {
    footer.push({ label: totals.costCents / 100 });
  }

  return { headers, rows, footer };
}

export function getLoggedTable(ctx, raw) {
  const {
    billable,
    project,
    people,
    phases,
    hasPhases,
    hasBudgetsAccess,
    hasCostsAccess,
  } = ctx;

  const hasBudgetSupport = project.budget_priority === BudgetPriority.Task;
  const hasFeeColumns = hasBudgetsAccess;
  const hasCostColumns = hasCostsAccess;

  const headers = [
    {
      label: billable ? 'Billable' : 'Non-billable',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Logged`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasBudgetSupport) {
    headers.splice(1, 0, {
      label: t`Budget`,
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    });
  }

  if (hasPhases) {
    headers.splice(1, 0, {
      label: t`Phase`,
      align: 'flex-start',
      grow: 1,
    });
  }

  if (hasCostColumns) {
    headers.push({
      label: t`Logged cost`,
      width: 160,
      formatter: (x) =>
        x === '' ? '' : formatAmount(ProjectBudgetType.TotalFee, x),
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byTask = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    costCents: 0,
  };

  const rows = [];
  forEach(byTask, (o, key) => {
    const fee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const cost = hasCostColumns ? o.logged.costCents / 100 : '';
    const hours = billable ? o.logged.billable : o.logged.nonbillable;

    if (o.isBillable != billable) return;

    totals.hours += hours;

    if (hasFeeColumns) totals.feeCents += fee * 100;
    if (hasCostColumns) totals.costCents += cost * 100;

    const data = [o.name || getEmptyTaskLabel(), fee, hours];

    if (hasBudgetSupport) {
      data.splice(1, 0, o.budget);
    }

    if (hasPhases) {
      data.splice(1, 0, phases[o.phase_id]?.phase_name || 'No Phase');
    }

    if (hasCostColumns) {
      data.push(cost);
    }

    rows.push({
      id: key,
      data,
      children: map(o.children, (c, personId) => {
        const childBudget = '';
        const childFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childCost = hasCostColumns ? c.logged.costCents / 100 : '';
        const childHours = billable ? c.logged.billable : c.logged.nonbillable;
        const childData = [people[personId].name, childFee, childHours];

        if (hasBudgetSupport) {
          childData.splice(1, 0, childBudget);
        }

        if (hasPhases) {
          childData.splice(1, 0, '');
        }

        if (hasCostColumns) {
          childData.push(childCost);
        }

        return { data: childData };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
  ];

  if (hasBudgetSupport) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasCostColumns) {
    footer.push({ label: totals.costCents / 100 });
  }

  return { headers, rows, footer };
}

export function getCompareTable(ctx, raw) {
  const { billable, project, people, phases, hasPhases } = ctx;
  const hasFeeColumns =
    project.budget_type === BudgetType.TotalFee ||
    project.budget_type === BudgetType.HourlyFee;
  const hasBudgetSupport = project.budget_priority === BudgetPriority.Task;

  const headers = [
    {
      label: billable ? 'Billable' : 'Non-billable',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Logged',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Scheduled',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Difference',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasBudgetSupport) {
    headers.splice(1, 0, {
      label: 'Budget',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    });
  }

  if (hasPhases) {
    headers.splice(1, 0, {
      label: 'Phase',
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byTask = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows = [];
  forEach(byTask, (o, key) => {
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = billable ? o.billable : o.nonbillable;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = billable ? o.logged.billable : o.logged.nonbillable;
    const diffFee = hasFeeColumns ? (o.feeCents - o.logged.feeCents) / 100 : '';
    const diffHours = hours - loggedHours;

    if (o.isBillable != billable) return;

    totals.hours += hours;
    totals.loggedHours += loggedHours;

    if (hasFeeColumns) {
      totals.feeCents += fee * 100;
      totals.loggedFeeCents += loggedFee * 100;
    }

    const data = [
      o.name || getEmptyTaskLabel(),
      loggedFee,
      loggedHours,
      fee,
      hours,
      diffFee,
      diffHours,
    ];

    if (hasBudgetSupport) {
      data.splice(1, 0, o.budget);
    }

    if (hasPhases) {
      data.splice(1, 0, phases[o.phase_id]?.phase_name || 'No Phase');
    }

    rows.push({
      id: key,
      data,
      children: map(o.children, (c, personId) => {
        const childBudget = '';
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = billable ? c.billable : c.nonbillable;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = billable
          ? c.logged.billable
          : c.logged.nonbillable;
        const childDiffFee = hasFeeColumns
          ? (c.feeCents - c.logged.feeCents) / 100
          : '';
        const childDiffHours = childHours - childLoggedHours;

        const childData = [
          // sometimes the id isn't found in the peopleMap
          // https://rollbar.com/float/fe-web-app/items/4968/
          people[personId]?.name || '',
          childLoggedFee,
          childLoggedHours,
          childFee,
          childHours,
          childDiffFee,
          childDiffHours,
        ];

        if (hasBudgetSupport) {
          childData.splice(1, 0, childBudget);
        }

        if (hasPhases) {
          childData.splice(1, 0, '');
        }

        return { data: childData };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents - totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours - totals.loggedHours },
  ];

  if (hasBudgetSupport) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  return { headers, rows, footer };
}

export function getCombinedTable(ctx, raw) {
  const {
    billable,
    project,
    people,
    phases,
    hasPhases,
    hasBudgetsAccess,
    hasCostsAccess,
  } = ctx;

  const isMoneyBudgetType = getIsMoneyBudgetType(project.budget_type);

  const hasFeeColumns = hasBudgetsAccess && isMoneyBudgetType;
  const hasCostColumns = hasCostsAccess;

  const headers = [
    {
      label: billable ? 'Billable' : 'Non-billable',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 130,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Past logged`,
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 130,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Future scheduled`,
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 130,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Total`,
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasPhases) {
    headers.splice(1, 0, {
      label: t`Phase`,
      align: 'flex-start',
      grow: 1,
    });
  }

  if (hasCostColumns) {
    headers.push({
      label: t`Projected cost`,
      width: 160,
      formatter: (x) =>
        x === '' ? '' : formatAmount(ProjectBudgetType.TotalFee, x),
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byTask = breakdown(ctx, raw);
  const totals = {
    pastLoggedHours: 0,
    pastLoggedFeeCents: 0,
    pastLoggedCostCents: 0,

    futureScheduledHours: 0,
    futureScheduledFeeCents: 0,
    futureScheduledCostCents: 0,
  };

  const rows = [];
  forEach(byTask, (o, key) => {
    const pastLoggedHours = billable ? o.logged.billable : o.logged.nonbillable;
    const pastLoggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const pastLoggedCost = hasCostColumns ? o.logged.costCents / 100 : '';

    const futureScheduledFee = hasFeeColumns ? o.future.feeCents / 100 : '';
    const futureScheduledCost = hasCostColumns ? o.future.costCents / 100 : '';
    const futureScheduledHours = billable
      ? o.future.billable
      : o.future.nonbillable;

    const projectedHours = pastLoggedHours + futureScheduledHours;
    const projectedFee = hasFeeColumns
      ? (100 * pastLoggedFee + 100 * futureScheduledFee) / 100
      : '';
    const projectedCost = hasCostColumns
      ? (100 * pastLoggedCost + 100 * futureScheduledCost) / 100
      : '';

    if (o.isBillable != billable) return;

    totals.pastLoggedHours += pastLoggedHours;
    totals.futureScheduledHours += futureScheduledHours;

    if (hasFeeColumns) {
      totals.pastLoggedFeeCents += pastLoggedFee * 100;
      totals.futureScheduledFeeCents += futureScheduledFee * 100;
    }

    if (hasCostColumns) {
      totals.pastLoggedCostCents += pastLoggedCost * 100;
      totals.futureScheduledCostCents += futureScheduledCost * 100;
    }

    const data = [
      o.name || getEmptyTaskLabel(),
      pastLoggedFee,
      pastLoggedHours,
      futureScheduledFee,
      futureScheduledHours,
      projectedFee,
      projectedHours,
    ];

    if (hasPhases) {
      data.splice(1, 0, phases[o.phase_id]?.phase_name || 'No Phase');
    }

    if (hasCostColumns) {
      data.push(projectedCost);
    }

    rows.push({
      id: key,
      data,
      children: map(o.children, (c, personId) => {
        const childPastLoggedHours = billable
          ? c.logged.billable
          : c.logged.nonbillable;
        const childPastLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childPastLoggedCost = hasCostColumns
          ? c.logged.costCents / 100
          : '';

        const childFutureScheduledHours = billable
          ? c.future.billable
          : c.future.nonbillable;
        const childFutureScheduledFee = hasFeeColumns
          ? c.future.feeCents / 100
          : '';
        const childFutureScheduledCost = hasCostColumns
          ? c.future.costCents / 100
          : '';

        const childProjectedHours =
          childPastLoggedHours + childFutureScheduledHours;
        const childProjectedFee = hasFeeColumns
          ? (100 * childPastLoggedFee + 100 * childFutureScheduledFee) / 100
          : '';
        const childProjectedCost = hasCostColumns
          ? (100 * childPastLoggedCost + 100 * childFutureScheduledCost) / 100
          : '';

        const childData = [
          people[personId].name,
          childPastLoggedFee,
          childPastLoggedHours,
          childFutureScheduledFee,
          childFutureScheduledHours,

          childProjectedFee,
          childProjectedHours,
        ];

        if (hasPhases) {
          childData.splice(1, 0, '');
        }

        if (hasCostColumns) {
          childData.push(childProjectedCost);
        }

        return { data: childData };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: hasFeeColumns ? totals.pastLoggedFeeCents / 100 : '' },
    { label: totals.pastLoggedHours },
    { label: hasFeeColumns ? totals.futureScheduledFeeCents / 100 : '' },
    { label: totals.futureScheduledHours },
    {
      label: hasFeeColumns
        ? (totals.pastLoggedFeeCents + totals.futureScheduledFeeCents) / 100
        : '',
    },
    { label: totals.pastLoggedHours + totals.futureScheduledHours },
  ];

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasCostColumns) {
    footer.push({
      label:
        (totals.pastLoggedCostCents + totals.futureScheduledCostCents) / 100,
    });
  }

  return { headers, rows, footer };
}
