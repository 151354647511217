import { useCallback } from 'react';
import { t } from '@lingui/macro';

import { bulkUpdateRoles, ensureRolesLoaded } from '@float/common/actions';
import { trackCostRateChanges } from '@float/common/actions/lib/trackCostRateChanges';
import { useAppDispatchStrict } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { useSnackbar } from '@float/ui/deprecated/Snackbar';
import type { CostRateHistoryPayload } from '@float/types/costRate';

export type HandleBulkEditRolesProps = {
  fields?: {
    cost_rate_history?: CostRateHistoryPayload;
    default_hourly_rate?: string | null;
  };
  ids: number[];
  setLoadingFn?: (loading: boolean) => void;
};

export function useBulkEditRoles(onClose: () => void) {
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatchStrict();

  const handleBulkEditRoles = useCallback(
    async (args: HandleBulkEditRolesProps) => {
      const { ids, fields } = args;
      if (!fields) {
        onClose();
        showSnackbar(t`No changes made.`);
        return;
      }

      const isBulkUpdatingCostRate = Boolean(fields.cost_rate_history);

      try {
        await dispatch(bulkUpdateRoles(ids, fields));
        const rolesCount = ids.length;
        onClose();
        showSnackbar(t`${rolesCount} roles updated.`);

        if (isBulkUpdatingCostRate) {
          trackCostRateChanges({
            costRateHistory: fields.cost_rate_history,
            isBulk: true,
            scope: 'role',
          });

          // If cost rate is updated with an effective date, we refetch roles
          // to ensure we have the latest "currently applicable" cost rates.
          await dispatch(ensureRolesLoaded());
        }
      } catch (error) {
        onClose();
        logger.error('Error bulk updating roles', error);
        showSnackbar(t`An error occurred.`);
      }
    },
    [dispatch, onClose, showSnackbar],
  );

  return { handleBulkEditRoles };
}
