import { proxy } from 'comlink';

import { getQueryAttributes } from '@float/common/api3/search.helpers';
import { selectIsProjectCodesEnabled } from '@float/common/selectors/projects';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { queryApi } from '../api/queryApi';
import { getSearchAutocompleteResults } from '../selectors/getSearchAutocompleteResults';
import { deriveContextMiddleware } from './deriveContextMiddleware';
import {
  searchRemoteResolveListener,
  startListeningSearchRemoteResolve,
} from './searchRemoteResolve/searchRemoteResolveListener';
import { searchWorkerResultMiddleware } from './searchWorkerResultMiddleware';
import { getSelectorValue } from './worker/getSelectorValue';
import { subscribeToSelector } from './worker/subscribeToSelector';
import type { SearchAutocompleteParams } from '../selectors/getSearchAutocompleteResults';
import type {
  SearchWorkerSelectors,
  SearchWorkerSelectorsKeys,
  SelectorParameters,
} from './worker/searchSelectors';
import type {
  SearchWorkerReduxState,
  SearchWorkerReduxStore,
} from './worker/searchStore';
import type { SearchWorker } from './worker/SearchWorker.class';

export class SearchService {
  #workerApi: SearchWorker | null = null;

  setWorkerApi(workerApi: SearchWorker) {
    this.#workerApi = workerApi;
  }

  getMainThreadReduxMiddleware(nonCloneableActions = new Set<string>()) {
    if (featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
      startListeningSearchRemoteResolve();

      return searchRemoteResolveListener.middleware;
    }

    if (this.#workerApi) {
      return searchWorkerResultMiddleware(this.#workerApi, nonCloneableActions);
    }

    // On the main thread we want to debounce the deriveContext more because otherwise
    // it would have a bigger impact on performance
    return deriveContextMiddleware(300);
  }

  /**
   * Makes possible to subscribe a selector which processing happens inside the
   * worker (if the worker is provided)
   *
   * The selector is selected via string value, and must be defined in ./worker/searchSelectors
   *
   * Meant to be used through useSearchSelectorWithParams and never directly
   */
  async subscribeToSelector<S extends SearchWorkerSelectorsKeys>(
    store: SearchWorkerReduxStore,
    selectorKey: S,
    callback: (value: ReturnType<SearchWorkerSelectors[S]>) => void,
    args: SelectorParameters<SearchWorkerSelectors[S]>,
  ) {
    if (featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
      return subscribeToSelector(store, selectorKey, callback, args);
    }

    if (this.#workerApi) {
      return this.#workerApi.subscribeToSelector(
        selectorKey,
        proxy(callback),
        args,
      );
    }

    return subscribeToSelector(store, selectorKey, callback, args);
  }

  /**
   * Makes possible to process a selector behind the worker.
   *
   * If the worker is not configured the processing happens in
   * the main thread (that's why the main thread state must be provided).
   */
  async getSelectorValue<S extends SearchWorkerSelectorsKeys>(
    state: SearchWorkerReduxState,
    selectorKey: S,
    args: SelectorParameters<SearchWorkerSelectors[S]>,
  ) {
    if (featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
      return getSelectorValue(state, selectorKey, args);
    }

    if (this.#workerApi) {
      return this.#workerApi.getSelectorValue(selectorKey, args);
    }

    return getSelectorValue(state, selectorKey, args);
  }

  async getSearchAutocompleteResults(
    state: SearchWorkerReduxState,
    params: SearchAutocompleteParams,
  ) {
    if (featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
      const projectCodesEnabled = selectIsProjectCodesEnabled(state);

      const queryResult = await queryApi(
        {
          query: params.rawInput,
          category: params.expandedCategory,
          attributes: getQueryAttributes({
            category: params.expandedCategory,
            projectCodesEnabled,
          }),
        },
        { projectCodesEnabled },
      );

      return getSearchAutocompleteResults(state, {
        ...params,
        remoteQueryResult: queryResult,
      });
    }

    return this.getSelectorValue(state, 'getSearchAutocompleteResults', [
      params,
    ]);
  }
}
