import React from 'react';

import { Checkbox } from '@float/ui/components/Checkbox';
import { CheckboxCheckedState } from '@float/ui/components/Checkbox/types';

type RowCheckboxProps = {
  as?: 'div' | 'td';
  checked: CheckboxCheckedState;
  onChange?: (checked: CheckboxCheckedState) => void;
};

export function RowCheckbox(props: RowCheckboxProps) {
  const { as, checked, onChange } = props;
  const isDisabled = typeof onChange !== 'function';
  const children = (
    <Checkbox checked={checked} disabled={isDisabled} onChange={onChange} />
  );

  if (!as) return children;

  return React.createElement(as, undefined, children);
}
