export const enum ProjectBudgetType {
  Omitted = -1,
  Disabled = 0,
  TotalHours = 1,
  TotalFee = 2,
  HourlyFee = 3,
}

export const enum ProjectBudgetPriority {
  Project = 0,
  Phase = 1,
  Task = 2,
}

export const enum ProjectRateType {
  PerPerson = 1,
  PerProject = 2,
}

export enum ProjectStatus {
  Draft = 0,
  Tentative = 1,
  Confirmed = 2,
}
