import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { FIN } from '@float/theme';
import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import { R400NoUnderline } from '@float/ui/deprecated/Earhart/Typography/Label14';

import { inputStyles } from './styles';

export const DateInputContainer = styled.div<{
  inputTextStyle?: FlattenSimpleInterpolation;
  inputWidth?: number;
  height?: number;
  appearance?: 'outline';
}>`
  position: relative;
  ${inputStyles};
  input {
    ${(p) => p.inputTextStyle ?? ''};
    cursor: pointer;
  }

  input {
    width: ${(p) => p.inputWidth ?? 130}px;
  }

  ${(p) =>
    p.height &&
    css`
      input {
        height: ${p.height}px;
      }
    `}

  ${(p) =>
    p.appearance === 'outline' &&
    css`
      label {
        width: fit-content;
      }

      .date-input-wrapper {
        height: 40px;
        padding: 0 10px 0 16px;
        position: relative;

        &:hover {
          &:before {
            border-color: ${FIN.Color.Action.ActionPrimary.Default};
          }
        }

        // Borders
        &:after,
        &:before {
          content: '';
          position: absolute;
          box-sizing: border-box;

          transition-property: transform, opacity, border-color;
          transition-duration: ${Animation.Settings.Duration.Normal};
          transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
        }

        // Inner border
        &:before {
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          background-color: ${FIN.Color.Surface.Default};

          border-width: 1px;
          border-style: solid;
          border-color: var(
            --input-border-color,
            ${FIN.Color.Radix.Flay.Flay5}
          );
          border-radius: 5px;

          z-index: 1;
        }

        // Outer border
        &:after {
          top: -2px;
          left: -2px;
          bottom: -2px;
          right: -2px;

          border-width: 2px;
          border-style: solid;
          border-color: ${FIN.Color.Radix.Flue.Flue6};
          border-radius: 6px;

          background-color: ${FIN.Color.Radix.Flue.Flue6};

          opacity: 0;
          transform: scale(0.99, 0.9);

          z-index: 0;
        }

        &[data-state='open'] {
          &:after {
            opacity: 1;
            transform: none;
          }
        }
      }

      input {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        ${R400NoUnderline}
      }
    `}
`;
