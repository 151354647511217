import React, { useCallback, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { isObject, isUndefined } from 'lodash';
import ImportPeopleModal from 'manage/people-v2/import-people/ImportPeopleModal';
import ImportProjectsModal from 'manage/projects-v2/import-projects/ImportProjectsModal';
import manageModal from 'modalManager/manageModalActionCreator';

import {
  LOGGED_TIME_BULK_CREATED,
  LOGGED_TIME_BULK_CREATED_UNDO,
} from '@float/common/actions/loggedTimes';
import { ensureProjectsLoaded } from '@float/common/actions/projects';
import { trackEvent, trackIntegrationEvent } from '@float/common/lib/analytics';
import { ASYNC_PROCESS_RECEIVED } from '@float/common/lib/asyncProcess';
import { displayHoursFormatIsTime } from '@float/common/lib/timer/displayHoursFormat';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';
import { getPrefs, getUser } from '@float/common/selectors/currentUser';
import {
  useAppDispatch,
  useAppSelector,
  useAppSelectorStrict,
} from '@float/common/store';
import IconTick from '@float/ui/deprecated/Icons/icon-tick';
import { useSnackbar } from '@float/ui/deprecated/Snackbar';
import { getTypeString } from '@float/web/importCsv/helpers';
import {
  ProjectPlanViewSource,
  trackProjectPlanView,
} from '@float/web/lib/tracking/trackProjectPlanView';
import {
  fetchAllIntegrations,
  fetchSidebarData,
} from '@float/web/pmSidebar/actions';
import { integrationTitles } from '@float/web/pmSidebar/constants';
import { shouldShowPmSidebar } from '@float/web/pmSidebar/reducers/selectors';
import { undo } from '@float/web/undo/actions';

import { useOnProjectCreated } from './AsyncProcess.hooks';

function AsyncProcess() {
  const user = useAppSelectorStrict(getUser);
  const dispatch = useAppDispatch();
  const showPmSidebar = useAppSelector(shouldShowPmSidebar);
  const prefs = useAppSelector(getPrefs);
  const [processes, setProcesses] = useState([]);

  // TODO: https://linear.app/float-com/issue/SC-882/bug-async-process-snackbars-should-only-be-shown-to-the-actor
  const { showSnackbar, closeSnackbar } = useSnackbar();

  const onImportDataReceived = useCallback(
    (eventData) => {
      const { processId, data } = eventData;
      const newProcesses = processes.filter((x) => x.processId !== processId);
      const error =
        (data.errors && data.errors.length) ||
        (data.duplicates && data.duplicates.length);
      if (error) {
        closeSnackbar(processId);
        setProcesses([...newProcesses, eventData]);
      } else {
        setProcesses(newProcesses);
      }

      if (!isUndefined(data.success)) {
        const successCount = +data.success;
        const ignoredCount = +data.ignored;

        const successMessage = `Imported ${successCount} ${getTypeString(
          data.type,
          successCount,
        )}.`;

        const ignoredMessage = ignoredCount
          ? ` ${ignoredCount} ${getTypeString(
              data.type,
              ignoredCount,
            )} ignored.`
          : '';

        showSnackbar(`${successMessage}${ignoredMessage}`, {
          id: error ? null : processId,
          className: 'success',
          persist: false,
          loader: false,
          showClose: false,
          IconRight: IconTick,
        });

        trackEvent(`Imported ${data.type}`, { successCount, ignoredCount });
      }
    },
    [processes, showSnackbar, closeSnackbar],
  );

  const onPmImportDataReceived = useCallback(
    (eventData) => {
      const { processId, data, integrationType: type } = eventData;
      const title = integrationTitles[type] || 'Integration';
      const newProcesses = processes.filter((x) => x.processId !== processId);
      if (data.error) {
        closeSnackbar(processId);
        showSnackbar(`Failed to import ${title}: ${data.error}`, {
          id: null,
          loader: false,
          persist: true,
          showClose: true,
          linkText: 'Review settings',
          linkTo: '/admin/api',
        });
        setProcesses([...newProcesses, eventData]);
      } else if (data.sync) {
        showSnackbar(`${title} sync in progress.`, {
          id: processId,
          loader: true,
          persist: true,
          showClose: true,
          linkText: null,
          linkTo: null,
        });
        setProcesses([...newProcesses, eventData]);
      } else if (isObject(data.stats)) {
        // force reload projects if it's first progress notification
        if (data.remaining + 1 === data.stats.projects) {
          dispatch(ensureProjectsLoaded({ forceLoad: true }));
        }

        // always refresh sidebar
        dispatch(fetchSidebarData());

        // if no remaining process, display snackbar
        if (!data.remaining) {
          const { projects = 0, people = 0, estimate = false } = data.stats;
          const message = `Imported ${projects} ${getTypeString(
            'project',
            projects,
          )} and ${people} ${getTypeString('people', people)} from ${title}.`;
          showSnackbar(message, {
            id: processId,
            loader: false,
            persist: true,
            showClose: true,
            ...(showPmSidebar && {
              linkText: 'Start Scheduling',
              linkTo: '/#pm-sidebar',
            }),
          });
          trackIntegrationEvent(
            type,
            'Integration completed',
            {
              people,
              projects,
              estimate,
            },
            user.cid,
          );
        }
      } else {
        closeSnackbar(processId);
        setProcesses([...newProcesses, eventData]);
      }
    },
    [processes, closeSnackbar, showSnackbar, showPmSidebar, dispatch, user.cid],
  );

  const onPmResyncDataReceived = useCallback(
    (eventData) => {
      const {
        processId,
        data: { step, error },
      } = eventData;
      const newProcesses = processes.filter((x) => x.processId !== processId);
      switch (step) {
        case 'start': {
          dispatch(fetchAllIntegrations());
          break;
        }
        case 'end': {
          showSnackbar(`Sync completed.`, {
            id: processId,
            loader: false,
            persist: false,
            showClose: false,
            autoCloseMs: 10000,
          });
          setProcesses([...newProcesses, eventData]);
          break;
        }
        case 'error': {
          closeSnackbar(processId);
          showSnackbar(
            `Failed to resync integration: ${
              error || 'Unexpected error, please try again.'
            }`,
            {
              id: null,
              loader: false,
              persist: true,
              showClose: true,
            },
          );
          setProcesses([...newProcesses, eventData]);
          break;
        }
      }
    },
    [processes, closeSnackbar, showSnackbar, dispatch],
  );

  const onDuplicateDataReceived = useCallback(
    (eventData) => {
      const { processId, data } = eventData;
      const newProcesses = processes.filter((x) => x.processId !== processId);
      setProcesses(newProcesses);

      const { success, project_name: projectName, errors } = data;
      let message = `${projectName} ${!success ? 'could not be ' : ''}added.`;
      const snackbarOptions = {
        id: processId,
        persist: false,
        loader: false,
        showClose: false,
        autoCloseMs: 10000,
      };
      if (success) {
        const queryString = `?project=${encodeURIComponent(projectName)}`;
        snackbarOptions.linkText = t`Plan`;
        snackbarOptions.linkTo = `/project-plan${queryString}`;
        snackbarOptions.onLinkClick = () =>
          trackProjectPlanView(ProjectPlanViewSource.Snackbar);
      } else if (errors?.length && errors[0].message) {
        message = errors[0].message;
      }
      showSnackbar(message, snackbarOptions);
    },
    [processes, showSnackbar, dispatch],
  );

  const onExtCalImportDataReceived = useCallback(
    (eventData) => {
      const { processId, data } = eventData;
      const newProcesses = processes.filter((x) => x.processId !== processId);
      setProcesses(newProcesses);

      const message = data.sync
        ? 'Calendar sync in progress'
        : data.finished
          ? 'Calendar sync is complete'
          : `Failed to sync calendar: ${
              data.error || 'Please check your connection.'
            }`;
      if (data.error) {
        closeSnackbar(processId);
      }
      showSnackbar(message, {
        id: !data.error ? processId : null,
        loader: data.sync,
        persist: !data.finished,
        showClose: !data.finished,
        ...(data.finished && { autoCloseMs: 10000 }),
      });
    },
    [processes, showSnackbar, closeSnackbar],
  );
  const onExtCalDisconnectDataReceived = useCallback(
    (eventData) => {
      const { processId, data } = eventData;
      const newProcesses = processes.filter((x) => x.processId !== processId);
      setProcesses(newProcesses);

      if (data.finished && data.unsyncedCount > 0) {
        closeSnackbar(processId);
        dispatch(
          manageModal({
            modalType: 'integrationCalendarDisconnectedModal',
            modalSettings: {
              unsyncedCount: data.unsyncedCount,
            },
          }),
        );
        return;
      }
      const message = data.sync
        ? 'Calendar sync disconnect in progress'
        : data.finished
          ? 'Calendar sync is disconnected'
          : `Failed to disconnect calendar: ${data.error}`;
      if (data.error) {
        closeSnackbar(processId);
      }
      showSnackbar(message, {
        id: !data.error ? processId : null,
        loader: data.sync,
        persist: !data.finished,
        showClose: !data.finished,
        ...(data.finished && { autoCloseMs: 10000 }),
      });
    },
    [processes, showSnackbar, closeSnackbar, dispatch],
  );

  const onBulkLogTime = useCallback(
    ({ processId, time, people }) => {
      const shouldDisplayAsTime = displayHoursFormatIsTime(prefs);

      const hoursStr = time
        ? shouldDisplayAsTime
          ? formatDecimalHoursAsClockTime(time)
          : `${time.toLocaleString()}h`
        : 'Time';

      setTimeout(() => {
        showSnackbar(`${hoursStr} logged`, {
          id: processId,
          autoCloseMs: 60000,
          showClose: true,
          linkText: 'Undo',
          onLinkClick: () => {
            return dispatch(undo());
          },
        });
      }, 800);
      trackEvent('Logged Suggested Task', { time, people });
    },
    [showSnackbar, dispatch, prefs],
  );

  const onBulkLogTimeUndo = useCallback(
    ({ processId }) => {
      closeSnackbar(processId);
    },
    [closeSnackbar],
  );

  const onProjectCreated = useOnProjectCreated();

  const onDataReceived = useCallback(
    (event) => {
      const { processId, processType } = event.detail;
      if (!processId) {
        return;
      }

      if (processType === 'import') {
        onImportDataReceived(event.detail);
      } else if (processType === 'pm-import') {
        onPmImportDataReceived(event.detail);
      } else if (processType === 'pm-resync') {
        onPmResyncDataReceived(event.detail);
      } else if (processType === 'duplicate') {
        onDuplicateDataReceived(event.detail);
      } else if (processType === 'ext-cal-import') {
        onExtCalImportDataReceived(event.detail);
      } else if (processType === 'ext-cal-disconnect') {
        onExtCalDisconnectDataReceived(event.detail);
      } else if (processType === LOGGED_TIME_BULK_CREATED) {
        onBulkLogTime(event.detail);
      } else if (processType === LOGGED_TIME_BULK_CREATED_UNDO) {
        onBulkLogTimeUndo(event.detail);
      } else if (processType === 'Create project from template') {
        onProjectCreated(event.detail);
      }
    },
    [
      onImportDataReceived,
      onPmImportDataReceived,
      onPmResyncDataReceived,
      onDuplicateDataReceived,
      onExtCalImportDataReceived,
      onExtCalDisconnectDataReceived,
      onBulkLogTime,
      onBulkLogTimeUndo,
      onProjectCreated,
    ],
  );

  useEffect(() => {
    window.addEventListener(ASYNC_PROCESS_RECEIVED, onDataReceived);
    return () => {
      window.removeEventListener(ASYNC_PROCESS_RECEIVED, onDataReceived);
    };
  }, [onDataReceived]);

  const closeProcess = (processId) => {
    const newProcesses = processes.filter((x) => x.processId !== processId);
    setProcesses(newProcesses);
  };

  const renderImport = ({ processId, data }) => {
    let ImportComponent;

    if (data.type === 'people') {
      ImportComponent = ImportPeopleModal;
    } else if (data.type === 'project') {
      ImportComponent = ImportProjectsModal;
    }

    if (ImportComponent) {
      return (
        <ImportComponent
          {...data}
          key={processId}
          processId={processId}
          onClose={() => closeProcess(processId)}
        />
      );
    }

    return null;
  };

  const renderProcess = (props) => {
    if (props.processType === 'import') {
      return renderImport(props);
    }
    return null;
  };

  return processes.map(renderProcess);
}

export default AsyncProcess;
