import {
  searchSelectors,
  SearchWorkerSelectors,
  SearchWorkerSelectorsKeys,
  SelectorParameters,
} from './searchSelectors';
import { SearchWorkerReduxState } from './searchStore';

export async function getSelectorValue<S extends SearchWorkerSelectorsKeys>(
  state: SearchWorkerReduxState,
  selectorKey: S,
  args: SelectorParameters<SearchWorkerSelectors[S]>,
) {
  // @ts-expect-error Typescript doesn't like the mix of Parameters and spread argument
  // because it requires tuples when doing spread
  return searchSelectors[selectorKey](state, ...args) as ReturnType<
    SearchWorkerSelectors[S]
  >;
}
