import { ScheduleViewType } from '@float/constants/schedule';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

export type GetViewTypeResult =
  (typeof ScheduleViewType)[keyof typeof ScheduleViewType];

export type GetViewTypeParams = {
  logTimeView: boolean;
  path: string;
  viewType?: GetViewTypeResult;
  viewTypePref: GetViewTypeResult;
};

export function getViewType(params: GetViewTypeParams): GetViewTypeResult {
  const { logTimeView, path, viewType, viewTypePref } = params;

  if (logTimeView) {
    return ScheduleViewType.People;
  }

  let result = viewType ?? ScheduleViewType.People;

  if (viewTypePref && viewTypePref !== viewType) {
    result = viewTypePref;
  }

  const isSingleProjectView =
    featureFlags.isFeatureEnabled(FeatureFlag.SingleProjectView) &&
    path?.startsWith('/project/');

  if (isSingleProjectView) {
    return ScheduleViewType.SingleProject;
  }

  if (path === '/project-plan') {
    return ScheduleViewType.Projects;
  }

  // Given that "Project plan" is lifted to main nav, always render the
  // "People" view on "Schedule" page; since "Project plan" view has its
  // own dedicated page at the `/project-plan` path.
  if (result === ScheduleViewType.Projects) {
    return ScheduleViewType.People;
  }

  return result;
}
