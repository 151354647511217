import { Role } from '@float/types/role';

import { roleTableRowDisplayConfig as view } from './roleTableRowDisplayConfig';
import { roleTableRowEditConfig as edit } from './roleTableRowEditConfig';

export function getRoleTableRowWithoutRates(role: Role) {
  const data = [role.name, role.people_count];
  const display = [view.roleName(role), view.peopleCount(role)];
  const editConfig = [edit.roleName(), edit.peopleCount(role)];

  return { data, display, editConfig };
}
