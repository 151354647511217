import React from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';

import {
  RateHistory,
  RateHistoryProps,
  RateInput,
} from '@float/common/components/Rate';
import { Props as InputCurrencyStringProps } from '@float/ui/components/InputCurrency/InputCurrencyString';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';

import * as styles from './PersonRates.styles.css';

type Props = InputCurrencyStringProps & RateHistoryProps;

export function EditPersonCostRate({ history, onRemove, ...props }: Props) {
  const hasHistory = !isEmpty(history);

  return (
    <div className={styles.costRateInputWrapper}>
      <RateInput
        className={styles.costRateInput}
        label={t`Cost rate`}
        {...props}
      />

      {hasHistory && (
        <Popover
          className={styles.rateHistoryPopover}
          content={<RateHistory history={history} onRemove={onRemove} />}
        >
          <div className={styles.viewChangesButton}>
            <TextButton appearance="flue-fill">
              <Trans>View changes</Trans>
            </TextButton>
          </div>
        </Popover>
      )}
    </div>
  );
}
