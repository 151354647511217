export function darken(
  [r, g, b]: [number, number, number],
  val: number,
): [number, number, number] {
  return [
    Math.round(r * (1 - val)),
    Math.round(g * (1 - val)),
    Math.round(b * (1 - val)),
  ];
}
