import React from 'react';

import { Role } from '@float/types/role';
import { RoleTablePeopleCount } from '@float/web/settingsV2/components/Roles/RoleTableCell/RoleTablePeopleCount';
import { RoleTableRate } from '@float/web/settingsV2/components/Roles/RoleTableCell/RoleTableRate';

import * as styles from '../../Roles.styles.css';

export const roleTableRowDisplayConfig = {
  roleName: (role: Role) => <div className={styles.roleName}>{role.name}</div>,

  peopleCount: (role: Role) => (
    <RoleTablePeopleCount name={role.name} count={role.people_count ?? 0} />
  ),

  costRate: (role: Role) => <RoleTableRate rate={role.cost_rate} />,

  billRate: (role: Role) => <RoleTableRate rate={role.default_hourly_rate} />,
};
