import React, { useCallback, useMemo } from 'react';
import { t } from '@lingui/macro';

import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';
import { isCostRateFeatureActive } from '@float/common/lib/rates/rates';
import { getPeopleByRoleId } from '@float/common/selectors/people/people';
import { useAppSelectorStrict } from '@float/common/store';
import { getBulkEditBillRateConfig } from '@float/web/components/elements/BulkEditModal/BulkEditBillRate';
import { getBulkEditCostRateConfig } from '@float/web/components/elements/BulkEditModal/BulkEditCostRate';
import BulkEditModal from '@float/web/components/elements/BulkEditModal/BulkEditModal';
import { BulkEditRolesHeader } from '@float/web/components/elements/BulkEditModal/BulkEditRolesHeader';
import { ModalConfirmRateChange } from '@float/web/components/modals/ModalConfirmRateChange/ModalConfirmRateChange';
import { getRoles } from '@float/web/selectors';

import { useModal } from '../../components/Roles/useModal';
import { HandleBulkEditRolesProps, useBulkEditRoles } from './useBulkEditRoles';

type BulkEditRolesProps = {
  inTableEditMode: number | null;
  roleIds: Set<number>;
  onSingleRoleEdit: (id: number) => void;
};

type BulkEditRolesModalProps = {
  roleIds: Set<number>;
  onClose: () => void;
};

function getBulkEditFields() {
  const default_hourly_rate = getBulkEditBillRateConfig();

  if (!isCostRateFeatureActive()) {
    return { default_hourly_rate };
  }

  return {
    cost_rate_history: getBulkEditCostRateConfig(),
    default_hourly_rate,
  };
}

export function BulkEditRolesModal(props: BulkEditRolesModalProps) {
  const { roleIds, onClose } = props;
  const rolesMap = useAppSelectorStrict(getRoles);
  const peopleByRoleId = useAppSelectorStrict(getPeopleByRoleId);
  const currencyConfig = useCurrencyProps();
  const fields = useMemo(() => getBulkEditFields(), []);
  const ids = useMemo(() => Array.from(roleIds), [roleIds]);
  const modalConfirmRateChange = useModal();
  const { handleBulkEditRoles } = useBulkEditRoles(onClose);
  const numRolesAffected = ids.length;

  const numPeopleAffected = useMemo(
    () =>
      ids.reduce((total: number, id: number) => {
        const rolePeopleCount = peopleByRoleId[id]?.length || 0;
        return total + rolePeopleCount;
      }, 0),
    [peopleByRoleId, ids],
  );

  const handleSaveClick = useCallback(
    async (args: HandleBulkEditRolesProps) => {
      const { fields } = args;
      const costRateTo = fields?.cost_rate_history?.add?.[0]?.rate ?? null;

      if (numPeopleAffected === 0) {
        await handleBulkEditRoles(args);
        return;
      }

      modalConfirmRateChange.open(
        <ModalConfirmRateChange
          numPeopleAffected={numPeopleAffected}
          numRolesAffected={numRolesAffected}
          billRateTo={fields?.default_hourly_rate}
          costRateTo={costRateTo}
          currencyConfig={currencyConfig}
          onCancel={() => {
            modalConfirmRateChange.close();
            if (typeof args.setLoadingFn === 'function') {
              args.setLoadingFn(false);
            }
          }}
          onConfirm={async () => {
            modalConfirmRateChange.close();
            await handleBulkEditRoles(args);
          }}
        />,
      );
    },
    [
      currencyConfig,
      modalConfirmRateChange,
      numRolesAffected,
      numPeopleAffected,
      handleBulkEditRoles,
    ],
  );

  return (
    <>
      <BulkEditModal
        type="Role"
        plural={t`Roles`}
        ids={ids}
        entities={rolesMap}
        fields={fields}
        onUpdate={handleSaveClick}
        onCancel={onClose}
      />
      {modalConfirmRateChange.render()}
    </>
  );
}

export function BulkEditRoles({
  inTableEditMode,
  roleIds,
  onSingleRoleEdit,
}: BulkEditRolesProps) {
  const rolesCount = roleIds.size;

  const bulkEditModal = useModal();

  if (!rolesCount) return null;

  const handleEditClick = () => {
    if (inTableEditMode) return;

    if (rolesCount === 1) {
      const [roleId] = roleIds;
      onSingleRoleEdit(roleId);
      return;
    }

    bulkEditModal.open(
      <BulkEditRolesModal roleIds={roleIds} onClose={bulkEditModal.close} />,
    );
  };

  return (
    <>
      <BulkEditRolesHeader
        count={rolesCount}
        inTableEditMode={inTableEditMode}
        onEditClick={handleEditClick}
      />
      {bulkEditModal.render()}
    </>
  );
}
