import React from 'react';
import { t, Trans } from '@lingui/macro';
import { memoize } from 'lodash';

import { timeTrackingEnabled } from '@float/common/lib/acl';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Button } from '@float/ui/deprecated/Button/Button';
import { IconArrowDown, IconArrowUp } from '@float/ui/deprecated/Earhart/Icons';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';
import { ReadOnlyField } from '@float/ui/deprecated/Field/ReadOnlyField';
import { Input } from '@float/ui/deprecated/Input';
import { FieldLabel } from '@float/ui/deprecated/Label';

import { ButtonsContainer } from './MyInfoModal.styles';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import ForgotPassword from './ResetPassword';
import {
  StyledExpandableContainer,
  StyledFieldGroup,
  StyledInput,
} from './styles';

const getTimeFormatOptions = memoize(() => [
  { value: 1, label: t`12-hour clock` },
  { value: 2, label: t`24-hour clock` },
]);

const getDurationDisplayFormatOptions = memoize(() => [
  { value: 1, label: t`HH:MM` },
  { value: 0, label: t`Decimal` },
]);

export function InfoFragment() {
  const {
    newAccount,
    errors,
    isSubmitting,
    passwordFieldsetOpen,
    sensitiveDataChanged,
    resetPassword,
  } = this.state;

  const { currentUser } = this.props;

  const isPasswordStrengthMeterFF = featureFlags.isFeatureEnabled(
    FeatureFlag.PasswordStrengthMeter,
  );

  const onTogglePasswordFields = () => {
    if (this.state.passwordFieldsetOpen) {
      setTimeout(() => {
        this.currentPasswordRef.focusInput();
      }, 200);
    }
  };

  const tooglePasswordFields = (e) => {
    this.togglePasswordFieldset(e, onTogglePasswordFields);
  };

  const onChangeInput = (e) => {
    const { value, dataset } = e.currentTarget;
    const { name } = dataset;

    this.handleChange(name, value);
  };

  const onChangeTimeFormat = (value) => {
    this.setState(() => ({ timeFormatUsesCompany: false }));

    this.handleChange('time_format_24h', value);
  };

  const onChangeDurationDisplayFormat = (value) => {
    this.handleChange('duration_display_format', value);
  };

  // did any value change other than the old_password
  const isConfirmEnabled = this.didAnyValueChange(undefined, ['old_password']);

  const ssoEmail = currentUser.sso_email;

  return (
    <>
      <StyledFieldGroup>
        <Input
          ref={this.setEmailRef}
          type="email"
          label={t`Email *`}
          value={newAccount.email}
          maxLength={125}
          autoFocus
          secondaryText={
            currentUser.login_method !== 1 &&
            currentUser.sso_email &&
            currentUser.sso_type === 'google'
              ? t`You are connected to your Google account (${ssoEmail})`
              : null
          }
          readOnly={currentUser.login_method !== 1 && !!currentUser.sso_email}
          noPointerEvents={
            currentUser.login_method !== 1 && !!currentUser.sso_email
          }
          errors={errors.email}
          onChange={onChangeInput}
          dataName="email"
          data1pIgnore={false}
        />

        {currentUser.login_method !== 1 &&
          currentUser.sso_email &&
          currentUser.sso_type === 'google' && (
            <ForgotPassword
              data={resetPassword}
              onClick={this.sendPassResetEmail}
            />
          )}
      </StyledFieldGroup>

      {currentUser.login_method === 1 && (
        <StyledFieldGroup>
          <StyledExpandableContainer
            ref={this.setPasswordFieldsetRef}
            open={passwordFieldsetOpen || sensitiveDataChanged}
          >
            <StyledInput>
              <Input
                ref={this.setCurrentPasswordRef}
                className="currentPassword"
                type="password"
                autocomplete="off"
                label={
                  sensitiveDataChanged
                    ? t`Enter current password to confirm change`
                    : t`Current password`
                }
                value={newAccount.old_password}
                maxLength={100}
                errors={errors.old_password}
                onChange={onChangeInput}
                dataName="old_password"
                data1pIgnore={false}
              />

              <ForgotPassword
                data={resetPassword}
                onClick={this.sendPassResetEmail}
              />
            </StyledInput>

            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              ref={this.setNewPasswordRef}
            >
              <StyledInput>
                <Input
                  type="password"
                  autocomplete="off"
                  label={t`New password`}
                  value={newAccount.new_password}
                  maxLength={100}
                  secondaryText={
                    errors.new_password && errors.new_password.length > 0
                      ? null
                      : t`(Min. 8 characters with a letter and a number)`
                  }
                  errors={errors.new_password}
                  onChange={onChangeInput}
                  dataName="new_password"
                  data1pIgnore={false}
                />
                {isPasswordStrengthMeterFF && (
                  <PasswordStrengthMeter password={newAccount.new_password} />
                )}
              </StyledInput>

              <StyledInput>
                <Input
                  type="password"
                  autocomplete="off"
                  label={t`Confirm password`}
                  value={newAccount.password_confirm}
                  maxLength={100}
                  errors={errors.password_confirm}
                  onChange={onChangeInput}
                  dataName="password_confirm"
                  data1pIgnore={false}
                />
              </StyledInput>
            </div>
          </StyledExpandableContainer>

          <Button
            type="button"
            appearance="secondary"
            iconRight={!passwordFieldsetOpen ? IconArrowDown : IconArrowUp}
            onClick={tooglePasswordFields}
          >
            {!passwordFieldsetOpen ? t`Change password` : t`Cancel`}
          </Button>
        </StyledFieldGroup>
      )}

      <StyledFieldGroup>
        <ReadOnlyField
          label="Access"
          value={currentUser.account_type_name || t`Account Owner`}
        />
      </StyledFieldGroup>

      <StyledFieldGroup>
        <FieldLabel>
          <Trans>Time format</Trans>
        </FieldLabel>
        <TextToggle
          value={newAccount.time_format_24h.value}
          options={getTimeFormatOptions()}
          onChange={onChangeTimeFormat}
        />
      </StyledFieldGroup>

      {timeTrackingEnabled(currentUser) && (
        <StyledFieldGroup>
          <FieldLabel>
            <Trans>Log time display format</Trans>
          </FieldLabel>
          <TextToggle
            value={newAccount.duration_display_format.value}
            options={getDurationDisplayFormatOptions()}
            onChange={onChangeDurationDisplayFormat}
          />
        </StyledFieldGroup>
      )}

      <ButtonsContainer>
        <Button
          type="submit"
          loader={isSubmitting}
          disabled={!isConfirmEnabled}
        >
          <Trans>Save settings</Trans>
        </Button>
        <Button appearance="secondary" onClick={this.hide}>
          <Trans>Cancel</Trans>
        </Button>
      </ButtonsContainer>
    </>
  );
}
