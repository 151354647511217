import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { IconRadioOff } from '@float/ui/icons/essentials/IconRadioOff';
import { IconRadioOn } from '@float/ui/icons/essentials/IconRadioOn';

import Icons from '../Icons';

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  line-height: 24px;

  &:focus {
    outline: none;
  }

  svg {
    min-width: 24px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.horizontal ? 'row' : 'column')};

  ${(p) =>
    p.horizontal
      ? css`
          ${Option}:not(:first-child) {
            margin-left: 20px;
          }
        `
      : css`
          ${Option}:not(:first-child) {
            margin-top: 10px;
          }
        `}
`;

const OptionLabel = styled.span`
  margin-left: 9px;
  font-size: 18px;
  color: ${(p) => p.theme.charcoalGrey};
`;

const ICON_TYPES = {
  default: {
    on: Icons.RadioOn,
    off: Icons.RadioOff,
  },

  essentials: {
    on: IconRadioOn,
    off: IconRadioOff,
  },

  checkbox: {
    on: Icons.CircleCheckOn,
    off: Icons.CircleCheckOff,
  },
};

export const useRadio = (options) => {
  const [currentOptionIdx, setCurrentOptionIdx] = useState(
    () => options.findIndex((opt) => opt.default) || 0,
  );
  const optionsWithValue = options.map((opt, i) => {
    return {
      value: i,
      ...opt,
    };
  });

  const radioProps = {
    onChange: setCurrentOptionIdx,
    value: currentOptionIdx,
    options: optionsWithValue,
  };

  return [options[currentOptionIdx], radioProps];
};

export function Radio(props) {
  const {
    className,
    iconType = 'default',
    horizontal,
    options,
    value,
    onChange,
    renderOption,
  } = props;

  return (
    <Container className={className} horizontal={horizontal}>
      {options.map((opt) => {
        const isActive = opt.value === value;
        const Icon = ICON_TYPES[iconType][isActive ? 'on' : 'off'];

        return (
          <Option
            tabIndex={0}
            key={opt.value}
            onClick={() => {
              onChange(opt.value);
            }}
            onKeyPress={(evt) => {
              if (evt.key === ' ') {
                onChange(opt.value);
              }
            }}
          >
            {renderOption ? (
              renderOption(isActive, opt)
            ) : (
              <>
                <Icon className={isActive ? 'on' : 'off'} />
                <OptionLabel>{opt.label}</OptionLabel>
              </>
            )}
          </Option>
        );
      })}
    </Container>
  );
}

export default Radio;
