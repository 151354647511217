import type { VirtualFilterTypes } from '@float/constants/search';
import type { LegacyFilterType } from '@float/types/view';

export const SEARCH_RESOLVE_WILDCARD_VALUE = '*';

export enum SearchResolveMatchMode {
  Wildcard = 'wildcard',
}

export enum LegacyFilterSpecialValue {
  None = 'None',
}

export const LEGACY_FILTERS_THAT_SUPPORT_NONE_VALUE: Array<
  VirtualFilterTypes | LegacyFilterType
> = ['department', 'jobTitle', 'project'];

export enum SearchResolveFilterSpecialValue {
  None = 'none',
  Any = '*',
}

export enum SearchResolveFilterOperator {
  Is = 'is',
  IsNot = 'isNot',
  Contains = 'contains',
}

export enum SearchResolveQueryLogicalOperator {
  And = 'and',
  Or = 'or',
}

export enum SearchResolveMatchAlgorithm {
  CONTAINS = 'contains',
  EXACT = 'exact',
  FULL_TEXT_EXACT = 'full_text_exact',
  FULL_TEXT_WILDCARD = 'full_text_wildcard',
}
