import { omit } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { Person } from '@float/types';
import { Account } from '@float/types/account';

import {
  ACCOUNTS_DELETE,
  ACCOUNTS_LOAD_FAILED,
  ACCOUNTS_LOAD_FINISH,
  ACCOUNTS_LOAD_START,
  ACCOUNTS_UPDATE,
  PEOPLE_BULK_UPDATED,
} from '../actions';
import { checkIsPeopleManager } from '../lib/acl/access';
import { LoadState, RehydratePartialStateAction } from './lib/types';

export type AccountsState = {
  accounts: Record<number, Account>;
  loadState: LoadState;
  accountsLoaded: boolean;
};

export type AccountsAction =
  | {
      type: typeof ACCOUNTS_LOAD_START;
    }
  | {
      type: typeof ACCOUNTS_LOAD_FAILED;
    }
  | {
      type: typeof ACCOUNTS_LOAD_FINISH;
      accounts: AccountsState['accounts'];
    }
  | {
      type: typeof ACCOUNTS_UPDATE;
      account?: Account | Partial<Account>;
    }
  | {
      type: typeof ACCOUNTS_DELETE;
      account?: Account;
    }
  | {
      type: typeof PEOPLE_BULK_UPDATED;
      people: Person[];
      fields: any;
    };

const REDUCER_NAME = 'accounts';
const DEFAULT_STATE: AccountsState = {
  accounts: {},
  loadState: LoadState.UNLOADED,
  accountsLoaded: false,
};

const isBulkAccountField = (field: string) =>
  ['access', 'management_group'].includes(field);

export const accountsReducer = (
  state: AccountsState = DEFAULT_STATE,
  action?:
    | AccountsAction
    | RehydratePartialStateAction<AccountsState, typeof REDUCER_NAME>,
): AccountsState => {
  if (!action) return state;

  switch (action.type) {
    case ACCOUNTS_LOAD_FINISH: {
      return {
        ...state,
        loadState: LoadState.LOADED,
        accountsLoaded: true,
        accounts: action.accounts,
      };
    }

    case PEOPLE_BULK_UPDATED: {
      const { people, fields } = action;
      const nextAccounts = { ...state.accounts };

      people.forEach((person) => {
        if (nextAccounts[person.account_id]) {
          const nextAccount = { ...nextAccounts[person.account_id] };

          Object.entries(fields).forEach(([field, update]) => {
            const skip = !isBulkAccountField(field);
            if (skip) return;

            if (field === 'access') {
              const { access, account_type_id, department_filter } =
                update as any;
              Object.assign(nextAccount, {
                access,
                account_type_id: account_type_id,
                account_type: account_type_id,
                department_filter,
              });

              if (!checkIsPeopleManager(nextAccount)) {
                nextAccount.management_group = {
                  departments: [],
                  people: [],
                };
              }
            } else if (field === 'management_group') {
              nextAccount.management_group = Object.assign(
                {
                  departments: [],
                  people: [],
                },
                nextAccount.management_group,
                update,
              );
            }
          });

          nextAccounts[person.account_id] = nextAccount;
        }
      });
      return {
        ...state,
        accounts: nextAccounts,
      };
    }

    case ACCOUNTS_UPDATE: {
      const id = action.account?.account_id;
      if (!id) return state;

      const account = {
        ...(state.accounts[id] || {}),
        ...action.account,
      } as Account;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [id]: account,
        },
      };
    }

    case ACCOUNTS_DELETE: {
      const id = action.account?.account_id;
      if (!id) return state;

      return {
        ...state,
        accounts: omit(state.accounts, id),
      };
    }

    case ACCOUNTS_LOAD_FAILED: {
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
        accountsLoaded: false,
        accounts: {},
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.accountsLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
};

export default accountsReducer;
