import React from 'react';
import { t, Trans } from '@lingui/macro';

import {
  formatAmount,
  getExpandedInfo,
  getPercentage,
} from '@float/common/lib/budget';
import { ProjectBudgetType } from '@float/constants/projects';
import { getHasFixedBudgetSupport } from '@float/web/reports/helpers/getHasFixedBudgetSupport';
import { getHasProjectBudgetAccess } from '@float/web/reports/helpers/getHasProjectBudgetAccess';
import { getIsMoneyBudgetType } from '@float/web/reports/helpers/getIsMoneyBudgetType';

import {
  ItemHeader,
  ItemLabel,
  ItemSubLabel,
  ReportsTotalsCard,
  ReportsTotalsContainer,
  ReportsTotalsItem,
} from '../../../styles';
import {
  SummaryItemCost,
  SummaryItemCostCalculationType,
} from '../SummaryItemCost/SummaryItemCost';
import {
  SummaryItemMargin,
  SummaryItemTotalBudgetMarginCalculationType,
} from '../SummaryItemMargin/SummaryItemMargin';
import { SummaryItemTotalBudget } from '../SummaryItemTotalBudget/SummaryItemTotalBudget';
import type { SummaryBarProps } from '../types';

import * as summaryBarStyles from '../SummaryBar.css';

export function SummaryBarLogged({
  project,
  projectBudget,
  totals,
  hasBudgetsAccess,
  hasCostsAccess,
  hasPeopleWithMissingCostRates,
  projectMarginType,
  onProjectMarginTypeChange,
}: SummaryBarProps) {
  const { budget_type, non_billable } = project;

  const isMoneyBudgetType = getIsMoneyBudgetType(budget_type);
  const hasProjectBudgetAccess = getHasProjectBudgetAccess(
    hasBudgetsAccess,
    budget_type,
  );

  const budget_total = projectBudget?.budget_total ?? 0;

  let budget_used = 0;

  if (isMoneyBudgetType) {
    budget_used = totals.logged.feeCents / 100 || 0;
  } else {
    budget_used = non_billable
      ? totals.logged.nonbillable
      : totals.logged.billable;
  }

  const billable = non_billable
    ? totals.logged.nonbillable
    : totals.logged.billable;

  const budget_remaining = isMoneyBudgetType
    ? budget_total - budget_used
    : budget_total - billable;

  const label =
    isMoneyBudgetType && hasProjectBudgetAccess && !non_billable
      ? t`Logged billable`
      : t`Logged hours`;

  const hasFixedBudgetSupport = getHasFixedBudgetSupport(budget_type);
  const hasBudgetTotal = budget_total > 0;
  const isDisabledBudget = budget_type === ProjectBudgetType.Disabled;

  const perc = hasFixedBudgetSupport
    ? getPercentage(budget_used, budget_total)
    : null;
  const { overBudget = false, percentage: pct_remaining = 0 } =
    !hasFixedBudgetSupport
      ? {}
      : getExpandedInfo({
          budget_total,
          budget_remaining,
        });

  return (
    <ReportsTotalsContainer data-testid="summary-bar-logged">
      {!isDisabledBudget && hasProjectBudgetAccess && (
        <ReportsTotalsCard borderRight flexGrow={1}>
          <SummaryItemTotalBudget
            project={project}
            projectBudget={projectBudget}
            totals={totals}
          />
        </ReportsTotalsCard>
      )}

      <ReportsTotalsCard flexGrow={1}>
        {isMoneyBudgetType && hasProjectBudgetAccess ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>{label}</ItemLabel>
            {perc ? (
              <ItemSubLabel>
                <span
                  className={overBudget ? summaryBarStyles.valueCritical : ''}
                >
                  <Trans>{perc}% budget</Trans>
                </span>
              </ItemSubLabel>
            ) : null}
            <ItemHeader data-testid="project-budget-used">
              {formatAmount(budget_type, budget_used)}
              <ItemSubLabel>{formatAmount(1, billable)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>{label}</ItemLabel>
            {perc && hasProjectBudgetAccess ? (
              <ItemSubLabel>
                <span
                  className={overBudget ? summaryBarStyles.valueCritical : ''}
                >
                  <Trans>{perc}% budget</Trans>
                </span>
              </ItemSubLabel>
            ) : null}
            <ItemHeader data-testid="project-budget-used">
              {formatAmount(1, billable)}
            </ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      {hasCostsAccess && (
        <ReportsTotalsCard flexGrow={1}>
          <SummaryItemCost
            project={project}
            totals={totals}
            projectBudget={projectBudget}
            costCalculationType={SummaryItemCostCalculationType.LOGGED}
            hasPeopleWithMissingCostRates={hasPeopleWithMissingCostRates}
          />
        </ReportsTotalsCard>
      )}

      {hasFixedBudgetSupport && hasProjectBudgetAccess && (
        <ReportsTotalsCard flexGrow={1}>
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Budget remaining</Trans>
            </ItemLabel>
            {hasBudgetTotal && (
              <ItemSubLabel>
                <span
                  className={overBudget ? summaryBarStyles.valueCritical : ''}
                >
                  {overBudget ? '-' : ''}
                  {pct_remaining}%
                </span>
              </ItemSubLabel>
            )}
            <ItemHeader data-testid="project-budget-remaining">
              <span
                className={overBudget ? summaryBarStyles.valueCritical : ''}
              >
                {formatAmount(budget_type, budget_remaining)}
              </span>
            </ItemHeader>
          </ReportsTotalsItem>
        </ReportsTotalsCard>
      )}

      {hasCostsAccess && hasBudgetsAccess && isMoneyBudgetType && (
        <ReportsTotalsCard borderLeft flexGrow={1}>
          <SummaryItemMargin
            project={project}
            totals={totals}
            projectBudget={projectBudget}
            hasPeopleWithMissingCostRates={hasPeopleWithMissingCostRates}
            marginCalculationType={
              SummaryItemTotalBudgetMarginCalculationType.LOGGED
            }
            projectMarginType={projectMarginType}
            onProjectMarginTypeChange={onProjectMarginTypeChange}
          />
        </ReportsTotalsCard>
      )}
    </ReportsTotalsContainer>
  );
}
