import { useCallback, useMemo, useState } from 'react';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { storage } from '@float/libs/storage';

const MAX_RECENT_MENTIONS = 6;

const getLocalKey = (
  user: { admin_id: string | number; cid: string | number },
  trigger: string,
) => {
  const { admin_id, cid } = user || {};
  return `${cid || ''}::${admin_id || ''}::${trigger}::mentions`;
};

export function useRecentMentions<T extends { account_id: string }>(
  data: T[],
  trigger: string,
): [T[], (data: T) => void] {
  const user = useAppSelectorStrict(getUser);

  const getLocal = useCallback(async () => {
    const key = getLocalKey(user, trigger);
    const item = await storage.UNSAFE_getItem(key);
    const data = item ? JSON.parse(item) : [];

    return data;
  }, [user, trigger]);

  const saveLocal = useCallback(
    (data: unknown) => {
      const key = getLocalKey(user, trigger);
      const json = JSON.stringify(data);

      storage.UNSAFE_setItem(key, json);
    },
    [user, trigger],
  );

  const [recentMentions, setRecentMentions] = useState<{ a: string }[]>([]);

  useMemo(() => {
    getLocal().then(setRecentMentions);
  }, [getLocal]);

  const dataById = useMemo(
    () =>
      data.reduce<Record<string, T>>((all, item) => {
        all[item.account_id] = item;
        return all;
      }, {}),
    [data],
  );

  const recentMentionsData = useMemo(
    () =>
      recentMentions.reduce((all, item) => {
        if (dataById[item.a]) {
          all.push(dataById[item.a]);
        }

        return all;
      }, [] as T[]),
    [dataById, recentMentions],
  );

  const addRecentMention = useCallback(
    (data: T) => {
      // since we are exposing the account_id on the local_storage
      // we can as well just disguise it a bit
      const mention = { a: data.account_id };

      let mentions = [
        // add current mention to the first place
        mention,
        // only keep mentions different from current one and still exist in original data
        ...recentMentions.filter(
          (item) => item.a !== mention.a && dataById[item.a],
        ),
      ];

      // only keep the first MAX_RECENT_MENTIONS in the array
      if (mentions.length > MAX_RECENT_MENTIONS) {
        mentions = mentions.slice(0, MAX_RECENT_MENTIONS);
      }

      // update state
      setRecentMentions(mentions);
    },
    [recentMentions, setRecentMentions, dataById],
  );

  useUpdateEffect(() => {
    saveLocal(recentMentions);
  }, [recentMentions]);

  return [recentMentionsData, addRecentMention];
}
