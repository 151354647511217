import React from 'react';

import { useIsMediumMediaSizeActive } from '@float/libs/hooks/media';

import { ResponsiveFilterTokens } from './ResponsiveFilterTokens';
import { SearchFilterTokensProps } from './types';
import { WideScreenFilterTokens } from './WideScreenFilterTokens';

export const SearchFilterTokens = (props: SearchFilterTokensProps) => {
  const isMediumBreakpointActive = useIsMediumMediaSizeActive();

  if (isMediumBreakpointActive) {
    return <ResponsiveFilterTokens {...props} />;
  }

  return <WideScreenFilterTokens {...props} />;
};
