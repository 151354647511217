import {
  DEFAULT_ENTERPRISE,
  PRO_MONTHLY,
  PRO_YEARLY,
  STARTER_MONTHLY,
  STARTER_YEARLY,
} from '@float/common/lib/pricing';
import { AddOnStatus } from '@float/types/account';

import { getIsEnterprisePlan } from './getIsEnterprisePlan';

type GetIsProPlanParams = {
  isAnnualPlan: boolean;
  isPaidPlan: boolean;
  isProTrial: AddOnStatus | null;
  plusPack: AddOnStatus;
  proTrialEnd: string | null;
  timeTracking: AddOnStatus;
};

export const getIsProPlan = (id: string, params: GetIsProPlanParams) => {
  const {
    isAnnualPlan,
    isPaidPlan,
    isProTrial,
    plusPack,
    proTrialEnd,
    timeTracking,
  } = params;

  const planId = Number(id);
  const hasPlusPack = plusPack > 0;
  const hasTimeTracking = timeTracking > 0;
  const hasBothAddons = hasTimeTracking && hasPlusPack;
  const hasEitherPlan = hasTimeTracking || hasPlusPack;

  if (isPaidPlan) {
    if (
      planId === Number(PRO_MONTHLY.plan_id) ||
      planId === Number(PRO_YEARLY.plan_id)
    ) {
      return true;
    }

    // Customer on paid Starter plan, but is trialing Pro
    if (
      (timeTracking === AddOnStatus.TRIAL && plusPack === AddOnStatus.TRIAL) ||
      (isProTrial === AddOnStatus.TRIAL && proTrialEnd)
    ) {
      return true;
    }

    // Current Non-Pro plans
    if (
      planId === Number(DEFAULT_ENTERPRISE.plan_id) ||
      planId === Number(STARTER_MONTHLY.plan_id) ||
      planId === Number(STARTER_YEARLY.plan_id)
    ) {
      return false;
    }

    // Special case for customers with RP + PP (Beta)
    if (!hasTimeTracking && Number(plusPack) === AddOnStatus.BETA) {
      return false;
    }

    // Legacy paid plans where either addon is enabled
    // And not annual plans where both addons are enabled
    const isEnterprisePlan = getIsEnterprisePlan(id, {
      isPaidPlan,
      hasBothAddons,
      isAnnualPlan,
    });
    return hasEitherPlan && !isEnterprisePlan;
  }

  // Trial plans considered "Pro" when both TT + PP addons enabled
  return Boolean(hasBothAddons);
};
