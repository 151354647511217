import { createSelector } from 'reselect';

import { AccountType } from '@float/constants/accounts';

import {
  getUserAccess,
  userCanOnlyViewThemself,
  userCanUpdateThemself,
} from '../lib/rights';
import { getUser } from './currentUser';

// since getUserAccess returns a new object everytime it's called
// this selector is to memoize that call until getUser changes,
// so it prevents selectors that depend on getUserAccess data to re-run
const geAccessRights = createSelector([getUser], (user) => {
  return getUserAccess(user);
});

export const selectUserAccessRights = createSelector(
  [getUser, geAccessRights],
  (user, accessRights) => {
    const hasTimeTracking =
      user.time_tracking > 0 && Boolean(Number(user.people_id));
    const isManager = user.account_tid !== AccountType.Member;

    const canOnlyViewThemself = userCanOnlyViewThemself(user);
    const canUpdateThemself = userCanUpdateThemself(user);

    return {
      accessRights,

      hasTimeTracking,
      isManager,

      canOnlyViewThemself,
      canUpdateThemself,
    };
  },
);

export const selectUserHasManagerAccessRights = createSelector(
  [getUser],
  (user) => user.account_tid !== AccountType.Member,
);
