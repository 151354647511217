import styled, { css } from 'styled-components';

import { FIN } from '@float/theme';

import { AccordionTableCellValue } from './types';

export const Container = styled.div`
  margin-bottom: 30px;
  &,
  * {
    box-sizing: border-box;
  }
`;

export const HeaderWrapper = styled.div<{ numRows?: number }>`
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  color: ${(p) => p.theme.blueGrey};
  height: 36px;
  line-height: 36px;
  border-bottom: ${({ numRows = 0 }) => (numRows > 0 ? 1 : 0)}px solid
    ${(p) => p.theme.black12};
  background: ${(p) => p.theme.superLightGrey};
`;

export const Cell = styled.div<{
  align?: string;
  allowOverflow?: boolean;
  isPercent?: boolean;
  isOpen?: boolean;
  value?: AccordionTableCellValue;
  width?: number;
}>`
  ${(p) =>
    p.width &&
    css`
      width: ${p.width}px;
      min-width: ${p.width}px;
    `}

  transition: color 0.1s linear;
  ${(p) => {
    const color =
      p.isPercent && typeof p.value === 'number' && p.value > 100
        ? p.theme.red
        : p.isPercent
          ? p.theme.blueGrey
          : p.theme.charcoalGrey;

    return `
      color: ${color};
      svg {
        --svg-icon-color: ${color};
      }
    `;
  }};

  &:first-child {
    padding-left: 12px;
  }

  &:last-child {
    padding-right: 20px;
  }

  & > div {
    white-space: nowrap;
    ${(p) =>
      !p.allowOverflow &&
      `
      display: block;

      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
      font-size: 13px;
    `}
  }

  display: flex;
  justify-content: ${(p) => p.align || 'flex-end'};

  svg.icon-chevron-down-sm {
    min-width: 24px;
    transition: transform 0.1s linear;
    transform: rotate(${(p) => (p.isOpen ? '0' : '-90deg')});
    align-self: center;

    & + div {
      padding-left: 10px;
    }
  }

  a {
    ${(p) => {
      const color = p.theme.charcoalGrey;

      return `
        color: ${color};
        font-size: 13px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        svg {
          --svg-icon-color: ${color};
        }
      `;
    }};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const HeaderCell = styled(Cell)<{
  grow?: number;
  activeSort?: boolean;
}>`
  color: ${(p) =>
    p.activeSort ? p.theme.charcoalGrey : FIN.Color.Text.Subdued};
  align-items: center;
  padding-left: 0px;
  outline: none;
  ${(p) => p.grow && 'flex-grow: 1;'}

  div.label, svg {
    cursor: pointer;
    font-size: 13px;
  }
`;

export const Summary = styled.div<{ numChildren: number }>`
  width: 100%;
  cursor: ${(p) => (p.numChildren > 0 ? 'pointer' : 'default')};
  display: flex;
`;

export const RowDetails = styled.div<{ isOpen: boolean; numChildren: number }>`
  font-size: 13px;
  background: #fff;
  line-height: 36px;
  display: flex;
  flex-wrap: wrap;
  height: 36px;

  border: 1px solid ${(p) => p.theme.grey};
  border-top: 0;
  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top: 1px solid ${(p) => p.theme.grey};
  }

  &:last-child,
  &.last {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  & & {
    border: 0;
    border-top: 1px solid ${(p) => p.theme.grey};
  }

  ${(p) =>
    p.isOpen &&
    css`
      height: ${(p.numChildren + 1) * 37}px;
    `}

  overflow: hidden;
  transition: height 0.1s linear;

  &:focus {
    outline: none;
  }
`;

export const SubRowWrapper = styled.div`
  width: 100%;
  display: flex;
  line-height: 36px;
  font-size: 13px;
  color: ${(p) => p.theme.charcoalGrey};
  border-top: 1px solid ${(p) => p.theme.lightGrey};

  ${Cell} {
    color: #575f65;

    &:first-child {
      padding-left: 58px;
    }
  }
`;

export const FooterRow = styled.div`
  display: flex;
  font-size: 15px;
  line-height: 36px;
  width: 100%;
  ${HeaderCell} {
    color: ${(p) => p.theme.charcoalGrey};
  }

  ${Cell} {
    font-weight: 600;
    box-sizing: border-box;
    &:first-child {
      padding-left: 0;
    }
  }
`;

export const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

export const NoResults = styled.div`
  height: 200px;
  background: ${(p) => p.theme.superLightGrey};
  font-size: 14px;
  color: ${(p) => p.theme.blueGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
