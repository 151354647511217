import { MePageData, PageData, SharePageData } from '@float/types/pageData';

import { Resources } from '../constants';
import { makeRequest, MakeRequestPagination } from '../makeRequest';

export const requestPageData = (
  gclid: string = '',
  utmParams: Map<string, string | null> | null,
): Promise<PageData> => {
  const query: Record<string, string> = {};
  if (gclid) {
    query.gclid = gclid;
  }
  if (utmParams) {
    utmParams.forEach((value, key) => {
      if (value) {
        query[key] = value;
      }
    });
  }

  return makeRequest<PageData>({
    resource: Resources.PageData,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
    query: query,
  });
};

export const requestMePageData = (): Promise<MePageData> =>
  makeRequest<MePageData>({
    resource: Resources.MePageData,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
  });

export const requestSharePageData = (token: string): Promise<SharePageData> => {
  return makeRequest<SharePageData, { token: string }>({
    resource: Resources.SharePageData,
    method: 'POST',
    pagination: MakeRequestPagination.Off,
    data: {
      token,
    },
  });
};
