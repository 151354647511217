import React from 'react';
import { t, Trans } from '@lingui/macro';
import cs from 'classnames';

import { formatAmount } from '@float/common/lib/budget/helpers/formatAmount';
import { formatPercentage } from '@float/common/lib/budget/helpers/getPercentage';
import { config } from '@float/libs/config';
import { BudgetType } from '@float/types/project';
import type { BudgetsState } from '@float/common/store/budgets/budgets.reducer';
import type { CurrencyProps } from '@float/types/companyPreferences';

import type { AllocationCost } from '../../EditTaskModal.types';

import * as styles from './BudgetLabel.css';

type EntityBudgetLabel = {
  budget:
    | BudgetsState['tasks'][keyof BudgetsState['tasks']]
    | BudgetsState['phases'][keyof BudgetsState['phases']]
    | BudgetsState['projects'][keyof BudgetsState['projects']]
    | null;
  budgetType?: BudgetType;
  currencyProps: CurrencyProps;
  displayExplicitLabel?: boolean;
  allocationCost: AllocationCost;
  allocationCostInitial: AllocationCost;
};

export const BudgetLabel = (props: EntityBudgetLabel) => {
  const {
    budget,
    budgetType,
    allocationCost,
    allocationCostInitial,
    currencyProps,
    displayExplicitLabel = false,
  } = props;

  // We dont display budget label in disabled case
  if (budgetType === BudgetType.Disabled) return null;

  // We want to display "Hourly fee" in case of project and phase
  if (budgetType === BudgetType.HourlyFee) {
    return (
      <label className={cs(styles.budgetLabel)}>
        <Trans>Hourly fee</Trans>
      </label>
    );
  }

  // Guard to ensure that the logic down below is
  // only applicable to TotalFee and TotalHours
  if (
    budgetType !== BudgetType.TotalFee &&
    budgetType !== BudgetType.TotalHours
  )
    return null;

  // The budget is not accessible (we can't show anything at this stage yet)
  if (!budget) return null;

  const isProjectBudget = 'project_id' in budget;
  const isPhaseBudget = 'phase_id' in budget;
  const isTaskBudget = 'task_meta_id' in budget;

  if (
    typeof budget.budget_remaining !== 'number' ||
    typeof budget.budget_total !== 'number' ||
    typeof budgetType === 'undefined'
  )
    return null;

  // If the allocation entity was changed
  const hasAllocationEntityChanged =
    (isProjectBudget &&
      allocationCostInitial.allocatedToProjectId !==
        allocationCost.allocatedToProjectId) ||
    (isPhaseBudget &&
      allocationCostInitial.allocatedToPhaseId !==
        allocationCost.allocatedToPhaseId) ||
    (isTaskBudget &&
      allocationCostInitial.allocatedToTaskMetaId !==
        allocationCost.allocatedToTaskMetaId);

  const budgetTotal = budget.budget_total;
  // Already allocated tasks are already included into the budget usage and remaining
  // So we need subtract it if the allocation was done initially on the same entity
  const budgetRemainingAfterAllocationUpdate = hasAllocationEntityChanged
    ? // There was no initial allocation to this entity, subtract current cost from the remaining budget
      budget.budget_remaining - allocationCost.costTotal
    : // There was an initial allocation to this entity, subtract current cost except the initial cost
      budget.budget_remaining -
      allocationCost.costTotal +
      allocationCostInitial.costTotal;

  const budgetRemainingAfterAllocationUpdateAbsolute = Math.abs(
    budgetRemainingAfterAllocationUpdate,
  );
  const budgetRemainingAfterAllocationUpdatePercent =
    budgetRemainingAfterAllocationUpdateAbsolute / budgetTotal;

  const budgetRemainingAfterAllocationUpdateString =
    budgetTotal > 0
      ? `(${formatPercentage(budgetRemainingAfterAllocationUpdatePercent)})`
      : '';

  const budgetRemainingAfterAllocationUpdateFormatted = formatAmount(
    budgetType,
    budgetRemainingAfterAllocationUpdateAbsolute,
    {
      inLocale: false,
      locale: config.locale,
      currency: currencyProps.currency,
    },
  );

  let budgetLabel = t`Budget`;
  let budgetLabelExplicit = t`Budget`;

  if (isPhaseBudget) {
    budgetLabel = t`Phase Budget`;
    budgetLabelExplicit = t`Phase Budget`;
  } else if (isTaskBudget) {
    budgetLabel = t`Budget`;
    budgetLabelExplicit = t`Task Budget`;
  } else if (isProjectBudget) {
    budgetLabel = t`Budget`;
    budgetLabelExplicit = t`Project Budget`;
  }

  if (displayExplicitLabel) {
    budgetLabel = budgetLabelExplicit;
  }

  return (
    <label
      className={cs(
        styles.budgetLabel,
        budgetRemainingAfterAllocationUpdate < 0 && styles.budgetLabelCritical,
      )}
    >
      {budgetRemainingAfterAllocationUpdate >= 0 ? (
        <Trans>
          {budgetLabel}: {budgetRemainingAfterAllocationUpdateFormatted}{' '}
          {budgetRemainingAfterAllocationUpdateString} remaining
        </Trans>
      ) : (
        <Trans>
          {budgetLabel}: {budgetRemainingAfterAllocationUpdateFormatted}{' '}
          {budgetRemainingAfterAllocationUpdateString} over
        </Trans>
      )}
    </label>
  );
};
