import { t } from '@lingui/macro';
import {
  WebAppDispatch,
  WebAppDispatchStrict,
  WebAppStore,
  WebAppStoreStrict,
} from 'lib/store';
import manageModal from 'modalManager/manageModalActionCreator';

import { TEMPLATE_LIMITS } from '@float/common/actions';
import { Rights } from '@float/common/lib/acl';
import { getUser } from '@float/common/selectors/currentUser';
import { getProjectById, getTemplates } from '@float/common/selectors/projects';
import { AppDispatch, AppStoreStrict } from '@float/common/store';

import {
  PhaseEditData,
  ProjectEditData,
  TemplateData,
} from '../components/panelViews/Project/types';
import { getPhaseById } from '../selectors';
import { getSidePanelState } from './selectors';
import { PanelType, ShowSidePanelPayload } from './types';

export const HIDE_SIDE_PANEL = 'HIDE_SIDE_PANEL' as const;
export const SHOW_SIDE_PANEL = 'SHOW_SIDE_PANEL' as const;
export const ADD_PANEL = 'ADD_PANEL' as const;
export const HIDE_CURRENT_PANEL = 'HIDE_CURRENT_PANEL' as const;
export const HIDE_CURRENT_PANEL_WITH_WARNING =
  'HIDE_CURRENT_PANEL_WITH_WARNING' as const;
export const REMOVE_PANEL_TENTATIVE_OVERRIDE =
  'REMOVE_PANEL_TENTATIVE_OVERRIDE' as const;
export const COMMIT_PANEL_TENTATIVE_OVERRIDE =
  'COMMIT_PANEL_TENTATIVE_OVERRIDE' as const;
export const UPDATE_PREVENT_PANEL_OVERRIDE =
  'UPDATE_PREVENT_PANEL_OVERRIDE' as const;

export const hideSidePanel = (payload = { force: true }) => ({
  type: HIDE_SIDE_PANEL,
  payload,
});

export const removePanelTentativeOverride = () => ({
  type: REMOVE_PANEL_TENTATIVE_OVERRIDE,
});
export const commitPanelTentativeOverride = () => ({
  type: COMMIT_PANEL_TENTATIVE_OVERRIDE,
});
export const updatePreventPanelOverride = (
  active: boolean,
  panelType: PanelType,
) => ({
  type: UPDATE_PREVENT_PANEL_OVERRIDE,
  payload: {
    active,
    panelType,
  },
});

export const hideCurrentPanel = () => ({
  type: HIDE_CURRENT_PANEL,
});

export const hideCurrentPanelWithWarning = () => ({
  type: HIDE_CURRENT_PANEL_WITH_WARNING,
});

export const showSidePanel = (payload: ShowSidePanelPayload) => ({
  type: SHOW_SIDE_PANEL,
  payload,
});

export const addPanel = (payload: ShowSidePanelPayload) => ({
  type: ADD_PANEL,
  payload,
});

export const hideSidePanelIfNotPrevented =
  () => (dispatch: WebAppDispatch, getState: WebAppStore['getState']) => {
    dispatch(hideSidePanel({ force: false }));

    const { sidePanelOpen } = getSidePanelState(getState());

    return !sidePanelOpen;
  };

export const showProjectSidePanel = (projectEditData?: ProjectEditData) => {
  return (dispatch: AppDispatch, getState: AppStoreStrict['getState']) => {
    if (projectEditData?.projectId) {
      const user = getUser(getState());
      const project = getProjectById(getState(), projectEditData.projectId);

      if (project) {
        const canEditProject = Rights.canUpdateProject(user, { project });

        if (!canEditProject) {
          return dispatch(
            manageModal({
              visible: true,
              modalType: 'projectModal',
              modalSettings: {
                project,
                isEditable: false,
              },
            }),
          );
        }
      }
    }

    dispatch(
      showSidePanel({
        panelType: PanelType.ProjectPanel,
        panelPayload: projectEditData,
      }),
    );
  };
};

function showTemplateLimitReachedModal(limit: number) {
  return manageModal({
    visible: true,
    modalType: 'ConfirmModal',
    modalSettings: {
      title: t`Unable to create template`,
      message: t`You have reached the maximum allowed number of ${limit} templates, please remove some from your team before proceeding to create.`,
      hideCancel: true,
      confirmLabel: t`Got it`,
    },
    skipSidePanelAutoClose: true,
  });
}

export const showTemplateSidePanel = (
  data?: TemplateData,
  limit = TEMPLATE_LIMITS.total,
) => {
  return (
    dispatch: WebAppDispatchStrict,
    getState: WebAppStoreStrict['getState'],
  ) => {
    const isCreateMode = !data?.templateId;
    const isAtLimit = isCreateMode && getTemplates(getState()).length >= limit;

    if (isAtLimit) {
      dispatch(showTemplateLimitReachedModal(limit));
      return;
    }

    dispatch(
      showSidePanel({
        panelType: PanelType.TemplatePanel,
        panelPayload: data,
      }),
    );
  };
};

export const closeCurrentPanel = () => {
  return (dispatch: WebAppDispatch, getState: WebAppStore['getState']) => {
    if (getState().sidePanel.openPanels.length) {
      dispatch(hideCurrentPanel());
    }
  };
};

export const showPhaseSidePanel = (data?: PhaseEditData) => {
  return (dispatch: AppDispatch, getState: WebAppStore['getState']) => {
    const user = getUser(getState());

    const { projectId, phaseId } = data ?? {};

    const project = getProjectById(getState(), projectId);
    const phase = getPhaseById(getState(), phaseId);

    let canEdit = false;

    if (project && !phase) {
      canEdit = Rights.canUpdateProject(user, {
        project,
      });
    } else if (phase) {
      canEdit = Rights.canUpdatePhase(user, {
        project,
        phase,
      });
    }

    if (!canEdit) {
      return phase
        ? dispatch(
            manageModal({
              visible: true,
              modalType: 'phaseModal',
              modalSettings: {
                phase,
                projectId: phase.project_id,
                editing: true,
              },
            }),
          )
        : null;
    }

    const openPanels = getState().sidePanel.openPanels;
    // If the underlying project panel is open, then we just add the phase panel over the top. If the project panel is not open or if the panel of
    // a different project is open then we remove the stack and show the phase panel.
    if (
      openPanels.length >= 1 &&
      openPanels[0].panelType === PanelType.ProjectPanel &&
      openPanels[0].panelPayload?.projectId === data?.projectId
    ) {
      dispatch(
        addPanel({
          panelType: PanelType.PhasePanel,
          panelPayload: data,
        }),
      );
    } else {
      dispatch(
        showSidePanel({
          panelType: PanelType.PhasePanel,
          panelPayload: data,
        }),
      );
    }
  };
};

export const backToProjectPanel = (projectId: number) => {
  return (dispatch: WebAppDispatch, getState: WebAppStore['getState']) => {
    const openPanels = getState().sidePanel.openPanels;
    // Check to see whether the underlying project panel already open. If so just close this panel, otherwise close the panel and open the project panel
    if (
      openPanels.length >= 2 &&
      openPanels[0].panelType === PanelType.ProjectPanel &&
      openPanels[0].panelPayload?.projectId === projectId
    ) {
      dispatch(hideCurrentPanelWithWarning());
    } else {
      dispatch(
        showProjectSidePanel({
          projectId,
          hideDelete: false,
          hideTasksTab: false,
        }),
      );
    }
  };
};
