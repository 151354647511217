import { CurrentUser, FilterToken } from '@float/types';

import { isOr, normalize, PERSON_RELATED_TYPES } from '../../helpers';
import {
  FilterMatcher,
  FiltersContext,
  FiltersContextMap,
  FiltersDataType,
  GroupedFilterToken,
  VirtualFilterTypes,
} from '../../types';
import { ContainsMatcher, FILTER_MATCHERS } from '../filters';

const getMeFilterValues = (user: CurrentUser) => {
  return user.people_id ? [user.people_id.toString()] : [];
};

const getFilterValues = (filter: FilterToken, user: CurrentUser) => {
  const tokens = filter.val;

  if (filter.type === 'me') {
    return getMeFilterValues(user);
  }

  return Array.isArray(tokens)
    ? tokens.map(normalize)
    : [normalize(tokens as string)];
};

function getFilterMatcher<T extends FiltersDataType>(
  dataType: T,
  context: FiltersContextMap[T],
  filterType: VirtualFilterTypes,
  val: string[],
) {
  const FilterMatcherClass = FILTER_MATCHERS[dataType];

  if (filterType === 'contains') {
    return new ContainsMatcher(
      FilterMatcherClass as typeof FilterMatcher<T>,
      context,
      val,
    );
  }

  return new FilterMatcherClass(context, filterType, val);
}

// Converts a linear filters expression e.g. A = 1 AND B = 2 OR C = 3 AND D = 4
// into one grouped by precedence. E.g. ((A = 1 AND B = 2) OR (C = 3 AND D = 4))
// The result is represented by a 2d array. E.g. [ [{ A: 1}, {B: 2 }], [{ C: 3 }, { D: 4 }] ]
export function getAppliedFiltersGroups<T extends FiltersDataType>(
  dataType: T,
  searchFilters: FilterToken[],
  context: FiltersContext<T>,
): GroupedFilterToken[][] {
  const { me, user } = context;

  let appliedFilters = searchFilters;

  // If Me filter is on, do not filter by people related criteria
  if (me) {
    appliedFilters = searchFilters.filter(
      (x) => !PERSON_RELATED_TYPES.includes(x.type),
    );
  }

  const groupedFilters: GroupedFilterToken[][] = [[]];
  let currentGroup: GroupedFilterToken[] = groupedFilters[0];

  for (const filter of appliedFilters) {
    if (currentGroup.length && isOr(filter.operator)) {
      currentGroup = [];
      groupedFilters.push(currentGroup);
    }

    const values = getFilterValues(filter, user);
    const matcher = getFilterMatcher(dataType, context, filter.type, values);

    currentGroup.push({
      ...filter,
      values,
      matcher,
    });
  }

  if (me) {
    const values = getMeFilterValues(user);

    const meFilter: GroupedFilterToken = {
      type: 'me',
      values,
      matcher: getFilterMatcher(dataType, context, 'me', values),
    };

    groupedFilters.forEach((group) => {
      if (!group.some((filter) => filter.type === 'me')) {
        group.push(meFilter);
      }
    });
  }

  return groupedFilters;
}
