import React, { useId } from 'react';
import { FieldPath, useController } from 'react-hook-form';
import { plural, t } from '@lingui/macro';

import { NumericQuickInputField } from '@float/ui/components/NumericQuickInputField';

import { ProjectFormData } from '../../types';
import { FormField } from '../FormFieldsSection';

type Unit = 'hrs' | 'days';

const unitSingular = {
  hrs: 'hr',
  days: 'day',
};

type DurationInputFieldProps = {
  fieldClassName?: string;
  className?: string;
  name: FieldPath<ProjectFormData>;
  label: string;
  placeholder?: string;
  hideLabel?: boolean;
  readOnly?: boolean;
  valueOverride?: string;
  compact?: boolean;
  unit?: Unit;
  blockOutsideInteraction?: boolean;
};

type GetSuffixProps = {
  value: number;
  placeholder: string;
  unit: Unit;
};

function getSuffix({ value, placeholder, unit }: GetSuffixProps) {
  if (typeof value === 'number') {
    return plural(value, { one: unitSingular[unit], other: unit });
  }

  if (!value && placeholder === t`1`) {
    return unitSingular[unit];
  }

  return unit;
}

export function DurationInputField(props: DurationInputFieldProps) {
  const id = useId();
  const { unit = 'hrs', placeholder = t`0` } = props;
  const { field } = useController({ name: props.name });

  return (
    <FormField
      label={props.label}
      htmlFor={id}
      readOnly={props.readOnly}
      hideLabel={props.hideLabel}
      className={props.fieldClassName}
      stopClickPropagation
    >
      <NumericQuickInputField
        className={props.className}
        id={id}
        name={props.name}
        placeholder={placeholder}
        suffix={getSuffix({ value: field.value, placeholder, unit })}
        readOnly={props.readOnly}
        valueOverride={props.valueOverride}
        compact={props.compact}
        blockOutsideInteraction={props.blockOutsideInteraction}
      />
    </FormField>
  );
}
