import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { REHYDRATE } from 'redux-persist';

import { RehydratePartialStateAction } from '@float/common/reducers/lib/types';

import {
  FETCH_SCHEDULE_RANGE,
  FETCH_SCHEDULE_RANGE_FAILURE,
  FETCH_SCHEDULE_RANGE_SUCCESS,
  RESET_SCHEDULE_RANGE,
  SET_SCHEDULE_VISIBLE_DATA_WINDOW,
} from './schedule.actions';
import { ScheduleActions, ScheduleState } from './schedule.types';

const DEFAULT_STATE: ScheduleState = {
  fetchedRanges: [],
  fetchRequestedRanges: [],
  startDate: null,
  endDate: null,
  visibleDataWindow: null,
};

// TODO: [APA 2020-05-27]: I don't think this is correct - it should find the
// min and max fetchedRanges and convert those to dates to get the outer bounds.
function getOverallStartEndDates(
  state: ScheduleState,
  action: { startDate: string; endDate: string },
) {
  let { startDate, endDate } = state;
  const { startDate: rangeStart, endDate: rangeEnd } = action;

  if (rangeStart && rangeEnd) {
    if (!startDate || isBefore(new Date(rangeStart), new Date(startDate))) {
      startDate = rangeStart;
    }

    if (!endDate || isAfter(new Date(rangeEnd), new Date(endDate))) {
      endDate = rangeEnd;
    }
  }

  return { startDate, endDate };
}

export function scheduleReducer(
  state = DEFAULT_STATE,
  action:
    | ScheduleActions
    | RehydratePartialStateAction<ScheduleState, 'schedule'>,
): ScheduleState {
  switch (action.type) {
    case FETCH_SCHEDULE_RANGE: {
      return {
        ...state,
        fetchRequestedRanges: [...state.fetchRequestedRanges, ...action.ranges],
      };
    }

    case FETCH_SCHEDULE_RANGE_SUCCESS: {
      const { startDate, endDate } = getOverallStartEndDates(state, action);
      return {
        ...state,
        fetchedRanges: [...state.fetchedRanges, ...action.ranges],
        startDate,
        endDate,
      };
    }

    case FETCH_SCHEDULE_RANGE_FAILURE: {
      const excludeRange = (r: number) => !action.ranges.includes(r);

      return {
        ...state,
        fetchedRanges: state.fetchedRanges.filter(excludeRange),
        fetchRequestedRanges: state.fetchRequestedRanges.filter(excludeRange),
      };
    }

    case RESET_SCHEDULE_RANGE: {
      const { startDate, endDate } = getOverallStartEndDates(state, action);
      return {
        ...state,
        fetchedRanges: [],
        fetchRequestedRanges: [],
        startDate,
        endDate,
      };
    }

    case SET_SCHEDULE_VISIBLE_DATA_WINDOW: {
      return {
        ...state,
        visibleDataWindow: action.payload,
      };
    }

    case REHYDRATE: {
      // This reducer is deliberately not persisted, eliminating the need to manage rehydration.
      return state;
    }

    default: {
      return state;
    }
  }
}
