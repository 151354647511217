import React, { Fragment } from 'react';

import { useAppSelector } from '@float/common/store';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { getOnboardingSurveyConfig } from '@float/web/store/onboardingManager/selectors/survey';
import { OnboardingSurveyStatus } from '@float/web/store/onboardingManager/types';

import { NavigationStep } from '../NavigationStep';
import { StyledNavigationSeparator } from './LegacySteps.styles';

export const LegacySteps = ({
  navigation,
}: Omit<OnboardingSurveyStatus, 'completed'>) => {
  const onboardingSurveyConfig = useAppSelector(getOnboardingSurveyConfig);

  const onboardingSteps = Object.entries(onboardingSurveyConfig)
    .filter(([_, config]) => !config?.excludeFromNavigation)
    .map(([step]) => step);

  return onboardingSteps.map((step, index) => (
    <Fragment key={step}>
      {index > 0 && (
        <StyledNavigationSeparator>
          <Icons.IconChevronRightLarge />
        </StyledNavigationSeparator>
      )}

      <NavigationStep
        index={index + 1}
        step={step}
        active={index === navigation.page}
        completed={index < navigation.page}
      />
    </Fragment>
  ));
};

LegacySteps.displayName = 'LegacySteps';
