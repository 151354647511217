import { isCostRateFeatureActive as getIsCostRateFeatureActive } from '@float/common/lib/rates/rates';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

import * as phasesTable from './table/phases';
import { getPhasesCombinedTable } from './table/phases/getPhasesCombinedTable';
import { getPhasesCompareTable } from './table/phases/getPhasesCompareTable';
import { getPhasesLoggedTable } from './table/phases/getPhasesLoggedTable';
import { getPhasesScheduledTable } from './table/phases/getPhasesScheduledTable';
import * as tasksTableLegacy from './table/tasks';
import * as tasksTable from './table/tasks/tasks';
import * as teamTableLegacy from './table/team';
import * as teamTable from './table/team/team';
import getTimetrackingTable from './table/timetracking';
import type { RawTableData } from '../../useReportsStateReducer';
import type { ParseTableContext } from './table.types';

function getTasksTable(ctx: ParseTableContext, raw: RawTableData) {
  const { mode } = ctx;

  const isCostRateFeatureActive = getIsCostRateFeatureActive();

  if (isCostRateFeatureActive) {
    if (mode === ChartComparisonModes.SCHEDULED)
      return tasksTable.getScheduledTable(ctx, raw);
    if (mode === ChartComparisonModes.LOGGED)
      return tasksTable.getLoggedTable(ctx, raw);
    if (mode === ChartComparisonModes.COMPARE)
      return tasksTable.getCompareTable(ctx, raw);
    if (mode === ChartComparisonModes.COMBINED)
      return tasksTable.getCombinedTable(ctx, raw);
  }

  if (mode === ChartComparisonModes.SCHEDULED)
    return tasksTableLegacy.getScheduledTable(ctx, raw);
  if (mode === ChartComparisonModes.LOGGED)
    return tasksTableLegacy.getLoggedTable(ctx, raw);
  if (mode === ChartComparisonModes.COMPARE)
    return tasksTableLegacy.getCompareTable(ctx, raw);
  if (mode === ChartComparisonModes.COMBINED)
    return tasksTableLegacy.getCombinedTable(ctx, raw);
}

function getTeamTable(ctx: ParseTableContext, raw: RawTableData) {
  const { mode } = ctx;

  const isCostRateFeatureActive = getIsCostRateFeatureActive();

  if (isCostRateFeatureActive) {
    if (mode === ChartComparisonModes.SCHEDULED)
      return teamTable.getScheduledTable(ctx, raw);
    if (mode === ChartComparisonModes.LOGGED)
      return teamTable.getLoggedTable(ctx, raw);
    if (mode === ChartComparisonModes.COMPARE)
      return teamTable.getCompareTable(ctx, raw);
    if (mode === ChartComparisonModes.COMBINED)
      return teamTable.getCombinedTable(ctx, raw);
  }

  if (mode === ChartComparisonModes.SCHEDULED)
    return teamTableLegacy.getScheduledTable(ctx, raw);
  if (mode === ChartComparisonModes.LOGGED)
    return teamTableLegacy.getLoggedTable(ctx, raw);
  if (mode === ChartComparisonModes.COMPARE)
    return teamTableLegacy.getCompareTable(ctx, raw);
  if (mode === ChartComparisonModes.COMBINED)
    return teamTableLegacy.getCombinedTable(ctx, raw);
}

function getPhasesTable(ctx: ParseTableContext, raw: RawTableData) {
  const { mode } = ctx;

  const isCostRateFeatureActive = getIsCostRateFeatureActive();

  if (isCostRateFeatureActive) {
    if (mode === ChartComparisonModes.SCHEDULED)
      return getPhasesScheduledTable(ctx, raw);
    if (mode === ChartComparisonModes.LOGGED)
      return getPhasesLoggedTable(ctx, raw);
    if (mode === ChartComparisonModes.COMPARE)
      return getPhasesCompareTable(ctx, raw);
    if (mode === ChartComparisonModes.COMBINED)
      return getPhasesCombinedTable(ctx, raw);
  }

  if (mode === ChartComparisonModes.SCHEDULED)
    return phasesTable.getScheduledTable(ctx, raw);
  if (mode === ChartComparisonModes.LOGGED)
    return phasesTable.getLoggedTable(ctx, raw);
  if (mode === ChartComparisonModes.COMPARE)
    return phasesTable.getCompareTable(ctx, raw);
  if (mode === ChartComparisonModes.COMBINED)
    return phasesTable.getCombinedTable(ctx, raw);
}

export default function parseTableData(
  ctx: ParseTableContext,
  raw: RawTableData,
) {
  return {
    taskBillable: getTasksTable({ ...ctx, billable: true }, raw),
    taskNonBillable: getTasksTable({ ...ctx, billable: false }, raw),
    team: getTeamTable(ctx, raw),
    timetracking: getTimetrackingTable(ctx, raw),
    phaseBillable: getPhasesTable({ ...ctx, billable: true }, raw),
    phaseNonBillable: getPhasesTable({ ...ctx, billable: false }, raw),
  };
}
