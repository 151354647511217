import { useEffect, useRef } from 'react';
import cn from 'classnames';

import { useColorThemeContext } from '../ColorThemeContext';

export const ColorThemeInjector = () => {
  const { activeColorThemeClass } = useColorThemeContext();

  const stashedColorThemeClass = useRef<string>();

  useEffect(() => {
    const htmlElement = document.getElementsByTagName('html')[0];

    if (stashedColorThemeClass.current) {
      htmlElement.className = htmlElement.className.replace(
        stashedColorThemeClass.current,
        activeColorThemeClass,
      );
    } else {
      htmlElement.className = cn(htmlElement.className, activeColorThemeClass);
    }

    stashedColorThemeClass.current = activeColorThemeClass;
  }, [activeColorThemeClass]);

  return null;
};
