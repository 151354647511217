import React, { useId, useRef } from 'react';
import { Trans } from '@lingui/macro';
import cn from 'classnames';

import { noop } from '@float/libs/utils/noop';
import { useButtonAutoFocus } from '@float/ui/components/Button/hooks/useButtonAutoFocus';
import { IconCheck } from '@float/ui/icons/essentials/IconCheck';
import { IconTrash } from '@float/ui/icons/essentials/IconTrash';

import { ConfirmCheckboxAppearance, ConfirmCheckboxProps } from '../../types';

import * as styles from './styles.css';

export const ConfirmCheckbox = (props: ConfirmCheckboxProps) => {
  const {
    appearance = ConfirmCheckboxAppearance.Danger,
    checked = false,
    className,
    icon: ConfirmIcon = IconTrash,
    onClick = noop,
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  // because this is used within a modal, using this hook to make that this
  // component receives the focus outline correctly when modal opens
  useButtonAutoFocus(buttonRef);

  const checkboxId = useId();
  const labelId = useId();

  return (
    <div className={cn(className, styles.wrapper)}>
      <button
        aria-checked={checked}
        aria-labelledby={labelId}
        className={styles.checkbox}
        id={checkboxId}
        onClick={onClick}
        ref={buttonRef}
        role="checkbox"
      >
        <i className={styles.icon({ appearance })}>
          <ConfirmIcon />
          <IconCheck
            className={cn('icon-check', styles.iconCheck({ appearance }))}
            size={12}
          />
        </i>
      </button>
      <label id={labelId} className={styles.label} htmlFor={checkboxId}>
        <Trans>Click to confirm you want to proceed</Trans>
      </label>
    </div>
  );
};
