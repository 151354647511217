import { useCallback, useEffect, useState } from 'react';

import { CheckboxCheckedState } from '@float/ui/components/Checkbox/types';

export function useMultiSelectRows<T>(rowsCount: number) {
  const [selectedRows, setSelectedRows] = useState<Set<T>>(new Set<T>());
  const [isAllSelected, setAllSelected] = useState<CheckboxCheckedState>(false);

  const toggleSelectRow = useCallback((id: T) => {
    setSelectedRows((prevSelectedIds) => {
      const newSelectedIds = new Set(prevSelectedIds);
      if (newSelectedIds.has(id)) {
        newSelectedIds.delete(id);
      } else {
        newSelectedIds.add(id);
      }
      return newSelectedIds;
    });
  }, []);

  const toggleSelectAll = useCallback((allIds: T[]) => {
    setSelectedRows((prevSelectedIds) => {
      if (prevSelectedIds.size > 0) return new Set<T>();

      return new Set<T>(allIds);
    });
  }, []);

  useEffect(() => {
    let newAllChecked: CheckboxCheckedState = false;

    if (selectedRows.size === rowsCount) {
      newAllChecked = true;
    } else if (selectedRows.size > 0) {
      newAllChecked = 'indeterminate';
    }

    setAllSelected(newAllChecked);
  }, [selectedRows, rowsCount]);

  return {
    selectedRows,
    isAllSelected,
    toggleSelectRow,
    toggleSelectAll,
  };
}
