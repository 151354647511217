import { TASK_STATUS_ORDER } from '@float/common/reducers/search/getDerivedContext';
import type { SearchAutocompleteCategory } from '@float/types';

export const getSortedData = <T extends { val: string }>(
  expandedCategory: SearchAutocompleteCategory | undefined,
  data: T[],
  isSearchBeyondLimitsEnabled: boolean,
): T[] => {
  if (!isSearchBeyondLimitsEnabled) {
    return data;
  }

  if (expandedCategory === 'taskStatuses') {
    return data
      .map((item) => ({
        ...item,
        showSeparatorAfter:
          TASK_STATUS_ORDER.get(item.val)?.showSeparatorAfter ?? false,
      }))
      .sort((a, b) => {
        const orderA = TASK_STATUS_ORDER.get(a.val)?.index;
        const orderB = TASK_STATUS_ORDER.get(b.val)?.index;

        if (typeof orderA === 'undefined' || typeof orderB === 'undefined') {
          return 0;
        }

        return orderA - orderB;
      });
  }

  return data;
};
