import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { cloneDeep, isEqual, omit, sumBy } from 'lodash';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';

import * as milestoneActions from '@float/common/actions/milestones';
import * as peopleActions from '@float/common/actions/people';
import * as projectActions from '@float/common/actions/projects';
import API3 from '@float/common/api3';
import { Rights } from '@float/common/lib/acl';
import { getShouldAllowDraftStatus } from '@float/common/lib/acl/getShouldAllowDraftStatus';
import { formatAmount, toCents, toNum } from '@float/common/lib/budget';
import { isTaskMetaUserCreated } from '@float/common/lib/scheduleUtils';
import { randomColor } from '@float/common/lib/utils';
import { provideStoreHoc } from '@float/common/store';
import { DEFAULT_PALETTE } from '@float/constants/colors';
import { moment } from '@float/libs/moment';
import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { BudgetPriority, ProjectStatus } from '@float/types/project';
import { bindVal } from '@float/ui/deprecated/helpers/forms';
import { Input } from '@float/ui/deprecated/Input';
import { Modal } from '@float/ui/deprecated/Modal';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { withSnackbar } from '@float/ui/deprecated/Snackbar';
import { Tab } from '@float/ui/deprecated/Tab/Tab';
import { ensureCalendarExtLoaded } from '@float/web/integrations/actions/calendar';
import {
  getClientOptions,
  getCurrencyProps,
  getPeopleMap,
  getProjectPhases,
  getProjectsMap,
  getProjectsSortedByCreation,
  getProjectTagByLabel,
  getUser,
} from '@float/web/selectors';
import { addClient } from '@float/web/settingsV2/actions/clients';
import withConfirmDelete from 'components/decorators/withConfirmDelete';
import withListeners from 'components/decorators/withListeners';

import ProjectDetails from './ProjectDetails';
import { getVisibleTasks } from './ProjectForm/helpers/getVisibleTasks';
import InfoFragment from './ProjectForm/InfoFragment';
import MilestonesFragment from './ProjectForm/MilestonesFragment';
import TasksFragment from './ProjectForm/TasksFragment';
import TeamFragment from './ProjectForm/TeamFragment';
import { getFilteredTeam } from './ProjectModal.helpers';
import ProjectModalActions, { saveProject } from './ProjectModalActions';

const NameInputContainer = styled.div`
  flex-basis: 100%;

  input {
    width: 100%;
  }
`;

class ProjectModal extends React.Component {
  constructor(props) {
    super(props);

    const color = DEFAULT_PALETTE[0];

    this.state = {
      isLoading: true,
      hiding: false,
      activeTab: props.modalSettings.defaultTab || 'info',
      editing: this.isEditing(),
      promptModal: null,
      isUpdating: false,
      milestones: [],
      taskNames: [],
      selectedTasks: {},
      headerCounts: {},
      form: {
        project_name: '',
        newMilestones: [],
        newTaskNames: [],
        tags: [],
      },
      formErrors: {},
      milestoneAdd: {
        name: '',
        startDate: moment(),
        endDate: moment(),
      },
      taskAdd: '',
    };

    if (this.props.modalSettings.isAdding) {
      Object.assign(this.state.form, {
        project_name: '',
        common: false,
        tentative: false,
        status: ProjectStatus.Confirmed,
        non_billable: false,
        people_ids: [],
        people_rates: {},
        tags: [],
        color,
        locked_task_list: 1,
        project_manager: this.props.currentUser.account_id,
        budget_type: 0,
      });
    }

    // Although we have client_name in props for other usages, we only use the
    // id in this modal. Remove client_name to reduce confusion.
    delete this.state.form.client_name;

    this.milestoneNameRef = React.createRef();
    this.taskAddNameRef = React.createRef();
    this.projectNameRef = React.createRef();
  }

  componentDidMount() {
    const { currentUser, people } = this.props;
    const { project, isAdding, fromTemplate, fromProject } =
      this.props.modalSettings;
    const duplicating = this.isDuplicating();
    if (project && project.project_id) {
      if (this.state.isLoading) {
        this.props.actions.ensureProjectLoaded(project.project_id).then((p) => {
          const team = getFilteredTeam(p, currentUser, people);

          this.setState((ps) => {
            this.initialProject = {
              ...ps.form,
              // Deep clone because we want to alter properties directly on project
              ...cloneDeep(p),
            };

            if (duplicating) {
              this.initDuplicate(p);
            }

            return {
              isLoading: false,
              form: this.initialProject,
              headerCounts: {
                ...ps.headerCounts,
                team: team.length,
              },
            };
          });
        });
      }

      if (duplicating) {
        return;
      }

      this.fetchTaskMeta();
      this.props.actions.ensureCalendarExtLoaded();

      // TODO: Confirm server side filtering by project_id works.
      // Check if BE can filter by null phase_id, & remove filter below.
      API3.getMilestones({
        query: { project_id: project.project_id, 'per-page': 8000 },
      }).then((res) => {
        const ms = res[0].filter(
          (r) => r.project_id === project.project_id && !r.phase_id,
        );

        this.setState((ps) => {
          this.initialProject = {
            ...ps.form,
            newMilestones: cloneDeep(ms),
          };

          return {
            milestones: ms,
            form: {
              ...ps.form,
              newMilestones: cloneDeep(ms),
            },
            headerCounts: {
              ...ps.headerCounts,
              milestones: ms.length,
            },
          };
        });
      });
    }

    const isEditingTemplate = project.project_template_id && this.isTemplate();
    const isAddingProjectFromTemplate =
      project.project_template_id && isAdding && fromTemplate;

    // TODO: Unify it with the next condition
    if (project && (isEditingTemplate || isAddingProjectFromTemplate)) {
      // We need to load full template data. Currently, the data we have contains only truncated values to reduce /all response size.
      this.props.actions
        .ensureTemplateLoaded(project.project_template_id)
        .then((t) => {
          const people_ids = project.team_people?.map((item) => item.id) ?? [];
          const team = getFilteredTeam({ people_ids }, currentUser, people);
          const ms =
            t.milestones?.map((milestone) => ({ ...milestone, isNew: true })) ??
            [];
          const taskNames =
            t.task_metas?.map((taskMeta) => ({ ...taskMeta, isNew: true })) ??
            [];

          this.setState((ps) => {
            this.initialProject = {
              ...ps.form,
              // Deep clone because we want to alter properties directly on project
              newMilestones: cloneDeep(ms),
              newTaskNames: cloneDeep(taskNames),
              ...cloneDeep(project),
              description: t.description,
            };

            return {
              isLoading: false,
              form: this.initialProject,
              headerCounts: {
                ...ps.headerCounts,
                team: team.length,
              },
            };
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }

    const isAddingTemplateFromProject = isAdding && fromProject;

    if (project && isAddingTemplateFromProject) {
      const people_ids = project.team_people?.map((item) => item.id) ?? [];
      const team = getFilteredTeam({ people_ids }, currentUser, people);
      const ms =
        project.milestones?.map((milestone) => ({
          ...milestone,
          isNew: true,
        })) ?? [];
      const taskNames =
        project.task_metas?.map((taskMeta) => ({ ...taskMeta, isNew: true })) ??
        [];

      this.setState((ps) => {
        this.initialProject = {
          ...ps.form,
          // Deep clone because we want to alter properties directly on project
          newMilestones: cloneDeep(ms),
          newTaskNames: cloneDeep(taskNames),
          people_ids,
          ...cloneDeep(project),
        };

        return {
          isLoading: false,
          form: this.initialProject,
          headerCounts: {
            ...ps.headerCounts,
            team: team.length,
          },
        };
      });
    }

    if (isAdding && !fromTemplate && !fromProject) {
      this.setState((ps) => {
        this.initialProject = {
          ...ps.form,
          project_name: (project && project.project_name) || '', // passed in from outside (e.g. task modal)
          common: false,
          tentative: false,
          non_billable: false,
          people_ids: [],
          people_rates: {},
          tags: [],
          color: randomColor(),
          project_manager: this.props.currentUser.account_id,
        };
        return {
          isLoading: false,
          form: this.initialProject,
        };
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.form.newTaskNames !== this.state.form.newTaskNames ||
      prevState.form.newMilestones !== this.state.form.newMilestones ||
      prevState.form.people_ids !== this.state.form.people_ids
    ) {
      const numMilestones = this.state.form.newMilestones.length;
      const numTasks = getVisibleTasks(
        this.state.form.newTaskNames,
        this.props.currentUser,
      ).length;
      const numTeam = getFilteredTeam(
        this.state.form,
        this.props.currentUser,
        this.props.people,
      ).length;

      this.setHeaderCounts({
        tasks: numTasks,
        team: numTeam,
        milestones: numMilestones,
      });
    }

    // This happens when project modal is opened, &
    // then Duplicate is selected from Actions menu.
    const isDuplicating =
      !this.isDuplicating(prevProps) && this.isDuplicating();
    const isCreatingTemplate = !this.isTemplate(prevProps) && this.isTemplate();
    if (isDuplicating || isCreatingTemplate) {
      this.initDuplicate();
      this.setState({ form: this.initialProject, activeTab: 'info' }, () => {
        this.projectNameRef.current.focusInput();
      });
    }
  }

  // ---------------------------------------------------------------------------
  // !!! Helpers ---------------------------------------------------------------
  // ---------------------------------------------------------------------------

  initDuplicate = (p = this.props.modalSettings.project) => {
    const isTemplate = this.isTemplate();
    this.initialProject.source_project_id = this.initialProject.project_id;
    delete this.initialProject.project_id;
    this.initialProject.project_name = isTemplate
      ? p.project_name
      : `${p.project_name} (copy)`;
    this.initialProject.project_manager = this.props.currentUser.admin_id;

    if (p.start_date && p.end_date) {
      const start = moment(`${p.start_date} 00:00:00`);
      const end = moment(`${p.end_date} 00:00:00`);
      this.initialProject.durationInDays = end.diff(start, 'days');
    }
  };

  fetchTaskMeta = () => {
    const { project } = this.props.modalSettings;
    API3.getTaskMeta({ project_id: project.project_id }).then((res) => {
      const taskNames = res.filter(
        (t) => !t.phase_id && isTaskMetaUserCreated(t),
      );

      this.setState((ps) => {
        this.initialProject = {
          ...ps.form,
          newTaskNames: cloneDeep(taskNames),
        };
        return {
          allTaskNames: res,
          taskNames,
          form: {
            ...ps.form,
            newTaskNames: cloneDeep(taskNames),
          },
          headerCounts: {
            ...ps.headerCounts,
            tasks: taskNames.length,
          },
        };
      });
    });
  };

  isTaskNameInUse = (task) => {
    const { count_tasks = 0, count_logged_time = 0 } = task;
    return +count_tasks > 0 || +count_logged_time > 0;
  };

  noChangesMade = () => {
    const isReadOnly = !this.state.editing;
    return (
      isReadOnly ||
      isEqual(
        // task names persist independently of project save
        omit(this.initialProject, ['newTaskNames']),
        omit(this.state.form, ['newTaskNames']),
      )
    );
  };

  isEditing = () => {
    const {
      currentUser,
      modalSettings: { project },
    } = this.props;

    if (this.isAdding()) {
      if (this.isTemplate()) {
        return Rights.canCreateProjectTemplate(currentUser);
      }

      return Rights.canCreateProject(currentUser, { project });
    }

    if (this.isTemplate()) {
      return Rights.canUpdateProjectTemplate(currentUser, {
        template: project,
      });
    }

    return Rights.canUpdateProject(currentUser, { project });
  };

  isAdding = () => {
    return this.props.modalSettings.isAdding;
  };

  isDuplicating = (props = this.props) => {
    return props.modalSettings.duplicating;
  };

  isTemplate = (props = this.props) => {
    return props.modalSettings.isTemplate;
  };

  shouldAllowDraftOption = () => {
    return getShouldAllowDraftStatus(this.props.currentUser);
  };

  hasPhase = () => {
    if (this.isAdding()) return false;
    return this.props.projectPhases[this.state.form.project_id]?.length > 0;
  };

  hasErrors = (fields) => {
    const { formErrors } = this.state;
    return fields.some((field) => (formErrors[field] || {}).length);
  };

  resetBudgetFormErrors = (ps = this.state) => ({
    ...ps.formErrors,
    budget_total: [],
    people_rates: [],
    default_hourly_rate: [],
  });

  setHeaderCounts = (headerCounts) => {
    this.setState({ headerCounts });
  };

  setFormErrors = (formErrors, cb) => {
    this.setState(
      { formErrors: { ...this.state.formErrors, ...formErrors } },
      cb,
    );
  };

  hide = (evt) => {
    preventDefaultAndStopPropagation(evt);
    this.setState({ isUpdating: false, promptModal: null });
    this.props.manageModal({
      visible: false,
      modalType: 'projectModal',
    });
  };

  hideAfterCheckingForChanges = () => {
    if (this.noChangesMade()) {
      this.hide();
      return;
    }
    this.props.confirm({
      title: 'You have unsaved changes.',
      message: 'Are you sure you want to leave?',
      confirmLabel: 'Leave',
      cancelLabel: 'Cancel',
      onConfirm: this.hide,
    });
  };

  onEscapePressed = () => {
    if (!this.state.editing) {
      this.hide();
    }
  };

  setPromptModal = (promptModal) => {
    this.setState({ promptModal });
  };

  closePromptModal = () => {
    this.setState({ promptModal: null });
  };

  showMessage = (message) => {
    this.props.showSnackbar(message);
  };

  getPhaseBudgetSumNumeric = () => {
    let projectId = this.state.form.project_id;
    if (!projectId && this.isDuplicating()) {
      projectId = this.initialProject.source_project_id;
    }
    const phases = this.props.projectPhases[projectId] || [];
    const { budget_type: budgetType } = this.state.form;
    const isMoney = budgetType === 2;
    const budgetTotal = sumBy(phases, (p) => {
      const total = toNum(p.budget_total);
      return isMoney ? toCents(total) : total;
    });
    return isMoney ? budgetTotal / 100 : budgetTotal;
  };

  getPhaseBudgetSum = () => {
    const { budget_type: budgetType } = this.state.form;
    const budgetTotal = this.getPhaseBudgetSumNumeric();
    return formatAmount(budgetType, budgetTotal);
  };

  getReadOnlyProjectDetails = () => {
    // Existing logic
    const project =
      this.props.modalSettings.isAdding || this.isTemplate()
        ? this.props.modalSettings.project
        : this.props.projects[this.props.modalSettings.project.project_id];

    const budgetPriority = project.budget_priority;

    // If budget is set per phase, then show the total budget of all phases
    if (budgetPriority === BudgetPriority.Phase) {
      return {
        ...project,
        budget_total: this.getPhaseBudgetSumNumeric(),
      };
    }

    // TODO: If budget is set per task, then show the total budget of all tasks
    // https://linear.app/float-com/issue/COR-428/ui-read-only-project-modal-show-total-budget-by-task

    return project;
  };

  render() {
    const { editing, activeTab } = this.state;
    const duplicating = this.isDuplicating();

    let fragment;
    if (editing) {
      if (activeTab === 'info') fragment = InfoFragment.call(this);
      if (activeTab === 'team') fragment = TeamFragment.call(this);
      if (activeTab === 'milestones') fragment = MilestonesFragment.call(this);
      if (activeTab === 'tasks') fragment = TasksFragment.call(this);
    } else {
      fragment = (
        <ProjectDetails
          activeTab={activeTab}
          currentUser={this.props.currentUser}
          project={this.getReadOnlyProjectDetails()}
          people={this.props.people}
          projectTagByLabel={this.props.projectTagByLabel}
          clients={this.props.clients}
          accounts={this.props.accounts}
          milestones={this.state.milestones}
          taskNames={this.state.taskNames}
        />
      );
    }

    return createPortal(
      <Modal
        isOpen
        onClose={this.hide}
        onBgClick={this.hideAfterCheckingForChanges}
        noBgTransition={this.props.modalSettings.noBgTransition}
      >
        <form onSubmit={saveProject.bind(this)}>
          <Modal.Header className={duplicating ? undefined : 'pb-0'}>
            <NameInputContainer>
              {this.isTemplate() ? (
                <Input
                  {...bindVal(this, 'project_name')}
                  placeholder={'Project template name'}
                  ref={this.projectNameRef}
                  autoFocus
                  noBorder
                  size="xlarge"
                  maxLength={125}
                  readOnly={!editing}
                />
              ) : (
                <Input
                  {...bindVal(this, 'project_name')}
                  placeholder={'Project name'}
                  ref={this.projectNameRef}
                  autoFocus
                  noBorder
                  size="xlarge"
                  maxLength={125}
                  readOnly={!editing}
                />
              )}
            </NameInputContainer>
            {(!duplicating || this.isTemplate()) && (
              <>
                <Tab
                  onClick={() => {
                    this.setState({ activeTab: 'info' }, () => {
                      this.projectNameRef.current.focusInput();
                    });
                  }}
                  active={activeTab === 'info'}
                  label="Info"
                  hasError={this.hasErrors([
                    'budget_total',
                    'default_hourly_rate',
                  ])}
                />
                <Tab
                  counter={this.state.headerCounts.team}
                  onClick={() => this.setState({ activeTab: 'team' })}
                  active={activeTab === 'team'}
                  label="Team"
                  hasError={this.hasErrors(['people_rates'])}
                />
                {!this.isTemplate() && (
                  <Tab
                    counter={this.state.headerCounts.milestones}
                    onClick={() => this.setState({ activeTab: 'milestones' })}
                    active={activeTab === 'milestones'}
                    label="Milestones"
                  />
                )}
                {!this.props.modalSettings.hideTasksTab && (
                  <Tab
                    counter={
                      <span data-callout-id="group-by-task-new-project-task-tab">
                        {this.state.headerCounts.tasks}
                      </span>
                    }
                    onClick={() => this.setState({ activeTab: 'tasks' })}
                    active={activeTab === 'tasks'}
                    label={'Task list'}
                  />
                )}
              </>
            )}
          </Modal.Header>
          <Modal.Body>{fragment}</Modal.Body>
          {editing && (
            <Modal.Actions style={{ paddingTop: 30 }}>
              {ProjectModalActions.call(this)}
            </Modal.Actions>
          )}
        </form>
        {this.state.promptModal &&
          createPortal(this.state.promptModal, document.body)}
      </Modal>,
      document.body,
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: getUser(state),
  projectTagByLabel: getProjectTagByLabel(state),
  accounts: state.accounts.accounts,
  clients: state.clients.clients,
  people: getPeopleMap(state),
  projects: getProjectsMap(state),
  templates: state.projects.templates,
  projectPhases: getProjectPhases(state),
  projectsSortedByCreation: getProjectsSortedByCreation(state),
  clientOptions: getClientOptions(state),
  calendarExt: state.integrations.calendar.extCalendars,
  currencyConfig: getCurrencyProps(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ensureCalendarExtLoaded: () => dispatch(ensureCalendarExtLoaded()),
    ...bindActionCreators(projectActions, dispatch),
    ...bindActionCreators(peopleActions, dispatch),
    ...bindActionCreators(milestoneActions, dispatch),
    ...bindActionCreators({ addClient }, dispatch),
  },
});

const enhance = withListeners([
  {
    eventType: 'keyup',
    testFn: (e) => e.keyCode === 27,
    handler: 'onEscapePressed',
  },
]);

const Component = compose(
  withConfirm,
  withConfirmDelete,
  withSnackbar,
)(provideStoreHoc(ProjectModal));

export const ProjectContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(enhance(Component));

export default modalManagerHoc({
  Comp: ProjectContainer,
  modalType: 'projectModal',
});
