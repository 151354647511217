import { configureStore, Tuple } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { thunk } from 'redux-thunk';
import sidePanel from 'sidePanel/reducer';
import type { Middleware } from '@reduxjs/toolkit';

import history from '@float/common/lib/history';
import accounts from '@float/common/reducers/accounts';
import { appInfoReducer } from '@float/common/reducers/appInfo';
import clients from '@float/common/reducers/clients';
import companyPrefs from '@float/common/reducers/companyPrefs';
import currentUser from '@float/common/reducers/currentUser';
import departments from '@float/common/reducers/departments';
import holidays from '@float/common/reducers/holidays';
import jwt from '@float/common/reducers/jwt';
import {
  indexedDbStrategy,
  lastAllocatedTaskMiddleware,
  lastAllocatedTaskReducer,
} from '@float/common/reducers/lastAllocatedTask';
import lockLoggedTime from '@float/common/reducers/lockLoggedTime';
import loggedTimes from '@float/common/reducers/loggedTimes';
import { milestonesReducer } from '@float/common/reducers/milestones';
import oneOffs from '@float/common/reducers/oneOffs';
import people from '@float/common/reducers/people';
import phases from '@float/common/reducers/phases';
import { projectsReducer } from '@float/common/reducers/projects';
import publicHolidays from '@float/common/reducers/publicHolidays';
import { roles } from '@float/common/reducers/roles';
import { search } from '@float/common/reducers/search';
import { statusesReducer } from '@float/common/reducers/statuses';
import statusTypes from '@float/common/reducers/statusTypes';
import tags from '@float/common/reducers/tags';
import { tagsGroupsReducer } from '@float/common/reducers/tagsGroups';
import { taskMetasReducer } from '@float/common/reducers/taskMetas';
import tasks from '@float/common/reducers/tasks';
import timeoffs from '@float/common/reducers/timeoffs';
import timeoffTypes from '@float/common/reducers/timeoffTypes';
import { timer } from '@float/common/reducers/timer';
import { timeRange } from '@float/common/reducers/timeRange';
import { viewsReducer } from '@float/common/reducers/views';
import { searchService } from '@float/common/search/service';
import { dispatchDeriveSearchContextAction } from '@float/common/search/service/deriveContextMiddleware';
import { budgetsReducer } from '@float/common/store/budgets/budgets.reducer';
import {
  estimatesReducer,
  estimatesReducerPath,
} from '@float/common/store/estimates';
import { scheduleReducer } from '@float/common/store/schedule/schedule.reducer';
import { searchResults } from '@float/common/store/searchResults/searchResults.reducer';
import { notificationsReducer } from '@float/web/store/notifications/notifications.reducer';
import type { AllActions } from '@float/common/reducers';

import activityData from '../activity/reducers';
import integrations from '../integrations/reducers';
import modalManager, {
  MODAL_MANAGER_HIDE,
  MODAL_MANAGER_SHOW,
} from '../modalManager/modalManagerReducers';
import {
  ITEMS_LIVE_UPDATE,
  ITEMS_LOAD_SUCCESS,
  TASK_LINKS_LIVE_UPDATE,
} from '../pmSidebar/actions';
import pmSidebar from '../pmSidebar/reducers';
import persistPmSidebarPrefs from '../pmSidebar/reducers/middleware';
import { urlFilterSync } from '../searchV2/middleware/urlFilterSync';
import { billingInfo as settingsBillingInfo } from '../settingsV2/reducers/account/billingInfo';
import settingsInvoices from '../settingsV2/reducers/account/invoices';
import settingsJwt from '../settingsV2/reducers/account/jwt';
import settingsAccounts from '../settingsV2/reducers/accounts';
import settingsAccountTypes from '../settingsV2/reducers/accountTypes';
import settingsClients from '../settingsV2/reducers/clients';
import settingsCurrencies from '../settingsV2/reducers/currencies';
import settingsHolidays from '../settingsV2/reducers/holidays';
import settingsPeople from '../settingsV2/reducers/people';
import settingsProjects from '../settingsV2/reducers/projects';
import settingsPublicHolidays from '../settingsV2/reducers/publicHolidays';
import settingsTimeoffTypes from '../settingsV2/reducers/timeoffTypes';
import settingsTimezones from '../settingsV2/reducers/timezones';
import { ADD_PANEL, SHOW_SIDE_PANEL } from '../sidePanel/actions';
import { slackReducer as slack } from '../slack/slackRedux';
import legacyOnboarding from '../store/legacyOnboarding/reducer';
import { listenForOnboardingTriggers } from '../store/onboardingManager/middleware';
import onboardingManager from '../store/onboardingManager/reducer';
import { reducer as undoCommandStream } from '../undo';
import app from './app';
import lastUpdated from './lastUpdated';
import type { WebAppState } from './types';

const NON_CLONEABLE_ACTIONS = new Set([
  // May contain callbacks, not relevant for the search worker
  MODAL_MANAGER_SHOW,
  MODAL_MANAGER_HIDE,
  SHOW_SIDE_PANEL,
  ADD_PANEL,
  TASK_LINKS_LIVE_UPDATE,

  // Pass the full state, which may contain unserializable values
  // and can be very slow to pass to a worker
  ITEMS_LIVE_UPDATE,
  ITEMS_LOAD_SUCCESS,
]);

export const createWebAppStore = (preloadedState?: WebAppState) => {
  const store = configureStore<
    WebAppState,
    AllActions,
    Tuple<Middleware<WebAppState>[]>
  >({
    reducer: {
      accounts,
      // @ts-expect-error This reducer is still in Javascript
      activityData,
      app,
      appInfo: appInfoReducer,
      budgets: budgetsReducer,
      clients,
      companyPrefs,
      currentUser,
      departments,
      [estimatesReducerPath]: estimatesReducer,
      holidays,
      integrations,
      // @ts-expect-error This reducer is still in Javascript
      jwt,
      lastAllocatedTask: lastAllocatedTaskReducer,
      lastUpdated,
      // @ts-expect-error This reducer is still in Javascript
      lockLoggedTime,
      loggedTimes,
      milestones: milestonesReducer,
      // @ts-expect-error This reducer is still in Javascript
      modalManager,
      // @ts-expect-error This reducer is still in Javascript
      notifications: notificationsReducer,
      legacyOnboarding,
      // @ts-expect-error This reducer is still in Javascript
      onboardingManager,
      oneOffs,
      people,
      phases,
      pmSidebar,
      projects: projectsReducer,
      publicHolidays,
      roles,
      router: connectRouter(history),
      schedule: scheduleReducer,
      search,
      searchResults,
      settingsAccounts: settingsAccounts(),
      settingsAccountTypes,
      settingsBillingInfo,
      settingsClients,
      settingsCurrencies,
      settingsHolidays,
      settingsInvoices,
      settingsJwt,
      settingsPeople: settingsPeople(),
      settingsProjects: settingsProjects(),
      settingsPublicHolidays,
      settingsTimeoffTypes,
      settingsTimezones,
      sidePanel,
      slack,
      statuses: statusesReducer,
      statusTypes,
      tags,
      tagsGroups: tagsGroupsReducer,
      taskMetas: taskMetasReducer,
      tasks,
      timeoffs,
      timeoffTypes,
      timer,
      timeRange,
      undoCommandStream,
      views: viewsReducer,
    },
    preloadedState,
    middleware: () =>
      new Tuple<Middleware<WebAppState>[]>(
        thunk,
        searchService.getMainThreadReduxMiddleware(NON_CLONEABLE_ACTIONS),
        urlFilterSync,
        listenForOnboardingTriggers,
        persistPmSidebarPrefs,
        routerMiddleware(history),
        lastAllocatedTaskMiddleware(indexedDbStrategy),
      ),
  });

  window.reduxStoreDispatch = store.dispatch;

  return store;
};

export type WebReduxStore = ReturnType<typeof createWebAppStore>;

export const mockWebAppStore = (initialState: Partial<WebAppState> = {}) => {
  // Using casting because we support passing partials to simplify
  // testing setup
  return createWebAppStore(initialState as WebAppState);
};

export const mockWebAppState = (overrides: Partial<WebAppState> = {}) => {
  const store = mockWebAppStore(overrides);

  dispatchDeriveSearchContextAction(store);

  return store.getState();
};

/**
 * @deprecated
 *
 * Use mockAppStore, which has better types
 */
export const makeTestStore = (initialState?: unknown) => {
  return createWebAppStore(initialState as WebAppState);
};
