import { omit } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import {
  CLEAR_JWT,
  GET_JWT,
  GET_JWT_FAILURE,
  GET_JWT_SUCCESS,
} from '../actions';

const REDUCER_NAME = 'jwt';
const DEFAULT_STATE = {
  accessToken: null,
  refreshToken: null,
  currentCompanyId: null,
  expiry: 0,
};

export function jwtReducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case GET_JWT: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case GET_JWT_SUCCESS: {
      // Ensure `payload` doesn't have `type`, otherwise it will be saved in the state.
      const payload = omit(action, ['type']);
      if (!(payload.accessToken || payload.refreshToken)) {
        return state.loadState === 'LOADING'
          ? { ...state, loadState: 'LOAD_SUCCESS' }
          : state;
      }
      return { ...state, loadState: 'LOAD_SUCCESS', ...payload };
    }

    case CLEAR_JWT: {
      return {
        loadState: null,
        ...DEFAULT_STATE,
      };
    }

    case GET_JWT_FAILURE: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.refreshToken ? 'LOAD_SUCCESS' : null;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
}

export default jwtReducer;
