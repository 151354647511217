import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

import { config } from '@float/libs/config';
import {
  featureFlags,
  TargetedDebugLogFlagVariants,
} from '@float/libs/featureFlags';

export function initDatadogRum() {
  const options: RumInitConfiguration = {
    applicationId: '5fa94314-283d-401d-b914-2f03095b36c3',
    clientToken: 'pubc10c15d1e8ff001dd03de6945ebc5297',
    site: 'datadoghq.com',
    service: 'float-web-app',
    env: config.environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.BUILD_VERSION,
    sessionSampleRate: 5,
    // We don't want to use the session replay for now
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  };

  if (
    featureFlags.isDebugLogVariantEnabled(
      TargetedDebugLogFlagVariants.PerformanceMonitoring,
    )
  ) {
    Object.assign(options, {
      trackUserInteractions: true,
      sessionSampleRate: 100,
    });
  }

  datadogRum.init(options);
}
