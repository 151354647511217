import {
  Account,
  CurrentUser,
  Person,
  Phase,
  Project,
  ProjectTemplate,
  SavedView,
  Status,
  Tag,
  Timeoff,
  TimeoffStatuses,
  TimeoffType,
} from '@float/types';
import type { ProjectWithExtraData } from '@float/common/selectors/projects';

import { checkHasRequiredBit } from '../lib/checkMatchRequiredBits';
import {
  getAccount,
  getAccountId,
  getAccountType,
  getAssignees,
  getManageGroup,
  getUserAccess,
} from '../lib/helpers';
import { hasNoDepartmentFilter as hasNoDepartmentFilterHelper } from './helpers/hasNoDepartmentFilter';

export const newAccountIsEqualOrLess = (
  user: CurrentUser,
  {
    account,
    person,
    accessCheck,
  }: {
    account: { account_type: number };
    person?: Person;
    accessCheck?: number;
  },
) => {
  const accountType = getAccountType(user);
  const newAccountTypeId = getAccountType(account || person);
  if (!newAccountTypeId) {
    return true; // isPersonWithoutAccount
  }

  // AccountOwner > Admin > Manager > DepartmentManager > ProjectManager > Member
  const accountRightsOrder = [1, 2, 7, 6, 3, 4];
  const userOrder = accountType && accountRightsOrder.indexOf(accountType);
  const newAccountOrder = accountRightsOrder.indexOf(newAccountTypeId);

  const userHasHigherRole =
    userOrder !== undefined && userOrder < newAccountOrder;
  if (userHasHigherRole) {
    return true;
  }

  const isSameRole = userOrder == newAccountOrder;
  if (isSameRole) {
    if (typeof accessCheck === 'number') {
      const currentAccess = getUserAccess(user);
      return checkHasRequiredBit(currentAccess, accessCheck);
    }

    return true;
  }

  return false;
};

export const accountPeopleInManagementGroup = (
  user: CurrentUser,
  { person }: { person: Person },
) => {
  if (!person) return false;

  const managed: Account['management_group'] = getManageGroup(user);

  const isManagedDirectly = managed.people.includes(person.people_id);
  if (isManagedDirectly) return true;

  const isManagedByDepartment = managed.departments.some((departmentId) => {
    const department = person.department && person.department.department_id;
    return department === departmentId;
  });
  if (isManagedByDepartment) return true;

  return false;
};

export const isPersonWithoutAccount = (
  user: CurrentUser,
  { person }: { person: Person },
) => {
  if (!person) return false;
  return person.account;
};

export const isOwnAccount = (
  user: CurrentUser,
  { account, person }: { account: Account; person: Person },
) => {
  const { account_id } = user;
  const otherAccountId = getAccountId(account || person);
  return account_id === otherAccountId;
};

export const isNotOwnAccount = (
  user: CurrentUser,
  ctx: { account: Account; person: Person },
) => {
  return !isOwnAccount(user, ctx);
};

export const requestIsInternal = () => {
  return false;
};

export const timeTrackingEnabled = (user: CurrentUser) => {
  return user.time_tracking > 0;
};

export const isNotLocked = (
  user: CurrentUser,
  { entity }: { entity?: { isInLockPeriod: boolean } },
) => {
  return !entity?.isInLockPeriod;
};

export const isNotLockedTaskList = (
  user: CurrentUser,
  { project }: { project?: Project },
) => {
  return !project?.locked_task_list;
};

export const isProjectOwner = (
  user: CurrentUser,
  {
    template,
    project,
  }: {
    template?: ProjectTemplate;
    project?: Project;
  },
) => {
  if (template) {
    return user.account_id === template.creator_id;
  }

  if (!project?.project_manager) {
    return false;
  }

  const projectOwnderId =
    // @ts-expect-error Not sure why project_manager can be also an account
    project.project_manager.account_id || project.project_manager;

  return user.admin_id === projectOwnderId;
};

export const onProjectTeam = (
  user: CurrentUser,
  { project }: { project: ProjectWithExtraData },
) => {
  if (!project) {
    return false;
  }

  const peopleIds = project.all_people_ids ?? project.people_ids ?? [];

  // we should consider people in phases too
  return peopleIds.some((x) => x == user.people_id);
};

export const onPhaseTeam = (
  user: CurrentUser,
  { phase }: { phase?: Phase },
) => {
  if (!phase) {
    return false;
  }

  return (phase.people_ids || []).some((x) => x == user.people_id);
};

export const projectIsCommon = (
  user: CurrentUser,
  { project }: { project?: Project },
) => {
  if (!project) {
    return false;
  }

  return project?.common;
};

export const isPerson = (user: CurrentUser) => {
  return !!user.people_id;
};

export const pre_isOwnItem = (user: CurrentUser) => {
  return isPerson(user);
};

export const hasCreatedItem = (
  user: CurrentUser,
  ctx: { entity?: Pick<SavedView, 'created_by'> },
) => {
  if (!ctx.entity) return false;
  if (!ctx.entity.created_by) return false;

  return user.account_id === ctx.entity.created_by;
};

export const isOwnPersonalView = (
  user: CurrentUser,
  ctx: {
    entity?: Pick<SavedView, 'created_by' | 'personal'>;
    changes?: Partial<SavedView>;
  },
) => {
  if (!ctx.entity) return false;

  const personal = ctx.changes ? ctx.changes.personal : ctx.entity.personal;

  // Views generated from the Saved Search migation
  // have the created_by field as null
  // see https://linear.app/float-com/issue/CS-1552/team-61778-unable-to-remove-personal-views
  if (!ctx.entity.created_by) {
    return personal === true;
  }

  return user.account_id === ctx.entity.created_by && personal === true;
};

export const isPinAction = (
  _: CurrentUser,
  ctx: {
    changes?: Partial<SavedView>;
  },
) => {
  if (!ctx.changes) return false;

  const keys = Object.keys(ctx.changes);

  return keys.length && keys.every((key) => key === 'pinned');
};

export const isOwnItem = (
  user: CurrentUser,
  ctx: {
    people?: Person[];
    person?: Person;
    entity: { people_id?: number; people_ids?: number };
    account_id?: undefined;
  },
) => {
  const assignees = getAssignees(ctx);
  const uniqPerson = assignees.length === 1 && assignees[0];
  if (uniqPerson) {
    const id = uniqPerson?.people_id || uniqPerson;
    return user.people_id === id;
  }

  const { person, account_id } = ctx;
  const itemOwnerId = person?.account_id || account_id;
  return user.account_id === itemOwnerId;
};

export const peopleInViewableDepartment = (
  user: CurrentUser,
  ctx: {
    people?: Person[];
    person?: Person;
    entity: { people_id?: number; people_ids?: number };
  },
) => {
  const account = getAccount(user);
  const viewableDeps = new Set(
    account.department_filter_all || account.department_filter || [],
  );

  const canViewEveryone = viewableDeps.size === 0;
  if (canViewEveryone) {
    return true;
  }

  const assignees: Person[] = getAssignees(ctx);

  return assignees.every((person) => {
    const personDepartment = person.department_id;
    return viewableDeps.has(personDepartment);
  });
};

export const assigneesInManagementGroup = (
  user: CurrentUser,
  ctx: {
    people?: Person[];
    person?: { people_id: number | null; department_id?: number };
    entity?: { people_id?: number; people_ids?: number };
  },
) => {
  const managed = getManageGroup(user);
  const assignees: Person[] = getAssignees(ctx);

  return assignees.every((person) => {
    const isOwn = user.people_id === person.people_id;
    if (isOwn) return true;

    const isManagedViaDepartment =
      person.department_id &&
      managed.departments.includes(person.department_id);
    if (isManagedViaDepartment) return true;

    const personId = person.people_id || person;
    const isManagedViaPerson = managed.people.includes(personId);
    if (isManagedViaPerson) return true;

    return false;
  });
};

export const isPeopleTag = (user: CurrentUser, { tag }: { tag?: Tag }) => {
  if (!tag) {
    return false;
  }

  return tag.type === 2;
};

export const isProjectTag = (user: CurrentUser, { tag }: { tag?: Tag }) => {
  if (!tag) {
    return false;
  }

  return tag.type === 1;
};

export const isStatusUpdate = (
  user: CurrentUser,
  { entity }: { entity?: Status },
) => {
  return !!entity?.status_id;
};

export const isNotSelf = (
  user: CurrentUser,
  { account, person }: { account?: Account; person?: Person },
) => {
  const { account_id } = user;
  const otherAccountId = getAccountId(account || person);
  return account_id !== otherAccountId;
};

export const peopleAccountIsEqualOrLess = newAccountIsEqualOrLess;

export const isNotActive = (
  user: CurrentUser,
  { entity }: { entity?: { active: boolean } },
) => {
  return entity && !entity.active;
};

export const isTimeoffTypeActive = (
  user: CurrentUser,
  { timeoffType }: { timeoffType?: TimeoffType },
) => {
  return timeoffType && timeoffType.active;
};

export const statusIsTentative = (
  user: CurrentUser,
  { entity }: { entity?: Timeoff },
) => {
  return entity?.status === TimeoffStatuses.TENTATIVE;
};

export const statusIsDeclined = (
  user: CurrentUser,
  { entity }: { entity?: Timeoff },
) => {
  return entity?.status === TimeoffStatuses.DECLINED;
};

export const approvalRequired = (user: CurrentUser) => {
  const timeoffApprovalsEnabled = user.timeoff_approvals;
  return timeoffApprovalsEnabled;
};

export const approvalNotRequired = (user: CurrentUser) => {
  const timeoffApprovalsEnabled = user.timeoff_approvals;
  return !timeoffApprovalsEnabled;
};

export const hasNoDepartmentFilterSet = hasNoDepartmentFilterHelper;

// Server specific validation logic that cannot implemented on the client ACL layer
export const isValidTaskName = () => true;
export const isLoggedTimeValidTaskName = () => true;

export const loggedAssigneesInManagementGroup = assigneesInManagementGroup;
export const peopleInManagementGroup = assigneesInManagementGroup;
export const isOwnLoggedTime = isOwnItem;
export const isParentProjectOwner = isProjectOwner;
export const onParentProjectTeam = onProjectTeam;
export const parentProjectIsCommon = projectIsCommon;
export const isLoggedTimeProjectOwner = isProjectOwner;
export const onLoggedTimeProjectTeam = onProjectTeam;
export const loggedTimeProjectIsCommon = projectIsCommon;
