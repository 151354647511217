import React from 'react';

import { RateInput } from '@float/common/components/Rate';
import { useEditBillRate } from '@float/common/hooks/useEditBillRate';
import { CostRateFormData } from '@float/types/costRate';
import { Role } from '@float/types/role';

type RoleBillRate = Role['default_hourly_rate'];

type Props = {
  className?: string;
  placeholder?: string;
  value: RoleBillRate;
  onChange: (value: RoleBillRate) => void;
};

export type EditRoleBillRateRenderProps = {
  value: RoleBillRate;
  row: (string | CostRateFormData)[];
  onChange: Props['onChange'];
};

export function EditRoleBillRate(props: Props) {
  const { className, placeholder, value, onChange } = props;

  const { handleBillRateChange } = useEditBillRate(onChange);

  return (
    <div className={className}>
      <RateInput
        placeholder={placeholder}
        value={value}
        onChange={handleBillRateChange}
      />
    </div>
  );
}
