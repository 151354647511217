import React, { ReactNode } from 'react';
import { plural, t, Trans } from '@lingui/macro';

import {
  formatRateWithCurrencySymbol,
  getBillRateLabel,
  isCostRateFeatureActive,
} from '@float/common/lib/rates/rates';
import type { CurrencyConfig } from '@float/common/lib/rates/rates';

import * as styles from './ModalConfirmRateChange.styles.css';

export type RawRate = number | string | null | undefined;

type Rates = {
  billRateFrom?: RawRate;
  billRateTo?: RawRate;
  costRateFrom?: RawRate;
  costRateTo?: RawRate;
};

export type GetConfirmPrimaryMessageProps = Rates & {
  numPeopleAffected: number;
  numRolesAffected?: number;
  currencyConfig: CurrencyConfig;
};

type GetModalTitleProps = Rates & {
  roleName?: string;
  numRolesAffected?: number;
  numPeopleAffected: number;
};

function isBulkEdit(numRolesAffected?: number) {
  return Boolean(numRolesAffected && numRolesAffected > 1);
}

export function getPeopleCountLabel(numPeopleAffected: number): string {
  return plural(numPeopleAffected, {
    one: '# person',
    other: '# people',
  });
}

function getAffectedMessage(
  numPeopleAffected: number,
  numRolesAffected?: number,
) {
  const peopleLabel = getPeopleCountLabel(numPeopleAffected);

  if (isBulkEdit(numRolesAffected)) {
    return (
      <Trans>
        You are changing the rates at the Role level for {peopleLabel}
      </Trans>
    );
  }

  return (
    <Trans>
      You are changing the rates for {peopleLabel} assigned to this Role
    </Trans>
  );
}

function getRateWithCurrency(rate: string, currencyConfig: CurrencyConfig) {
  const opts = { useSpacing: false };
  return formatRateWithCurrencySymbol(rate, currencyConfig, opts);
}

function getFormattedRate(rate: RawRate, currencyConfig: CurrencyConfig) {
  if (typeof rate === 'number')
    return getRateWithCurrency(rate.toString(), currencyConfig);

  if (typeof rate === 'string')
    return getRateWithCurrency(rate, currencyConfig);

  return '';
}

function getRateChangeMessage(props: {
  rateFrom: RawRate;
  rateTo: RawRate;
  rateLabel: string;
  currencyConfig: CurrencyConfig;
}): ReactNode {
  const { currencyConfig, rateLabel } = props;
  const rateFrom = getFormattedRate(props.rateFrom, currencyConfig);
  const rateTo = getFormattedRate(props.rateTo, currencyConfig);

  const isRateFromSet = typeof rateFrom === 'string' && rateFrom.length > 0;
  const isRateToSet = typeof rateTo === 'string' && rateTo.length > 0;

  if (!isRateFromSet && !isRateToSet) return null;

  if (rateFrom === rateTo) return null;

  if (isRateFromSet && isRateToSet) {
    return t`${rateLabel} from ${rateFrom} to ${rateTo}`;
  }

  if (isRateToSet) {
    return t`${rateLabel} to ${rateTo}`;
  }

  if (isRateFromSet) {
    return null;
  }

  return rateLabel;
}

/**
 * Returns the primary confirm message
 *
 * It returns ReactNode, since later we might need to style the currency symbols with some tags
 */
export const getConfirmPrimaryMessage = (
  props: GetConfirmPrimaryMessageProps,
): ReactNode => {
  const {
    billRateFrom,
    billRateTo,
    costRateFrom,
    costRateTo,
    numPeopleAffected,
    numRolesAffected,
    currencyConfig,
  } = props;

  const affectedMessage = getAffectedMessage(
    numPeopleAffected,
    numRolesAffected,
  );

  const costRateMessage = !isCostRateFeatureActive()
    ? null
    : getRateChangeMessage({
        rateFrom: costRateFrom,
        rateTo: costRateTo,
        rateLabel: t`Cost rate`,
        currencyConfig,
      });

  const billRateMessage = getRateChangeMessage({
    rateFrom: billRateFrom,
    rateTo: billRateTo,
    rateLabel: getBillRateLabel(),
    currencyConfig,
  });

  if (!costRateMessage && !billRateMessage) return <>{affectedMessage}. </>;

  return (
    <>
      {affectedMessage}:
      <ul className={styles.list}>
        {costRateMessage && (
          <li className={styles.listItem}>{costRateMessage}</li>
        )}
        {billRateMessage && (
          <li className={styles.listItem}>{billRateMessage}</li>
        )}
      </ul>
    </>
  );
};

export function getConfirmTitle(props: GetModalTitleProps) {
  const {
    billRateFrom,
    billRateTo,
    costRateFrom,
    costRateTo,
    numRolesAffected,
    roleName,
  } = props;

  const billRateChanged = billRateFrom !== billRateTo;
  const costRateChanged = costRateFrom !== costRateTo;
  const billRateLabel = getBillRateLabel().toLowerCase();

  if (isBulkEdit(numRolesAffected)) {
    const rateType = billRateChanged ? billRateLabel : 'cost rate';
    return t`Update ${rateType} for ${numRolesAffected} roles?`;
  }

  let line1 = t`Update rates for “${roleName}”?`;

  const peopleLabel = getPeopleCountLabel(props.numPeopleAffected);
  const line2 = t`This will update rates for ${peopleLabel}`;

  if (billRateChanged && !costRateChanged) {
    line1 = t`Update ${billRateLabel} for “${roleName}”?`;
  }

  if (costRateChanged && !billRateChanged) {
    line1 = t`Update cost rate for “${roleName}”?`;
  }

  return `${line1} ${line2}`;
}
