import { createSelector } from 'reselect';

import { filterAndKeyBy } from '@float/common/lib/keyBy';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import {
  getLoggedTimesListRaw,
  isLoggedTimeDeletePlaceholder,
  isSearchLoggedTime,
} from '@float/common/selectors/loggedTimes';
import { getProjectsIdsSet } from '@float/common/selectors/projects/getProjectsIdsSet';
import {
  LoggedTime,
  LoggedTimeDeletePlaceholder,
  SearchLoggedTime,
} from '@float/types';
import type { ScheduleVisibleDataWindow } from '@float/common/store/schedule';

import { selectVisibleDataWindow } from './selectVisibleDataWindow';

const getLoggedTimeIsInsideRange = (
  dates: DatesManager,
  { start, end }: ScheduleVisibleDataWindow,
  { date, reference_date }: LoggedTime,
) => {
  const dateNum = dates.toNum(date);
  if (dateNum >= start && dateNum <= end) return true;

  if (reference_date) {
    const referenceDateNum = dates.toNum(reference_date);

    if (referenceDateNum >= start && referenceDateNum <= end) return true;
  }

  return false;
};

function isLoggedTimeVisible(
  dates: DatesManager,
  loggedTime: LoggedTime | SearchLoggedTime,
  visibleDataWindow: ScheduleVisibleDataWindow,
  projecsIds: Set<number>,
) {
  // If it is a search logged time it can't be visible on the schedule
  if (isSearchLoggedTime(loggedTime)) return false;

  // Is the person in one of the visible rows?
  if (!visibleDataWindow.peopleIds.has(loggedTime.people_id)) return false;

  // Compensate for a scenario where the backend returns loggedtimes for
  // projects that have been deleted. Note that if we don't have a project_id
  // at all, we should forward along the record as it's the result of a
  // delete and we want to ensure the replacesTempId logic in Serena loadData
  // triggers.
  if (!projecsIds.has(loggedTime.project_id)) return false;

  // Is the logged time inside the columns range?
  return getLoggedTimeIsInsideRange(dates, visibleDataWindow, loggedTime);
}

export const selectVisibleLoggedTimes = createSelector(
  [
    selectVisibleDataWindow,
    selectDatesManager,
    getLoggedTimesListRaw,
    getProjectsIdsSet,
  ],
  (visibleDataWindow, dates, loggedTimes, projecsIds) => {
    if (visibleDataWindow === null) return {};

    return filterAndKeyBy(
      loggedTimes,
      'logged_time_id',
      (loggedTime): loggedTime is LoggedTime | LoggedTimeDeletePlaceholder =>
        isLoggedTimeDeletePlaceholder(loggedTime) ||
        isLoggedTimeVisible(dates, loggedTime, visibleDataWindow, projecsIds),
    );
  },
);
