import { useState } from 'react';
import { t } from '@lingui/macro';

import { areRatesEqual } from '@float/common/lib/rates/rates';
import { useAppSelectorStrict } from '@float/common/store';
import {
  BudgetType,
  Project,
  ProjectTeamMemberData,
} from '@float/types/project';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';
import { usePeopleByDept } from '@float/web/components/panelViews/Project/fields/ProjectTeamMemberField.hooks';
import { getPeopleMap } from '@float/web/selectors';

import { PersonToSwitchIn } from '../../ModalSwitchPerson.types';

type UseSwitchPersonSelectParams = {
  project?: Project;
  personBillRateOnProject?: string | null;
  shouldShowRates: boolean;
  onClickSwitchPerson?: (newPerson: PersonToSwitchIn) => void;
};

const usePeopleByDepartment = (project?: Project, shouldShowRates = false) => {
  const peopleOptions = usePeopleByDept(
    project?.people_ids.map((p) => ({
      people_id: p,
    })) as ProjectTeamMemberData[],
    shouldShowRates,
  );

  return peopleOptions;
};

function useGetPersonDefaultBillRate() {
  const peopleMap = useAppSelectorStrict(getPeopleMap);

  return (personId: number) => {
    const person = peopleMap[personId];
    if (!person) return null;

    return (
      person.default_hourly_rate ?? person.role?.default_hourly_rate ?? null
    );
  };
}

export const useSwitchPersonSelect = (params: UseSwitchPersonSelectParams) => {
  const {
    project,
    personBillRateOnProject,
    shouldShowRates,
    onClickSwitchPerson,
  } = params;

  const peopleOptions = usePeopleByDepartment(project, shouldShowRates);

  const getDefaultBillRate = useGetPersonDefaultBillRate();

  const [error, setError] = useState<string[][] | undefined>(undefined);

  const [newPerson, setNewPerson] = useState<PersonToSwitchIn>();

  const [shouldUseNewRate, setShouldUseNewRate] = useState(false);

  const handleOnClickSwitchPerson = () => {
    if (!newPerson?.personId) {
      setError([[t`This field is required.`]]);
      return;
    }

    const newRate = shouldUseNewRate ? newPerson.hourlyRate : undefined;
    const newPersonToUse = { ...newPerson, hourlyRate: newRate };

    if (onClickSwitchPerson) onClickSwitchPerson(newPersonToUse);
  };

  const handlePersonSelect = (option: VirtualSelectOption) => {
    const personId = option.value ? Number(option.value) : undefined;
    if (!personId) return;

    const newPersonHourlyRate: string | null = getDefaultBillRate(personId);

    setNewPerson({
      personId,
      personName: option.label,
      hourlyRate: newPersonHourlyRate,
    });

    setError(undefined);
    setShouldUseNewRate(false);
  };

  const handleShouldUseNewRateChange = (value: boolean) => {
    setShouldUseNewRate(value);
  };

  const isMonetaryBudgetType =
    project?.budget_type === BudgetType.TotalFee ||
    project?.budget_type === BudgetType.HourlyFee;

  const isNewPersonSelected = Boolean(newPerson?.personId);
  const doesNewPersonHaveRate = Boolean(newPerson?.hourlyRate);
  const shouldShowRateMismatch =
    isMonetaryBudgetType &&
    isNewPersonSelected &&
    doesNewPersonHaveRate &&
    !areRatesEqual(personBillRateOnProject, newPerson?.hourlyRate);

  return {
    error,
    newPerson,
    peopleOptions,
    shouldShowRateMismatch,
    shouldUseNewRate,
    handleOnClickSwitchPerson,
    handlePersonSelect,
    handleShouldUseNewRateChange,
  };
};
