import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import cs from 'classnames';

import { keyBy } from '@float/common/lib/keyBy';
import { getCurrencyProps } from '@float/common/selectors/currentUser';
import { getRateSuffixForRoleOption } from '@float/common/selectors/roles.helpers';
import { RoleOption } from '@float/types';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';

import { useRoleOptionsWithRateElements } from './hooks/useRoleOptionsWithRateElements';

import * as styles from './PersonRole.css';

export type PersonRoleProps = {
  currencyConfig: ReturnType<typeof getCurrencyProps>;
  hasCustomRate: boolean;
  onRoleChange: (roleId: number | null) => void;
  placeholder?: string;
  roleId: number | null;
  rolesOptions: RoleOption[];
};

export const PersonRole = (props: PersonRoleProps) => {
  const {
    currencyConfig,
    hasCustomRate,
    onRoleChange,
    placeholder,
    roleId,
    rolesOptions,
  } = props;

  const rolesOptionsById = useMemo(
    () => keyBy(rolesOptions, 'value'),
    [rolesOptions],
  );

  const rolesOptionsWithRatesElements: VirtualSelectOption[] =
    useRoleOptionsWithRateElements({ rolesOptions, currencyConfig });

  const roleOptionSelected =
    roleId && rolesOptionsById[roleId] ? rolesOptionsById[roleId] : null;

  const rateSuffixForSelectedRole = getRateSuffixForRoleOption(
    roleOptionSelected,
    currencyConfig,
  );

  const rateSuffixElement = rateSuffixForSelectedRole ? (
    <span
      className={cs(
        styles.roleRateSelected,
        hasCustomRate && styles.roleRateSelectedInactive,
      )}
    >
      {rateSuffixForSelectedRole}
    </span>
  ) : null;

  const onChange = (option: RoleOption) => {
    onRoleChange(option.value);
  };

  return (
    <div className={styles.wrapper}>
      <VirtualSelect
        // @ts-expect-error virtual select is not typed
        clearInputOnDropdownOpen={false}
        creatable
        data-testid="personRole"
        hideSelectedIcon={true}
        label={t`Role`}
        onChange={onChange}
        options={rolesOptionsWithRatesElements}
        placeholder={placeholder}
        suffix={rateSuffixElement}
        value={roleId}
        visibleItems={6}
      />
    </div>
  );
};
