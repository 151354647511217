import type {
  ChartComparisonModes,
  ProjectPercentageModes,
} from '@float/ui/deprecated/Chart/types';

import type { RawTableData } from './useReportsStateReducer';

export type TimeoffRawItem = {
  holiday: boolean;
  id: number;
  name: string;
  people: Record<number, { days: number; hours: number }>;
};

export type ReportTableDataApiResponse = {
  'legacy.capacity': RawTableData['capacity'];
  'legacy.budgets': {
    projects: RawTableData['budgets'];
    phases: RawTableData['budgets'];
    tasks: RawTableData['budgets'];
  };
  'legacy.overtime': RawTableData['overtime'];
  'legacy.timeoff': RawTableData['timeoff'];
  'legacy.totals': RawTableData['totals'];
  chart: { origin: unknown; datapoints: unknown };
};

export type ReportTimeItemData = {
  billable: boolean;
  date?: string;
  hours: { future: number; scheduled: number };
  fee: number;
  cost?: {
    scheduled: number;
    future: number;
  };
  note?: string;
  id: number;
  name: string;
  person_id: number;
  task_meta_id: number;
  phase_id: number;
  project_id: number;
  rate: number;
  tentative: boolean;
  type: string;
};

export type OverTimeReportData = {
  future: number;
  logged: number;
  total: number;
};

export type TimeReportData = {
  scheduled: number;
  billable: number;
  nonbillable: number;
  overtime: number;
};

export type FutureTimeReportData = {
  scheduled: number;
  billable: number;
  nonbillable: number;
  overtime: number;
  tentative: { billable: number; nonbillable: number };
};

export type PeopleChildRow = {
  billable: number;
  capacity: number;
  combined: TimeReportData;
  logged: TimeReportData;
  nonbillable: number;
  overtime: number;
  person_id: string | number;
  scheduled: number;
  timeoff: number;
};

export type RoleChildRow = {
  billable: number;
  capacity: number;
  children: {
    people: Record<string, PeopleChildRow>;
  };
  combined: TimeReportData;
  future: FutureTimeReportData;
  logged: TimeReportData;
  nonbillable: number;
  overtime: number;
  role_id: number;
  scheduled: number;
  tentative: Pick<TimeReportData, 'billable' | 'nonbillable'>;
  timeoff: number;
};

export type RowData = {
  billable: number;
  capacity: number;
  children: {
    people: Record<string, PeopleChildRow>;
  };
  combined: TimeReportData;
  future: FutureTimeReportData;
  logged: TimeReportData;
  nonbillable: number;
  overtime: number;
  role_id: number;
  person_id: number;
  scheduled: number;
  tentative: Pick<TimeReportData, 'billable' | 'nonbillable'>;
  timeoff: number;
};

export type ReportSettings = {
  activeMode?: number;
  activeTeamModes?: number[];
  comparisonMode: ChartComparisonModes;
  endDate: DateString | Date;
  projectsPercentageMode: ProjectPercentageModes;
  startDate: DateString | Date;
  taskStatus?: string[];
  teamMode?: number;
  timeoffApprovalsEnabled?: boolean;
  timeOffStatus?: string[];
};

export type CsvExportConfig = {
  options: Array<{
    title: string;
    fn: () => Promise<void>;
  }>;
};

export type ReportTotals = {
  capacity: number;
  billable: number;
  nonbillable: number;
  feeCents: number;
  costCents: number;
  tentative: {
    billable: number;
    nonbillable: number;
    timeoff: number;
  };
  scheduled: number;
  timeoff: number;
  timeoff_days: number;
  holiday: number;
  holiday_days: number;
  overtime: number;
  unscheduled: number;
  logged: {
    billable: number;
    nonbillable: number;
    total: number;
    overtime: number;
    unscheduled: number;
    feeCents: number;
    costCents: number;
  };
  combined: {
    billable: number;
    nonbillable: number;
    total: number;
    overtime: number;
    unscheduled: number;
    feeCents: number;
    costCents: number;
    tentative: {
      billable: number;
      nonbillable: number;
    };
  };
  future: {
    billable: number;
    nonbillable: number;
    scheduled: number;
    overtime: number;
    tentative: {
      billable: number;
      nonbillable: number;
    };
  };
};

export enum ProjectMarginType {
  MarginOnFixedFee = 'MarginOnFixedFee',
  MarginOnPeopleFee = 'MarginOnPeopleFee',
}
