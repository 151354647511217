import { t } from '@lingui/macro';

import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

export const getCostHeaderLabel = (mode: ChartComparisonModes) => {
  if (mode === ChartComparisonModes.LOGGED) {
    return t`Logged cost`;
  }
  if (mode === ChartComparisonModes.SCHEDULED) {
    return t`Scheduled cost`;
  }
  return t`Projected cost`;
};
