import { FloatDeploymentEnvironment } from '@float/constants/environment';
import { LAUNCH_DARKLY_CLIENT_ID } from '@float/libs/featureFlags';

export function getLaunchDarklyClientId(
  environment: FloatDeploymentEnvironment,
) {
  if (environment === FloatDeploymentEnvironment.Prod) {
    return LAUNCH_DARKLY_CLIENT_ID.production;
  }

  if (environment === FloatDeploymentEnvironment.Staging) {
    return LAUNCH_DARKLY_CLIENT_ID.staging;
  }

  return LAUNCH_DARKLY_CLIENT_ID.develop;
}
