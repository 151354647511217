import React from 'react';
import { t } from '@lingui/macro';

import { formatDateForDB } from '@float/common/lib/utils';
import { Role } from '@float/types/role';
import {
  EditRoleBillRate,
  EditRoleBillRateRenderProps,
} from '@float/web/settingsV2/components/Roles/EditRole/EditRoleBillRate';
import {
  EditRoleCostRate,
  EditRoleCostRateRenderProps,
} from '@float/web/settingsV2/components/Roles/EditRole/EditRoleCostRate';
import { RoleTablePeopleCount } from '@float/web/settingsV2/components/Roles/RoleTableCell/RoleTablePeopleCount';

import * as styles from '../../Roles.styles.css';

export const roleTableRowEditConfig = {
  roleName: () => ({
    type: 'text',
    placeholder: t`Role name`,
    className: styles.inputField,
  }),

  peopleCount: (role: Role) => ({
    render: () => (
      <div className={styles.rolePeopleCountTagWrapper}>
        <RoleTablePeopleCount name={role.name} count={role.people_count ?? 0} />
      </div>
    ),
  }),

  costRate: () => ({
    render: (renderProps: EditRoleCostRateRenderProps) => {
      const { onChange } = renderProps;

      const value =
        typeof renderProps.value === 'string'
          ? {
              cost_rate: renderProps.value,
              cost_rate_effective_date: formatDateForDB(),
            }
          : renderProps.value;

      return (
        <EditRoleCostRate
          className={styles.editRoleCostRate}
          value={value}
          onChange={onChange}
        />
      );
    },
  }),

  billRate: () => ({
    render: (renderProps: EditRoleBillRateRenderProps) => {
      const { value, onChange } = renderProps;

      return (
        <EditRoleBillRate
          className={styles.inputField}
          value={value}
          onChange={onChange}
        />
      );
    },
  }),
};
