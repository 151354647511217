import React from 'react';

import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import { isAmountEmpty } from '@float/common/lib/budget';
import { getNumberSeparators } from '@float/common/lib/budget/helpers/getNumberSeparators';
import { config } from '@float/libs/config';

import Input from './Input';

const USD_CONFIG = {
  currency: 'USD',
  style: 'currency',
};

function isPrefixLocale(currentUser) {
  return new Intl.NumberFormat(currentUser.locale, USD_CONFIG)
    .format('11.00')
    .endsWith('0');
}

function getPrefixOrSuffix(currentUser) {
  const { currency_symbol } = currentUser;

  return isPrefixLocale(currentUser)
    ? { prefix: currency_symbol }
    : { suffix: currency_symbol };
}
class CurrencyInputComponent extends React.Component {
  componentDidMount() {
    const { decimal, thousand } = getNumberSeparators(config.locale);
    this.decimal = decimal;
    this.thousand = thousand;
  }

  onKeyDown = () => {
    // reset flag
    this.lastCharacterInvalid = false;
  };

  onKeyPress = (e) => {
    const char =
      e.type == 'keypress'
        ? String.fromCharCode(e.keyCode || e.which)
        : (e.clipboardData || window.clipboardData).getData('Text');

    const notNumberOrSeparator = new RegExp(
      `[^(\\d|\\${this.decimal}|\\${this.thousand})]`,
    ).test(char);
    if (notNumberOrSeparator) {
      this.lastCharacterInvalid = true;
      return;
    }
    this.lastCharacterInvalid = false;
  };

  onChange = (e) => {
    if (this.lastCharacterInvalid) {
      return;
    }

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e);
    }
  };

  render() {
    const { currentUser, value, allowZero = false, ...rest } = this.props;

    if (!getCanCurrentUserSeeBudgets(currentUser)) return null;
    const isEmpty = isAmountEmpty(value, allowZero);

    // Allow a custom placeholder when value is empty
    const placeholder = this.props.placeholder || (isEmpty ? '0' : '');

    return (
      <Input
        {...getPrefixOrSuffix(currentUser)}
        {...rest}
        ariaLabel={rest.label}
        value={isEmpty ? '' : value}
        placeholder={placeholder}
        onKeyDown={this.onKeyDown}
        onKeyPress={this.onKeyPress}
        onPaste={this.onKeyPress}
        onChange={this.onChange}
      />
    );
  }
}

CurrencyInputComponent._styles = Input._styles;

/**
 * @deprecated - Use "import { InputCurrency } from '@float/ui/InputCurrency'" instead
 */
export const CurrencyInput = CurrencyInputComponent;
