import { groupBy } from 'lodash';
import { createSelector } from 'reselect';

import { Milestone, ProjectStatus } from '@float/types';

import { getSearchFilteredProjectsIds } from '../search/selectors/projects';
import { getIsProjectPlanView } from './appInfo/scheduleView';
import { createSelectorWithShallowEqualSetResultCheck } from './lib/createSelectorWithShallowEqualSetResultCheck';
import { getProjectsMapRaw } from './projects';

export const getMilestonesMap = (state: {
  milestones: { milestones: Record<number, Milestone> };
}) => state.milestones.milestones;

export const getMilestones = createSelector(
  [getMilestonesMap],
  (milestonesMap) => Object.values(milestonesMap),
);

export const getAllMilestonesByProjectId = createSelector(
  [getMilestones, (_, projectId: number | undefined) => projectId],
  (milestones, projectId) =>
    projectId
      ? milestones.filter((milestone) => milestone.project_id === projectId)
      : [],
);

// Retrieve project milestones and leave out the phase specific ones
export const getProjectMilestonesByProjectId = createSelector(
  [getMilestones, (_, projectId: number | undefined) => projectId],
  (milestones, projectId) =>
    projectId
      ? milestones.filter(
          (milestone) =>
            !milestone.phase_id && milestone.project_id === projectId,
        )
      : [],
);

export const selectProjectFilteredMilestonesIds =
  createSelectorWithShallowEqualSetResultCheck(
    [
      getMilestones,
      getSearchFilteredProjectsIds,
      getProjectsMapRaw,
      getIsProjectPlanView,
    ],
    (
      milestones: Milestone[],
      searchFilteredProjectIds,
      projectsMap,
      isProjectPlanView,
    ) => {
      const result = new Set<number>();
      milestones.forEach((milestone) => {
        if (!searchFilteredProjectIds.has(milestone.project_id)) return;

        const project = projectsMap[milestone.project_id];
        const isDraft = project?.status === ProjectStatus.Draft;

        if (isDraft && !isProjectPlanView) return;

        result.add(milestone.milestone_id);
      });
      return result;
    },
  );

export const selectProjectFilteredMilestones = createSelector(
  [selectProjectFilteredMilestonesIds, getMilestonesMap],
  (ids, milestones) => {
    const result: Milestone[] = [];
    for (const id of ids) {
      if (!milestones[id]) continue;

      result.push(milestones[id]);
    }
    return result;
  },
);

export const selectSearchFilteredMilestonesMap = createSelector(
  [selectProjectFilteredMilestonesIds, getMilestonesMap],
  (ids, milestones) => {
    const result: Record<number, Milestone> = {};
    for (const id of ids) {
      if (!milestones[id]) continue;

      result[id] = milestones[id];
    }
    return result;
  },
);

export const getMilestonesByPhaseId = createSelector(
  [getMilestones, (_, phaseId: number | undefined) => phaseId],
  (milestones, phaseId) =>
    phaseId
      ? milestones.filter((milestone) => milestone.phase_id === phaseId)
      : [],
);

export const selectMilestonesGroupedByProjectId = createSelector(
  [selectProjectFilteredMilestones],
  (milestones) => groupBy(milestones, 'project_id'),
);
