import { useContextSelector } from 'use-context-selector';

import { MediaSize } from '@float/constants/media';
import { MediaContext } from '@float/libs/contexts/MediaContext';

/**
 * Reactively check if the small breakpoint is active.
 *
 * 🚨🚨🚨
 * Prefer using style-based approaches over these hooks where possible
 * as they are more performant.
 * 🚨🚨🚨
 */
export const useIsSmallMediaSizeActive = () =>
  useContextSelector(
    MediaContext,
    (context) => context?.activeMediaSize === MediaSize.Small,
  );

/**
 * Reactively check if the medium breakpoint is active.
 *
 * 🚨🚨🚨
 * Prefer using style-based approaches over these hooks where possible
 * as they are more performant.
 * 🚨🚨🚨
 */
export const useIsMediumMediaSizeActive = () =>
  useContextSelector(
    MediaContext,
    (context) => context?.activeMediaSize === MediaSize.Medium,
  );
