import { LoadState } from '@float/common/reducers/lib/types';

import type {
  EstimatesState,
  PhaseEstimatesState,
  ProjectEstimatesState,
  TaskEstimatesState,
} from './estimates.types';

export const ESTIMATES_REDUCER_NAME = 'estimates';
export const DEFAULT_ESTIMATES_STATE: EstimatesState = {
  projectEstimates: {},
  phaseEstimates: {},
  taskEstimates: {},
};

const BASE_DEFAULT_ESTIMATES_STATE = {
  loadState: LoadState.UNLOADED,
  map: {},
};
export const DEFAULT_PROJECT_ESTIMATES_STATE: ProjectEstimatesState =
  BASE_DEFAULT_ESTIMATES_STATE;
export const DEFAULT_PHASE_ESTIMATES_STATE: PhaseEstimatesState =
  BASE_DEFAULT_ESTIMATES_STATE;
export const DEFAULT_TASK_ESTIMATES_STATE: TaskEstimatesState =
  BASE_DEFAULT_ESTIMATES_STATE;
