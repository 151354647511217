import { truncNumber } from '@float/ui/helpers/number/numberParser';

import { ReportTableDataApiResponse } from './types';

export function sanitizeReportsApiResponse(data: ReportTableDataApiResponse) {
  if (!data || !Array.isArray(data['legacy.totals'])) {
    return data;
  }

  data['legacy.totals'] = data['legacy.totals'].map((item) => {
    if ('hours' in item && item.hours) {
      item.hours.future = truncNumber(item.hours.future);
      item.hours.scheduled = truncNumber(item.hours.scheduled);
    }

    if ('fee' in item && item.fee) {
      item.fee = truncNumber(item.fee);
    }

    return item;
  });

  return data;
}
