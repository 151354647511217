import { t } from '@lingui/macro';

import { isAmountEmpty } from '@float/common/lib/budget';
import {
  areRatesEqual,
  isCostRateFeatureActive,
} from '@float/common/lib/rates/rates';
import { Role } from '@float/types/role';

type RateKey = 'cost_rate' | 'default_hourly_rate';

export type GetPlaceholderParams = {
  selectedRole: Role;
  rateKey: RateKey;
};

export function getRateInputPlaceholder(params: GetPlaceholderParams) {
  const { selectedRole, rateKey } = params;

  const defaultPlaceholder = '0';

  if (!selectedRole) return defaultPlaceholder;

  // As an example - if user clears a person's custom bill rate, we should
  // show the role's bill rate as placeholder implying the person will now
  // inherit the role's bill rate going forward.
  if (typeof selectedRole[rateKey] === 'string') {
    return selectedRole[rateKey];
  }

  return defaultPlaceholder;
}

export function getBudgetAccessLabel() {
  if (isCostRateFeatureActive()) {
    return t`View bill rates and budgets`;
  }

  return t`View everyone’s rates and their project budgets`;
}

type HasCustomRateParams = {
  hasRateAccess: boolean;
  personRate?: string | null;
  roleRate?: string | null;
};

export function getHasCustomRate(params: HasCustomRateParams) {
  const { hasRateAccess, personRate, roleRate } = params;

  if (!hasRateAccess) return false;

  if (isAmountEmpty(personRate) || isAmountEmpty(roleRate)) return false;

  return areRatesEqual(personRate, roleRate) === false;
}
