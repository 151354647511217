import React from 'react';

import { RateInput } from '@float/common/components/Rate';
import { getBillRateLabel } from '@float/common/lib/rates/rates';
import { Props } from '@float/ui/components/InputCurrency/InputCurrencyString';

import * as styles from './PersonRates.styles.css';

export function EditPersonBillRate(props: Props) {
  return (
    <RateInput
      className={styles.rateInput}
      label={getBillRateLabel()}
      {...props}
    />
  );
}
