import React from 'react';
import { isUndefined } from 'lodash';
import styled from 'styled-components';

import * as budget from '@float/common/lib/budget';
import { moment } from '@float/libs/moment';
import { MODES, PURPLE, TEAL } from '@float/ui/deprecated/Chart';
import colors from '@float/ui/deprecated/Theme/colors';

import { getHasFixedBudgetSupport } from '../../helpers/getHasFixedBudgetSupport';

// Note that since we use ReactDOMServer to render these, we won't have the
// theme in context and must access colors directly from the component.

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  min-width: 250px;
  padding: 10px 5px 5px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const Header = styled.header`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
`;

const Value = styled.div`
  font-weight: bold;
  text-align: right;
  color: ${TEAL};

  &.remaining {
    color: ${() => colors.charcoalGrey};
    &.negative {
      color: ${() => colors.red};
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: ${() => colors.charcoalGrey};
  text-align: left;
  line-height: 30px;

  &.logged {
    ${Value} {
      color: ${PURPLE};
    }
  }
`;

const Desc = styled.div`
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const MidValue = styled.div`
  color: ${() => colors.blueGrey};
  text-align: right;
  font-size: 14px;

  padding-right: 10px;

  &.negative {
    color: ${() => colors.red};
  }
`;

function ScheduledTooltip({
  data,
  project: { budget_type, non_billable, isMoneyBudgetType },
  defaultLabel = 'Scheduled',
}) {
  const header = moment(data.key).format('DD MMM YYYY');
  const midValue = data.scheduled_hours;
  const isOverbudget = data.budget_remaining < 0;
  const label = non_billable ? defaultLabel : `${defaultLabel} (billable)`;
  const hasFixedBudgetSupport = getHasFixedBudgetSupport(budget_type);
  const hasBudgetRemaining =
    hasFixedBudgetSupport && !isUndefined(data.budget_remaining);

  const scheduledRow =
    budget_type === 0 ? (
      <Row>
        <Desc>{label}</Desc>
        <MidValue />
        <Value className="remaining">
          {budget.formatAmount(1, data.value)}
        </Value>
      </Row>
    ) : (
      <Row>
        <Desc>{label}</Desc>
        <MidValue>
          {isMoneyBudgetType &&
            !isUndefined(midValue) &&
            budget.formatAmount(1, midValue)}
        </MidValue>
        <Value>{budget.formatAmount(budget_type, data.value)}</Value>
      </Row>
    );

  return (
    <Container>
      <Header>{header}</Header>
      {scheduledRow}
      {hasBudgetRemaining && (
        <Row>
          <Desc>Budget remaining</Desc>
          <Value className={`remaining ${isOverbudget ? 'negative' : ''}`}>
            {budget.formatAmount(budget_type, data.budget_remaining)}
          </Value>
        </Row>
      )}
    </Container>
  );
}

function LoggedTooltip({
  data,
  project: { budget_type, non_billable, isMoneyBudgetType },
}) {
  const header = moment(data.key).format('DD MMM YYYY');
  const midValue = data.logged_hours;
  const isOverbudget = data.budget_remaining < 0;
  const label = non_billable ? 'Logged' : 'Logged (billable)';
  const hasFixedBudgetSupport = getHasFixedBudgetSupport(budget_type);
  const hasBudgetRemaining =
    hasFixedBudgetSupport && !isUndefined(data.budget_remaining);

  const loggedRow =
    budget_type === 0 ? (
      <Row className="logged">
        <Desc>{label}</Desc>
        <MidValue />
        <Value className="remaining">
          {budget.formatAmount(1, data.value)}
        </Value>
      </Row>
    ) : (
      <Row className="logged">
        <Desc>{label}</Desc>
        <MidValue>
          {isMoneyBudgetType &&
            !isUndefined(midValue) &&
            budget.formatAmount(1, midValue)}
        </MidValue>
        <Value>{budget.formatAmount(budget_type, data.value)}</Value>
      </Row>
    );

  return (
    <Container>
      <Header>{header}</Header>
      {loggedRow}
      {hasBudgetRemaining && (
        <Row>
          <Desc>Budget remaining</Desc>
          <Value className={`remaining ${isOverbudget ? 'negative' : ''}`}>
            {budget.formatAmount(budget_type, data.budget_remaining)}
          </Value>
        </Row>
      )}
    </Container>
  );
}

function CombinedTooltip(props) {
  if (typeof props.data.logged_hours !== 'undefined') {
    return <LoggedTooltip {...props} />;
  }

  if (props.data.scheduled_hours) {
    return <ScheduledTooltip {...props} defaultLabel="Total" />;
  }

  return null;
}

function CompareTooltip(props) {
  const {
    data,
    project: { budget_type, non_billable, isMoneyBudgetType },
  } = props;
  const header = moment(data.key).format('DD MMM YYYY');
  const rows = [];

  if (!data.values) return null;

  const midValue1 = data.logged_hours;
  const midValue2 = data.scheduled_hours;
  const value1 = data.values[1];
  const value2 = data.values[0];
  const isBudgetHourly = budget_type === 0;
  const budgetType = isBudgetHourly ? 1 : budget_type;

  if (!isUndefined(data.logged_hours)) {
    const loggedRow = (
      <Row key="logged" className="logged">
        <Desc>Logged</Desc>
        <MidValue>
          {isMoneyBudgetType &&
            !isUndefined(midValue1) &&
            budget.formatAmount(1, midValue1)}
        </MidValue>
        <Value>{budget.formatAmount(budgetType, value1)}</Value>
      </Row>
    );
    rows.push(loggedRow);
  }

  if (!isUndefined(data.scheduled_hours)) {
    const scheduledRow = (
      <Row key="scheduled">
        <Desc>{non_billable ? 'Scheduled' : 'Scheduled (billable)'}</Desc>
        <MidValue>
          {isMoneyBudgetType &&
            !isUndefined(midValue2) &&
            budget.formatAmount(1, midValue2)}
        </MidValue>
        <Value>{budget.formatAmount(budgetType, value2)}</Value>
      </Row>
    );
    rows.push(scheduledRow);
  }

  if (rows.length === 2) {
    const differenceRow = (
      <Row key="difference">
        <Desc>Difference</Desc>
        <MidValue>
          {isMoneyBudgetType &&
            !isUndefined(midValue2) &&
            !isUndefined(midValue1) &&
            budget.formatAmount(1, Math.abs(midValue1 - midValue2))}
        </MidValue>
        <Value className="remaining">
          {budget.formatAmount(
            isBudgetHourly ? 1 : budget_type,
            Math.abs(value1 - value2),
          )}
        </Value>
      </Row>
    );
    rows.push(differenceRow);
  }

  return (
    <Container>
      <Header>{header}</Header>
      {rows}
    </Container>
  );
}

const TippyContent = ({ data, mode, project }) => {
  if (mode === MODES.COMBINED) {
    return <CombinedTooltip data={data} project={project} />;
  }

  if (mode === MODES.LOGGED) {
    return <LoggedTooltip data={data} project={project} />;
  }

  if (mode === MODES.SCHEDULED) {
    return <ScheduledTooltip data={data} project={project} />;
  }

  if (mode === MODES.COMPARE) {
    return <CompareTooltip data={data} project={project} />;
  }

  return null;
};

export default (project, mode) => (props) => (
  <TippyContent {...props} project={project} mode={mode} />
);
