import { t } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { SupportEffort } from './types';

export const getGetToKnowYouConfig = () => {
  const withExampleData = featureFlags.isFeatureEnabled(
    FeatureFlag.OnboardingExampleData,
  );
  const isSignUpRevampEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.SignUpRevamp,
  );

  return {
    pageHeading: isSignUpRevampEnabled
      ? t`Your team’s needs`
      : t`Tell us your team's needs`,
    teamSizeHeading: isSignUpRevampEnabled
      ? t`How many people are on your team?`
      : t`How many people are you planning to schedule?`,
    teamSize: isSignUpRevampEnabled
      ? [
          { label: t`1 to 9`, data: '9' },
          { label: t`10 to 24`, data: '24' },
          { label: t`25 to 49`, data: '49' },
          { label: t`50+`, data: '50' },
        ]
      : [
          { label: t`1 to 10`, data: '10' },
          { label: t`11 to 100`, data: '100' },
          { label: t`101 to 250`, data: '250' },
          { label: t`251 to 1,000`, data: '1000' },
          { label: t`1,001+`, data: '1001' },
        ],
    supportEffortHeading: isSignUpRevampEnabled
      ? t`What best describes you?`
      : t`How would you like to get started?`,
    supportEffort: [
      {
        label: t`New to Float`,
        desc: t`Guide my setup`,
        data: SupportEffort.Low,
      },
      {
        label: t`Need an expert`,
        desc: t`A team member will email you`,
        data: SupportEffort.High,
      },
      {
        label: t`Already use Float`,
        desc: withExampleData
          ? t`Start with a blank Schedule`
          : t`I know what I'm doing`,
        data: SupportEffort.No,
      },
    ],
  };
};
