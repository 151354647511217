import { t } from '@lingui/macro';

import { updateProject } from '@float/common/actions';
import { getErrorMessageFromException } from '@float/common/lib/getErrorMessageFromException';
import { useAppDispatchStrict } from '@float/common/store';
import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';

import { PersonToSwitchIn } from '../../ModalSwitchPerson.types';

export type UseSwitchPersonConfirmProps = {
  person?: Person;
  project?: Project;
  newPerson?: PersonToSwitchIn;
  onSwitch?: (newPerson: PersonToSwitchIn) => void;
  onSwitchComplete: () => void;
};

export const useSwitchPersonConfirm = (props: UseSwitchPersonConfirmProps) => {
  const { newPerson, onSwitch, person, project, onSwitchComplete } = props;

  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatchStrict();

  const switchPerson = ({
    projectId,
    from,
    to,
    newRate,
  }: {
    projectId: Project['project_id'];
    from: Person['people_id'];
    to: Person['people_id'];
    newRate?: Person['default_hourly_rate'];
  }) => {
    if (newRate === undefined) {
      const patch = { project_team: { sub: { [from]: to } } };
      return dispatch(updateProject(projectId, patch));
    }

    const patch = {
      project_team: {
        sub: {
          [from]: { people_id: to, hourly_rate: newRate },
        },
      },
    };

    return dispatch(updateProject(projectId, patch));
  };

  const onClickConfirm = async () => {
    if (!newPerson?.personId || !project || !person) return;

    try {
      await switchPerson({
        projectId: project.project_id,
        from: person.people_id,
        to: newPerson.personId,
        newRate: newPerson.hourlyRate,
      });

      const name = project!.project_name;

      showSnackbar(t`${name} updated`);

      if (onSwitch) onSwitch(newPerson);
      if (onSwitchComplete) onSwitchComplete();
    } catch (error) {
      throw new Error(getErrorMessageFromException(error));
    }
  };

  return {
    onClickConfirm,
  };
};
