import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import type { CurrentUser, Person } from '@float/types';

export type UseStartTimerRightsParams = {
  currentLoggedTimePerson: Person | CurrentUser | undefined;
};

export function useStartTimerRights(params: UseStartTimerRightsParams) {
  const { currentLoggedTimePerson } = params;

  const currentUser = useAppSelectorStrict(getUser);

  // FT-1465
  const isLoggedTimeFromAnotherUser =
    currentUser.people_id !== currentLoggedTimePerson?.people_id;

  return {
    canStartTimer: !isLoggedTimeFromAnotherUser,
  };
}
