import {
  FilterMatcher,
  FiltersContext,
  FiltersEntity,
  FilterType,
} from '../../types';
import {
  getDeparmentsIdsByName,
  getPeopleIdsByJobTitle,
  getPeopleIdsByManager,
  getPeopleIdsByName,
  getPeopleIdsByTag,
  getPeopleIdsByType,
} from './lib/extractors';
import { matchString, matchTimeoffByStatus } from './lib/matchers';

const FORCE_MATCH = new Set([
  'project',
  'projectOwner',
  'projectTag',
  'client',
  'projectStatus',
  'phase',
  'task',
  'taskStatus',
]);

export class TimeoffsFilterMatcher implements FilterMatcher<'timeoffs'> {
  ids: Record<string, Set<number | string | undefined> | undefined> = {};
  type: FilterType;
  context: FiltersContext<'timeoffs'>;
  values: string[];
  partialMatch: boolean;
  forceMatch: boolean;

  constructor(
    context: FiltersContext<'timeoffs'>,
    type: FilterType,
    values: string[],
    partialMatch = false,
  ) {
    this.context = context;
    this.type = type;
    this.values = values;
    this.partialMatch = partialMatch;

    this.forceMatch = FORCE_MATCH.has(type);
  }

  private getIdsByValue(value: string) {
    switch (this.type) {
      case 'department': {
        return getDeparmentsIdsByName(this.context, value, this.partialMatch);
      }
      case 'jobTitle': {
        return getPeopleIdsByJobTitle(this.context, value, this.partialMatch);
      }
      case 'manager': {
        return getPeopleIdsByManager(this.context, value, this.partialMatch);
      }
      case 'me': {
        const people_id = this.context.user.people_id;
        return new Set<number>(people_id ? [people_id] : []);
      }
      case 'person': {
        return getPeopleIdsByName(this.context, value, this.partialMatch);
      }
      case 'personTag': {
        return getPeopleIdsByTag(this.context, value, this.partialMatch);
      }
      case 'personType': {
        return getPeopleIdsByType(this.context, value);
      }
      case 'timeoff':
      case 'timeoffStatus': {
        return new Set<number>();
      }
    }

    return new Set<number>();
  }

  matches(entity: FiltersEntity<'timeoffs'>): boolean {
    for (const value of this.values) {
      if (!this.ids[value]) {
        this.ids[value] = this.getIdsByValue(value);
      }

      if (this.matchesByValue(entity, value)) {
        return true;
      }
    }

    return false;
  }

  private matchesByValue(
    timeoff: FiltersEntity<'timeoffs'>,
    value: string,
  ): boolean {
    const ids = this.ids[value];

    if (!ids) return true;

    switch (this.type) {
      case 'timeoff': {
        if (timeoff.timeoff_type_name) {
          return matchString(
            timeoff.timeoff_type_name,
            value,
            this.partialMatch,
          );
        }
        const type = this.context.timeoffTypes[timeoff.timeoff_type_id];

        if (type?.timeoff_type_name) {
          return matchString(type.timeoff_type_name, value, this.partialMatch);
        }

        return false;
      }
      case 'timeoffStatus':
        return matchTimeoffByStatus(timeoff, this.context.user, value);
      case 'department': {
        if (timeoff.people_ids) {
          for (const id of timeoff.people_ids) {
            const person = this.context.people[id];

            if (person && ids.has(person.department_id)) return true;
          }
        }

        return false;
      }
      case 'jobTitle':
      case 'me':
      case 'manager':
      case 'person':
      case 'personTag':
      case 'personType': {
        if (timeoff.people_ids) {
          for (const id of timeoff.people_ids) {
            if (ids.has(id)) return true;
          }
        }

        return false;
      }
    }

    return true;
  }
}
