import React from 'react';

import {
  InputCurrencyString,
  Props as InputCurrencyStringProps,
} from '@float/ui/components/InputCurrency/InputCurrencyString';

import { useRateInput } from './useRateInput';

type RateInputProps = Omit<
  InputCurrencyStringProps,
  'currency' | 'currencySymbol' | 'locale'
>;

export function RateInput(props: RateInputProps) {
  const { isHoursBased = true, ...rest } = props;

  const { currency, currencySymbol, locale, shouldShowPerHourSuffix } =
    useRateInput({ isHoursBased });

  return (
    <InputCurrencyString
      {...rest}
      currency={currency}
      currencySymbol={currencySymbol}
      isHoursBased={shouldShowPerHourSuffix}
      locale={locale}
    />
  );
}
