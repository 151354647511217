import { REHYDRATE } from 'redux-persist';

import {
  PUBLIC_HOLIDAYS_LOAD_FAILURE,
  PUBLIC_HOLIDAYS_LOAD_START,
  PUBLIC_HOLIDAYS_LOAD_SUCCESS,
} from '../actions';

const REDUCER_NAME = 'publicHolidays';
const DEFAULT_STATE = {
  regions: [],
  loadState: 'UNLOADED',
  publicHolidaysLoaded: false,
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case PUBLIC_HOLIDAYS_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case PUBLIC_HOLIDAYS_LOAD_FAILURE: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case PUBLIC_HOLIDAYS_LOAD_SUCCESS: {
      return {
        ...state,
        loadState: 'LOADED',
        publicHolidaysLoaded: true,
        regions: action.response,
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.publicHolidaysLoaded
        ? 'LOADED'
        : 'UNLOADED';

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
}
