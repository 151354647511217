import { isCostRateFeatureActive } from '@float/common/lib/rates/rates';
import { selectCurrencyPropsRaw } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';

export function useRateInput({ isHoursBased }: { isHoursBased?: boolean }) {
  const currencyProps = useAppSelectorStrict(selectCurrencyPropsRaw);

  const shouldShowPerHourSuffix =
    Boolean(isHoursBased) && isCostRateFeatureActive();

  return { ...currencyProps, shouldShowPerHourSuffix };
}
