import { get } from 'lodash';

import { DEFAULT_AVATAR_URL } from '@float/constants/avatar';
import { config } from '@float/libs/config';
import { FloatStorageKey, storage } from '@float/libs/storage';

import { logout as intercomLogout } from './intercom';

export const replaceSubdomain = (
  url: string,
  newSubdomain: string,
  protocol = window.location.protocol,
) => {
  const newUrl = url.replace(/^[^.]*/, newSubdomain);
  return `${protocol}//${newUrl}`;
};

export const getSubdomainPath = (path = '', hostname?: string) => {
  if (config.isFeature) {
    return path;
  }

  if (hostname) {
    const currentSubdomain = window.location.hostname.split('.')[0];

    if (currentSubdomain === hostname) {
      return path;
    }

    const base = path
      ? `${window.location.protocol}//${window.location.host}`
      : window.location.href;
    return `${replaceSubdomain(base, hostname)}${path}`;
  }

  return path;
};

export const redirectToSubdomainIfNeeded = (
  hostname = '',
  replace = true,
  path = '',
) => {
  if (config.isFeature || config.isPreview) {
    return false;
  }

  hostname = String(hostname);

  if (hostname) {
    const currentSubdomain = window.location.hostname.split('.')[0];

    if (currentSubdomain.toLowerCase() !== hostname.toLowerCase()) {
      const base = path
        ? `${window.location.protocol}//${window.location.host}`
        : window.location.href;
      const newHref = `${replaceSubdomain(base, hostname)}${path}`;

      if (replace) {
        window.location.replace(newHref);
      } else {
        window.location.href = newHref;
      }
      return true;
    }
  }
  return false;
};

export const appendFilter = (
  filter: Record<string, string>,
  location: { pathname: string; query?: Record<string, string | number> },
) => {
  return {
    pathname: location.pathname,
    query: {
      ...get(location, 'query', {}),
      ...filter,
    },
  };
};

export const signOut = () => {
  if (config.isWebApp) {
    // to convey to other open tabs to sign out
    storage.setItem(FloatStorageKey.SignOutEvent, String(Math.random()));
    storage.setItem(FloatStorageKey.CurrentCompanyId, '');
  }

  intercomLogout();

  window.location.href = getSubdomainPath('/logout');
};

export const isPlaceholderAvatar = (avatar?: string) => {
  return !avatar || avatar === DEFAULT_AVATAR_URL;
};
