import { t } from '@lingui/macro';
import { intersection } from 'lodash';

import { Account } from '@float/types/account';
import { Person } from '@float/types/person';

import { AccountWithAccess } from '../../PersonModal/access/PersonAccess.types';
import Manages from '../Manages';

export function getBulkEditManagesField() {
  return {
    label: t`Manages`,
    render: Manages,
    getPayloadFromState: (data: Account) => {
      return data.management_group;
    },
    getDefaultValue: (entity: Person) => {
      const value: { departments?: number[]; people?: number[] } = {
        ...(entity.account?.management_group || {
          departments: [],
          people: [],
        }),
      };
      delete value.people;

      return {
        management_group: value,
      };
    },
    handleMismatch: {
      management_group: (
        initialEntity: Person,
        currentEntity: Person,
        initialValue: Partial<AccountWithAccess>,
      ) => {
        const left =
          initialEntity?.account?.management_group?.departments || [];
        const right =
          currentEntity?.account?.management_group?.departments || [];

        initialValue.management_group = {
          departments: intersection(left, right),
          people: [],
        };
      },
    },
  };
}
