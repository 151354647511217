// Remember to use the "Remove Feature Flag" template when adding a new feature flag task in Linear
export enum FeatureFlag {
  LinearIntegration = 'linear-integration',
  OnboardingExampleData = 'onboarding-example-data',
  OnboardingWelcomeVideo = 'onboarding-welcome-video',
  PlaceholdersPackaging = 'placeholders-packaging',
  ProjectBudgetByTasks = 'project-budget-by-tasks',
  RolesRates = 'role-rates',
  ScheduleDataWindowing = 'schedule-data-windowing',
  SearchBeyondLimits = 'search-beyond-limits',
  SearchBeyondLimitsMinQueryLength = 'search-beyond-limits-min-query-length',
  SearchBeyondLimitsNewAlg = 'search-beyond-limits-new-alg',
  SearchBeyondLimitsDisableRevalidation = 'search-beyond-limits-disable-revalidation',
  SearchBeyondLimitsDisableProjectSkipFieldsForPeopleFiltering = 'disable-project-skip-fields-for-people-filtering-on-sbl', // Jan 17, 2025 – Haakon Jack – https://linear.app/float-com/issue/PI-444/remove-disable-project-skip-fields-for-people-filtering-on-sbl
  TagGroups = 'tag-groups',
  TargetedDebugLogs = 'rollbar-debug',
  AllocationByTotalHours = 'allocation-by-total-hours',
  WebSocketMonitoring = 'websocket-monitoring',
  AllocationByAvailabilityPercentage = 'allocation-by-availability-percentage',
  EditTaskModalAssigneesFieldTotalHoursUpdate = 'edit-task-modal-assignees-field-total-hours-update',
  LightOnboarding = 'activation-squad-light-onboarding',
  PasswordStrengthMeter = 'activation-squad-password-strength-meter',
  ProjectCodes = 'project-codes',
  AllocationByTimeframe = 'allocation-by-timeframe',
  ActivationSquadHidePhasesInProjectSidePanel = 'activation-squad-hide-phases-in-project-side-panel',
  SingleProjectView = 'single-project-view',
  AggregateTagAndStatusFilteringForProjects = 'aAggregate-tag-and-status-filtering-for-projects', // Jan 13, 2025 – Haakon Jack - feature/pi-442-remove-aaggregate-tag-and-status-filtering-for-projects
  // Note: "'aAggregate-tag-and-status-filtering-for-projects" is not a typo, launch darkly generated it that way :/
  MobileOfflineSupport = 'mobile-offline-support', // Jan 29, 2025 – Danilo Figueiredo – https://linear.app/float-com/issue/PLTAPP-153/remove-mobile-offline-support
  CostRate = 'cost-rate', // Feb 8, 2025 - Sameet - https://linear.app/float-com/issue/BB-343/remove-cost-rate-feature-flag
  EstimateCapture = 'estimate-capture', // Feb 24, 2025 - Danilo Figueiredo - https://linear.app/float-com/issue/COR-397/remove-estimate-capturefeature-flag
  WeekGrid = 'week-grid', // Date N/A - Gevorg Harutyunyan – https://linear.app/float-com/issue/BB-286/remove-ff-week-grid
  SignUpRevamp = 'signup-revamp', // Mar 14 - Natan Schling – https://linear.app/float-com/issue/ACT-174/remove-feature-flag-and-clean-up-dead-code
  CostRatesMultiProjectReport = 'cost-rates-multi-project-report', // Mar 20, 2025 - Jason Keum - https://linear.app/float-com/issue/COR-434/profit-margin-updates-for-multi-project-reports-view
}

export const ALL_FEATURE_FLAGS = Object.values(FeatureFlag);

export const FEATURE_FLAG_VARIANTS = [
  'on',
  'gentle-refresh',
  'strong-refresh',
  'off',
] as const;

// Add here the feature flags that aren't actively being used and are meant to
// be deprecated soon
export const FEATURE_FLAGS_TO_DEPRECATE = [
  FeatureFlag.OnboardingExampleData,
  FeatureFlag.OnboardingWelcomeVideo,
  FeatureFlag.LightOnboarding,
  FeatureFlag.ActivationSquadHidePhasesInProjectSidePanel,
];

// Add here the permanent feature flags
export const FEATURE_FLAGS_PERMANENT = [FeatureFlag.TargetedDebugLogs];

// Add here the feature flags that are exclusive to mobile platforms
export const FEATURE_FLAGS_MOBILE = [FeatureFlag.MobileOfflineSupport];

// See "Debug Code in Production" in the `float-javascript` github wiki
// https://github.com/floatschedule/float-javascript/wiki/Customer-Issues#2-debug-code-in-production
export enum TargetedDebugLogFlagVariants {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  PerformanceMonitoring = 'performanceMonitoring',
  // Extend as needed with variations names
}

export const LAUNCH_DARKLY_CLIENT_ID = {
  production: '634d5f3676538411510c5c28',
  staging: '64ef1f0a1398ce1235c3a3b7',
  develop: '634d5f3676538411510c5c27',
} as const;
