import { get } from 'lodash';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { SEARCH_DERIVE_CONTEXT } from '../../actions/search';
import { SearchWorkerReduxState } from './worker/searchStore';
import type { AllActionsStrict } from '../../reducers';

const MONITORED_STORE_KEYS = [
  'currentUser.savedSearches',
  'currentUser.account_id',
  'currentUser.department_filter',
  'currentUser.account_type_id',
  'currentUser.people_id',
  'people.people',
  'departments.departments',
  'timeoffs.timeoffs',
  'projects.projects',
  'phases.phases',
  'clients.clients',
  'tasks.tasks',
  'loggedTimes.loggedTimes',
];

export const dispatchDeriveSearchContextAction = (
  store: MiddlewareAPI<Dispatch<AllActionsStrict>, SearchWorkerReduxState>,
) => {
  return store.dispatch({
    type: SEARCH_DERIVE_CONTEXT,
    fullState: store.getState(),
  });
};

export const deriveContextMiddleware: (
  debounceInterval: number,
) => Middleware<
  Dispatch<AllActionsStrict>,
  SearchWorkerReduxState,
  Dispatch<AllActionsStrict>
> = (debounceInterval: number) => {
  let currentTm: ReturnType<typeof setTimeout>;
  let needsDerive = false;

  const compareAndDerive =
    (
      currentStore: MiddlewareAPI<
        Dispatch<AllActionsStrict>,
        SearchWorkerReduxState
      >,
    ) =>
    () => {
      dispatchDeriveSearchContextAction(currentStore);
      needsDerive = false;
    };

  return (store) => (next) => async (action) => {
    const stateBefore = store.getState();
    const res = await next(action);
    if (currentTm) {
      clearTimeout(currentTm);
    }
    const stateAfter = store.getState();

    needsDerive =
      needsDerive ||
      MONITORED_STORE_KEYS.some((key) => {
        return get(stateBefore, key) !== get(stateAfter, key);
      });

    if (needsDerive) {
      currentTm = setTimeout(compareAndDerive(store), debounceInterval);
    }

    return res;
  };
};
