import { isEmpty, pickBy } from 'lodash';
import { getEmptyTaskLabel } from 'reports/TeamCapacity/parser/table/getEmptyTaskLabel';

import { toCents } from '@float/common/lib/budget';
import type { ProjectBudgetType } from '@float/constants/projects';
import type { Phase } from '@float/types/phase';
import type { RawTableData } from '@float/web/reports/useReportsStateReducer';

import type { ParseTableContext } from '../../table.types';

type TotalsByPhaseItem = {
  scheduled: number;
  feeCents: number;
  costCents: number;
  logged: {
    scheduled: number;
    feeCents: number;
    costCents: number;
  };
  future: {
    scheduled: number;
    feeCents: number;
    costCents: number;
  };
};

export type TotalsByPhase = {
  budget_type: ProjectBudgetType;
  budget: number | null;
  children: Record<string, TotalsByPhaseItem>;
} & TotalsByPhaseItem;

function getBlankPhase() {
  return {
    budget_type: 0,
    budget: 0,
    scheduled: 0,
    feeCents: 0,
    costCents: 0,
    logged: {
      scheduled: 0,
      feeCents: 0,
      costCents: 0,
    },
    future: {
      scheduled: 0,
      feeCents: 0,
      costCents: 0,
    },
    children: {},
  };
}

export function getTotalsByPhase(ctx: ParseTableContext, raw: RawTableData) {
  const { people, phases } = ctx;
  let byPhase: Record<Phase['phase_id'], TotalsByPhase> = {};

  Object.entries(raw.phases).forEach(([phaseId, phase]) => {
    const reduxPhase = phases[parseInt(phaseId)];
    if (!reduxPhase) return;

    byPhase[parseInt(phaseId)] = {
      ...getBlankPhase(),
      ...(raw.budgets[reduxPhase.project_id] && {
        budget_type: raw.budgets[reduxPhase.project_id].type,
      }),
      budget: phase.budget,
    };
  });

  raw.totals.forEach((item) => {
    if (item.type !== 'task' && item.type !== 'logged_time') return;

    if (!people[item.person_id]) {
      console.error('No person found', item);
      return;
    }

    if (!!item.billable !== !!ctx.billable) return;

    if (!item.name) {
      item.name = getEmptyTaskLabel();
    }

    if (!item.phase_id && !byPhase[0]) {
      byPhase[0] = getBlankPhase();
    }
    const phase = byPhase[item.phase_id];

    // record may be missing if the phase has not been loaded
    // e.g. when the team has reached the MAX_LIMIT on phases
    // https://linear.app/float-com/issue/CS-1077/team-106838-unable-to-access-the-project-report
    if (!phase) {
      console.warn(`Phase with the id ${item.phase_id} is missing`);
      return;
    }

    if (!phase.children[item.name]) {
      phase.children[item.name] = {
        scheduled: 0,
        feeCents: 0,
        costCents: 0,
        logged: {
          scheduled: 0,
          feeCents: 0,
          costCents: 0,
        },
        future: {
          scheduled: 0,
          feeCents: 0,
          costCents: 0,
        },
      };
    }

    const child = phase.children[item.name];

    const isTask = item.type === 'task';
    const isLoggedtime = item.type === 'logged_time';

    // Skip logged time if it's outside the logged time boundary
    if (isLoggedtime && item.date && item.date >= ctx.loggedTimeBoundary) {
      return;
    }

    const itemHours = item.hours.scheduled;
    const itemHoursFuture = item.hours.future;
    const itemFeeCents = toCents(item.rate) * item.hours.scheduled;
    const itemFeeFutureCents = toCents(item.rate) * item.hours.future;

    const itemCostCents = toCents(item.cost?.scheduled ?? 0);
    const itemCostFutureCents = toCents(item.cost?.future ?? 0);

    if (isTask) {
      phase.scheduled += itemHours;
      phase.feeCents += itemFeeCents;
      phase.costCents += itemCostCents;

      phase.future.scheduled += itemHoursFuture;
      phase.future.feeCents += itemFeeFutureCents;

      phase.future.costCents += itemCostFutureCents;

      child.scheduled += itemHours;
      child.feeCents += itemFeeCents;
      child.costCents += itemCostCents;

      child.future.scheduled += itemHoursFuture;
      child.future.feeCents += itemFeeFutureCents;
      child.future.costCents += itemCostFutureCents;
    }

    if (isLoggedtime) {
      phase.logged.scheduled += itemHours;
      phase.logged.feeCents += itemFeeCents;
      phase.logged.costCents += itemCostCents;

      child.logged.scheduled += itemHours;
      child.logged.feeCents += itemFeeCents;
      child.logged.costCents += itemCostCents;
    }
  });

  byPhase = pickBy(byPhase, (val, phaseId) => {
    const reduxPhase = phases[parseInt(phaseId)];

    return (
      !isEmpty(val.children) || !reduxPhase?.non_billable === !!ctx.billable
    );
  });

  return byPhase;
}
