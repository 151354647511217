import React from 'react';
import { t } from '@lingui/macro';

import { Project } from '@float/types/project';
import { MeatballMenu } from '@float/ui/components/MeatballMenu';
import { IconCalendarTimer } from '@float/ui/icons/essentials/IconCalendarTimer';
import { IconCopy } from '@float/ui/icons/essentials/IconCopy';
import { IconDownload } from '@float/ui/icons/essentials/IconDownload';
import { IconExport } from '@float/ui/icons/essentials/IconExport';
import { IconFilePlus } from '@float/ui/icons/essentials/IconFilePlus';
import { IconPrint } from '@float/ui/icons/essentials/IconPrint';
import { IconReport } from '@float/ui/icons/essentials/IconReport';
import { IconShare } from '@float/ui/icons/essentials/IconShare';

import { useSingleProjectMenu } from './SingleProjectMenu.hooks';

import * as styles from './styles.css';

export const SingleProjectMenu = ({ project }: { project: Project }) => {
  const {
    showDuplicateModal,
    showShiftTimelineModal,
    viewProjectReport,
    showShareLinkModal,
    showExportCSVModal,
    archiveProject,
    createTemplateFromProject,
    printSingleProjectView,
  } = useSingleProjectMenu();

  const items = [
    {
      value: t`View project report`,
      icon: IconReport,
      onClick: () => viewProjectReport(project.project_name),
    },
    {
      value: t`Duplicate`,
      icon: IconCopy,
      onClick: () => showDuplicateModal(project),
    },
    {
      value: t`Shift timeline`,
      icon: IconCalendarTimer,
      onClick: () => showShiftTimelineModal(project),
    },
    {
      value: t`Create template from project`,
      icon: IconFilePlus,
      onClick: () => createTemplateFromProject(project.project_id),
    },
    {
      value: t`Share a link`,
      icon: IconShare,
      onClick: () => showShareLinkModal(),
    },
    {
      value: t`Export (.CSV for Excel)`,
      icon: IconExport,
      onClick: () => showExportCSVModal(),
    },
    {
      value: t`Print`,
      icon: IconPrint,
      onClick: () => printSingleProjectView(),
    },
    {
      value: t`Archive`,
      icon: IconDownload,
      onClick: () => archiveProject(project.project_id),
      disabled: Boolean(!project.active),
    },
  ];
  return (
    <MeatballMenu
      className={styles.projectMenuButton}
      items={items}
      trigger={{ label: t`Project menu`, iconSize: 26 }}
      vertical
      sideOffset={5}
    />
  );
};
