import React, { useCallback, useMemo } from 'react';

import { useAppSelectorStrict } from '@float/common/store';
import { CurrentUser } from '@float/types';
import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import {
  getDepartmentOptions,
  getDepartments,
  getSubdepartments,
  getUser,
} from '@float/web/selectors';

import { DepartmentsAccessProps } from './types';

function canSeeDepartmentId(user: CurrentUser, depId: number) {
  if (!user.department_filter_all) return true;

  const canSeeAllDepartments = !user.department_filter_all.length;

  return canSeeAllDepartments || user.department_filter_all.includes(depId);
}

export function useDepartmentsAccessController(props: DepartmentsAccessProps) {
  const { account, formErrors, onChange } = props;

  const user = useAppSelectorStrict(getUser);
  const departments = useAppSelectorStrict(getDepartments);
  const departmentOptions = useAppSelectorStrict(getDepartmentOptions);
  const subDepartments = useAppSelectorStrict(getSubdepartments);

  const userDepartmentsIds = useMemo(
    () => account.department_filter || [],
    [account.department_filter],
  );

  const selectedDepartments = useMemo(
    () =>
      userDepartmentsIds
        .filter((depId) => canSeeDepartmentId(user, depId))
        .map((depId) => {
          // if the department name is not found is because it's a new department so
          // we fallback to the depId which will be the name of the department
          const label = departments[depId]?.name || depId;

          return {
            value: depId,
            label,
          };
        }),
    [departments, user, userDepartmentsIds],
  );

  const options = useMemo(() => {
    // if a parent department is selected, we shall hide sub-departments from the list
    const subDepartmentsToDelete = new Set();

    if (account.department_filter) {
      account.department_filter.forEach((depId) => {
        if (subDepartments[depId]) {
          subDepartments[depId].forEach((subDepId) => {
            subDepartmentsToDelete.add(subDepId);
          });
        }
      });
    }

    return departmentOptions
      .filter((option) => !subDepartmentsToDelete.has(option.value))
      .map((option) => {
        const department = departments[option.value];
        return {
          ...option,
          icon: department.parent_id && <IconArrowDownRight size={16} />,
        };
      });
  }, [
    account.department_filter,
    departments,
    departmentOptions,
    subDepartments,
  ]);

  const handleAdd = useCallback(
    (o: { value: number }) => {
      onChange({
        department_filter: [...userDepartmentsIds, o.value],
      });
    },
    [userDepartmentsIds, onChange],
  );

  const handleRemove = useCallback(
    (o: { value: number }) => {
      onChange({
        department_filter: userDepartmentsIds.filter((id) => id !== o.value),
      });
    },
    [userDepartmentsIds, onChange],
  );

  return {
    errors: formErrors?.department_filter,
    value: selectedDepartments,
    options,
    handleAdd,
    handleRemove,
  };
}
