import { trackEvent } from '@float/common/lib/analytics';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { AccountType } from '@float/types/account';
import { PageData } from '@float/types/pageData';

const EXPERIMENTS = {
  hidePhasesExperiment: {
    /*
     * "Hide Phases" experiment to test hiding project phases in the side panel.
     */
    featureFlag: FeatureFlag.ActivationSquadHidePhasesInProjectSidePanel,
    id: 'ACTEX-2',
    name: 'Hide Phases',
  },
  signUpRevamp: {
    /*
     * "Sign up Revamp" consists in UX & UI updates to the sign up flow.
     */
    featureFlag: FeatureFlag.SignUpRevamp,
    id: 'ACTEX-X',
    name: 'SignUp Revamp',
  },
} as const;

/**
 * Tracks multiple experiments based on their feature flag status.
 *
 * This function iterates through the configured experiments and sends tracking
 * events for each enabled feature flag. Events are only tracked for users with
 * the AccountOwner role.
 *
 * @param {PageData['user']} user - The user object containing account and role information
 *
 * @example
 * // Will track all enabled experiments for an account owner
 * trackExperiments(user);
 *
 */
export const trackExperiments = (user: PageData['user']) => {
  if (user.user.account_tid !== AccountType.AccountOwner) {
    return;
  }

  Object.values(EXPERIMENTS).forEach(({ id, name, featureFlag }) => {
    if (featureFlags.isFeatureEnabled(featureFlag)) {
      trackEvent('Experiment Viewed', {
        experiment_id: id,
        experiment_name: name,
        team_id: user.user.cid,
      });
    }
  });
};
