import React, { memo, useMemo } from 'react';

import { FilterOperators } from '@float/types/filters';
import { VirtualFilterTypes } from '@float/types/view';
import { ToggleGroup } from '@float/ui/deprecated/Toggle/ToggleGroup';
import { isFilterCombinationForbidden } from '@float/web/components/SearchFilterDropdown/helpers/filterCombinationValidation';

type SearchFilterToggleIsNotProps = {
  onChange: (value: FilterOperators | '') => void;
  operator: FilterOperators | '';
  showSelectedOnly?: boolean;
  type: VirtualFilterTypes;
  value: string | string[];
};

const operatorOptions: { label: string; value: FilterOperators | '' }[] = [
  { label: 'is', value: '' },
  { label: 'is not', value: '-' },
];

const SearchFilterToggleIsNot = memo((props: SearchFilterToggleIsNotProps) => {
  const { operator, showSelectedOnly = false, onChange, type, value } = props;

  const options = useMemo(() => {
    const baseOptions = showSelectedOnly
      ? operatorOptions.filter((x) => !!x.value === !!operator)
      : operatorOptions;

    return baseOptions.map((option) => ({
      ...option,
      disabled: isFilterCombinationForbidden({
        type,
        value: value,
        operator: option.value,
      }),
    }));
  }, [operator, showSelectedOnly, type, value]);

  const onChangeHandler = (value: FilterOperators | '' | undefined) => {
    if (value !== undefined) onChange(value);
  };

  return (
    <ToggleGroup
      appearance="stitched"
      color="flay"
      disableUnselect
      onChange={onChangeHandler}
      options={options}
      size="tiny"
      type="single"
      value={operator || ''}
    />
  );
});

export { SearchFilterToggleIsNot };
