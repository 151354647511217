import { useEffect } from 'react';

import { useAppSelectorStrict } from '@float/common/store';

export const useBodyLoadedClass = () => {
  const appLoaded = useAppSelectorStrict((state) => state.app.loaded);

  useEffect(() => {
    if (appLoaded) {
      document.body.classList.add('loaded');
    } else {
      document.body.classList.remove('loaded');
    }

    return () => {
      document.body.classList.remove('loaded');
    };
  }, [appLoaded]);
};
