import { getBulkEditCostRateConfig } from '@float/web/components/elements/BulkEditModal/BulkEditCostRate';

import { getCostRateChangeConfirmData } from '../Rate.helpers';

export function getBulkEditCostRateField() {
  return {
    ...getBulkEditCostRateConfig(),
    getConfirmData: getCostRateChangeConfirmData,
  };
}
