import { ModalManagerState } from '@float/web/modalManager/types';
import type { ProjectsState } from '@float/common/reducers/projects';
import type { Project } from '@float/types/project';
import type { PromptId } from '@float/types/prompts';
import type { OnboardingManagerState } from '@float/web/store/onboardingManager/types';

export const getPromptsUserHasDone = (state: {
  onboardingManager: OnboardingManagerState;
}) => state.onboardingManager?.promptsUserHasDone;

export const isPromptDone = (
  state: { onboardingManager: OnboardingManagerState },
  id: PromptId,
) => Boolean(state.onboardingManager?.promptsUserHasDone?.includes(id));

export const getNavigateToPrompt = (state: {
  onboardingManager: OnboardingManagerState;
}) => state.onboardingManager?.navigateToPrompt;

export const getPromptData = (state: {
  onboardingManager: OnboardingManagerState;
}) => state.onboardingManager?.promptData;

export const getLastUpdatedProject = (state: {
  lastUpdated: { project: Project };
}) => state.lastUpdated.project;

export const getAllProjects = (state: { projects: ProjectsState }) =>
  state.projects.projects;

export const getModalManagerState = (state: {
  modalManager: ModalManagerState;
}) => state.modalManager;
