const formatterCache = new Map<string, Intl.NumberFormat>();

export const getCurrencySymbol = (locale: string, currency: string) => {
  // Generate a unique key for the locale-currency pair
  const cacheKey = `${locale}-${currency}`;

  // Check if the formatter already exists in the cache
  if (!formatterCache.has(cacheKey)) {
    // Create and store the formatter if it doesn't exist
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    });
    formatterCache.set(cacheKey, formatter);
  }

  // Retrieve the cached formatter
  const formatter = formatterCache.get(cacheKey)!;

  // Extract and return the currency symbol
  const parts = formatter.formatToParts(0);
  const symbol = parts.find((part) => part.type === 'currency');

  return symbol?.value;
};

// @test-export
export const getFormatterCache = () => {
  return formatterCache;
};

// @test-export
export const clearFormatterCache = () => {
  formatterCache.clear();
};
