/**
 * The version of useReportsFetchUrl implemented using the Search Beyond Limits
 * services instead of the local search data
 */

import { useState } from 'react';
import { dequal as isEqual } from 'dequal';
import useCustomCompareEffect from 'react-use/esm/useCustomCompareEffect';

import { selectPeopleManagedByAccounts } from '../selectors/people/managers';
import { selectIsMeFilterActive } from '../selectors/search';
import { getActiveFilters } from '../selectors/views';
import { useAppSelectorStrict, useAppStoreStrict } from '../store';
import { buildReportsURL } from './selectors/getReportsFetchUrl/buildReportsURL';
import { getReportQueryFromFiltersRemote } from './selectors/getReportsFetchUrl/getReportQueryFromFiltersRemote';
import { ReportQuerySettings } from './selectors/getReportsFetchUrl/types';

export function useReportsFetchUrlRemote(
  settings: ReportQuerySettings,
  baseUrl: string,
  enabled: boolean,
) {
  const activeFilters = useAppSelectorStrict(getActiveFilters);
  const isMeFilterActive = useAppSelectorStrict(selectIsMeFilterActive);

  const store = useAppStoreStrict();

  const [url, setUrl] = useState<string | null>(null);

  // Using a deep comparison on the args array to call the APIs
  // only when the values are changing
  useCustomCompareEffect(
    () => {
      if (enabled === false) return;

      let cancelled = false;

      async function main() {
        const reportQuery = await getReportQueryFromFiltersRemote(
          activeFilters,
          settings,
          isMeFilterActive,
          {
            getPeopleManagedByAccounts(ids) {
              return selectPeopleManagedByAccounts(store.getState(), ids);
            },
          },
        );

        if (cancelled) return;

        setUrl(buildReportsURL(baseUrl, reportQuery));
      }

      main();

      return () => {
        cancelled = true;
      };
    },
    [activeFilters, settings, baseUrl, isMeFilterActive, enabled],
    isEqual,
  );

  return enabled ? url : null;
}
