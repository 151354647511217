import React from 'react';
import { createPortal } from 'react-dom';

import { useWebAppSelectorStrict } from '../lib/store';
import { getOnboardingManagerStatus } from '../store/onboardingManager/selectors/manager';
import { useExcludePromptsForNewUsers } from './hooks/useExcludePromptsForNewUsers';
import { OnboardingViewManager } from './OnboardingViewManager';

export const OnboardingManager = () => {
  const onboardingStatus = useWebAppSelectorStrict(getOnboardingManagerStatus);

  useExcludePromptsForNewUsers();

  if (
    onboardingStatus.active &&
    (!onboardingStatus.completed || onboardingStatus.replaying)
  ) {
    return createPortal(<OnboardingViewManager />, document.body);
  }

  return null;
};
