import React, { memo } from 'react';

import * as styles from './styles.css';

export const DensitySpacer = memo(
  ({ density }: { density: 'default' | 'compact' }) => (
    <div
      className={styles.densitySpacer[density]}
      data-testid="density-spacer"
    />
  ),
);
