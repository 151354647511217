import React from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import * as styles from './styles.css';

export const SideCellShadowLayer = ({
  totalHeight,
}: {
  totalHeight: number;
}) => {
  return (
    <div
      className={styles.sideCellShadowLayer}
      style={assignInlineVars({
        [styles.totalHeightVar]: `${totalHeight}px`,
      })}
    />
  );
};
