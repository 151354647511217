import { useHistory } from 'react-router-dom';
import { t } from '@lingui/macro';

import { IconReport } from '@float/ui/icons/essentials/IconReport';
import { useSidePanel } from '@float/web/sidePanel/useSidePanel';

import { useProject } from './useProject';

export function useProjectReportMenuItem() {
  const project = useProject();
  const history = useHistory();
  const { hideCurrentPanelWithWarning } = useSidePanel();

  const navigateToProjectReport = (projectName: string) => {
    history.push(`/report?project=${encodeURIComponent(projectName)}`);
  };

  const handleClick = () => {
    const projectName = project?.project_name;
    if (!projectName) return;

    hideCurrentPanelWithWarning();

    navigateToProjectReport(projectName);
  };

  return {
    value: t`View project report`,
    icon: IconReport,
    onClick: handleClick,
  };
}
