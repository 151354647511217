// The order of items in this array controls the order of categories that

import { t } from '@lingui/macro';

import { SearchResolveFilterSpecialValue } from '@float/common/api3/search.constants';
import { SEARCH_CATEGORY_TO_FILTER_TYPE_MAPPING } from '@float/constants/search';
import type { SearchCategory } from '@float/constants/search';
import type { LegacyFilterType, VirtualFilterTypes } from '@float/types';

import { normalize } from '.';
import type { SearchAutocompleteQueryItem } from '../selectors/getSearchAutocompleteResults';

export const PERSON_RELATED_KEYS = [
  'departments',
  'people',
  'managers',
  'personTags',
  'personTypes',
  'jobTitles',
  'roles',
];

export const PERSON_RELATED_TYPES = PERSON_RELATED_KEYS.map(
  (k) => SEARCH_CATEGORY_TO_FILTER_TYPE_MAPPING[k as SearchCategory],
) as string[];

export const TIMEOFF_RELATED_KEYS = ['timeoffs', 'timeoffStatuses'];

export const TIMEOFF_RELATED_TYPES = TIMEOFF_RELATED_KEYS.map(
  (k) => SEARCH_CATEGORY_TO_FILTER_TYPE_MAPPING[k as SearchCategory],
) as string[];

export const FRIENDLY_NAMES = {
  clients: 'Clients',
  contains: 'Contains',
  departments: 'Departments',
  jobTitles: 'Roles',
  people: 'People',
  managers: 'Managers',
  personTags: 'Person tag',
  personTypes: 'Person type',
  projectStatuses: 'Project status',
  projectTags: 'Project tags',
  projects: 'Projects',
  projectOwners: 'Project owners',
  phases: 'Phases',
  savedSearches: 'Saved',
  tasks: 'Tasks',
  taskStatuses: 'Allocation status',
  timeoffs: 'Time offs',
  timeoffStatuses: 'Time off status',
  me: 'Me',
};

export const LEGACY_FILTER_TYPE_TO_VIRTUAL_FILTER_TYPE_MAPPING: Record<
  LegacyFilterType,
  VirtualFilterTypes
> = {
  contains: 'contains',
  people: 'person',
  project: 'project',
  'person tag': 'personTag',
  person_type: 'personType',
  department: 'department',
  'job title': 'jobTitle',
  client: 'client',
  timeoff: 'timeoff',
  task: 'task',
  task_status: 'taskStatus',
  'task status': 'taskStatus',
  timeoffStatus: 'timeoffStatus',
  tag: 'projectTag',
  project_status: 'projectStatus',
  projectOnwer: 'projectOwner',
};

export const LEGACY_FILTER_TYPES = Object.keys(
  LEGACY_FILTER_TYPE_TO_VIRTUAL_FILTER_TYPE_MAPPING,
) as LegacyFilterType[];

export const ONLY_INITIAL_QUERY = ['view', 'timetype'];

// The search service uses a different set of ids for the static values
// @see https://linear.app/float-com/issue/PS-1569/bug-time-off-status-is-not-working-as-expected#comment-7b2baa5f
export enum TimeoffStatusSearchId {
  APPROVED = 1,
  DECLINED = 2,
  TENTATIVE = 3,
}

const DEPARTMENT_ID_FOR_NONE = -1;
const JOB_TITLE_ID_FOR_NONE = -1;
const PROJECT_ID_FOR_NONE = -1;

/**
 * Locally defined filters that are added to the remote
 * results.
 *
 * NOTE: This needs to be a function for translations to work.
 */
export const getLocalFilters = (): SearchAutocompleteQueryItem[] => [
  {
    type: 'task' as const,
    val: t`No task used`,
    lookupValue: '',
    normalizedVal: normalize(t`No task used`),
  },
  {
    type: 'timeoffStatus' as const,
    val: t`Declined`,
    id: TimeoffStatusSearchId.DECLINED,
    normalizedVal: normalize(t`Declined`),
  },
  {
    type: 'timeoffStatus' as const,
    val: t`Approved`,
    id: TimeoffStatusSearchId.APPROVED,
    normalizedVal: normalize(t`Approved`),
  },
  {
    type: 'timeoffStatus' as const,
    val: t`Tentative`,
    id: TimeoffStatusSearchId.TENTATIVE,
    normalizedVal: normalize(t`Tentative`),
  },
  {
    type: 'timeoffStatus' as const,
    val: t`Confirmed`,
    // The search service doesn't have the knowledge of Confirmed status
    id: TimeoffStatusSearchId.APPROVED,
    normalizedVal: normalize(t`Confirmed`),
  },
  {
    type: 'timeoff' as const,
    val: SearchResolveFilterSpecialValue.Any,
    normalizedVal: normalize(t`Any (All Types)`),
    sortVal: '*',
    isActive: true,
  },
  {
    type: 'department' as const,
    val: SearchResolveFilterSpecialValue.None,
    id: DEPARTMENT_ID_FOR_NONE,
    sortVal: '*',
    normalizedVal: normalize(t`None`),
  },
  {
    type: 'jobTitle' as const,
    val: SearchResolveFilterSpecialValue.None,
    id: JOB_TITLE_ID_FOR_NONE,
    sortVal: '*',
    normalizedVal: normalize(t`None`),
  },
  {
    type: 'project' as const,
    val: SearchResolveFilterSpecialValue.None,
    id: PROJECT_ID_FOR_NONE,
    sortVal: '*',
    normalizedVal: normalize(t`None`),
    isActive: true,
  },
];
