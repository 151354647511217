import React from 'react';
import { Trans } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { SingleProjectSortColumn } from '@float/types/account';
import { Project } from '@float/types/project';

import { HeaderColumn } from './HeaderColumn';

import * as projectCardStyles from '../ProjectCard.css';
import * as styles from './SingleProjectHeader.css';

export const SingleProjectHeaders = ({
  project,
  rowHeight,
}: {
  project: Project;
  rowHeight: number;
}) => {
  const projectColor = project.color.startsWith('#')
    ? project.color
    : `#${project.color}`;
  return (
    <div className={projectCardStyles.wrapper} data-interactive={true}>
      <div
        className={styles.headerContent}
        style={assignInlineVars({
          [styles.contentBgColorVar]: projectColor,
          [styles.contentHeightVar]: `${rowHeight}px`,
        })}
      >
        <HeaderColumn
          column={SingleProjectSortColumn.Name}
          className={styles.teamMemberHeader}
        >
          <Trans>Person</Trans>
        </HeaderColumn>
        <div className={styles.roleColumn}>
          <Trans>Role</Trans>
        </div>
        <div className={styles.costRateColumn}>
          <Trans>
            Cost <span className={styles.suffix}>/hr</span>
          </Trans>
        </div>
        <HeaderColumn
          column={SingleProjectSortColumn.HourlyRate}
          className={styles.billRateHeader}
        >
          <Trans>
            Bill<span className={styles.suffix}>/hr</span>
          </Trans>
        </HeaderColumn>
        <div className={styles.allocationColumn}>
          <Trans>Allocation</Trans>
        </div>
        <div className={styles.actionsColumn} />
      </div>
    </div>
  );
};
