import { LoadState, ReduxStateStrict } from '@float/common/reducers/lib/types';
import { TagGroup } from '@float/types';

import API3 from '../api3';
import { AppDispatchStrict } from '../store';

export const TAGS_GROUPS_LOAD_STARTED = 'tagsGroups/LOAD_STARTED';
export const TAGS_GROUPS_LOAD_FAILED = 'tagsGroups/LOAD_FAILED';
export const TAGS_GROUPS_LOAD_FINISHED = 'tagsGroups/LOAD_FINISHED';
export const TAGS_GROUPS_UPDATED = 'tagsGroups/UPDATED';
export const TAGS_GROUPS_CREATED = 'tagsGroups/CREATED';
export const TAGS_GROUPS_DELETED = 'tagsGroups/DELETED';

export const ensureTagsGroupsLoaded =
  (forceLoad?: boolean) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { loadState: currentLoadState } = getState().tagsGroups;

    if (!forceLoad && currentLoadState === LoadState.LOADED) return;
    if (currentLoadState === LoadState.LOADING) return; // There's already an in-flight load request

    try {
      dispatch({ type: TAGS_GROUPS_LOAD_STARTED });
      const res = await API3.getTagsGroups();

      dispatch({ type: TAGS_GROUPS_LOAD_FINISHED, tagsGroups: res });
      return res;
    } catch (e) {
      dispatch({ type: TAGS_GROUPS_LOAD_FAILED });
      throw e;
    }
  };

export const createTagGroup =
  (tagGroup: TagGroup) => async (dispatch: AppDispatchStrict) => {
    const res = await API3.createTagGroup(tagGroup);

    dispatch({ type: TAGS_GROUPS_CREATED, tagsGroups: res });
  };

export const deleteTagGroup =
  (tagGroup: TagGroup) => async (dispatch: AppDispatchStrict) => {
    await API3.deleteTagGroup(tagGroup);

    dispatch({
      type: TAGS_GROUPS_DELETED,
      tagsGroups: [tagGroup],
    });
  };

export const updateTagGroup =
  (tagGroup: TagGroup) => async (dispatch: AppDispatchStrict) => {
    await API3.updateTagGroup(tagGroup);
    dispatch({
      type: TAGS_GROUPS_UPDATED,
      tagsGroups: [tagGroup],
    });
  };
