import {
  cleanActivity,
  groupAndFilterActivity,
  removeDupesByItemKey,
} from '@floatschedule/activity-format-npm';

import { getBillRateLabel } from '@float/common/lib/rates/rates';
import request from '@float/common/lib/request';
import { getUser } from '@float/common/selectors/currentUser';
import { ACTIVITY_ITEMS_PER_PAGE } from '@float/constants/activity';
import { config } from '@float/libs/config';

import {
  accountNamesMapSelector,
  clientNamesMapSelector,
  departmentNamesMapSelector,
  peopleNamesMapSelector,
  phaseNamesMapSelector,
  projectNamesMapSelector,
  statusTypeNamesMapSelector,
  timeoffTypeNamesMapSelector,
} from './selectors';
import { updateActivityPage } from './updateActivityPage';
import { retrieveActivitySeen } from './updateSeenActivities';

export const ACTIVITY_FETCHING = 'ACTIVITY_FETCHING';
export const ACTIVITY_FETCH_RECEIVED = 'ACTIVITY_FETCH_RECEIVED';
export const RESET_ACTIVITY = 'RESET_ACTIVITY';
export const ACTIVITY_HALT = 'ACTIVITY_HALT';

const itemsPerPage = ACTIVITY_ITEMS_PER_PAGE;

function getLatestTimestamp(items) {
  return Math.max.apply(
    Math,
    items.map(function (o) {
      return +(o.actioned_timestamp || 0);
    }),
  );
}

// We use this to inject the display name for default_hourly_rate, which should
// either be "Hourly rate" or "Bill rate" subject to the CostRate feature flag.
function getKeyToPrettyNameMap() {
  return { default_hourly_rate: getBillRateLabel() };
}

export const receivedActivity =
  ({
    items,
    specialName,
    specialFilterer,
    page,
    perPage = itemsPerPage,
    needsLatest,
    groupBatchOnly,
    sortOrder = 'desc',
  }) =>
  (dispatch, getState) => {
    const {
      activityData,
      projects: { projects: projectsMap },
      phases: { phases: phasesMap },
      timeoffTypes: { timeoffTypes: timeoffTypesMap },
      currentUser,
      companyPrefs,
    } = getState();
    const hasMore = items.length >= perPage;
    const isForModal = specialName === 'modal';
    const state = getState();

    const { allFetched = [] } =
      activityData.activityCollections[specialName] || {};
    let filtered = cleanActivity({
      items,
      projectsMap,
      phasesMap,
      timeOffTypesMap: timeoffTypesMap,
      isForModal,
    });

    if (isForModal) {
      // TODO: remove activities from mongo OR move code to activity-format-npm (cleanActivity)
      filtered = filtered.filter(
        (x) =>
          // Fix for rogue activities https://app.asana.com/0/388486760637802/1200636477539923/f
          !(
            x.activity_mode === 'split' &&
            x.activity_type === 'update' &&
            x.batch_size === 2
          ) &&
          // Fix for rogue activities storing only modified_by delta
          !(
            x.before_data?.modified_by &&
            Object.keys(x.before_data).length === 1
          ),
      );
    }

    if (items.length && !filtered.length && hasMore) {
      // None of the items are renderable (e.g. all logged time activities).
      // Fetch next page.
      dispatch(updateActivityPage({ specialName, page: page + 1 }));
      dispatch(
        // eslint-disable-next-line no-use-before-define
        requestActivity({
          specialName,
          specialFilterer,
          groupBatchOnly,
          sortOrder,
        }),
      );
      return;
    }

    const merged = removeDupesByItemKey(
      filtered.concat(allFetched),
      'activity_id',
    );

    const dictClientIdToClientName = clientNamesMapSelector(state);
    const dictProjectIdToProjectName = projectNamesMapSelector(state);
    const dictPhaseIdToPhaseName = phaseNamesMapSelector(state);
    const dictAccountIdToName = accountNamesMapSelector(state);
    const dictPeopleIdToName = peopleNamesMapSelector(state);
    const dictTimeoffTypeIdToTimeoffTypeName =
      timeoffTypeNamesMapSelector(state);
    const dictStatusTypeIdToStatusTypeName = statusTypeNamesMapSelector(state);
    const dictDepartmentIdToDepartmentName = departmentNamesMapSelector(state);
    const dictKeyToPrettyName = getKeyToPrettyNameMap();

    const grouped = groupAndFilterActivity({
      items: merged,
      dicts: {
        dictAccountIdToName,
        dictClientIdToClientName,
        dictProjectIdToProjectName,
        dictPhaseIdToPhaseName,
        dictPeopleIdToName,
        dictTimeoffTypeIdToTimeoffTypeName,
        dictStatusTypeIdToStatusTypeName,
        dictDepartmentIdToDepartmentName,
        dictKeyToPrettyName,
      },
      isModal: specialName === 'modal',
      user: getUser(getState()),
      options: {
        locale: config.locale,
        currency: companyPrefs?.currency || {},
      },
      groupBatchOnly,
      order: sortOrder,
    });
    const toDispatch = {
      type: ACTIVITY_FETCH_RECEIVED,
      activity: filtered,
      allFetched: merged,
      hasMore,
      page,
      grouped,
      specialName,
      needsLatest,
      currentUserAccountId: currentUser.account_id,
    };

    if (!isForModal) {
      const { latestActivityTimestamp: latestStoredTimestamp = 0 } =
        activityData;
      const latestBatchTimestamp = getLatestTimestamp(items);
      if (latestBatchTimestamp > latestStoredTimestamp) {
        toDispatch.latestActivityTimestamp = latestBatchTimestamp;
      }
    }

    dispatch(toDispatch);
  };

/**
 *
 * @param {*} param0
 * @returns
 */
export const requestActivity = ({
  latest,
  toClear,
  specialFilterer,
  specialName,
  needsLatest,
  perPage = itemsPerPage,
  groupBatchOnly = false,
  sortOrder = 'desc',
} = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { activityData, currentUser } = state;

    // if in shared link view, only request filtered activities (for task modal, etc)
    if (!specialFilterer && currentUser?.shared_link_view) {
      return null;
    }
    dispatch(retrieveActivitySeen());
    const activityCollection =
      activityData.activityCollections[specialName] || {};
    const page = latest || toClear || needsLatest ? 1 : activityCollection.page;
    const filterer = specialFilterer || activityCollection.filterer || {};
    if (toClear) {
      dispatch({
        type: RESET_ACTIVITY,
        specialName,
        filterer,
        sortOrder,
      });
    }
    dispatch({
      type: ACTIVITY_FETCHING,
      specialName,
    });

    // TODO: the following halting logic is legacy code carried forward,
    //  limit upped from 50 to 1000 just as a sanity check. Limit probably not needed
    //  once we confirm the virtualization on activity feed is working alright.
    if (page > 1000) {
      // #todo implement rollbar message
      console.log('-- halt');
      console.log('page', page);
      console.log('perPage', perPage);
      console.log('#todo implement rollbar message');
      return dispatch({
        specialName,
        type: ACTIVITY_HALT,
      });
    }

    let since = 0;
    if (
      needsLatest &&
      activityCollection.items &&
      activityCollection.items[0] &&
      activityCollection.items[0].actioned_timestamp
    ) {
      since = activityCollection.items[0].actioned_timestamp;
    }

    return request
      .get(
        'svc/af/activities',
        {
          page,
          'per-page': perPage,
          since,
          order: sortOrder,
          ...(specialFilterer || {}),
        },
        { hostname: '', version: '', jwt: true },
      )
      .then(({ items }) => {
        dispatch(
          receivedActivity({
            items,
            specialName,
            specialFilterer,
            page,
            perPage,
            needsLatest,
            groupBatchOnly,
            sortOrder,
          }),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };
};
