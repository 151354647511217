import React from 'react';
import { Trans } from '@lingui/macro';

import { Button } from '@float/ui/components/Button';
import { IconPencilOutline } from '@float/ui/icons/essentials/IconPencilOutline';

import * as styles from './BulkEditRolesHeader.styles.css';

type BulkEditRolesHeaderProps = {
  count: number;
  inTableEditMode: number | null;
  onEditClick: () => void;
};

export function BulkEditRolesHeader(props: BulkEditRolesHeaderProps) {
  const { count, inTableEditMode, onEditClick } = props;

  if (!count) return null;

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <Trans>{count} selected</Trans>
      </div>
      <Button
        size="small"
        iconStart={IconPencilOutline}
        disabled={Boolean(inTableEditMode)}
        onClick={onEditClick}
      >
        <Trans>Edit</Trans>
      </Button>
    </div>
  );
}
