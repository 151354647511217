import React, { forwardRef, FunctionComponent, MouseEvent } from 'react';
import classNames from 'classnames';

import { IconTrash } from '@float/ui/deprecated/Earhart/Icons';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import { ButtonActionColorVariants } from './types';

import * as styles from './styles.css';

export type ButtonActionProps = {
  className?: string;
  color?: ButtonActionColorVariants;
  icon: FunctionComponent<{ size?: number | string; className?: string }>;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  size?: number;
  tooltip?: string;
} & Pick<
  React.ComponentPropsWithRef<'button'>,
  'disabled' | 'tabIndex' | 'aria-label'
>;

const ButtonAction = forwardRef<HTMLButtonElement, ButtonActionProps>(
  (props, ref) => {
    const {
      className,
      color,
      disabled,
      icon = IconTrash,
      onClick,
      size,
      tooltip,
      ...rest
    } = props;

    const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.blur();

      if (onClick) onClick(e);
    };

    const button = (
      <button
        className={classNames(styles.buttonAction({ color }), className)}
        onClick={onClickHandler}
        type="button"
        disabled={disabled}
        aria-disabled={disabled}
        {...rest}
        ref={ref}
      >
        {React.createElement(icon, { size, className: styles.svg })}
      </button>
    );

    if (tooltip) {
      return (
        <TextTooltip className="hover" content={tooltip}>
          {button}
        </TextTooltip>
      );
    }

    return button;
  },
);

export { ButtonAction };
