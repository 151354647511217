import { useCallback } from 'react';
import { t } from '@lingui/macro';

import {
  PHASES_UPDATED,
  PROJECT_MILESTONES_LOAD_FINISH,
  PROJECTS_UPDATED,
} from '@float/common/actions';
import { TASK_META_LOAD_FINISH } from '@float/common/actions/taskMetas';
import { useAppDispatch } from '@float/common/store';
import { emitEvent } from '@float/libs/eventEmitter';
import { logger } from '@float/libs/logger';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';
import { useProjectPlanAction } from '@float/web/serena/hooks/useProjectPlanAction';

import {
  parseAsyncProjectCreatedResult,
  ProjectCreatedResult,
} from './AsyncProcess.helpers';

export function useOnProjectCreated() {
  const dispatch = useAppDispatch();
  const openProjectPlanAction = useProjectPlanAction();
  // Use `updateSnackbar` instead of `showSnackbar` so that only the user that
  // initiated creation sees this snackbar. This relies on the fact that when
  // project creation starts, user is shown a snackbar with a loader. That
  // snackbar has the same ID that we use here to update it (i.e. processId).
  const { updateSnackbar } = useSnackbar();

  return useCallback(
    (eventData: ProjectCreatedResult) => {
      const { processId, data, success, errors } = eventData;
      const { project_id: projectId } = data;
      const projectName = data.project_name ?? 'Project';

      const snackbarOptions = {
        id: processId,
        persist: false,
        loader: false,
        showClose: true,
        autoCloseMs: 10000,
      };

      if (!success) {
        const serverError = errors?.[0]?.message;
        const errorMessage = serverError || t`"${projectName}" creation failed`;
        updateSnackbar(errorMessage, snackbarOptions);

        // Ideally, async project creation should not fail after passing client
        // side validation. If it does, log an error to investigate.
        logger.error('Async project creation failed', errors);

        return;
      }

      const { project, phases, milestones, tasks } =
        parseAsyncProjectCreatedResult(data);

      dispatch({ type: PROJECTS_UPDATED, project });

      if (phases.length) {
        dispatch({ type: PHASES_UPDATED, phases });
      }

      if (milestones.length) {
        dispatch({ type: PROJECT_MILESTONES_LOAD_FINISH, milestones });
      }

      if (tasks.length) {
        dispatch({ type: TASK_META_LOAD_FINISH, projectId, taskMetas: tasks });
      }

      const message = t`“${projectName}” added`;
      const successSnackbarOptions = {
        ...snackbarOptions,
        showClose: true,
        linkAriaLabel: t`Open the project view for ${projectName}`,
        linkText: t`Plan`,
        onLinkClick: () => {
          openProjectPlanAction(projectName, projectId);
        },
      };
      updateSnackbar(message, successSnackbarOptions);
      emitEvent(processId, project);
    },
    [updateSnackbar, dispatch, openProjectPlanAction],
  );
}
