import { LoadState, ReduxStateStrict } from '@float/common/reducers/lib/types';

type PetIsTagsLoadingProps = {
  loadStatePeople: ReduxStateStrict['people']['loadState'];
  loadStateProjects: ReduxStateStrict['projects']['loadState'];
  loadStateTags: ReduxStateStrict['tags']['loadState'];
  loadStateTagsGroups: ReduxStateStrict['tagsGroups']['loadState'];
};

export const getIsTagsPageLoading = ({
  loadStatePeople,
  loadStateProjects,
  loadStateTags,
  loadStateTagsGroups,
}: PetIsTagsLoadingProps) => {
  const isLoadingPeople = loadStatePeople === LoadState.LOADING;
  const isLoadingProjects = loadStateProjects === LoadState.LOADING;
  const isLoadingTags = loadStateTags === LoadState.LOADING;
  const isLoadingTagsGroups = loadStateTagsGroups === LoadState.LOADING;
  const isLoading =
    isLoadingPeople ||
    isLoadingProjects ||
    isLoadingTags ||
    isLoadingTagsGroups;

  return isLoading;
};
