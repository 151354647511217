import { RouterState } from 'connected-react-router';
import { REHYDRATE } from 'redux-persist';

import { BudgetsState } from '@float/common/store/budgets/budgets.reducer';
import { estimatesReducerPath } from '@float/common/store/estimates';
import {
  AnyEntity,
  BillingInfo,
  CompanyPreferences,
  CurrentUser,
  CustomHoliday,
  RolesState,
  SplitTaskChanges,
  Task,
  Timeoff,
  TimeoffType,
} from '@float/types';
import {
  LockLoggedTimeFrequency,
  LockLoggedTimeItem,
} from '@float/types/lockLoggedTime';
import type { EstimatesState } from '@float/common/store/estimates';
import type { ScheduleState } from '@float/common/store/schedule';
import type { SearchResultsState } from '@float/common/store/searchResults/searchResults.types';

import { AccountsState } from '../accounts';
import { AppInfoState } from '../appInfo';
import { ClientsState } from '../clients';
import { DepartmentsState } from '../departments';
import { LastAllocatedTaskState } from '../lastAllocatedTask/types';
import { LoggedTimesState } from '../loggedTimes';
import { MilestonesState } from '../milestones';
import { OneOffsState } from '../oneOffs';
import { PeopleState } from '../people';
import { PhasesState } from '../phases';
import { ProjectsState } from '../projects';
import { SearchState } from '../search';
import { StatusesState } from '../statuses';
import { StatusTypesState } from '../statusTypes';
import { TagsState } from '../tags';
import { TagsGroupsState } from '../tagsGroups';
import { TaskMetasState } from '../taskMetas';
import { TasksState } from '../tasks';
import { TimeoffsState } from '../timeoffs';
import { TimeoffTypesState } from '../timeoffTypes';
import { TimerState } from '../timer';
import { TimeRangeState } from '../timeRange';
import { ViewsState } from '../views';
import type { AllActions } from '..';

export type SettingsState<Entity extends AnyEntity> = {
  entities: Entity[];
  pageCount: number | null;
  totalCount: number | null;
  lastFetchedPage: number;
  pagesBeingFetched: number[];
  beingSubmitted: boolean;
  beingEditedIds: number[];
  beingDeletedIds: number[];
  lastFetchError: Error | null;
  lastSubmitError: Error | null;
  lastDeleteError: Error | null;
  sortableTables: Record<
    string,
    {
      sortProp: keyof Entity;
      sortDir: 'asc' | 'desc';
    }
  >;
  sortProps: Record<
    keyof Entity,
    {
      asc: {
        lastFetchedPage: number;
        ids: string[];
      };
      desc: {
        lastFetchedPage: number;
        ids: string[];
      };
    }
  >;
};

export type SettingsBillingInfoState = {
  is_trial?: boolean;
  plan: Partial<BillingInfo['plan']>;
  isLoading: boolean;
  isLoaded: boolean;
  available_plans: unknown[];
  inactive_plans: unknown[];
  trial_days_left: number | undefined;
};

export enum LoadState {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  UNLOADED = 'UNLOADED',
  LOAD_FAILED = 'LOAD_FAILED',
}

export type RehydratePartialStateAction<
  State = unknown,
  ReducerName extends string = string,
> = {
  type: typeof REHYDRATE;
  payload?: {
    [key in ReducerName]?: Partial<State>;
  };
};

export type ReduxStateStrict = {
  app: {
    loaded: boolean;
  };
  accounts: AccountsState;
  appInfo?: AppInfoState; // Can be undefined on Mobile
  budgets: BudgetsState;
  clients: ClientsState;
  companyPrefs: CompanyPreferences;
  currentUser: CurrentUser;
  departments: DepartmentsState;
  [estimatesReducerPath]: EstimatesState;
  holidays: {
    holidays: Record<number, CustomHoliday>;
    loadState: LoadState;
    holidaysLoaded: boolean;
  };
  jwt: {
    loadState: LoadState;
    accessToken: string | null;
    refreshToken: string | null;
    currentCompanyId: number | null;
    expiry: number;
  };
  lockLoggedTime: {
    config?: {
      active: boolean;
      calendar?: LockLoggedTimeItem[];
      frequency: LockLoggedTimeFrequency;
      interval?: number;
    };
  };
  loggedTimes: LoggedTimesState;
  milestones: MilestonesState;
  oneOffs: OneOffsState;
  people: PeopleState;
  phases: PhasesState;
  projects: ProjectsState;
  roles: RolesState;
  search: SearchState;
  searchResults?: SearchResultsState;
  settingsTimeoffTypes: SettingsState<TimeoffType>;
  settingsBillingInfo: SettingsBillingInfoState;
  statusTypes: StatusTypesState;
  schedule: ScheduleState;
  tags: TagsState;
  tagsGroups: TagsGroupsState;
  taskMetas: TaskMetasState;
  tasks: TasksState;
  timeoffs: TimeoffsState;
  timeoffTypes: TimeoffTypesState;
  router?: RouterState & { location?: { pathname?: string } }; // Can be undefined on Mobile
  lastAllocatedTask: LastAllocatedTaskState;
  statuses: StatusesState;
  timer: TimerState;
  timeRange: TimeRangeState;
  views?: ViewsState; // Can be undefined in Timer-Native and Mobile
  lastUpdated: {
    project: { project_id: number };
    taskOrTimeOff: Task | Timeoff;
  };

  undoCommandStream: {
    undoable: (AllActions & { batchId: number })[];
    redoable: (AllActions & { batchId: number })[];
    processing: boolean | 'undo' | 'redo';
    processingBatchId: null | number;
    mode: 'schedule' | 'logTime';
    splitTaskChanges: SplitTaskChanges | [];
  };
};

/**
 * @deprecated - Use `ReduxStateStrict` if at all possible, adding required type definitions as needed
 */
export type ReduxState = ReduxStateStrict & {
  // TBD
  app: any;
  integrations: any;
  lastUpdated?: any;
  loggedTimes: any;
  pmSidebar: any;
  publicHolidays: any;
  settingsJwt: any;
};

export type TimerGlobalState = ReduxState;
