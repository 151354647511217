import { RowType } from '@float/constants/schedule';
import { SingleProjectSortColumn } from '@float/types';
import { Placeholder } from '@float/types/placeholder';
import { parseNumber } from '@float/ui/helpers/number/numberParser';
import type {
  Person,
  PersonProjectRow,
  Project,
  ProjectRow,
} from '@float/types';

import { stringCompare } from '../../lib/sort';
import type { SortPrefs } from './useScheduleRows';

export function getSingleProjectViewRows(
  singleProjectViewProject: Project & { all_people_ids?: number[] },
  people: Record<number, Person>,
  sortPrefs: Partial<SortPrefs> = {},
): Array<ProjectRow | PersonProjectRow> {
  const rows: Array<ProjectRow | PersonProjectRow> = [];

  if (!singleProjectViewProject) return rows;
  const prefix = RowType.Person;
  const projectId = singleProjectViewProject.project_id;

  const projectPeopleIds = singleProjectViewProject.all_people_ids ?? [];

  const projectPeople = projectPeopleIds
    .map((id) => people[id])
    .filter(Boolean);

  const projectRow: ProjectRow = {
    type: RowType.Project,
    id: `${RowType.Project}-${projectId}`,
    darkBackground: true,
    isCollapsed: false,
    data: singleProjectViewProject,
    numActivePeople: projectPeople.length,
    numTotalPeople: projectPeopleIds.length,
  };

  rows.push(projectRow);

  const peopleSortBy = sortPrefs.single_project_view_sort_by;
  const peopleSortDir = sortPrefs.single_project_view_sort_dir;

  switch (peopleSortBy) {
    case SingleProjectSortColumn.Name:
      projectPeople.sort((a, b) => {
        return peopleSortDir === 'asc'
          ? stringCompare(a.name.toLowerCase(), b.name.toLowerCase())
          : stringCompare(b.name.toLowerCase(), a.name.toLowerCase());
      });
      break;
    case SingleProjectSortColumn.HourlyRate:
      projectPeople.sort((a, b) => {
        const rateA = parseNumber(
          singleProjectViewProject.people_rates?.[a.people_id] || '0',
        ) as number;
        const rateB = parseNumber(
          singleProjectViewProject.people_rates?.[b.people_id] || '0',
        ) as number;
        return peopleSortDir === 'asc' ? rateA - rateB : rateB - rateA;
      });
      break;
    default:
      projectPeople.sort((a, b) =>
        stringCompare(a.name.toLowerCase(), b.name.toLowerCase()),
      );
      break;
  }

  for (const person of projectPeople) {
    rows.push({
      type: RowType.Person,
      id: `${prefix}-${person.people_id}`,
      key: `${prefix}-${projectId}-${person.people_id}`,
      projectId,
      peopleId: person.people_id,
      data: person,
      isLogTimeRow: false,
      darkBackground:
        person.people_type_id === 3 &&
        person.new_role_placeholder !== Placeholder.New,
    });
  }

  return rows;
}
