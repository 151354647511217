import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  margin-top: 25px;
  margin-bottom: 0;
  ${({ wrapperWidth }) => `flex-basis: ${wrapperWidth}px`}
`;

export const Title = styled.h1`
  text-transform: uppercase;
`;

export const List = styled.ul`
  list-style: none;
  margin-top: 8px;

  > * + * {
    margin-top: 4px;
  }
`;

export const PointInTimeApplyToWrapper = styled.div`
  display: inline-block;
  width: 230px;
  margin-top: 20px;
  margin-right: 20px;
`;

export const PointInTimeDateWrapper = styled.div`
  display: inline-block;
  width: 150px;
  margin-top: 25px;

  input[type='text'] {
    width: 150px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    height: 40px;
    border-radius: 0;

    &:focus {
      border-color: ${(p) => p.theme.charcoalGrey};
    }
  }
`;

export const HistoryLine = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: row-reverse;

  color: ${(p) => p.theme.blueGrey};
  font-size: 14px;
  letter-spacing: -0.2px;

  .tippy-content {
    padding: 0;
  }
`;

export const DescriptionLine = styled.div`
  display: inline-block;
  color: #b0b0b0;
  letter-spacing: 0.12px;
`;

export const HistoryTooltipContainer = styled.div`
  display: grid;
  align-self: center;
`;

export const HistoryTooltipDays = styled.div`
  grid-column: 1;
  font-weight: 600;
  align-self: center;
  line-height: 44px;
  border-top: 1px solid ${(p) => p.theme.grey};
  padding-left: 15px;
  text-align: left;
  ${(p) => !p.isRemovable && `color: ${p.theme.blueGrey};`}
`;

export const HistoryTooltipDates = styled.div`
  grid-column: 2;
  text-align: left;
  align-self: center;
  line-height: 44px;
  border-top: 1px solid ${(p) => p.theme.grey};
  padding-left: 5px;
  ${(p) => !p.isRemovable && `color: ${p.theme.blueGrey};`}
`;

export const HistoryRemoveIconWrapper = styled.div`
  grid-column: 3;
  width: 24px;
  height: 24px;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
  border-top: 1px solid ${(p) => p.theme.grey};
  box-sizing: content-box;
`;
