import type { SearchResultsState } from './searchResults.types';

export const getSearchResults = (state: {
  searchResults?: SearchResultsState;
}) => {
  // Check to verify that the SearchWorker is enabled and active
  if (state.searchResults?.active) {
    return state.searchResults;
  }

  return undefined;
};
