import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { get, isEmpty } from 'lodash';
import { getCurrentPlan, getVisibleSettings } from 'selectors';

import { ensureDepartmentsLoaded } from '@float/common/actions/departments';
import { ensureHolidaysLoaded } from '@float/common/actions/holidays';
import { ensureMilestonesLoaded } from '@float/common/actions/milestones';
import { fetchOneOffs } from '@float/common/actions/oneOffs';
import { ensurePeopleLoaded } from '@float/common/actions/people';
import { ensureSearchContextLoaded } from '@float/common/actions/search';
import { fetchStatuses } from '@float/common/actions/statuses';
import { ensureStatusTypesLoaded } from '@float/common/actions/statusTypes';
import { fetchTasksWithDates } from '@float/common/actions/tasks';
import { fetchTimeoffsWithDates } from '@float/common/actions/timeoffs';
import { ensureTimeoffTypesLoaded } from '@float/common/actions/timeoffTypes';
import {
  getCompanyPrefs,
  isPaidPlan as isPaidPlanSelector,
  isPlusPackEnabled,
  isTimeTrackingEnabled,
} from '@float/common/selectors/companyPrefs';
import { getAllProjectsOptions } from '@float/common/selectors/projects';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { Button } from '@float/ui/deprecated/Button/Button';
import IconExport from '@float/ui/deprecated/Earhart/Icons/Icon/IconExport';

import { getIsEnterprisePlan } from '../Billing/TeamAccountFeaturesNew/helpers/getIsEnterprisePlan';
import { getIsProPlan } from '../Billing/TeamAccountFeaturesNew/helpers/getIsProPlan';
import { getIsStarterPlan } from '../Billing/TeamAccountFeaturesNew/helpers/getIsStarterPlan';
import Body from '../Body';
import { SUPPORT_ARTICLES } from '../constants';
import { PlanUpsellBanner } from '../PlanUpsellBanner';
import RestrictedMessage from '../RestrictedMessage';
import { DateRangeFilter } from './DateRangeFilter';
import Dropdown from './Dropdown';
import { exportCsv } from './exportCsv';
import { useActivityFeedFilters } from './filters/useActivityFeedFilters';
import { getAccountOptions, getPeopleOptions } from './selectors';
import { CustomButton, DropdownFilters, MainFilters } from './styles';
import { ActivityTable } from './Table';
import { useTeamActivityDateRange } from './useTeamActivityDateRange';

const FilterButton = ({ displayFilter, ...others }) => (
  <Button {...others} {...(displayFilter && { appearance: 'secondary' })}>
    {!displayFilter ? t`Filter activity` : t`Close`}
  </Button>
);

const getActionOptions = () => [
  { value: 'create', label: t`Created` },
  { value: 'update', label: t`Updated` },
  { value: 'delete', label: t`Deleted` },
];

const getItemTypeOptions = () => [
  { value: 'task', label: t`Allocation` },
  { value: 'timeoff', label: t`Time off` },
  { value: 'people', label: t`Person` },
  { value: 'project', label: t`Project` },
  { value: 'phase', label: t`Phase` },
  { value: 'role', label: t`Role` },
];

function TeamActivity() {
  const plan = useAppSelector(getCurrentPlan);
  const isPaidPlan = useAppSelector(isPaidPlanSelector);
  const hasPlusPack = useAppSelector(isPlusPackEnabled);
  const companyPrefs = useAppSelector(getCompanyPrefs);
  const hasTimeTracking = useAppSelector(isTimeTrackingEnabled);
  const hasBothAddons = hasPlusPack && hasTimeTracking;
  const { plan_id: planId, is_monthly: isMonthly } = plan;

  const isEnterprisePlan = getIsEnterprisePlan(planId, {
    hasBothAddons,
    isAnnualPlan: !isMonthly,
    isPaidPlan,
  });
  const isProPlan = getIsProPlan(planId, {
    isPaidPlan,
    isProTrial: companyPrefs.is_pro_trial,
    plusPack: companyPrefs.plus_pack,
    proTrialEnd: companyPrefs.pro_trial_end,
    timeTracking: companyPrefs.time_tracking,
  });
  const isStarterPlan = getIsStarterPlan(planId, {
    isPaidPlan,
    isProTrial: companyPrefs.is_pro_trial,
    plusPack: companyPrefs.plus_pack,
    proTrialEnd: companyPrefs.pro_trial_end,
    timeTracking: companyPrefs.time_tracking,
  });

  const isFilterFeatureEnabled = !isStarterPlan;

  const getSubHeaderMessage = () => {
    if (isEnterprisePlan) {
      return t`View all of your team's activity or filter by project or person.`;
    }
    if (isProPlan) {
      return t`View all of your team's activity over the last 180 days.`;
    }
    return t`View all of your team's activity over the last 7 days.`;
  };

  const { rangeFilter, onDateRangeChange } = useTeamActivityDateRange({
    isStarterPlan,
    isProPlan,
  });

  const [exporting, setExporting] = useState(false);
  const {
    filters,
    filtersDisplayValues,
    filtersForApiQuery,
    isFilterApplied,
    shouldShowFilters,
    clearFilters,
    onFilterChange,
    setShouldShowFilters,
  } = useActivityFeedFilters(rangeFilter, isFilterFeatureEnabled);

  const dispatch = useAppDispatch();
  const firstOfWeek = useAppSelector((state) =>
    get(state, 'currentUser.start_work_week'),
  );
  const projectOptions = useAppSelector(getAllProjectsOptions);
  const peopleOptions = useAppSelector(getPeopleOptions);
  const accountOptions = useAppSelector(getAccountOptions);
  const userCanSee = useAppSelector(getVisibleSettings);

  // ensure all required data is loaded
  useEffect(() => {
    dispatch(ensureSearchContextLoaded());
    dispatch(ensureDepartmentsLoaded());
    dispatch(ensureTimeoffTypesLoaded());
    dispatch(ensureStatusTypesLoaded());
    dispatch(ensurePeopleLoaded());
    dispatch(ensureHolidaysLoaded());
    dispatch(ensureMilestonesLoaded());
  }, [dispatch]);
  useEffect(() => {
    const start = moment(rangeFilter.start)
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
    const end = moment(rangeFilter.end).add(1, 'year').format('YYYY-MM-DD');
    dispatch(fetchTasksWithDates(start, end));
    dispatch(fetchTimeoffsWithDates(start, end));
    dispatch(fetchOneOffs(start, end));
    dispatch(fetchStatuses(start, end));
  }, [dispatch, rangeFilter]);

  const getExportMetadata = useCallback(() => {
    return {
      dateRange: { start: rangeFilter.start, end: rangeFilter.end },
      filters: isEmpty(filtersDisplayValues) ? null : filtersDisplayValues,
    };
  }, [rangeFilter, filtersDisplayValues]);

  const onExportReady = useCallback(
    async (data) => {
      await exportCsv(data, companyPrefs.company_name, getExportMetadata());
      setExporting(false);
    },
    [getExportMetadata, companyPrefs.company_name],
  );

  const exportButton = useMemo(() => {
    if (!isFilterFeatureEnabled) return null;

    return (
      <CustomButton
        appearance="ghost"
        size="small"
        iconRight={exporting ? null : IconExport}
        loader={exporting}
        onClick={() => setExporting(true)}
        floatRight
      >
        <Trans>Export</Trans>
      </CustomButton>
    );
  }, [exporting, isFilterFeatureEnabled]);

  const planUpsellBanner = useMemo(
    () => (
      <PlanUpsellBanner
        header={t`Need longer activity feed?`}
        subheader={t`Get 180 days with our Pro plan.`}
        learnMoreUrl={SUPPORT_ARTICLES.activityFeed}
      />
    ),
    [],
  );

  const actionOptions = useMemo(() => getActionOptions(), []);
  const itemTypeOptions = useMemo(() => getItemTypeOptions(), []);

  if (!userCanSee.teamActivity) {
    return <RestrictedMessage />;
  }

  return (
    <Body
      header={t`Activity`}
      subheader={getSubHeaderMessage()}
      headerWidget={exportButton}
      withPlusPackUpgrade={!hasPlusPack}
      planUpsellBanner={planUpsellBanner}
    >
      {isFilterFeatureEnabled && (
        <>
          <MainFilters>
            <DateRangeFilter
              rangeMode={rangeFilter.rangeMode}
              firstOfWeek={firstOfWeek}
              start={rangeFilter.start}
              end={rangeFilter.end}
              onChange={onDateRangeChange}
            />

            <FilterButton
              displayFilter={shouldShowFilters}
              onClick={() => setShouldShowFilters(!shouldShowFilters)}
            />
          </MainFilters>

          <DropdownFilters hide={!shouldShowFilters}>
            <Dropdown
              placeholder={t`All projects`}
              value={filters.project}
              groupedOptions={projectOptions}
              onChange={(e) => onFilterChange('project', e)}
            />
            <Dropdown
              placeholder={t`All people`}
              value={filters.person}
              groupedOptions={peopleOptions}
              onChange={(e) => onFilterChange('person', e)}
            />
            <Dropdown
              placeholder={t`All actions`}
              value={filters.action}
              options={actionOptions}
              onChange={(e) => onFilterChange('action', e)}
            />
            <Dropdown
              placeholder={t`All actioned by`}
              value={filters.actionedBy}
              options={accountOptions}
              onChange={(e) => onFilterChange('actionedBy', e)}
            />
            <Dropdown
              placeholder={t`All types`}
              value={filters.itemType}
              options={itemTypeOptions}
              onChange={(e) => onFilterChange('itemType', e)}
            />
            <Button
              size="large"
              onClick={clearFilters}
              disabled={!isFilterApplied}
            >
              <Trans>Clear</Trans>
            </Button>
          </DropdownFilters>
        </>
      )}

      <ActivityTable
        filters={filtersForApiQuery}
        exporting={exporting}
        onExportReady={onExportReady}
      />
    </Body>
  );
}

export default TeamActivity;
