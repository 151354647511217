import { config } from '@float/libs/config';

import { formatToDbString as _formatToDbString } from '../normalizer';
import { getNumberSeparators } from './getNumberSeparators';

/**
 * Converts a locale-specific string (e.g., "1.000.000") into a numerical value (1000000).
 * Ensure the input `amount` follows the locale's formatting conventions, as passing non-locale-specific numbers
 * (e.g., "1000.456") may lead to incorrect parsing (e.g., "1000456") in some locales.
 */
export const formatToDbString = (
  amount: number | string | null | undefined,
) => {
  const { decimal, thousand, minusSign } = getNumberSeparators(config.locale);
  return _formatToDbString(decimal, thousand, minusSign, amount);
};
