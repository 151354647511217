import React from 'react';

import { CanView } from '../CanView';
import { PersonAccessProps } from '../PersonAccess.types';
import { ManagerPermissions } from './ManagerPermissions';

import * as styles from '../PersonAccess.styles.css';

export function ManagerRights(props: PersonAccessProps) {
  const { account, formErrors, initialPerson, onChange } = props;

  return (
    <div>
      <CanView
        account={account}
        initialPerson={initialPerson}
        formErrors={formErrors}
        onChange={onChange}
      />

      <div className={styles.separator} />

      <ManagerPermissions account={account} onChange={onChange} />
    </div>
  );
}
