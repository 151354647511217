import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { isNil } from 'es-toolkit';

import { formatDate } from '@float/common/lib/utils';
import { FAR_PAST_DATE } from '@float/constants/dates';
import { CostRateHistory } from '@float/types/costRate';
import { Button } from '@float/ui/components/Button';
import { IconTrash } from '@float/ui/icons/essentials/IconTrash';

import { RateText } from '../RateText/RateText';

import * as styles from './RateHistory.styles.css';

export type RateHistoryProps = {
  history: CostRateHistory;
  onRemove?: (date: DateString) => void;
};

type RateHistoryItemProps = {
  date: DateString;
  rate: string | null;
  onRemove?: (date: DateString) => void;
};

function RateHistoryItem(props: RateHistoryItemProps) {
  const { date, rate, onRemove } = props;

  const isInitial = date === FAR_PAST_DATE;

  const fromDate = !isInitial && formatDate(date, 'd MMM yyyy');

  return (
    <div className={styles.rateHistoryItem} key={date}>
      <div>
        {isNil(rate) ? (
          <span>{t`Removed custom cost rate`}</span>
        ) : (
          <span>
            <Trans>
              Updated cost rate to{' '}
              <RateText className={styles.rateText} rate={rate} />
            </Trans>
          </span>
        )}
        {fromDate && (
          <ul className={styles.ul}>
            <li className={styles.li}>
              <Trans>Effective from {fromDate}</Trans>
            </li>
          </ul>
        )}
      </div>
      {typeof onRemove === 'function' && (
        <Button
          appearance="clear-flay"
          className={styles.removeRate}
          iconEnd={IconTrash}
          iconOnlyLabel={t`Remove rate`}
          size="xxsmall"
          tabIndex={-1}
          onClick={() => onRemove(date)}
        />
      )}
    </div>
  );
}

export function RateHistory(props: RateHistoryProps) {
  const { history, onRemove } = props;

  const entries = useMemo(() => {
    const sortedHistory = Object.entries(history).sort((a, b) =>
      b[0].localeCompare(a[0]),
    );

    return sortedHistory.map(([date, { rate }], index) => (
      <RateHistoryItem key={date} date={date} rate={rate} onRemove={onRemove} />
    ));
  }, [history, onRemove]);

  return <div className={styles.rateHistory}>{entries}</div>;
}
