import { LoadState } from '@float/common/reducers/lib/types';
import {
  BudgetPriority,
  BudgetType,
  NotesMeta,
  Phase,
  PhaseInputData,
  ProjectStatus,
  ProjectTeamMemberData,
} from '@float/types';

import { phases as PhasesAPI } from '../../api3/phases';
import { trackEvent } from '../../lib/analytics';
import { getBudgetTypeText } from '../../lib/budget';
import { batch } from '../../lib/reduxBatch';
import { getPhasesMapRaw } from '../../selectors/phases';
import { AppDispatchStrict, AppStoreStrict } from '../../store';
import {
  MilestoneApiPayload,
  MILESTONES_UPDATE_START,
} from '../milestones/types';
import { UPDATE_TASK_BATCH } from '../tasks';
import { mapPhaseToV3 } from './phases.helpers';

export const PHASES_LOAD_START = 'phases/LOAD_START';
export const PHASES_LOAD_FAILED = 'phases/LOAD_FAILED';
export const PHASES_LOAD_FINISH = 'phases/LOAD_FINISH';

export const PHASES_UPDATED = 'phases/UPDATED';
export const PHASES_DELETED = 'phases/DELETED';
export const PHASES_BULK_UPDATED = 'phases/BULK_UPDATED';

export const SHIFT_PHASE_TIMELINE = 'phases/SHIFT_TIMELINE';

type BulkEditPhaseTeam = {
  set?: ProjectTeamMemberData[];
  add?: ProjectTeamMemberData[];
  del?: number[];
};

export type PhaseTeam =
  | ProjectTeamMemberData[]
  | BulkEditPhaseTeam
  | Record<string, never>
  | undefined;

export type PhaseApiPayload = {
  active: 0 | 1;
  budget_total: number | null;
  budget_type?: BudgetType;
  budget_priority?: BudgetPriority;
  color: string | null;
  default_hourly_rate: number | null;
  end_date?: string | null;
  name: string;
  non_billable: 0 | 1;
  notes_meta: NotesMeta;
  notes: string;
  phase_id: number | null;
  phase_team?: PhaseTeam;
  project_id: number | null;
  start_date?: string | null;
  status: ProjectStatus;
  /**
   * @deprecated Use `status`. Remove `tentative` field from payload after API
   * adds support for `status` field.
   */
  tentative: 0 | 1;
};

export type PhaseInputDataWithTeam = PhaseInputData & {
  phase_team?: PhaseTeam;
};

export function createPhase(newPhase: PhaseInputDataWithTeam) {
  return async (
    dispatch: AppDispatchStrict,
    getState: AppStoreStrict['getState'],
  ) => {
    const data = mapPhaseToV3(newPhase);
    const response = await PhasesAPI.createPhase({
      data,
      query: {
        expand: 'phase_team',
      },
    });

    dispatch({ type: PHASES_UPDATED, phases: [response] });

    trackEvent('Phase added', {
      budgetType: getBudgetTypeText(newPhase),
      nonBillable: response.non_billable,
      tentative: response.tentative,
    });

    return response;
  };
}

export type UpdatePhaseData = PhaseInputData & {
  phase_id: number;
  phase_team?: PhaseTeam;
};

type UpdatePhaseOpts = {
  originalEntity?: Phase;
  opts?: { isResize?: boolean };
};

export function updatePhase(
  newPhase: UpdatePhaseData,
  { originalEntity, opts }: UpdatePhaseOpts = {},
) {
  return async (
    dispatch: AppDispatchStrict,
    getState: AppStoreStrict['getState'],
  ) => {
    const data = mapPhaseToV3(newPhase, opts);
    const response = await PhasesAPI.updatePhase({
      id: newPhase.phase_id,
      data,
      query: {
        expand: 'phase_team',
      },
    });

    dispatch({
      type: PHASES_UPDATED,
      phases: [response],
      prevPhases: [
        originalEntity || getState().phases.phases[newPhase.phase_id],
      ],
    });

    return response;
  };
}

export function shiftPhaseTimeline(
  newPhase: Phase,
  { originalEntity }: UpdatePhaseOpts,
) {
  return async (dispatch: AppDispatchStrict) => {
    const res = await PhasesAPI.shiftPhase({
      id: newPhase.phase_id,
      data: { start_date: newPhase.start_date },
    });

    batch(() => {
      dispatch({ type: UPDATE_TASK_BATCH, tasks: res.task });

      res.milestone.forEach((milestone: MilestoneApiPayload) =>
        dispatch({ type: MILESTONES_UPDATE_START, milestone }),
      );

      dispatch({ type: PHASES_UPDATED, phases: [res.phase] });

      // Dispatch an event to indicate this was a shift event for undo support
      dispatch({ type: SHIFT_PHASE_TIMELINE, newPhase, originalEntity });
    });

    return res;
  };
}

export function deletePhase(id: number) {
  return async (dispatch: AppDispatchStrict) => {
    await PhasesAPI.deletePhase({ id });
    dispatch({ type: PHASES_DELETED, phaseIds: [id] });
  };
}

export function ensurePhaseLoaded(phaseId: number) {
  return async (
    dispatch: AppDispatchStrict,
    getState: AppStoreStrict['getState'],
  ): Promise<Phase | undefined> => {
    // TODO: Detect fully loaded phase and skip network call

    const phase = await PhasesAPI.getPhase({
      id: phaseId,
      query: {
        expand: 'phase_team',
      },
    });
    dispatch({ type: PHASES_LOAD_FINISH, phases: [phase] });

    const result = getPhasesMapRaw(getState())[phaseId];
    if (!result) return undefined;

    return result as Phase;
  };
}

export const ensureProjectPhasesLoaded = (projectId: number) => {
  return async (dispatch: AppDispatchStrict) => {
    const phases = await PhasesAPI.getAllPhases({ project_id: projectId });
    dispatch({ type: PHASES_LOAD_FINISH, phases });
    return phases;
  };
};

export const ensurePhasesLoaded = ({
  forceLoad = false,
  includeArchived = false,
} = {}) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: AppStoreStrict['getState'],
  ) => {
    const { loadState: currentLoadState, archivedPhasesLoaded } =
      getState().phases;

    if (currentLoadState === LoadState.LOADING) return; // There's already an in-flight load request

    const shouldFetchActive =
      forceLoad || currentLoadState !== LoadState.LOADED;
    const shouldFetchArchived = includeArchived && !archivedPhasesLoaded;

    if (!(shouldFetchActive || shouldFetchArchived)) return; // Already loaded

    try {
      dispatch({ type: PHASES_LOAD_START });

      const requests = [
        shouldFetchActive ? PhasesAPI.getAllPhases({ active: true }) : null,
        shouldFetchArchived ? PhasesAPI.getAllPhases({ archived: true }) : null,
      ];

      const [phases, archivedPhases] = await Promise.all(requests);

      dispatch({
        type: PHASES_LOAD_FINISH,
        phases,
        archivedPhases,
      });
    } catch (e) {
      dispatch({ type: PHASES_LOAD_FAILED });
    }
  };
};
