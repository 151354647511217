import React, { MouseEventHandler } from 'react';
import { Trans } from '@lingui/macro';
import useMount from 'react-use/esm/useMount';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { useIsSmallMediaSizeActive } from '@float/libs/hooks/media';
import EHIconImport from '@float/ui/deprecated/Earhart/Icons/Icon/IconImport';
import IconCopyRound from '@float/ui/deprecated/Icons/icon-copy-round';
import { useNavUpdater } from 'components/Nav/NavContext';

interface ProjectQuickActionsProps {
  showManageTemplatesModal?: MouseEventHandler;
  showImportProjectModal?: MouseEventHandler;
}

export const ProjectQuickActions: React.FC<ProjectQuickActionsProps> = ({
  showManageTemplatesModal,
  showImportProjectModal,
}) => {
  const navCtx = useNavUpdater();
  const isSmallBreakpointActive = useIsSmallMediaSizeActive();

  useMount(() => {
    const quickActionControls = [];

    if (!isSmallBreakpointActive) {
      quickActionControls.push(
        <NavIconBtn
          isPrimary={false}
          iconLeft={<IconCopyRound size={'20'} />}
          onClick={showManageTemplatesModal}
          style={{
            minWidth: 160,
          }}
        >
          <Trans>Manage templates</Trans>
        </NavIconBtn>,
      );

      quickActionControls.push(
        <NavIconBtn
          isPrimary={false}
          iconLeft={<EHIconImport size={20} />}
          onClick={showImportProjectModal}
        >
          <Trans>Import</Trans>
        </NavIconBtn>,
      );
    }

    navCtx.registerQuickActionCtrls(quickActionControls);
    return () => {
      navCtx.registerQuickActionCtrls([]);
    };
  });

  return null;
};
