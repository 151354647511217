import styled, { createGlobalStyle, css } from 'styled-components';

// These are all smaller that the "assetsInlineLimit" and so are inlined
// see https://vitejs.dev/config/build-options.html#build-assetsinlinelimit
import CompleteCursor from '@float/common/assets/cursors/cursor-complete.svg';
import DeleteCursor from '@float/common/assets/cursors/cursor-delete.svg';
import InsertCursor from '@float/common/assets/cursors/cursor-insert.svg';
import LinkCursor from '@float/common/assets/cursors/cursor-link.svg';
import LogTimeCursor from '@float/common/assets/cursors/cursor-log-time.svg';
import ReplaceCursor from '@float/common/assets/cursors/cursor-replace.svg';
import SplitCursor from '@float/common/assets/cursors/cursor-split.svg';
import { Styles as WRPStyles } from '@float/common/components/WeekRangePicker';
import { config } from '@float/libs/config';

import BG from './util/bgimage';
import { TASK_EDIT_MODES } from './util/ContextMenu';

const CURSORS = {
  [TASK_EDIT_MODES.INSERT]: InsertCursor,
  [TASK_EDIT_MODES.SPLIT]: SplitCursor,
  [TASK_EDIT_MODES.REPLACE]: ReplaceCursor,
  [TASK_EDIT_MODES.COMPLETE]: CompleteCursor,
  [TASK_EDIT_MODES.DELETE]: DeleteCursor,
  [TASK_EDIT_MODES.LINK]: LinkCursor,
  [TASK_EDIT_MODES.LOG_TIME]: LogTimeCursor,
};

export function getCursor(actionMode) {
  const mapper = CURSORS;
  return mapper[actionMode];
}

export const GlobalStyleOverrides = createGlobalStyle`
  ${() =>
    config.isSafari &&
    `
      html {
        -webkit-user-select: none;
      }
  `}
`;

export const ScrollWrapper = styled.div`
  height: calc(
    var(--screen-height) - var(--app-margin-top, 0px) -
      ${(p) => p.headerHeight}px
  );
  width: 100%;
  overflow: auto;
  will-change: transform;
  background: white;
  position: relative;
  user-select: none;

  #schedule-container.single-user-schedule & {
    width: calc(100vw - 59px);
  }

  #schedule-container.log-time-view & {
    ${(p) => p.dayWidth !== 140 && 'overflow-x: hidden;'}
  }

  @media (max-width: 767px) {
    #schedule-container.single-user-schedule & {
      width: 100%;
      overflow-x: auto;
    }
  }

  @media print {
    height: inherit;
    width: 80in;
  }

  ${(p) =>
    p.disableAnimation &&
    `
      ${RowGroup} {
        transition: none !important;
      }
  `}

  ${() =>
    config.isSafari &&
    `
      ${RowGroup} {
        transition: none !important;
      }
  `}
`;

export const ScheduleContainer = styled.div`
  position: absolute;
  top: -4px;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  div {
    box-sizing: border-box;
  }

  @media (max-width: 850px) {
    ${WRPStyles.RangeLabelContainer} {
      display: none;
    }
  }

  @media (max-width: 980px) {
    ${WRPStyles.TodayButton},
    ${WRPStyles.ArrowsContainer} {
      display: none;
    }

    ${WRPStyles.DateContainer} {
      padding-left: 0;
    }
  }

  @media print {
    position: relative;
  }
`;

export const RowGroup = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  transition:
    height 0.25s ease,
    transform 0.25s ease;
  border-top: 1px solid ${({ theme }) => theme.stroke1};

  ${(p) => p.rowType === 'project' && 'box-sizing: content-box !important;'}
  ${(p) => p.isAnyRowDragging && 'border-bottom: 1px solid #cccccc'};
  ${(p) =>
    p.isDragging &&
    css`
      box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 12px 1px;
      background: url(${BG[`${p.dayWidth}x${p.numDays}`]});
      background-size: ${p.dayWidth * p.numDays}px ${p.dayWidth * p.numDays}px;
    `}
`;

export const ContentWrapper = styled.div`
  height: ${(p) => p.height - p.scrollbarSize}px;
  width: calc(100vw - 60px - ${(p) => p.scrollbarSize}px);

  & > ${RowGroup}:first-child {
    border-top: 0;
  }
`;

export const TooltipBoundary = styled.div`
  top: ${(p) => p.headerHeight}px;
  height: calc(
    var(--screen-height) - var(--app-margin-top, 0px) -
      ${(p) => p.headerHeight}px
  );
`;

export const PlaceholderRow = styled.div`
  position: absolute;
  left: 0;
  background: #ebebeb;
  opacity: 1;
  z-index: 0;
`;

export const SingleUserViewRow = styled.div`
  height: 80px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: width 0.25s ease;
  will-change: width;

  ${(p) =>
    p.isSidebarOpen &&
    css`
      width: calc(100% - 400px);

      @media (max-width: 1365px) {
        ${WRPStyles.TodayButton},
        ${WRPStyles.ArrowsContainer} {
          display: none;
        }

        ${WRPStyles.DateContainer} {
          padding-left: 0;
        }
      }

      @media (max-width: 1200px) {
        ${WRPStyles.RangeLabelContainer} {
          display: none;
        }
      }

      @media (max-width: 1150px) {
        width: 100%;
      }
    `}
`;

export const TimeRangeInsightsContainer = styled.div`
  display: flex;
  ${(p) => !p.singleUserView && 'margin-left: auto;'}

  @media screen and (max-width: 1010px) {
    position: relative;
    top: -20px;
    left: 50px;
  }
`;
