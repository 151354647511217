import React from 'react';

import { useAppSelectorWithParams } from '@float/common/store';
import { FIN } from '@float/theme';
import { Button } from '@float/ui/components/Button';
import { DotSeparator } from '@float/ui/components/DotSeparator';
import { selectClientById } from '@float/web/selectors';
import { useSidePanel } from '@float/web/sidePanel/useSidePanel';

import { ColorPicker } from './ColorPicker';

import * as styles from './styles.css';

export const SingleProjectDetails = ({
  projectId,
  clientId,
  projectName,
  projectCode,
  projectColor,
}: {
  projectId: number;
  clientId: number;
  projectName: string;
  projectCode: string;
  projectColor: string;
}) => {
  const { showProjectSidePanel } = useSidePanel();
  const openProjectPanel = () => {
    showProjectSidePanel({
      projectId,
      hideDelete: false,
      hideTasksTab: false,
    });
  };

  const client = useAppSelectorWithParams(selectClientById, clientId);

  const showBullet = Boolean(projectCode && client?.client_name);
  return (
    <div className={styles.projectNameContainer}>
      <ColorPicker
        color={projectColor}
        clientId={clientId}
        projectId={projectId}
      />
      <div className={styles.projectDetailsContainer}>
        <Button
          onClick={openProjectPanel}
          appearance="clear-flay"
          size="xsmall"
          className={styles.projectName}
        >
          {projectName}
        </Button>
        <span
          className={styles.projectCodeAndClient}
          data-testid="projectCodeAndClient"
        >
          {projectCode}{' '}
          {showBullet ? <DotSeparator fill={FIN.Color.Text.Secondary} /> : ''}{' '}
          {client?.client_name}
        </span>
      </div>
    </div>
  );
};
