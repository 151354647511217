import { getSideCellWidth } from '@float/common/serena/util/getSideCellWidth';
import { SIDE_CELL_WIDTH_SMALL } from '@float/constants/schedule';

export type IsTodayVisibleParams = {
  dayWidth: number;
  isLogMyTimeView: boolean;
  isLogTimeView: boolean;
  scrollWrapper: HTMLDivElement;
  isSingleProjectView: boolean;
};

export const isTodayVisible = (params: IsTodayVisibleParams) => {
  const {
    dayWidth,
    isLogMyTimeView,
    isLogTimeView,
    scrollWrapper,
    isSingleProjectView,
  } = params;

  let isVisible = false;

  if (isLogMyTimeView || isLogTimeView) {
    const sideColumnSize =
      window.innerWidth <= 1010
        ? SIDE_CELL_WIDTH_SMALL
        : getSideCellWidth(isSingleProjectView);

    const todayTopCell = scrollWrapper.querySelector('[data-is-today]');

    let todayTopCellPosition;

    if (todayTopCell) {
      todayTopCellPosition = todayTopCell.getBoundingClientRect();
      isVisible =
        todayTopCellPosition.x > sideColumnSize &&
        todayTopCellPosition.x < window.innerWidth - dayWidth / 2;
    }
  }

  return isVisible;
};
