import { createSelector } from 'reselect';

import { LoadState } from '@float/common/reducers/lib/types';
import { SearchState } from '@float/common/reducers/search';

import { fastDictionary } from '../../lib/fast-object-spread';
import { getAccountCanOnlyViewThemself } from '../../lib/rights';
import { getCurrentUserRaw, selectCurrentUserAccount } from '../currentUser';
import { getLoggedTimesListRaw } from '../loggedTimes';
import { selectTasksList } from '../tasks/selectTasksList';
import { selectProjectTaskedPeople } from './search.helpers';

export const selectIsMeFilterActive = createSelector(
  [selectCurrentUserAccount, getCurrentUserRaw],
  (account, user) => {
    if (!user.people_id) return false;
    if (user.prefs?.me_filter) return true;

    if (!account) return false;

    return getAccountCanOnlyViewThemself(account);
  },
);

/**
 * Attention: all these selectors require the search data to work properly
 *
 * They must be used through the SearchService
 */

// @test-export
export const _TEST_EXPORT_getPhaseTaskedPeople = createSelector(
  [selectTasksList],
  (tasks) => {
    const phaseTaskedPeople = fastDictionary() as Record<string, Set<string>>;

    for (const task of tasks) {
      if (!task.phase_id) continue;

      let peopleIds = phaseTaskedPeople[task.phase_id];

      if (!peopleIds) {
        peopleIds = new Set();
        phaseTaskedPeople[task.phase_id] = peopleIds;
      }

      for (const id of task.people_ids) {
        peopleIds.add(String(id));
      }
    }

    return phaseTaskedPeople;
  },
);

export const getProjectsTasksIds = createSelector(
  [selectTasksList],
  (tasks) => {
    const projectTaskIds: Record<number, Set<string>> = fastDictionary();

    for (const task of tasks) {
      const taskIds = projectTaskIds[task.project_id];

      if (!taskIds) {
        projectTaskIds[task.project_id] = new Set([task.task_id]);
      } else {
        taskIds.add(task.task_id);
      }
    }

    return projectTaskIds;
  },
);

export const getProjectsWithTasks = createSelector(
  [selectTasksList, getLoggedTimesListRaw],
  (tasks, loggedTimes) => {
    const projectsWithTasks = new Set<number>();

    for (const task of tasks) {
      projectsWithTasks.add(task.project_id);
    }

    for (const loggedTime of loggedTimes) {
      if ('project_id' in loggedTime) {
        projectsWithTasks.add(loggedTime.project_id);
      }
    }

    return projectsWithTasks;
  },
);

export const getPeopleTasks = createSelector(
  [selectTasksList, getLoggedTimesListRaw],
  (tasks, loggedTimes) => {
    const peopleTasks: Record<number, Set<string>> = fastDictionary();

    for (const task of tasks) {
      for (const id of task.people_ids) {
        const taskNames = peopleTasks[id];
        const taskName = task.name || 'No task used';

        if (!taskNames) {
          peopleTasks[id] = new Set([taskName]);
        } else {
          taskNames.add(taskName);
        }
      }
    }

    for (const loggedTime of loggedTimes) {
      if ('people_id' in loggedTime && loggedTime.task_name) {
        const id = loggedTime.people_id;
        const taskNames = peopleTasks[id];

        if (!taskNames) {
          peopleTasks[id] = new Set([loggedTime.task_name]);
        } else {
          taskNames.add(loggedTime.task_name);
        }
      }
    }

    return peopleTasks;
  },
);

export const getPeopleWithTasks = createSelector(
  [selectTasksList, getLoggedTimesListRaw],
  (tasks, loggedTimes) => {
    const peopleWithTasks = new Set<number>();

    for (const task of tasks) {
      for (const id of task.people_ids) {
        peopleWithTasks.add(id);
      }
    }

    for (const loggedTime of loggedTimes) {
      if ('people_id' in loggedTime) {
        peopleWithTasks.add(loggedTime.people_id);
      }
    }

    return peopleWithTasks;
  },
);

export const isProjectWithTasks = createSelector(
  [getProjectsWithTasks, (_: unknown, projectId: number) => projectId],
  (projectsWithTasks, projectId) => {
    return projectsWithTasks.has(projectId);
  },
);

export const getTaskedPeopleByProjectId = createSelector(
  [
    selectProjectTaskedPeople,
    (_: unknown, projectId: number | undefined) => projectId,
  ],
  (projectTaskedPeople, projectId) => {
    if (!projectId) return new Set<string>();

    return projectTaskedPeople[projectId] || new Set<string>();
  },
);

export const isPersonTaskedInTheProject = createSelector(
  [
    selectProjectTaskedPeople,
    (_: unknown, projectId: number) => projectId,
    (_: unknown, __: number, peopleId: number) => peopleId,
  ],
  (projectTaskedPeople, projectId, peopleId) => {
    const taskedPeople = projectTaskedPeople[projectId];

    if (!taskedPeople) return false;

    return taskedPeople.has(String(peopleId));
  },
);

export const getTaskedPeopleByPhaseId = createSelector(
  [
    _TEST_EXPORT_getPhaseTaskedPeople,
    (_: unknown, phaseId: number | undefined) => phaseId,
  ],
  (phaseTaskedPeople, phaseId) => {
    if (!phaseId) return new Set<string>();

    return phaseTaskedPeople[phaseId] || new Set<string>();
  },
);

export const isPersonTaskedInThePhase = createSelector(
  [
    getTaskedPeopleByPhaseId,
    (_: unknown, __: unknown, peopleId: number | undefined) => peopleId,
  ],
  (taskedPeople, peopleId) => {
    return taskedPeople.has(String(peopleId));
  },
);

export const selectIsSearchContextLoaded = (state: { search: SearchState }) =>
  state.search.contextState === LoadState.LOADED;
