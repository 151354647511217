import React, { useId } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';

import { projects } from '@float/common/api3/projects';
import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';
import { rateValidationSuite } from '@float/libs/form/rateValidationSuite';
import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { NumericQuickInputField } from '@float/ui/components/NumericQuickInputField';
import { parseNumber } from '@float/ui/helpers/number/numberParser';

import { useValueOverride } from './PersonRateField.hooks';

export const PersonRateField = ({
  project,
  person,
}: {
  project: Project;
  person: Person;
}) => {
  const rate = parseNumber(
    project.people_rates?.[person.people_id] || '0',
  ) as number;
  const { valueOverride, isDisabled } = useValueOverride(project);

  const currencyProps = useCurrencyProps();
  const id = useId();

  const methods = useForm<{ rate: number }>({
    defaultValues: { rate },
    values: { rate },
    resetOptions: {
      keepDirtyValues: true,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: vestResolver(rateValidationSuite, undefined, {
      mode: 'sync',
    }),
  });

  const { handleSubmit } = methods;

  const formSubmit = handleSubmit(async (data: { rate: number }) => {
    if (data.rate === rate) return;
    await projects.updateProject({
      id: project.project_id,
      data: {
        project_team: {
          add: [
            {
              people_id: person.people_id,
              hourly_rate: data.rate,
            },
          ],
        },
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <NumericQuickInputField
        {...currencyProps}
        id={id}
        compact
        name={'rate'}
        placeholder={'0'}
        readOnly={Boolean(valueOverride)}
        valueOverride={valueOverride}
        onBlur={formSubmit}
        disabled={isDisabled}
      />
    </FormProvider>
  );
};
