import { toDash } from '@float/libs/utils/strings';
import type {
  PhaseEstimate,
  ProjectEstimate,
  TaskEstimate,
} from '@float/types/estimate';
import type { Project } from '@float/types/project';

import request from '../lib/request';
import { makeRequest } from './makeRequest';
import type { MakeRequestParams } from './makeRequest';

const RESOURCE = 'estimates';

export const estimates = {
  /**
   * Creates a new project estimate asynchronously
   * @param params - Request parameters containing project id
   * @returns Promise resolving to a process id string. The project estimate data will be delivered asynchronously via live updates once processing is complete.
   */
  createProjectEstimateAsync: async (
    params: MakeRequestParams<{ project_id: Project['project_id'] }>,
  ): Promise<string> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'POST',
    }),

  /**
   * Updates an existing project estimate
   * @param params - Request parameters containing project estimate baseline and project estimate id
   * @returns Promise resolving to the updated ProjectEstimate
   */
  updateProjectEstimate: async (
    params: MakeRequestParams<
      NonNullable<Pick<ProjectEstimate, 'baseline'>>,
      ProjectEstimate['id']
    >,
  ): Promise<ProjectEstimate> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'PATCH',
    }),

  /**
   * Deletes a project estimate
   * @param params - Request parameters containing project estimate id
   * @returns Promise that resolves when deletion is complete
   */
  deleteProjectEstimate: async (
    params: MakeRequestParams<undefined, ProjectEstimate['id']>,
  ): Promise<void> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'DELETE',
    }),

  /**
   * Retrieves a specific project estimate
   * @param params - Request parameters containing project estimate id
   * @returns Promise resolving to the requested ProjectEstimate
   */
  getProjectEstimate: async (
    params: MakeRequestParams<undefined, ProjectEstimate['id']>,
  ): Promise<ProjectEstimate> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    }),

  /**
   * Retrieves all phase estimates for a specific project estimate
   * @param params - Object containing the project estimate id
   * @returns Promise resolving to an array of PhaseEstimate objects
   */
  getPhaseEstimates: async ({
    id,
  }: {
    id: ProjectEstimate['id'];
  }): Promise<PhaseEstimate[]> => {
    return request.get(`${RESOURCE}/${String(id)}/phases`, null, {
      version: 'f3',
    });
  },

  /**
   * Retrieves all task estimates for a specific project estimate
   * @param params - Object containing the project estimate id
   * @returns Promise resolving to an array of TaskEstimate objects
   */
  getTaskEstimates: async ({
    id,
  }: {
    id: ProjectEstimate['id'];
  }): Promise<TaskEstimate[]> => {
    return request.get(`${RESOURCE}/${String(id)}/tasks`, null, {
      version: 'f3',
    });
  },
};
