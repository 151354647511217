import React from 'react';
import cx from 'classnames';
import { compose } from 'redux';

import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { withSnackbar } from '@float/ui/deprecated/Snackbar';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';
import withConfirmDelete from 'components/decorators/withConfirmDelete';

import { StyledAction, StyledColumnAction, StyledNoAction } from './styles';

import * as styles from './Table.css';

const DEFAULT_ACTIONS = ['edit', 'delete'];

class RowActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeletePopup: false,
      actionDisabled: false,
    };
    this.onClickAction_edit = this.onClickAction_edit.bind(this);
    this.onClickAction_delete = this.onClickAction_delete.bind(this);
    this.onClickConfirmDelete = this.onClickConfirmDelete.bind(this);
    this.getActionCallback = this.getActionCallback.bind(this);
    this.bindRowActions();
  }

  bindRowActions() {
    const { rowActions } = this.props;
    if (!rowActions) return;
    rowActions.forEach((rowAction) => {
      if (DEFAULT_ACTIONS.includes(rowAction.id)) return;
      this[`onClickAction_${rowAction.id}`] = this.onClickAction.bind(
        this,
        rowAction,
      );
    });
  }

  isEntity(entity) {
    const { recordNames } = this.props;
    return recordNames?.length && recordNames?.includes(entity);
  }

  onClickAction_edit() {
    const { onClickEdit, row } = this.props;
    onClickEdit(row);
  }

  onClickAction_delete() {
    const { people, projects, row } = this.props;
    if (this.isEntity('client')) {
      this.props.confirmDelete({
        ...this.props.confirmDeleteProps({ row }),
        onDelete: this.onClickConfirmDelete,
      });
      return;
    }
    if (this.isEntity('role')) {
      // RolesTable customizes the standard confirm/delete flow of withConfirmation
      // and handles its own delete process.
      return this.props.onClickDelete({
        entity: row.entity,
      });
    }
    this.props.confirm({
      ...this.props.confirmDeleteProps({ people, projects, id: row.id }),
      onConfirm: this.onClickConfirmDelete,
    });
  }

  onClickConfirmDelete() {
    const { onClickDelete, row } = this.props;
    const afterDelete = () => {
      this.props.showSnackbar(`${row.data[0]} deleted.`);
    };
    if (this.isEntity('client')) {
      return onClickDelete(row.id).then(afterDelete);
    }

    return onClickDelete(row.id).then(afterDelete);
  }

  onClickAction(rowAction, e) {
    const { row } = this.props;
    const callbackName = `onClickRowAction_${rowAction.id}`;
    this.setState({
      actionDisabled: rowAction.id,
    });
    this.props[callbackName](row.id).then(() => {
      this.setState({ actionDisabled: false });
      if (rowAction.id === 'resendinvite') {
        this.props.showSnackbar(`Invite for ${row.data[0][0]} resent.`);
      }
    });
  }

  getActionContent({ id, label }) {
    let content;

    switch (id) {
      case 'delete':
        content = <Icons.IconTrash className="delete" />;
        break;

      case 'edit':
        content = <Icons.IconPen />;
        break;

      case 'addchild':
        content = <Icons.IconPlus />;
        break;

      case 'resendinvite':
        content = <Icons.IconSend />;
        break;

      default:
        content = label;
        break;
    }

    return content;
  }

  getActionCallback(rowAction) {
    const { row } = this.props;

    if (rowAction.onClick) {
      return () => rowAction.onClick(row);
    }

    return this[`onClickAction_${rowAction.id}`];
  }

  getActions() {
    const { rowActions, row } = this.props;
    const action = row.actions || rowActions;
    return action;
  }

  renderActions() {
    const { actionDisabled } = this.state;
    const { inTableEditMode, row } = this.props;
    const rowActionsDisabled = inTableEditMode && inTableEditMode !== row.id;
    const numberOfRowsSelected = this.props.selectedRows?.size || 0;
    const areMultipleRowsSelected = numberOfRowsSelected > 1;

    const actions = this.getActions();

    if (!Array.isArray(actions)) {
      return null;
    }
    if (actions.length === 0) {
      return null;
    }

    return actions.map((rowAction) => {
      if (rowActionsDisabled) {
        return <StyledNoAction key={rowAction.id} />;
      }

      if (areMultipleRowsSelected) {
        return (
          <StyledAction
            key={rowAction.id}
            className={cx(rowAction.id, { disabled: true })}
            aria-label={rowAction.label}
          >
            {this.getActionContent(rowAction)}
          </StyledAction>
        );
      }

      return (
        <TextTooltip
          key={rowAction.id}
          content={rowAction.label}
          className="hover"
        >
          <StyledAction
            onClick={this.getActionCallback(rowAction)}
            className={cx(rowAction.id, {
              disabled: actionDisabled == rowAction.id,
            })}
            aria-label={rowAction.label}
          >
            {this.getActionContent(rowAction)}
          </StyledAction>
        </TextTooltip>
      );
    });
  }

  render() {
    const { virtualized } = this.props;

    const actions = this.getActions();
    if (!actions) {
      return null;
    }

    return (
      <StyledColumnAction className="cell" as={virtualized ? 'div' : 'td'}>
        <div className={styles.columnActionContainer}>
          {this.renderActions()}
        </div>
      </StyledColumnAction>
    );
  }
}

export default compose(
  withConfirm,
  withConfirmDelete,
  withSnackbar,
)(RowActions);
