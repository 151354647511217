import { useCallback } from 'react';
import { compact } from 'es-toolkit';

import { useAppSelectorStrict } from '@float/common/store';
import { getUser } from '@float/web/selectors';

import {
  getDepartmentOption,
  getEveryoneOption,
  PersonAccessOption,
} from '../PersonAccessOptions';
import { CanViewProps } from './types';

export function useCanViewController(props: CanViewProps) {
  const { initialPerson, account: accountForm, onChange } = props;

  const user = useAppSelectorStrict(getUser);
  const deptFilterCount = user.department_filter?.length ?? 0;
  const isCurrentUserRestrictedByDepartment = deptFilterCount > 0;

  const initialAccountBeingEdited = initialPerson?.account;

  const canCurrentlyViewEveryone =
    initialAccountBeingEdited &&
    (!initialAccountBeingEdited.department_filter ||
      !initialAccountBeingEdited.department_filter.length);

  const isRestrictingByDepartment = Boolean(accountForm?.department_filter_set);

  const value = isRestrictingByDepartment
    ? PersonAccessOption.Department
    : PersonAccessOption.Everyone;

  const shouldShowEveryoneOption =
    canCurrentlyViewEveryone || !isCurrentUserRestrictedByDepartment;

  const EveryoneOption = getEveryoneOption();
  const DepartmentOption = getDepartmentOption();

  const options = compact([
    shouldShowEveryoneOption && EveryoneOption,
    DepartmentOption,
  ]);

  const handleViewAccessChange = useCallback(
    (nextOption: { value: number }) => {
      switch (nextOption.value) {
        case PersonAccessOption.Department:
          onChange({
            department_filter_set: true,
            department_filter: accountForm?.prev_department_filter || [],
            prev_department_filter: [],
          });
          break;
        case PersonAccessOption.Everyone:
          onChange({
            department_filter_set: false,
            department_filter: [],
            prev_department_filter: accountForm?.department_filter,
          });
          break;
      }
    },
    [accountForm, onChange],
  );

  return {
    value,
    options,
    onChange: handleViewAccessChange,
  };
}
