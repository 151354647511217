import React, { forwardRef, useCallback, useMemo } from 'react';
import { LayoutProps, motion } from 'framer-motion';

import { useAppSelectorStrict } from '@float/common/store';
import { formatToFloatDate } from '@float/libs/dates';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { multiplyOperation } from '@float/libs/utils/floats';
import { TimeString } from '@float/types/datesManager';
import { RepeatState } from '@float/types/repeatState';
import { AllocationType } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';
import { getPeopleMapRaw, getTimeoffTypesMap } from '@float/web/selectors';

import { AllocationTypeToggle } from '../../components/AllocationTypeToggle/AllocationTypeToggle';
import {
  InputAllocationDateRange,
  InputAllocationDateRangeProps,
} from '../../components/InputAllocationDateRange/InputAllocationDateRange';
import { InputAllocationDateRangeHeader } from '../../components/InputAllocationDateRangeHeader/InputAllocationDateRangeHeader';
import {
  InputAllocationHours,
  InputAllocationHoursProps,
} from '../../components/InputAllocationHours/InputAllocationHours';
import { InputAllocationHoursHeader } from '../../components/InputAllocationHoursHeader/InputAllocationHoursHeader';
import { InputAllocationRepeat } from '../../components/InputAllocationRepeat';
import {
  InputAllocationTimeRange,
  InputAllocationTimeRangeProps,
} from '../../components/InputAllocationTimeRange/InputAllocationTimeRange';
import { InputAllocationTimeRangeHeader } from '../../components/InputAllocationTimeRangeHeader/InputAllocationTimeRangeHeader';
import { TimeoffApproveRejectHeader } from '../../components/TimeoffApproveRejectHeader/TimeoffApproveRejectHeader';
import { AllocationTimeSectionPayload } from '../../EditTaskModal.types';
import { getAllocationCorrectedHoursUsingHoursPerDay } from '../../helpers/getAllocationCorrectedHours';
import { useAllocationDaysHelpers } from '../../hooks/useAllocationDaysHelpers';
import { useAllocationRepeatControls } from '../../hooks/useAllocationRepeatControls';
import { useAllocationTypeControls } from '../../hooks/useAllocationTypeControls';
import { useIsTotalHoursInputSubdued } from '../../hooks/useIsTotalHoursInputSubdued';
import { useEditTaskModalController } from '../../useEditTaskModalController';
import { getIsFullDay } from './TimeoffTimeSection.helpers';

import * as styles from '../../EditTaskModal.css';

export type TimeoffTimeSectionProps = {
  endDate: Date;
  hasRecurringExternalCalendar: boolean;
  hoursPerDay: number | null;
  hoursTotal: number | null;
  is24HoursTimeFormat: boolean;
  isApproveRejectMode: boolean;
  isIntegrationSyncLocked: boolean;
  isIntervalRepeatable: boolean;
  isReadOnly: boolean;
  layout: LayoutProps['layout'];
  onChange: (data: AllocationTimeSectionPayload) => void;
  peopleIds: number[];
  repeatState: RepeatState;
  repeatEndDate: Date | null;
  startDate: Date;
  startTime: TimeString | null;
  timeoffId?: number;
  timeoff?: Timeoff;
};

export const TimeoffTimeSection = forwardRef<
  HTMLDivElement,
  TimeoffTimeSectionProps
>((props, ref) => {
  const {
    endDate,
    hasRecurringExternalCalendar,
    hoursPerDay,
    hoursTotal,
    is24HoursTimeFormat,
    isApproveRejectMode,
    isIntervalRepeatable,
    isIntegrationSyncLocked,
    isReadOnly,
    layout,
    onChange,
    peopleIds,
    repeatEndDate,
    repeatState,
    startDate,
    startTime,
    timeoffId,
    timeoff,
  } = props;

  const isAllocationByPercentageEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.AllocationByAvailabilityPercentage,
  );

  const timeoffTypesMap = useAppSelectorStrict(getTimeoffTypesMap);
  const peopleMap = useAppSelectorStrict(getPeopleMapRaw);

  const { getHasDifferentDailyWorkHoursInRange } = useEditTaskModalController();

  const {
    getIsWorkDay,
    getNumberOfAllocationDays,
    getEndDateFromTotalHours,
    getMinimumWorkHoursInRange,
  } = useAllocationDaysHelpers({
    peopleIds,
    timeoffId,
  });

  const numberOfAllocationDays = useMemo(() => {
    return getNumberOfAllocationDays(startDate, endDate);
  }, [startDate, endDate, getNumberOfAllocationDays]);

  const minimumWorkHoursInRange = getMinimumWorkHoursInRange(
    peopleIds,
    formatToFloatDate(startDate),
    formatToFloatDate(endDate),
  );

  const minimumHoursPerDayValue = 0.01;
  const maximumHoursPerDayValue = minimumWorkHoursInRange;

  const isFullDay = getIsFullDay({
    startTime,
    hoursPerDay,
    minimumWorkHoursInRange,
  });

  // The total hours might need a re-computation on mount
  useOnMount(() => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      isFullDay,
    });
  });

  const isTotalHoursInputSubdued = useIsTotalHoursInputSubdued(
    numberOfAllocationDays,
  );

  const hasDifferentDailyWorkHours = useMemo(() => {
    const startDateString = formatToFloatDate(startDate);
    const endDateString = formatToFloatDate(endDate);

    return getHasDifferentDailyWorkHoursInRange(
      startDateString,
      endDateString,
      peopleIds,
    );
  }, [peopleIds, startDate, endDate, getHasDifferentDailyWorkHoursInRange]);

  const hasApproveRejectHeader =
    isApproveRejectMode &&
    typeof timeoffId !== 'undefined' &&
    typeof timeoff !== 'undefined';

  const getIsDateDisabled = useCallback(
    (date: Moment) => !getIsWorkDay(date.toDate()),
    [getIsWorkDay],
  );

  const handleAllocationTypeChangeWithFullDayComputation = (
    payload: AllocationTimeSectionPayload,
  ) => {
    const isFullDay = getIsFullDay({
      startTime: payload.startTime,
      hoursPerDay: payload.hoursPerDay,
      minimumWorkHoursInRange,
    });

    onChange({
      ...payload,
      isFullDay,
    });
  };

  const {
    isAllocationByHoursPerDay,
    isAllocationByHoursSpecificTime,
    selectHoursPerDay,
    selectSpecificTime,
  } = useAllocationTypeControls({
    allocationType: AllocationType.Hours,
    hoursPerDay: hoursPerDay || 0,
    numberOfAllocationDays,
    startTime,
    peopleIds,
    isFixedEndDate: false,
    isAllocationByPercentDisabled: true,
    onChange: handleAllocationTypeChangeWithFullDayComputation,
    setAllocationSettings: () => {},
  });

  const handleHoursPerDayChange: InputAllocationHoursProps['onChangeHoursPerDay'] =
    (hoursPerDay) => {
      const isFullDay = getIsFullDay({
        hoursPerDay,
        minimumWorkHoursInRange,
      });

      onChange({
        hoursPerDay,
        isFullDay,
      });
    };

  const handleHoursTotalChange: InputAllocationHoursProps['onChangeHoursTotal'] =
    (hoursTotal) => {
      const endDate = getEndDateFromTotalHours({
        startDate,
        hoursPerDay: hoursPerDay ?? 0,
        hoursTotal: hoursTotal ?? hoursPerDay ?? 0,
      });

      const numberOfAllocationDays = getNumberOfAllocationDays(
        startDate,
        endDate,
      );

      const hoursPerDayRecomputed =
        typeof hoursTotal === 'number'
          ? hoursTotal / numberOfAllocationDays
          : 0;

      const isFullDay = getIsFullDay({
        hoursPerDay: hoursPerDayRecomputed,
        minimumWorkHoursInRange,
      });

      onChange({
        hoursTotal,
        endDate,
        isFullDay,
      });
    };

  const handleHoursPerDayBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = getIsFullDay({
      hoursPerDay: hoursPerDayCorrected,
      minimumWorkHoursInRange,
    });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      isFullDay,
    });
  };

  const handleHoursTotalBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay: Math.min(hoursPerDay ?? 0, hoursTotal ?? 0),
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = getIsFullDay({
      hoursPerDay: hoursPerDayCorrected,
      minimumWorkHoursInRange,
    });

    onChange({
      hoursTotal: hoursTotalCorrected,
      hoursPerDay: hoursPerDayCorrected,
      isFullDay,
    });
  };

  const handleTimeRangeChange: InputAllocationTimeRangeProps['onChange'] = (
    data,
  ) => {
    const { startTime, hoursPerDay } = data;

    const hoursTotal = multiplyOperation(hoursPerDay, numberOfAllocationDays);

    const isFullDay = getIsFullDay({
      startTime,
      hoursPerDay,
      minimumWorkHoursInRange,
    });

    onChange({
      startTime,
      hoursTotal,
      hoursPerDay,
      isFullDay,
    });
  };

  const handleDateRangeChange: InputAllocationDateRangeProps['onChange'] = (
    data,
  ) => {
    const { startDate, endDate } = data;

    const numberOfAllocationDays = getNumberOfAllocationDays(
      startDate,
      endDate,
    );

    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = getIsFullDay({
      startTime,
      hoursPerDay: hoursPerDayCorrected,
      minimumWorkHoursInRange,
    });

    onChange({
      startDate,
      endDate,
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      isFullDay,
    });
  };

  const { repeatTimes, handleRepeatEndDateChange, handleRepeatIntervalChange } =
    useAllocationRepeatControls({
      startDate,
      repeatState,
      repeatEndDate,
      onChange,
    });

  const hasAllocationTypeControlInFooter = !isReadOnly;

  const hasIntervalSelect = false;

  const allocationTypeSelect = undefined;

  const allocationTypeToggle = hasAllocationTypeControlInFooter ? (
    <AllocationTypeToggle
      appearence={isAllocationByPercentageEnabled ? 'flay-fill' : 'flue-fill'}
      isAllocationByHoursPerDay={isAllocationByHoursPerDay}
      isAllocationByHoursSpecificTime={isAllocationByHoursSpecificTime}
      hasIcon={isAllocationByPercentageEnabled}
      hasHoursPerDayLabel={isAllocationByPercentageEnabled}
      onSelectHoursPerDay={selectHoursPerDay}
      onSelectHoursSpecificTime={selectSpecificTime}
    />
  ) : null;

  return (
    <motion.div
      layout={layout}
      className={
        isReadOnly
          ? styles.timeSectionReadOnlyWrapper
          : styles.timeSectionWrapper
      }
      ref={ref}
    >
      {hasApproveRejectHeader && (
        <TimeoffApproveRejectHeader
          isReadOnly={isReadOnly}
          endDate={endDate}
          startDate={startDate}
          ignoreTimeoffId={timeoffId}
          repeatEndDate={repeatEndDate}
          repeatState={repeatState}
          hoursPd={hoursPerDay ?? 0}
          timeoff={timeoff}
          timeoffType={timeoffTypesMap[timeoff?.timeoff_type_id]}
          personId={peopleIds[0]}
          personStartDate={peopleMap[peopleIds[0]]?.start_date}
        />
      )}
      {isAllocationByHoursPerDay && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationHoursHeader
            allocationTypeSelect={allocationTypeSelect}
          />

          <InputAllocationHours
            hoursPerDay={hoursPerDay}
            hoursTotal={hoursTotal}
            isReadOnly={isReadOnly}
            isTotalHoursInputSubdued={isTotalHoursInputSubdued}
            hasVaryingDailyHours={isFullDay && hasDifferentDailyWorkHours}
            hasHoursPerDaySuffix={isAllocationByPercentageEnabled}
            onChangeHoursPerDay={handleHoursPerDayChange}
            onChangeHoursTotal={handleHoursTotalChange}
            onBlurHoursPerDay={handleHoursPerDayBlur}
            onBlurHoursTotal={handleHoursTotalBlur}
          />

          {allocationTypeToggle}
        </div>
      )}

      {isAllocationByHoursSpecificTime && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationTimeRangeHeader
            allocationTypeSelect={allocationTypeSelect}
          />

          <InputAllocationTimeRange
            hoursPerDay={hoursPerDay}
            startTime={startTime}
            isReadOnly={isReadOnly}
            is24HoursTimeFormat={is24HoursTimeFormat}
            onChange={handleTimeRangeChange}
          />

          {allocationTypeToggle}
        </div>
      )}

      <div className={styles.dateRangeColumn}>
        <InputAllocationDateRangeHeader
          numberOfAllocationDays={numberOfAllocationDays}
          startDate={startDate}
          endDate={endDate}
          hasIntervalSelect={hasIntervalSelect}
          onChange={handleDateRangeChange}
        />

        <InputAllocationDateRange
          startDate={startDate}
          endDate={endDate}
          isReadOnly={isReadOnly}
          getIsDateDisabled={getIsDateDisabled}
          onChange={handleDateRangeChange}
        />

        {isIntervalRepeatable && (
          <InputAllocationRepeat
            getIsDateDisabled={getIsDateDisabled}
            hasRecurringExternalCalendar={hasRecurringExternalCalendar}
            isIntegrationSyncLocked={isIntegrationSyncLocked}
            isReadOnly={isReadOnly}
            minRepeatDate={endDate}
            onChangeRepeatEndDate={handleRepeatEndDateChange}
            onChangeRepeatInterval={handleRepeatIntervalChange}
            repeatEndDate={repeatEndDate}
            repeatState={repeatState}
            repeatTimes={repeatTimes}
            startDate={startDate}
          />
        )}
      </div>
    </motion.div>
  );
});
