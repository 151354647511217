import React from 'react';
import { t } from '@lingui/macro';

import MultiSelect from '@float/ui/deprecated/MultiSelect';

import { DepartmentsAccessProps } from './types';
import { useDepartmentsAccessController } from './useDepartmentsAccessController';

export function DepartmentsAccess(props: DepartmentsAccessProps) {
  const { errors, value, options, handleAdd, handleRemove } =
    useDepartmentsAccessController(props);

  return (
    <MultiSelect
      label={t`Departments`}
      values={value}
      options={options}
      sort={false}
      visibleItems={6}
      errors={errors}
      onAdd={handleAdd}
      onRemove={handleRemove}
    />
  );
}
