import React from 'react';
import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types/project';
import {
  IconStatusConfirmed,
  IconStatusDraft,
  IconStatusTentative,
} from '@float/ui/components/StatusIcons';
import { SVGIconComponent } from '@float/ui/primitives/SVGIcon';

import { CalloutDraftStatus } from './components/CalloutDraftStatus';

import * as styles from './styles.css';

type NewProjectCardsProps = {
  handleCreateProject: (status: ProjectStatus) => void;
};

type NewProjectCardProps = {
  'data-callout-id'?: string;
  icon: SVGIconComponent;
  title: string;
  description: string;
  onClick: () => void;
};

function NewProjectCard(props: NewProjectCardProps) {
  return (
    <div
      data-callout-id={props['data-callout-id']}
      className={styles.newProjectCard}
      onClick={props.onClick}
    >
      {React.createElement(props.icon)}
      <div className={styles.newProjectCardContent}>
        <h2 className={styles.newProjectCardTitle}>{props.title}</h2>
        <p className={styles.newProjectCardDescription}>{props.description}</p>
      </div>
    </div>
  );
}

export function NewProjectCards({ handleCreateProject }: NewProjectCardsProps) {
  return (
    <>
      <div className={styles.newProjectCards}>
        <NewProjectCard
          data-callout-id="draftStatusCallout"
          icon={IconStatusDraft}
          title={t`Draft`}
          description={t`Admins and Project Owners view from the Project plan`}
          onClick={() => handleCreateProject(ProjectStatus.Draft)}
        />
        <CalloutDraftStatus />
        <NewProjectCard
          icon={IconStatusTentative}
          title={t`Tentative`}
          description={t`All team members can view but are not notified`}
          onClick={() => handleCreateProject(ProjectStatus.Tentative)}
        />
        <NewProjectCard
          icon={IconStatusConfirmed}
          title={t`Confirmed`}
          description={t`Team members can view and receive notifications`}
          onClick={() => handleCreateProject(ProjectStatus.Confirmed)}
        />
      </div>
    </>
  );
}
