import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import EHIconSearchFilter from '@float/ui/deprecated/Earhart/Icons/Icon/IconSearchFilter';
import { useOnClickOutside } from '@float/ui/deprecated/hooks/useOnClickOutside';

type FilterButtonProps = {
  active: boolean;
  closeAllDropdowns: () => void;
  excludeRefs: React.MutableRefObject<HTMLElement | null>[];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  showDropdown: boolean;
};

export const FilterButton = React.forwardRef<
  HTMLButtonElement,
  FilterButtonProps
>(function (props, ref) {
  const { active, closeAllDropdowns, excludeRefs, onClick, showDropdown } =
    props;

  useOnClickOutside(
    closeAllDropdowns,
    [...excludeRefs, ref] as React.MutableRefObject<HTMLElement | null>[],
    showDropdown,
  );

  return (
    <NavIconBtn
      isPrimary={false}
      onClick={onClick}
      className={classNames({ active })}
      iconLeft={<EHIconSearchFilter />}
      ref={ref}
    >
      <Trans>Filter</Trans>
    </NavIconBtn>
  );
});
