import { createSelector } from 'reselect';

import { selectIsMeFilterActive } from '../search';
import { getActiveFilters } from './getActiveFilters';

export const getAllActiveSearchFilters = createSelector(
  [getActiveFilters, selectIsMeFilterActive],
  (activeFilters, meFilter) => {
    const filters = activeFilters.map((filter) => ({
      type: filter.type,
      operator: filter.operator,
      val: filter.val,
    }));

    if (meFilter) {
      filters.unshift({
        type: 'me',
        operator: '',
        val: [],
      });
    }

    return filters;
  },
);
