import { keyBy, map } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import type { StatusType } from '@float/types';

import {
  STATUS_TYPE_UPDATED,
  STATUS_TYPES_LOAD_FAILED,
  STATUS_TYPES_LOAD_FINISH,
} from '../actions';
import { RehydratePartialStateAction } from './lib/types';

export type StatusTypesState = {
  statusTypes: Record<string, StatusType>;
  loadState: 'UNLOADED' | 'LOADED' | 'LOAD_FAILED';
  statusTypesLoaded: boolean;
};

const REDUCER_NAME = 'statusTypes';
const DEFAULT_STATE: StatusTypesState = {
  statusTypes: {},
  loadState: 'UNLOADED',
  statusTypesLoaded: false,
};

export type StatusTypesAction =
  | {
      type: typeof STATUS_TYPES_LOAD_FAILED;
    }
  | {
      type: typeof STATUS_TYPES_LOAD_FINISH;
      statusTypes: StatusType[];
    }
  | {
      type: typeof STATUS_TYPE_UPDATED;
      statusType: StatusType;
    };

const getStatusTypeModel = (statusType: StatusType) => ({
  ...statusType,
  status_type_name: statusType.status_type_name || 'Custom',
});

const statusTypes = (
  state = DEFAULT_STATE,
  action:
    | StatusTypesAction
    | RehydratePartialStateAction<StatusTypesState, typeof REDUCER_NAME>,
): StatusTypesState => {
  switch (action.type) {
    case STATUS_TYPES_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
        statusTypesLoaded: false,
        statusTypes: {},
      };
    }

    case STATUS_TYPES_LOAD_FINISH: {
      const types = map(action.statusTypes, getStatusTypeModel);
      return {
        ...state,
        loadState: 'LOADED',
        statusTypesLoaded: true,
        statusTypes: keyBy(types, 'status_type_id'),
      };
    }

    case STATUS_TYPE_UPDATED: {
      const { statusType } = action;
      if (!statusType) {
        return state;
      }

      return {
        ...state,
        statusTypes: {
          ...state.statusTypes,
          [statusType.status_type_id]: getStatusTypeModel(statusType),
        },
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.statusTypesLoaded ? 'LOADED' : 'UNLOADED';

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default:
      return state;
  }
};

export default statusTypes;
