import { get, keyBy, map, orderBy, reduce, sortBy, uniq } from 'lodash';
import { createSelector, Selector } from 'reselect';

import { getUserCanSeeProjectBudget } from '@float/common/lib/acl/getUserCanSeeProjectBudget';
import { sortByName } from '@float/common/lib/itemSort';
import { ProjectsState } from '@float/common/reducers/projects';
import { ProjectBudgetType, ProjectStatus } from '@float/constants/projects';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import type { AccountsState } from '@float/common/reducers/accounts';
import type { SearchState } from '@float/common/reducers/search';
import type {
  Account,
  CompanyPreferences,
  CurrentUser,
  EnhancedProject,
  Person,
  Phase,
  Project,
  ProjectOptions,
} from '@float/types';

import { Access, Rights } from '../../lib/acl';
import { fastObjectSpread } from '../../lib/fast-object-spread';
import { projectEditable } from '../../lib/rights';
import { stringCompare } from '../../lib/sort';
import { LoadState } from '../../reducers/lib/types';
import { getClientsMap } from '../clients';
import { getUser } from '../currentUser';
import { getPeopleMap } from '../people/people';
import { getProjectPhases } from '../phases';
import { getProjectsTagsValues } from '../tags';
import { getProjectOption } from './getProjectOption';
import { getProjectsRawList } from './getProjectsRawList';

const isMoneyBudgetType = (budget_type?: number) =>
  budget_type === 2 || budget_type === 3;

export type ProjectWithExtraData = ReturnType<typeof getProjects>[0];

// OPTIMIZATION: Cache the all people ids calculation
// by project people and phases
const selectProjectAllPeopleIds = createSelector(
  [
    (project: Project) => project.people_ids,
    (_: Project, phases: Phase[] | undefined) => phases,
  ],
  (peopleIds, phases = []) => {
    if (!phases.length) return peopleIds;

    const values = peopleIds.slice();

    for (const phase of phases) {
      for (const id of phase.people_ids) {
        values.push(id);
      }
    }

    return Array.from(new Set(values));
  },
);

export const selectIsProjectCodesEnabled = (state: {
  companyPrefs: CompanyPreferences;
}) => {
  const isProjectCodesFeatureFlagEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.ProjectCodes,
  );

  // (enabled by default): Because project_codes is undefined in companyPrefs until explicitly set in admin settings,
  // this check enables the feature for all teams that haven't yet explicitly disabled the setting.
  return (
    isProjectCodesFeatureFlagEnabled &&
    (typeof state.companyPrefs.project_codes === 'undefined' ||
      Boolean(state.companyPrefs.project_codes))
  );
};

export const getProjects = createSelector(
  [getProjectsRawList, getClientsMap, getUser, getProjectPhases],
  (
    projects,
    clients,
    user: CurrentUser,
    projectPhases: Record<number, Phase[]>,
  ) =>
    projects.map((project) => {
      const clientName =
        (project.client_id && clients[project.client_id]?.client_name) ??
        'No Client';

      const longName = `${project.project_name}${
        clientName === 'No Client' ? '' : ` / ${clientName}`
      }`;

      const phases = projectPhases[project.project_id];

      const result = fastObjectSpread(project, {
        canEdit: project.canEdit ?? projectEditable(project, user),
        client_name: clientName,
        long_name: longName,
        canSeeBudget: getUserCanSeeProjectBudget(project, user),
        people_rates: project.people_rates || {},
        isMoneyBudgetType: isMoneyBudgetType(project.budget_type),
        all_people_ids: selectProjectAllPeopleIds(project, phases),
      });

      return result as EnhancedProject;
    }),
);

export const getProjectsMap = createSelector([getProjects], (projects) =>
  keyBy(projects, 'project_id'),
);

export const getProjectsByClient = createSelector(
  [getProjectsRawList, getClientsMap],
  (projects, clients) => {
    const groupedProjects = reduce(
      projects,
      (acc, project) => {
        const client = project.client_id && clients[project.client_id];
        const clientName = get(client, 'client_name', 'No Client');
        (acc[clientName] || (acc[clientName] = [])).push(project);
        return acc;
      },
      {} as Record<string, Project[]>,
    );

    return sortBy(
      map(groupedProjects, (clientProjects, name) => {
        return {
          name,
          items: sortBy(clientProjects, 'project_name'),
        };
      }),
      'name',
    );
  },
);

export const getProjectsByClientId = createSelector(
  [getProjectsRawList, getClientsMap],
  (projects, clients) => {
    return reduce(
      projects,
      (acc, project) => {
        const client = project.client_id && clients[project.client_id];
        const clientId = get(client, 'client_id', 0);
        (acc[clientId] || (acc[clientId] = [])).push(project);
        return acc;
      },
      {} as Record<number, Project[]>,
    );
  },
);

export const getLastProject = (state: {
  lastUpdated: { project: Project | undefined };
}): Project | undefined => state.lastUpdated?.project;

export const getDefaultDropdownProject = createSelector(
  [getLastProject, (state) => state.projects.projects, getUser],
  (lastUpdatedProject, projects: Record<string, Project>, user) => {
    if (lastUpdatedProject && lastUpdatedProject.project_id) {
      const project = projects[lastUpdatedProject.project_id];
      if (project && project.active && projectEditable(project, user))
        return lastUpdatedProject;
    }

    for (const [id, project] of Object.entries(projects)) {
      if (projectEditable(project, user) && project.active) {
        return { project_id: id };
      }
    }

    return {};
  },
);

export const getHaveProjectsBeenLoaded = (state: { projects: ProjectsState }) =>
  state.projects.loadState === LoadState.LOADED;

export const getHaveProjectsWithContextBeenLoaded = (state: {
  search: SearchState;
  projects: ProjectsState;
}) =>
  state.search.contextState === LoadState.LOADED &&
  state.projects.loadState === LoadState.LOADED;

export const getAccessibleProjects = createSelector(
  [getProjects, getPeopleMap, getUser],
  (projects, peopleMap: Record<number, Person>, user: CurrentUser) => {
    const person = user.people_id && peopleMap[user.people_id];
    return projects.filter((project) => {
      if (project.status === ProjectStatus.Draft) {
        return false;
      }

      const hasAccess = Access.canAssignProject(user, { project, person });
      // isEditableProjectForMember(project, person, user)
      const res = +project.active && (project.canEdit || hasAccess);
      return res;
    });
  },
);

const getPMLoggableProjects = createSelector(
  [getProjects, getPeopleMap, getUser],
  (projects, peopleMap: Record<number, Person>, user: CurrentUser) => {
    const person = user.people_id && peopleMap[user.people_id];
    return projects.filter(
      (project) =>
        +project.active &&
        project.status !== ProjectStatus.Draft &&
        (project.canEdit ||
          (person && person.project_ids.includes(project.project_id))),
    );
  },
);

const groupProjects = (
  projects: ReturnType<typeof getAccessibleProjects>,
  isProjectCodesEnabled: boolean,
) => {
  const groups = new Map<string, ProjectOptions>();

  for (const project of projects) {
    const key =
      project.client_name === 'null' || project.client_name === 'undefined'
        ? 'No Client'
        : project.client_name;

    const group = groups.get(key);

    if (!group) {
      groups.set(key, {
        name: key,
        value: key,
        options: [getProjectOption(project, isProjectCodesEnabled)],
      });
    } else {
      group.options.push(getProjectOption(project, isProjectCodesEnabled));
    }
  }

  // Before sorting the results, remove the "No Client" option
  // to place it on the bottom
  const noClientOption = groups.get('No Client');
  if (noClientOption) groups.delete('No Client');

  const result = Array.from(groups.values());

  result.sort((a, b) =>
    stringCompare(a.name.toLowerCase(), b.name.toLowerCase()),
  );

  if (noClientOption) result.push(noClientOption);

  // Sort all the options by label (project_name)
  for (const { options } of result) {
    options.sort((a, b) => stringCompare(a.label, b.label));
  }

  return result;
};

/**
 * Creates a selector that transforms projects into project options, considering the feature flag for Project Codes.
 *
 * @param {Selector<State, EnhancedProject[]>} projectsSelector - The selector to get the list of projects from the state.
 * @returns {Selector<State, ProjectOption[]>} A selector that returns the transformed project options.
 *
 * @example
 * const getProjectsOptions = createProjectsOptionsSelector(getAccessibleProjects);
 */
const createProjectsOptionsSelector = (
  projectsSelector: Selector<any, EnhancedProject[]>,
) => {
  return createSelector(
    [projectsSelector, selectIsProjectCodesEnabled],
    (projects: EnhancedProject[], isProjectCodesEnabled: boolean) => {
      return groupProjects(projects, isProjectCodesEnabled);
    },
  );
};

export const getProjectsOptions = createProjectsOptionsSelector(
  getAccessibleProjects,
);

export const getUnlockedTaskListProjectsOptions = createProjectsOptionsSelector(
  createSelector([getAccessibleProjects], (projects) =>
    projects.filter((p) => !p.locked_task_list),
  ),
);
export const getNonTentativeUnlockedTaskListProjectsOptions =
  createProjectsOptionsSelector(
    createSelector([getAccessibleProjects], (projects) =>
      projects.filter((p) => !p.locked_task_list && !p.tentative),
    ),
  );

export const getAllProjectsOptions = createProjectsOptionsSelector(getProjects);

export const getPMLoggableNonTentativeProjectsOptions =
  createProjectsOptionsSelector(
    createSelector([getPMLoggableProjects], (projects) =>
      projects.filter((p) => !p.tentative),
    ),
  );

export const getNonTentativeProjectsOptions = createProjectsOptionsSelector(
  createSelector([getAccessibleProjects], (projects) =>
    projects.filter((p) => !p.tentative),
  ),
);

export const getProjectsSortedByCreation = createSelector(
  [getProjectsRawList],
  (projects) => {
    return orderBy(
      projects,
      (p) => (p.created ? new Date(p.created).getTime() : p.project_id),
      'desc',
    );
  },
);

export const getClientOptions = createSelector(getClientsMap, (clients) => {
  return sortBy(
    map(clients, (c) => ({
      value: c.client_id,
      label: c.client_name,
    })),
    (c) => c.label?.toLowerCase() || '',
  );
});

export const getRecentProjectColors = createSelector(
  getProjectsSortedByCreation,
  (projects) => uniq(map(projects, (p) => p.color)),
);

export const getProjectTagOptions = createSelector(
  [getProjectsTagsValues],
  (tags) => {
    return tags.map((t) => ({ label: t, value: t }));
  },
);

export const getAssignablePmOptions = createSelector(
  (state: { accounts: AccountsState }) => state.accounts.accounts,
  (accounts: Record<number, Account>) => {
    return sortBy(
      map(accounts, (a) => ({
        value: a.account_id,
        label: a.name,
        account: a,
      })),
      (p) => p.label.toLowerCase(),
    );
  },
);

export const getTemplates = createSelector(
  [(state: { projects: ProjectsState }) => state.projects.templates],
  (templateMap) => sortByName(Object.values(templateMap), 'project_name'),
);

export const getTemplateById = createSelector(
  [
    (state: { projects: ProjectsState }) => state.projects.templates,
    (_: unknown, id: number | undefined) => id,
  ],
  (templates, id) => {
    if (!id) return undefined;

    return templates[id];
  },
);

export const getAccessibleTemplates = createSelector(
  [getTemplates, getUser],
  (templates, currentUser: CurrentUser) => {
    const canViewBudgets = Rights.canViewBudget(currentUser);
    if (!canViewBudgets) {
      return templates.filter(
        (x) =>
          typeof x.budget_type !== 'number' ||
          [ProjectBudgetType.Disabled, ProjectBudgetType.TotalHours].includes(
            x.budget_type,
          ),
      );
    }
    return templates;
  },
);

export const getSyncIcon = createSelector(
  [
    (state: { projects: ProjectsState }) => state.projects.syncIcon,
    (_: unknown, coIntId: number) => coIntId,
    (_: unknown, __: unknown, projectId: number) => projectId,
  ],
  (syncIconState, coIntId, projectId) => {
    return get(syncIconState, `${coIntId}.data.${projectId}`);
  },
);
