import { CurrentUser } from '@float/types/account';

import { getAccount } from '../../lib/helpers';

export const hasNoDepartmentFilter = (user: CurrentUser) => {
  const account = getAccount(user);
  const viewableDeps =
    account.department_filter_all || account.department_filter || [];
  return viewableDeps.length === 0;
};
