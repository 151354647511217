import React from 'react';
import { t } from '@lingui/macro';

import {
  IconForbidden,
  IconGlob,
  IconUser,
} from '@float/ui/deprecated/Earhart/Icons';
import { IconBuilding } from '@float/ui/icons/essentials/IconBuilding';

export enum PersonAccessOption {
  Themself = 0,
  Everyone = 1,
  Department = 2,
  NoOne = 3,
  Everything = 4,
}

export const getEveryoneOption = () => ({
  label: t`Everyone`,
  description: t`All users on account`,
  icon: <IconGlob />,
  value: PersonAccessOption.Everyone,
});

export const getEveryThingOption = () => ({
  label: t`All People, Projects, and Settings`,
  description: t`All People, Projects, and Settings`,
  value: PersonAccessOption.Everything,
  icon: <IconGlob />,
});

export const getEverythingBillingOption = () => ({
  label: t`Billing, all people, projects and settings`,
  description: t`Billing, all people, projects and settings`,
  value: PersonAccessOption.Everything,
  icon: <IconGlob />,
});

export const getDepartmentOption = () => ({
  label: t`Departments`,
  description: t`Selected departments`,
  icon: <IconBuilding />,
  value: PersonAccessOption.Department,
});

export const getThemselfOption = () => ({
  label: t`Themself`,
  description: t`Just themself`,
  value: PersonAccessOption.Themself,
  icon: <IconUser />,
});

export const getNoOneOption = () => ({
  label: t`No one`,
  value: PersonAccessOption.NoOne,
  description: t`Including themself`,
  icon: <IconForbidden />,
});
