import { t } from '@lingui/macro';

import Tags from '../Tags';

export function getBulkEditTagsField() {
  return {
    label: t`Tags`,
    render: Tags,
    defaultValue: [],
  };
}
