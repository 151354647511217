import { CellItem } from '@float/types';

import { SELECTION_ID } from '../selection';
import { CellRenderArgs } from '../types';
import { getNonWorkSubCols } from './getNonWorkSubCols';

export function addSelection(
  args: CellRenderArgs,
  excludeNonWorkDaysGaps?: boolean,
) {
  const { cell, cellKey, cells, firstCellDay, fns, props, lastCellDay } = args;

  const { bimaps, dates, leftHiddenDays, maps, rightHiddenDays } = props;

  const { selection: selectionsBimap } = bimaps;
  const { selection: selectionsMap } = maps;
  const { getHorizontalDimensions } = fns;
  const { rowId } = cell;

  const selectionIds = selectionsBimap.getRev(cellKey);
  selectionIds.forEach((selectionId) => {
    const s = selectionsMap[selectionId];
    const { x, w } = getHorizontalDimensions(s, firstCellDay, lastCellDay);

    const nonWorks = excludeNonWorkDaysGaps
      ? []
      : getNonWorkSubCols(
          rowId,
          firstCellDay,
          x,
          w,
          dates,
          cells,
          fns.isWorkDay,
        );

    let curX = x;
    let curW = 0;

    do {
      while (nonWorks.includes(curX) && curX < x + w) {
        curX++;
      }

      while (curX + curW < x + w && !nonWorks.includes(curX + curW)) {
        curW++;
      }

      if (curW > 0) {
        const cellItem: CellItem<'selection'> = {
          cellKey,
          key: `${cellKey}:selection:${SELECTION_ID}:${curX}`,
          type: 'selection',
          entity: s,
          entityId: SELECTION_ID,
          x: curX,
          w: curW,
        };

        cell.items.push(cellItem);
      }

      curX = curX + curW + 1;
      curW = 0;
    } while (curX < 7 - leftHiddenDays - rightHiddenDays);
  });
}
