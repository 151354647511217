import { t } from '@lingui/macro';

import { getIsCurrencyPrefix } from '@float/common/lib/currency';
import { getCurrencySymbol } from '@float/libs/currency/getCurrencySymbol';

const getHoursBasedSuffix = () => t`/hr`;

type UseFormattedCurrencyParams = {
  currency: string;
  currencySymbol?: string;
  isHoursBased?: boolean;
  locale: string;
};

export const useFormattedCurrency = (
  params: UseFormattedCurrencyParams,
): {
  prefix?: string;
  suffix?: string;
} => {
  const { currency, currencySymbol, isHoursBased, locale } = params;
  const formattedCurrencySymbol =
    currencySymbol || getCurrencySymbol(locale, currency);

  const result = getIsCurrencyPrefix(locale)
    ? {
        prefix: formattedCurrencySymbol,
        suffix: isHoursBased ? getHoursBasedSuffix() : undefined,
      }
    : {
        suffix:
          formattedCurrencySymbol + (isHoursBased ? getHoursBasedSuffix() : ''),
      };

  return result;
};
