import React from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmConfig } from 'manage/people-v2/People';

import { isAmountEmpty } from '@float/common/lib/budget';
import {
  ConfirmCheckboxAppearance,
  ModalConfirmAppearance,
} from '@float/ui/components/Modals/ModalConfirm';
import { IconThumbsUp } from '@float/ui/icons/essentials/IconThumbsUp';
import { ModalConfirmDeleteConfig } from '@float/web/modalManager/hoc/withModalConfirmDelete';
import type {
  CompanyPreferences,
  PartialRecord,
  Person,
  Role,
} from '@float/types';

import { canActivatePerson } from './helpers/canActivatePerson';

export async function toggleArchiveStatus({
  companyPrefs,
  confirm,
  newPerson,
  onLimitReached,
  personNameElement,
  triggerSave,
}: {
  companyPrefs: CompanyPreferences;
  confirm: (confirmConfig: ConfirmConfig) => void;
  newPerson: Person;
  onLimitReached: () => void;
  personNameElement?: { focusInput: () => void };
  triggerSave: (person: Person) => Promise<void>;
}) {
  const isActive = newPerson.active;

  if (!isActive && !canActivatePerson(newPerson, companyPrefs)) {
    onLimitReached();
    return;
  }

  const newPersonName = newPerson.name;
  const value = isActive ? 0 : 1;
  const title = isActive ? t`Archive ${newPersonName}?` : t`Move to active`;
  const message = isActive ? (
    <>
      <p>
        <Trans>What this means:</Trans>
      </p>
      <ul>
        <li>
          <Trans>All historical data will be retained in Float</Trans>
        </li>
        <li>
          <Trans>
            Any access is removed, so they will no longer be able to sign in
          </Trans>
        </li>
      </ul>
      <p>
        <Trans>Anyone you archive can be restored from the People page.</Trans>
      </p>
    </>
  ) : (
    <p>
      <Trans>
        <span>Move </span>
        <strong>{newPersonName}</strong>
        <span> to active?</span>
      </Trans>
    </p>
  );
  const confirmLabel = isActive ? t`Archive ${newPersonName}` : title;
  const confirmButtonAppearance = isActive ? 'danger' : undefined;

  confirm({
    title,
    message,
    confirmLabel,
    confirmButtonAppearance,
    onCancel: personNameElement?.focusInput,
    onConfirm: () => triggerSave({ ...newPerson, active: value }),
  });
}

export type RoleChangeWarningProps = {
  isAdding: boolean;
  person: Person;
  newPerson: Person;
  roles: PartialRecord<number, Role>;
  confirmDelete: (config: ModalConfirmDeleteConfig) => void;
  triggerSave: (person: Person) => Promise<void>;
};

function getPersonRole(person: Person, roles: PartialRecord<number, Role>) {
  const defaultRole = {
    name: person.role_id,
    cost_rate: undefined,
    default_hourly_rate: undefined,
  };

  if (!person.role_id) return defaultRole;

  return roles[person.role_id] || defaultRole;
}

export function showRoleChangeWarningIfNeeded(props: RoleChangeWarningProps) {
  const { isAdding, person, newPerson, roles, confirmDelete, triggerSave } =
    props;

  if (isAdding || !newPerson.role_id) {
    return false;
  }
  const hasRoleChanged = person.role_id !== newPerson.role_id;
  if (!hasRoleChanged) {
    return false;
  }

  const oldRole = getPersonRole(person, roles);
  const newRole = getPersonRole(newPerson, roles);

  const wasInheritingBillRateFromOldRole =
    isAmountEmpty(newPerson.default_hourly_rate) &&
    !isAmountEmpty(oldRole.default_hourly_rate);

  const wasInheritingCostRateFromOldRole =
    isAmountEmpty(newPerson.cost_rate) && !isAmountEmpty(oldRole.cost_rate);

  const willInheritBillRateFromNewRole =
    isAmountEmpty(newPerson.default_hourly_rate) &&
    !isAmountEmpty(newRole.default_hourly_rate);

  const willInheritCostRateFromNewRole =
    isAmountEmpty(newPerson.cost_rate) && !isAmountEmpty(newRole.cost_rate);

  if (
    !wasInheritingBillRateFromOldRole &&
    !wasInheritingCostRateFromOldRole &&
    !willInheritBillRateFromNewRole &&
    !willInheritCostRateFromNewRole
  ) {
    // No impact to inherited rates. No need to show warning.
    return false;
  }

  const personName = newPerson.name;
  const newRoleName = newRole.name;

  confirmDelete({
    title: t`Update Role for “${personName}” to “${newRoleName}”?`,
    message: (
      <>
        <Trans>
          <p>
            Changing their Role will replace the current Role rates with “
            {newRoleName}” Role rates.
          </p>
          <p>
            New rates will not affect custom Person, Project, or Phase rates.
          </p>
        </Trans>
      </>
    ),
    appearance: ModalConfirmAppearance.Info,
    confirmCheckboxAppearance: ConfirmCheckboxAppearance.Warning,
    confirmCheckboxIcon: IconThumbsUp,
    twoStep: true,
    deleteLabel: t`Update Role`,
    onDelete: () => triggerSave(newPerson),
  });

  return true;
}
