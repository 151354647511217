import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createContext } from 'use-context-selector';

import { MediaSize } from '@float/constants/media';
import { useSafeContext } from '@float/libs/hooks/useSafeContext';
import { media, useListenForWindowResize } from '@float/libs/media';

export type MediaContextType = { activeMediaSize: MediaSize };

export const MediaContext = createContext<MediaContextType | null>(null);

export const MediaContextProvider: React.FC<{
  children?: ReactNode;
  overrides?: Partial<MediaContextType>;
}> = ({ children, overrides }) => {
  const [activeMediaSize, setactiveMediaSize] = useState<MediaSize>(
    media.activeMediaSize,
  );

  const activeMediaSizeRef = useRef<MediaSize>(media.activeMediaSize);

  const updateactiveMediaSizeIfChanged = useCallback(() => {
    if (media.activeMediaSize !== activeMediaSizeRef.current) {
      setactiveMediaSize(media.activeMediaSize);
      activeMediaSizeRef.current = media.activeMediaSize;
    }
  }, []);

  useListenForWindowResize(updateactiveMediaSizeIfChanged, {
    // If we don't run on mount, we won't have the correct breakpoint
    // until the user resizes the window.
    runOnMount: true,
  });

  const memoizedContext = useMemo(
    () => ({
      activeMediaSize,
      ...overrides,
    }),
    [overrides, activeMediaSize],
  );

  return (
    <MediaContext.Provider value={memoizedContext}>
      {React.useMemo(() => children, [children])}
    </MediaContext.Provider>
  );
};

/**
 * 🚨🚨🚨
 * Prefer using the hooks exported from `@float/libs/hooks/media`
 * instead of this context.
 * 🚨🚨🚨
 */
export const useMediaContext = () =>
  useSafeContext(MediaContext, 'MediaContext');
