import { memoize } from 'proxy-memoize';

import { AppInfoState } from '@float/common/reducers/appInfo';
import { ScheduleViewType } from '@float/constants/schedule';

import { getLocationPathname } from '../location';

export const selectIsLogTimeViewType = (state: { appInfo?: AppInfoState }) => {
  return (
    Boolean(state?.appInfo?.logTimeView) &&
    state?.appInfo?.viewType !== ScheduleViewType.Projects
  );
};

export const selectScheduleViewType = (state: { appInfo?: AppInfoState }) => {
  return state?.appInfo?.viewType || ScheduleViewType.People;
};

export const getIsProjectPlanView = memoize(
  (state: { appInfo?: AppInfoState }): boolean => {
    return selectScheduleViewType(state) === ScheduleViewType.Projects;
  },
);

export const getIsLogTimeView = memoize(
  (state: {
    router?: { location?: { pathname?: string } };
    appInfo?: AppInfoState;
  }): boolean => {
    const location = getLocationPathname(state);
    const logTimeView = selectIsLogTimeViewType(state);

    if (location === '/log-time') return true;

    return logTimeView && location === '/';
  },
);
