import { t } from '@lingui/macro';
import { forEach, map } from 'lodash';

import { formatAmount } from '@float/common/lib/budget';
import { ProjectBudgetType } from '@float/constants/projects';

import { getTotalsByPerson } from './getTotalsByPerson';

export function getScheduledTable(ctx, raw) {
  const { project, people, hasBudgetsAccess, hasCostsAccess } = ctx;

  const hasFeeColumns = hasBudgetsAccess;
  const hasCostColumns = hasCostsAccess;

  const headers = [
    {
      label: t`Name`,
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Scheduled`,
      width: 140,
      allowOverflow: true,
      formatter: (x) =>
        x === '' ? '' : formatAmount(ProjectBudgetType.TotalHours, x),
    },
  ];

  if (hasCostColumns) {
    headers.push({
      label: t`Scheduled cost`,
      width: 160,
      formatter: (x) =>
        x === '' ? '' : formatAmount(ProjectBudgetType.TotalFee, x),
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPerson = getTotalsByPerson(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    costCents: 0,
  };

  const rows = [];
  forEach(byPerson, (o, personId) => {
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const cost = hasCostColumns ? o.costCents / 100 : '';
    const hours = o.scheduled;
    const person = people[personId];

    totals.hours += o.scheduled;
    if (hasFeeColumns) totals.feeCents += o.feeCents;
    if (hasCostColumns) totals.costCents += o.costCents;

    const data = [person.name, fee, hours];

    if (hasCostColumns) {
      data.push(cost);
    }

    rows.push({
      id: personId,
      data,
      children: map(o.children, (c, taskName) => {
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childCost = hasCostColumns ? c.costCents / 100 : '';
        const childHours = c.scheduled;

        const childData = [taskName, childFee, childHours];

        if (hasCostColumns) {
          childData.push(childCost);
        }

        return {
          data: childData,
        };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
  ];

  if (hasCostColumns) {
    footer.push({ label: totals.costCents / 100 });
  }

  return { headers, rows, footer };
}

export function getLoggedTable(ctx, raw) {
  const { project, people, hasBudgetsAccess, hasCostsAccess } = ctx;

  const hasFeeColumns = hasBudgetsAccess;
  const hasCostColumns = hasCostsAccess;

  const headers = [
    {
      label: t`Name`,
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Logged`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasCostColumns) {
    headers.push({
      label: t`Logged cost`,
      width: 160,
      formatter: (x) =>
        x === '' ? '' : formatAmount(ProjectBudgetType.TotalFee, x),
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPerson = getTotalsByPerson(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    costCents: 0,
  };

  const rows = [];
  forEach(byPerson, (o, personId) => {
    const fee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const cost = hasCostColumns ? o.logged.costCents / 100 : '';
    const hours = o.logged.scheduled;
    const person = people[personId];

    totals.hours += o.logged.scheduled;
    if (hasFeeColumns) totals.feeCents += o.logged.feeCents;
    if (hasCostColumns) totals.costCents += o.logged.costCents;

    const data = [person.name, fee, hours];

    if (hasCostColumns) {
      data.push(cost);
    }

    rows.push({
      id: personId,
      data,
      children: map(o.children, (c, taskName) => {
        const childFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childCost = hasCostColumns ? c.logged.costCents / 100 : '';
        const childHours = c.logged.scheduled;

        const childData = [taskName, childFee, childHours];

        if (hasCostColumns) {
          childData.push(childCost);
        }

        return {
          data: childData,
        };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
  ];

  if (hasCostColumns) {
    footer.push({ label: totals.costCents / 100 });
  }

  return { headers, rows, footer };
}

export function getCompareTable(ctx, raw) {
  const { project, people } = ctx;
  const hasFeeColumns = project.budget_type === 2 || project.budget_type === 3;

  const headers = [
    {
      label: t`Name`,
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Logged`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Scheduled`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Difference`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPerson = getTotalsByPerson(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows = [];
  forEach(byPerson, (o, personId) => {
    const person = people[personId];
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = o.scheduled;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = o.logged.scheduled;
    const diffFee = hasFeeColumns ? (o.feeCents - o.logged.feeCents) / 100 : '';
    const diffHours = hours - loggedHours;

    totals.hours += o.scheduled;
    totals.loggedHours += o.logged.scheduled;
    if (hasFeeColumns) {
      totals.feeCents += o.feeCents;
      totals.loggedFeeCents += o.logged.feeCents;
    }

    rows.push({
      id: personId,
      data: [
        person.name,
        loggedFee,
        loggedHours,
        fee,
        hours,
        diffFee,
        diffHours,
      ],
      children: map(o.children, (c, taskName) => {
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = c.scheduled;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = c.logged.scheduled;
        const childDiffFee = hasFeeColumns
          ? (c.feeCents - c.logged.feeCents) / 100
          : '';
        const childDiffHours = childHours - childLoggedHours;

        return {
          data: [
            taskName,
            childLoggedFee,
            childLoggedHours,
            childFee,
            childHours,
            childDiffFee,
            childDiffHours,
          ],
        };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents - totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours - totals.loggedHours },
  ];

  return { headers, rows, footer };
}

export function getCombinedTable(ctx, raw) {
  const { project, people, hasBudgetsAccess, hasCostsAccess } = ctx;

  const hasFeeColumns = hasBudgetsAccess;
  const hasCostColumns = hasCostsAccess;

  const headers = [
    {
      label: t`Name`,
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 130,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Past logged`,
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 130,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Future scheduled`,
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 130,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Total`,
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasCostColumns) {
    headers.push({
      label: t`Projected cost`,
      width: 160,
      formatter: (x) =>
        x === '' ? '' : formatAmount(ProjectBudgetType.TotalFee, x),
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPerson = getTotalsByPerson(ctx, raw);

  const totals = {
    pastLoggedHours: 0,
    pastLoggedFeeCents: 0,
    pastLoggedCostCents: 0,

    futureScheduledHours: 0,
    futureScheduledFeeCents: 0,
    futureScheduledCostCents: 0,
  };

  const rows = [];
  forEach(byPerson, (o, personId) => {
    const person = people[personId];

    const personPastLoggedHours = o.logged.scheduled;
    const personPastLoggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const personPastLoggedCost = hasCostColumns ? o.logged.costCents / 100 : '';

    const personFutureScheduledHours = o.future.scheduled;
    const personFutureScheduledFee = hasFeeColumns
      ? o.future.feeCents / 100
      : '';
    const personFutureScheduledCost = hasCostColumns
      ? o.future.costCents / 100
      : '';

    const personProjectedHours =
      personPastLoggedHours + personFutureScheduledHours;
    const personProjectedFee = hasFeeColumns
      ? (100 * personPastLoggedFee + 100 * personFutureScheduledFee) / 100
      : '';
    const personProjectedCost = hasCostColumns
      ? (100 * personPastLoggedCost + 100 * personFutureScheduledCost) / 100
      : '';

    totals.pastLoggedHours += personPastLoggedHours;
    totals.futureScheduledHours += personFutureScheduledHours;

    if (hasFeeColumns) {
      totals.pastLoggedFeeCents += 100 * personPastLoggedFee;
      totals.futureScheduledFeeCents += 100 * personFutureScheduledFee;
    }

    if (hasCostColumns) {
      totals.pastLoggedCostCents += 100 * personPastLoggedCost;
      totals.futureScheduledCostCents += 100 * personFutureScheduledCost;
    }

    const data = [
      person.name,
      personPastLoggedFee,
      personPastLoggedHours,
      personFutureScheduledFee,
      personFutureScheduledHours,
      personProjectedFee,
      personProjectedHours,
    ];

    if (hasCostColumns) {
      data.push(personProjectedCost);
    }

    rows.push({
      id: personId,
      data,
      children: map(o.children, (c, taskName) => {
        const childPastLoggedHours = c.logged.scheduled;
        const childPastLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childPastLoggedCost = hasCostColumns
          ? c.logged.costCents / 100
          : '';

        const childFutureScheduledHours = c.future.scheduled;
        const childFutureScheduledFee = hasFeeColumns
          ? c.future.feeCents / 100
          : '';
        const childFutureScheduledCost = hasCostColumns
          ? c.future.costCents / 100
          : '';

        const childProjectedHours =
          childPastLoggedHours + childFutureScheduledHours;
        const childProjectedFee = hasFeeColumns
          ? (100 * childPastLoggedFee + 100 * childFutureScheduledFee) / 100
          : '';
        const childProjectedCost = hasCostColumns
          ? (100 * childPastLoggedCost + 100 * childFutureScheduledCost) / 100
          : '';

        const childData = [
          taskName,
          childPastLoggedFee,
          childPastLoggedHours,
          childFutureScheduledFee,
          childFutureScheduledHours,
          childProjectedFee,
          childProjectedHours,
        ];

        if (hasCostColumns) {
          childData.push(childProjectedCost);
        }

        return {
          data: childData,
        };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: hasFeeColumns ? totals.pastLoggedFee : '' },
    { label: totals.pastLoggedHours },
    { label: hasFeeColumns ? totals.futureScheduledFee : '' },
    { label: totals.futureScheduledHours },
    {
      label: hasFeeColumns
        ? (totals.pastLoggedFeeCents + totals.futureScheduledFeeCents) / 100
        : '',
    },
    { label: totals.pastLoggedHours + totals.futureScheduledHours },
  ];

  if (hasCostColumns) {
    footer.push({
      label:
        (totals.pastLoggedCostCents + totals.futureScheduledCostCents) / 100,
    });
  }

  return { headers, rows, footer };
}
