import { isEmpty, some } from 'lodash';

import { LoadState, ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import { ensureBudgetsLoaded } from '@float/common/store/budgets/budgets.actions';
import { logger } from '@float/libs/logger';
import { RawProject } from '@float/types';

import API3 from '../../api3';
import { sanitizeFetchedProject } from '../projects.helpers';
import {
  PROJECTS_LOAD_FAILED,
  PROJECTS_LOAD_FINISH,
  PROJECTS_LOAD_START,
} from './actionTypes';

async function fetchProjects(params?: { archived?: boolean }) {
  const response: RawProject[] = await API3.getAllProjects(params);

  // If there are no projects `response` will be empty
  if (isEmpty(response)) return [];

  return response.map((project) =>
    sanitizeFetchedProject(project),
  ) as RawProject[];
}

export function ensureProjectsLoaded({
  forceLoad = false,
  includeArchived = false,
  skipBudgets = false,
} = {}) {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    const { loadState: currentLoadState, archivedProjectsLoaded } =
      getState().projects;

    if (currentLoadState === LoadState.LOADING) return; // There's already an in-flight load request

    const shouldFetchActive =
      forceLoad || currentLoadState !== LoadState.LOADED;
    const shouldFetchArchived = forceLoad
      ? includeArchived
      : includeArchived && !archivedProjectsLoaded;

    if (!(shouldFetchActive || shouldFetchArchived)) return; // Already loaded

    try {
      dispatch({ type: PROJECTS_LOAD_START });

      const requests = [
        shouldFetchActive ? fetchProjects() : null,
        shouldFetchArchived ? fetchProjects({ archived: true }) : null,
      ];

      const [projects, archivedProjects] = await Promise.all(requests);

      dispatch({
        type: PROJECTS_LOAD_FINISH,
        forceLoad,
        projects,
        archivedProjects,
        includeArchived,
      });

      if (!forceLoad && some(projects, (p) => p?.budget_type) && !skipBudgets) {
        // OPTIMIZATION: We don't need to wait for the budgets fetching
        // and we load them in background without returning the promise
        // Budgets are used in
        // - Project plan, to show the budgets usage
        // - Manage projects, we show a progress bar related to the budgets usage
        // In those two cases it is ok to not show immediately that value
        // Also, includeArchived is always false because we only need budgets data of archived
        // projects in the Manage projects page when "Archived" is enabled, and the fetching
        // is already handled there
        dispatch(ensureBudgetsLoaded(null, { includeArchived: false }));
      }
    } catch (e) {
      logger.error('Failed to load projects', e);
      dispatch({ type: PROJECTS_LOAD_FAILED });
    }
  };
}
