/**
 * @see {@link architectureDecisions/0002-replace-react-virtualized.md}
 */

import React, { forwardRef, memo, useEffect, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import usePrevious from 'react-use/esm/usePrevious';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { useCombinedRefs } from '@float/libs/hooks/useCombinedRefs';
import { useRect } from '@float/libs/web/hooks/useRect';
import { ScrollArea } from '@float/ui/primitives/ScrollArea';
import type { FilteredContext } from '@float/common/search/selectors/getSearchAutocompleteResults/types';
import type { SearchAutocompleteCategory } from '@float/types';

import { useScrollAreaState } from '../../hooks/useScrollAreaState';

import '../../types';

import { ResultsList } from './components/ResultsList';
import { getSortedData } from './SearchFilterResults.helpers';
import { useSearchFilterInfiniteScroll } from './useSearchFilterInfiniteScroll';
import type {
  ActivateCategory,
  AddFilter,
  AddValue,
  DeleteSavedSearch,
  GetIsValueDisabled,
  RemoveValue,
  ReplaceValue,
} from '../../types';

import * as styles from './styles.css';

type SearchFilterResultsProps = {
  addFilter: AddFilter;
  addValue: AddValue;
  deleteSavedSearch: DeleteSavedSearch;
  disableMultiSelect: boolean;
  activateCategory: ActivateCategory;
  expandedCategory: SearchAutocompleteCategory | undefined;
  filteredContext: FilteredContext;
  highlightedIndex: number | undefined;
  removeValue: RemoveValue;
  replaceValue: ReplaceValue;
  selectedValues: string[];
  setHighlightedIndex: (index: number) => void;
  isLoading?: boolean;
  fetchNextPage?: () => void;
  totalResultsCount?: number;
  isValueDisabled: GetIsValueDisabled;
};

export const SearchFilterResults = memo(
  forwardRef<HTMLDivElement, SearchFilterResultsProps>(
    (props, forwardedRef) => {
      const {
        addFilter,
        addValue,
        deleteSavedSearch,
        disableMultiSelect,
        activateCategory,
        expandedCategory,
        filteredContext,
        highlightedIndex,
        removeValue,
        replaceValue,
        selectedValues,
        setHighlightedIndex,
        isLoading,
        fetchNextPage,
        totalResultsCount,
        isValueDisabled,
      } = props;
      const { result: data, categoryIndices } = filteredContext;
      const listRef = useRef<HTMLDivElement>(null);

      const ref = useCombinedRefs([listRef, forwardedRef]);
      const previousHighlightedIndex = usePrevious(highlightedIndex);

      /**
       * With SBL we do an API call and in that case we should use totalResultsCount because the results might be paginated.
       * Without SBL the search is done locally and using data.length is more correct.
       *
       * In any case picking the biggest between the two is always safe.
       */
      const count = Math.max(totalResultsCount ?? 0, data.length);

      const virtualizer = useVirtualizer({
        count,
        getScrollElement: () => listRef.current,
        estimateSize: () => 32,
      });
      const virtualItems = virtualizer.getVirtualItems();
      const height = virtualizer.getTotalSize();
      const isEmpty = !height;

      const rect = useRect(listRef);
      const scrollAreaState = useScrollAreaState({ top: rect?.top, height });
      const isSearchBeyondLimitsEnabled = featureFlags.isFeatureEnabled(
        FeatureFlag.SearchBeyondLimits,
      );

      const sortedData = getSortedData(
        expandedCategory,
        data,
        isSearchBeyondLimitsEnabled,
      );

      useEffect(() => {
        /**
         * @todo: improve keyboard navigation
         * https://linear.app/float-com/issue/FT-2054/ux-improve-keyboard-navigation-up-and-down
         *
         * This is legacy functionality ported over here.
         **/

        if (
          virtualItems.length > 0 &&
          previousHighlightedIndex !== highlightedIndex
        )
          virtualizer.scrollToIndex(highlightedIndex || 0);
      }, [
        highlightedIndex,
        previousHighlightedIndex,
        virtualItems.length,
        virtualizer,
      ]);

      useSearchFilterInfiniteScroll({
        data: sortedData,
        isLoading,
        fetchNextPage,
        virtualItems,
      });

      return (
        <ScrollArea.Root
          className={styles.filterResults}
          data-empty={isEmpty}
          data-scrollbar-state={scrollAreaState.scrollbarState}
          style={{ height: scrollAreaState.height }}
          type="auto"
        >
          <ScrollArea.Content
            className={styles.content}
            data-testid="virtual-list"
            ref={ref}
          >
            <div style={{ height }}>
              <ResultsList
                addFilter={addFilter}
                addValue={addValue}
                categoryIndices={categoryIndices}
                data={sortedData}
                deleteSavedSearch={deleteSavedSearch}
                disableMultiSelect={disableMultiSelect}
                activateCategory={activateCategory}
                expandedCategory={expandedCategory}
                highlightedIndex={highlightedIndex}
                measureElement={virtualizer.measureElement}
                removeValue={removeValue}
                replaceValue={replaceValue}
                selectedValues={selectedValues}
                setHighlightedIndex={setHighlightedIndex}
                virtualItems={virtualItems}
                isValueDisabled={isValueDisabled}
              />
            </div>
          </ScrollArea.Content>
        </ScrollArea.Root>
      );
    },
  ),
);
