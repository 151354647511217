import { useLayoutEffect } from 'react';

import { media } from '@float/libs/media';

/**
 * 🚨🚨🚨
 * Prefer style-based approach to breakpoints whenever possible.
 * 🚨🚨🚨
 */
export const useListenForWindowResize = (
  handler: () => void,
  options: { runOnMount: boolean } = { runOnMount: false },
) => {
  useLayoutEffect(() => {
    if (options.runOnMount) {
      handler();
    }

    media.addWindowResizeListener(handler);

    return () => {
      media.removeWindowResizeListener(handler);
    };
  }, [handler, options.runOnMount]);
};
