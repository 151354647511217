import React, { useCallback } from 'react';
import cx from 'classnames';

import * as Icons from '@float/ui/deprecated/Earhart/Icons';

import { RowCheckbox } from './RowCheckbox';
import { StyledSortButton, StyledTableHeader } from './styles';

const oppSortDir = (sortDir) => (sortDir === 'asc' ? 'desc' : 'asc');

const headSortIcon = (isSorted, sortDir) => {
  if (!isSorted) return '';

  return sortDir === 'asc' ? <Icons.IconArrowDown /> : <Icons.IconArrowUp />;
};

export const SortableHeader = ({
  onSortChange,
  headerKey,
  rowCount,
  name,
  sortConfig,
}) => {
  const sortProp = sortConfig && sortConfig.property;
  const sortDir = sortConfig && sortConfig.direction;

  return (
    <StyledSortButton
      onClick={() => {
        const nextDirection =
          sortProp === headerKey ? oppSortDir(sortDir) : 'asc';

        onSortChange({
          property: headerKey,
          direction: nextDirection,
        });
      }}
    >
      {name}
      {typeof rowCount == 'number' && (
        <span className="row-count">{` ${rowCount} `}</span>
      )}
      {headSortIcon(headerKey === sortProp, sortDir)}
    </StyledSortButton>
  );
};

const Thead = ({
  names,
  nameKeys,
  rowCount,
  sort,
  onClickHeader,
  rowActions,
  visible,
  columnsWidth,
  className,
  isAllSelected,
  inTableEditMode,
  onToggleSelectAll,
}) => {
  const defaultWidthColumn = useCallback((names, actions = false) => {
    let total = names.length;
    if (actions) total += 1;

    return Array.from(Array(total).keys()).map(() => `${100 / total}%`);
  }, []);

  const displayRowCount = rowCount === 0 || rowCount > 0;

  if (!visible) return null;

  const widths =
    columnsWidth || defaultWidthColumn(names, rowActions.length > 0);

  const columns = names.map((name, i) => {
    const nameKey = (nameKeys && nameKeys[i]) || '';
    const isSortable = !!nameKey;

    return (
      <StyledTableHeader key={name} className={className} $width={widths[i]}>
        {isSortable ? (
          <SortableHeader
            name={name}
            headerKey={nameKey}
            sortConfig={sort}
            rowCount={displayRowCount && i === 0 ? rowCount : null}
            onSortChange={({ property, direction }) => {
              return onClickHeader(property, direction);
            }}
          />
        ) : (
          name
        )}
      </StyledTableHeader>
    );
  });

  const selectable = typeof onToggleSelectAll === 'function';
  const handleToggleSelectAll = inTableEditMode ? undefined : onToggleSelectAll;

  return (
    <thead>
      <tr>
        {selectable && (
          <StyledTableHeader $width="6%">
            <RowCheckbox
              checked={isAllSelected}
              onChange={handleToggleSelectAll}
            />
          </StyledTableHeader>
        )}
        {columns}
        {rowActions.length > 0 && (
          <StyledTableHeader
            className={cx(className, 'actions')}
            $width={widths[widths.length - 1]}
          >
            {' '}
          </StyledTableHeader>
        )}
      </tr>
    </thead>
  );
};

export default Thead;
