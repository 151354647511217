import React from 'react';
import { Select, t, Trans } from '@lingui/macro';

import { capitalize } from '@float/libs/utils/string/capitalize';
import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { Button } from '@float/ui/components/Button';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { Modal } from '@float/ui/primitives/Modal';

import {
  ModalSwitchPersonProps,
  PersonToSwitchIn,
} from '../../ModalSwitchPerson.types';
import { PersonToSwitchOut } from '../PersonToSwitchOut';
import { RateToUseAfterPersonSwitch } from '../RateToUseAfterPersonSwitch';
import { useSwitchPersonSelect } from './useSwitchPersonSelect';

export type ModalSwitchPersonSelectProps = {
  modalProps: ModalSwitchPersonProps['modalProps'];
  onClickSwitchPerson: (newPerson: PersonToSwitchIn) => void;
  person: Person;
  project: Project;
  personBillRateOnProject?: string | null;
  shouldShowRates: boolean;
};

const getLabel = (personName: string) => {
  const name = capitalize(personName);
  return t`Select a person to swap onto this project. ${name}'s scheduled allocations will be automatically reassigned.`;
};

export const ModalSwitchPersonSelect = (
  props: ModalSwitchPersonSelectProps,
) => {
  const {
    modalProps,
    person,
    personBillRateOnProject,
    project,
    shouldShowRates,
    onClickSwitchPerson,
  } = props;

  const {
    error,
    newPerson,
    peopleOptions,
    shouldShowRateMismatch,
    shouldUseNewRate,
    handleOnClickSwitchPerson,
    handlePersonSelect,
    handleShouldUseNewRateChange,
  } = useSwitchPersonSelect({
    personBillRateOnProject,
    project,
    shouldShowRates,
    onClickSwitchPerson,
  });

  const personName = person?.name;
  const titleOtherCopy = personName
    ? t`Swap ${personName}`
    : t`Swap previous person`;

  return (
    <Modal.Root {...modalProps}>
      <Modal.Layout.Header>
        <Modal.Title>
          <Select
            value={personName || 'person'}
            _person={'Swap person'}
            other={titleOtherCopy}
          />
        </Modal.Title>
      </Modal.Layout.Header>
      <Modal.Description asChild>
        <PersonToSwitchOut
          avatarFile={person.avatar_file}
          billRate={personBillRateOnProject}
          name={person.name}
          shouldShowRates={shouldShowRates}
        />

        <VirtualSelect
          // @ts-expect-error virtual select is not typed so these props are not recognized
          errors={error}
          data-testid="teamMember"
          groupedOptions={peopleOptions}
          label={getLabel(personName)}
          onChange={handlePersonSelect}
          onFocus={() => true} // fixes an autoFocus issue when rendered inside a modal
          placeholder={''}
          value={newPerson?.personId}
          visibleItems={6}
        />

        {shouldShowRateMismatch && (
          <RateToUseAfterPersonSwitch
            newPersonName={newPerson?.personName}
            projectName={project.project_name}
            shouldUseNewRate={shouldUseNewRate}
            onChange={handleShouldUseNewRateChange}
          />
        )}
      </Modal.Description>

      <Modal.Layout.Footer>
        <Modal.Layout.ButtonGroup>
          <Button onClick={handleOnClickSwitchPerson}>
            <Trans>Swap person</Trans>
          </Button>
          <Modal.Close>
            <Button appearance="secondary-flay">
              <Trans>Cancel</Trans>
            </Button>
          </Modal.Close>
        </Modal.Layout.ButtonGroup>
      </Modal.Layout.Footer>
    </Modal.Root>
  );
};
