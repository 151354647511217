import request from '@float/common/lib/request';
import { Timer } from '@float/types';

import {
  CreateTimerParameters,
  CreateTimerResponse,
  GetTimerParameters,
  GetTimerResponseItem,
  StartTimerParameters,
  StartTimerResponse,
} from './types';

export const timers = {
  getActiveTimers: (
    params: GetTimerParameters = {},
  ): Promise<GetTimerResponseItem[]> => {
    const opts = {
      jwt: true,
      contentType: 'application/json',
      version: '',
      hostname: '',
    };

    return request.get('svc/timer-api/timer', { active: 1, ...params }, opts);
  },

  getInactiveTimers: (
    params: GetTimerParameters = {},
  ): Promise<GetTimerResponseItem[]> => {
    const opts = {
      jwt: true,
      contentType: 'application/json',
      version: '',
      hostname: '',
    };

    return request.get('svc/timer-api/timer', { active: 0, ...params }, opts);
  },

  startTimer: (timer: StartTimerParameters): Promise<StartTimerResponse> => {
    const opts = {
      jwt: true,
      contentType: 'application/json',
      version: '',
      hostname: '',
    };

    return request.post('svc/timer-api/timer/start', timer, opts);
  },

  stopTimer: (timerId: string) => {
    const opts = {
      jwt: true,
      contentType: 'application/json',
      version: '',
      hostname: '',
    };

    return request.post(`svc/timer-api/timer/stop/${timerId}`, null, opts);
  },

  createTimer: (timer: CreateTimerParameters): Promise<CreateTimerResponse> => {
    const opts = {
      jwt: true,
      contentType: 'application/json',
      version: '',
      hostname: '',
    };

    return request.post('svc/timer-api/timer', timer, opts);
  },

  updateTimer: (
    timer: Pick<Timer, '_id' | 'start_timestamp' | 'end_timestamp'>,
  ) => {
    const opts = {
      jwt: true,
      contentType: 'application/json',
      version: '',
      hostname: '',
    };

    return request.put(`svc/timer-api/timer/${timer._id}`, timer, opts);
  },

  deleteTimer: (timerId: string) => {
    const opts = {
      jwt: true,
      contentType: 'application/json',
      version: '',
      hostname: '',
    };

    return request.del(`svc/timer-api/timer/${timerId}`, null, opts);
  },
};
