import { createSelector } from 'reselect';

import { filterNonNullable } from '@float/libs/utils/arrays';
import type { ReduxStateStrict } from '@float/common/reducers/lib/types';
import type { ProjectEstimate } from '@float/types/estimate';
import type { Project } from '@float/types/project';

import {
  DEFAULT_PHASE_ESTIMATES_STATE,
  DEFAULT_PROJECT_ESTIMATES_STATE,
  DEFAULT_TASK_ESTIMATES_STATE,
  ESTIMATES_REDUCER_NAME,
} from './estimates.constants';

const selectEstimatesState = (state: ReduxStateStrict) =>
  state[ESTIMATES_REDUCER_NAME];

const selectProjectEstimatesStateMap = (state: ReduxStateStrict) =>
  selectEstimatesState(state).projectEstimates;

const selectPhaseEstimatesStateMap = (state: ReduxStateStrict) =>
  selectEstimatesState(state).phaseEstimates;

const selectTaskEstimatesStateMap = (state: ReduxStateStrict) =>
  selectEstimatesState(state).taskEstimates;

const selectProjectIdParam = (
  _: ReduxStateStrict,
  projectId: Project['project_id'],
) => projectId;

const selectProjectEstimateIdParam = (
  _: ReduxStateStrict,
  projectEstimateId: ProjectEstimate['id'],
) => projectEstimateId;

const selectProjectEstimatesState = createSelector(
  [selectProjectEstimatesStateMap, selectProjectIdParam],
  (projectEstimatesStateMap, projectId) =>
    projectEstimatesStateMap[projectId] ?? DEFAULT_PROJECT_ESTIMATES_STATE,
);

const selectPhaseEstimatesState = createSelector(
  [selectPhaseEstimatesStateMap, selectProjectEstimateIdParam],
  (phaseEstimatesStateMap, projectEstimateId) =>
    phaseEstimatesStateMap[projectEstimateId] ?? DEFAULT_PHASE_ESTIMATES_STATE,
);

const selectTaskEstimatesState = createSelector(
  [selectTaskEstimatesStateMap, selectProjectEstimateIdParam],
  (taskEstimatesStateMap, projectEstimateId) =>
    taskEstimatesStateMap[projectEstimateId] ?? DEFAULT_TASK_ESTIMATES_STATE,
);

export const selectProjectEstimatesLoadState = createSelector(
  [selectProjectEstimatesState],
  ({ loadState }) => loadState,
);

export const selectProjectEstimates = createSelector(
  [selectProjectEstimatesState],
  ({ map }) => filterNonNullable(Object.values(map)),
);

export const selectBaselineProjectEstimate = createSelector(
  [selectProjectEstimates],
  (projectEstimates) => {
    for (const estimate of projectEstimates) {
      if (estimate.baseline) return estimate;
    }
    return undefined;
  },
);

export const selectHistoricalProjectEstimates = createSelector(
  [selectProjectEstimates],
  (projectEstimates) =>
    projectEstimates.filter((estimate) => !estimate.baseline),
);

export const selectPhaseEstimatesLoadState = createSelector(
  [selectPhaseEstimatesState],
  ({ loadState }) => loadState,
);

export const selectPhaseEstimates = createSelector(
  [selectPhaseEstimatesState],
  ({ map }) => filterNonNullable(Object.values(map)),
);

export const selectTaskEstimatesLoadState = createSelector(
  [selectTaskEstimatesState],
  ({ loadState }) => loadState,
);

export const selectTaskEstimates = createSelector(
  [selectTaskEstimatesState],
  ({ map }) => filterNonNullable(Object.values(map)),
);
