import React from 'react';

import { RateText } from '@float/common/components/Rate';
import { Role } from '@float/types/role';

import * as styles from './PersonRoleRate.styles.css';

export type PersonRoleRate = {
  name: string;
  hourlyRate: Role['default_hourly_rate'];
};

export const PersonRoleRate = (props: PersonRoleRate) => {
  const { name, hourlyRate } = props;

  if (!hourlyRate) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.rate}>
        <span className={styles.name}>{name}</span>
        <RateText rate={hourlyRate} />
      </div>
    </div>
  );
};
