import { MediaSize } from '@float/constants/media';
import { logger } from '@float/libs/logger';

import { getBreakpointFromWidth } from './media.helpers';
import type { MediaClientAdapter } from './media.types';

export class Media {
  private _clientAdapter: MediaClientAdapter | undefined;

  private get clientAdapter(): MediaClientAdapter {
    if (!this._clientAdapter)
      throw new Error(
        'The Media service has not been initialized, you need to call `initialize` with a client adapter',
      );

    return this._clientAdapter;
  }

  public async initialize(clientAdapter: MediaClientAdapter) {
    if (this._clientAdapter) {
      logger.log('The Media service has already been initialized', {
        level: 'warn',
      });

      return;
    }

    this._clientAdapter = clientAdapter;

    return this;
  }

  public get screenDimensions() {
    return this.clientAdapter.screenDimensions;
  }

  public get windowDimensions() {
    return this.clientAdapter.windowDimensions;
  }

  public get activeMediaSize() {
    return getBreakpointFromWidth(this.windowDimensions.width);
  }

  /**
   * 🚨🚨🚨
   * Prefer style-based approaches where possible.
   * 🚨🚨🚨
   */
  public getIsMediaSizeActive = (mediaSize: MediaSize): boolean =>
    this.activeMediaSize === mediaSize;
  /**
   * 🚨🚨🚨
   * Prefer style-based approaches where possible.
   * 🚨🚨🚨
   */
  public get isSmallBreakpointActive(): boolean {
    return this.activeMediaSize === MediaSize.Small;
  }
  /**
   * 🚨🚨🚨
   * Prefer style-based approaches where possible.
   * 🚨🚨🚨
   */
  public get isMediumBreakpointActive(): boolean {
    return this.activeMediaSize === MediaSize.Medium;
  }

  /**
   * 🚨🚨🚨
   * Prefer style-based approaches where possible.
   * 🚨🚨🚨
   */
  public get isLargeBreakpointActive(): boolean {
    return this.activeMediaSize === MediaSize.Large;
  }

  /**
   * 🚨🚨🚨
   * Prefer style-based approaches where possible.
   * 🚨🚨🚨
   */
  public get isXLargeBreakpointActive(): boolean {
    return this.activeMediaSize === MediaSize.XLarge;
  }

  /**
   * Add a listener that runs when the window dimension changes.
   *
   * 🚨🚨🚨
   * You probably don't want to use this directly – prefer using the
   * `useListenForWindowResize` hook which handles the cleanup for you.
   * 🚨🚨🚨
   */
  public addWindowResizeListener(listener: () => void) {
    this.clientAdapter.addWindowResizeListener(listener);
  }

  /**
   * Remove a previously added window dimension change listener.
   *
   * 🚨🚨🚨
   * You probably don't want to use this directly – instead using the
   * `useListenForWindowResize` hook which handles the cleanup for you.
   * 🚨🚨🚨
   */
  public removeWindowResizeListener(listener: () => void) {
    this.clientAdapter.removeWindowResizeListener(listener);
  }
}
