import { TaskEditMode } from '@float/common/components/Schedule/util/ContextMenu/types';
import { TaskStatusEnum } from '@float/types/taskStatus';
import Theme from '@float/ui/deprecated/Theme';

export function getTaskStyles(colors, item, config) {
  const {
    actionMode = null,
    isEligibleLinkSource = false,
    isEligibleLinkTarget = false,
    isLinking = false,
    isLinkOrigin = false,
    isSelected = false,
    isSelectedAsLink = false,
  } = config;

  let backgroundColor;
  let foregroundColor;
  let backgroundColorPill;
  let foregroundColorPill;
  let border;
  let opacity = 1;

  if (item.isTaskReference) {
    backgroundColor = colors.backgroundUnlogged.color;
    foregroundColor = colors.foregroundUnlogged.color;
    backgroundColorPill = colors.backgroundUnloggedPill.color;
    foregroundColorPill = colors.foregroundUnloggedPill.color;
    border = `1px solid ${backgroundColor}`;
  } else if (
    item.type === 'loggedTime' &&
    item.isInLockPeriod &&
    !item.entity?.isResizingVertically
  ) {
    backgroundColor = colors.backgroundLockedLogged.color;
    foregroundColor = colors.foregroundLockedLogged.color;
    backgroundColorPill = colors.backgroundLockedLoggedPill.color;
    foregroundColorPill = colors.foregroundLockedLoggedPill.color;
    border = `1px solid ${backgroundColor}`;
  } else if (
    item.type !== 'loggedTime' &&
    item.entity?.status === TaskStatusEnum.Draft
  ) {
    backgroundColor = colors.backgroundTentative.color;
    foregroundColor = colors.foregroundTentative.color;
    backgroundColorPill = colors.backgroundTentativePill.color;
    foregroundColorPill = colors.foregroundTentativePill.color;
    border = `1px dashed ${foregroundColor}`;
  } else if (
    item.type !== 'loggedTime' &&
    item.entity?.status === TaskStatusEnum.Tentative
  ) {
    backgroundColor = colors.backgroundTentative.color;
    foregroundColor = colors.foregroundTentative.color;
    backgroundColorPill = colors.backgroundTentativePill.color;
    foregroundColorPill = colors.foregroundTentativePill.color;
    border = `1px solid ${foregroundColor}`;
  } else if (
    item.type !== 'loggedTime' &&
    item.entity?.status === TaskStatusEnum.Complete
  ) {
    backgroundColor = colors.backgroundCompleted.color;
    foregroundColor = colors.foregroundCompleted.color;
    backgroundColorPill = colors.backgroundCompletedPill.color;
    foregroundColorPill = colors.foregroundCompletedPill.color;
    border = `1px solid ${backgroundColor}`;
  } else {
    backgroundColor = colors.background.color;
    foregroundColor = colors.foreground.color;
    backgroundColorPill = colors.backgroundPill.color;
    foregroundColorPill = colors.foregroundPill.color;
    border = `1px solid ${backgroundColor}`;
  }

  if (item.entity?.isSplitting || item.entity?.temporaryId) {
    opacity = 0.75;
  }

  if (isSelected) {
    border = `1px solid ${Theme.charcoalGrey}`;
  } else if (isSelectedAsLink) {
    border = `2px solid ${Theme.charcoalGrey}`;
  }

  if (isLinking && !isLinkOrigin) {
    opacity = isEligibleLinkTarget ? 1 : 0.2;
  }

  if (actionMode === TaskEditMode.LINK && !isLinking) {
    opacity = isEligibleLinkSource ? 1 : 0.2;
  }

  const styles = {
    '--bg-color': backgroundColor,
    '--fg-color': foregroundColor,
    '--bg-pill-color': backgroundColorPill,
    '--fg-pill-color': foregroundColorPill,
    backgroundColor,
    color: foregroundColor,
    borderTop: border,
    borderBottom: border,
    borderRight: border,
    borderLeft: border,
    opacity,
  };

  if (!item.isStart) {
    styles.borderLeft = '0';
  }

  if (!item.isEnd) {
    styles.borderRight = '0';
  }

  return styles;
}
