import React from 'react';
import { t, Trans } from '@lingui/macro';

import { formatAmount } from '@float/common/lib/budget';
import { ProjectBudgetType } from '@float/constants/projects';
import { getHasFixedBudgetSupport } from '@float/web/reports/helpers/getHasFixedBudgetSupport';
import type { ProjectBudgetUsage } from '@float/types/project';

import { ItemHeader, ItemLabel, ReportsTotalsItem } from '../../../styles';
import { BudgetTypeTag } from './BudgetTypeTag/BudgetTypeTag';
import type { ProjectSummaryInfo, TotalsData } from '../types';

import * as styles from './SummaryItemTotalBudget.css';

export type SummaryItemTotalBudgetProps = {
  project: ProjectSummaryInfo;
  projectBudget: ProjectBudgetUsage;
  totals: TotalsData;
};

export const SummaryItemTotalBudget = ({
  project,
  projectBudget,
  totals,
}: SummaryItemTotalBudgetProps) => {
  const { budget_type, budget_priority } = project;

  const projectBudgetValue = getHasFixedBudgetSupport(budget_type)
    ? projectBudget?.budget_total || 0
    : totals.feeCents / 100;

  const projectBudgetValueFormatted =
    budget_type === ProjectBudgetType.Disabled
      ? t`No budget`
      : formatAmount(budget_type, projectBudgetValue);

  return (
    <ReportsTotalsItem width="200px">
      <ItemLabel>
        <div className={styles.labelWrapper}>
          <Trans>Total budget</Trans>
          <BudgetTypeTag
            budgetType={budget_type}
            budgetPriority={budget_priority}
          />
        </div>
      </ItemLabel>
      <ItemHeader data-testid="project-budget-total">
        {projectBudgetValueFormatted}
      </ItemHeader>
    </ReportsTotalsItem>
  );
};
