import { formatToDbString } from '@float/common/lib/budget/helpers/formatToDbString';
import type { Person, Project } from '@float/types';

import { useDoesPersonHaveDifferentBillRateOnPhases } from './useDoesPersonHaveDifferentBillRateOnPhases';
import { usePersonBillRateOnProject } from './usePersonBillRateOnProject';
import { useShouldShowRates } from './useShouldShowRates';

type UseModalSwitchPersonControllerParams = {
  person: Person;
  project: Project;
};

export function useModalSwitchPersonController(
  params: UseModalSwitchPersonControllerParams,
) {
  const { person, project } = params;

  const shouldShowRates = useShouldShowRates(project);

  let personBillRateOnProject = usePersonBillRateOnProject({
    personId: person.people_id,
    projectId: project.project_id,
  });

  const doesPersonHaveDifferentBillRateOnPhases =
    useDoesPersonHaveDifferentBillRateOnPhases({
      personId: person.people_id,
      projectId: project.project_id,
      personBillRateOnProject,
    });

  if (doesPersonHaveDifferentBillRateOnPhases) {
    // If the person being swapped out has different rates on phases, we don't
    // show their project rate next to their name, to not mislead the user into
    // thinking that their rate is the same across the entire project.
    personBillRateOnProject = null;
  }

  return {
    shouldShowRates,

    // Project rates are pre-formatted in Redux while person rates are stored raw.
    // This inconsistency affects newer components like <RateText /> and <InputCurrency />
    // which expect raw values for proper locale-based formatting.
    personBillRateOnProject: personBillRateOnProject
      ? formatToDbString(personBillRateOnProject)
      : null,
  };
}
