import { get } from 'lodash';

import { addMinutes } from '@float/common/lib/time';
import { isEditTheirTask } from '@float/common/lib/utils';
import { moment } from '@float/libs/moment';

import { getExportedTaskCopiedFromState } from './actions/updateTask';
import { getDefaultProjectForTaskModal } from './getDefaultProjectForTaskModal';
import { getPMUrl } from './helpers';
import { getDefaultTimeoffType } from './helpers/getDefaultTimeoffType';
import { getWorkDays } from './helpers/getWorkDays';
import { calculateRepeatTimes, getTotalHours } from './helpers/timeReducers';

const getProjectById = function (projectId) {
  const { projectsMap = {} } = this.props;
  return projectsMap[projectId] || {};
};

function getHoursPdFromTask(task, startDate, endDate) {
  if (task.hours) return +task.hours;

  const { getMinWorkHoursInRange } = this.props;

  const h = getMinWorkHoursInRange({
    start_date: moment(startDate).format('YYYY-MM-DD'),
    end_date: moment(endDate).format('YYYY-MM-DD'),
    people_ids: task.people_ids.filter((id) => this.props.peopleMap[id]),
  });

  return h === Number.POSITIVE_INFINITY ? 8 : h;
}

function isTaskPhaseArchived(task) {
  if (!task || !task.project_id || !task.phase_id) return false;

  const phases = this.props.projectPhases[task.project_id];
  if (!phases || !phases.length) return false;

  const phase = phases.find((x) => x.phase_id == task.phase_id);
  return phase && !+phase.active;
}

function isReadOnly(task) {
  if (task.isTimeoff) {
    return !this.canEdit;
  }
  // const projArchived = task.task_id && !+(task.project && task.project.active);
  const project = getProjectById.call(this, task.project_id);
  const projArchived = task.task_id && !+project.active;
  const phaseArchived = isTaskPhaseArchived.call(this, task);
  // the origin of task.archived remains unknown
  return task.archived || projArchived || phaseArchived || !this.canEdit;
}

function resetFlags() {
  this.updating = false;
  this.pressingCtrl = false;
  this.newFocus = true;
  this.newTaskFocus = true;
}

function isPreviewingBeforeEditMode(task, isNewTask) {
  const isApproved = task.status === 2;
  const { logTimeView } = this.props;
  return this.isRequestMode(task) && !isNewTask && isApproved && !logTimeView;
}

export function getTaskModalInitialState(task, isNewTask, insert, mode) {
  let readOnly = isReadOnly.call(this, task);

  const isPreviewingBeforeEdit = isPreviewingBeforeEditMode.call(
    this,
    task,
    isNewTask,
  );
  if (isPreviewingBeforeEdit) {
    readOnly = true;
  }

  const currentTaskMode = this.state.taskMode;
  resetFlags.call(this);
  const originalTask = task.task || task;
  const originalTaskUnmodified = { ...originalTask };

  const allocationType = originalTask.allocation_type ?? null;
  const notes = originalTask.timeoff_notes || originalTask.notes || '';
  const status_note = originalTask.status_note ?? '';
  const notes_meta = originalTask.notes_meta || null;
  const taskName = originalTask.name || originalTask.status_name || '';

  let {
    start_date,
    end_date,
    project,
    start_time,
    end_time,
    status_creator_id,
    priority,
    isTimeoff,
    isStatus,
    repeat_state,
    status_type_id,
    status_type_name,
    phase_id: phaseId,
    task_meta_id: taskMetaId,
  } = originalTask;

  const isTimeoffFocus = currentTaskMode === 'timeoff';
  const statusText = isStatus ? taskName || status_type_name : '';
  const statusTypeId = status_type_id;
  const taskMode =
    isTimeoff || isTimeoffFocus ? 'timeoff' : isStatus ? 'status' : 'task';
  let clientName = originalTask.client_name;
  const startDate = start_date
    ? moment(`${start_date} 00:00:00`).toDate()
    : new Date();
  const endDate = end_date
    ? moment(`${end_date} 00:00:00`).toDate()
    : new Date();
  const { clients = [], projects = [] } = this.props;

  const hoursPd = getHoursPdFromTask.call(
    this,
    originalTask,
    startDate,
    endDate,
  );

  if (isNewTask) {
    originalTask.hours_pd = hoursPd;
  }

  // Calculates the number of working days for a given person
  const length = getWorkDays(this.props.calcEntityLength, {
    people_ids: originalTask.people_ids,
    timeoff_id: originalTask.timeoff_id,
    start_date: startDate,
    end_date: endDate,
  });

  const totalHours = getTotalHours(hoursPd, length);
  const isRepeating = !!repeat_state;
  const errors = [];

  const isEditingTheirSchedule = isEditTheirTask(this.props.user, task);

  const { defaultProject, filteredClient, isLastProject } =
    getDefaultProjectForTaskModal({
      project: originalTask.project,
      clients,
      accessibleProjects: this.props.accessibleProjects,
      projects,
      searchFilters: this.props.searchFilters,
      lastProject: this.props.lastProject,
    });

  if (defaultProject && !readOnly) {
    project = defaultProject;

    originalTask.project = defaultProject;
    originalTask.project_id = defaultProject.project_id;
    originalTask.project_name = defaultProject.project_name;
    originalTask.client_id = defaultProject.client_id;
    originalTask.client_name = defaultProject.client_name;
  }

  let client;
  let clientId = filteredClient?.client_id || originalTask.client_id;

  if (clientId) {
    client = filteredClient || clients.find((cl) => cl.client_id === clientId);

    if (client) {
      clientName = client.client_name;
    } else if (isLastProject && defaultProject.client_id) {
      clientId = defaultProject.client_id;
      client = clients.find((cl) => cl.client_id === clientId);
      clientName = client && client.client_name;
    }
  }

  if (project && !readOnly) {
    project = { ...project };
    project.previous = { ...project };
  }

  const endDateMoment = moment(endDate);
  const repeatEnd = (
    task.repeat_end_date
      ? moment(task.repeat_end_date)
      : endDateMoment.clone().add(1, 'week').day(endDateMoment.day())
  ).toDate();
  const repeatState = originalTask.repeat_state;

  let timeoffType = originalTask.type;
  let isFullDay = true;
  const people = originalTask.people || {};

  let timeoffTypeId, timeoffTypeName, timeoffNotes;
  if (originalTask.timeoff_id) {
    timeoffTypeId =
      originalTask.timeoff_type_id || get(originalTask, 'type.timeoff_type_id');
    timeoffTypeName =
      originalTask.timeoff_type_name ||
      get(originalTask, 'type.timeoff_type_name');
    timeoffNotes = originalTask.timeoff_notes;
    isFullDay = originalTask.full_day;
  } else {
    timeoffType = getDefaultTimeoffType(this.props.timeoffTypes);
    timeoffTypeId = get(timeoffType, 'timeoff_type_id');
    timeoffTypeName = get(timeoffType, 'timeoff_type_name');
  }
  let [startTime, endTime] = [start_time, end_time];
  const editingTimeOfDay = startTime || endTime;
  if (startTime) {
    endTime = addMinutes(startTime, Math.round(hoursPd * 60));
  }

  const peopleIds =
    originalTask.people_ids.filter((id) => this.props.peopleMap[id]) || [];
  const peopleItems = originalTask.peopleItems;

  const showingNotes = !!notes.length;
  let timeExpanded = editingTimeOfDay || isRepeating || !isFullDay;
  if (readOnly) {
    timeExpanded = false;
    project = originalTaskUnmodified.project;
  }

  // 1 = Tentative , 2 = Scheduled, 3 = Complete,
  let status = originalTask.status;

  // Tentative projects or phases can only have tentative tasks
  if (
    taskMode === 'task' &&
    this.isProjectOrPhaseTentative({ project, phaseId })
  ) {
    status = 1;
  }

  const integrationSynced = originalTask.integration_status === 1;
  const integrationSyncLocked = integrationSynced;

  const pmUrl = getPMUrl(
    task,
    project,
    this.props.pmBaseUrl,
    this.props.pmIntegrationType,
    this.props.taskLinksMap,
  );

  const newState = {
    allocationType,
    isNewTask,
    task: originalTask,
    taskMode,
    taskName,
    taskMetaId,
    statusText,
    statusTypeId,
    originalTaskUnmodified,
    notes,
    notes_meta,
    status_note,
    showingNotes,
    client,
    clientId,
    clientName,
    project,
    hoursPd,
    startDate,
    endDate,
    totalHours,
    status_creator_id,
    initialTotalHours: isNewTask ? 0 : totalHours,
    isOffWork: originalTask.offWork,
    isLinkedTask: !!task.root_task_id,
    repeatEnd,
    isRepeating,
    repeatState,
    priority,
    peopleIds,
    peopleItems,
    errors,
    people,
    editingTimeOfDay,
    insert,
    mode,
    timeoffNotes,
    startTime,
    endTime,
    timeoffType,
    timeoffTypeId,
    timeoffTypeName,
    isFullDay,
    length,
    isEditingTheirSchedule,
    timeExpanded,
    readOnly,
    status,
    integrationSynced,
    integrationSyncLocked,
    isPreviewingBeforeEdit,
    pmUrl,
    phaseId,
  };

  this.asEntity = (state = this.state) => {
    const entity = getExportedTaskCopiedFromState.call(
      this,
      { ...state.task },
      false,
      state,
    );

    if (entity.full_day) {
      entity.hours = null;
    }

    return entity;
  };

  newState.repeatTimes = calculateRepeatTimes(this.props.dates, newState);

  const initialHours = newState.isNewTask
    ? { totalHours: 0, hoursByPerson: {} }
    : this.props.calcEntityTotalHours(this.asEntity(newState));

  newState.initialHours = initialHours;

  return newState;
}
