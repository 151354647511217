import { FilterToken } from '@float/types';

import { isNot } from '../../helpers';
import { FiltersContext, FiltersDataType, FiltersEntity } from '../../types';
import { getAppliedFiltersGroups } from './getAppliedFiltersGroups';
import { getIsNotOperatorAccepted } from './getIsNotOperatorAccepted';

/**
 * Similar to `getFilteredData`, but it narrows the available context for each
 * sucessive filter group based on the filters applied. This allows us to do
 * aggregate matching on Project Tag and Project Status.
 *
 * For example, searching for `tag=wizard and status=active` now matches people
 * assigned to projects where the project has BOTH `tag=wizard` and `status=active`,
 * whereas `getFilteredData` would instead match people attached to ANY project with
 * `tag=wizard` and ANY project with `status=active`, even if they were different
 * projects.
 *
 * In the documentation we reference "context entities" to distinguish
 * between the "primary entities" we are matching against. If we are filtering
 * People by Projects, the "primary entities" are People, and the "context entities"
 * are Projects.
 */
export const getFilteredDataWithContextNarrowing = <
  T extends FiltersDataType,
  ID extends string | number,
>(
  dataType: T,
  context: FiltersContext<T>,
  data: FiltersEntity<T>[],
  searchFilters: FilterToken[] = [],
  getEntityId: (entity: FiltersEntity<T>) => ID,
): Set<ID> => {
  const groupedFilters = getAppliedFiltersGroups(
    dataType,
    searchFilters,
    context,
  );

  const results = new Set<ID>();

  for (const filterGroup of groupedFilters) {
    let contextEntitiesMatchedByPriorFilters:
      | Partial<FiltersContext<T>>
      | undefined;

    let prevFilterGroupMatches = data;

    for (const filter of filterGroup) {
      if (!filter.values.length && filter.type !== 'me') {
        break;
      }

      const isLastFilterInGroup =
        filterGroup.length === filterGroup.indexOf(filter) + 1;

      const currentFilterGroupMatches: FiltersEntity<T>[] = [];

      // Passing matched context entities to the matcher allows it
      // to narrow the context for the next filter, resulting in fewer
      // search iterations.
      if (contextEntitiesMatchedByPriorFilters) {
        filter.matcher?.setContextEntitiesMatchedByPriorFilters?.(
          contextEntitiesMatchedByPriorFilters,
        );
      }

      for (const entity of prevFilterGroupMatches) {
        if (entity === undefined) {
          break;
        }

        let doesMatch = false;

        if (filter.matcher.forceMatch) {
          doesMatch = true;
        } else if (isNot(filter.operator)) {
          if (getIsNotOperatorAccepted(filter.type, entity, dataType)) {
            doesMatch = filter.matcher.matches(entity) === false;
          } else {
            // If the not operator is not accepted, we always match.
            doesMatch = true;
          }
        } else {
          doesMatch = filter.matcher.matches(entity);
        }

        if (doesMatch) {
          if (!isLastFilterInGroup) {
            currentFilterGroupMatches.push(entity);
          } else {
            results.add(getEntityId(entity));
          }
        }
      }

      prevFilterGroupMatches = currentFilterGroupMatches;

      contextEntitiesMatchedByPriorFilters =
        filter.matcher?.getMatchedContextEntities?.();
    }
  }

  return results;
};
