import {
  DEFAULT_ENTERPRISE,
  PRO_MONTHLY,
  PRO_YEARLY,
  STARTER_MONTHLY,
  STARTER_YEARLY,
} from '@float/common/lib/pricing';

type GetIsEnterprisePlan = {
  hasBothAddons: boolean;
  isAnnualPlan: boolean;
  isPaidPlan: boolean;
};

export const getIsEnterprisePlan = (
  id: string,
  params: GetIsEnterprisePlan,
) => {
  const { isPaidPlan, hasBothAddons, isAnnualPlan } = params;

  const planId = Number(id);

  // Current "Enterprise" plan
  if (planId === Number(DEFAULT_ENTERPRISE.plan_id)) {
    return true;
  }

  // Current Non-Enterprise plans
  if (
    planId === Number(PRO_MONTHLY.plan_id) ||
    planId === Number(PRO_YEARLY.plan_id) ||
    planId === Number(STARTER_MONTHLY.plan_id) ||
    planId === Number(STARTER_YEARLY.plan_id)
  ) {
    return false;
  }

  // All other legacy paid plans
  if (isPaidPlan) {
    return Boolean(hasBothAddons && isAnnualPlan);
  }

  // Trial plans are never "Enterprise"
  return false;
};
