import { isCostRateFeatureActive } from '@float/common/lib/rates/rates';
import { CostRateFormData } from '@float/types/costRate';

type RoleRowWithoutCostRate = [
  roleName: string,
  billRate: string,
  peopleCount: number,
];

type RoleRowWithNewCostRate = [
  roleName: string,
  costRate: CostRateFormData,
  billRate: string,
  peopleCount: number,
];

type RoleRowWithOldCostRate = [
  roleName: string,
  costRate: CostRateFormData['cost_rate'],
  billRate: string,
  peopleCount: number,
];

type RoleRowWithCostRate = RoleRowWithNewCostRate | RoleRowWithOldCostRate;

export type RoleRow =
  | RoleRowWithoutCostRate
  | RoleRowWithNewCostRate
  | RoleRowWithOldCostRate;

export type RoleChanges = {
  roleName: string;
  billRateFrom: string;
  billRateTo: string;
  costRateFrom?: CostRateFormData['cost_rate'];
  costRateTo?: CostRateFormData['cost_rate'];
  costRateEffectiveDate?: string | null;
};

function isRoleRowWithoutCostRate(row: RoleRow): row is RoleRowWithoutCostRate {
  return row.length === 3;
}

function isRoleChangeWithCostRate(row: RoleRow): row is RoleRowWithCostRate {
  return row.length === 4;
}

function isRoleRowWithOldCostRate(row: RoleRow): row is RoleRowWithOldCostRate {
  if (!isRoleChangeWithCostRate(row)) return false;

  const costRate = row[1];
  return !costRate || typeof costRate === 'string';
}

function getChangedRoleCostRate(row: RoleRowWithCostRate) {
  if (isRoleRowWithOldCostRate(row)) {
    return { costRateTo: row[1] };
  }

  return {
    costRateTo: row[1].cost_rate,
    costRateEffectiveDate: row[1].cost_rate_effective_date,
  };
}

function getRoleChangesWithCostRate(before: RoleRow, after: RoleRow) {
  if (!(isRoleChangeWithCostRate(after) && isRoleRowWithOldCostRate(before))) {
    throw new Error('Expected role table rows with cost rate values.');
  }

  const [roleName, , billRateTo] = after;
  const [, costRateFrom, billRateFrom] = before;

  return {
    roleName,
    billRateFrom,
    billRateTo,
    costRateFrom,
    ...getChangedRoleCostRate(after),
  };
}

function getRoleChangesWithoutCostRate(before: RoleRow, after: RoleRow) {
  if (!(isRoleRowWithoutCostRate(after) && isRoleRowWithoutCostRate(before))) {
    throw new Error('Expected role table rows without cost rate values.');
  }

  const [roleName, billRateTo] = after;
  const [, billRateFrom] = before;
  return {
    roleName,
    billRateFrom,
    billRateTo,
  };
}

export function hasRoleBillRateChanged(changes: RoleChanges) {
  return changes.billRateFrom !== changes.billRateTo;
}

export function hasRoleCostRateChanged(changes: RoleChanges) {
  return changes.costRateFrom !== changes.costRateTo;
}

export function hasAnyRoleRateChanged(props: RoleChanges) {
  return hasRoleBillRateChanged(props) || hasRoleCostRateChanged(props);
}

export function getRoleChanges(before: RoleRow, after: RoleRow): RoleChanges {
  if (isCostRateFeatureActive()) {
    return getRoleChangesWithCostRate(before, after);
  }

  return getRoleChangesWithoutCostRate(before, after);
}
