import React from 'react';
import { t } from '@lingui/macro';
import cs from 'classnames';

import { formatAmount } from '@float/common/lib/budget';
import { formatPercentage } from '@float/common/lib/budget/helpers/getPercentage';
import { type ProjectBudgetUsage } from '@float/types/project';
import { Select } from '@float/ui/components/Select';
import { Tooltip } from '@float/ui/components/Tooltip';
import { IconInformation } from '@float/ui/icons/essentials/IconInformation';
import { getHasFixedBudgetSupport } from '@float/web/reports/helpers/getHasFixedBudgetSupport';
import { ProjectMarginType } from '@float/web/reports/types';

import {
  ItemHeader,
  ItemLabel,
  ItemSubLabel,
  ReportsTotalsItem,
} from '../../../styles';
import type { ProjectSummaryInfo, TotalsData } from '../types';

import * as styles from './SummaryItemMargin.css';

export enum SummaryItemTotalBudgetMarginCalculationType {
  LOGGED = 'logged',
  SCHEDULED = 'scheduled',
  COMBINED = 'combined',
}

export type SummaryItemTotalBudgetProps = {
  project: ProjectSummaryInfo;
  projectBudget: ProjectBudgetUsage;
  totals: TotalsData;
  marginCalculationType: SummaryItemTotalBudgetMarginCalculationType;
  projectMarginType: ProjectMarginType;
  hasPeopleWithMissingCostRates: boolean;
  onProjectMarginTypeChange: (value: string) => void;
};

export const SummaryItemMargin = ({
  project,
  projectBudget,
  totals,
  marginCalculationType,
  hasPeopleWithMissingCostRates,
  projectMarginType,
  onProjectMarginTypeChange,
}: SummaryItemTotalBudgetProps) => {
  const { budget_type } = project;

  const hasFixedBudgetSupport = getHasFixedBudgetSupport(budget_type);
  const isUsingFixedBudgetMarginType =
    hasFixedBudgetSupport &&
    projectMarginType === ProjectMarginType.MarginOnFixedFee;

  let projectBillableCalculated = 0;
  let projectCostCalculated = 0;

  const marginLabel = t`Margin`;
  let marginContentTooltipContent;

  if (
    marginCalculationType === SummaryItemTotalBudgetMarginCalculationType.LOGGED
  ) {
    marginContentTooltipContent = isUsingFixedBudgetMarginType
      ? t`Calculated based on the total budget and the cost of all logged hours`
      : t`Calculated based on the logged billable amount and the cost of all logged hours`;
    projectBillableCalculated = isUsingFixedBudgetMarginType
      ? projectBudget?.budget_total ?? 0
      : totals.logged.feeCents / 100;
    projectCostCalculated = totals.logged.costCents / 100;
  } else if (
    marginCalculationType ===
    SummaryItemTotalBudgetMarginCalculationType.SCHEDULED
  ) {
    marginContentTooltipContent = isUsingFixedBudgetMarginType
      ? t`Calculated based on the total budget and the cost of all scheduled hours`
      : t`Calculated based on the scheduled billable amount and the cost of all scheduled hours`;
    projectBillableCalculated = isUsingFixedBudgetMarginType
      ? projectBudget?.budget_total ?? 0
      : totals.feeCents / 100;
    projectCostCalculated = totals.costCents / 100;
  } else if (
    marginCalculationType ===
    SummaryItemTotalBudgetMarginCalculationType.COMBINED
  ) {
    marginContentTooltipContent = isUsingFixedBudgetMarginType
      ? t`Calculated based on the total budget and the cost of all past logged plus future scheduled hours`
      : t`Calculated based on the projected billable amount and the cost of all past logged plus future scheduled hours`;
    projectBillableCalculated = isUsingFixedBudgetMarginType
      ? projectBudget?.budget_total ?? 0
      : totals.combined.feeCents / 100;
    projectCostCalculated = totals.combined.costCents / 100;
  }

  const marginValue = projectBillableCalculated - projectCostCalculated;
  const marginValueFormatted = formatAmount(budget_type, marginValue);

  const marginPercent =
    (projectBillableCalculated - projectCostCalculated) /
    (projectBillableCalculated || 1);

  const marginPercentFormatted = formatPercentage(marginPercent);

  return (
    <ReportsTotalsItem>
      <div className={styles.contentWrapper}>
        <ItemLabel>
          <div className={styles.labelWrapper}>
            {marginLabel}

            <Tooltip
              content={
                <div className={styles.tooltipContentWrapper}>
                  {marginContentTooltipContent}
                </div>
              }
            >
              <span
                className={styles.infoIconWrapper}
                aria-label={t`Budget information icon`}
              >
                <IconInformation size={20} />
              </span>
            </Tooltip>
          </div>
        </ItemLabel>

        <ItemHeader>
          {!hasPeopleWithMissingCostRates && (
            <>
              <span
                className={cs({
                  [styles.valuePositive]: marginPercent > 0,
                  [styles.valueNegative]: marginPercent < 0,
                })}
              >
                {marginPercentFormatted}
              </span>

              <ItemSubLabel>{marginValueFormatted}</ItemSubLabel>
            </>
          )}

          {hasPeopleWithMissingCostRates && (
            <span className={styles.marginValueMissing}>—</span>
          )}
        </ItemHeader>

        {hasFixedBudgetSupport && (
          <Select
            className={styles.marginType}
            hideLabel={true}
            value={projectMarginType}
            options={[
              {
                label: t`Margin based on total budget`,
                value: ProjectMarginType.MarginOnFixedFee,
              },
              {
                label: t`Margin based on total billable`,
                value: ProjectMarginType.MarginOnPeopleFee,
              },
            ]}
            onChange={onProjectMarginTypeChange}
          />
        )}
      </div>
    </ReportsTotalsItem>
  );
};
