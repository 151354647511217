import { useEffect, useRef } from 'react';
import { throttle } from 'lodash';

interface PlatformSafeResizeObserver<Entry> {
  new (callback: (entries: Entry[]) => void): {
    observe: (element: Element) => void;
    unobserve: (element: Element) => void;
  };
}
/**
 * A performance-optimized hook that abstracts the web-specific ResizeObserver
 * API and allows it to be run outside of web contexts.
 *
 * You probably don't want to use this hook directly, but instead use a
 * platform-specific implementation like `libs/web/hooks/useResizeObserver`.
 */
export const usePlatformSafeResizeObserver = <Entry>(
  targetRef: { current: Element | null } | null = { current: null },
  resizeObserver: PlatformSafeResizeObserver<Entry>,
  observerCallback: (entries: Entry[]) => void, // Run on every resize
  { enabled = true, wait = 500 } = {},
) => {
  // a little trick to update the callback without re-triggering the subscription effect
  const callbackRef = useRef(observerCallback);
  useEffect(() => {
    callbackRef.current = observerCallback;
  });

  const wrapperElement = targetRef?.current;

  useEffect(() => {
    if (!enabled) return;

    if (!wrapperElement) {
      return;
    }

    const observer = new resizeObserver(
      throttle(callbackRef.current, wait, { trailing: true }),
    );

    observer.observe(wrapperElement);

    return () => {
      observer.unobserve(wrapperElement);
    };
  }, [enabled, wrapperElement, callbackRef, wait, resizeObserver]);
};
