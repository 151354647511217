import React, { StrictMode } from 'react';
import { t, Trans } from '@lingui/macro';

import { type CurrencyConfig } from '@float/common/lib/rates/rates';
import {
  ConfirmCheckboxAppearance,
  ModalConfirm,
} from '@float/ui/components/Modals/ModalConfirm';
import { IconThumbsUp } from '@float/ui/icons/essentials/IconThumbsUp';

import {
  getConfirmPrimaryMessage,
  getConfirmTitle,
  RawRate,
} from './ModalConfirmRateChange.helpers';

export type ModalConfirmRateChangeProps = {
  roleName?: string;
  billRateFrom?: RawRate;
  billRateTo?: RawRate;
  costRateFrom?: RawRate;
  costRateTo?: RawRate;
  numRolesAffected?: number;
  numPeopleAffected: number;
  currencyConfig: CurrencyConfig;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ModalConfirmRateChange = (props: ModalConfirmRateChangeProps) => {
  const {
    roleName,
    billRateFrom,
    billRateTo,
    costRateFrom,
    costRateTo,
    numPeopleAffected,
    numRolesAffected,
    currencyConfig,
    onConfirm,
    onCancel,
  } = props;

  const confirmTitle = getConfirmTitle({
    roleName,
    numPeopleAffected,
    numRolesAffected,
    billRateFrom,
    billRateTo,
    costRateFrom,
    costRateTo,
  });

  const confirmPrimaryMessage = getConfirmPrimaryMessage({
    billRateFrom,
    billRateTo,
    costRateFrom,
    costRateTo,
    numPeopleAffected,
    numRolesAffected,
    currencyConfig,
  });

  return (
    <StrictMode>
      <ModalConfirm
        twoStepConfirmation
        title={confirmTitle}
        primaryButtonLabel={t`Update rates`}
        secondaryButtonLabel={t`Cancel`}
        confirmCheckboxAppearance={ConfirmCheckboxAppearance.Warning}
        confirmCheckboxIcon={IconThumbsUp}
        modalProps={{ open: true }}
        onClickConfirm={onConfirm}
        onOpenChange={onCancel}
      >
        {confirmPrimaryMessage && <p>{confirmPrimaryMessage}</p>}
        <p>
          <Trans>
            New rate will not affect custom Person, Project, or Phase rates.
          </Trans>
        </p>
      </ModalConfirm>
    </StrictMode>
  );
};
