import React, { useRef } from 'react';
import { t } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { AvatarImage } from '@float/common/components/Avatar/AvatarImage';
import { Rights } from '@float/common/lib/acl';
import { isNewPlaceholderType } from '@float/common/lib/people/isNewPlaceholderType';
import { useSearchSelectorWithParams } from '@float/common/search/useSearchSelectorWithParams';
import { getUser } from '@float/common/selectors/currentUser';
import { getProjectById } from '@float/common/selectors/projects';
import { createInsightsEntry } from '@float/common/serena/Data/insights/helpers/createInsightsEntry';
import { PersonProjectRow } from '@float/common/serena/Data/useScheduleRows';
import {
  useAppSelectorStrict,
  useAppSelectorWithParams,
} from '@float/common/store';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import {
  LoggedTimeCell,
  Person,
  PersonCell,
  PersonType,
  Project,
  ProjectCell,
} from '@float/types';
import { DownSmall as IconDownSmall } from '@float/ui/deprecated/Icons/iconChevronDownSmall';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { Table } from '@float/ui/deprecated/Table/Table';

import { InsightForProjectPlan } from '../../insights/InsightForProjectPlan/InsightForProjectPlan';
import { getPersonStatusToday, usePersonTagsWithColor } from './PersonCard';

import * as styles from './PersonCard.css';

export type ProjectPersonCardProps = {
  currentWeekCell: ProjectCell | PersonCell | LoggedTimeCell | undefined;
  person: Person;
  actions: {
    showAddTaskModal: (person: Person) => void;
    showPersonModal: (person: Person) => void;
    removeFromProjects: (person: Person, projects: Project[]) => void;
    showSidebarMenu: (
      position: React.MutableRefObject<unknown>,
      options: unknown,
    ) => void;
    setDragItem: (...args: unknown[]) => void;
    showSwitchPersonModal: (...args: unknown[]) => void;
  };
  timeRangeSettings?: { insightsPreferredUnit?: string };
  logTimeView: boolean;
  rowHeight: number;
  dayOfWeek: number;
  row: PersonProjectRow;
  rowGroupTop: number;
  isTimer?: boolean;
};

function ProjectPersonCard(props: ProjectPersonCardProps) {
  const positionRef = useRef<SVGSVGElement>(null);
  const { showPersonModal, removeFromProjects, showSwitchPersonModal } =
    props.actions;

  const insights = useAppSelectorStrict((state) => state.timeRange.insights);
  const insightsLoading = useAppSelectorStrict(
    (state) => state.timeRange.loadState === 'INITIAL',
  );
  const currentUser = useAppSelectorStrict(getUser);

  const { avatar_file, name, job_title, department, people_type_id } =
    props.person;
  const { isTimer = false, timeRangeSettings } = props;

  const tags = usePersonTagsWithColor(props.person);
  const isPersonNewPlaceholderType = isNewPlaceholderType({
    person: props.person,
  });

  const { projectId, peopleId } = props.row;
  const type = currentUser.account_tid;
  const project = useAppSelectorWithParams(getProjectById, projectId);

  const isSharedLink = currentUser.shared_link_view;

  const isTasked = useSearchSelectorWithParams(
    'isPersonTaskedInTheProject',
    [projectId, peopleId],
    true,
  );

  const isLegacyPlaceholderType =
    people_type_id === PersonType.PLACEHOLDER && !isPersonNewPlaceholderType;

  const isLowEmphasis = isLegacyPlaceholderType || !isTasked;

  if (!project) {
    // The user just deleted a project, and React chose to update this before
    // re-processing which rows to render. Just bail and things will be ok.
    return null;
  }
  const canEditProject = Rights.canUpdateProject(currentUser, {
    project,
  });

  const projectColor = `#${project.color}`;
  const personStatusToday = getPersonStatusToday(
    props.currentWeekCell,
    props.dayOfWeek,
  );

  const showMenu = (e: React.MouseEvent) => {
    prevent(e);

    const options = [];

    options.push({
      title: t`View profile`,
      action: () => showPersonModal(props.person),
    });

    if (type !== 4) {
      options.push({
        title: t`Swap person`,
        action: () =>
          showSwitchPersonModal({
            person: props.person,
            project,
          }),
      });
      options.push({
        title: t`Remove from project`,
        action: () => removeFromProjects(props.person, [project]),
      });
    }

    props.actions.showSidebarMenu(positionRef, options);
  };

  const insightProject =
    insights.byProject?.[projectId]?.[props.person.people_id] ??
    createInsightsEntry();

  // The total capacity, capacity (remaining) and overtime values calculation are missing
  // in the insights.byProject,
  // for that reason we need to obtain the totalCapacity value from byPerson breakdown
  const insightPerson =
    insights.byPerson?.[props.person.people_id] ?? createInsightsEntry();

  const insight = {
    ...insightProject,
    capacity: insightPerson.capacity || 0,
    overtime: insightPerson.overtime || 0,
    totalCapacity: insightPerson.totalCapacity || 0,
  };

  const insightDisplayMetric =
    timeRangeSettings?.insightsPreferredUnit === 'percentage'
      ? 'utilization'
      : 'allocation';

  const isCompactView = currentUser.prefs?.sked_zoom === 1;

  return (
    <div
      className={styles.wrapper}
      data-testid={`project-person-card`}
      data-interactive={!isSharedLink}
      data-islowemphasis={isLowEmphasis}
      onMouseDown={(event) => {
        if (event.button !== 0 || isSharedLink) return;
        props.actions.setDragItem({
          type: 'person-card',
          element: <div />,
          invisible: true,
          row: props.row,
          rowGroupTop: props.rowGroupTop,
          rowHeight: props.rowHeight,
          person: props.person,
          clientX: event.clientX,
          clientY: event.clientY,
        });
      }}
    >
      <div
        className={styles.projectPersonCardContent}
        style={assignInlineVars({
          [styles.projectPersonCardBgColorVar]: projectColor,
        })}
      >
        <div className={styles.projectPersonCardInnerContent}>
          <div
            className={styles.avatar({
              type: 'projectPersonCard',
            })}
          >
            <AvatarImage
              name={name}
              imageUrl={avatar_file}
              size="sm2"
              style={{ position: 'absolute' }}
              statusText={personStatusToday.name}
              statusColor={personStatusToday.color}
              isNewPlaceholderType={isPersonNewPlaceholderType}
            />
          </div>
          <div
            className={styles.personName({
              emphasis: isLowEmphasis ? 'low' : 'high',
              density: isCompactView ? 'compact' : 'default',
            })}
          >
            <div className={styles.personNameText}>{name}</div>
            {(props.person.canEdit || canEditProject) && (
              <div
                className={styles.menuBtn}
                onMouseDown={prevent}
                onClick={showMenu}
                data-testid="person-menu"
              >
                <IconDownSmall ref={positionRef} />
              </div>
            )}
          </div>
          {job_title && (
            <>
              <Spacer size={1} inline={false} />
              <div
                className={styles.jobTitle({
                  emphasis: isLowEmphasis ? 'low' : 'high',
                  density: isCompactView ? 'compact' : 'default',
                })}
              >
                {job_title}
              </div>
            </>
          )}
          {department && (
            <>
              <Spacer size={2} inline={false} />
              <div
                className={styles.departmentName({
                  emphasis: isLowEmphasis ? 'low' : 'high',
                  density: isCompactView ? 'compact' : 'default',
                })}
              >
                {department.name}
              </div>
            </>
          )}
          <Table.Tags
            className={styles.tags({
              density: isCompactView ? 'compact' : 'default',
            })}
            tags={tags}
            size={isCompactView ? 'xxsmall' : 'xsmall'}
          />
        </div>
      </div>
      {!insightsLoading && (
        <div
          className={styles.insightWrapper({
            density: isCompactView ? 'compact' : 'default',
          })}
          style={assignInlineVars({
            [styles.rowHeightVar]: `${props.rowHeight}px`,
          })}
          onMouseDown={props.logTimeView ? prevent : undefined}
        >
          {!isTimer && (
            <InsightForProjectPlan
              insight={insight}
              displayMetric={insightDisplayMetric}
              isForSingleProject={true}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(ProjectPersonCard);
