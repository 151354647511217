import React from 'react';
import { t, Trans } from '@lingui/macro';
import { isNil } from 'es-toolkit';

import { getCostRatePayload } from '@float/common/actions/lib/getCostRatePayload';
import { formatDateForDB } from '@float/common/lib/utils';
import { Person, RoleOption } from '@float/types';
import { CostRateHistoryPayload } from '@float/types/costRate';
import { getPeopleCountLabel } from '@float/web/components/modals/ModalConfirmRateChange/ModalConfirmRateChange.helpers';
import * as styles from '@float/web/components/modals/ModalConfirmRateChange/ModalConfirmRateChange.styles.css';

import { getEffectiveDateLabel } from './Rate.helpers';

export type GetRoleChangeConfirmDataProps = {
  ids: number[];
  entities: Record<number, Person>;
  fields: {
    role_id: Person['role_id'];
    default_hourly_rate?: Person['default_hourly_rate'];
    cost_rate_history?: CostRateHistoryPayload;
  };
};

function getNumberOfPeopleAffected({
  ids,
  entities,
}: Pick<GetRoleChangeConfirmDataProps, 'ids' | 'entities'>) {
  const peopleSelected = ids.map((id) => entities[id]).filter(Boolean);

  const peopleWithRateChange = peopleSelected.filter(
    (person) => !isNil(person.default_hourly_rate) || !isNil(person.cost_rate),
  );

  return peopleWithRateChange.length;
}

export const getRoleChangeConfirmData = ({
  ids,
  entities,
  fields,
}: GetRoleChangeConfirmDataProps) => {
  const willBillRateChange = typeof fields.default_hourly_rate !== 'undefined';
  const willCostRateChange = typeof fields.cost_rate_history !== 'undefined';
  const willRateChange = willBillRateChange || willCostRateChange;

  const numPeopleWithRateChange = willRateChange
    ? getNumberOfPeopleAffected({ ids, entities })
    : 0;

  const hasPeopleWithRateChange = numPeopleWithRateChange > 0;

  // No confirmation dialog needed if no rate change
  if (!hasPeopleWithRateChange) return null;

  const effectiveDateLabel = willCostRateChange
    ? getEffectiveDateLabel(fields.cost_rate_history)
    : null;

  const peopleLabel = getPeopleCountLabel(ids.length);

  const costRateImpact = effectiveDateLabel ? (
    <li className={styles.listItem}>
      Apply new cost rates from {effectiveDateLabel} to all current Projects and
      any future work
    </li>
  ) : null;

  return {
    twoStepConfirmation: true,
    primaryButtonLabel: t`Update Role`,
    title: t`Update Role for ${peopleLabel}?`,
    message: (
      <p>
        <Trans>
          This will:
          <ul className={styles.list}>
            <li className={styles.listItem}>
              Replace the custom rates for {peopleLabel} with the default rates
              of their new Role
            </li>
            {costRateImpact}
          </ul>
          New Role rates do not replace bill rates for current Projects.
        </Trans>
      </p>
    ),
  };
};

export function getRatesToResetForSelectedRole(props: {
  shouldApplyRoleRate: boolean;
  roleSelected: RoleOption | null;
}) {
  const { shouldApplyRoleRate, roleSelected } = props;
  if (!shouldApplyRoleRate || !roleSelected) return {};

  const { billRate, costRate } = roleSelected;

  const hasBillRate = !isNil(billRate);
  const hasCostRate = !isNil(costRate);

  if (hasBillRate && hasCostRate) {
    return {
      default_hourly_rate: null,
      ...getCostRatePayload({
        cost_rate_effective_date: formatDateForDB(),
      }),
    };
  }

  if (hasBillRate) {
    return {
      default_hourly_rate: null,
    };
  }

  if (hasCostRate) {
    return getCostRatePayload({
      cost_rate_effective_date: formatDateForDB(),
    });
  }

  return {};
}

export function doesSelectedRoleHaveRates(roleSelected: RoleOption | null) {
  if (!roleSelected) return false;

  return !isNil(roleSelected.billRate) || !isNil(roleSelected.costRate);
}
