import { REHYDRATE } from 'redux-persist';

import { fastObjectSpread } from '@float/common/lib/fast-object-spread';
import { omit } from '@float/common/lib/omit';
import { Tag } from '@float/types';

import {
  TAGS_CREATED,
  TAGS_DELETED,
  TAGS_LOAD_FAILED,
  TAGS_LOAD_FINISH,
  TAGS_LOAD_START,
  TAGS_UPDATED,
} from '../actions';
import { LoadState, RehydratePartialStateAction } from './lib/types';

export type TagsState = {
  tags: Record<number | string, Tag>;
  loadState: LoadState;
  tagsLoaded: boolean;
};

const REDUCER_NAME = 'tags';
export const DEFAULT_STATE: TagsState = {
  tags: {},
  loadState: LoadState.UNLOADED,
  tagsLoaded: false,
};

export type TagAction =
  | {
      type: typeof TAGS_LOAD_START;
    }
  | {
      type: typeof TAGS_LOAD_FAILED;
    }
  | {
      type: typeof TAGS_LOAD_FINISH;
      tags: Tag[];
    }
  | {
      type: typeof TAGS_UPDATED;
      tags: Tag[];
    }
  | {
      type: typeof TAGS_CREATED;
      tags: Tag[];
    }
  | {
      type: typeof TAGS_DELETED;
      tags: Tag[];
    };

function assignTags(map: Record<number | string, Tag>, list: Tag[]) {
  for (const tag of list) {
    map[tag.tags_id] = tag;
  }

  return map;
}

export default function reducer(
  state = DEFAULT_STATE,
  action:
    | TagAction
    | RehydratePartialStateAction<TagsState, typeof REDUCER_NAME>,
): TagsState {
  switch (action.type) {
    case TAGS_LOAD_START: {
      return {
        ...state,
        loadState: LoadState.LOADING,
      };
    }

    case TAGS_LOAD_FAILED: {
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
      };
    }

    case TAGS_LOAD_FINISH: {
      if (!action.tags?.length)
        return {
          ...state,
          loadState: LoadState.LOADED,
          tagsLoaded: true,
        };

      return {
        ...state,
        loadState: LoadState.LOADED,
        tagsLoaded: true,
        tags: assignTags(fastObjectSpread(), action.tags),
      };
    }

    case TAGS_UPDATED:
    case TAGS_CREATED: {
      return {
        ...state,
        tags: assignTags(fastObjectSpread(state.tags), action.tags),
      };
    }

    case TAGS_DELETED: {
      const tagsToRemove = action.tags.map((t) => t.tags_id.toString());

      return {
        ...state,
        tags: omit(state.tags, tagsToRemove),
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.tagsLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
}
