import {
  SIDE_CELL_WIDTH,
  SINGLE_PROJECT_SIDE_CELL_WIDTH,
} from '@float/constants/schedule';

export const getSideCellWidth = (isSingleProjectView: boolean) =>
  isSingleProjectView ? SINGLE_PROJECT_SIDE_CELL_WIDTH : SIDE_CELL_WIDTH;

export const getSideCellWidthString = (isSingleProjectView: boolean) =>
  isSingleProjectView
    ? `${SINGLE_PROJECT_SIDE_CELL_WIDTH}px`
    : `${SIDE_CELL_WIDTH}px`;
