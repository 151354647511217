import { subtractOperation } from '@float/libs/utils/floats';
import { CellItem } from '@float/types/cells';

export const getCellOvertimeItems = function getOvertimeItems(
  dailyWorkHours: number[] | undefined,
  allocatedDailyHours: number[],
  forceShowOvertimeItemsOnWorkingDays: boolean,
) {
  const cellItemsOvertime: CellItem<'overtime'>[] = [];

  // Add overtime items
  for (let i = 0; i < 7; i++) {
    const dailyWorkHoursOnThisDay = dailyWorkHours?.[i] || 0;
    const hoursAllocatedOnThisDay = allocatedDailyHours[i];

    const hasDailyWorkHoursOnThisDay = dailyWorkHoursOnThisDay > 0;

    const overtime = hasDailyWorkHoursOnThisDay
      ? Math.max(
          subtractOperation(hoursAllocatedOnThisDay, dailyWorkHoursOnThisDay),
          0,
        )
      : 0;

    const hasOvertime = overtime > 0;

    if (
      hasOvertime ||
      (forceShowOvertimeItemsOnWorkingDays && hasDailyWorkHoursOnThisDay)
    ) {
      cellItemsOvertime.push({
        type: 'overtime',
        key: `overtime:${i}`,
        cellKey: `overtime:${i}`,
        entityId: `overtime:${i}`,
        x: i,
        w: 1,
        y: dailyWorkHoursOnThisDay,
        h: overtime,
      });
    }
  }

  return cellItemsOvertime;
};
