import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans } from '@lingui/macro';
import cn from 'classnames';
import { Transition, TransitionRef } from 'TransitionManager';

import { trackCompanyData } from '@float/common/lib/intercom';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { Button } from '@float/ui/components/Button';
import RadioButton from '@float/ui/deprecated/Earhart/Buttons/RadioButton';
import { useRadio } from '@float/ui/deprecated/Radio/Radio';
import { IconArrowRight } from '@float/ui/icons/essentials/IconArrowRight';
import { OnboardingPrompt } from '@float/web/store/onboardingManager/types';

import { OnboardingSurveyOption } from '../../types';
import { getSurveyGoalsConfig } from './config';
import { SurveyGoalsDataType } from './types';

import * as commonStyles from '../../styles.css';
import * as styles from '../GetToKnowYou/styles.css';

const withDefault = (
  data: SurveyGoalsDataType,
  dataKey: keyof SurveyGoalsDataType,
  previousState: OnboardingPrompt['previousState'],
) => {
  if (previousState) {
    return data[dataKey].map((item) => {
      if (item.data === previousState[dataKey]) {
        return { ...item, default: true };
      }

      return { ...item, default: false };
    });
  }

  return data[dataKey];
};

export const SurveyGoals = forwardRef<TransitionRef, OnboardingPrompt>(
  (props, ref) => {
    const { transitionId, onClickNext, previousState } = props;

    const user = useAppSelectorStrict(getUser);

    const contentRef = useRef(null);

    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const config = useMemo(() => getSurveyGoalsConfig(), []);

    const getGoalsData = useMemo(() => {
      return withDefault(config, 'goals', previousState);
    }, [config, previousState]);

    const [goals, goalsProps] = useRadio(getGoalsData);

    // Interaction handlers

    const goNext = useCallback(() => {
      if (onClickNext) {
        onClickNext({
          goal: goals.data,
        });
      }
    }, [onClickNext, goals]);

    const onClickNextHandler = useCallback(async () => {
      trackCompanyData('SurveyGoals', goals.data, user.cid);

      goNext();

      setLoading(true);
    }, [goNext, goals, user.cid]);

    // Effects

    useEffect(() => {
      const ready = !!goals;

      setReady(ready);
    }, [goals]);

    return (
      <Transition ref={ref} id={transitionId}>
        <div className={commonStyles.content} ref={contentRef}>
          <h1 className={commonStyles.h1}>
            <Trans>Tell us your team's goals</Trans>
          </h1>

          <div className={cn(commonStyles.question, styles.goals)}>
            <h2 className={commonStyles.h2}>
              <Trans>
                What are you hoping Float can help you with the most?
              </Trans>
            </h2>

            <div className={styles.options}>
              {goalsProps.options.map(
                (option: OnboardingSurveyOption & { value: number }) => {
                  const { label, desc, value } = option;

                  const isSelected = value === goalsProps.value;

                  return (
                    <RadioButton
                      // @ts-expect-error RadioButton is not typed
                      className={styles.radioButton}
                      key={value}
                      label={label}
                      name="goals"
                      onChange={goalsProps.onChange}
                      selected={isSelected}
                      subLabel={desc}
                      tabIndex={0}
                      value={value}
                    />
                  );
                },
              )}
            </div>
          </div>

          <Button
            disabled={!ready || loading}
            iconEnd={IconArrowRight}
            isLoading={loading}
            onClick={onClickNextHandler}
            size="medium"
          >
            <Trans>Next</Trans>
          </Button>
        </div>
      </Transition>
    );
  },
);
