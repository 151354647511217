import { useMemo, useState } from 'react';
import useMount from 'react-use/esm/useMount';

import { normalize } from '@float/common/search/helpers';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { FloatStorageKey, storage } from '@float/libs/storage';
import { CurrentUser, SavedView } from '@float/types';

const matchAllViewTypes = (_: SavedView) => true;
const matchPersonalViewTypes = (view: SavedView) => view.personal;
const matchSharedViewTypes = (view: SavedView) => !view.personal;

/**
 * Decorate a compare function by giving priority to the pinned stat
 */
const compareByPinnedState =
  (compareWhenEqual: (a: SavedView, b: SavedView) => number) =>
  (a: SavedView, b: SavedView) =>
    a.pinned === b.pinned
      ? compareWhenEqual(a, b)
      : Number(b.pinned) - Number(a.pinned);

const getMostRecentValue = (view: SavedView) =>
  view.lastUsed ? view.lastUsed : '-' + view.created;

const compareByMostRecent = compareByPinnedState((a: SavedView, b: SavedView) =>
  getMostRecentValue(b).localeCompare(getMostRecentValue(a)),
);

const compareByNameAsc = compareByPinnedState((a: SavedView, b: SavedView) =>
  a.name.localeCompare(b.name),
);

const compareByNameDesc = compareByPinnedState((a: SavedView, b: SavedView) =>
  b.name.localeCompare(a.name),
);

const filterAndSortViews = (
  views: SavedView[],
  filter: string,
  viewType: ViewTypeFilter,
  sortOrder: ViewSortType,
) => {
  const normalizedFilter = normalize(filter);

  let viewTypeMatcher = matchAllViewTypes;

  if (viewType === 'personal') {
    viewTypeMatcher = matchPersonalViewTypes;
  } else if (viewType === 'shared') {
    viewTypeMatcher = matchSharedViewTypes;
  }

  const result = views.filter(
    (view) =>
      viewTypeMatcher(view) && normalize(view.name).includes(normalizedFilter),
  );

  let compare = compareByMostRecent;

  if (sortOrder === 'a-z') {
    compare = compareByNameAsc;
  } else if (sortOrder === 'z-a') {
    compare = compareByNameDesc;
  }

  result.sort(compare);

  return result;
};

export type ViewTypeFilter = 'all' | 'personal' | 'shared';
export type ViewSortType = 'recent' | 'a-z' | 'z-a';

export function getLastViewSortTypeCacheKey(
  user: Pick<CurrentUser, 'admin_id' | 'cid'>,
) {
  if (!user) {
    return;
  }

  const { cid, admin_id } = user;
  return `${cid}:${admin_id}:lastViewSortType`;
}

export function useViewsOptions(views: SavedView[]) {
  const [viewNameFilter, setViewNameFilter] = useState('');
  const [viewTypeFilter, setViewTypeFilter] = useState<ViewTypeFilter>('all');
  const [viewSortType, setViewSortType] = useState<ViewSortType>('recent');

  const user = useAppSelectorStrict(getUser);
  const localStorageKey = getLastViewSortTypeCacheKey(user);

  useMount(() => {
    const fetchSortType = async () => {
      const value = await storage.getItem(localStorageKey as FloatStorageKey);
      if (value) {
        setViewSortType(value as ViewSortType);
      }
    };

    fetchSortType();
  });

  const setViewSortTypeAndPersist = (value: ViewSortType) => {
    setViewSortType(value);
    storage.setItem(localStorageKey as FloatStorageKey, value);
  };

  const options = useMemo(
    () =>
      filterAndSortViews(views, viewNameFilter, viewTypeFilter, viewSortType),
    [views, viewNameFilter, viewTypeFilter, viewSortType],
  );

  return {
    options,
    setViewNameFilter,
    viewNameFilter,
    setViewTypeFilter,
    viewTypeFilter,
    setViewSortType: setViewSortTypeAndPersist,
    viewSortType,
  };
}
