import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { FilterToken } from '@float/types';

import { isNot } from '../helpers';
import { FiltersContext, FiltersDataType, FiltersEntity } from '../types';
import { getAppliedFiltersGroups } from './helpers/getAppliedFiltersGroups';
import { getFilteredDataWithContextNarrowing } from './helpers/getFilteredDataWithContextNarrowing';
import { getIsNotOperatorAccepted } from './helpers/getIsNotOperatorAccepted';

const getFilteredData = <T extends FiltersDataType, ID extends string | number>(
  dataType: T,
  context: FiltersContext<T>,
  data: FiltersEntity<T>[],
  searchFilters: FilterToken[] = [],
  getEntityId: (entity: FiltersEntity<T>) => ID,
): Set<ID> => {
  const { me } = context;

  const noFilters = searchFilters.length === 0 && !me;

  if (noFilters) {
    return new Set(data.map(getEntityId));
  }

  if (
    featureFlags.isFeatureEnabled(
      FeatureFlag.AggregateTagAndStatusFilteringForProjects,
    )
  ) {
    return getFilteredDataWithContextNarrowing(
      dataType,
      context,
      data,
      searchFilters,
      getEntityId,
    );
  }

  // This maps [{ type, val }, { type, val2 }, ...] to { type: [val, val2], type2: [val3] }
  const groupedFilters = getAppliedFiltersGroups(
    dataType,
    searchFilters,
    context,
  );

  const result = new Set<ID>();

  data.forEach((entity) => {
    if (entity === undefined) return false;

    const match = groupedFilters.some((filterGroup) => {
      return filterGroup.every((filter) => {
        if (!filter.values.length && filter.type !== 'me') return true;

        if (filter.matcher.forceMatch) return true;

        if (isNot(filter.operator)) {
          if (getIsNotOperatorAccepted(filter.type, entity, dataType)) {
            return filter.matcher.matches(entity) === false;
          }

          return true;
        }

        return filter.matcher.matches(entity);
      });
    });

    if (match) {
      result.add(getEntityId(entity));
    }
  });

  return result;
};

export const forPeople = (
  context: FiltersContext<'people'>,
  people: FiltersEntity<'people'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'people',
    context,
    people,
    filters,
    (entity) => entity.people_id,
  );
};

export const forProjects = (
  context: FiltersContext<'projects'>,
  projects: FiltersEntity<'projects'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'projects',
    context,
    projects,
    filters,
    (entity) => entity.project_id,
  );
};

export const forTasks = (
  context: FiltersContext<'tasks'>,
  tasks: FiltersEntity<'tasks'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'tasks',
    context,
    tasks,
    filters,
    (entity) => entity.task_id,
  );
};

export const forTimeoffs = (
  context: FiltersContext<'timeoffs'>,
  timeoffs: FiltersEntity<'timeoffs'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'timeoffs',
    context,
    timeoffs,
    filters,
    // Casting to string because timeoffs are part of the filteredEntities
    // and aligning all the filteredEntities types to string helps on reducing
    // possible false negatives related to the id types
    (entity) => String(entity.timeoff_id),
  );
};

export const forLoggedTimes = (
  context: FiltersContext<'loggedTimes'>,
  loggedTimes: FiltersEntity<'loggedTimes'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'loggedTimes',
    context,
    loggedTimes,
    filters,
    (entity) => entity.logged_time_id,
  );
};
