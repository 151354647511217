import { keyBy, omit } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { Status } from '@float/types/status';

import {
  STATUS_DELETED,
  STATUS_UPDATED,
  STATUSES_LOAD_FAILED,
  STATUSES_LOAD_FINISH,
  STATUSES_LOAD_START,
} from '../actions/statuses';
import { formatStatus } from '../lib/formatters';
import { LoadState } from './lib/types';

export type StatusesState = {
  statuses: Record<number, Status>;
  loadState: LoadState;
  statusesLoaded: boolean;
};

const REDUCER_NAME = 'statuses';
const DEFAULT_STATE: StatusesState = {
  statuses: {},
  loadState: LoadState.UNLOADED,
  statusesLoaded: false,
};

type StatusUpdatedAction = {
  type: typeof STATUS_UPDATED;
  response?: {
    status?: Status[];
    delete?: {
      status?: Status[];
    };
  };
  item?: {
    temporaryId: number;
    task_id: number;
  };
};

export type StatusesAction =
  | {
      type: typeof STATUSES_LOAD_START;
    }
  | {
      type: typeof STATUSES_LOAD_FAILED;
    }
  | StatusUpdatedAction
  | {
      type: typeof STATUS_DELETED;
      id: number;
    }
  | {
      type: typeof STATUSES_LOAD_FINISH;
      statuses?: Status[];
      rebuild?: boolean;
      shouldRefresh?: boolean;
    }
  | {
      type: typeof REHYDRATE;
      payload: {
        statuses: StatusesState;
      };
    };

const replace = (state: StatusesState, action: StatusUpdatedAction) => {
  let statusesToUpdate: Status[] = [];
  let statusesToDelete: Status[] = [];

  if ('response' in action && action.response) {
    statusesToUpdate = action.response.status ?? [];
    statusesToDelete = action.response.delete?.status ?? [];
  }

  const newStatuses = { ...state.statuses };

  statusesToDelete.forEach(({ status_id }) => {
    delete newStatuses[status_id];
  });

  statusesToUpdate.forEach((status: Status) => {
    if (status.status_id) {
      newStatuses[status.status_id] = formatStatus(status);
      if (
        'item' in action &&
        action.item &&
        action.item.temporaryId &&
        status.task_id
      ) {
        const statusToUpdate = newStatuses[status.task_id];

        if (statusToUpdate) {
          statusToUpdate.replacesTempId = action.item.temporaryId;
        }
      }
    }
  });

  if (!statusesToUpdate.length && !statusesToDelete.length) {
    return state;
  }

  return { ...state, statuses: newStatuses };
};

export const statusesReducer = (
  state: StatusesState = DEFAULT_STATE,
  action: StatusesAction,
): StatusesState => {
  switch (action.type) {
    case STATUSES_LOAD_START: {
      return {
        ...state,
        loadState: LoadState.LOADING,
      };
    }

    case STATUSES_LOAD_FAILED: {
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
      };
    }

    case STATUSES_LOAD_FINISH: {
      if (!action.statuses || !action.statuses.length) {
        return state;
      }
      const prevStatuses = action.shouldRefresh ? {} : state.statuses;
      const statuses = action.statuses.map(formatStatus);
      const statusesMap = keyBy(statuses, 'status_id');
      return {
        ...state,
        loadState: LoadState.LOADED,
        statusesLoaded: true,
        statuses: action.rebuild
          ? statusesMap
          : {
              ...prevStatuses,
              ...statusesMap,
            },
      };
    }

    case STATUS_UPDATED:
      return replace(state, action);

    case STATUS_DELETED: {
      return {
        ...state,
        statuses: omit(state.statuses, action.id),
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.statusesLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
};

export default statusesReducer;
