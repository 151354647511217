import React, { Fragment } from 'react';
import {
  AutoSizer,
  List,
  WindowScroller,
} from '@floatschedule/react-virtualized';
import cx from 'classnames';
import { isEmpty, isPlainObject, isUndefined, omit, without } from 'lodash';

import { media } from '@float/libs/media';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import Button from '@float/ui/deprecated/Button/Button';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';

import Checkbox from '../Checkbox/Checkbox';
import IconDown from '../Icons/iconChevronDownSmall';
import EmptyCell from './EmptyCell';
import HoverLink from './HoverLink';
import { HoverLinkIcon } from './HoverLinkIcon';
import Sort from './Sort';
import * as styled from './styles';
import { getGroupLabelForFunctions } from './Table.helpers';
import Tags from './Tags';
import Viewing from './Viewing';

const TableColumns = ({
  selectable,
  isSelectAll,
  onSelectAll,
  selectedCount,
  columns,
  sortBy,
  sortOrder,
  onSortByChange,
  onSortOrderChange,
  shadowVisible,
}) => {
  return (
    <styled.TableColumns className={`desktop ${selectable ? '' : 'no-select'}`}>
      <styled.TableColumnsShadow $visible={shadowVisible} />
      {columns.map((column, columnIndex) => {
        const isFirstColumn = columnIndex === 0;
        const headerProps = {
          className: column.key,
          style: {
            width: column.width,
            minWidth: column.width,
            height: 48,
            paddingLeft: selectable && isFirstColumn ? 17 : 6,
          },
        };

        if (!column.title) {
          return <styled.HeaderCell key={column.key} {...headerProps} />;
        }

        const isSortable = column.sortable !== false;
        let onClickHandler = null;
        if (isSortable) {
          const isSortedBy = sortBy === column.key;
          onClickHandler = () => {
            if (isSortedBy) {
              onSortOrderChange(sortOrder === 'desc' ? 'asc' : 'desc');
              return;
            }
            onSortByChange(column.key, 'asc');
          };
          Object.assign(headerProps, {
            isSortedBy: isSortedBy,
            isGroupKey: isSortedBy,
          });
        } else {
          Object.assign(headerProps, {
            cursorNotAllowed: true,
          });
        }

        return (
          <styled.HeaderCell key={column.key} {...headerProps}>
            {selectable && isFirstColumn ? (
              <Checkbox
                className={cx('desktop', { selected: selectedCount > 0 })}
                semiChecked={!isSelectAll && selectedCount > 0}
                value={isSelectAll}
                onChange={onSelectAll}
                style={{
                  paddingRight: 17,
                }}
              />
            ) : null}
            <styled.HeaderCellLabelWrapper
              onClick={onClickHandler}
              cursorNotAllowed={headerProps.cursorNotAllowed}
            >
              {column.callout && <div data-callout-id={column.callout} />}
              {column.title}
              {headerProps.isSortedBy && (
                <div>
                  <IconDown
                    style={{
                      position: 'relative',
                      top: 2,
                      transform: sortOrder === 'desc' ? 'rotate(180deg)' : '',
                    }}
                  />
                </div>
              )}
            </styled.HeaderCellLabelWrapper>
          </styled.HeaderCell>
        );
      })}
    </styled.TableColumns>
  );
};

const TableActions = ({
  selected,
  selectedCount,
  onSelectAll,
  renderActions,
  getMultiSelectActions,
}) => {
  const selectedIds = selectedCount ? Object.keys(selected) : [];
  const shouldShowUnselectedActions =
    !selectedCount || window.innerWidth <= 740;

  return (
    <styled.TableActions>
      {!shouldShowUnselectedActions && (
        <styled.TableCountLabel>{`${selectedCount} selected`}</styled.TableCountLabel>
      )}
      {typeof renderActions === 'function' && (
        <styled.TableActionsInner
          className="unselected-actions"
          style={!shouldShowUnselectedActions ? { display: 'none' } : undefined}
        >
          {renderActions()}
        </styled.TableActionsInner>
      )}
      {!shouldShowUnselectedActions &&
        typeof getMultiSelectActions === 'function' && (
          <styled.TableActionsInner className="selected-actions">
            {getMultiSelectActions(selectedIds).map((o) => (
              <Fragment key={o.label}>
                <Button
                  size="small"
                  icon={o.icon}
                  onClick={(e) => {
                    prevent(e);
                    o.action(selectedIds);
                  }}
                >
                  {o.label}
                </Button>
                <Spacer size={4} />
              </Fragment>
            ))}
            <Button size="small" appearance="clear" onClick={onSelectAll}>
              Clear
            </Button>
          </styled.TableActionsInner>
        )}
    </styled.TableActions>
  );
};

export class Table extends React.Component {
  static defaultProps = {
    className: '',
    selectable: true,
    rowGutter: 4,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      selectedGroups: {},
      selectedAllGroups: {},
      rows: this.getRows(),
      shadowVisible: false,
    };
  }

  componentDidMount() {
    media.addWindowResizeListener(this.recomputeRowHeights);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      this.setState({ rows: this.getRows() });
    }
  }

  componentWillUnmount() {
    media.removeWindowResizeListener(this.recomputeRowHeights);
  }

  isSelected = (id) => {
    return !!this.state.selected[id];
  };

  isGroupSelected = (group) => {
    return !!this.state.selectedGroups[group];
  };

  isGroupSelectedAll = (group) => {
    return !!this.state.selectedAllGroups[group];
  };

  isSelectedAll = () => {
    const selectedCount = Object.keys(this.state.selected).length;
    const filteredRowsCount = this.state.rows.filter(
      (r) => !r.preventSelect,
    ).length;
    const selectedGroupsCount = Object.keys(this.state.selectedGroups).length;
    return selectedCount === filteredRowsCount - selectedGroupsCount;
  };

  addToGroup = (id, group) => {
    const selectedGroups = { ...this.state.selectedGroups };
    const selectedGroup = selectedGroups[group];
    if (selectedGroup) {
      selectedGroups[group] = [...selectedGroup, id];
    } else {
      selectedGroups[group] = [id];
    }
    return selectedGroups;
  };

  removeFromGroup = (id, group) => {
    const selectedGroup = this.state.selectedGroups[group];

    if (selectedGroup) {
      const selectedGroups = omit(this.state.selectedGroups, group);
      selectedGroups[group] = without(selectedGroup, id);
      if (!selectedGroups[group].length) {
        delete selectedGroups[group];
      }
      return selectedGroups;
    }

    return this.state.selectedGroups;
  };

  toggleSelection = (id, group) => () => {
    const newState = {};
    if (this.isSelected(id)) {
      newState.selected = omit(this.state.selected, id);
      // newState.selectedGroups = this.removeFromGroup(id, group);
    } else {
      newState.selected = { ...this.state.selected, [id]: true };
      // newState.selectedGroups = this.addToGroup(id, group);
    }

    newState.selectedAllGroups = omit(this.state.selectedAllGroups, group);
    this.setState(newState);
  };

  toggleGroupSelection = (key, value) => () => {
    const selectedGroup = this.state.selectedGroups[value];
    const selectedGroups = omit(this.state.selectedGroups, value);
    const selectedAllGroups = omit(this.state.selectedAllGroups, value);
    const selected = { ...this.state.selected };

    if (selectedGroup) {
      // de-select all
      selectedGroup.forEach((id) => {
        delete selected[id];
      });
    } else {
      // select all
      selectedGroups[value] = [];
      this.state.rows.forEach((r) => {
        let rowValue = r[key];
        if (isPlainObject(rowValue)) {
          rowValue = rowValue.value;
        }
        if (rowValue != value || !r.canEdit) {
          return;
        }

        selectedAllGroups[value] = true;
        selectedGroups[value].push(r.key);
        selected[r.key] = true;
      });
      if (!selectedGroups[value].length) {
        delete selectedGroups[value];
      }
    }

    this.setState({ selected, selectedGroups, selectedAllGroups });
  };

  toggleSelectAll = () => {
    const newState = {
      selected: {},
      selectedGroups: {},
      selectedAllGroups: {},
    };
    const isAnySelected = Object.keys(this.state.selected).length > 0;
    if (isAnySelected) {
      this.setState(newState);
      return;
    }

    const { rows } = this.state;
    const groups = rows.filter((row) => row.groupBy);
    if (groups.length) {
      groups.forEach((group) => {
        newState.selectedAllGroups[group.groupValue] = true;
        newState.selectedGroups[group.groupValue] = [];
        rows
          .filter((r) => {
            let item = r[group.groupBy];
            if (isPlainObject(item)) {
              item = item.value;
            }
            return item == group.groupValue && r.canEdit && !r.preventSelect;
          })
          .forEach((r) => {
            newState.selectedGroups[group.groupValue].push(r.key);
            newState.selected[r.key] = true;
          });
        if (!newState.selectedGroups[group.groupValue].length) {
          delete newState.selectedGroups[group.groupValue];
          delete newState.selectedAllGroups[group.groupValue];
        }
      });
    } else {
      rows.forEach((r) => {
        if (r.canEdit && !r.preventSelect) {
          newState.selected[r.key] = true;
        }
      });
    }
    this.setState(newState);
  };

  clearAll = () => {
    if (!isEmpty(this.state.selected)) {
      this.toggleSelectAll();
    }
  };

  changeSortBy = (type, dir) => {
    const { onSortByChange } = this.props;
    if (typeof onSortByChange === 'function') {
      this.clearAll();
      onSortByChange(type, dir);
    }
  };

  changeSortOrder = (dir) => {
    const { onSortOrderChange } = this.props;
    if (typeof onSortOrderChange === 'function') {
      this.clearAll();
      onSortOrderChange(dir);
    }
  };

  getRowGutterSize = (row) => {
    const { isParentRow, isLastExpandedRow, isChildRow } = row.metadata || {};
    const isFromRowGroup = isParentRow || isChildRow;
    const shouldAddGutter = !isFromRowGroup || isLastExpandedRow;
    return shouldAddGutter ? this.props.rowGutter : 0;
  };

  getRowHeight = ({ index }) => {
    const row = this.state.rows[index];
    const styleHeight = row?.height || row?.style?.height;
    if (styleHeight) {
      return styleHeight;
    }

    if (media.isSmallBreakpointActive) {
      const isGroup = row?.groupBy;
      return (isGroup ? 25 : 90) + this.props.rowGutter; // mobile view
    }

    const height = 48 + this.getRowGutterSize(row);
    if (row.groupBy) {
      // row group are slightly bigger than normal rows
      const groupedRowHeight = height + 4;
      if (index === 0) {
        // avoid unnecessary spacing with table headers
        return groupedRowHeight - 17;
      }

      return groupedRowHeight;
    }

    return height;
  };

  recomputeRowHeights = () => {
    if (this.table) {
      this.table.recomputeRowHeights();
    }
  };

  getOverscanIndices = ({
    cellCount, // Number of rows or columns in the current axis
    overscanCellsCount, // Maximum number of cells to over-render in either direction
    startIndex, // Begin of range of visible cells
    stopIndex, // End of range of visible cells
  }) => ({
    overscanStartIndex: Math.max(0, startIndex - overscanCellsCount - 2), // the " - 2" here prevents rows scrolling out of viewport unmounting too fast
    overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
  });

  getRows = () => {
    const { rows = [], sortBy, nonGroupKeys } = this.props;
    const groupBy = (nonGroupKeys || []).includes(sortBy) ? null : sortBy;

    let previousGroup = null;
    let currentGroup = null;
    const rowByEntity = new Map();
    const tableRows = [];
    rows.forEach((item) => {
      const tableRow = this.props.rowRenderer(item);
      rowByEntity.set(tableRow.entity, tableRow);

      if (groupBy) {
        currentGroup = tableRow[groupBy];
        if (typeof currentGroup === 'function') {
          currentGroup = getGroupLabelForFunctions(groupBy, item);
        } else if (isPlainObject(currentGroup)) {
          currentGroup = currentGroup.value;
        }

        if (!isUndefined(currentGroup) && currentGroup !== previousGroup) {
          previousGroup = currentGroup;
          tableRows.push({
            key: `group-${previousGroup}`,
            groupBy,
            groupValue: previousGroup,
          });
        }
      }

      const prevRow = tableRows[tableRows.length - 1];

      const parentRow = tableRow.parent && rowByEntity.get(tableRow.parent);
      if (parentRow) {
        tableRow.metadata = tableRow.metadata || {};
        prevRow.metadata = prevRow.metadata || {};
        parentRow.metadata = parentRow.metadata || {};

        // Assign each row with needed metadata for rendering purposes.
        // We need to know whether it's the fisrt/last or a middle row when parent row is expanded.
        parentRow.metadata.isParentRow = true;
        prevRow.metadata.isLastExpandedRow = false;
        tableRow.metadata.isLastExpandedRow = true;
        tableRow.metadata.isChildRow = true;
      }

      // Parent row and children rows need to share same background color and row indicator color.
      // Every other row needs to alternate their background color between odd and even rows.
      tableRow.isOddRow = parentRow ? parentRow.isOddRow : !prevRow?.isOddRow;
      tableRow.color = tableRow.color || parentRow?.color;
      tableRows.push(tableRow);
    });

    this.recomputeRowHeights();
    return tableRows;
  };

  getBorderRadius = (row) => {
    const { isParentRow, isLastExpandedRow, isChildRow } = row.metadata || {};
    const isFirstInGroupOrMiddleRow =
      isParentRow || (isChildRow && !isLastExpandedRow);
    const isLastInGroupOrMiddleRow = isLastExpandedRow || isChildRow;

    return {
      borderBottomLeftRadius: isFirstInGroupOrMiddleRow ? 0 : 4,
      borderBottomRightRadius: isFirstInGroupOrMiddleRow ? 0 : 4,
      borderTopLeftRadius: isLastInGroupOrMiddleRow ? 0 : 4,
      borderTopRightRadius: isLastInGroupOrMiddleRow ? 0 : 4,
    };
  };

  renderRowColorIndicator = (row) => {
    const rowColor = !row.render && row.color;
    if (!rowColor) {
      return null;
    }

    return (
      <styled.RowColorIndicator
        color={rowColor}
        style={this.getBorderRadius(row)}
      />
    );
  };

  renderRowCheckbox = (checkboxProps) => {
    return (
      <Checkbox
        {...checkboxProps}
        style={{
          // Cell left padding is set to 6px ( to ensure that tags text is verticaly aligned with table header)
          // We want to compensate left padding to make it 17 to match design specs.
          paddingLeft: 11,
          paddingRight: 20,
        }}
      />
    );
  };

  rowRenderer = ({ index, style: rowStyle }) => {
    const row = this.state.rows[index];
    const { sortBy } = this.props;
    let selectable = this.props.selectable;
    if (!isUndefined(row.selectable)) {
      selectable = row.selectable;
    }

    if (row.groupBy) {
      const isGroupSelected = this.isGroupSelected(row.groupValue);
      const groupLabel = (
        <styled.GroupByTitle>{row.groupValue}</styled.GroupByTitle>
      );
      return (
        <styled.Group
          key={row.key}
          style={{
            ...rowStyle,
            paddingTop: index === 0 ? 0 : 17,
            paddingBottom: this.getRowGutterSize(row),
          }}
        >
          {selectable ? (
            <styled.Cell>
              {this.renderRowCheckbox({
                label: groupLabel,
                value: isGroupSelected,
                semiChecked:
                  isGroupSelected && !this.isGroupSelectedAll(row.groupValue),
                onChange: this.toggleGroupSelection(
                  row.groupBy,
                  row.groupValue,
                ),
              })}
            </styled.Cell>
          ) : (
            <styled.Cell>{groupLabel}</styled.Cell>
          )}
        </styled.Group>
      );
    }

    return (
      <styled.Row
        key={row.key}
        className={cx({
          [row.className]: row.className,
        })}
        style={{
          paddingBottom: this.getRowGutterSize(row),
          ...rowStyle,
        }}
      >
        {this.renderRowColorIndicator(row)}
        <styled.RowInner
          onClick={row.onClick}
          className={cx({
            odd: row.isOddRow,
          })}
          style={{
            ...(row.style || {}),
            ...(selectable || row.selectablePadding ? {} : { paddingLeft: 10 }),
            ...this.getBorderRadius(row),
          }}
        >
          {row.render ? (
            row.render(row)
          ) : (
            <>
              {this.props.columns.map((column, columnIdx) => {
                let cell = row[column.key];
                if (isPlainObject(cell)) {
                  cell = cell.label;
                }
                return (
                  <styled.Cell
                    key={`${row.key}-${column.key}`}
                    className={column.key}
                    style={{
                      width: column.width,
                      minWidth: column.width,
                    }}
                  >
                    {columnIdx === 0 &&
                      selectable &&
                      this.renderRowCheckbox({
                        readOnly: !row.canEdit,
                        value: this.isSelected(row.key),
                        onChange: this.toggleSelection(row.key, row[sortBy]),
                        onClick: stopPropagation,
                      })}
                    {typeof cell === 'function' ? cell(row) : cell}
                  </styled.Cell>
                );
              })}
            </>
          )}
        </styled.RowInner>
      </styled.Row>
    );
  };

  handleScroll = ({ scrollTop }) => {
    const shadowVisible = scrollTop > 0;

    if (this.state.shadowVisible !== shadowVisible) {
      this.setState({ shadowVisible });
    }
  };

  setScrollRef = (el) => {
    // https://linear.app/float-com/issue/PI-450/bug-hidden-elements-when-scrolling-in-people-page
    // Bug: When navigating directly to the Manage page, the table sometimes fails to display additional rows.
    // Cause: The scrollRef was not set correctly.
    // Fix: Forcing a re-render after setting scrollRef resolves the issue.
    if (el) {
      setTimeout(() => {
        this.setState({ selected: this.state.selected });
      }, 0);
    }
    this.scrollRef = el;
  };

  render() {
    const { selected, rows, shadowVisible } = this.state;
    const rowsExist = !!(rows && rows.length);

    const {
      className,
      columns,
      sortBy,
      sortOrder,
      selectable,
      renderActions,
      getMultiSelectActions,
      printMode,
    } = this.props;

    const selectedCount = Object.keys(selected).length;

    return (
      <styled.Table className={className}>
        <styled.TableHeader>
          <TableActions
            selectable={selectable && rowsExist}
            selected={selected}
            selectedCount={selectedCount}
            isSelectAll={this.isSelectedAll()}
            onSelectAll={this.toggleSelectAll}
            renderActions={renderActions}
            getMultiSelectActions={getMultiSelectActions}
          />
          {rowsExist && (
            <TableColumns
              selectable={selectable}
              isSelectAll={this.isSelectedAll()}
              onSelectAll={this.toggleSelectAll}
              selectedCount={selectedCount}
              columns={columns}
              sortBy={sortBy}
              sortOrder={sortOrder}
              onSortByChange={this.changeSortBy}
              onSortOrderChange={this.changeSortOrder}
              shadowVisible={shadowVisible}
            />
          )}
        </styled.TableHeader>
        <styled.TableBodyWrapper ref={this.setScrollRef}>
          <WindowScroller
            onScroll={this.handleScroll}
            scrollElement={this.scrollRef}
          >
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <styled.TableBody>
                <AutoSizer disableHeight>
                  {({ width }) => {
                    return (
                      <List
                        ref={(el) => {
                          this.table = el;
                        }}
                        style={{ outline: 'none' }}
                        overscanRowCount={2}
                        overscanIndicesGetter={this.getOverscanIndices}
                        rowCount={rows.length}
                        rowHeight={this.getRowHeight}
                        rowRenderer={this.rowRenderer}
                        width={width}
                        autoHeight
                        height={
                          printMode
                            ? this.getRowHeight({ index: 0 }) * rows.length
                            : height || 0
                        }
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                      />
                    );
                  }}
                </AutoSizer>
              </styled.TableBody>
            )}
          </WindowScroller>
        </styled.TableBodyWrapper>
      </styled.Table>
    );
  }
}

Table._styles = {
  Cell: styled.Cell,
  Row: styled.Row,
  HeaderCell: styled.HeaderCell,
  HoverLink: styled.HoverLink,
  Tags: styled.Tags,
  Text: styled.Text,
};
Table.Tags = Tags;
Table.Viewing = Viewing;
Table.Sort = Sort;
Table.HoverLinks = styled.HoverLinks;
Table.HoverLinkIcon = HoverLinkIcon;
Table.HoverLink = HoverLink;
Table.EmptyCell = EmptyCell;
Table.Text = styled.Text;
Table.ActionsGroup = styled.ActionsGroup;

export default Table;
