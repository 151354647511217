import { isNil } from 'es-toolkit';

export function isAmountEmpty(
  value: string | number | null | undefined,
  allowZero = true,
) {
  const isZero = value === '0' || value === 0;

  if (isNil(value)) return true;

  if (isZero) return !allowZero;

  if (typeof value === 'string' && value.trim() === '') return true;

  return !value;
}
