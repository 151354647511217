import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectPhasesByProjectIdAndPersonId } from '@float/common/selectors/phases/selectPhasesByProjectIdAndPersonId';

type UseDoesPersonHaveDifferentBillRateOnPhasesParams = {
  personId: number;
  projectId: number;
  personBillRateOnProject?: string | null;
};

export function useDoesPersonHaveDifferentBillRateOnPhases(
  params: UseDoesPersonHaveDifferentBillRateOnPhasesParams,
) {
  const { personId, projectId, personBillRateOnProject } = params;

  const phases = useSelector((state) =>
    selectPhasesByProjectIdAndPersonId(state, projectId, personId),
  );

  return useMemo(
    () =>
      phases.some((phase) => {
        const personPhaseRate = phase.people_rates?.[personId];
        return (
          Boolean(personPhaseRate) &&
          personPhaseRate !== personBillRateOnProject
        );
      }),
    [personBillRateOnProject, personId, phases],
  );
}
