import { getEmptyTaskLabel } from 'reports/TeamCapacity/parser/table/getEmptyTaskLabel';

import { toCents } from '@float/common/lib/budget';
import type { RawTableData } from '@float/web/reports/useReportsStateReducer';

import type { ParseTableContext } from '../../table.types';

type TotalsByPeopleItem = {
  scheduled: number;
  feeCents: number;
  costCents: number;
  logged: {
    scheduled: number;
    feeCents: number;
    costCents: number;
  };
  future: {
    scheduled: number;
    feeCents: number;
    costCents: number;
  };
};

export type TotalsByPeople = TotalsByPeopleItem & {
  children: Record<string, TotalsByPeopleItem>;
};

function getBlankData(): TotalsByPeople {
  return {
    scheduled: 0,
    feeCents: 0,
    costCents: 0,
    logged: { scheduled: 0, feeCents: 0, costCents: 0 },
    future: { scheduled: 0, feeCents: 0, costCents: 0 },
    children: {},
  };
}

export function getTotalsByPerson(ctx: ParseTableContext, raw: RawTableData) {
  const { people, project, user } = ctx;

  const peopleIds = project.people_ids || [];
  const byPerson = Object.fromEntries(
    peopleIds
      .filter((pid) => {
        const person = people[pid];
        const deptIds = user.department_filter;
        return (
          person?.department_id &&
          !!person.active &&
          (!deptIds?.length || deptIds.includes(person.department_id))
        );
      })
      .map((pid) => [pid, getBlankData()]),
  );

  raw.totals.forEach((item) => {
    if (item.type !== 'task' && item.type !== 'logged_time') return;

    if (!people[item.person_id]) {
      console.error('No person found', item);
      return;
    }

    if (!item.name) {
      item.name = getEmptyTaskLabel();
    }

    if (!byPerson[item.person_id]) {
      byPerson[item.person_id] = getBlankData();
    }

    const record = byPerson[item.person_id];

    if (!record.children[item.name]) {
      record.children[item.name] = getBlankData();
    }

    const child = record.children[item.name];

    if (item.type === 'task' || item.type === 'logged_time') {
      if (
        item.type === 'logged_time' &&
        item.date &&
        item.date >= ctx.loggedTimeBoundary
      ) {
        return;
      }

      const childRoot = item.type === 'task' ? child : child.logged;
      const recordRoot = item.type === 'task' ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;
      record.future.scheduled += item.hours.future;

      recordRoot.feeCents += toCents(item.rate) * item.hours.scheduled;
      recordRoot.costCents += toCents(item.cost?.scheduled);
      childRoot.feeCents += toCents(item.rate) * item.hours.scheduled;
      childRoot.costCents += toCents(item.cost?.scheduled);

      const rateCents = toCents(item.rate);
      record.future.feeCents += rateCents * item.hours.future;
      record.future.costCents += toCents(item.cost?.future);

      if (item.type === 'task') {
        child.future.scheduled += item.hours.future;
        child.future.feeCents += rateCents * item.hours.future;
        child.future.costCents += toCents(item.cost?.future);
      }
    }
  });

  return byPerson;
}
