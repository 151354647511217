// Actions -------------------------------------------------------------------------------------
import { updateUserPref } from '@float/common/actions/currentUser';
import request from '@float/common/lib/request';

export const APP_LOADED = 'app/APP_LOADED';
export const UPDATE_START = 'app/UPDATE_START';
export const UPDATE_END = 'app/UPDATE_END';
export const UPDATE_ACTIVE_TAB_ROUTE = 'app/UPDATE_ACTIVE_TAB_ROUTE';
export const UPDATE_DATES = 'app/UPDATE_DATES';
export const RECEIVE_PROPS = 'app/RECEIVE_PROPS';
export const SET_PRINT_MODE = 'app/SET_PRINT_MODE';
export const SET_PAGE_TITLE = 'app/SET_PAGE_TITLE';
export const TRIGGER_ACTION = 'app/TRIGGER_ACTION';
export const SET_VIEW_TYPE = 'app/SET_VIEW_TYPE';
export const SET_HELP_MENU_VISIBILITY = 'app/SET_HELP_MENU_VISIBILITY';

// Action Creators ------------------------------------------------------------------------------
export const appLoaded = () => ({
  type: APP_LOADED,
});

export const updateStart = (start) => ({
  type: UPDATE_START,
  start,
});

export const updateEnd = (end) => ({
  type: UPDATE_END,
  end,
});

export const triggerAction = (actionType) => ({
  type: TRIGGER_ACTION,
  actionType,
});

export const updateDates = (start, end) => ({
  type: UPDATE_DATES,
  start,
  end,
});

export const unmountSettingsV2 = () => ({
  type: 'app/UNMOUNT_SETTINGS_v2',
});

export const changeZoom = (zoom) => {
  return updateUserPref('sked_zoom', zoom);
};

export const changeMode = (mode) => {
  return updateUserPref('sked_view', mode);
};

export const setPageTitle = (title) => ({
  type: SET_PAGE_TITLE,
  title,
});

export const ping = () => (dispatch, getState) => {
  if (getState()?.currentUser?.shared_link_view) {
    return null;
  }
  return request.get('svc/nf/ping', null, {
    version: '',
    hostname: '',
    jwt: true,
  });
};

export const setViewType = (viewType) => ({
  type: SET_VIEW_TYPE,
  viewType,
});

export const setHelpMenuVisible = (visible) => ({
  type: SET_HELP_MENU_VISIBILITY,
  visible,
});
