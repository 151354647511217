import { keyBy, omit, omitBy } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { Milestone } from '@float/types';

import * as actions from '../actions';
import { MilestoneAction } from '../actions/milestones';
import { LoadState, RehydratePartialStateAction } from './lib/types';
import { ProjectAction } from './projects';

export type MilestonesState = {
  milestones: Record<number, Milestone>;
  loadState: LoadState;
  milestonesLoaded: boolean;
};

const REDUCER_NAME = 'milestones';
const DEFAULT_STATE: MilestonesState = {
  milestones: {},
  loadState: LoadState.UNLOADED,
  milestonesLoaded: false,
};

export const milestonesReducer = (
  state = DEFAULT_STATE,
  action:
    | MilestoneAction
    | ProjectAction
    | RehydratePartialStateAction<MilestonesState, typeof REDUCER_NAME>,
): MilestonesState => {
  switch (action.type) {
    case actions.MILESTONES_LOAD_START:
      return { ...state, loadState: LoadState.LOADING };

    case actions.MILESTONES_LOAD_FINISH:
      return {
        ...state,
        loadState: LoadState.LOADED,
        milestonesLoaded: true,
        milestones: keyBy(action.milestones, 'milestone_id'),
      };

    case actions.PROJECT_MILESTONES_LOAD_FINISH:
      return {
        ...state,
        milestones: {
          ...state.milestones,
          ...keyBy(action.milestones, 'milestone_id'),
        },
      };

    case actions.MILESTONES_LOAD_FAILED:
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
        milestonesLoaded: false,
        milestones: DEFAULT_STATE.milestones,
      };

    case actions.MILESTONES_CREATE_FINISH:
    case actions.MILESTONES_UPDATE_START:
    case actions.MILESTONES_UPDATE_FAILED:
    case actions.MILESTONES_REMOVE_FAILED: {
      const { milestone } = action;
      // @ts-expect-error The API payload is used to update the state optimistically, but it shouldn't be used this way
      const id: number = milestone.id || milestone.milestone_id;
      if (!id) {
        return state;
      }
      const newMilestones = { ...state.milestones };
      const previousMilestone = newMilestones[id] || {};

      // @ts-expect-error The API payload is used to update the state optimistically, but it shouldn't be used this way
      newMilestones[id] = {
        ...previousMilestone,
        ...milestone,
        milestone_id: id,
      };

      // Since we're merging the milestone, we want to make sure that we
      // remove any Serena properties so that they can be regenerated correctly.
      // @ts-expect-error This attribute is required in the cells logic. Maybe it is filled somewhere else
      delete newMilestones[id].start_date;
      // @ts-expect-error keeping this for backward compatibility
      delete newMilestones[id].allInstances;
      // @ts-expect-error keeping this for backward compatibility
      delete newMilestones[id]._allInstancesMemoKey;

      if ('temporaryId' in action) {
        newMilestones[id].replacesTempId = action.temporaryId;
      }

      return { ...state, milestones: newMilestones };
    }

    case actions.MILESTONES_REMOVE_START: {
      const { milestoneId: id } = action;
      if (!id) {
        return state;
      }
      const newMilestones = { ...state.milestones };
      delete newMilestones[id];
      return { ...state, milestones: newMilestones };
    }

    case actions.PROJECTS_DELETED: {
      const newMilestones = omitBy(
        state.milestones,
        (m) => m.project_id == action.projectId,
      );
      return { ...state, milestones: newMilestones };
    }

    case actions.PROJECTS_BULK_DELETED: {
      const { ids: projectIds } = action;

      if (!projectIds) return state;

      const newMilestones = omitBy(state.milestones, (m) =>
        projectIds.find((id) => id == m.project_id),
      );
      return { ...state, milestones: newMilestones };
    }

    case actions.MILESTONES_BULK_UPDATE: {
      const milestones = action.result.map((x) => ({
        ...omit(x, ['milestone_name']),
        name: x.milestone_name || x.name,
        id: x.milestone_id,
      }));
      const newMilestones = keyBy(milestones, 'milestone_id');

      return {
        ...state,
        milestones: {
          ...state.milestones,
          ...newMilestones,
        },
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.milestonesLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
};

export default milestonesReducer;
