import React, { useEffect } from 'react';

import Avatar from '@float/common/components/Avatar/Avatar';
import { getUserAccess } from '@float/common/lib/rights';
import { CurrentUser, Person } from '@float/types';
import { ReadOnlyField } from '@float/ui/deprecated/Field/ReadOnlyField';
import { Flex, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

type SelectUserLogTimeProps = {
  user: CurrentUser;
  person: Person | CurrentUser | undefined;
  isReadOnly: boolean;
  peopleList: Person[];
  setPerson: (person: Person | CurrentUser | undefined) => void;
};

const SelectUserLogTime = (props: SelectUserLogTimeProps) => {
  const { user, person, isReadOnly } = props;
  const perms = getUserAccess(user);
  const isViewSelfOnly = perms.isMember();

  useEffect(() => {
    const hasChanged = person?.people_id !== user.people_id;
    if (!isReadOnly && isViewSelfOnly && hasChanged) {
      props.setPerson(user);
    }
  }, [isViewSelfOnly]); // eslint-disable-line

  if (isViewSelfOnly || isReadOnly) {
    return (
      <ReadOnlyField
        type="scrollableText"
        label="Assigned to"
        wrapperStyle={{ marginBottom: 22 }}
        value={
          <Flex>
            <Avatar
              name={person?.name}
              imageUrl={person?.avatar_file}
              readOnly
              size="xs"
            />
            <Spacer size={4} />
            {person?.name}
          </Flex>
        }
      />
    );
  }

  const assignedToOptions = props.peopleList
    .sort((a: Person, b: Person) => a.name.localeCompare(b.name))
    .map((person: Person) => ({
      label: person.name,
      value: person.people_id,
      icon: (
        <Avatar
          name={person.name}
          imageUrl={person.avatar_file}
          readOnly
          size="xs"
        />
      ),
    }));

  return (
    <VirtualSelect
      // @ts-expect-error VirtualSelect does not have types
      nonNullable
      searchable={false}
      visibleItems={6}
      label="Assigned to"
      style={{ marginBottom: 22 }}
      clearInputOnDropdownOpen={true}
      value={props.person?.people_id}
      options={assignedToOptions}
      onChange={(person: { label: string; value: number } & Person) => {
        const peopleId = person.value;

        const selectedPerson = props.peopleList.find(
          (person: Person) => person.people_id == peopleId,
        );

        props.setPerson(selectedPerson);
      }}
    />
  );
};

export { SelectUserLogTime };
