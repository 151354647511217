import styled, { css } from 'styled-components';

import { MediaSize } from '@float/constants/media';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

export const StyledNavigationSeparator = styled.span`
  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Low};

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 24px;

  ${respondTo(
    MediaSize.Small,
    '<=',
  )(css`
    margin: 0 4px;

    > svg {
      width: 18px;
      height: 18px;
    }
  `)};
`;
