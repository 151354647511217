import {
  hexToRgb,
  hexToRgbParts,
} from '@float/common/lib/colors/utils/oldColorUtils';
import {
  isFullDayTimeoff,
  isRejectedTimeoff,
  isTentativeTimeoff,
} from '@float/common/lib/timeoffs';
import { DEFAULT_COLOR } from '@float/constants/colors';
import { fakeOpacity } from '@float/libs/colors/utils/fakeOpacity';
import { getDiagonalStripeGradient } from '@float/libs/styles/getDiagonalStripeGradient';
import { TimeoffType } from '@float/types/timeoffType';
import EH from '@float/ui/deprecated/Earhart';
import Theme from '@float/ui/deprecated/Theme';

import { CellStylesConfig } from '../hooks/useCellStyles';

export function getTimeoffBackgroundAndColor(
  item: {
    isInLockPeriod?: boolean;
    entity?: {
      isSplitting?: boolean;
      isInLockPeriod?: boolean;
      temporaryId?: number;
    };
  },
  timeOffType: TimeoffType | null,
) {
  const { entity: timeoff } = item;

  if (!timeOffType) {
    return null;
  }

  if (isTentativeTimeoff(timeoff) || isRejectedTimeoff(timeoff)) {
    return {
      backgroundColor: 'transparent',
      color: `#${timeOffType.color || DEFAULT_COLOR}`,
    };
  }

  const parts = hexToRgbParts(`#${timeOffType.color || DEFAULT_COLOR}`);
  let [r, g, b] = parts.map((p) => parseInt(p, 16));

  [r, g, b] = fakeOpacity(r, g, b, 0.25);
  let textColor = 'rgba(0, 0, 0, 0.5)';

  if (item.isInLockPeriod) {
    [r, g, b] = fakeOpacity(r, g, b, 0.4);
    textColor = EH.Colors.FIN.Lt.Emphasis.Low;
  }

  return {
    backgroundImage: getDiagonalStripeGradient(
      `rgba(${r}, ${g}, ${b}, 1)`,
      -45,
    ),
    color: textColor,
  };
}

export function getTimeOffStyles(
  colors: unknown,
  item: {
    y?: number | undefined;
    isInLockPeriod?: boolean | undefined;
    entity?: {
      isSplitting?: boolean;
      isInLockPeriod?: boolean;
      temporaryId?: number;
    };
  },
  config?: CellStylesConfig,
) {
  const {
    isPrintMode = false,
    isSelected = false,
    timeOffType = null,
  } = config ?? {};

  if (isPrintMode) {
    const color = `#${timeOffType?.color}`;
    const rgb = `rgba(${hexToRgb(color, 0.3)})`;

    return {
      background: 'white',
      color: Theme.blueGrey,
      border: `1px solid ${rgb}`,
    };
  }

  let borderTop;
  let borderBottom;
  let borderSides;

  const isTentative = isTentativeTimeoff(item.entity);
  const isRejected = isRejectedTimeoff(item.entity);
  const borderRadius = isTentative || isRejected ? 3 : 0;

  const timeoffBackgroundAndColor = getTimeoffBackgroundAndColor(
    item,
    timeOffType,
  );

  if (isSelected) {
    borderTop = `1px solid ${Theme.charcoalGrey}`;
    borderBottom = borderTop;
    borderSides = borderTop;
  } else if (isTentative || isRejected) {
    const color = timeoffBackgroundAndColor
      ? timeoffBackgroundAndColor.color
      : '#cccccc';

    const border = `1px dashed ${color}`;
    borderTop = border;
    borderBottom = border;
    borderSides = border;
  } else if (isFullDayTimeoff(item.entity)) {
    borderTop = '1px solid transparent';
    borderBottom = borderTop;
    borderSides = borderTop;
  } else if (item.y === 0) {
    borderTop = '1px solid transparent';
    borderBottom = '1px solid #cccccc';
    borderSides = borderTop;
  } else {
    borderTop = '1px solid #cccccc';
    borderBottom = borderTop;
    borderSides = '1px solid transparent';
  }

  let opacity = 1;
  if (item?.entity?.isSplitting || item?.entity?.temporaryId) {
    opacity = 0.75;
  }

  return {
    backgroundSize: '5px 5px',
    borderTop,
    borderBottom,
    borderRight: borderSides,
    borderLeft: borderSides,
    borderRadius,
    ...timeoffBackgroundAndColor,
    opacity,
  };
}
