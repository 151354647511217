import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { ensureProjectLoaded } from '@float/common/actions';
import {
  useAppDispatchStrict,
  useAppSelectorStrict,
} from '@float/common/store';
import { getProjectsMap } from '@float/web/selectors';

function useProjectTeamRates(projectId: number) {
  const project = useAppSelectorStrict(getProjectsMap)[projectId];
  const dispatch = useAppDispatchStrict();

  useEffect(() => {
    if (!isEmpty(project.people_rates)) return;

    dispatch(ensureProjectLoaded(projectId));
  }, [projectId, project.people_rates, dispatch]);

  return project.people_rates;
}

type UsePersonBillRateOnProjectParams = {
  personId: number;
  projectId: number;
};

export function usePersonBillRateOnProject(
  params: UsePersonBillRateOnProjectParams,
) {
  const { personId, projectId } = params;

  const projectTeamRates = useProjectTeamRates(projectId);

  const personBillRate = projectTeamRates?.[personId] ?? null;

  return personBillRate;
}
