import { t } from '@lingui/macro';
import { isNil } from 'es-toolkit';

import { CurrencyProps, RoleOption } from '@float/types';

import { formatRateWithCurrencySymbol } from '../lib/rates/rates';

export function getSingleRateFromRoleOption(option?: RoleOption | null) {
  if (!option) return undefined;

  if (option.costRate === undefined && option.billRate !== undefined) {
    return option.billRate;
  }

  if (option.costRate !== undefined && option.billRate === undefined) {
    return option.costRate;
  }

  return undefined;
}

export function getRateSuffixForRoleOption(
  roleOption: RoleOption | null,
  currencyConfig: CurrencyProps,
) {
  // Rate suffix is shown in the VirtualSelect only in
  // the scenario where user can access a single rate.
  const singleRate = getSingleRateFromRoleOption(roleOption);

  if (isNil(singleRate)) return null;

  const formattedRate = formatRateWithCurrencySymbol(
    singleRate,
    currencyConfig,
  );

  if (!formattedRate) return null;

  return t`${formattedRate} /hr`;
}
