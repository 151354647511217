import { groupBy } from 'lodash';
import { createSelector } from 'reselect';

import { AccountType } from '@float/constants/accounts';
import { ProjectStatus } from '@float/constants/projects';
import type { PhasesState } from '@float/common/reducers/phases';
import type { ProjectsState } from '@float/common/reducers/projects';
import type { CurrentUser } from '@float/types/account';
import type { Phase } from '@float/types/phase';
import type { Project } from '@float/types/project';

import { getIsProjectPlanView } from '../appInfo/scheduleView';
import { getUser } from '../currentUser';

export type NoPhase = {
  phase_id: 0;
  phase_name: 'No phase';
  project_id: number;
  color?: string;
  tentative?: boolean;
  start_date?: string;
  end_date?: string;
};

export type PhaseOption = {
  phase_id: number;
  phase_name: Phase['phase_name'];
  color?: string;
  tentative?: boolean;
  start_date?: string;
  end_date?: string;
  value: number;
  label: string;
  sortKey: string;
};

// @test-export
export function phaseToGroupOption(phase: Phase | NoPhase) {
  return {
    phase_id: phase.phase_id,
    phase_name: phase.phase_name,
    start_date: phase.start_date,
    end_date: phase.end_date,
    project_id: phase.project_id,
    color: phase.color,
    tentative: phase.tentative,
    value: phase.phase_id,
    label: phase.phase_name,
    sortKey: phase.phase_name.toLowerCase(),
  };
}

function groupOptions(
  phases: Array<Phase | NoPhase>,
  predicate?: (phase: Phase | NoPhase) => boolean,
) {
  const result: Record<number, PhaseOption[]> = {};

  for (const phase of phases) {
    if (predicate?.(phase) === false) continue;

    const list = result[phase.project_id];
    const option = phaseToGroupOption(phase);

    if (!list) {
      result[phase.project_id] = [option];
    } else {
      list.push(option);
    }
  }

  // OPTIMIZATION: applying the sort on the single groups
  // is way faster than doing it on the entire list
  for (const projectId in result) {
    result[projectId].sort((a, b) => {
      if (a.phase_id === 0) return 1;
      if (b.phase_id === 0) return -1;

      return a.sortKey.localeCompare(b.sortKey);
    });
  }

  return result;
}

export const getPhasesMapRaw = (state: { phases: PhasesState }) =>
  state.phases.phases;

const getProjectsMapRaw = (state: { projects: ProjectsState }) =>
  state.projects.projects;

export const getPhases = createSelector([getPhasesMapRaw], (phases) => {
  return Object.values(phases);
});

// @test-export
export const _getAccessiblePhases = createSelector(
  [getPhases, getProjectsMapRaw, getUser, getIsProjectPlanView],
  (phases, projectsMap, user: CurrentUser, isProjectPlanView: boolean) => {
    const isAdminOrHigher =
      user.account_tid !== AccountType.Member &&
      user.account_tid !== AccountType.ProjectManager;

    // For a member or PM:
    // If such as user is in a project team, they see all its phases, and the
    // "No phase" option. Otherwise they only see phases that they are part of.
    const projectsDirectlyAccessibleToUser = new Set<number>();

    const accessiblePhases: Array<Phase | NoPhase> = phases.filter((phase) => {
      if (!phase.active) {
        return false;
      }

      if (phase.status === ProjectStatus.Draft && !isProjectPlanView) {
        return false;
      }

      if (isAdminOrHigher) {
        projectsDirectlyAccessibleToUser.add(phase.project_id);
        return true;
      }

      const project = projectsMap[phase.project_id] as Project | undefined;

      if (project) {
        const isTheProjectManager = project.project_manager == user.account_id;
        const isPartOfTheProjectTeam = project.people_ids.some(
          (x) => x === user.people_id,
        );

        if (isTheProjectManager || project.common || isPartOfTheProjectTeam) {
          projectsDirectlyAccessibleToUser.add(phase.project_id);
          return true;
        }
      }

      return phase.people_ids.some((x) => x == user.people_id);
    });

    for (const projectId of projectsDirectlyAccessibleToUser) {
      accessiblePhases.push({
        phase_id: 0,
        phase_name: 'No phase',
        project_id: projectId,
      });
    }

    return accessiblePhases;
  },
);

export const getProjectPhases = createSelector([getPhases], (phases) =>
  groupBy(phases, 'project_id'),
);

export const getPhasesOptions = createSelector(
  [_getAccessiblePhases],
  (phases) => groupOptions(phases),
);

export const getNonTentativePhasesOptions = createSelector(
  [_getAccessiblePhases],
  (phases) => groupOptions(phases, (phase) => !phase.tentative),
);
