import { FilterOperators } from '@float/types/filters';

import { getOperator } from './operators';

/*
  Encodes the raw search input to account for lone % characters.
  We can't use the built in encodeURI function in JS because it encodes
  away the pipe operator symbols |.
  
  The regexp searches for any % symbol that is not followed by a 2 character hex
  and replaces it with %25, which is the hex for %
*/
export const customEncodeString = (str: string) =>
  str.replace(/%(?![\da-f]{2,})/gi, '%25');

export function getValueAndOpFromEncodedStr(str: string): {
  operator: FilterOperators | '';
  val: string;
} {
  // Need to encode the raw string to account for lone % characters which breaks the page
  // when it is decoded raw.
  const operator = getOperator(decodeURIComponent(customEncodeString(str)));

  // We return str instead of decoded value because we need preserve `,` as non-encoded character
  // to differentiate between filter text and filters separator
  // https://linear.app/float-com/issue/CS-998/check-expected-behavior-when-filtering-by-project-names-including
  let val = str;

  if (operator) {
    if (str.startsWith(operator)) {
      val = str.slice(operator.length);
    } else {
      // Check if the operator was encoded
      // https://linear.app/float-com/issue/CS-826/project-filters-are-changing-when-tabs-left-open-in-safari-cid-90957
      const encodedOperator = encodeURIComponent(operator);

      if (str.startsWith(encodedOperator)) {
        val = str.slice(encodedOperator.length);
      }
    }
  }

  return { operator, val };
}

export function getValueAndOpFromStr(str: string) {
  const operator = getOperator(str);

  let val = str;

  if (operator) {
    val = str.slice(operator.length);
  }

  return { operator, val };
}
