import { createSelector } from 'reselect';

import { BaseFilterToken } from '@float/types';

import { getCurrentViewFilters } from './getCurrentViewId';

export const getActiveFilters = createSelector(
  [
    (state: { search: { filters: BaseFilterToken[] } }) => state.search.filters,
    getCurrentViewFilters,
  ],
  (filters, currentViewFilters) => {
    const result: BaseFilterToken[] = currentViewFilters
      .concat(filters)
      .map((f) => {
        // Backward compatibility for Unnamed task
        if (f.type === 'task' && f.val === 'Unnamed') {
          return {
            type: f.type,
            val: 'No task used',
            operator: f.operator,
          };
        }

        return {
          type: f.type,
          val: f.val,
          operator: f.operator,
        };
      });

    return result;
  },
);
