import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useEvent from 'react-use/esm/useEvent';
import { createContext } from 'use-context-selector';

import { print } from '@float/common/lib/print';
import { useSafeContext } from '@float/libs/hooks/useSafeContext';

export type PrintContextType = {
  isPrinting: boolean;
  requestPrint: () => void;
};

export const PrintContext = createContext<PrintContextType | null>(null);

// @test-export
export const usePrintListeners = () => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [shouldPrintAfterRender, setShouldPrintAfterRender] = useState(false);

  const requestPrint = useCallback(() => {
    setShouldPrintAfterRender(true);
    setIsPrinting(true);
  }, []);

  const keyDownHandler = (e: KeyboardEvent) => {
    // On Chrome using the Print keyboard shortcut behaves differently
    // than using the Print menu command. To get around this we
    // intercept the shortcut, set our print mode flags, and then
    // imperatively print after layout update.
    if (e.key === 'p' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      requestPrint();
    }
  };

  const beforePrint = () => {
    setIsPrinting(true);
  };

  const afterPrint = () => {
    setIsPrinting(false);
  };

  // We need to register on `document` or sometimes the event will
  // trigger after the print preview has rendered
  useEvent('keydown', keyDownHandler as EventListener, document);
  useEvent('beforeprint', beforePrint);
  useEvent('afterprint', afterPrint);

  useEffect(() => {
    if (shouldPrintAfterRender) {
      const timer = requestIdleCallback(() => {
        setIsPrinting(false);
        setShouldPrintAfterRender(false);

        print();
      });

      return () => {
        cancelIdleCallback(timer);
      };
    }
  }, [shouldPrintAfterRender]);

  return { isPrinting, requestPrint };
};

export const PrintContextProvider: React.FC<{
  children?: ReactNode;
  overrides?: Partial<PrintContextType>;
}> = ({ children, overrides }) => {
  const { isPrinting, requestPrint } = usePrintListeners();

  const memoizedContext = useMemo(
    () => ({
      isPrinting,
      requestPrint,
      ...overrides,
    }),
    [isPrinting, requestPrint, overrides],
  );

  return (
    <PrintContext.Provider value={memoizedContext}>
      {children}
    </PrintContext.Provider>
  );
};

export const usePrintContext = () =>
  useSafeContext(PrintContext, 'PrintContext');
