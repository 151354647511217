import React from 'react';

import { IconWarningTriangle } from '@float/ui/icons/essentials/IconWarningTriangle';

import * as styles from './Warning.styles.css';

type WarningProps = {
  children: React.ReactNode;
};

function WarningTitle({ children }: WarningProps) {
  return (
    <div className={styles.warningTitle}>
      <IconWarningTriangle className={styles.warningIcon} />
      {children}
    </div>
  );
}

function WarningBody({ children }: WarningProps) {
  return <div className={styles.warningBody}>{children}</div>;
}

export function Warning({ children }: WarningProps) {
  return <div className={styles.warning}>{children}</div>;
}

Warning.Title = WarningTitle;
Warning.Body = WarningBody;
