import { createSelector } from 'reselect';

import {
  isViewPageAccessibleByMembers,
  userCanOnlyViewThemself,
} from '@float/common/lib/rights';
import { AccountType } from '@float/constants/accounts';
import type { AccountsState } from '@float/common/reducers/accounts';
import type { DepartmentsState } from '@float/common/reducers/departments';
import type { ViewsState } from '@float/common/reducers/views';
import type { CompanyPreferences, CurrentUser, SavedView } from '@float/types';

import { getUser } from '../currentUser';

const getViewsRawList = (state: { views?: ViewsState }) => state?.views?.views;

const EMPTY_VIEWS_LIST: SavedView[] = [];

export const getViewsList = createSelector(
  [getViewsRawList, getUser],
  (views = EMPTY_VIEWS_LIST, user) => {
    if (user.account_type_id === AccountType.Member) {
      if (userCanOnlyViewThemself(user)) {
        return views.filter((view) => view.personal);
      }

      return views.filter((view) => isViewPageAccessibleByMembers(view));
    }

    return views;
  },
);

export const getViewsLoadState = (state: { views?: ViewsState }) =>
  state?.views?.loadState;

export const getViewsIsLoading = (state: { views?: ViewsState }) => {
  const loadState = getViewsLoadState(state);

  return loadState === 'INITIAL' || loadState === 'LOADING';
};

export const getViewsIsLoadingFailed = (state: { views?: ViewsState }) => {
  const loadState = getViewsLoadState(state);

  return loadState === 'LOAD_FAILED';
};

export const getViewById = (
  state: {
    accounts: AccountsState;
    companyPrefs: CompanyPreferences;
    currentUser: CurrentUser;
    departments: DepartmentsState;
    views?: ViewsState;
  },
  id: number,
) => {
  return getViewsList(state).find((view) => view.id === id) ?? null;
};
