const FILTER_ENTITIES = [
  'client',
  'department',
  'jobTitle',
  'manager',
  'person',
  'personTag',
  'personType',
  'phase',
  'project',
  'projectOwner',
  'projectStatus',
  'projectTag',
  'role', // Note 'role' and 'jobTitle' are both defined, but they are equivalent
  'task',
  'taskStatus',
  'timeoff',
  'timeoffStatus',
] as const;

// Should not include `savedSearch`
export type FilterEntity = (typeof FILTER_ENTITIES)[number];

// All filter types, excluding `me`
export type EditableFilterEntity = FilterEntity | 'contains';

export type FilterType = FilterEntity | 'savedSearch' | 'me';

export type VirtualFilterTypes = FilterEntity | 'contains' | 'me';

export const VIRTUAL_FILTER_CATEGORIES_EXCLUDING_SAVED_SEARCH = [
  ...FILTER_ENTITIES,
  'contains',
] as const;

export type VirtualFilterCategoryExcludingSavedSearch =
  (typeof VIRTUAL_FILTER_CATEGORIES_EXCLUDING_SAVED_SEARCH)[number];

export const VIRTUAL_FILTER_CATEGORIES = [
  ...VIRTUAL_FILTER_CATEGORIES_EXCLUDING_SAVED_SEARCH,
  'savedSearch',
] as const;

export type VirtualFilterCategory = (typeof VIRTUAL_FILTER_CATEGORIES)[number];

// The categories that are rendered on the drop down, excluding `me`
export const FILTERABLE_ENTITY_KEYS = [
  'savedSearches',
  'departments',
  'people',
  'managers',
  'jobTitles', // AKA 'roles'
  'personTags',
  'personTypes',
  'timeoffs',
  'clients',
  'projects',
  'phases',
  'projectTags',
  'projectOwners',
  'projectStatuses',
  'tasks',
  'taskStatuses',
  'timeoffStatuses',
] as const;

/**
 * The categories that are rendered on the drop down, excluding the `me` and `contains` categories.
 * These are all `expandable` categories – they have children that can be filtered/searched.
 */
export type SearchAutocompleteCategory =
  (typeof FILTERABLE_ENTITY_KEYS)[number];

// Add categories except `me` and
export type EditableAutocompleteCategory =
  | Exclude<SearchAutocompleteCategory, 'savedSearches'>
  | 'contains';

export type SearchCategoryExcludingContains = SearchAutocompleteCategory | 'me';

export type SearchCategory = SearchCategoryExcludingContains | 'contains';

export const FILTER_TO_CATEGORY_MAPPING = {
  client: 'clients',
  department: 'departments',
  jobTitle: 'jobTitles',
  manager: 'managers',
  person: 'people',
  personTag: 'personTags',
  personType: 'personTypes',
  phase: 'phases',
  project: 'projects',
  projectOwner: 'projectOwners',
  projectStatus: 'projectStatuses',
  projectTag: 'projectTags',
  role: 'jobTitles', // Note 'role' and 'jobTitle' are both defined, but they are equivalent
  task: 'tasks',
  taskStatus: 'taskStatuses',
  timeoff: 'timeoffs',
  timeoffStatus: 'timeoffStatuses',
} as const;

export const FILTER_ENTITY_TO_SEARCH_AUTOCOMPLETE_CATEGORY_MAPPING: Record<
  FilterEntity | 'savedSearch',
  SearchAutocompleteCategory
> = {
  ...FILTER_TO_CATEGORY_MAPPING,
  savedSearch: 'savedSearches',
};

export const EDITABLE_FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING: Record<
  EditableFilterEntity,
  EditableAutocompleteCategory
> = {
  ...FILTER_TO_CATEGORY_MAPPING,
  contains: 'contains',
};

export const FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING: Record<
  VirtualFilterCategory | 'me',
  SearchCategory | 'me'
> = {
  ...FILTER_ENTITY_TO_SEARCH_AUTOCOMPLETE_CATEGORY_MAPPING,
  contains: 'contains',
  me: 'me',
};

export const SEARCH_CATEGORY_EXCLUDING_SAVED_SEARCHES_TO_FILTER_TYPE_MAPPING: Record<
  Exclude<SearchCategory, 'savedSearches'> | 'contains',
  Exclude<FilterType, 'savedSearch'> | 'contains'
> = {
  clients: 'client',
  contains: 'contains',
  departments: 'department',
  jobTitles: 'jobTitle', // AKA 'roles
  managers: 'manager',
  me: 'me',
  people: 'person',
  personTags: 'personTag',
  personTypes: 'personType',
  phases: 'phase',
  projectOwners: 'projectOwner',
  projects: 'project',
  projectStatuses: 'projectStatus',
  projectTags: 'projectTag',
  tasks: 'task',
  taskStatuses: 'taskStatus',
  timeoffs: 'timeoff',
  timeoffStatuses: 'timeoffStatus',
};

export const SEARCH_CATEGORY_TO_FILTER_TYPE_MAPPING: Record<
  SearchCategory,
  FilterType | 'savedSearch' | 'contains'
> = {
  ...SEARCH_CATEGORY_EXCLUDING_SAVED_SEARCHES_TO_FILTER_TYPE_MAPPING,
  savedSearches: 'savedSearch',
};
