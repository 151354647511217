import { getPeopleMap } from '@float/common/selectors/people';
import { AppDispatchStrict, AppStoreStrict } from '@float/common/store';
import { Person } from '@float/types/person';

import API3 from '../../api3';
import { sanitizeFetchedPerson } from './helpers/sanitizeFetchedPerson';
import { PEOPLE_UPDATED } from './types';

export * from './people';
export * from './savePerson';

async function fetchPerson(personId: Person['people_id']) {
  const res = await API3.getPerson({ id: personId });

  return sanitizeFetchedPerson(res);
}

export const ensurePersonLoaded = (personId: Person['people_id']) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: AppStoreStrict['getState'],
  ) => {
    const person = await fetchPerson(personId);

    dispatch({ type: PEOPLE_UPDATED, person });

    return getPeopleMap(getState())[personId];
  };
};
