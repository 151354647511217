import { FilterMatcher, FiltersDataType } from '../../types';
import { LoggedTimesFilterMatcher } from './loggedTimes';
import { PeopleFilterMatcher } from './PeopleFilterMatcher.class';
import { ProjectsFilterMatcher } from './projects';
import { TasksFilterMatcher } from './tasks';
import { TimeoffsFilterMatcher } from './timeoffs';

export const FILTER_MATCHERS: Record<
  FiltersDataType,
  typeof FilterMatcher<FiltersDataType>
> = {
  tasks: TasksFilterMatcher,
  loggedTimes: LoggedTimesFilterMatcher,
  people: PeopleFilterMatcher,
  projects: ProjectsFilterMatcher,
  timeoffs: TimeoffsFilterMatcher,
} as const;

export { ContainsMatcher } from './contains';
