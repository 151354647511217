import { OnboardingSurveyOption } from '../../types';

export enum Goals {
  Resourcing = 'Resourcing',
  Reporting = 'Reporting',
  ProjectScoping = 'ProjectScoping',
  TimeTracking = 'TimeTracking',
}

export type SurveyGoalsDataType = Record<'goals', OnboardingSurveyOption[]>;
