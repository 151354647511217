import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';

import { StatusIcon } from '../StatusIcon/StatusIcon';

export const AVATAR_SIZES = {
  xl: {
    width: 200,
    fontSize: 48,
    fontWeight: 700,
  },
  lg: {
    width: 80,
    fontSize: 34,
    fontWeight: 700,
  },
  md: {
    width: 60,
    fontSize: 26,
    fontWeight: 600,
  },
  mobile: {
    width: 50,
    fontSize: 18,
    fontWeight: 600,
  },
  sm: {
    width: 40,
    fontSize: 18,
    fontWeight: 600,
  },
  sm2: {
    css: css`
      width: 32px;
      height: 32px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: center;
    `,
  },
  s: {
    width: 35,
    fontSize: 14,
    fontWeight: 500,
  },
  xs: {
    width: 24,
    fontSize: 11,
    fontWeight: 600,
  },
  xs2: {
    width: 20,
    fontSize: 8,
    fontWeight: 500,
  },
};

const getAvatarSizeStyles = (p) => {
  const opt = AVATAR_SIZES[p.size];
  if (!opt) {
    return '';
  }

  if (opt.css) {
    return opt.css;
  }

  return css`
    font-size: ${opt.fontSize}px;
    font-weight: ${opt.fontWeight || 'normal'};
    width: ${opt.width}px;
    height: ${opt.width}px;
    line-height: ${opt.width}px;
  `;
};

export const StyledAvatar = styled.div`
  position: relative;
  border-radius: 50%;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: ${({ theme }) => theme.white};
  ${({ theme }) => `color: ${theme.flueLight9};`}

  &:before {
    content: '';
    background: ${({ theme }) => theme.flueLight4};
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }

  img {
    position: relative;

    width: 100%;

    border-radius: 50%;

    opacity: 0;

    transition-property: opacity;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    z-index: 1;

    &.loaded {
      opacity: 1;
    }
  }

  .initials {
    position: absolute;

    top: 0;
    left: 0;

    display: flex;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    pointer-events: none;

    z-index: 0;
  }

  ${(p) => getAvatarSizeStyles(p)}

  ${({ disabled }) =>
    disabled &&
    `
    img, &:before, .initials, svg {
      opacity: 0.5;
    }
  `}
`;

export const AvatarEditLink = styled.label`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.7;
  font-size: 12px;
  line-height: 1.43;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  cursor: pointer;

  z-index: 2;

  input {
    display: none;
  }
`;

export const AvatarEditLinkContainer = styled.div`
  position: relative;
  cursor: pointer;
  ${(p) => getAvatarSizeStyles(p)}

  &:hover {
    ${AvatarEditLink} {
      display: flex;
    }
  }
`;

export const AvatarEditorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 51px 0 76px;
`;

export const ZoomFactorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  svg:first-child {
    margin-right: 1rem;
  }

  svg:last-child {
    margin-left: 1rem;
  }

  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    max-width: 240px;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: ${(p) => p.theme.grey};
    border-radius: 5px;
  }
  input[type='range']::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${(p) => p.theme.blueGrey};
    cursor: pointer;
    margin-top: -5px;
    -webkit-appearance: none;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: ${(p) => p.theme.grey};
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: ${(p) => p.theme.grey};
    border-radius: 5px;
  }
  input[type='range']::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${(p) => p.theme.blueGrey};
    cursor: pointer;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    background: ${(p) => p.theme.grey};
    border-radius: 5px;
  }
  input[type='range']::-ms-fill-upper {
    background: ${(p) => p.theme.grey};
    border-radius: 5px;
  }
  input[type='range']::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${(p) => p.theme.blueGrey};
    cursor: pointer;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: ${(p) => p.theme.grey};
  }
  input[type='range']:focus::-ms-fill-upper {
    background: ${(p) => p.theme.grey};
  }
`;

export const StatusIconContainer = styled(StatusIcon)`
  position: absolute;
  bottom: 1px;
  right: 0;

  ${StatusIcon} {
    position: absolute;
    bottom: -8px;
    right: 0px;
  }
`;
