import { createSelector } from 'reselect';

import { fastDictionary } from '../../lib/fast-object-spread';
import { selectTasksList } from '../tasks/selectTasksList';

// This selector that should only be used internally by other search selectors
export const selectProjectTaskedPeople = createSelector(
  [selectTasksList],
  (tasks) => {
    const projectTaskedPeople = fastDictionary() as Record<string, Set<string>>;

    for (const task of tasks) {
      let peopleIds = projectTaskedPeople[task.project_id];

      if (!peopleIds) {
        peopleIds = new Set();
        projectTaskedPeople[task.project_id] = peopleIds;
      }

      for (const id of task.people_ids) {
        peopleIds.add(String(id));
      }
    }

    return projectTaskedPeople;
  },
);
