import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import { Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AllActions, AllActionsStrict } from '@float/common/store/store.types';
import { WebAppState, WebAppStateStrict } from '@float/web/reducers/types';

import { ModalAction } from '../modalManager/types';
import { OnboardingActions } from '../store/onboardingManager/actions';

export type WebAppActionsStrict =
  | AllActionsStrict
  | OnboardingActions
  | ModalAction;
export type WebAppActions = WebAppActionsStrict | AllActions;

export type WebAppStore = Store<WebAppState, WebAppActions>;
export type WebAppStoreStrict = Store<WebAppStateStrict, WebAppActionsStrict>;

export type WebAppDispatch = ThunkDispatch<WebAppState, unknown, WebAppActions>;
export type WebAppDispatchStrict = ThunkDispatch<
  WebAppStateStrict,
  unknown,
  WebAppActionsStrict
>;

// @deprecated use `useWebAppSelectorStrict` if possible
export const useWebAppSelector: TypedUseSelectorHook<WebAppState> = useSelector;
export const useWebAppSelectorStrict: TypedUseSelectorHook<WebAppStateStrict> =
  useSelector;

export const useWebAppDispatch: () => WebAppDispatch = useDispatch;
export const useWebAppDispatchStrict: () => WebAppDispatchStrict = useDispatch;

export const useWebAppStoreStrict: () => WebAppStoreStrict = useStore;
