import { t } from '@lingui/macro';

import { SearchResolveFilterSpecialValue } from '@float/common/api3/search.constants';

export const getSearchDisplayValueText = (val: string | undefined) => {
  if (val === SearchResolveFilterSpecialValue.Any) {
    return t`Any (All Types)`;
  }

  if (val === SearchResolveFilterSpecialValue.None) {
    return t`None`;
  }

  return val;
};
