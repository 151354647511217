import { Rights } from '@float/common/lib/acl';
import {
  getManagerAccess,
  getManagerAccessBit,
  setManagerAccess,
} from '@float/common/lib/acl/access';
import { isCostRateFeatureActive } from '@float/common/lib/rates/rates';
import { useAppSelectorStrict } from '@float/common/store';
import { getUser } from '@float/web/selectors';

import { AccountWithAccess } from '../PersonAccess.types';

export function useManagerPermissionsController(account: AccountWithAccess) {
  const { access = 0 } = account;
  const user = useAppSelectorStrict(getUser);
  const managerAccess = getManagerAccess(account);

  const updateAccess = (key: string, value: boolean | number) => {
    return {
      access: setManagerAccess(access, key, value),
    };
  };

  const canUpdateAccess = (key: string) => {
    return Rights.canUpdatePeople(user, {
      accessCheck: getManagerAccessBit(key),
      account: {
        ...account,
        ...updateAccess(key, true),
      },
    });
  };

  const hasCostRateFeature = isCostRateFeatureActive();

  return {
    hasCostRateFeature,
    managerAccess,
    canUpdateAccess,
    updateAccess,
  };
}
