import React from 'react';
import { useLocation } from 'react-router-dom';

import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';
import { FIN } from '@float/theme';
import { DensitySpacer } from '@float/ui/components/DensitySpacer';
import { DotSeparator } from '@float/ui/components/DotSeparator';
import { Tooltip } from '@float/ui/components/Tooltip';

import { truncateFields } from './helpers';

import * as styles from './styles.css';

const MaybeTooltip = ({
  content,
  ogContent,
  showTooltip,
}: {
  content: ReturnType<typeof trimTextForReadibility>;
  ogContent: string;
  showTooltip: boolean;
}) => {
  return showTooltip ? (
    <Tooltip content={ogContent} placement="bottom">
      <span>{content.value}</span>
    </Tooltip>
  ) : (
    <span>{content.value}</span>
  );
};

const SingleFieldDisplay = ({ content }: { content: string }) => (
  <div className={styles.field({ display: 'single' })}>{content}</div>
);

const DualFieldDisplay = ({
  clientName,
  projectCode,
  ogProjectCode,
  ogClientName,
}: {
  clientName: ReturnType<typeof trimTextForReadibility>;
  projectCode: ReturnType<typeof trimTextForReadibility>;
  ogClientName: string;
  ogProjectCode: string;
}) => {
  return (
    <div className={styles.field({ display: 'dual' })}>
      <MaybeTooltip
        content={projectCode}
        ogContent={ogProjectCode}
        showTooltip={projectCode.trimmed}
      />
      <DotSeparator
        fill={FIN.Color.Radix.Flay.Flay10}
        className={styles.separator}
      />
      <MaybeTooltip
        content={clientName}
        ogContent={ogClientName}
        showTooltip={clientName.trimmed}
      />
    </div>
  );
};

export type ProjectCodeAndClientProps = {
  clientId?: number;
  clientName?: string;
  projectCode?: string;
  density: 'default' | 'compact';
};

export const ProjectCodeAndClient = ({
  clientId,
  clientName = '',
  projectCode,
  density,
}: ProjectCodeAndClientProps) => {
  const location = useLocation();
  const { isProjectCodesEnabled } = useProjectCodesPreference();

  const isSharedLink = location.pathname.includes('/share/');
  if (isSharedLink) {
    clientId = undefined;
  }
  if (!isProjectCodesEnabled) {
    if (!clientId) return null;

    return (
      <>
        <SingleFieldDisplay content={clientName} />
      </>
    );
  }

  if (projectCode && clientId) {
    const truncated = truncateFields(projectCode, clientName);

    return (
      <>
        <DensitySpacer density={density} />
        <DualFieldDisplay
          clientName={truncated.clientName}
          ogClientName={clientName}
          projectCode={truncated.projectCode}
          ogProjectCode={projectCode}
        />
      </>
    );
  }

  if (projectCode || clientId) {
    const content = projectCode ? projectCode : clientName;
    return (
      <>
        <DensitySpacer density={density} />
        <SingleFieldDisplay content={content} />
      </>
    );
  }

  return null;
};
