import { saveAs } from 'file-saver';
import { forEach, some } from 'lodash';
import { getTaskStatusString } from 'reports/components/TabBarFilters/StatusFilter';
import { getTeamModeString } from 'reports/components/TabBarFilters/TeamMode';
import { getExportDepartmentName } from 'reports/TeamCapacity/parser/csv/table';

import { getPercentage as _getPercentage } from '@float/common/lib/budget';
import { moment } from '@float/libs/moment';

import { breakdown } from '../table/tasks';

// For the purposes of CSV export, we always want the '%' symbol
function getPercentage(a, b) {
  return _getPercentage(a, b, true);
}

function getProjectBudgetTotal(ctx) {
  const projectId = ctx.project.project_id;
  return ctx.budgets.projects[projectId]?.budget_total ?? 0;
}

const COLUMNS = [
  ['Phase', (d, r, ctx) => ctx.phases[r.phase_id]?.phase_name || 'No Phase'],
  ['Task', (d, r) => r.name],
  ['Person', (d, r, ctx) => ctx.people[d.person_id].name],
  ['Department', (d, r, ctx) => getExportDepartmentName(ctx, d.person_id)],
  ['Budget', () => ' '],
  ['Scheduled hrs', (d) => d.scheduled],
  ['Scheduled Billable hrs', (d) => d.billable],
  ['Scheduled Fee', (d) => d.feeCents / 100],
  [
    'Budget used % (Scheduled)',
    (d, r, ctx) => getPercentage(d.feeCents, getProjectBudgetTotal(ctx) * 100),
  ],
  ['Scheduled Non-billable hrs', (d) => d.nonbillable],
  ['Scheduled Tentative (all) hrs', (d) => d.tentative],
  ['Logged hrs', (d) => d.logged.scheduled],
  ['Logged Billable hrs', (d) => d.logged.billable],
  ['Logged Fee', (d, r, ctx) => d.logged.feeCents / 100],
  [
    'Budget used % (Logged)',
    (d, r, ctx) =>
      getPercentage(d.logged.feeCents, getProjectBudgetTotal(ctx) * 100),
  ],
  ['Logged Non-billable hrs', (d) => d.logged.nonbillable],
];

// @test-export
export function getSheetData(ctx, rawTableData, chartTotals) {
  const data = breakdown(ctx, rawTableData);
  const projectBudgetTotal = getProjectBudgetTotal(ctx);

  let columns = ctx.timeTrackingEnabled
    ? [...COLUMNS]
    : COLUMNS.filter((c) => !c[0].includes('Logged'));

  const hasPhases = some(data, (d) => d.phase_id);
  if (!hasPhases) {
    columns.splice(0, 1);
  }

  if (!ctx.project.isMoneyBudgetType) {
    columns = columns.filter((c) => !c[0].includes('Fee'));
  }

  const headers = columns.map((c) => c[0]);
  const result = [headers];

  forEach(data, (d) => {
    forEach(d.children, (child) => {
      const row = columns.map((c) => c[1](child, d, ctx) || 0);
      result.push(row);
    });
  });

  const totalsRow = [
    '',
    'Total',
    '',
    '',
    projectBudgetTotal,
    chartTotals.scheduled,
    chartTotals.billable,
  ];
  if (!hasPhases) {
    totalsRow.splice(0, 1);
  }

  if (ctx.project.isMoneyBudgetType) {
    totalsRow.push(chartTotals.feeCents / 100);
  }

  totalsRow.push(
    getPercentage(chartTotals.feeCents, projectBudgetTotal * 100),
    chartTotals.nonbillable,
    chartTotals.tentative,
  );

  if (ctx.timeTrackingEnabled) {
    totalsRow.push(chartTotals.logged.total, chartTotals.logged.billable);

    if (ctx.project.isMoneyBudgetType) {
      totalsRow.push(chartTotals.logged.feeCents / 100);
    }

    totalsRow.push(
      getPercentage(chartTotals.logged.feeCents, projectBudgetTotal * 100),
      chartTotals.logged.nonbillable,
    );
  }

  result.push(totalsRow, [], []);

  result.push(
    [
      'Date range',
      moment(ctx.settings.startDate).format('DD MMM YYYY'),
      moment(ctx.settings.endDate).format('DD MMM YYYY'),
    ],
    ['Tasks', getTaskStatusString(ctx.settings)],
    ['People', getTeamModeString(ctx.settings)],
  );

  if (ctx.searchFiltersString) {
    result.push([ctx.searchFiltersString.replace('Filters:', 'Filters')]);
  }

  return result;
}

export default async function exportTableCsv(ctx, rawTableData, chartTotals) {
  return import('xlsx').then((XLSX) => {
    const { project, settings } = ctx;
    const startDate = moment(settings.startDate).format('YYYYMMDD');
    const endDate = moment(settings.endDate).format('YYYYMMDD');

    const projectName =
      `${project.client_name}-${project.project_name}`.replaceAll(' ', '-');

    const filename = `${projectName}-Table-${startDate}-${endDate}.csv`;

    const sheetData = getSheetData(ctx, rawTableData, chartTotals);

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Float');
    const wbout = XLSX.write(wb, { type: 'array', bookType: 'csv' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename);
  });
}
