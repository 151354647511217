import { createSelector } from 'reselect';

import type { Client } from '@float/types';

import type { ClientsState } from '../../reducers/clients';

export const getClientsMap = (state: {
  clients: ClientsState;
}): Record<number, Client> => state.clients.clients;

export const getClients = createSelector([getClientsMap], (clientsMap) =>
  Object.values(clientsMap),
);

export const selectClientById = (
  state: { clients: ClientsState },
  clientId: number | undefined,
) => (clientId ? state.clients.clients[clientId] : undefined);
