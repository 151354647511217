import { FeatureFlag } from '@float/libs/featureFlags';
import { isSupportedFeatureFlag } from '@float/libs/featureFlags/FeatureFlags.helpers';
import { logger } from '@float/libs/logger';

const STORAGE_KEY = 'featureFlags';

export enum FeatureFlagOverridesURLParam {
  ENABLED = 'enabledFeatureFlagOverrides',
  DISABLED = 'disabledFeatureFlagOverrides',
  CLEAR = 'clearFeatureFlagOverrides',
}

export const getAreFeatureFlagOverridesEnabled = () =>
  // This is web only, so we can use localStorage directly
  // eslint-disable-next-line no-restricted-globals
  Boolean(localStorage.getItem(STORAGE_KEY));

export const getIsClearFeatureFlagOverridesKeyInURLParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(FeatureFlagOverridesURLParam.CLEAR) !== null;
};

export const getFeatureFlagOverridesFromURLParams = (
  key: FeatureFlagOverridesURLParam,
  enabledValue: boolean,
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const unsanitizedOverrides = urlParams.get(key)?.split(',');

  if (!unsanitizedOverrides || unsanitizedOverrides.length === 0) return {};

  const overrides = unsanitizedOverrides.reduce(
    (acc, flag: string) => {
      if (isSupportedFeatureFlag(flag)) {
        acc[flag as FeatureFlag] = enabledValue;
      } else {
        logger.warn(
          `Encountered unknown feature flag '${flag}' while parsing URL params.\n\n` +
            `Available flags are:\n\n` +
            `${Object.values(FeatureFlag).join(', ')}\n\n` +
            `If you're trying to override a flag that is missing, it may need to be added to the list of available flags.`,
        );
      }
      return acc;
    },
    {} as Record<FeatureFlag, boolean>,
  );

  logger.info(
    `Parsed feature flags overrides from URL params: ${JSON.stringify(
      overrides,
    )}`,
  );

  return overrides;
};

export const getStoredFeatureFlagOverrides = (): Partial<
  Record<FeatureFlag, boolean>
> => {
  // This is web only, so we can use localStorage directly
  // eslint-disable-next-line no-restricted-globals
  const rawValue = localStorage.getItem(STORAGE_KEY);

  if (rawValue) return JSON.parse(rawValue);

  return {};
};

export const addFeatureFlagOverridesToLocalStorage = (
  value: Partial<Record<FeatureFlag, boolean>>,
) => {
  // This is web only, so we can use localStorage directly
  // eslint-disable-next-line no-restricted-globals
  localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
};

export const disableFeatureFlagOverrides = () => {
  // This is web only, so we can use localStorage directly
  // eslint-disable-next-line no-restricted-globals
  localStorage.removeItem(STORAGE_KEY);
};
