import React, { CSSProperties, PropsWithChildren } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import {
  getGridBackgroundSize,
  getGridBackgroundUrl,
} from '@float/common/serena/util/getGridBackground';

import * as styles from './styles.css';

export const GridContainer = ({
  children,
  dayWidth,
  numDays,
  style,
}: PropsWithChildren<{
  dayWidth: number;
  numDays: number;
  style: CSSProperties;
}>) => {
  return (
    <div
      className={styles.gridContainer}
      style={{
        ...style,
        ...assignInlineVars({
          [styles.backgroundSizeVar]: getGridBackgroundSize(dayWidth, numDays),
          [styles.backgroundURLVar]: getGridBackgroundUrl(dayWidth, numDays),
        }),
      }}
    >
      {children}
    </div>
  );
};
