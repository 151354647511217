import React from 'react';

import { RateText } from '@float/common/components/Rate';
import { getRateSuffix } from '@float/common/lib/rates/rates';

import * as styles from './RoleTableRate.styles.css';

type Props = {
  rate: string | null | undefined;
};

export function RoleTableRate({ rate }: Props) {
  if (!rate) return null;

  const perHourSuffix = getRateSuffix();

  return (
    <div className={styles.rateWrapper}>
      <RateText rate={rate} isHoursBased={false} />

      {perHourSuffix && <span className={styles.perHour}>{perHourSuffix}</span>}
    </div>
  );
}
