import { createSelector } from 'reselect';

import { filterAndKeyBy } from '@float/common/lib/keyBy';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getSearchFilteredPeopleIds } from '@float/common/search/selectors/people';
import {
  LoggedTime,
  LoggedTimeDeletePlaceholder,
} from '@float/types/loggedTime';

import {
  getLoggedTimesListRaw,
  isFullLoggedTime,
  isLoggedTimeDeletePlaceholder,
} from '../loggedTimes';
import { getProjectsIdsSet } from '../projects/getProjectsIdsSet';
import { getFullTasksList } from '../tasks';
import { getFullTimeoffsList } from '../timeoffs';

export const getPeopleFilteredTasks = createSelector(
  [
    getFullTasksList,
    getSearchFilteredPeopleIds,
    (state: ReduxStateStrict) => state.tasks.fullyHydrated,
  ],
  (tasks, peopleIds, fullyHydrated) => {
    if (!fullyHydrated) return undefined;

    return filterAndKeyBy(tasks, 'task_id', (task) =>
      task.people_ids.some((id) => peopleIds.has(id)),
    );
  },
);

export const getPeopleFilteredTimeoffs = createSelector(
  [
    getFullTimeoffsList,
    getSearchFilteredPeopleIds,
    (state: { timeoffs: { fullyHydrated: boolean } }) =>
      state.timeoffs.fullyHydrated,
  ],
  (timeoffs, peopleIds, fullyHydrated) => {
    if (!fullyHydrated) return undefined;

    return filterAndKeyBy(timeoffs, 'timeoff_id', (timeoff) =>
      timeoff.people_ids.some((id) => peopleIds.has(id)),
    );
  },
);

export const getPeopleFilteredLoggedTimes = createSelector(
  // OPTIMIZATION: using getProjectsIdsSet instead of the projects map
  // to reduce the revalidations to when the list of project ids really changes
  [getLoggedTimesListRaw, getProjectsIdsSet, getSearchFilteredPeopleIds],
  (loggedTimes, projecsIds, peopleIds) => {
    return filterAndKeyBy(
      loggedTimes,
      'logged_time_id',
      (loggedTime): loggedTime is LoggedTime | LoggedTimeDeletePlaceholder =>
        isLoggedTimeDeletePlaceholder(loggedTime) ||
        (isFullLoggedTime(loggedTime) &&
          peopleIds.has(loggedTime.people_id) &&
          // Compensate for a scenario where the backend returns loggedtimes for
          // projects that have been deleted. Note that if we don't have a project_id
          // at all, we should forward along the record as it's the result of a
          // delete and we want to ensure the replacesTempId logic in Serena loadData
          // triggers.
          projecsIds.has(loggedTime.project_id)),
    );
  },
);
