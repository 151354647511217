import React from 'react';
import { Link } from 'react-router-dom';
import cs from 'classnames';
import Linkify from 'linkify-react';
import { isNil } from 'lodash';
import styled from 'styled-components';
import type { TeamCapacityTableContext } from 'reports/TeamCapacity/parser/table/types';

import { addFilters } from '@float/common/actions/search';
import {
  LEGACY_FILTERS_THAT_SUPPORT_NONE_VALUE,
  LegacyFilterSpecialValue,
  SearchResolveFilterSpecialValue,
} from '@float/common/api3/search.constants';
import { formatAmount } from '@float/common/lib/budget';
import { formatPercentage } from '@float/common/lib/budget/helpers/getPercentage';
import { isEditableProject } from '@float/common/lib/utils';
import { ProjectBudgetType } from '@float/constants/projects';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Tooltip } from '@float/ui/components/Tooltip';
import { RangeBar } from '@float/ui/deprecated/Chart/RangeBar/RangeBar';
import IconPencil from '@float/ui/deprecated/Icons/IconPencil';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';
import type { LegacyFilterType, VirtualFilterTypes } from '@float/types';
import type {
  AccordionTableCellFormatter,
  AccordionTableCellValue,
  ParsedAccordionTableCellValue,
  RangeBarFormatter,
} from '@float/ui/deprecated/AccordionTable/types';
import type { BarConfig } from '@float/ui/deprecated/Chart/RangeBar/RangeBar';

import { ProjectManagerAvatar } from '../components/ProjectManagerAvatar';
import { showLogTimeEditModal } from './loggedTime';
import type { ReportTimeItemData } from '../types';

import * as styles from './styles.css';

const TableLink = styled.span`
  cursor: pointer;
  width: 100%;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`;

const NegativeAmt = styled.span`
  color: ${(p) => p.theme.red};
`;

export const pctFormatter: AccordionTableCellFormatter = (cell) => {
  if (typeof cell === 'undefined' || typeof cell === 'object' || cell === '')
    return '';
  return `${cell}%`;
};

export const amountFormatter: AccordionTableCellFormatter = (cell) => {
  if (typeof cell === 'object') {
    if (typeof cell?.sortVal === 'undefined' || cell.sortVal === '') return '';
    if (cell.val === '-') return '-';
    if (cell.type === -1) return '-';
    if (cell.type === 3) return 'Hourly fee';
    const formatted = formatAmount(cell.type, cell.val);
    return typeof cell.val === 'number' && cell.val >= 0 ? (
      formatted ?? ''
    ) : (
      <NegativeAmt>{formatted}</NegativeAmt>
    );
  }

  return '';
};

export const feeFormatter: AccordionTableCellFormatter = (cell) => {
  if (typeof cell === 'undefined' || cell === '') return '';

  if (typeof cell === 'object' && cell) {
    return formatAmount(1, cell.val) ?? '';
  }

  return formatAmount(2, cell) ?? '';
};

export const hoursFormatter: AccordionTableCellFormatter = (cell) => {
  if (typeof cell === 'undefined' || cell === '') return '';

  if (typeof cell === 'object' && cell) {
    if (cell.display) {
      return cell.display;
    }

    return formatAmount(1, cell.val) ?? '';
  }

  if (typeof cell === 'string') {
    return cell;
  }

  return formatAmount(1, cell) ?? '';
};

const getCellConfig = (
  cell: AccordionTableCellValue | undefined,
): ParsedAccordionTableCellValue => {
  if (isNil(cell)) {
    return {
      val: '',
    };
  }

  if (typeof cell === 'object') {
    return cell;
  }

  if (typeof cell === 'number') {
    return {
      val: cell,
    };
  }

  if (!cell.includes('::')) {
    return {
      val: cell,
    };
  }

  const [type, val, name] = cell.split('::');
  return {
    type,
    val,
    name: name || val,
  };
};

export const avatarFormatter: AccordionTableCellFormatter = (cell) => {
  const { type, name, val } = getCellConfig(cell);

  if (type === 'project-report-manager-avatar') {
    return <ProjectManagerAvatar id={Number(val)} name={name || 'N/A'} />;
  }

  return val;
};

// TODO: Replace with a more robust overflow detection system
// https://linear.app/float-com/issue/DQ-46/introduce-overflow-detection-for-tooltip-display-in-reports-table
const tooltipCells: Record<string, number> = {
  client: 18,
  'project-report-link': 20,
  'project-code': 12,
};

const shouldShowTooltip = (
  type: ReturnType<typeof getCellConfig>['type'],
  val: ReturnType<typeof getCellConfig>['val'],
) => {
  if (typeof type === 'string' && typeof val === 'string') {
    return val.length > tooltipCells[type];
  }

  return false;
};

// @test-export
export const sanitizeFilterName = (
  name: string | undefined,
  type: string | number,
  isSBLEnabled: boolean,
) => {
  let sanitizedName = name;

  // We need to convert the legacy none value to the search resolve none value
  // when SBL is enabled.
  //
  // NOTE: We're doing doing the sanitization here to limit the of impact of
  // the change, but a more robust solution *might* be to just change the
  // cell configuration for `No Department` cells to use `none` instead of `None`.
  // This would require further investigation to make sure there are no other
  // effects.
  if (
    isSBLEnabled &&
    LEGACY_FILTERS_THAT_SUPPORT_NONE_VALUE.includes(
      String(type) as VirtualFilterTypes | LegacyFilterType,
    ) &&
    sanitizedName === LegacyFilterSpecialValue.None
  ) {
    sanitizedName = SearchResolveFilterSpecialValue.None;
  }

  return sanitizedName;
};

export const filterLinkFormatter: AccordionTableCellFormatter = (cell) => {
  const { type, name, val } = getCellConfig(cell);

  if (!type) {
    return val;
  }

  if (type === 'project-report-link') {
    const link = (
      <Link
        to={{
          pathname: '/report',
          search: `?project=${encodeURIComponent(name || val)}`,
        }}
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        {val}
      </Link>
    );

    if (shouldShowTooltip(type, val)) {
      return (
        <Tooltip placement="bottom" content={val}>
          {link}
        </Tooltip>
      );
    }
    return link;
  }

  const isSBLEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.SearchBeyondLimits,
  );

  const sanitizedName = sanitizeFilterName(name, val, isSBLEnabled);

  const tableLink = (
    <TableLink
      onClick={(e) => {
        window.reduxStoreDispatch(
          addFilters(
            [
              {
                type: type as VirtualFilterTypes,
                val: String(sanitizedName ?? val),
              },
            ],
            {
              usePushState: true,
            },
          ),
        );
      }}
    >
      {val}
    </TableLink>
  );

  if (shouldShowTooltip(type, val)) {
    return (
      <Tooltip placement="bottom" content={val}>
        {tableLink}
      </Tooltip>
    );
  }

  return tableLink;
};

export const rangeBarFormatter: RangeBarFormatter = (cell: BarConfig[]) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', width: 90, height: 36 }}
    >
      <div style={{ width: 90, height: 12 }}>
        <RangeBar series={cell || []} asPercent={false} />
      </div>
    </div>
  );
};

export const tooltipFormatter: AccordionTableCellFormatter = (cell) => {
  const { type, val } = getCellConfig(cell);

  if (!type) {
    <Tooltip placement="bottom" content={val}>
      <span>{val}</span>
    </Tooltip>;
  }

  if (shouldShowTooltip(type, val)) {
    return (
      <Tooltip placement="bottom" content={val}>
        <span>{val}</span>
      </Tooltip>
    );
  }

  return val;
};

export const feeAndHoursFromatter = (cell: {
  sortVal: string | number;
  val: {
    fee: string | number;
    hours: string | number;
  };
}) => {
  if (typeof cell.val === 'undefined') return '';

  const hasFeeValue = typeof cell.val.fee === 'number';
  const hasHoursValue = typeof cell.val.hours === 'number';

  const feeFormatted = hasFeeValue
    ? formatAmount(ProjectBudgetType.TotalFee, cell.val.fee)
    : '';

  const hoursFormatted = hasHoursValue
    ? formatAmount(ProjectBudgetType.TotalHours, cell.val.hours)
    : '';

  return (
    <div className={styles.feeAndHoursWrapper}>
      {hasFeeValue && (
        <div className={styles.feeAndHoursCell}>{feeFormatted}</div>
      )}
      {hasHoursValue && (
        <div className={styles.feeAndHoursCell}>{hoursFormatted}</div>
      )}
    </div>
  );
};

export const marginFormatter = (cell: {
  sortVal: number;
  val: {
    fee: string | number;
    cost: string | number;
  };
}) => {
  if (!cell.val) return null;

  const { fee, cost } = cell.val;

  if (typeof fee === 'string' || typeof cost === 'string' || fee === 0)
    return null;

  const marginValue = fee - cost;
  const marginPercent = (fee - cost) / fee;

  const marginPercentFormatted = formatPercentage(marginPercent);
  const marginValueFormatted = formatAmount(
    ProjectBudgetType.TotalFee,
    marginValue,
  );

  return (
    <div className={styles.marginWrapper}>
      <div className={styles.marginCell}>
        <span
          className={cs({
            [styles.marginValuePositive]: marginPercent > 0,
            [styles.marginValueNegative]: marginPercent < 0,
          })}
        >
          {marginPercentFormatted}
        </span>
      </div>
      <div className={styles.marginCell}>{marginValueFormatted}</div>
    </div>
  );
};

const LogTimeEditContainer = styled.div`
  cursor: pointer;
  position: relative;
  min-width: 38px;
  text-align: right;

  svg {
    border-radius: 3px;
    border: 1px solid ${(p) => p.theme.medGrey};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    width: 34px;
    position: absolute;
    right: -5px;
    top: 5px;
    background: white;
    display: none;
  }

  &:hover svg {
    display: block;
  }
`;

function canEditLoggedTime(
  ctx: TeamCapacityTableContext,
  entity: ReportTimeItemData,
) {
  const project = ctx.projects[entity.project_id];
  const readOnly =
    project &&
    `${entity.person_id}` !== `${ctx.user.people_id}` &&
    (!project.active || !isEditableProject(project, ctx.user));
  return !readOnly;
}

export const logTimeEditFormatter =
  (ctx: TeamCapacityTableContext) =>
  (val?: { entity?: ReportTimeItemData; sortVal: string }) => {
    if (typeof val !== 'object') return val;
    if (!val.entity) return val.sortVal;
    if (!canEditLoggedTime(ctx, val.entity)) return val.sortVal;

    return (
      <LogTimeEditContainer
        onClick={() => {
          showLogTimeEditModal(ctx.reduxDispatch, val.entity?.id);
        }}
      >
        {val.sortVal}
        <TextTooltip content="Edit" className="hint">
          <IconPencil color="#C8C8C8" />
        </TextTooltip>
      </LogTimeEditContainer>
    );
  };

export function getLinkifiedValue(value?: string) {
  if (!value) return '';

  return (
    <span
      style={{
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        outline: 'none',
      }}
      key="notes"
    >
      <Linkify
        options={{
          attributes: {
            contentEditable: false,
            tabIndex: -1,
          },
        }}
      >
        {value.replace(/\n/g, ' ')}
      </Linkify>
    </span>
  );
}

export const coloredPercentageFormatter = (cell?: AccordionTableCellValue) => {
  if (typeof cell !== 'string' || typeof cell !== 'number') return '';
  const value = typeof cell === 'string' ? Number(cell) : cell;
  return (
    <span
      className={styles.colorPercentage({
        color: value < 0 ? 'negative' : 'positive',
      })}
    >
      {formatPercentage(value)}
    </span>
  );
};
