import React from 'react';
import { BillableVsNonBillable } from 'reports/components/SummaryBarParts';

import { formatAmount, getPercentage } from '@float/common/lib/budget';
import { LIGHT_PURPLE, MODES, PURPLE } from '@float/ui/deprecated/Chart';
import { getTimeoffHours } from '@float/ui/deprecated/Chart/BarChart/BarChart';

import {
  ItemHeader,
  ItemLabel,
  ItemSubLabel,
  ItemUnit,
  ReportsTotalsCard,
  ReportsTotalsContainer,
  ReportsTotalsItem,
} from '../../styles';

function getOvertime(totals, mode) {
  switch (mode) {
    case MODES.LOGGED:
      return totals.logged.overtime;
    case MODES.COMBINED:
      return totals.combined.overtime;
    default:
      return totals.overtime;
  }
}

function getUnscheduled(totals, mode) {
  switch (mode) {
    case MODES.LOGGED:
      return {
        value: Math.max(0, totals.logged.unscheduled),
        perc: Math.max(
          0,
          getPercentage(totals.logged.unscheduled, totals.capacity),
        ),
      };
    case MODES.COMBINED:
      return {
        value: Math.max(0, totals.combined.unscheduled),
        perc: Math.max(
          0,
          getPercentage(totals.combined.unscheduled, totals.capacity),
        ),
      };
    default:
      return {
        value: Math.max(0, totals.unscheduled),
        perc: Math.max(0, getPercentage(totals.unscheduled, totals.capacity)),
      };
  }
}

function SummaryBar({ totals, mode, minWidth }) {
  const loggedTotal = totals.logged.total;
  const loggedPerc = getPercentage(totals.logged.total, totals.capacity);
  const loggedBillable = totals.logged.billable;
  const loggedBillablePerc = getPercentage(
    totals.logged.billable,
    totals.logged.total,
  );
  const loggedNonBillable = totals.logged.nonbillable;
  const loggedNonBillablePerc = getPercentage(
    totals.logged.nonbillable,
    totals.logged.total,
  );

  const scheduledTotal = totals.scheduled;
  const scheduledPerc = getPercentage(totals.scheduled, totals.capacity);
  const scheduledBillable = totals.billable;
  const scheduledBillablePerc = getPercentage(
    totals.billable,
    totals.scheduled,
  );
  const scheduledNonBillable = totals.nonbillable;
  const scheduledNonBillablePerc = getPercentage(
    totals.nonbillable,
    totals.scheduled,
  );
  const scheduledTentative =
    totals.tentative.billable + totals.tentative.nonbillable;
  const scheduledTentativePerc = getPercentage(
    totals.tentative.billable + totals.tentative.nonbillable,
    totals.scheduled,
  );

  const combinedTotal = totals.combined.total;
  const combinedPerc = getPercentage(totals.combined.total, totals.capacity);
  const combinedBillable = totals.combined.billable;
  const combinedBillablePerc = getPercentage(
    totals.combined.billable,
    totals.combined.total,
  );
  const combinedNonBillable = totals.combined.nonbillable;
  const combinedNonBillablePerc = getPercentage(
    totals.combined.nonbillable,
    totals.combined.total,
  );
  const futureTentative =
    totals.combined.tentative.billable + totals.combined.tentative.nonbillable;
  const futureTentativePerc = getPercentage(
    totals.combined.tentative.billable + totals.combined.tentative.nonbillable,
    totals.combined.total,
  );

  const overtime = getOvertime(totals, mode);
  const unscheduled = getUnscheduled(totals, mode);

  return (
    <ReportsTotalsContainer style={{ minWidth }}>
      {mode !== MODES.COMPARE && (
        <ReportsTotalsCard flexGrow={1}>
          <ReportsTotalsItem width={'100%'}>
            <ItemLabel>Capacity</ItemLabel>
            <ItemHeader>
              {(totals.capacity || 0).toLocaleString()}
              <ItemUnit>h</ItemUnit>
            </ItemHeader>
          </ReportsTotalsItem>
        </ReportsTotalsCard>
      )}
      {mode === MODES.COMBINED && (
        <BillableVsNonBillable
          combined
          title="Past logged + Future scheduled"
          total={combinedTotal}
          perc={combinedPerc}
          billable={combinedBillable}
          billablePerc={combinedBillablePerc}
          nonBillable={combinedNonBillable}
          nonBillablePerc={combinedNonBillablePerc}
          tentative={futureTentative}
          tentativePerc={futureTentativePerc}
        />
      )}
      {(mode === MODES.LOGGED || mode === MODES.COMPARE) && (
        <BillableVsNonBillable
          title="Logged"
          colors={[PURPLE, LIGHT_PURPLE]}
          total={loggedTotal}
          perc={loggedPerc}
          billable={loggedBillable}
          billablePerc={loggedBillablePerc}
          nonBillable={loggedNonBillable}
          nonBillablePerc={loggedNonBillablePerc}
        />
      )}
      {(mode === MODES.SCHEDULED || mode === MODES.COMPARE) && (
        <BillableVsNonBillable
          title="Scheduled"
          total={scheduledTotal}
          perc={scheduledPerc}
          billable={scheduledBillable}
          billablePerc={scheduledBillablePerc}
          nonBillable={scheduledNonBillable}
          nonBillablePerc={scheduledNonBillablePerc}
          tentative={scheduledTentative}
          tentativePerc={scheduledTentativePerc}
        />
      )}
      {mode === MODES.COMPARE && (
        <ReportsTotalsCard flexGrow={1}>
          <ReportsTotalsItem width={'100%'}>
            <ItemLabel>Difference</ItemLabel>
            <ItemHeader color={scheduledTotal - loggedTotal < 0 ? 'red' : null}>
              {formatAmount(null, scheduledTotal - loggedTotal)}
              <ItemUnit>h</ItemUnit>
            </ItemHeader>
          </ReportsTotalsItem>
        </ReportsTotalsCard>
      )}
      {mode !== MODES.COMPARE && (
        <>
          <ReportsTotalsCard flexGrow={1}>
            <ReportsTotalsItem width={'100%'}>
              <ItemLabel>
                {mode === MODES.LOGGED ? 'Unlogged' : 'Unscheduled'}
              </ItemLabel>
              <ItemSubLabel>{unscheduled.perc}%</ItemSubLabel>
              <ItemHeader>
                {unscheduled.value.toLocaleString()}
                <ItemUnit>h</ItemUnit>
              </ItemHeader>
            </ReportsTotalsItem>
          </ReportsTotalsCard>
          <ReportsTotalsCard flexGrow={1}>
            <ReportsTotalsItem width={'100%'}>
              <ItemLabel>Time off</ItemLabel>
              <ItemHeader>
                {getTimeoffHours(
                  totals,
                  mode === MODES.LOGGED,
                ).toLocaleString()}
                <ItemUnit>h</ItemUnit>
              </ItemHeader>
            </ReportsTotalsItem>
          </ReportsTotalsCard>
          <ReportsTotalsCard flexGrow={1}>
            <ReportsTotalsItem width={'100%'}>
              <ItemLabel>Overtime</ItemLabel>
              <ItemHeader>
                {overtime.toLocaleString()}
                <ItemUnit>h</ItemUnit>
              </ItemHeader>
            </ReportsTotalsItem>
          </ReportsTotalsCard>
        </>
      )}
    </ReportsTotalsContainer>
  );
}

export default SummaryBar;
