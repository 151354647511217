import { t } from '@lingui/macro';

import {
  getBillRateLabel,
  isCostRateFeatureActive,
} from '@float/common/lib/rates/rates';
import { email as isValidEmail } from '@float/libs/validate';
import {
  areStringsSame,
  containsAnyOf,
  isNdigitOrLess,
  mapImportHeaderToKeys,
  parseRow,
  refineNumericalValue,
} from '@float/web/importCsv/validateImportRow';
import type { Person } from '@float/types/person';

type KeyValue = Record<string, string>;

type ParsedRow = Record<string, ReturnType<typeof refineValue>>;

const getKeyFromHeader = (key: string) => {
  const lower = key.toLowerCase().trim();
  if (lower.startsWith('name')) {
    return 'name';
  }

  if (lower.startsWith('email')) {
    return 'email';
  }

  if (lower.startsWith('job title') || lower.startsWith('role')) {
    return 'job_title';
  }

  if (lower.startsWith('department')) {
    return 'department';
  }

  if (lower.startsWith('tags')) {
    return 'tags';
  }

  if (lower.includes('cost')) {
    return 'cost_rate';
  }

  if (containsAnyOf(lower, ['hourly', 'rate', 'bill'])) {
    return 'hourly_rate';
  }

  if (lower.startsWith('type')) {
    return 'people_type_name';
  }
  if (lower.startsWith('active')) {
    return 'active';
  }
  if (lower.startsWith('start date')) {
    return 'start_date';
  }
  if (lower.startsWith('end date')) {
    return 'end_date';
  }
  if (lower.startsWith('full time')) {
    return 'employee_type';
  }
  if (lower.startsWith('work days hours')) {
    return 'work_days_hours';
  }
  if (lower.startsWith('public holiday')) {
    return 'location_code';
  }

  return null;
};

const refineValue = (value: string, key: string) => {
  switch (key) {
    case 'tags':
      return value
        .split(',')
        .map((x) => x.trim())
        .filter((y) => y);
    case 'email':
      return value ? value.toLowerCase().trim() : null;
    case 'hourly_rate':
    case 'cost_rate':
      return refineNumericalValue(value);
    default:
      return value.trim() || null;
  }
};

const isDuplicate = (row: KeyValue, people: Person[]) => {
  let duplicate = null;
  people.some((x) => {
    if (areStringsSame(row.name, x.name)) {
      duplicate = x.name;
      return true;
    }
    if (row.email && areStringsSame(row.email, x.email)) {
      duplicate = x.email;
      return true;
    }
    return false;
  });
  return duplicate || false;
};

const getRowErrors = (row: KeyValue) => {
  const errors = [];

  if (!row.name) {
    errors.push(t`Please enter a name`);
  }

  if (row.email && !isValidEmail(row.email)) {
    errors.push(t`Please enter a valid email`);
  }

  if (row.hourly_rate && !isNdigitOrLess(row.hourly_rate, 6)) {
    const rateType = getBillRateLabel();
    errors.push(t`${rateType} must be less than 6 numbers`);
  }

  if (row.cost_rate && !isNdigitOrLess(row.cost_rate, 6)) {
    errors.push(t`Cost rate must be less than 6 numbers`);
  }

  return errors;
};

export const mapPeopleHeaderToKeys = (headers: string[]) => {
  return mapImportHeaderToKeys({ headers, getKeyFromHeader });
};

export const validatePeopleRow = (
  row: KeyValue,
  { keyMap, people }: { keyMap: KeyValue; people: Person[] },
) => {
  const data: ParsedRow = parseRow({ row, keyMap, refineValue });
  const errors = getRowErrors(data);
  const duplicateRef = isDuplicate(data, people);
  return { data, errors, duplicateRef };
};

export function getWarningText() {
  if (isCostRateFeatureActive()) {
    return t`This will update the attributes based on your CSV. Updated bill rates won’t affect existing projects. Updated cost rates will impact project profitability from the effective date.`;
  }

  return t`This will update the attributes based on your CSV. It won’t affect their existing projects.`;
}
