import React, { useCallback } from 'react';

import { useTaskMetasPrefetch } from '@float/common/lib/hooks/useTaskMetasPrefetch';
import { ScheduleRow } from '@float/types/rows';

import { ScheduleActions } from '../MainCell/types';

export const useMainCellMouseHandlers = function useMainCellMouseHandlers(
  actions: ScheduleActions,
  row: ScheduleRow,
  colIdx: number,
  rowGroupTop: number,
  rowIdx: number,
  scrollWrapperRef: React.MutableRefObject<HTMLElement | undefined>,
  wrapperRef: React.RefObject<HTMLDivElement | undefined>,
) {
  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!scrollWrapperRef.current) return;
      if (!wrapperRef.current) return;

      const { clientX, clientY } = e;
      const { offsetLeft } = wrapperRef.current;
      const offsetParent = wrapperRef.current
        .offsetParent as HTMLElement | null;
      const cellY =
        clientY -
        scrollWrapperRef.current.offsetTop -
        (rowGroupTop - scrollWrapperRef.current.scrollTop);

      const rawY = Number(offsetParent?.dataset.translateY) + cellY;
      const parentOffsetTop = offsetParent?.offsetTop ?? 0;

      actions.trackMouse(
        colIdx,
        rowIdx,
        clientX - offsetLeft + scrollWrapperRef.current.scrollLeft,
        clientY - parentOffsetTop,
        cellY,
        offsetLeft,
        rawY,
        clientX,
        clientY,
      );
    },
    [actions, colIdx, rowGroupTop, rowIdx, wrapperRef, scrollWrapperRef],
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.button !== 0) return;

      const tagName = (e?.target as HTMLElement).tagName;

      if (tagName === 'BUTTON' || tagName === 'INPUT') {
        return;
      }
      actions.onCellMouseDown();
    },
    [actions],
  );

  const { prefetchTaskMetas } = useTaskMetasPrefetch();

  const handleMouseEnter = () => {
    if (row.type === 'project') {
      prefetchTaskMetas(row.data.project_id);
    }
  };

  return { handleMouseDown, handleMouseEnter, handleMouseMove };
};
