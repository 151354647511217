import {
  forEach,
  isArray,
  isUndefined,
  map,
  mapValues,
  omitBy,
  reduce,
} from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { fastObjectSpread } from '@float/common/lib/fast-object-spread';
import { Phase, Project, ProjectStatus, RawPhase, RawTask } from '@float/types';

import * as actions from '../actions';
import { formatAmount } from '../lib/budget/helpers/formatAmount';
import { BudgetsLoadFinishActionType } from '../store/budgets/budgets.actions';
import { LoadState, RehydratePartialStateAction } from './lib/types';
import { getStatus } from './projects';

export type PhasesState = {
  phases: Record<number, Phase>;
  loadState: LoadState;
  phasesLoaded: boolean;
  archivedPhasesLoaded?: boolean;
};

export type PhaseAction =
  | { type: typeof actions.PHASES_LOAD_START }
  | {
      type: typeof actions.PHASES_LOAD_FINISH;
      phases?: RawPhase[] | null;
      archivedPhases?: RawPhase[] | null;
    }
  | { type: typeof actions.PHASES_LOAD_FAILED }
  | { type: typeof actions.PHASES_UPDATED; phases: RawPhase[] }
  | { type: typeof actions.PHASES_DELETED; phaseIds: number[] }
  | { type: typeof actions.PHASES_BULK_UPDATED; result: RawPhase[] }
  | {
      type: typeof actions.SHIFT_PHASE_TIMELINE;
      newPhase: Phase;
      originalEntity?: Phase;
    };

type ActionsFromProject =
  | BudgetsLoadFinishActionType
  | actions.ProjectsBulkUpdatedAction
  | {
      type: typeof actions.PROJECTS_UPDATED;
      project?: Project;
      prevProject?: Project;
    }
  | {
      type: typeof actions.PROJECTS_DELETED;
      projectId: number;
    }
  | {
      type: typeof actions.PROJECTS_BULK_DELETED;
      ids?: number[];
    };

type ActionsFromTask = {
  type:
    | typeof actions.CREATE_TASK_SUCCESS
    | typeof actions.UPDATE_TASK
    | typeof actions.REPLACE_TASK_SUCCESS
    | typeof actions.INSERT_TASK_SUCCESS;
  item: RawTask;
};

type ActionsFromLoggedTime = {
  type: typeof actions.LOGGED_TIME_CREATED;
  loggedTime: {
    project_id: number;
    people_id: number;
    phase_id: string;
  };
};

const REDUCER_NAME = 'phases';
export const DEFAULT_STATE: PhasesState = {
  phases: {},
  loadState: LoadState.UNLOADED,
  phasesLoaded: false,
  archivedPhasesLoaded: false,
};

const mapPhase = (p: RawPhase) => {
  const phase: Partial<Phase> = {
    phase_id: p.phase_id,
    project_id: p.project_id,
    phase_name: p.name || p.phase_name,
    active: +p.active === 1,
    notes: p.notes,
    notes_meta: p.notes_meta,
    start_date: p.start_date,
    end_date: p.end_date,
    calculated_end_date: p.calculated_end_date,
    calculated_start_date: p.calculated_start_date,
    created: p.created,
    modified: p.modified,
    budget_type: p.budget_type,
    budget_total: formatAmount(null, p.budget_total || p.budget),
    default_hourly_rate: formatAmount(null, p.default_hourly_rate),
    status: getStatus(p),
  };

  if (!isUndefined(p.people_ids)) phase.people_ids = p.people_ids;
  if (!isUndefined(p.phase_team)) {
    phase.people_ids = map(p.phase_team, (pt) => Number(pt.people_id));
    phase.people_rates = reduce(
      p.phase_team,
      (acc, pt) => {
        if (pt.people_id) {
          acc[pt.people_id] = formatAmount(null, pt.hourly_rate);
        }
        return acc;
      },
      {} as Record<number, string | null>,
    );
  }

  // Attributes not present in the all endpoint. Define them conditionally so
  // that the merge ignores them if necessary.
  if (!isUndefined(p.color)) phase.color = p.color;
  if (!isUndefined(p.tentative)) phase.tentative = p.tentative === 1;
  if (!isUndefined(p.non_billable)) phase.non_billable = p.non_billable === 1;

  return phase;
};

const mergePhases = (a: Phase, b: RawPhase) => {
  if (!b) return a;

  const newPhase = mapPhase(b);
  let mergedPhase = newPhase;

  if (a) {
    mergedPhase = fastObjectSpread(a, newPhase);
  }

  if (isUndefined(mergedPhase.people_ids)) mergedPhase.people_ids = [];

  return mergedPhase as Phase;
};

const addPersonToPhase = (
  state: PhasesState,
  action: { phaseId: number; personId?: number; peopleIds?: number[] },
) => {
  const { phaseId, personId } = action;
  const peopleIds = action.peopleIds || [];
  if (personId && !peopleIds.includes(personId)) {
    peopleIds.push(personId);
  }

  const phase = state.phases[phaseId] || { people_ids: [] };
  if (!phase || !phase.people_ids) {
    return state;
  }

  const addedPeopleIds: number[] = [];

  peopleIds.forEach((persId) => {
    if (!phase.people_ids.includes(persId)) {
      addedPeopleIds.push(persId);
    }
  });

  if (!addedPeopleIds.length) {
    return state;
  }

  return {
    ...state,
    phases: {
      ...state.phases,
      [phaseId]: {
        ...phase,
        people_ids: [...phase.people_ids, ...addedPeopleIds],
      },
    },
  };
};

export const phases = (
  state = DEFAULT_STATE,
  action:
    | PhaseAction
    | ActionsFromProject
    | ActionsFromTask
    | ActionsFromLoggedTime
    | RehydratePartialStateAction<PhasesState, typeof REDUCER_NAME>,
): PhasesState => {
  switch (action.type) {
    case actions.PHASES_LOAD_START: {
      return {
        ...state,
        loadState: LoadState.LOADING,
      };
    }

    case actions.PHASES_LOAD_FINISH: {
      const newPhases = fastObjectSpread(state.phases);

      if (action.phases) {
        for (const p of action.phases) {
          newPhases[p.phase_id] = mergePhases(state.phases[p.phase_id], p);
        }
      }

      if (action.archivedPhases) {
        for (const p of action.archivedPhases) {
          newPhases[p.phase_id] = mergePhases(state.phases[p.phase_id], p);
        }
      }

      const archivedPhasesLoaded = Boolean(
        action.archivedPhases || state.archivedPhasesLoaded,
      );

      return {
        ...state,
        loadState: LoadState.LOADED,
        phasesLoaded: true,
        archivedPhasesLoaded,
        phases: newPhases,
      };
    }

    case actions.PHASES_UPDATED: {
      const newPhases = { ...state.phases };

      forEach(action.phases, (p) => {
        newPhases[p.phase_id] = mergePhases(state.phases[p.phase_id], p);
      });

      return {
        ...state,
        phases: newPhases,
      };
    }

    case actions.PHASES_DELETED: {
      const newPhases = { ...state.phases };

      forEach(action.phaseIds, (id) => {
        delete newPhases[id];
      });

      return {
        ...state,
        phases: newPhases,
      };
    }

    case actions.PHASES_LOAD_FAILED: {
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
      };
    }

    case actions.PHASES_BULK_UPDATED: {
      const { result } = action;
      if (!result || !result.length) return state;

      const newPhases = { ...state.phases };
      forEach(result, (p) => {
        newPhases[p.phase_id] = mergePhases(state.phases[p.phase_id], p);
      });

      return {
        ...state,
        phases: newPhases,
      };
    }

    case actions.PROJECTS_BULK_UPDATED: {
      if (isUndefined(action.fields.active)) return state;
      const ids = action.ids.map(String);

      return {
        ...state,
        phases: mapValues(state.phases, (phase) => {
          if (ids.includes(String(phase.project_id))) {
            return { ...phase, active: action.fields.active };
          }
          return phase;
        }),
      };
    }

    case actions.PROJECTS_UPDATED: {
      if (!action.prevProject || !action.project) return state;

      let active: boolean | null = null;
      if (action.project.active && !action.prevProject.active) {
        active = true;
      } else if (!action.project.active) {
        active = false;
      }

      let tentative: boolean | null = null;
      let status: ProjectStatus | null = null;

      const newStatus = action.project.status;
      const isStatusFieldAvailable = newStatus !== undefined;

      // The new tentative value comes down as an integer
      if (
        Boolean(action.project.tentative) !==
        Boolean(action.prevProject.tentative)
      ) {
        tentative = Boolean(action.project.tentative);

        if (isStatusFieldAvailable === false) {
          // Derive `status` from `tentative` field
          status = tentative
            ? ProjectStatus.Tentative
            : ProjectStatus.Confirmed;
        }
      }

      if (isStatusFieldAvailable && newStatus !== action.prevProject.status) {
        status = action.project.status;
      }

      if (active === null && tentative === null && status === null)
        return state;

      return {
        ...state,
        phases: mapValues(state.phases, (phase) => {
          if (phase.project_id != action.project?.project_id) return phase;
          return {
            ...phase,
            ...(active !== null && { active }),
            ...(tentative !== null && { tentative }),
            ...(status !== null && { status }),
          };
        }),
      };
    }

    case actions.PROJECTS_DELETED: {
      return {
        ...state,
        phases: omitBy(
          state.phases,
          (p) => String(p.project_id) === String(action.projectId),
        ),
      };
    }

    case actions.PROJECTS_BULK_DELETED: {
      if (!action.ids?.length) return state;

      const ids = action.ids?.map(String);

      return {
        ...state,
        phases: omitBy(state.phases, (p) => ids.includes(String(p.project_id))),
      };
    }

    case actions.CREATE_TASK_SUCCESS:
    case actions.UPDATE_TASK:
    case actions.REPLACE_TASK_SUCCESS:
    case actions.INSERT_TASK_SUCCESS: {
      const { item } = action;
      if (!item) return state;

      const phaseId = +(item.phase_id || 0);

      if (phaseId === 0) {
        // Task NOT created for a specific phase,
        // no need to add person to any phase team.
        return state;
      }

      const { people_id: personId, people_ids: peopleIds } = item;

      return addPersonToPhase(state, { phaseId, personId, peopleIds });
    }

    case actions.LOGGED_TIME_CREATED: {
      let item = action.loggedTime;
      if (!item) return state;

      // Logged time created
      if (isArray(item)) item = item[0];

      const phaseId = +item.phase_id;

      if (phaseId === 0) {
        // Task NOT created for a specific phase,
        // no need to add person to any phase team.
        return state;
      }

      return addPersonToPhase(state, { phaseId, personId: item.people_id });
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.phasesLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
};

export default phases;
