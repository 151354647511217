export type ActivityFeedDateRange = {
  start: DateString;
  end: DateString;
};

export type ActivityFeedFilters = {
  project: string;
  person: string;
  action: string;
  actionedBy: string;
  itemType: string;
};

const filterLabel = {
  project: 'Project',
  person: 'Person',
  action: 'Action',
  actionedBy: 'Actioned by',
  itemType: 'Type',
};

export const EMPTY_FILTER = 'all';

export function getEmptyFilters(): ActivityFeedFilters {
  return {
    project: EMPTY_FILTER,
    person: EMPTY_FILTER,
    action: EMPTY_FILTER,
    actionedBy: EMPTY_FILTER,
    itemType: EMPTY_FILTER,
  };
}

export function getFiltersOnPageLoad(
  isFilterFeatureEnabled: boolean,
): ActivityFeedFilters {
  if (!isFilterFeatureEnabled) return getEmptyFilters();

  return getFiltersFromURLParams();
}

export function getFiltersFromURLParams(): ActivityFeedFilters {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    project: queryParams.get('project') || EMPTY_FILTER,
    person: queryParams.get('person') || EMPTY_FILTER,
    action: queryParams.get('action') || EMPTY_FILTER,
    actionedBy: queryParams.get('actor') || EMPTY_FILTER,
    itemType: queryParams.get('type') || EMPTY_FILTER,
  };
}

export function getFiltersDisplayValues(filters: ActivityFeedFilters) {
  const keys = Object.keys(filters) as Array<keyof ActivityFeedFilters>;

  return keys.reduce((acc, key) => {
    if (filters[key] === EMPTY_FILTER) return acc;

    const label = filterLabel[key];
    if (!label) return acc;

    return { ...acc, [label]: filters[key] };
  }, {});
}

export function getFiltersForApiQuery(
  rangeFilter: ActivityFeedDateRange,
  filters: ActivityFeedFilters,
  areFiltersEnabled: boolean,
) {
  const result: Record<string, string> = {
    start: rangeFilter.start,
    end: rangeFilter.end,
  };

  if (!areFiltersEnabled) return result;

  if (filters.person !== EMPTY_FILTER) {
    result['people-ids'] = filters.person;
  }

  if (filters.project !== EMPTY_FILTER) {
    result['project-ids'] = filters.project;
  }

  if (filters.action !== EMPTY_FILTER) {
    result['activity-types'] = filters.action;
  }

  if (filters.itemType !== EMPTY_FILTER) {
    result['item-types'] = filters.itemType;
  }

  if (filters.actionedBy !== EMPTY_FILTER) {
    result['actioned-by-ids'] = filters.actionedBy;
  }

  return result;
}

export function isFilterEmpty(val: string) {
  return !val || val === EMPTY_FILTER;
}

export function areAllFiltersEmpty(filters: ActivityFeedFilters) {
  return Object.values(filters).every(isFilterEmpty);
}

export function getFilterLabel(key: keyof ActivityFeedFilters) {
  return filterLabel[key] ?? key;
}
