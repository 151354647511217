import { NumberFormatter } from '@internationalized/number';
import { isNil, memoize } from 'lodash';

import { formatCurrency } from '@float/common/lib/currency';
import { config } from '@float/libs/config';
import { DO_NOT_USE_getGlobalPageData } from '@float/libs/unsafeGlobalData';

import { formatToDbString } from './formatToDbString';

type Amount = string | number | null | undefined;

/**
 * @deprecated - can be removed when `formatAmountDEPRECATED` is removed
 * @test-export
 */
export const getMemoKey = (
  budgetType: number | string | null | undefined,
  amount: Amount,
  {
    inLocale,
    locale,
    currency,
  }: { inLocale: boolean; locale: string; currency: string | null | undefined },
) => {
  return `${budgetType}_${amount}_${inLocale}_${locale}_${currency}`;
};

const format = (
  budgetType: number | string | null | undefined,
  amount: Amount,
  {
    inLocale,
    locale,
    currency,
  }: { inLocale: boolean; locale: string; currency: string | null | undefined },
) => {
  if (isNil(amount)) return null;

  if (inLocale) {
    amount = formatToDbString(String(amount));
  }

  amount = Number(amount);

  if (budgetType === null || budgetType === 0) {
    const nf = new NumberFormatter(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return nf.format(amount);
  }

  if (budgetType === 1) {
    const nf = new NumberFormatter(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return `${nf.format(amount)}h`;
  }

  if (budgetType === 2 || budgetType === 3) {
    return formatCurrency(amount, locale, currency ?? undefined);
  }

  throw Error(`Unknown budget type ${budgetType}`);
};

const memoizedFormat = memoize(format, getMemoKey);

/**
 * @deprecated
 * Use `formatAmount` from `@float/common/lib/budget/helpers/formatAmount` instead, as
 * formatAmountDEPRECATED relies on unsafe window data
 */
export const formatAmountDEPRECATED = (
  budgetType: number | string | null | undefined = 0,
  amount: Amount,
  {
    inLocale = false,
    locale = config.locale,
    currency = DO_NOT_USE_getGlobalPageData().currency,
  } = {},
) => memoizedFormat(budgetType, amount, { inLocale, locale, currency });

export const formatAmount = (
  budgetType: number | string | null | undefined = 0,
  amount: Amount,
  {
    inLocale = false,
    locale = config.locale,
    currency = null,
  }: { inLocale?: boolean; locale?: string; currency?: string | null } = {},
) => format(budgetType, amount, { inLocale, locale, currency });
