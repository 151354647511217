import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { isNil } from 'lodash';

import { getExpandedInfo } from '@float/common/lib/budget';
import { searchService } from '@float/common/search/service';
import { getUser } from '@float/common/selectors/currentUser';
import { useIsSingleProjectPlanView } from '@float/common/serena/util/getIsSingleProjectPlanView';
import {
  useAppSelectorStrict,
  useAppSelectorWithParams,
  useAppStoreStrict,
} from '@float/common/store';
import { selectProjectBudget } from '@float/common/store/budgets/budgets.selectors';
import { useEventListenerOnElement } from '@float/libs/hooks/useEventListenerOnElement';
import {
  prevent,
  preventDefaultAndStopPropagation,
} from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { BudgetType, ProjectStatus } from '@float/types';
import { DownSmall as IconDownSmall } from '@float/ui/deprecated/Icons/iconChevronDownSmall';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { useDoubleClick } from '@float/ui/hooks/useDoubleClick';
import { IconCalendarTimer } from '@float/ui/icons/essentials/IconCalendarTimer';
import { IconCopy } from '@float/ui/icons/essentials/IconCopy';
import { IconDottedCircleLeft } from '@float/ui/icons/essentials/IconDottedCircleLeft';
import { IconDownload } from '@float/ui/icons/essentials/IconDownload';
import { IconPencil } from '@float/ui/icons/essentials/IconPencil';
import { IconProjectDraft } from '@float/ui/icons/essentials/IconProjectDraft';
import { IconReport } from '@float/ui/icons/essentials/IconReport';
import { IconSortDescending } from '@float/ui/icons/essentials/IconSortDescending';
import { IconUserPlus } from '@float/ui/icons/essentials/IconUserPlus';
import type { ProjectRow } from '@float/common/serena/Data/useScheduleRows';
import type { Project } from '@float/types';
import type { BudgetProject } from '@float/types/budget';

import { getIsCompactDensity } from '../../Window/projectRow.helpers';
import { ProjectCardTeam } from './ProjectCardTeam';
import { ProjectCodeAndClient } from './ProjectCodeClient';

import * as styles from './ProjectCard.css';

function getProjectBudgetSimpleText(
  project: Project,
  projectBudgetUsage: BudgetProject | null,
  logTimeView: boolean,
  density: 'default' | 'compact',
) {
  // @ts-expect-error canSeeBudget is added by a selector
  const { canSeeBudget } = project;
  const { budget_type } = project;

  const budgetTotal = projectBudgetUsage?.budget_total || null;
  let budgetRemaining = logTimeView
    ? projectBudgetUsage?.budget_logged_remaining
    : projectBudgetUsage?.budget_remaining;

  if (!budget_type) return null;

  if (budget_type === BudgetType.HourlyFee) {
    return (
      <div className={styles.budgetLine({ density })}>
        <Trans>Hourly fee</Trans>
      </div>
    );
  }

  if (!canSeeBudget) return null;

  budgetRemaining = isNil(budgetRemaining) ? budgetTotal : budgetRemaining;

  const { overBudget, percentage: pct } = getExpandedInfo({
    budget_total: Number(budgetTotal),
    budget_remaining: Number(budgetRemaining),
  });

  const percentText =
    pct === 'NaN' ? `0%` : pct === 'Infinity' ? '' : `${pct}%`;
  const label = overBudget
    ? t`Budget: ${percentText} over`
    : t`Budget: ${percentText} remaining`;

  return (
    <div
      className={styles.budgetLine({
        status: overBudget ? 'overBudget' : 'default',
        density,
      })}
    >
      {label}
    </div>
  );
}

export type ProjectCardProps = {
  actions: {
    showSingleProjectView: (projectId: Project['project_id']) => void;
    showProjectModal: (...args: unknown[]) => void;
    showProjectShiftModal: (...args: unknown[]) => void;
    showProjectDuplicateModal: (...args: unknown[]) => void;
    showEditPhaseModal: (...args: unknown[]) => void;
    updateProject: (...args: unknown[]) => void;
    toggleCollapsedProject: (...args: unknown[]) => void;
    resetProjectPeopleSort: (...args: unknown[]) => void;
    showSidebarMenu: (
      position: React.MutableRefObject<unknown>,
      options: { title: string; action: () => unknown }[],
    ) => void;
  };
  hourHeight: number;
  logTimeView: boolean;
  rowHeight: number;
  row: ProjectRow;
  project: Project;
};

function ProjectCard(props: ProjectCardProps) {
  const { project_id: projectId } = props.project;
  const menuRef = useRef<HTMLDivElement>(null);

  const isSharedLink = useAppSelectorStrict(
    (state) => getUser(state).shared_link_view,
  );
  const prefs = useAppSelectorStrict((state) => getUser(state).prefs);
  const projectBudgetUsage = useAppSelectorWithParams(
    selectProjectBudget,
    projectId,
  );

  const store = useAppStoreStrict();

  const getHasTasksAllocated = useCallback(() => {
    return searchService.getSelectorValue(
      store.getState(),
      'isProjectWithTasks',
      [projectId],
    );
  }, [projectId, store]);

  const {
    showSingleProjectView,
    showProjectModal,
    showProjectShiftModal,
    showProjectDuplicateModal,
    showEditPhaseModal,
    updateProject,
    toggleCollapsedProject,
    resetProjectPeopleSort,
  } = props.actions;
  const areProjectPeopleCustomSorted = Boolean(
    prefs.projview_people_order?.[projectId],
  );
  const { isSingleProjectPlanView: isSingleProjectView } =
    useIsSingleProjectPlanView();
  const positionRef = useRef<SVGSVGElement>(null);

  const history = useHistory();

  const showMenu: EventListener = useCallback(
    async (e) => {
      preventDefaultAndStopPropagation(e);
      const options = [];

      options.push({
        title: 'Edit',
        action: () => {
          showProjectModal(props.project);
        },
        icon: <IconPencil />,
      });

      options.push({
        title: 'Assign people',
        action: () => {
          showProjectModal(props.project, 'team');
        },
        icon: <IconUserPlus />,
      });

      options.push({
        title: 'Add a phase',
        action: () => {
          showEditPhaseModal(props.project);
        },
        icon: <IconDottedCircleLeft />,
      });

      options.push({
        title: 'View project report',
        action: () => {
          history.push(
            `/report?project=${encodeURIComponent(props.project.project_name)}`,
          );
        },
        icon: <IconReport />,
      });

      if (await getHasTasksAllocated()) {
        options.push({
          title: 'Shift timeline',
          action: () => {
            showProjectShiftModal(props.project);
          },
          icon: <IconCalendarTimer />,
        });
      }

      if (areProjectPeopleCustomSorted) {
        options.push({
          title: 'Reset sort',
          action: () => {
            resetProjectPeopleSort(projectId);
          },
          icon: <IconSortDescending />,
        });
      }

      options.push({
        title: 'Duplicate',
        action: () => {
          showProjectDuplicateModal(props.project);
        },
        icon: <IconCopy />,
      });

      options.push({
        title: 'Archive',
        action: () => {
          updateProject(props.project.project_id, { active: 0 });
        },
        icon: <IconDownload />,
      });

      props.actions.showSidebarMenu(positionRef, options);
    },
    [
      getHasTasksAllocated,
      areProjectPeopleCustomSorted,
      props.actions,
      props.project,
      showProjectModal,
      showEditPhaseModal,
      history,
      showProjectShiftModal,
      resetProjectPeopleSort,
      projectId,
      showProjectDuplicateModal,
      updateProject,
    ],
  );

  const {
    project_name,
    project_code,
    color,
    client_name,
    client_id,
    // @ts-expect-error canSeeBudget is added by a selector
    canSeeBudget,
    budget_type,
    canEdit,
  } = props.project;

  const projectColor = color.startsWith('#') ? color : `#${color}`;
  const displayBudgetLine = canSeeBudget && budget_type! > 0;
  const density = getIsCompactDensity(props.hourHeight) ? 'compact' : 'default';
  const isDraft = props.project.status === ProjectStatus.Draft;

  const onClickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    !isSharedLink && showProjectModal(props.project);
  };

  const onDoubleClickHandler = () => {
    !isSharedLink && showSingleProjectView(props.project.project_id);
  };
  const doubleClickRef = useDoubleClick({
    onSingleClick: onClickHandler,
    onDoubleClick: onDoubleClickHandler,
  });

  // using onClick directly on the menu button element does not prevent the propagation
  // to the parent elements event listener which is registered by addEventListener.
  // so we need to do the same on the menu icon to stop the propagation.
  useEventListenerOnElement(menuRef, 'click', showMenu);
  return (
    <div
      className={styles.wrapper}
      data-interactive={!isSharedLink}
      data-testid="project-card"
      ref={doubleClickRef}
    >
      <div
        className={styles.content}
        style={assignInlineVars({
          [styles.contentBgColorVar]: projectColor,
        })}
      >
        <div className={styles.projectName({ density })}>
          <div className={styles.projectNameText}>{project_name}</div>
          {canEdit && (
            <div
              className={styles.menuBtn}
              ref={menuRef}
              data-testid="project-menu"
            >
              <IconDownSmall ref={positionRef} />
            </div>
          )}
        </div>

        <ProjectCodeAndClient
          clientId={client_id}
          clientName={client_name}
          projectCode={project_code}
          density={density}
        />

        {displayBudgetLine && (
          <>
            <Spacer size={2} inline={false} />
            {getProjectBudgetSimpleText(
              props.project,
              projectBudgetUsage,
              props.logTimeView,
              density,
            )}
          </>
        )}
      </div>

      <ProjectCardTeam
        visiblePeople={props.row.numActivePeople}
        totalPeople={props.row.numTotalPeople}
        isCollapsed={!!props.row.isCollapsed}
        isCollapseDisabled={Boolean(isSingleProjectView)}
        isCompact={density === 'compact'}
        isSharedLink={isSharedLink}
        rowHeight={props.rowHeight}
        onAdd={(e) => {
          prevent(e);
          showProjectModal(props.project, 'team');
        }}
        onToggle={(e) => {
          prevent(e);

          // disable collapsing if it's a single project report
          if (isSingleProjectView) {
            return;
          }
          toggleCollapsedProject(projectId);
        }}
      />

      {isDraft && <IconProjectDraft className={styles.draftIcon} />}
    </div>
  );
}

export default React.memo(ProjectCard);
