import React, { forwardRef, useCallback } from 'react';
import cn from 'classnames';
import type { ComponentPropsWithRef } from 'react';

import {
  SEARCH_CATEGORY_EXCLUDING_SAVED_SEARCHES_TO_FILTER_TYPE_MAPPING,
  SearchAutocompleteCategory,
} from '@float/constants/search';
import { IconArrowLeft } from '@float/ui/icons/essentials/IconArrowLeft';
import type { FilterOperators } from '@float/types/filters';

import { ActivateCategory } from '../../types';
import { SearchFilterCategory } from './components/SearchFilterCategory';
import { SearchFilterToggleIsNot } from './components/SearchFilterToggleIsNot';

import * as styles from './styles.css';

type SearchFilterBreadcrumbProps = {
  onClick: ActivateCategory;
  onOperatorChange: (operator?: FilterOperators | '') => void;
  operator: FilterOperators | '';
  type: SearchAutocompleteCategory | undefined;
  value: string | string[];
} & Omit<ComponentPropsWithRef<'div'>, 'onClick'>;

const SearchFilterBreadcrumb = forwardRef<
  HTMLDivElement,
  SearchFilterBreadcrumbProps
>((props, ref) => {
  const { onClick, type, operator, onOperatorChange, className, value } = props;

  const handleSettingsNavClick = useCallback(() => {
    onClick(type);
  }, [onClick, type]);

  return (
    <div className={cn(styles.searchFilterBreadcrumb, className)} ref={ref}>
      <button
        className={styles.button}
        onClick={handleSettingsNavClick}
        type="button"
      >
        <IconArrowLeft size={20} />
      </button>

      <SearchFilterCategory
        className={styles.category}
        onClick={onClick}
        type={type}
      />

      {type && type !== 'savedSearches' && (
        <SearchFilterToggleIsNot
          operator={operator}
          onChange={onOperatorChange}
          type={
            SEARCH_CATEGORY_EXCLUDING_SAVED_SEARCHES_TO_FILTER_TYPE_MAPPING[
              type
            ]
          }
          value={value}
        />
      )}
    </div>
  );
});

export { SearchFilterBreadcrumb };
