import { CurrentUser } from '@float/types/account';
import { BudgetType } from '@float/types/project';

import { getCanCurrentUserSeeBudgets } from './getCanCurrentUserSeeBudgets';
import { getDoesBudgetUseFees } from './getDoesBudgetUseFees';

export const getCanCurrentUserSeeFeesForProject = (
  user: CurrentUser,
  budget_type?: BudgetType | null,
) => {
  const budgetUsesFees = getDoesBudgetUseFees(budget_type);
  const canSeeBudget = getCanCurrentUserSeeBudgets(user);
  return canSeeBudget && budgetUsesFees;
};
