import { Project } from '@float/types/project';

export const getProjectPeopleIds = (
  project: Project | undefined,
  search: { projectAllPeople: Record<number, Set<number>> },
): Set<number> => {
  if (!project) return new Set();

  const ids = search.projectAllPeople[project.project_id];
  if (ids?.size) {
    return ids;
  }

  return new Set(project.people_ids || []);
};

export const getProjectPhaseIds = (
  project: Project | undefined,
  search: { projectPhaseIds: Record<number, Set<number>> },
): Set<number> => {
  if (!project) return new Set();

  const ids = search.projectPhaseIds[project.project_id];
  if (ids?.size) {
    return ids;
  }

  return new Set();
};

export const getProjectTaskIds = (
  project: Project | undefined,
  search: { projectTaskIds: Record<number, Set<string>> },
): Set<string> => {
  if (!project) return new Set();

  const ids = search.projectTaskIds[project.project_id];
  if (ids?.size) {
    return ids;
  }

  return new Set();
};
