import { keyBy, omit } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { Client, RawClient } from '@float/types/client';

import {
  ADD_CLIENT_SUCCESS,
  CLIENT_LOAD_FAILED,
  CLIENT_LOAD_FINISH,
  CLIENT_LOAD_START,
  CLIENTS_BULK_UPDATED,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from '../actions/clients';
import { LoadState, RehydratePartialStateAction } from './lib/types';

const REDUCER_NAME = 'clients';
const DEFAULT_STATE: ClientsState = {
  clients: {},
  loadState: LoadState.UNLOADED,
  clientsLoaded: false,
};

export type ClientsState = {
  clients: Record<Client['client_id'], Client>;
  loadState: LoadState;
  clientsLoaded: boolean;
};

export type ClientsAction =
  | {
      type: typeof CLIENT_LOAD_START;
    }
  | {
      type: typeof CLIENT_LOAD_FAILED;
    }
  | {
      type: typeof CLIENT_LOAD_FINISH;
      clients: RawClient[];
    }
  | {
      type: typeof ADD_CLIENT_SUCCESS | typeof UPDATE_CLIENT_SUCCESS;
      payload: {
        client_id: RawClient['client_id'];
        name: RawClient['name'];
      };
    }
  | {
      type: typeof DELETE_CLIENT_SUCCESS;
      id: RawClient['client_id'];
    }
  | {
      type: typeof CLIENTS_BULK_UPDATED;
      result: Client[];
    };

export const clientsReducer = (
  state = DEFAULT_STATE,
  action:
    | ClientsAction
    | RehydratePartialStateAction<ClientsState, typeof REDUCER_NAME>,
): ClientsState => {
  switch (action.type) {
    case CLIENT_LOAD_START: {
      return {
        ...state,
        loadState: LoadState.LOADING,
      };
    }
    case CLIENT_LOAD_FAILED: {
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
      };
    }
    case CLIENT_LOAD_FINISH: {
      const { clients = [] } = action;
      const newClients: Record<Client['client_id'], Client> = {};

      clients.forEach((c) => {
        newClients[c.client_id] = Object.assign({}, clients[c.client_id], {
          client_id: c.client_id,
          client_name: c.name,
        });
      });

      return {
        ...state,
        loadState: LoadState.LOADED,
        clientsLoaded: true,
        clients: newClients,
      };
    }

    case ADD_CLIENT_SUCCESS:
    case UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload.client_id]: {
            client_id: action.payload.client_id,
            client_name: action.payload.name,
          },
        },
      };
    }

    case DELETE_CLIENT_SUCCESS: {
      const { id } = action;
      if (!id) {
        return state;
      }
      return {
        ...state,
        clients: omit(state.clients, id),
      };
    }

    case CLIENTS_BULK_UPDATED: {
      const { result } = action;
      if (!result || !result.length) {
        return state;
      }

      const newClients = keyBy(
        result.map((c) => ({
          client_id: +c.client_id,
          client_name: c.client_name,
        })),
        'client_id',
      );

      return {
        ...state,
        clients: {
          ...state.clients,
          ...newClients,
        },
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.clientsLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
};

export default clientsReducer;
