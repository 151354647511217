import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, set, without } from 'lodash';
import { bindActionCreators } from 'redux';

import {
  updateCurrentUserInfo,
  updateUserPref,
} from '@float/common/actions/currentUser';
import {
  ensurePeopleLoaded,
  removeAvatar,
  uploadAvatar,
} from '@float/common/actions/people';
import { timeTrackingEnabled } from '@float/common/lib/acl';
import { userCanUpdateThemself } from '@float/common/lib/rights';
import { TIME_FORMAT, TIME_FORMAT_MAP } from '@float/common/lib/time';
import { getUser } from '@float/common/selectors/currentUser';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { getErrors } from '@float/ui/deprecated/helpers/forms';
import { Modal } from '@float/ui/deprecated/Modal';
import { formatDomains } from '@float/web/manage/people-v2/_helpers';
import modalManagerHoc from '@float/web/modalManager/modalManagerHoc';

import { InfoFragment } from './InfoFragment';
import MyInfoModalHeader from './MyInfoModalHeader';

const myInfoModalHeaderStyle = {
  paddingBottom: 0,
  paddingRight: 32,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-end',
};

class MyInfoModal extends React.Component {
  constructor(props) {
    super(props);

    let timeFormatUsesCompany = false;
    let { time_format_24h, duration_display_format } = props.currentUser.prefs;
    if (!time_format_24h) {
      timeFormatUsesCompany = true;
      time_format_24h = +props.currentUser.time_format_24h + 1;
    }

    if (!duration_display_format) {
      duration_display_format = {
        value: TIME_FORMAT.decimal,
        label: TIME_FORMAT_MAP[TIME_FORMAT.decimal],
      };
    }

    const { tab = 'info' } = this.props.modalSettings || {};
    const activeTab = ['info', 'integrations'].includes(tab) ? tab : 'info';

    this.state = {
      timeFormatUsesCompany,
      newAccount: {
        name: props.currentUser.name || '',
        email: props.currentUser.email,
        old_password: '',
        new_password: '',
        password_confirm: '',
        duration_display_format: duration_display_format,
        time_format_24h,
      },
      errors: {},
      isSubmitting: false,
      activeTab,
      passwordFieldsetOpen: false,
      sensitiveDataChanged: false,
      resetPassword: {
        sending: false,
        sent: false,
        succeeded: null,
        message: '',
      },
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    const { ...defaultValues } = this.state.newAccount;
    this.defaultValues = defaultValues;

    setTimeout(() => {
      if (this.email && this.email.input) {
        this.email.input.focus();
      }
    }, 200);
  }

  componentDidUpdate(prevProps, prevState) {
    const fields = ['name', 'email'];

    if (this.didAnyValueChange(fields) && !this.state.sensitiveDataChanged) {
      this.setState({ sensitiveDataChanged: true });
    } else if (
      !this.didAnyValueChange(fields) &&
      this.state.sensitiveDataChanged
    ) {
      this.setState({ sensitiveDataChanged: false });
    }

    // toggle
    if (
      this.passwordFieldsetRef &&
      (this.state.sensitiveDataChanged !== prevState.sensitiveDataChanged ||
        this.state.passwordFieldsetOpen !== prevState.passwordFieldsetOpen)
    ) {
      let height = 0;

      if (this.state.sensitiveDataChanged) {
        height =
          this.passwordFieldsetRef.scrollHeight -
          this.newPasswordRef.offsetHeight;
      }

      if (this.state.passwordFieldsetOpen) {
        height = this.passwordFieldsetRef.scrollHeight + 10;
      }

      this.passwordFieldsetRef.style.setProperty('--max-height', height + 'px');
      this.passwordFieldsetRef.scrollTop = 0;
    }

    // if there are errors move focus to first error's input
    const errors = Object.keys(this.state.errors);
    if (errors.length > 0) {
      const firstError = errors.find(
        (error) => this.state.errors[error].length > 0,
      );
      const inputEl = this.formRef.querySelector(`[data-name="${firstError}"]`);
      if (inputEl)
        setTimeout(() => {
          inputEl.focus();
        }, 200);
    }
  }

  componentWillUnmount() {
    this.defaultValues = null;
    this.personAvatar = null;
    this.email = null;
    this.passwordFieldsetRef = null;
    this.currentPasswordRef = null;
  }

  hide = (evt) => {
    prevent(evt);

    this.props.manageModal({
      visible: false,
      modalType: 'myInfoModal',
    });
  };

  sendPassResetEmail = async (evt) => {
    prevent(evt);

    this.setState({
      resetPassword: { ...this.state.resetPassword, sending: true },
    });

    let res;

    try {
      this.props.actions.ensurePeopleLoaded(true);

      res = await this.props.actions.updateCurrentUserInfo(
        this.props.currentUser.admin_id,
        {
          reset_password_by_email: 1,
          name: this.props.currentUser.name,
          email: this.props.currentUser.email,
        },
      );
    } catch (e) {
      res = e;
    }

    this.setState({
      resetPassword: {
        sent: true,
        sending: false,
        succeeded: res.status === 'success',
        message: res.message,
      },
    });
  };

  togglePasswordFieldset = (evt, callback) => {
    prevent(evt);

    const { passwordFieldsetOpen } = this.state;

    this.setState({ passwordFieldsetOpen: !passwordFieldsetOpen }, callback);
  };

  didAnyValueChange = (fields, ignore = []) =>
    this.defaultValues &&
    Object.keys(this.defaultValues).some((k) => {
      const isField = fields ? fields.indexOf(k) > -1 : true;
      if (ignore.includes(k)) {
        return false;
      }

      if (
        typeof this.defaultValues[k] === 'string' ||
        this.defaultValues[k] instanceof String
      ) {
        return this.defaultValues[k] !== this.state.newAccount[k] && isField;
      }

      return (
        this.defaultValues[k].value !== this.state.newAccount[k].value &&
        isField
      );
    });

  // ---------------------------------------------------------------------------
  // !!! Save handlers ---------------------------------------------------------
  // ---------------------------------------------------------------------------

  validate = () => {
    const { newAccount, sensitiveDataChanged } = this.state;

    const nameErrors = getErrors.name(newAccount.name);

    const emailErrors =
      newAccount.email !== this.props.currentUser.email
        ? getErrors.email(newAccount.email, {
            currentUserAccount: this.props.currentUser,
            existingAccounts: this.props.accounts,
            existingPeople: this.props.people,
          })
        : [];

    const passwordErrors = getErrors.password(
      newAccount.new_password,
      newAccount.password_confirm,
    );

    const oldPasswordErrors =
      (!this.props.currentUser.sso_email ||
        this.props.currentUser.sso_email.length === 0) &&
      sensitiveDataChanged
        ? getErrors.old_password(newAccount.old_password)
        : [];

    // if error on one of the password fields make sure
    // password fields are expanded
    let passwordFieldsetOpen = this.state.passwordFieldsetOpen;
    if (
      (oldPasswordErrors.length > 0 && !this.state.sensitiveDataChanged) ||
      Object.values(passwordErrors).some((pe) => pe.length > 0)
    ) {
      passwordFieldsetOpen = true;
    }

    this.setState({
      errors: {
        name: nameErrors,
        email: emailErrors,
        old_password: oldPasswordErrors,
        ...passwordErrors,
      },
      passwordFieldsetOpen,
    });

    return (
      isEmpty(nameErrors) &&
      isEmpty(emailErrors) &&
      isEmpty(passwordErrors) &&
      isEmpty(oldPasswordErrors)
    );
  };

  onSaveError = (err) => {
    const { currentUser } = this.props;
    const newState = { isSubmitting: false };
    const errors = err.errors;

    const addFieldError = (field, message) => {
      if (!field) return;

      if (!newState.errors) {
        newState.errors = {};
      }

      if (!newState.errors[field]) {
        newState.errors[field] = [];
      }

      newState.errors[field].push(message);

      // if error on one of the password fields make sure
      // password fields are expanded
      if (
        (field === 'old_password' && !this.state.sensitiveDataChanged) ||
        field === 'new_password' ||
        field === 'password_confirm'
      ) {
        newState.passwordFieldsetOpen = true;
      }
    };

    Object.keys(errors).forEach((field) => {
      if (field === 'new_password') {
        const msg =
          'Make sure it’s at least 8 characters and includes a number.';
        return addFieldError('new_password', errors[field][0] || msg);
      }

      if (
        field === 'old_password' &&
        (!currentUser.sso_email || currentUser.sso_email.length)
      ) {
        const msg = 'Old password is incorrect.';
        return addFieldError('old_password', errors[field][0] || msg);
      }

      const isDomainRestrictedErr =
        field === 'email' && errors[field] === 'Domain not permitted.';
      if (isDomainRestrictedErr) {
        return addFieldError(
          'email',
          `${formatDomains(currentUser.domains)} account required.`,
        );
      }

      addFieldError(field, errors[field][0]);
    });

    this.setState(newState);

    if (!newState.errors) {
      this.props.manageModal({
        visible: true,
        modalType: 'ConfirmModal',
        modalSettings: {
          title: 'Error',
          message:
            'There was an error updating the user. Please refresh the page and try again.',
          confirmLabel: 'Reload',
          onConfirm: () => window.location.reload(),
        },
      });
    }
  };

  save = async (evt) => {
    prevent(evt);

    if (this.validate()) {
      const { newAccount, timeFormatUsesCompany } = this.state;
      const { currentUser } = this.props;
      this.setState({ isSubmitting: true });

      // if none of this values have change skip this backend call
      if (
        this.didAnyValueChange([
          'name',
          'email',
          'old_password',
          'new_password',
        ])
      ) {
        try {
          await this.props.actions.updateCurrentUserInfo(currentUser.admin_id, {
            name: newAccount.name,
            email: newAccount.email,
            old_password: newAccount.old_password,
            new_password: newAccount.new_password,
          });
        } catch (e) {
          this.onSaveError(e);
          return;
        }
      }

      const promises = [this.personAvatar.save({ myInfo: true })];

      if (!timeFormatUsesCompany) {
        promises.push(
          this.props.actions.updateUserPref(
            'time_format_24h',
            newAccount.time_format_24h,
          ),
        );
      }

      if (timeTrackingEnabled(currentUser)) {
        promises.push(
          this.props.actions.updateUserPref(
            'duration_display_format',
            newAccount.duration_display_format,
          ),
        );
      }

      await Promise.all(promises);
      this.hide();
    }
  };

  change = (name, value) => {
    this.setState((ps) => ({
      newAccount: set({ ...ps.newAccount }, name, value),
      errors: without(ps.errors, name),
    }));
  };

  handleChange = (field, value) => {
    let val = value;

    if (field === 'email') {
      val = val.trim();
    }

    this.change(field, val);
  };

  setFormRef = (el) => {
    this.formRef = el;
  };

  setPersonAvatarRef = (el) => {
    this.personAvatar = el;
  };

  setEmailRef = (el) => {
    this.email = el;
  };

  setPasswordFieldsetRef = (el) => {
    this.passwordFieldsetRef = el;
  };

  setCurrentPasswordRef = (el) => {
    this.currentPasswordRef = el;
  };

  setNewPasswordRef = (el) => {
    this.newPasswordRef = el;
  };

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };

  isGuest = () => {
    return !this.props.currentUser.people_id;
  };

  hasScheduleEditRights = () => {
    const { currentUser } = this.props;
    return userCanUpdateThemself(currentUser);
  };

  renderModalBody = () => {
    return InfoFragment.call(this);
  };

  render() {
    return (
      <Modal isOpen onClose={this.hide}>
        <form ref={this.setFormRef} noValidate onSubmit={this.save}>
          <Modal.Header style={myInfoModalHeaderStyle}>
            {MyInfoModalHeader.call(this)}
          </Modal.Header>
          <Modal.Body style={{ paddingBottom: 32 }}>
            {this.renderModalBody()}
          </Modal.Body>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: getUser(state),
  accounts: state.accounts.accounts,
  people: state.people.people,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        uploadAvatar,
        updateCurrentUserInfo,
        removeAvatar,
        updateUserPref,
        ensurePeopleLoaded,
      },
      dispatch,
    ),
  },
});

export default modalManagerHoc({
  Comp: connect(mapStateToProps, mapDispatchToProps)(MyInfoModal),
  modalType: 'myInfoModal',
});
