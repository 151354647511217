import Rollbar from 'rollbar';

import { config } from '@float/libs/config';
import { logger } from '@float/libs/logger';
import { initializeRollbarWebLoggerClient } from '@float/libs/logger/rollbarWebLoggerAdapter';
import { PageData } from '@float/types/pageData';

import supportedBrowsers from '../../constants/supportedBrowsers';

const BUILD_VERSION = process.env.BUILD_VERSION ?? 'unknown';
const BUILD_TIME = process.env.BUILD_TIME ?? 'unknown';

const isDevMode = process.env.NODE_ENV === 'development';

initializeRollbarWebLoggerClient(
  new Rollbar({
    // The `autoInstrument` option hijacks the `console` methods, making it
    // harder to trace the origin of the logs.
    autoInstrument: !config.isDevBuildMode,
  }),
  {
    environment: config.environment,
    // TODO: Rename this parameter to `version`
    gitSha: BUILD_VERSION,
    supportedBrowsers,
    isDevMode,
    onReady: () => {
      logger.log(`g20230726:mono:${BUILD_TIME}:${BUILD_VERSION}`);
    },
  },
);

export const identifyUserToLogger = (user: PageData['user']) => {
  logger.identify({
    id: user.user.admin_id,
    companyId: user.user.cid,
    companyName: user.company_prefs.company_name,
  });
};
