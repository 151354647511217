import { BudgetPhase, BudgetProject } from '@float/types/budget';
import { Phase } from '@float/types/phase';
import { Project } from '@float/types/project';

export const getBudget = (
  budgets: {
    phases: Record<number, BudgetPhase>;
    projects: Record<number, BudgetProject>;
  },
  projectId: number,
  project: Project,
  phase?: Phase,
) => {
  return phase &&
    ((!project?.non_billable && !phase.non_billable) ||
      project?.non_billable) &&
    budgets.phases[phase.phase_id] &&
    typeof budgets.phases[phase.phase_id].budget_total === 'number'
    ? budgets.phases[phase.phase_id]
    : budgets.projects[projectId];
};
