import React from 'react';
import { Plural, t, Trans } from '@lingui/macro';

import { formatRate, getBillRateLabel } from '@float/common/lib/rates/rates';
import { formatDate } from '@float/common/lib/utils';
import { CostRateHistoryPayload } from '@float/types/costRate';
import { Person } from '@float/types/person';
import { getPeopleCountLabel } from '@float/web/components/modals/ModalConfirmRateChange/ModalConfirmRateChange.helpers';
import * as styles from '@float/web/components/modals/ModalConfirmRateChange/ModalConfirmRateChange.styles.css';

export type GetRateChangeConfirmDataProps = {
  ids: number[];
  entities: Record<number, Person>;
  fields: {
    cost_rate?: string | null;
    cost_rate_history?: CostRateHistoryPayload;
    default_hourly_rate?: string | null;
  };
};

function getNumberOfPeopleAffected(props: GetRateChangeConfirmDataProps) {
  const { ids, entities } = props;
  const peopleSelected = ids.map((id) => entities[id]).filter(Boolean);

  return peopleSelected.length;
}

export function getEffectiveDateLabel(payload?: CostRateHistoryPayload) {
  const date = payload?.add?.[0]?.effective_date;
  if (!date) return 'today';

  return formatDate(date, 'dd MMM yyyy');
}

export const getRateChangeConfirmData = ({
  ids,
  entities,
  fields,
}: GetRateChangeConfirmDataProps) => {
  const peopleSelected = ids.map((id) => entities[id]).filter(Boolean);

  const isUsingRoleRate = fields.default_hourly_rate === null;

  const peopleWithRateChange = peopleSelected.filter(
    (person) =>
      formatRate(person.default_hourly_rate) !== fields.default_hourly_rate,
  );

  const numPeopleWithRateChange = peopleWithRateChange.length;
  const hasPeopleWithRateChange = numPeopleWithRateChange > 0;

  // No people with rate change found
  if (!hasPeopleWithRateChange) return null;

  if (isUsingRoleRate) {
    return {
      title: t`Confirm rate change`,
      message: (
        <p>
          <Trans>
            You are changing the custom Person rate for{' '}
            {numPeopleWithRateChange}{' '}
            <Plural
              value={numPeopleWithRateChange}
              one="Person"
              other="People"
            />{' '}
            to the default hourly rate of their current Role.
            <br />
            <br />
            New rates do not override rates set at the Project, or Phase level.
          </Trans>
        </p>
      ),
    };
  }

  return {
    title: t`Confirm rate change`,
    message: (
      <p>
        <Trans>
          You are changing the custom Person rate for {numPeopleWithRateChange}{' '}
          <Plural value={numPeopleWithRateChange} one="Person" other="People" />
          .<br />
          <br />
          New rates do not override rates set at the Project, or Phase level.
        </Trans>
      </p>
    ),
  };
};

export const getBillRateChangeConfirmData = (
  props: GetRateChangeConfirmDataProps,
) => {
  const numPeopleWithRateChange = getNumberOfPeopleAffected(props);
  const hasPeopleWithRateChange = numPeopleWithRateChange > 0;

  // No people with rate change found
  if (!hasPeopleWithRateChange) return null;

  const peopleCountLabel = getPeopleCountLabel(numPeopleWithRateChange);
  const rateType = getBillRateLabel().toLowerCase();

  return {
    twoStepConfirmation: true,
    title: t`Update ${rateType} for ${peopleCountLabel}?`,
    primaryButtonLabel: t`Update ${rateType}`,
    message: (
      <p>
        <Trans>
          This will apply the new custom {rateType} to {peopleCountLabel}.
          <br />
          <br />
          New Person rates will not affect the current Project or Phase rates.
        </Trans>
      </p>
    ),
  };
};

export const getCostRateChangeConfirmData = (
  props: GetRateChangeConfirmDataProps,
) => {
  const numPeopleWithRateChange = getNumberOfPeopleAffected(props);
  const hasPeopleWithRateChange = numPeopleWithRateChange > 0;

  // No people with rate change found
  if (!hasPeopleWithRateChange) return null;

  const peopleCountLabel = getPeopleCountLabel(numPeopleWithRateChange);
  const effectiveDateLabel = getEffectiveDateLabel(
    props.fields.cost_rate_history,
  );

  return {
    twoStepConfirmation: true,
    title: t`Update cost rate for ${peopleCountLabel}?`,
    primaryButtonLabel: t`Update cost rate`,
    message: (
      <p>
        <Trans>
          This will:
          <ul className={styles.list}>
            <li className={styles.listItem}>
              Apply the new custom cost rate to {peopleCountLabel}
            </li>
            <li className={styles.listItem}>
              Take effect from {effectiveDateLabel} in all current Projects and
              any future work
            </li>
          </ul>
          Updating cost rates does not affect bill rates for current Projects.
        </Trans>
      </p>
    ),
  };
};
