import { useCallback, useMemo, useState } from 'react';

import { moment } from '@float/libs/moment';
import { CostRateFormData } from '@float/types/costRate';
import { Input } from '@float/ui/deprecated/Input';

import { formatDateForDB } from '../lib/utils';

const isValid = Input.validators.number(6);

const defaultData: CostRateFormData = {
  cost_rate: '',
  cost_rate_effective_date: formatDateForDB(),
  cost_rate_removed_dates: [],
};

function getValue<K extends keyof CostRateFormData>(
  data: CostRateFormData,
  key: K,
): CostRateFormData[K] {
  return data[key] || defaultData[key];
}

export function useEditCostRate(
  initialData: CostRateFormData,
  onChange: (data: CostRateFormData) => void,
) {
  const data = initialData || defaultData;
  const costRate = getValue(data, 'cost_rate');
  const effectiveDate = getValue(data, 'cost_rate_effective_date');

  const [originalCostRate] = useState(() => Number(costRate));

  const isEffectiveDateShown = Number(costRate) !== originalCostRate;

  const effectiveDateMoment = useMemo(
    () => moment(effectiveDate),
    [effectiveDate],
  );

  const handleCostRateChange = useCallback(
    (cost_rate: string) => {
      if (!isValid(cost_rate)) return;

      onChange({ cost_rate, cost_rate_effective_date: effectiveDate });
    },
    [effectiveDate, onChange],
  );

  const handleEffectiveDateChange = useCallback(
    (value: Moment) => {
      onChange({
        cost_rate: costRate,
        cost_rate_effective_date: formatDateForDB(value.startOf('day')),
      });
    },
    [costRate, onChange],
  );

  return {
    costRate,
    effectiveDate: effectiveDateMoment,
    isEffectiveDateShown,
    handleCostRateChange,
    handleEffectiveDateChange,
  };
}
