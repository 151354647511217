import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Checkbox } from '@float/ui/components/Checkbox';
import { PaginationArrow } from '@float/ui/deprecated/DateRangePicker/DateRangePicker';
import Icons from '@float/ui/deprecated/Icons';
import IconCloseSmall from '@float/ui/deprecated/Icons/icon-close';
import { FieldLabel } from '@float/ui/deprecated/Label';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';

import { DateInputContainer } from './DateInputContainer';
import DateRangePicker from './DateRangePicker';
import InputHandler from './InputHandler';
import KeyHandler from './KeyHandler';

import * as styles from './DatePicker.css';

const isDateInLockPeriod = (date, lockPeriodEndDate) =>
  date <= lockPeriodEndDate;

const CalendarIconContainer = styled.div`
  position: absolute;
  right: 5px;
  bottom: ${({ hasLabel }) => (hasLabel ? '6px' : '3px')};
  pointer-events: none;
  height: 24px;
`;

const ClearIconContainer = styled(CalendarIconContainer)`
  pointer-events: all;
  bottom: 6px;
  cursor: pointer;

  path {
    fill: ${(p) => p.theme.blueGrey};
  }

  &:hover {
    path {
      fill: ${(p) => p.theme.charcoalGrey};
    }
  }
`;

const CalendarIcon = function ({ hasLabel }) {
  return (
    <CalendarIconContainer hasLabel={hasLabel}>
      <Icons.Calendar />
    </CalendarIconContainer>
  );
};

const ClearIcon = function ({ onClick }) {
  return (
    <ClearIconContainer onClick={onClick}>
      <IconCloseSmall />
    </ClearIconContainer>
  );
};

export class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.keyHandler = KeyHandler('value', 'inputValue').bind(this);
    this.inputHandler = InputHandler('value', 'inputValue').bind(this);
    this.state = this.defaultState();
    this.inputRef = React.createRef();
    this.dateRangePickerRef = React.createRef();
    this.localChanges = false;
  }

  defaultState() {
    return {
      value: this.props.value,
      inputValue: this.format(this.props.value),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // New value from props, reset to default state
    if (prevProps.value != this.props.value) {
      this.setState(this.defaultState());
    }
    // Anytime the component updates via prop or state
    // after initial render, we flag to submit on hide
    this.localChanges = true;
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.hide);
  }

  format = (momentObj) => {
    if (!momentObj) return '';
    return momentObj.format('DD MMM YYYY');
  };

  submit = () => {
    this.props.onChange(this.state.value);
  };

  hide = () => {
    this.setState({ tipShowing: false });
  };

  clear = (e) => {
    e.stopPropagation();
    this.setState({ value: null }, this.submit);
  };

  onShow = () => {
    this.setState({ tipShowing: true });
    // Needed for case of clicking on a text input in another window
    window.addEventListener('blur', this.hide);
  };

  onHidden = () => {
    if (this.localChanges) {
      this.submit();
    }
    this.setState({ tipShowing: false });
    window.removeEventListener('blur', this.hide);
  };

  onChange = (e) => {
    this.inputHandler(e);
    if (!e.currentTarget.value && this.props.allowClear) {
      this.setState({ value: null }, this.submit);
    }
  };

  handleSelect = (value) => {
    this.hide();
    this.blurInput();
    this.setState(
      {
        value,
        inputValue: this.format(value),
      },
      this.submit,
    );
  };

  blurInput = () => {
    this.inputRef.current.blur();
  };

  focusInput = () => {
    this.inputRef.current.focus();
  };

  isDateInLockPeriod = (date) => {
    const latestLockDate = this.props.lockPeriod?.latest;
    const datesSet = date && latestLockDate;

    return (
      datesSet && isDateInLockPeriod(date.toDate(), new Date(latestLockDate))
    );
  };

  hasLockPeriodAccess = (date) => {
    const isDateInLockPeriod = this.isDateInLockPeriod(date);

    return (
      !isDateInLockPeriod ||
      (isDateInLockPeriod && !!this.props.lockPeriod?.hasAccess)
    );
  };

  // Handling the blur event on the input & datepicker
  // to find if the user is exited from the component
  handleBlur = () => {
    // Need a setTimeout to wait for the next focused element
    // to become active
    setTimeout(() => {
      // This check prevents the onHidden call when the user
      // interacts with the date picker months/year selects
      const focusingDatePicker = this.dateRangePickerRef.current?.contains(
        document.activeElement,
      );
      // This is to check if the focus is goinf from the picker to the input
      const focusingInput = this.inputRef.current === document.activeElement;

      if (!focusingDatePicker && !focusingInput) {
        this.onHidden();
      }
    });
  };

  inputId = this.props.inputId || `picker-${Math.random()}`;

  render() {
    const { inputValue } = this.state;
    const { ariaLabel, inputAriaLabel, allowClear, hasIcon, appearance } =
      this.props;

    let icon = hasIcon ? <CalendarIcon hasLabel={this.props.label} /> : null;
    if (allowClear && inputValue) {
      icon = <ClearIcon onClick={this.clear} />;
    }

    const hasFixedEndDateToggleValue =
      typeof this.props.fixedEndDateToggleValue === 'boolean';

    return (
      <DateInputContainer
        appearance={appearance}
        className={this.props.className}
        height={this.props.height}
        inputWidth={this.props.inputWidth}
        inputTextStyle={this.props.inputTextStyle}
      >
        {icon}
        {this.props.label && (
          <FieldLabel htmlFor={this.inputId}>{this.props.label}</FieldLabel>
        )}
        <Popover
          placement="bottom-start"
          maxWidth="300px"
          arrow={false}
          delay={1}
          open={this.state.tipShowing}
          onOpenAutoFocus={(evt) => {
            evt.preventDefault();
          }}
          ariaLabel={ariaLabel}
          distance={5}
          container={this.props.container}
          content={
            this.state.tipShowing ? (
              <div
                ref={this.dateRangePickerRef}
                onMouseDown={(evt) => {
                  // prevents the input blur when the user interacts with the calendar
                  evt.preventDefault();
                }}
                onBlur={this.handleBlur}
              >
                <DateRangePicker
                  paginationArrowComponent={PaginationArrow}
                  onChangeNativeSelect={() => {
                    // We need to refocus the input after datepicker's native select
                    // otherwise it will leave the picker open and not close on blur
                    this.focusInput();
                  }}
                  className={'float-single-datepicker-container'}
                  firstOfWeek={this.props.firstOfWeek || 0}
                  minimumDate={this.props.minimumDate || null}
                  maximumDate={this.props.maximumDate || null}
                  stateDefinitions={this.props.stateDefinitions}
                  disableDayFn={this.props.disableDayFn}
                  defaultState={this.props.defaultState}
                  dateStates={this.props.dateStates}
                  numberOfCalendars={1}
                  selectionType="single"
                  singleDateRange={true}
                  value={this.state.value}
                  onSelect={this.handleSelect}
                  initialYear={this.props.initialYear}
                  initialMonth={this.props.initialMonth}
                  isDateInLockPeriod={this.isDateInLockPeriod}
                  hasLockPeriodAccess={this.hasLockPeriodAccess}
                  lockPeriod={this.props.lockPeriod}
                />
                {hasFixedEndDateToggleValue && (
                  <div className={styles.wixedEndDateToggleWrapper}>
                    <Checkbox
                      label={t`Fixed end date`}
                      checked={this.props.fixedEndDateToggleValue}
                      disabled={this.props.fixedEndDateToggleIsDisabled}
                      onChange={this.props?.onFixedEndDateToggleValueChange}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div />
            )
          }
        >
          <div className="date-input-wrapper" style={{ outline: 'none' }}>
            {this.props.children}
            <input
              disabled={this.props.disabled}
              className={this.props.inputClassname}
              ref={this.inputRef}
              type={this.props.children ? 'hidden' : 'text'}
              autoFocus={this.props.autoFocus}
              value={this.state.inputValue}
              onKeyDown={this.keyHandler}
              onChange={this.onChange}
              tabIndex={this.props.tabIndex}
              placeholder={this.props.placeholder}
              onFocus={this.onShow}
              onBlur={this.handleBlur}
              aria-label={inputAriaLabel}
              id={this.inputId}
            />
          </div>
        </Popover>
      </DateInputContainer>
    );
  }
}

export default DatePicker;
