import React, { useId } from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import cn from 'classnames';

import { CheckboxProps } from './types';

import * as styles from './styles.css';

const Checkbox = (props: CheckboxProps) => {
  const {
    checked,
    className,
    defaultChecked,
    disabled,
    label,
    onChange,
    onClick,
    size,
    readOnly,
  } = props;

  const checkboxId = useId();
  const labelId = useId();

  return (
    <div
      className={cn(styles.checkbox, className)}
      aria-disabled={disabled}
      aria-readonly={readOnly}
    >
      <RadixCheckbox.Root
        aria-labelledby={labelId}
        checked={checked}
        className={styles.checkboxButton({ size })}
        defaultChecked={defaultChecked}
        disabled={disabled || readOnly}
        id={checkboxId}
        onCheckedChange={onChange}
        onClick={onClick}
      >
        <RadixCheckbox.Indicator className={styles.indicator} forceMount>
          <svg
            className={styles.checkIcon}
            width="10"
            height="8"
            stroke="#000"
            fill="none"
            viewBox="0 0 10 8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={styles.checkIconPath}
              d="M1.3833 4.83191L3.509 6.95761L9.11664 1.34997"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </svg>
          <span className={styles.indeterminateIcon} />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <label
        id={labelId}
        htmlFor={checkboxId}
        className={styles.label({ size })}
      >
        {label}
      </label>
    </div>
  );
};

export { Checkbox };
