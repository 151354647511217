import React from 'react';
import { t, Trans } from '@lingui/macro';

import { getCostRatePayload } from '@float/common/actions/lib/getCostRatePayload';
import { DatePicker } from '@float/common/components/DatePicker/DatePicker';
import { RateInput } from '@float/common/components/Rate';
import { useEditCostRate } from '@float/common/hooks/useEditCostRate';
import { CostRateFormData } from '@float/types/costRate';
import { Field } from '@float/ui/primitives/Field/Field';
import { FieldLabel } from '@float/ui/primitives/FieldLabel/FieldLabel';

import * as styles from './BulkEditBillRate.styles.css';

type Props = {
  value: CostRateFormData;
  onChange: (args: CostRateFormData) => void;
};

export function getBulkEditCostRateConfig() {
  return {
    label: t`Cost rate`,
    render: BulkEditCostRate,
    getPayloadFromState: (data: CostRateFormData) => {
      if (!data) return {};
      return getCostRatePayload(data).cost_rate_history ?? {};
    },
  };
}

export function BulkEditCostRate(props: Props) {
  const { value, onChange } = props;

  const {
    costRate,
    effectiveDate,
    handleCostRateChange,
    handleEffectiveDateChange,
  } = useEditCostRate(value, onChange);

  return (
    <div className={styles.wrapper}>
      <div className={styles.fieldGroup}>
        <Field className={styles.fieldInput}>
          <FieldLabel>
            <Trans>New cost rate</Trans>
          </FieldLabel>
          <RateInput
            autoFocus
            required
            value={costRate}
            onChange={handleCostRateChange}
          />
        </Field>
        <Field>
          <FieldLabel>
            <Trans>Effective date</Trans>
          </FieldLabel>
          <DatePicker
            ariaLabel={t`Effective date popover`}
            inputAriaLabel={t`Effective date`}
            height={40}
            inputWidth={124}
            value={effectiveDate}
            onChange={handleEffectiveDateChange}
          />
        </Field>
      </div>
    </div>
  );
}
