import React from 'react';
import { t } from '@lingui/macro';

import { IconWarningTriangle } from '@float/ui/icons/essentials/IconWarningTriangle';
import { Modal } from '@float/ui/primitives/Modal';

import { AlertBar, AlertBarVariantDisplay } from '../../AlertBar/AlertBar';
import { Button } from '../../Button';
import { ConfirmCheckbox } from './components/ConfirmCheckbox';
import { useModalConfirm } from './hooks/useModalConfirm';
import { ModalConfirmProps } from './types';

import * as styles from './styles.css';

export type * from './types';
export { ModalConfirmAppearance, ConfirmCheckboxAppearance } from './types';

export const ModalConfirm = (props: ModalConfirmProps) => {
  const {
    children,
    description,
    modalProps,
    primaryButtonLabel,
    secondaryButtonLabel,
    title,
    twoStepConfirmation,
    confirmCheckboxAppearance,
    confirmCheckboxIcon,
  } = props;

  const {
    buttonAppearance,
    buttonCancelRef,
    error,
    isLoading,
    isTwoStepConfirmationChecked,
    onClickConfirmHandler,
    onClickTwoStepConfirmation,
    onOpenAutoFocusHandler,
    onOpenChangeHandler,
  } = useModalConfirm(props);

  return (
    <Modal.Root
      {...modalProps}
      onOpenChange={onOpenChangeHandler}
      onOpenAutoFocus={onOpenAutoFocusHandler}
    >
      <Modal.Layout.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Layout.Header>

      {/* used for accessibility */}
      {children && description && (
        <Modal.Description hidden>{description}</Modal.Description>
      )}

      {/* used for visual rich content */}
      <Modal.Description asChild={!!children}>
        {children || description}
      </Modal.Description>

      {twoStepConfirmation && (
        <ConfirmCheckbox
          appearance={confirmCheckboxAppearance}
          checked={isTwoStepConfirmationChecked}
          className={styles.twoStepConfirmation}
          icon={confirmCheckboxIcon}
          onClick={onClickTwoStepConfirmation}
        />
      )}

      <Modal.Layout.Footer>
        {error && (
          <AlertBar
            icon={IconWarningTriangle}
            display={AlertBarVariantDisplay.Block}
            message={error}
          />
        )}

        <Modal.Layout.ButtonGroup>
          <Button
            appearance={buttonAppearance.primary}
            disabled={twoStepConfirmation && !isTwoStepConfirmationChecked}
            isLoading={isLoading}
            onClick={onClickConfirmHandler}
          >
            {primaryButtonLabel || t`Confirm`}
          </Button>

          <Modal.Close>
            <Button
              ref={buttonCancelRef}
              appearance={buttonAppearance.secondary}
            >
              {secondaryButtonLabel || t`Cancel`}
            </Button>
          </Modal.Close>
        </Modal.Layout.ButtonGroup>
      </Modal.Layout.Footer>
    </Modal.Root>
  );
};
