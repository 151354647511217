import { Project } from '@float/types/project';

export const getPlanLink = (
  isSPVEnabled: boolean,
  projectName: string,
  projectId: Project['project_id'],
) => {
  const projectPlanLink = `/project-plan?project=${encodeURIComponent(
    projectName,
  )}`;
  const planLink = isSPVEnabled ? `/project/${projectId}` : projectPlanLink;

  return planLink;
};
