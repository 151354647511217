import React from 'react';

import { FilterCategory } from '@float/common/search/types';
import { ActivateCategory } from '@float/web/components/SearchFilterDropdown/types';

import { getCategoryConfig } from '../../../../helpers/getCategoryConfig';

import * as styles from './styles.css';

type ItemCategoryProps = {
  type: FilterCategory;
  onClick: ActivateCategory;
  onMouseEnter?: () => void;
};

export const ItemCategory = (props: ItemCategoryProps) => {
  const { type, onClick, onMouseEnter } = props;

  const category = getCategoryConfig(type);

  const isContainsCategory = type === 'contains';

  if (category) {
    const { icon: Icon, value } = category;

    const onClickHandler = () => {
      if (value !== 'contains') onClick(value);
    };

    return (
      <button
        className={styles.itemCategory}
        onClick={onClickHandler}
        onMouseEnter={onMouseEnter}
        disabled={isContainsCategory}
      >
        {Icon && <Icon size={20} />}

        <span className={styles.label}>
          {category.shortLabel || category.label}
        </span>
      </button>
    );
  }

  return null;
};
