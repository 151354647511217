import { useCallback, useState } from 'react';
import { t } from '@lingui/macro';

import { multiplyOperation } from '@float/libs/utils/floats';
import { TimeString } from '@float/types/datesManager';
import { AllocationType } from '@float/types/task';

import { getIsSpecificTimeSet } from '../EditTaskModal.helpers';
import { AllocationTimeSectionPayload } from '../EditTaskModal.types';
import { AllocationSettings } from './useAllocationSettings';

export const DEFAULT_START_TIME = '10:00';

export type UseAllocationTypeControlsParams = {
  allocationType: AllocationType | null;
  offWorkDate?: Date | undefined;
  hoursPerDay: number;
  startTime: TimeString | null;
  numberOfAllocationDays: number;
  peopleIds: number[];
  isFixedEndDate: boolean;
  isAllocationByPercentAvailable?: boolean;
  isAllocationByPercentDisabled?: boolean;
  onChange: (payload: AllocationTimeSectionPayload) => void;
  setAllocationSettings: (
    key: keyof AllocationSettings,
    value: AllocationSettings[keyof AllocationSettings],
  ) => void;
};

export enum AllocationTypeOptionValue {
  HOURS = 'hours',
  PERCENTAGE = 'percentage',
}

export type AllocationTypeOption = {
  value: AllocationTypeOptionValue;
  label: string;
};

export const useAllocationTypeControls = ({
  allocationType,
  startTime,
  hoursPerDay,
  numberOfAllocationDays,
  isFixedEndDate,
  isAllocationByPercentAvailable = true,
  isAllocationByPercentDisabled = false,
  onChange,
  setAllocationSettings,
}: UseAllocationTypeControlsParams) => {
  // We need to preserve this value in state for switching from Hours/day to Specific time
  // because specific time for new task comes by default as one hour starting from 10:00
  // meanwhile the default value for hours per day is the minimum working
  // hours per day for the selected time range
  const [hoursPerDayInitialValue] = useState(() => hoursPerDay);

  const allocationTypeOptions: AllocationTypeOption[] = [
    {
      value: AllocationTypeOptionValue.HOURS,
      label: t`Hours`,
    },
    {
      value: AllocationTypeOptionValue.PERCENTAGE,
      label: t`Percentage`,
      disabled: isAllocationByPercentDisabled,
    },
  ].filter((option) => {
    if (
      !isAllocationByPercentAvailable &&
      option.value === AllocationTypeOptionValue.PERCENTAGE
    )
      return false;

    return true;
  });

  // This is used foor both hours and specific time
  const isAllocationByHours =
    allocationType === AllocationType.Hours ||
    allocationType === AllocationType.HoursWithFixedDuration;

  const isAllocationByPercentage = allocationType === AllocationType.Percentage;

  const isAllocationByHoursSpecificTime = isAllocationByHours && !!startTime;
  const isAllocationByHoursPerDay = isAllocationByHours && !startTime;

  let allocationTypeOptionSelectedValue;

  if (isAllocationByPercentage) {
    allocationTypeOptionSelectedValue = AllocationTypeOptionValue.PERCENTAGE;
  } else {
    allocationTypeOptionSelectedValue = AllocationTypeOptionValue.HOURS;
  }

  const selectHoursPerDay = useCallback(() => {
    onChange({
      allocationType: isFixedEndDate
        ? AllocationType.HoursWithFixedDuration
        : AllocationType.Hours,
      hoursPerDay: hoursPerDayInitialValue,
      hoursTotal: multiplyOperation(
        hoursPerDayInitialValue,
        numberOfAllocationDays,
      ),
      startTime: null,
    });
  }, [
    onChange,
    isFixedEndDate,
    numberOfAllocationDays,
    hoursPerDayInitialValue,
  ]);

  const selectPercentage = useCallback(() => {
    onChange({
      hoursPerDay: hoursPerDayInitialValue,
      hoursTotal: multiplyOperation(
        hoursPerDayInitialValue,
        numberOfAllocationDays,
      ),
      allocationType: AllocationType.Percentage,
      startTime: null,
    });
  }, [onChange, hoursPerDayInitialValue, numberOfAllocationDays]);

  const selectSpecificTime = useCallback(() => {
    const hoursPerDayValue = 1;
    const hoursTotalValue = multiplyOperation(
      numberOfAllocationDays,
      hoursPerDayValue,
    );
    const startTimeValue = getIsSpecificTimeSet(startTime)
      ? startTime
      : DEFAULT_START_TIME;

    onChange({
      allocationType: AllocationType.Hours,
      hoursPerDay: hoursPerDayValue,
      hoursTotal: hoursTotalValue,
      startTime: startTimeValue,
    });
  }, [onChange, numberOfAllocationDays, startTime]);

  const handleAllocationTypeChange = ({ value }: AllocationTypeOption) => {
    // Emit the changes
    if (value === AllocationTypeOptionValue.HOURS) {
      selectHoursPerDay();
    } else if (value === AllocationTypeOptionValue.PERCENTAGE) {
      selectPercentage();
    }

    // Preserve the selected view in the storage
    if (value === AllocationTypeOptionValue.PERCENTAGE) {
      setAllocationSettings('defaultAllocationType', AllocationType.Percentage);
    } else if (isFixedEndDate) {
      setAllocationSettings(
        'defaultAllocationType',
        AllocationType.HoursWithFixedDuration,
      );
    } else {
      setAllocationSettings('defaultAllocationType', AllocationType.Hours);
    }
  };

  return {
    isAllocationByHours,
    isAllocationByPercentage,
    isAllocationByHoursSpecificTime,
    isAllocationByHoursPerDay,
    allocationTypeOptions,
    allocationTypeOptionSelectedValue,
    handleAllocationTypeChange,
    selectHoursPerDay,
    selectPercentage,
    selectSpecificTime,
  };
};
