import type { TaskMeta } from '@float/types/task';

import { createEmptyTaskRecord } from './createEmptyTaskRecord';
import type { ParentTaskRowData } from '../../table.types';

export const createTaskRowDataMap = (
  tasks: TaskMeta[],
  rawTasksMap: Record<string, TaskMeta>,
) => {
  const byTask: Record<string, ParentTaskRowData> = {};

  tasks.forEach((task) => {
    // Empty task name string implies allocations that have no task assignment
    const key = `${task.task_name || 'No task allocation'}::${task.phase_id}`;
    const record = createEmptyTaskRecord(task);
    const rawTask = rawTasksMap[task.task_meta_id];
    if (rawTask && rawTask.budget) {
      record.budget = rawTask.budget;
    }
    byTask[key] = record;
  });

  return byTask;
};
