import { FloatDeploymentEnvironment } from './environment';

export const APP_HOSTNAMES: Record<FloatDeploymentEnvironment, string> = {
  [FloatDeploymentEnvironment.Local]: 'https://app.local.float.com',
  [FloatDeploymentEnvironment.Develop]: 'https://app.develop.float.com',
  [FloatDeploymentEnvironment.Staging]: 'https://app.staging.float.com',
  [FloatDeploymentEnvironment.Preview]: 'https://app.staging.float.com',
  [FloatDeploymentEnvironment.Prod]: 'https://app.float.com',
};

export const API_HOSTNAMES = {
  [FloatDeploymentEnvironment.Local]: 'https://api.local.float.com',
  [FloatDeploymentEnvironment.Develop]: 'https://api.develop.float.com',
  [FloatDeploymentEnvironment.Staging]: 'https://api.staging.float.com',
  [FloatDeploymentEnvironment.Preview]: 'https://api.staging.float.com',
  [FloatDeploymentEnvironment.Prod]: 'https://api.float.com',
};

export const LIVEUPDATES_HOSTNAMES = {
  [FloatDeploymentEnvironment.Local]: 'https://float-notify.local.float.com',
  [FloatDeploymentEnvironment.Develop]:
    'https://float-notify.develop.float.com',
  [FloatDeploymentEnvironment.Staging]:
    'https://float-notify.staging.float.com',
  [FloatDeploymentEnvironment.Preview]:
    'https://float-notify.staging.float.com',
  [FloatDeploymentEnvironment.Prod]: 'https://float-notify.float.com',
};
