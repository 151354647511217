import React, { useReducer, useRef } from 'react';
import { Moment } from 'moment';

import { DatePicker } from '@float/common/components/DatePicker/DatePicker';
import { Rights } from '@float/common/lib/rights';
import { formatDateForDB } from '@float/common/lib/utils';
import { moment } from '@float/libs/moment';

import { LogTimeInputProps } from '../../types';
import { LogTimeHours } from '../LogTimeHours';
import { StyledModalInput } from './styles';

function LogTimeInputAndDate(props: LogTimeInputProps) {
  const {
    className,
    date,
    entity,
    hours,
    isFullDayTimeoff,
    isMobile,
    lockPeriod,
    person,
    setDate,
    setHours,
    submit,
    user,
  } = props;

  const [, forceUpdate] = useReducer((c) => c + 1, 0);

  const hoursRef = useRef<HTMLInputElement>();

  return (
    <StyledModalInput className={className}>
      <div style={{ width: 140, marginRight: 20 }}>
        <LogTimeHours
          onChange={(hours) => {
            setHours(hours);

            if (hours !== null) {
              localStorage.setItem('logTimeDefaultHours', hours.toString());
            }
          }}
          onSubmit={(hours) => {
            submit(hours);
          }}
          autoFocus={isMobile}
          value={hours}
        />
      </div>
      <div style={{ width: 140 }}>
        <DatePicker
          label="Date"
          value={moment(date)}
          onChange={(d: Moment) => {
            if (!isFullDayTimeoff(entity.people_id, d.format('YYYY-MM-DD'))) {
              setDate(formatDateForDB(d));
            } else {
              forceUpdate();
            }
          }}
          disableDayFn={(date: Moment) => {
            return isFullDayTimeoff(
              entity.people_id,
              date.format('YYYY-MM-DD'),
            );
          }}
          onTabKey={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (hoursRef.current) {
              const elements = hoursRef.current.getElementsByTagName('input');
              if (elements.length) {
                e.preventDefault();
                elements[0].focus();
              }
            }
          }}
          lockPeriod={{
            hasAccess: Rights.canCreateLoggedTime(user, {
              person,
              entity: {
                isInLockPeriod: true,
              },
              ignore: [
                'isLoggedTimeProjectOwner',
                'onLoggedTimeProjectTeam',
                'loggedTimeProjectIsCommon',
              ],
            }),
            ...lockPeriod,
          }}
        />
      </div>
    </StyledModalInput>
  );
}

export { LogTimeInputAndDate };
