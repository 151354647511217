import React from 'react';
import { plural } from '@lingui/macro';

import { setViewTypeAction } from '@float/common/actions/appInfo';
import { selectScheduleViewType } from '@float/common/selectors/appInfo/scheduleView';
import { getCurrentViewId, getViewById } from '@float/common/selectors/views';
import { useAppDispatchDecorator, useAppSelector } from '@float/common/store';
import { ScheduleViewType } from '@float/constants/schedule';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';

import { getViewTypeCounts } from './getViewTypeCounts';
import { useViewTypePrefs } from './hooks/useViewTypePrefs';

export function ViewTypeNav() {
  const viewType = useAppSelector(selectScheduleViewType);
  const setViewType = useAppDispatchDecorator(setViewTypeAction);
  const { viewTypePref, updateViewTypePref } = useViewTypePrefs();

  const currentView = useAppSelector((state) =>
    getViewById(state, getCurrentViewId(state) ?? -1),
  );

  const countByCategory = useAppSelector(getViewTypeCounts);
  const peopleCount = countByCategory?.people;
  const projectsCount = countByCategory?.projects;

  const options = [
    {
      value: ScheduleViewType.People,
      label: plural(peopleCount, {
        one: '# Person',
        other: '# People',
      }),
    },
    {
      value: ScheduleViewType.Projects,
      label: plural(projectsCount, {
        one: '# Project',
        other: '# Projects',
      }),
    },
  ];

  const onChange = ({ value }: { value: ScheduleViewType | undefined }) => {
    if (value !== undefined) {
      updateViewTypePref(value);
      setViewType(value);
    }
  };

  const isCurrentViewOnReport = currentView?.settings.page.endsWith('report');

  // TODO Don't use a useEffect to align the states
  // https://linear.app/float-com/issue/PI-72/create-a-single-source-of-truth-for-the-viewtype
  useOnMount(() => {
    // If we are on a report related view, use the viewType and store is as the last user selection
    if (isCurrentViewOnReport) {
      if (viewType) {
        updateViewTypePref(viewType);
      }

      return;
    }

    if (!viewTypePref) return;

    // Align the viewType to the last user selection
    if (viewTypePref !== viewType) {
      setViewType(viewTypePref);
    }
  });

  // If not on a SavedView, use the viewTypePref to avoid glitches
  const value = isCurrentViewOnReport ? viewType : viewTypePref;

  return (
    <TextToggle<ScheduleViewType | undefined>
      variant="nav"
      options={options}
      value={value}
      toggle
      onChange={onChange}
    />
  );
}
