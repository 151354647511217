import { useFormContext } from 'react-hook-form';

import { ProjectFormData } from '@float/web/components/panelViews/Project/types';
import { useOpenSingleProjectView } from '@float/web/serena/hooks/useOpenSingleProjectView';

export function useOpenSPVFromSidePanel() {
  const openSingleProjectView = useOpenSingleProjectView();
  const { getValues } = useFormContext<ProjectFormData>();
  const onOpenProjectClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const projectId = getValues('projectId');
    openSingleProjectView(projectId);
  };

  return onOpenProjectClick;
}
