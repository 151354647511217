import { Resources } from '../constants';
import { makeRequest, MakeRequestPagination } from '../makeRequest';

export enum IntercomPlatform {
  Web = 'web',
  iOS = 'ios',
  Android = 'android',
}

export type IntercomHash = {
  hash: string;
};

export const requestIntercomHash = async (platform: IntercomPlatform) => {
  const response = await makeRequest<IntercomHash | undefined>({
    resource: Resources.IntercomHash,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
    query: {
      platform,
    },
  });

  return response?.hash;
};
