import React from 'react';
import { BillableVsNonBillable } from 'reports/components/SummaryBarParts';

import { getPercentage } from '@float/common/lib/budget';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { LIGHT_PURPLE, PURPLE } from '@float/ui/deprecated/Chart';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

import { ReportsTotalsContainer } from '../../styles';
import { getLoggedLabel } from '../helpers/getLoggedLabel';
import { getScheduledLabel } from '../helpers/getScheduledLabel';
import { ProfitSummary } from './ProfitSummary';
import type { ReportTotals } from '../../types';

function SummaryBar({
  totals,
  mode,
  minWidth,
  timeTrackingEnabled,
  hasCostsAccess,
}: {
  totals: ReportTotals;
  mode: ChartComparisonModes;
  minWidth: string | number;
  timeTrackingEnabled: boolean;
  hasCostsAccess: boolean;
}) {
  const loggedTotal = totals.logged.total;
  const loggedBillable = totals.logged.billable;
  const loggedBillablePerc = getPercentage(
    totals.logged.billable,
    totals.logged.total,
  );
  const loggedNonBillable = totals.logged.nonbillable;
  const loggedNonBillablePerc = getPercentage(
    totals.logged.nonbillable,
    totals.logged.total,
  );

  const scheduledTotals =
    mode === ChartComparisonModes.COMBINED ? totals.future : totals;

  const scheduledTotal = scheduledTotals.scheduled;
  const scheduledBillable = scheduledTotals.billable;
  const scheduledBillablePerc = getPercentage(
    scheduledTotals.billable,
    scheduledTotals.scheduled,
  );
  const scheduledNonBillable = scheduledTotals.nonbillable;
  const scheduledNonBillablePerc = getPercentage(
    scheduledTotals.nonbillable,
    scheduledTotals.scheduled,
  );
  const scheduledTentative =
    scheduledTotals.tentative.billable + scheduledTotals.tentative.nonbillable;
  const scheduledTentativePerc = getPercentage(
    scheduledTotals.tentative.billable + scheduledTotals.tentative.nonbillable,
    scheduledTotals.scheduled,
  );
  const hasScheduleSummary =
    mode === ChartComparisonModes.SCHEDULED ||
    mode === ChartComparisonModes.COMBINED ||
    mode === ChartComparisonModes.COMPARE;
  const hasLoggedSummary =
    !!timeTrackingEnabled &&
    (mode === ChartComparisonModes.LOGGED ||
      mode === ChartComparisonModes.COMBINED ||
      mode === ChartComparisonModes.COMPARE);
  const hasProfitMargin =
    hasCostsAccess &&
    mode !== ChartComparisonModes.COMPARE &&
    featureFlags.isFeatureEnabled(FeatureFlag.CostRatesMultiProjectReport);
  const hasScheduleBorder =
    !timeTrackingEnabled || (hasProfitMargin && !hasLoggedSummary);
  return (
    <ReportsTotalsContainer style={{ minWidth }}>
      {hasScheduleSummary && (
        // @ts-expect-error need to update this component to TS later
        <BillableVsNonBillable
          title={getScheduledLabel(mode)}
          total={scheduledTotal}
          billable={scheduledBillable}
          billablePerc={scheduledBillablePerc}
          nonBillable={scheduledNonBillable}
          nonBillablePerc={scheduledNonBillablePerc}
          tentative={scheduledTentative}
          tentativePerc={scheduledTentativePerc}
          noBorderRight={hasScheduleBorder}
        />
      )}
      {hasLoggedSummary && (
        // @ts-expect-error need to update this component to TS later
        <BillableVsNonBillable
          title={getLoggedLabel(mode)}
          colors={[PURPLE, LIGHT_PURPLE]}
          total={loggedTotal}
          billable={loggedBillable}
          billablePerc={loggedBillablePerc}
          nonBillable={loggedNonBillable}
          nonBillablePerc={loggedNonBillablePerc}
          noBorderRight
        />
      )}
      {hasProfitMargin && <ProfitSummary totals={totals} mode={mode} />}
    </ReportsTotalsContainer>
  );
}

export default SummaryBar;
