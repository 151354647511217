import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export type RouteWithGuardProps = {
  guard: boolean | undefined;
  redirect?: string;
} & RouteProps;

const DEFAULT_REDIRECT = '/';

export function RouteWithGuard(props: RouteWithGuardProps) {
  const { guard, redirect = DEFAULT_REDIRECT, ...rest } = props;

  if (!guard) {
    return <Redirect to={redirect} />;
  }

  return <Route {...rest} />;
}
