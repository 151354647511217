import { LOCATION_CHANGE, push, replace } from 'connected-react-router';
import { isEmpty } from 'lodash';
import qs from 'query-string';
import { Middleware } from 'redux';

import {
  SEARCH_ADD_FILTERS,
  SEARCH_REMOVE_ALL_FILTERS,
  SEARCH_REMOVE_FILTER,
  SEARCH_SET_FILTERS,
  SEARCH_UPDATE_FILTER,
} from '@float/common/actions/search';
import { VIEW_APPLIED, VIEW_UNAPPLY } from '@float/common/actions/views';
import { omitOne } from '@float/common/lib/omit';
import { AllActions } from '@float/common/reducers';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import {
  getFiltersFromQueryString,
  getQueryStringFromFilters,
  OLD_TYPES,
  TYPES,
} from '@float/common/search/helpers';
import {
  getCurrentViewId,
  getViewPathname,
} from '@float/common/selectors/views';
import { AppDispatch } from '@float/common/store';
import { BaseFilterToken } from '@float/types/view';
import { isFilterCombinationForbidden } from '@float/web/components/SearchFilterDropdown/helpers/filterCombinationValidation';

const queryAlteringActions = [
  SEARCH_ADD_FILTERS,
  // This action is meant to be used only from urlFilterSync
  // SEARCH_SET_FILTERS,
  SEARCH_UPDATE_FILTER,
  SEARCH_REMOVE_FILTER,
  SEARCH_REMOVE_ALL_FILTERS,
  VIEW_APPLIED,
  VIEW_UNAPPLY,
];

let updatingFilters = false;
let updatingUrl = false;

// @test-export
export function areFiltersEqual(
  newFilters: BaseFilterToken[],
  currentFilters: BaseFilterToken[],
) {
  // Using getQueryStringFromFilters to normalize the filters
  // to avoid issues with different kind of definitions
  // (e.g. array vs non-array)
  const newFiltersParams = getQueryStringFromFilters(newFilters).split('&');
  const currentFiltersParams =
    getQueryStringFromFilters(currentFilters).split('&');

  return (
    newFiltersParams.length === currentFiltersParams.length &&
    newFiltersParams.every((param, i) => currentFiltersParams[i] === param)
  );
}

export const urlFilterSync: Middleware<{}, ReduxStateStrict, AppDispatch> =
  (store) => (next) => async (action: AllActions) => {
    if (!action) return next(action);

    const shouldUpdateFiltersQS = queryAlteringActions.includes(action.type);

    // If the location changes, it's because we navigated to the site for the
    // first time or changed pages in some other way. We'll propagate the URL
    // parameters down to the search filters store.
    if (action.type === LOCATION_CHANGE) {
      if (updatingFilters || updatingUrl) return;
      updatingFilters = true;

      const { location } = action.payload;

      const { search } = store.getState();
      const filtersFromUrl = getFiltersFromQueryString(location.search);
      const validFilters = filtersFromUrl.filter(
        (filter) =>
          !isFilterCombinationForbidden({
            type: filter.type,
            value: filter.val,
            operator: filter.operator,
          }),
      );

      if (!areFiltersEqual(search.filters, validFilters)) {
        store.dispatch({
          type: SEARCH_SET_FILTERS,
          filters: validFilters,
          removedFilters: [],
        });

        const res = await next(action);

        // Now that the store has the correct filters, we might need to update
        // the URL as well. This occurs in two different scenarios:
        //  - We previously had an old style filter that we want to convert
        //  - A filter was automatically removed/added on navigation
        const query = qs.parse(location.search);
        const q = { ...query };
        TYPES.forEach((type) => {
          delete q[type];
        });
        OLD_TYPES.forEach((type) => {
          delete q[type];
        });
        const { search } = store.getState();
        const searchQs = getQueryStringFromFilters(search.filters);
        const newQs = isEmpty(q) ? searchQs : `${qs.stringify(q)}&${searchQs}`;
        const newPath = `${location.pathname}?${newQs}`;

        if (location.search !== `?${newQs}`) {
          queueMicrotask(() => store.dispatch(replace(newPath)));
        }
        updatingFilters = false;
        return res;
      }
      updatingFilters = false;
    } else if (shouldUpdateFiltersQS) {
      // The other direction is when the search filters in the store change. In
      // this case, we want to update the URL parameters to match.

      if (updatingUrl || updatingFilters) {
        return next(action);
      }

      updatingUrl = true;

      const { router } = store.getState();

      let q = router ? { ...router.location.query } : {};

      // Clear out the existing parameters...
      TYPES.forEach((type) => {
        delete q[type];
      });

      // Allow the state change to happen...
      const res = await next(action);

      let pathname = location.pathname;

      if (action.type === VIEW_APPLIED) {
        pathname = getViewPathname(action.view);
      }

      const viewId = getCurrentViewId(store.getState());

      if (!viewId) {
        q = omitOne(q, 'view');
      } else {
        q.view = viewId.toString();
      }

      // And rebuild from the latest state.
      const { search } = store.getState();
      const searchQs = getQueryStringFromFilters(search.filters);

      // Joins the two query strings, avoiding leading and trailing & when one of the two is empty
      const newQs = [qs.stringify(q), searchQs].filter(Boolean).join('&');
      const newPath = `${pathname}?${newQs}`;

      const urlAlterMethod = action.usePushState ? push : replace;

      queueMicrotask(() => store.dispatch(urlAlterMethod(newPath)));
      updatingUrl = false;

      return res;
    }

    return next(action);
  };
