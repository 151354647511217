import React from 'react';

import { Avatar } from '@float/common/components/Avatar';
import { RateText } from '@float/common/components/Rate';
import { Person } from '@float/types';

import * as styles from './PersonToSwitchOut.styles.css';

type Props = {
  avatarFile: Person['avatar_file'];
  billRate: string | null | undefined;
  name: Person['name'];
  shouldShowRates: boolean;
};

export function PersonToSwitchOut(props: Props) {
  const { avatarFile, billRate, name, shouldShowRates } = props;
  return (
    <div className={styles.personToSwitch}>
      <div className={styles.nameAndAvatar}>
        <Avatar name={name} imageUrl={avatarFile} readOnly size="xs" />
        <span>{name}</span>
      </div>
      {shouldShowRates && billRate && <RateText rate={billRate} />}
    </div>
  );
}
