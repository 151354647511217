import { AppInfoState } from '@float/common/reducers/appInfo';
import { AnyLoggedTimeType } from '@float/common/reducers/loggedTimes';
import { getIsLogTimeView } from '@float/common/selectors/appInfo/scheduleView';
import { isFullLoggedTime } from '@float/common/selectors/loggedTimes';
import { getActiveTimer } from '@float/common/selectors/timer';
import { UseCellsAction } from '@float/common/serena/Data/useCells/types';
import { config } from '@float/libs/config';
import { LoggedTime, Timer } from '@float/types';

const getLoggedTimeByTimer = (
  state: {
    loggedTimes: { loggedTimes: Record<string, AnyLoggedTimeType> };
  },
  timer?: Timer,
) => {
  if (timer?.external_meta.id) {
    const loggedTimeId = timer?.external_meta.id;

    const loggedTime = state.loggedTimes.loggedTimes[loggedTimeId];

    if (loggedTime && isFullLoggedTime(loggedTime)) {
      return loggedTime;
    }
  }

  return undefined;
};

const shouldForceActiveTimerLoad = (state: {
  router?: { location?: { pathname?: string } };
  appInfo?: AppInfoState;
}) => {
  if (config.isMobileApp) return false;
  if (config.isNativeTimerApp) return true;

  return getIsLogTimeView(state);
};

/**
 * Forces the refresh of the logged time cells related to the active timer
 *
 * Since timers aren't directly related to the cells data because are loaded as helpers
 * their changes don't trigger a cells update
 *
 * Most of the time this is not necessary as usually a logged time is updated along with the timer
 * with the only exception for when a timer is started/stopped.
 *
 * With this function we handle this exception by forcing the refresh of the logged time cells
 * related to the started/stopped timers.
 */
export function handleActiveTimerChanged(
  state: {
    currentUser: { account_id: number };
    timer: { timers: Timer[] };
    router?: { location?: { pathname?: string } };
    appInfo?: AppInfoState;
    loggedTimes: { loggedTimes: Record<string, AnyLoggedTimeType> };
  },
  dispatch: (action: UseCellsAction) => void,
  lastActiveTimer: Timer | undefined,
) {
  if (shouldForceActiveTimerLoad(state) === false) {
    return lastActiveTimer;
  }

  const activeTimer = getActiveTimer(state);

  if (activeTimer !== lastActiveTimer) {
    const data: Record<string, LoggedTime> = {};

    const lastActiveTimerLoggedTime = getLoggedTimeByTimer(
      state,
      lastActiveTimer,
    );
    const activeTimerLoggedTime = getLoggedTimeByTimer(state, activeTimer);

    if (lastActiveTimerLoggedTime) {
      data[lastActiveTimerLoggedTime.logged_time_id] =
        lastActiveTimerLoggedTime;
    }

    if (activeTimerLoggedTime) {
      data[activeTimerLoggedTime.logged_time_id] = activeTimerLoggedTime;
    }

    dispatch({
      type: 'LOAD_DATA',
      ignoreMissing: true,
      data,
      dataType: 'loggedTime',
      forceLoad: true,
    });
  }

  return activeTimer;
}
