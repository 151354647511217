import React from 'react';
import { t } from '@lingui/macro';

import { DatePicker } from '@float/common/components/DatePicker';
import { moment } from '@float/libs/moment';
import { CostRateHistory } from '@float/types/costRate';

import { EditPersonBillRate } from './EditPersonBillRate';
import { EditPersonCostRate } from './EditPersonCostRate';

import * as styles from './PersonRates.styles.css';

type Props = {
  billRate?: string;
  billRateErrors?: string[];
  billRatePlaceholder?: number;
  costRate?: string;
  costRateErrors?: string[];
  costRateHistory: CostRateHistory;
  costRatePlaceholder?: number;
  costRateEffectiveDate?: string | null;
  currency: string;
  hasCustomBillRate: boolean;
  hasCustomCostRate: boolean;
  isCostRateFocused: boolean;
  locale: string;
  placeholder?: string;
  onBillRateChange: (newValue: string) => void;
  onCostRateChange: (newValue: string) => void;
  onCostRateRemove: (date: DateString) => void;
  onCostRateEffectiveDateChange: (newValue: string) => void;
  onCostRateFocusChange: (isFocused: boolean) => void;
};

export function EditPersonRates(props: Props) {
  const {
    billRate,
    billRateErrors,
    billRatePlaceholder,
    costRate,
    costRateEffectiveDate,
    costRateErrors,
    costRateHistory,
    costRatePlaceholder,
    currency,
    hasCustomBillRate,
    hasCustomCostRate,
    isCostRateFocused,
    locale,
    onBillRateChange,
    onCostRateChange,
    onCostRateRemove,
    onCostRateEffectiveDateChange,
    onCostRateFocusChange,
  } = props;

  const handleBillRateFocus = () => {
    onCostRateFocusChange(false);
  };

  const handleCostRateFocus = () => {
    onCostRateFocusChange(true);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.fields}>
          <EditPersonCostRate
            currency={currency}
            errors={costRateErrors}
            history={costRateHistory}
            locale={locale}
            placeholder={costRatePlaceholder}
            placeholderOverrideEnabled={hasCustomCostRate}
            value={costRate}
            onChange={onCostRateChange}
            onRemove={onCostRateRemove}
            onFocus={handleCostRateFocus}
          />
          <EditPersonBillRate
            currency={currency}
            errors={billRateErrors}
            locale={locale}
            placeholder={billRatePlaceholder}
            placeholderOverrideEnabled={hasCustomBillRate}
            value={billRate}
            onChange={onBillRateChange}
            onFocus={handleBillRateFocus}
          />
        </div>
      </div>
      {costRateEffectiveDate && isCostRateFocused && (
        <div className={styles.row}>
          <DatePicker
            className={styles.effectiveDateWrapper}
            label={t`Effective date`}
            appearance="outline"
            value={moment(costRateEffectiveDate)}
            onChange={onCostRateEffectiveDateChange}
          />
        </div>
      )}
    </>
  );
}
