import type {
  AndNotOperator,
  AndOperator,
  FilterOperators,
  OrNotOperator,
  OrOperator,
} from '@float/types/filters';

/*
 * '-', '+', '|'
 *  Does not include Nor '|-'
 */
export const OPERATOR_PRIMITIVES = new Set<FilterOperators>(['-', '+', '|']);

function getIsOperatorPrimitive(char: string): char is FilterOperators {
  return OPERATOR_PRIMITIVES.has(char as FilterOperators);
}

function getOperatorAtPosition(
  value: string,
  position: number,
): FilterOperators | '' {
  const char = value.charAt(position);

  return getIsOperatorPrimitive(char) ? char : '';
}

export function getOperator(value: string): FilterOperators | '' {
  let operator: FilterOperators | '' = '';

  // We can have upto two operators
  // E.g. Or Not, which would be |-
  operator += getOperatorAtPosition(value, 0);

  if (operator) {
    operator += getOperatorAtPosition(value, 1);
  }

  // This co-ercions isn't really safe – to avoid it we'd need to refactor
  // the code to get rid of the `+=` operations as they coerce the type to
  // `string`
  return operator as FilterOperators | '';
}

export function isOp<Op extends FilterOperators | ''>(
  input?: string,
  op?: Op,
): input is Op {
  if (!input || !op) return false;

  return getOperator(input).includes(op);
}

export function isAnd(input?: string): input is AndOperator | OrOperator {
  return isOp(input, '+');
}

export function isNot(input?: string): input is AndNotOperator | OrNotOperator {
  return isOp(input, '-');
}

export function isOr(input?: string): input is OrOperator | OrNotOperator {
  return isOp(input, '|');
}
