import { search } from '@float/common/api3/search';
import {
  SearchResolveApiParams,
  SearchResolveContext,
  SearchResolveField,
} from '@float/common/api3/search.types';
import type { CurrentUser } from '@float/types/account';
import type { BaseFilterToken } from '@float/types/view';

import { isOr, PERSON_RELATED_TYPES } from '../helpers';
import { convertFilterTokenToSearchResolveQuery } from './helpers/convertFilterTokenToSearchResolveQuery';
import { convertResolveResultsToSearchResults } from './helpers/convertResolveResultsToSearchResults';
import { getIsTimeoffFilterActive } from './helpers/getIsTimeoffFilterActive';
import { groupFiltersByLogicalOperator } from './helpers/groupFiltersByLogicalOperator';

export type SearchResolveParams = {
  filters: BaseFilterToken[];
  context: SearchResolveContext;
  contextId?: string;
  user: CurrentUser;
};

export type SearchResolveResult = {
  contextId: string;
  result: ReturnType<typeof convertResolveResultsToSearchResults>;
};

export async function resolveApi(
  params: SearchResolveParams,
): Promise<SearchResolveResult> {
  // TODO: Filter-out the non-valid filters
  // https://linear.app/float-com/issue/PS-1443/discovery-specify-all-the-exceptions-implemented-on-the-client-search
  const isMeFilterActive = params.filters.some(
    (filter) => filter.type === 'me',
  );
  const isTimeoffFilterActive = getIsTimeoffFilterActive(params.filters);

  let appliedFilters = params.filters;

  // If Me filter is on, do not filter by people related criteria
  if (isMeFilterActive) {
    appliedFilters = appliedFilters.filter(
      (x) => !PERSON_RELATED_TYPES.includes(x.type),
    );
  }

  // Convert the filter token in query compatible with the resolve API
  // Since we need to gather the ids values from an API the call is async
  // and we run the conversions in parallel
  const resolvedFilters = await Promise.all(
    appliedFilters.map(async (filter) => ({
      logicalOperator: isOr(filter.operator)
        ? ('or' as const)
        : ('and' as const),
      filters: await convertFilterTokenToSearchResolveQuery(
        filter,
        params.user,
      ),
    })),
  );

  const query = groupFiltersByLogicalOperator(resolvedFilters);

  // The filters lookup has returned an empty result
  // so we don't need to make the API call to know that
  // there are no results
  if (query.filters.length === 0) {
    return {
      contextId: '',
      result: {
        loggedTimes: new Set(),
        tasks: new Set(),
        timeoffs: new Set(),
        people: new Set(),
        projects: new Set(),
      },
    };
  }

  const fields = getResolveFields(params.context, isTimeoffFilterActive);

  const { contextId, result } = await search.resolve({
    query,
    context: params.context,
    fields,
    contextId: params.contextId,
  });

  return {
    contextId,
    result: convertResolveResultsToSearchResults(result),
  };
}

// @test-export
export function getResolveFields(
  context: SearchResolveContext,
  isTimeoffFilterActive: boolean,
) {
  // "Logged Time" entities are omitted by default, as they are can
  // be very large
  let fields: SearchResolveApiParams['fields'] = [
    SearchResolveField.TASK,
    SearchResolveField.TIME_OFF,
    SearchResolveField.PEOPLE,
    SearchResolveField.PROJECT,
  ];

  // When a timeoff filter is active we don't want to show
  // any task or logged time
  if (isTimeoffFilterActive) {
    fields = [
      SearchResolveField.TIME_OFF,
      SearchResolveField.PEOPLE,
      SearchResolveField.PROJECT,
    ];
  } else if (context === SearchResolveContext.LoggedTime) {
    fields.push(SearchResolveField.LOGGED_TIME);
  }

  return fields;
}
