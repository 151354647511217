import { fastObjectSpread } from '@float/common/lib/fast-object-spread';
import { overlaps } from '@float/common/serena/Data/useCells/_helpers';
import { sortCellItemsY } from '@float/common/serena/Data/useCells/helpers/sortCellItemsY';
import { subtractOperation } from '@float/libs/utils/floats';
import {
  CellItemWithEntity,
  LoggedTimeCell,
  PersonCell,
  ProjectCell,
} from '@float/types/cells';
import { TaskStatusEnum } from '@float/types/taskStatus';

type CellType = PersonCell | ProjectCell | LoggedTimeCell | undefined;

function isPersonCell(cell: CellType): cell is PersonCell {
  if (!cell || !cell.rowId) return false;

  return cell.rowId.startsWith('person-');
}

function removeDraftTasksBelongingToOtherProjects(
  cellItems: PersonCell['items'],
  projectId: number,
) {
  const filteredCellItems: CellItemWithEntity[] = [];
  const draftTasksInOtherProjects: CellItemWithEntity<'task'>[] = [];

  for (let i = 0; i < cellItems.length; i++) {
    const item = cellItems[i];
    const isDraftTaskInAnotherProject =
      item.type === 'task' &&
      item.entity?.status === TaskStatusEnum.Draft &&
      item.entity.project_id !== projectId;

    if (isDraftTaskInAnotherProject) {
      draftTasksInOtherProjects.push(item);
      continue;
    }

    filteredCellItems.push(item);
  }

  return { draftTasksInOtherProjects, filteredCellItems };
}

function clonePersonCell(cell: PersonCell, cellItems: CellItemWithEntity[]) {
  const clonedCell: PersonCell = fastObjectSpread(cell);

  // Reset the height and clone the dayHours array so that the overtime
  // calculations become unique to this cell.
  clonedCell.height = 0;
  clonedCell.dayHours = [...cell.dayHours];

  // Clone the items array so that we can apply the vertical sorting logic
  // uniquely to the cloned items.
  clonedCell.items = new Array(cellItems.length);
  for (let i = 0; i < cellItems.length; i++) {
    clonedCell.items[i] = fastObjectSpread(cellItems[i]);
  }

  return clonedCell;
}

function recalculateDayHours(
  clonedCell: PersonCell,
  draftTasksFromOtherProjects: CellItemWithEntity<'task'>[],
) {
  for (let i = 0; i < draftTasksFromOtherProjects.length; i++) {
    const item = draftTasksFromOtherProjects[i];
    if (item.x === undefined || item.w === undefined) continue;

    // Remove the the draft task hours from the day's total.
    for (let j = item.x; j < item.x + item.w; j++) {
      clonedCell.dayHours[j] = subtractOperation(
        clonedCell.dayHours[j],
        item.entity.hours,
      );
    }
  }
}

/**
 * In Project plan view, a person can appear under multiple projects, but the
 * underlying "PersonCell" data is the same. Customizations for a person's row
 * under a project are handled during rendering (e.g., ghost tasks). Draft
 * tasks should only appear in the person's row for the project they belong to
 * and should not appear in other projects' rows. This function clones the
 * "PersonCell" in these cases, filtering out draft tasks from other projects
 * and recalculating y-positioning and overtime.
 *
 * @param cell Cell to process.
 * @param projectId Project ID under which this person row belongs.
 */
export function getCellForProjectPlan(cell: CellType, projectId?: number) {
  if (!cell || !projectId) return cell;

  // We only want to process people cells rendered in the context of a project in
  // Project plan view type, to filter out draft allocations from other projects.
  if (!isPersonCell(cell)) return cell;

  if (!cell.items?.length) return cell;

  const { draftTasksInOtherProjects, filteredCellItems } =
    removeDraftTasksBelongingToOtherProjects(cell.items, projectId);

  if (!draftTasksInOtherProjects.length) return cell;

  // Now that we have filtered out draft tasks from other projects, we need to
  // clone the cell, recalculate the y-positioning and overtime metrics.
  const clonedCell: PersonCell = clonePersonCell(cell, filteredCellItems);

  recalculateDayHours(clonedCell, draftTasksInOtherProjects);
  sortCellItemsY(clonedCell, undefined, overlaps);

  return clonedCell;
}
