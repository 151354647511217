import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { t } from '@lingui/macro';
import { keyBy } from 'lodash';

import { addRole } from '@float/common/actions';
import { getRolesOptions } from '@float/common/selectors/roles';
import { getRateSuffixForRoleOption } from '@float/common/selectors/roles.helpers';
import {
  useAppDispatchStrict,
  useAppSelectorStrict,
} from '@float/common/store';
import { CostRateHistoryPayload } from '@float/types/costRate';
import { Checkbox } from '@float/ui/components/Checkbox';
import { CheckboxProps } from '@float/ui/components/Checkbox/types';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';
import { getCurrencyProps } from '@float/web/selectors';

import { useRoleOptionsWithRateElements } from '../PersonModal/components/PersonRole/hooks/useRoleOptionsWithRateElements';
import {
  doesSelectedRoleHaveRates,
  getRatesToResetForSelectedRole,
} from './Role.helpers';

import * as stylesCommon from './BulkEditPeopleModal.css';
import * as styles from './Role.css';

export type RoleProps = {
  value: number | string | null;
  onChange: (value: RoleProps['value']) => void;
};

export type RoleHandle = {
  getFields: () => Promise<{
    role_id: number | null;
    default_hourly_rate?: string | null;
    cost_rate_history?: CostRateHistoryPayload;
  }>;
};

export const Role = forwardRef<RoleHandle, RoleProps>((props, ref) => {
  const { value, onChange } = props;

  const dispatch = useAppDispatchStrict();

  const currencyConfig = useAppSelectorStrict(getCurrencyProps);

  const rolesOptions = useAppSelectorStrict(getRolesOptions);

  const rolesOptionsById = useMemo(
    () => keyBy(rolesOptions, 'value'),
    [rolesOptions],
  );

  const rolesOptionsWithRatesElements: VirtualSelectOption[] =
    useRoleOptionsWithRateElements({ rolesOptions, currencyConfig });

  const roleSelected =
    value && rolesOptionsById[value] ? rolesOptionsById[value] : null;

  const rateSuffixForSelectedRole = getRateSuffixForRoleOption(
    roleSelected,
    currencyConfig,
  );

  const rateSuffixElement = rateSuffixForSelectedRole ? (
    <span className={stylesCommon.roleRateSelected}>
      {rateSuffixForSelectedRole}
    </span>
  ) : null;

  const [shouldApplyRoleRate, setShouldApplyRoleRate] = useState(false);

  const handleChangeRole = (e: { value: number | string }) => {
    onChange(e.value);
  };

  const handleChangeApplyRoleRate: CheckboxProps['onChange'] = (
    applyNewRoleRate,
  ) => {
    if (typeof applyNewRoleRate !== 'boolean') return;

    setShouldApplyRoleRate(applyNewRoleRate);
  };

  // Exposing getFields as so the BulkeEditModal component can use the getFields method before submitting
  useImperativeHandle(
    ref,
    () => {
      return {
        /*
         * The "Role" VirtualSelect differentiates between new and existing roles by the datatype of `value`:
         *   - For new roles, `value` is set as a string (the role name).
         *   - For existing roles, `value` is a number (the role_id)
         *
         * Creating a new role before `bulkUpdate` is called prevents an unnecessary rerender of the People table.
         */
        async getFields() {
          const ratesField = getRatesToResetForSelectedRole({
            shouldApplyRoleRate,
            roleSelected,
          });

          const shouldCreateANewRole = typeof value === 'string';

          const roleId = shouldCreateANewRole
            ? (
                await dispatch(
                  addRole(
                    { name: value },
                    { trackEvent: true, from: 'people-management-bulk' },
                  ),
                )
              ).id
            : value;

          const roleIdField = {
            role_id: roleId,
          };

          // We need to ensure we call the onChange to use the new numerical value,
          // otherwise when the flow is interrupted with confirm modal, there would be an attempt
          // to create a role with a same name value which will cause a runtime error
          if (shouldCreateANewRole) onChange(roleId);

          return {
            ...roleIdField,
            ...ratesField,
          };
        },
      };
    },
    [roleSelected, shouldApplyRoleRate, value, dispatch, onChange],
  );

  return (
    <div className={styles.wrapper}>
      <div>
        <VirtualSelect
          // @ts-expect-error TS doesn't infer PropTypes from VirtualSelect
          label={t`Role`}
          visibleItems={6}
          creatable
          clearInputOnDropdownOpen={false}
          options={rolesOptionsWithRatesElements}
          value={value}
          onChange={handleChangeRole}
          suffix={rateSuffixElement}
          hideSelectedIcon={true}
        />
      </div>

      {doesSelectedRoleHaveRates(roleSelected) && (
        <div>
          <Checkbox
            label={t`Apply new Role rates`}
            checked={shouldApplyRoleRate}
            onChange={handleChangeApplyRoleRate}
          />
        </div>
      )}
    </div>
  );
});
