import React, { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Trans } from '@lingui/macro';
import { Location } from 'history';
import { debounce, isArray, isNil } from 'lodash';

import {
  updateUserPref,
  updateUserPrefLocally,
} from '@float/common/actions/currentUser';
import * as searchActions from '@float/common/actions/search';
import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { userCanOnlyViewThemself } from '@float/common/lib/rights';
import { isOr } from '@float/common/search/helpers';
import {
  SearchAutocompleteParams,
  SearchAutocompleteResults,
} from '@float/common/search/selectors/getSearchAutocompleteResults';
import { usePaginatedSearchAutocompleteCallback } from '@float/common/search/usePaginatedSearchAutocompleteCallback';
import {
  getCurrentViewContainsMeFilter,
  isViewApplied,
} from '@float/common/selectors/views';
import {
  useAppDispatch,
  useAppDispatchDecorator,
  useAppSelectorStrict,
} from '@float/common/store';
import {
  FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING,
  SearchCategoryExcludingContains,
} from '@float/constants/search';
import { useIsMediumMediaSizeActive } from '@float/libs/hooks/media';
import { CurrentUser } from '@float/types/account';
import { Hotkeys } from '@float/ui/deprecated/Hotkeys/Hotkeys';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import {
  SnackbarMethods,
  useSnackbar,
} from '@float/ui/deprecated/Snackbar/useSnackbar';
import { getUser, selectIsMeFilterActive } from '@float/web/selectors';
import type {
  FilterOperators,
  FilterToken,
  SearchAutocompleteCategory,
  VirtualFilterTypes,
} from '@float/types';

import { EditableFilter } from '../components/SearchFilterDropdown/types';
import { trackSavedSearchApply } from '../lib/tracking/trackSavedSearchApply';
import { ModalConfig, useManageModal } from '../modalManager/useManageModal';
import { measureInteractivity } from '../perfMonitoring/interactivity';
import DeleteBookmarkModal from './components/DeleteBookmarkModal.jsx';
import { FilterButton } from './components/FilterButton';
import SearchAutocomplete from './components/SearchAutocomplete.jsx';
import { ViewsSelectorContainer } from './components/ViewsSelectorContainer';
import {
  fetchSearchAutocompleteResults,
  getEmptyFilteredContext,
  getKeydownHandler,
  getSearchPlaceholder,
  getSearchTotalResultsCount,
} from './Search.helpers';
import {
  AutocompleteResultsPaginationState,
  SearchState,
} from './Search.types';
import { SearchFilterTokens } from './SearchFilterTokens';
import StyledSearch from './StyledSearch';

type SearchInnerProps = {
  actions: {
    addFilters: typeof searchActions.addFilters;
    deleteSearch: (name: string) => Promise<unknown>;
    manageModal: (modalConfig: ModalConfig) => void;
    removeAllFilters: typeof searchActions.removeAllFilters;
    removeFilter: typeof searchActions.removeFilter;
  };
  currentUser: CurrentUser;
  currentViewContainsMeFilter: boolean;
  filters: FilterToken[];
  getSearchAutocompleteResults: (params: SearchAutocompleteParams) => Promise<{
    filteredContext: SearchAutocompleteResults;
    hasNextPage: () => boolean;
    fetchNextPage: () => Promise<SearchAutocompleteResults>;
  }>;
  isViewApplied: boolean;
  location: Location;
  logTimeView: boolean;
  isMediumBreakpointActive: boolean;
  meFilter: boolean;
  showSnackbar: SnackbarMethods['showSnackbar'];
  toggleMeFilter: (value: boolean) => void;
  toggleMeFilterLocally: (value: boolean) => void;
  setViewType: (val: 'people' | 'projects') => void;
};

const DEFAULT_STATE = {
  currentInput: '',
  showDropdown: false,
  highlightedIndex: undefined,
  editingFilterIndex: undefined,
  expandedCategory: undefined,
  moreFiltersPopupOpen: false,
  isLoading: false,
};

class SearchInner extends React.Component<SearchInnerProps, SearchState> {
  state: SearchState = {
    ...DEFAULT_STATE,
    filteredContext: getEmptyFilteredContext(),
  };

  inputRef = React.createRef<HTMLInputElement>(); // for focusing input
  listPopoverRef = React.createRef<HTMLDivElement>(); // exclusion target
  modalRef = React.createRef<HTMLElement>(); // exclusion target
  addFilterRef = React.createRef<HTMLButtonElement>(); // filter dropdown target for keyboard shortcut "f"
  excludeRefs = [this.listPopoverRef, this.modalRef];

  updateState = (newState: SearchState) => {
    this.setState(newState);
  };

  // ---------------------------------------------------------------------------
  // !!! Ref tracking ----------------------------------------------------------
  // ---------------------------------------------------------------------------

  listRef:
    | {
        scrollToRow: (index: SearchState['highlightedIndex']) => void;
      }
    | undefined;

  // ---------------------------------------------------------------------------
  // !!! Filter actions --------------------------------------------------------
  // ---------------------------------------------------------------------------

  addFilter = (
    f:
      | { type: 'savedSearch'; filters: FilterToken[]; val: string }
      | {
          type: VirtualFilterTypes;
          filter?: FilterToken;
          val: string | string[];
        },
    operator = this.state.filteredContext.operator,
    editingFilterIndex = this.state.editingFilterIndex,
    multiSelect = false,
  ) => {
    if (!f) {
      return;
    }

    measureInteractivity.trackSingleInteraction('ADD_SEARCH_FILTER');

    const isAppending = isNil(editingFilterIndex);
    const totalFilters = this.props.filters.length;
    const filters = this.props.filters;

    if (isAppending && !multiSelect) {
      const lastCategory = filters[totalFilters - 1]?.type;

      if (!isOr(operator)) {
        if (f.type === lastCategory) {
          operator = `${operator || ''}|`;
        }
      }
    }

    if (f.type === 'savedSearch') {
      trackSavedSearchApply(f);
      this.props.actions.addFilters(f.filters);
    } else {
      if (!multiSelect && isArray(f.val) && f.val.length === 1)
        f.val = f.val[0];
      const toAdd = [{ ...f, operator }];
      this.props.actions.addFilters(toAdd, { editingFilterIndex });
    }

    if (multiSelect) {
      if (isAppending) {
        this.setState({ editingFilterIndex: totalFilters });
      }
    } else {
      this.closeDropdown();
    }
  };

  // https://linear.app/float-com/issue/FT-2334/bug-can-deselect-item-when-theres-only-one-selection
  removeLastValueFromFilter = (filter: FilterToken) => {
    const selectedFilter = this.props.filters.find(
      (f) => f.type === filter.type,
    );

    if (selectedFilter) {
      this.props.actions.removeFilter(selectedFilter);
      this.setState({ ...this.state, editingFilterIndex: undefined });
    }
  };

  removeFilter = (filter: FilterToken) => {
    measureInteractivity.trackSingleInteraction('REMOVE_SEARCH_FILTER');

    this.props.actions.removeFilter(filter);
    this.closeDropdown();
  };

  clearFilters = () => {
    measureInteractivity.trackSingleInteraction('CLEAR_SEARCH_FILTERS');

    this.props.actions.removeAllFilters();

    if (this.props.meFilter) {
      this.props.toggleMeFilter(false);
    }
  };

  setIsLoading = (isLoading: boolean) => {
    this.setState({ isLoading });
  };

  lastAutocompleteInputRef = { current: '' };
  lastAutocompleteCategoryRef = { current: undefined };
  autocompleteResultsPaginationStateRef: {
    current: AutocompleteResultsPaginationState | undefined;
  } = {
    current: undefined,
  };

  fetchingNextPage = false;

  fetchNextPage = async () => {
    if (!this.autocompleteResultsPaginationStateRef.current) return;
    if (!this.autocompleteResultsPaginationStateRef.current?.hasNextPage())
      return;
    if (this.fetchingNextPage) return;

    this.setIsLoading(true);

    // Ensure that only one fetchNextPage is in progress
    // We need to use a class property because state updates are async
    // and might not work on preventing parallel fetches
    this.fetchingNextPage = true;

    const filteredContext =
      await this.autocompleteResultsPaginationStateRef.current.fetchNextPage();

    this.fetchingNextPage = false;

    // Avoid concurrency issues
    if (
      !this.autocompleteResultsPaginationStateRef.current ||
      this.lastAutocompleteCategoryRef.current !==
        this.autocompleteResultsPaginationStateRef.current.expandedCategory ||
      this.lastAutocompleteInputRef.current !==
        this.autocompleteResultsPaginationStateRef.current.currentInput
    ) {
      return;
    }

    this.setState({ filteredContext });
    this.setIsLoading(false);
  };

  showDeleteBookmarkModal = (filter: { val: string | string[] }) => {
    this.props.actions.manageModal({
      visible: true,
      modalType: 'deleteBookmarkModal',
      modalSettings: {
        term: filter.val,
      },
    });
  };

  deleteBookmark = ({ name }: { name: string }) => {
    this.props.actions.deleteSearch(name).then(() => {
      this.props.showSnackbar(`${name} deleted.`);
    });
  };

  focusInput = () => {
    if (this.inputRef?.current) {
      this.inputRef.current.focus();
    }
  };

  toggleMeFilter = () => {
    if (this.props.location.pathname === '/log-time' && this.props.meFilter) {
      return;
    }

    if (!this.props.currentUser.people_id && !this.props.meFilter) {
      // Turning on Me filter is not applicable to guests
      return;
    }

    this.props.toggleMeFilter(!this.props.meFilter);
    this.closeDropdown();
  };

  activateCategory = (
    category: SearchCategoryExcludingContains | undefined,
    operator?: FilterToken['operator'],
  ) => {
    if (category === 'me') {
      return this.toggleMeFilter();
    }

    const { state } = this;

    const newState = {
      filteredContext: getEmptyFilteredContext(),
      expandedCategory:
        category === state.expandedCategory ? undefined : category,
    };

    if (operator) {
      newState.filteredContext = { ...state.filteredContext, operator };
    }

    this.setState(newState, this.focusInput);

    fetchSearchAutocompleteResults(
      state.currentInput,
      newState.expandedCategory,
      operator,
      this.lastAutocompleteInputRef,
      this.lastAutocompleteCategoryRef,
      this.autocompleteResultsPaginationStateRef,
      this.updateState,
      this.setIsLoading,
      this.props.getSearchAutocompleteResults,
    );
  };

  setOperator = (operator: FilterOperators | '' | undefined) => {
    this.setState((ps) => ({
      filteredContext: {
        ...ps.filteredContext,
        operator,
      },
    }));
  };

  // ---------------------------------------------------------------------------
  // !!! Key and mouse events --------------------------------------------------
  // ---------------------------------------------------------------------------

  openDropdown = () => {
    if (!this.addFilterRef.current) return;
    this.addFilterRef.current.click();
  };

  closeDropdown = () => {
    if (this.state.showDropdown) {
      this.setState(DEFAULT_STATE);
    }
  };

  toggleMoreFilters = (open?: boolean) => {
    this.setState({ moreFiltersPopupOpen: Boolean(open) });

    if (open) {
      this.closeDropdown();
    }
  };

  closeMoreFilters = () => {
    if (this.state.moreFiltersPopupOpen) {
      this.setState({ moreFiltersPopupOpen: false });
    }
  };

  closeAllDropdowns = () => {
    this.closeMoreFilters();
    this.closeDropdown();
  };

  shouldCloseDropdownonOnFilterClick = ({
    index,
    filter,
    expandCategory,
  }: {
    index: number | undefined;
    filter: FilterToken | undefined;
    expandCategory?: boolean;
  }) => {
    if (!this.state.showDropdown) return false;
    if (this.state.editingFilterIndex !== index) return false;
    const clickedOnValue = expandCategory;

    if (clickedOnValue) {
      if (!filter) {
        return false;
      }

      if (
        this.state.expandedCategory !==
        FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING[filter.type]
      ) {
        return false;
      }
    }

    if (!clickedOnValue && this.state.expandedCategory) return false;
    return true;
  };

  onFilterClick = (params: {
    targetEl?: HTMLButtonElement | null | undefined;
    event?: React.MouseEvent;
    index: number | undefined;
    filter: FilterToken | undefined;
    expandCategory?: boolean;
  }) => {
    if (this.shouldCloseDropdownonOnFilterClick(params)) {
      this.closeDropdown();
      return;
    }

    const { event, targetEl, index, filter, expandCategory = false } = params;
    const rect = (
      targetEl || (event?.currentTarget as HTMLElement)
    ).getBoundingClientRect();

    let expandedCategory: SearchAutocompleteCategory | undefined = undefined;

    if (expandCategory && filter && filter.type) {
      const searchCategory =
        FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING[filter.type];

      // `contains` and `me` are not expandable categories
      if (searchCategory !== 'contains' && searchCategory !== 'me') {
        expandedCategory = searchCategory;
      }
    }

    const update: SearchState = {
      showDropdown: true,
      editingFilterIndex: index,
      highlightedIndex: 0,
      currentInput: filter?.type === 'contains' ? (filter.val as string) : '',
      expandedCategory,
      dropdownStyle: { top: rect.top + rect.height, left: rect.left },
      filteredContext: this.state.filteredContext,
      moreFiltersPopupOpen: this.state.moreFiltersPopupOpen,
      isLoading: this.state.isLoading,
    };

    const categoryChanged =
      expandedCategory && expandedCategory !== this.state.expandedCategory;
    if (categoryChanged) update.filteredContext = getEmptyFilteredContext();

    if (filter && !isNil(filter?.operator)) {
      update.filteredContext = {
        ...update.filteredContext,
        operator: filter.operator,
      };
    }

    this.setState(update, this.focusInput);

    fetchSearchAutocompleteResults(
      this.state.currentInput,
      expandedCategory,
      update.filteredContext.operator,
      this.lastAutocompleteInputRef,
      this.lastAutocompleteCategoryRef,
      this.autocompleteResultsPaginationStateRef,
      this.updateState,
      this.setIsLoading,
      this.props.getSearchAutocompleteResults,
    );
  };

  onAddClick = (event?: React.MouseEvent) => {
    this.closeMoreFilters();
    this.onFilterClick({ event, index: undefined, filter: undefined });
  };

  setHighlightedIndex = (highlightedIndex: SearchState['highlightedIndex']) => {
    this.setState({ highlightedIndex });
  };

  debouncedFetchSearchAutocompleteResults = debounce(
    fetchSearchAutocompleteResults,
    200,
  );

  setInputValue = (currentInput: string) => {
    const update = {
      currentInput,
      highlightedIndex: currentInput === '' ? undefined : 0,
    };

    this.setState(update);

    this.debouncedFetchSearchAutocompleteResults(
      currentInput,
      this.state.expandedCategory,
      undefined,
      this.lastAutocompleteInputRef,
      this.lastAutocompleteCategoryRef,
      this.autocompleteResultsPaginationStateRef,
      this.updateState,
      this.setIsLoading,
      this.props.getSearchAutocompleteResults,
    );
  };

  // ---------------------------------------------------------------------------
  // !!! React Lifecycle -------------------------------------------------------
  // ---------------------------------------------------------------------------

  componentDidMount() {
    this.adjustMeFilterIfMovingToOrFromLogMyTimePage(this.props);
  }

  componentDidUpdate(prevProps: SearchInnerProps, prevState: SearchState) {
    const filtersChanged = this.props.filters !== prevProps.filters;

    const newIndex = this.state.highlightedIndex !== prevState.highlightedIndex;
    if (this.listRef && newIndex) {
      // If highlightedIndex is undefined, we want to scroll to top
      this.listRef.scrollToRow(this.state.highlightedIndex || 0);
    }

    if (filtersChanged) {
      return;
    }

    const routeChanged =
      prevProps.location?.pathname !== this.props.location?.pathname;

    if (routeChanged) {
      this.adjustMeFilterIfMovingToOrFromLogMyTimePage(prevProps);
    }
  }

  // ---------------------------------------------------------------------------
  // !!! Helpers ---------------------------------------------------------------
  // ---------------------------------------------------------------------------

  previousMeFilter: undefined | false = undefined;

  adjustMeFilterIfMovingToOrFromLogMyTimePage = (
    prevProps: SearchInnerProps,
  ) => {
    // Applying the me filter on "log my time" page allows us to
    // filter by other criteria, while ignoring people filters.

    if (this.props.location.pathname === '/log-time' && !this.props.meFilter) {
      this.previousMeFilter = false;
      this.props.toggleMeFilterLocally(true);
    } else if (prevProps.location.pathname === '/log-time') {
      if (this.previousMeFilter === false) {
        this.previousMeFilter = undefined;
        this.props.toggleMeFilterLocally(false);
      }
    }
  };

  getPlaceholder = () => {
    const { expandedCategory } = this.state;

    if (expandedCategory) {
      const { categorySizes } = this.state.filteredContext;
      const size = categorySizes?.[expandedCategory];
      return getSearchPlaceholder(expandedCategory, size?.total);
    }

    return 'Search';
  };

  getTotalResultsCount = () => {
    const { expandedCategory, filteredContext } = this.state;
    const { categorySizes } = filteredContext;

    return getSearchTotalResultsCount(categorySizes, expandedCategory);
  };

  isLogMyTimeView = () => {
    return this.props.location?.pathname === '/log-time';
  };

  canOnlyViewSelf = () => {
    const { currentUser: user } = this.props;
    return userCanOnlyViewThemself(user);
  };

  shouldRenderMeFilter = () => {
    if (this.isLogMyTimeView() || this.canOnlyViewSelf()) return false;
    if (this.props.currentViewContainsMeFilter) return false;

    return this.props.meFilter;
  };

  // ---------------------------------------------------------------------------
  // !!! Render ----------------------------------------------------------------
  // ---------------------------------------------------------------------------

  renderSearchAutocomplete() {
    if (!this.state.showDropdown) return null;

    return createPortal(
      <SearchAutocomplete
        listPopoverRef={this.listPopoverRef}
        editingFilter={
          // This is technically safe because the `editingFilterIndex` will always
          // return an EditableFilter, but a better solution would be preferred!
          this.props.filters[this.state.editingFilterIndex!] as EditableFilter
        }
        style={this.state.dropdownStyle}
        filteredContext={this.state.filteredContext}
        isLoading={this.state.isLoading}
        currentInput={this.state.currentInput}
        highlightedIndex={this.state.highlightedIndex}
        expandedCategory={this.state.expandedCategory}
        isMeFilterVisible={Boolean(this.props.currentUser.people_id)}
        isPeopleFilterVisible={
          !this.props.meFilter &&
          !this.canOnlyViewSelf() &&
          !this.isLogMyTimeView()
        }
        isSavedSearchesFilterVisible={Boolean(
          Object.keys(this.props.currentUser.savedSearches).length,
        )}
        setHighlightedIndex={this.setHighlightedIndex}
        addFilter={this.addFilter}
        removeLastValueFromFilter={this.removeLastValueFromFilter}
        deleteSavedSearch={this.showDeleteBookmarkModal}
        activateCategory={this.activateCategory}
        setOperator={this.setOperator}
        getPlaceholder={this.getPlaceholder}
        totalResultsCount={this.getTotalResultsCount()}
        inputRef={this.inputRef}
        onInputKeyDown={getKeydownHandler(
          this.state.isLoading,
          this.state.highlightedIndex,
          this.state.filteredContext.result,
          this.updateState,
          this.addFilter,
          this.closeDropdown,
        )}
        setInputValue={this.setInputValue}
        fetchNextPage={this.fetchNextPage}
      />,
      document.body,
    );
  }

  keyMap = {
    f: this.openDropdown,
    m: this.toggleMeFilter,
    Escape: this.closeDropdown,
  };

  render() {
    const { filters, isViewApplied, isMediumBreakpointActive } = this.props;
    const isViewAppliedOnMediumScreen =
      isViewApplied && isMediumBreakpointActive;

    const shouldShowEmptySearch =
      !isViewAppliedOnMediumScreen &&
      !filters.length &&
      !this.shouldRenderMeFilter();

    return (
      <>
        <StyledSearch isTokenSearch>
          <ViewsSelectorContainer onAddFilterClick={this.openDropdown} />
          <Spacer size={8} />
          {shouldShowEmptySearch ? (
            <>
              <FilterButton
                active={this.state.showDropdown}
                closeAllDropdowns={this.closeAllDropdowns}
                excludeRefs={this.excludeRefs}
                onClick={this.onAddClick}
                ref={this.addFilterRef}
                showDropdown={this.state.showDropdown}
              />
              {isViewApplied && (
                <>
                  <Spacer size={8} />
                  <NavIconBtn noBorder onClick={this.clearFilters}>
                    <Trans>Clear all</Trans>
                  </NavIconBtn>
                </>
              )}
            </>
          ) : (
            <SearchFilterTokens
              addFilter={this.addFilter}
              addFilterRef={this.addFilterRef}
              clearFilters={this.clearFilters}
              closeAllDropdowns={this.closeAllDropdowns}
              excludeRefs={this.excludeRefs}
              filters={filters}
              moreFiltersPopupOpen={this.state.moreFiltersPopupOpen}
              onAddClick={this.onAddClick}
              onFilterClick={this.onFilterClick}
              removeFilter={this.removeFilter}
              shouldRenderMeFilter={this.shouldRenderMeFilter()}
              showDropdown={this.state.showDropdown}
              toggleMeFilter={this.toggleMeFilter}
              toggleMoreFilters={this.toggleMoreFilters}
              editingFilterIndex={this.state.editingFilterIndex}
            />
          )}
        </StyledSearch>

        <Hotkeys ignorePriority keyMap={this.keyMap} />
        <DeleteBookmarkModal
          modalRef={this.modalRef}
          onDelete={this.deleteBookmark}
        />
        {this.renderSearchAutocomplete()}
      </>
    );
  }
}

export type SearchProps = {
  location: Location;
  logTimeView: boolean;
  setViewType: (val: 'people' | 'projects') => void;
};

export function Search(props: SearchProps) {
  const isMediumBreakpointActive = useIsMediumMediaSizeActive();
  const { showSnackbar } = useSnackbar();

  const filters = useAppSelectorStrict((state) => state.search.filters);
  const currentUser = useAppSelectorStrict(getUser);
  const meFilter = useAppSelectorStrict(selectIsMeFilterActive);
  const currentViewContainsMeFilter = useAppSelectorStrict(
    getCurrentViewContainsMeFilter,
  );
  const isViewAppliedValue = useAppSelectorStrict(isViewApplied);

  const getSearchAutocompleteResults = usePaginatedSearchAutocompleteCallback();
  const addFilters = useAppDispatchDecorator(searchActions.addFilters);
  const removeFilter = useAppDispatchDecorator(searchActions.removeFilter);
  const removeAllFilters = useAppDispatchDecorator(
    searchActions.removeAllFilters,
  );
  const { manageModal } = useManageModal();

  const dispatch = useAppDispatch();

  const deleteSearch = useCallback(
    async (name: string) => {
      await dispatch(searchActions.deleteSearch(name));
    },
    [dispatch],
  );

  const toggleMeFilter = useCallback(
    async (val: boolean) => {
      await dispatch(updateUserPref('me_filter', val));
    },
    [dispatch],
  );

  const toggleMeFilterLocally = useCallback(
    async (val: boolean) => {
      await dispatch(updateUserPrefLocally('me_filter', val));
    },
    [dispatch],
  );

  const actions = useMemo<SearchInnerProps['actions']>(
    () => ({
      addFilters,
      deleteSearch,
      manageModal,
      removeAllFilters,
      removeFilter,
    }),
    [addFilters, deleteSearch, manageModal, removeAllFilters, removeFilter],
  );

  return (
    <SearchInner
      actions={actions}
      currentUser={currentUser}
      currentViewContainsMeFilter={currentViewContainsMeFilter}
      filters={filters}
      getSearchAutocompleteResults={getSearchAutocompleteResults}
      isViewApplied={isViewAppliedValue}
      location={props.location}
      logTimeView={props.logTimeView}
      isMediumBreakpointActive={isMediumBreakpointActive}
      meFilter={meFilter}
      setViewType={props.setViewType}
      showSnackbar={showSnackbar}
      toggleMeFilter={toggleMeFilter}
      toggleMeFilterLocally={toggleMeFilterLocally}
    />
  );
}
