import { SearchResolveFilterOperator } from '@float/common/api3/search.constants';

export const invertSearchResolveQueryOperator = (
  operator: SearchResolveFilterOperator,
) => {
  if (operator === SearchResolveFilterOperator.Is) {
    return SearchResolveFilterOperator.IsNot;
  }

  if (operator === SearchResolveFilterOperator.IsNot) {
    return SearchResolveFilterOperator.Is;
  }

  return operator;
};
