import { t } from '@lingui/macro';

import PeopleType from '../PeopleType';

export function getBulkEditPeopleTypeField() {
  return {
    label: t`Type`,
    render: PeopleType,
    getDefaultValueForMixedSelection: () => 1,
  };
}
