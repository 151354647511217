import { useHistory } from 'react-router-dom';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Project } from '@float/types/project';

export const useOpenSingleProjectView = () => {
  const history = useHistory();
  const openSingleProjectView = (projectId?: Project['project_id']) => {
    if (
      Boolean(projectId) &&
      featureFlags.isFeatureEnabled(FeatureFlag.SingleProjectView)
    ) {
      history.push(`/project/${projectId}`);
    }
  };

  return openSingleProjectView;
};
