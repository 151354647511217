import { cloneDeep, mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { datesCreatorWithGlobalCache } from '@float/libs/dates/datesCreatorWithGlobalCache';
import type {
  AccountType,
  CompanyPreferences,
  CurrencyProps,
  CurrentUser,
} from '@float/types';

import { getIsCurrencyPrefix } from '../lib/currency';
import { getUserAccess, Rights, userCanOnlyViewThemself } from '../lib/rights';
import { getSubdepartments } from './departments';
import type { AccountsState } from '../reducers/accounts';
import type { SettingsBillingInfoState } from '../reducers/lib/types';

export const getCurrentUserRaw = (state: { currentUser: CurrentUser }) =>
  state.currentUser;

export const selectCurrentUserAccount = createSelector(
  [getCurrentUserRaw, (state) => state.accounts.accounts],
  (currentUser, accounts) => accounts[currentUser.account_id],
);

export const getUser = createSelector(
  [
    getCurrentUserRaw,
    (state: { accounts: AccountsState }) => state.accounts.accounts,
    (state: { companyPrefs: CompanyPreferences }) => state.companyPrefs,
    getSubdepartments,
  ],
  (currentUser, accounts, companyPrefs, subdepartments): CurrentUser => {
    const currentWorkDaysHours =
      companyPrefs.work_days_hours ||
      (companyPrefs.work_days_hours_history
        ? companyPrefs.work_days_hours_history[0][1]
        : undefined);

    const account = currentUser.account ?? accounts[currentUser.account_id];

    const userData = {
      ...companyPrefs,
      ...currentUser,
      // In mobile app, account is coming with user
      account: account,
      hide_non_wk_days: Number(companyPrefs.hide_non_wk_days),
      start_work_week: Number(companyPrefs.start_work_week),
      appcid: `${currentUser.cid}-${currentUser.admin_id}`,
      work_days_hours: currentWorkDaysHours,
    };

    if (account) {
      const managedDeps = account.management_group?.departments || [];
      const departmentFilter = account.department_filter || [];

      const expandWithSubDepartments = (departments: number[]) => {
        return departments.flatMap((depId) => {
          const subdeps = subdepartments[depId];

          return [depId, ...(subdeps || [])];
        });
      };

      // only used by the rights.json engine;
      account.allManagedDepartments = expandWithSubDepartments(managedDeps);
      account.department_filter_all =
        expandWithSubDepartments(departmentFilter);
      userData.department_filter_all = account.department_filter_all;
    }

    if (!userData.prefs) userData.prefs = {};

    return userData as CurrentUser;
  },
);

export const getPrefs = createSelector(
  [(state) => state.currentUser.prefs],
  (prefs) => {
    if (!prefs) return {};

    const p = cloneDeep(prefs);

    try {
      if (p.custom_priority) {
        p.custom_priority = mapValues(p.custom_priority, (val, key) => {
          return val.map(Number);
        });
      }
    } catch (e) {
      delete p.custom_priority;
    }

    return p;
  },
);

export const getNotificationPrefs = createSelector(
  [(state) => state.currentUser.prefs],
  (prefs) => {
    const notifPrefs = {
      myTasks: true,
      allTasks: true,
      timeoffs: true,
      teamTimeoffs: true,
      timeoffApprovals: true,
    };

    if (prefs.notif_my_tasks == 0) {
      notifPrefs.myTasks = false;
    }

    if (prefs.notif_all_tasks == 0) {
      notifPrefs.allTasks = false;
    }

    if (prefs.notif_timeoffs == 0) {
      notifPrefs.timeoffs = false;
    }

    if (prefs.notif_timeoff_approvals == 0) {
      notifPrefs.timeoffApprovals = false;
    }

    return notifPrefs;
  },
);

const accountIsMember = (account: { account_type_id: AccountType }) =>
  account && Number(account.account_type_id) === 4;

export const getAccessRights = createSelector([getUser], (currentUser) => {
  const isMember = accountIsMember(currentUser);
  const viewOnlyPeopleId = userCanOnlyViewThemself(currentUser)
    ? currentUser.people_id
    : null;

  return {
    isMember,
    viewOnlyPeopleId,
  };
});

export const getShowUpgradeNotice = createSelector(
  [
    (state) => state.settingsBillingInfo.is_trial,
    (state) => state.settingsBillingInfo.plan?.plan_id,
    (state) => state.settingsBillingInfo.trial_days_left,
    (state) => state.currentUser.account_tid,
  ],
  (isTrial, planId, trialDaysLeft, accountTypeId) => {
    const isAccountOwner = [1, 5].some((x) => x == accountTypeId);
    const showUpgrade = isAccountOwner && planId != 0 && isTrial;
    return {
      showUpgrade,
      expiringSoon: showUpgrade && trialDaysLeft <= 30,
    };
  },
);

export const getVisibleSettings = createSelector([getUser], (currentUser) => {
  const accountTypeId = currentUser.account_type_id;
  const isAccountOwner = accountTypeId == 1;
  const isBilling = accountTypeId == 5;

  const accessRights = getUserAccess(currentUser);
  const { isAdminOrHigher } = accessRights;

  return {
    general: isAccountOwner,
    api: isAccountOwner,
    billing: isAccountOwner || isBilling,
    security: isAccountOwner,
    teamActivity: accountTypeId !== 3,
    clients: accountTypeId !== 3,
    departments: accountTypeId !== 3,
    timeoffs: accountTypeId !== 3,
    status: accountTypeId !== 3,
    guests: accountTypeId !== 3,
    lockLoggedTime: accountTypeId !== 3,
    adminForTags: Rights.canDeleteTags(currentUser),
    roles: isAdminOrHigher(),
  };
});

export const getCurrentPlan = (state: {
  settingsBillingInfo: SettingsBillingInfoState;
}) => state.settingsBillingInfo?.plan;

export const getIsCurrentUserGuest = createSelector(
  [getCurrentUserRaw],
  (user) => !user.people_id,
);

export const selectCurrencyPropsRaw = createSelector(
  [
    (state: { companyPrefs: CompanyPreferences }) =>
      state.companyPrefs.currency,
    (state: { companyPrefs: CompanyPreferences }) =>
      state.companyPrefs.currency_symbol,
    (state: { currentUser: CurrentUser }) => state.currentUser.locale,
  ],
  (currency, currencySymbol, locale) => {
    return { currency, currencySymbol, locale };
  },
);

export const getCurrencyProps = createSelector(
  [selectCurrencyPropsRaw],
  ({ currency, currencySymbol, locale }): CurrencyProps =>
    getIsCurrencyPrefix(locale)
      ? { prefix: currencySymbol, currency }
      : { suffix: currencySymbol, currency },
);

export const selectIsMondayStart = createSelector([getUser], (user) => {
  return user.start_work_week === 1;
});

/**
 * Users can select to hide the weekend days
 *
 * When that's true this utility reads the user working days
 * to calculate the number weekdays that fall in the configured "weekend"
 */
export const getRightLeftHiddenDaysCount = (
  user: CurrentUser,
  mondayStart: boolean,
) => {
  if (!user.hide_non_wk_days) {
    return {
      leftHiddenDays: 0,
      rightHiddenDays: 0,
    };
  }

  // We don't want to support the case of different numbers of days per week.
  // If the user has hidden weekends and work days hours history, we want to
  // show the widest week to not hide days incorrectly.
  const hoursToConsider = user.work_days_hours_history
    ? user.work_days_hours_history.map((h) => h[1])
    : [user.work_days_hours];

  let minLeftHiddenDays = 8;
  let minRightHiddenDays = 8;

  hoursToConsider.forEach((h) => {
    const hours = [...h];

    if (mondayStart) {
      hours.push(hours.shift()!);
    }

    let leftHiddenDays = 0;
    while (leftHiddenDays < 7 && hours[leftHiddenDays] === 0) {
      leftHiddenDays++;
    }

    let rightHiddenDays = 0;
    while (
      rightHiddenDays < 7 &&
      hours[hours.length - 1 - rightHiddenDays] === 0
    ) {
      rightHiddenDays++;
    }

    minLeftHiddenDays = Math.min(minLeftHiddenDays, leftHiddenDays);
    minRightHiddenDays = Math.min(minRightHiddenDays, rightHiddenDays);
  });

  // Users configured all days as non-working days
  // Returning the hidden days in this case would break the app
  if (minLeftHiddenDays > 7 || minRightHiddenDays > 7) {
    return {
      leftHiddenDays: 0,
      rightHiddenDays: 0,
    };
  }

  return {
    leftHiddenDays: minLeftHiddenDays,
    rightHiddenDays: minRightHiddenDays,
  };
};

export const selectHiddenDays = createSelector(
  [getCurrentUserRaw, selectIsMondayStart],
  getRightLeftHiddenDaysCount,
);

export const selectDatesManager = createSelector(
  [selectIsMondayStart, selectHiddenDays],
  (mondayStart: boolean, { leftHiddenDays, rightHiddenDays }) => {
    return datesCreatorWithGlobalCache({
      mondayStart,
      leftHiddenDays,
      rightHiddenDays,
    });
  },
);
