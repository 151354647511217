import { createSelector, weakMapMemoize } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import {
  getUser,
  selectDatesManager,
} from '@float/common/selectors/currentUser';

import { selectEnhancedPersonById } from '../people/people';
import { getWorkHoursInRange } from './getWorkHoursInRange';
import { selectIsWorkDayGetter } from './selectIsWorkDayGetter';

type SelectPersonWorkHoursInRangeParams = {
  personId: number;
  startDate: string;
  endDate: string;
};

const selectParamPersonId = (
  state: ReduxStateStrict,
  params: SelectPersonWorkHoursInRangeParams,
) => params.personId;

const selectParamStartDate = (
  state: ReduxStateStrict,
  params: SelectPersonWorkHoursInRangeParams,
) => params.startDate;

const selectParamEndDate = (
  state: ReduxStateStrict,
  params: SelectPersonWorkHoursInRangeParams,
) => params.endDate;

const selectPerson = (
  state: ReduxStateStrict,
  params: SelectPersonWorkHoursInRangeParams,
) => selectEnhancedPersonById(state, selectParamPersonId(state, params));

/**
 * This is a memoized selector for the schedule, designed to prevent unnecessary recomputations. The
 * memoization is performed per person, so it only accepts a single person ID as a parameter.
 */
export const selectPersonWorkHoursInRange = createSelector(
  [
    getUser,
    selectDatesManager,
    selectIsWorkDayGetter,
    selectPerson,
    selectParamStartDate,
    selectParamEndDate,
  ],
  (currentUser, datesManager, getIsWorkDay, person, startDate, endDate) => {
    if (!person) {
      return 0;
    }
    return getWorkHoursInRange({
      start_date: startDate,
      end_date: endDate,
      people: [person],
      dates: datesManager,
      user: currentUser,
      getIsWorkDay,
    });
  },
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
  },
);
