import { PERSON_RELATED_KEYS } from '@float/common/search/helpers';

import {
  ExpandableSearchFilterDropdownCategoryItem,
  FiltersVisibility,
  SearchFilterDropdownCategoryItem,
} from '../types';

export function filterSearchCategories(
  data: Array<SearchFilterDropdownCategoryItem>,
  filtersVisibility: FiltersVisibility,
) {
  return data.filter(({ hideInAllView, value, type }) => {
    if (hideInAllView) return false; // Exclude the `contains` category
    if (value === 'me') return filtersVisibility.me && filtersVisibility.people;
    if (PERSON_RELATED_KEYS.includes(type)) return filtersVisibility.people;
    if (type === 'savedSearches') return filtersVisibility.savedSearches;

    return true;
  }) as Array<ExpandableSearchFilterDropdownCategoryItem>;
}
