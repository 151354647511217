import React, { useMemo, useRef } from 'react';
import cs from 'classnames';

import { areEqualExtractStyle } from '@float/common/components/Schedule/util/diff';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { todayManager } from '@float/libs/dates';
import { LoggedTimeCell, PersonCell, TopCell } from '@float/types';

import PlaceholderItem from '../MainCell/Item/PlaceholderItem';
import TimeRangeItem from '../MainCell/Item/TimeRangeItem';
import { ScheduleActions } from '../MainCell/types';

import * as styles from './index.css';

type TopCellInnerProps = {
  actions: ScheduleActions;
  cell: TopCell;
  colIdx: number;
  dateRange: string[];
  dates: DatesManager;
  dayWidth: number;
  disableTooltips: boolean;
  logMyTimeView: boolean;
  logTimeView: boolean;
  suvPersonCell: PersonCell | LoggedTimeCell | undefined;
  totalHeight: number;
  wrapperRef: React.RefObject<HTMLDivElement>;
};

const TopCellInner = React.memo(function TopCellInner(
  props: TopCellInnerProps,
) {
  const { dayWidth, totalHeight, colIdx, cell, actions, wrapperRef } = props;

  const {
    dates,
    scrollWrapperRef,
    suvSingleDay,
    singleUserView,
    cellsWrapper: { cells },
    hourHeight,
    numDays,
  } = useScheduleContext();

  const today = todayManager.getToday();
  const todayCollIdx = dates.toDescriptor(today)?.[0];

  const dateStart = dates.fromDescriptor(colIdx, 0);
  const dateEnd = dates.fromDescriptor(colIdx, numDays - 1);

  const dateStartDay = dates.extractDay(dateStart);
  const dateEndDay = dates.extractDay(dateEnd);

  const isCurrentWeek = todayCollIdx === colIdx;

  const cellItemsTimeRange =
    cell.items?.filter((item) => item.type === 'timeRange') || [];

  const cellItemsSelection =
    cell.items?.filter((item) => item.type === 'selection') || [];

  return (
    <>
      {cellItemsTimeRange.map((item) => (
        <TimeRangeItem
          key={item.key}
          item={item}
          dayWidth={dayWidth}
          totalHeight={totalHeight}
          actions={actions}
          wrapperRef={wrapperRef}
          scrollWrapperRef={scrollWrapperRef}
          singleUserView={singleUserView}
          dates={dates}
          isSingleProjectView={false}
        />
      ))}

      {cellItemsSelection.map((item) => (
        <PlaceholderItem
          key={item.key}
          item={item}
          dayWidth={dayWidth}
          hourHeight={hourHeight}
          fullDay
          suvSingleDay={suvSingleDay}
          cells={cells}
          actions={actions}
          wrapperRef={wrapperRef}
        />
      ))}

      <div
        className={cs(
          styles.weekDaysWrapper,
          isCurrentWeek && styles.weekDaysWrapperActive,
        )}
      >
        <span>
          {dateStartDay}-{dateEndDay}
        </span>
      </div>
    </>
  );
});

export type TopCellProps = {
  style: { width: number; left?: number };
  colIdx: number;
  dayWidth: number;
  totalHeight: number;
  cell: TopCell;
  suvPersonCell: PersonCell | LoggedTimeCell | undefined;
  actions: ScheduleActions;
  disableTooltips: boolean;
  rowGroupTop: number;
  rowIdx: number;
};

function TopCellComponent({
  style,
  colIdx,
  dayWidth,
  totalHeight,
  cell,
  suvPersonCell,
  actions,
  disableTooltips,
  rowGroupTop,
  rowIdx,
}: TopCellProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    dates,
    logTimeView,
    logMyTimeView,
    scrollWrapperRef,
    availableWidth,
  } = useScheduleContext();
  const numDays = style.width / dayWidth;
  let width = style.width;

  const dateRange = useMemo(() => {
    const start = colIdx * 7;
    const end = (colIdx + 1) * 7;

    dates.ensureRange(colIdx, colIdx + 1);

    return dates.extractVisibleRange(dates.fromNum(start), dates.fromNum(end));
  }, [dates, colIdx]);

  if (logMyTimeView) {
    width = dayWidth === 140 ? numDays * dayWidth : availableWidth;
  }

  return (
    <div
      className={styles.wrapper}
      ref={wrapperRef}
      style={{
        ...style,
        width,
      }}
      onMouseDown={
        logMyTimeView
          ? undefined
          : (e) => {
              // 89, because time range interactions are only registered in the lower half of the TopCell
              if (e.button !== 0) return; // || (e.clientY && e.clientY < 89)) return;

              const target = e.target as HTMLElement;

              if (target.tagName === 'BUTTON' || target.tagName === 'INPUT') {
                return;
              }
              actions.onTimeRangeCellMouseDown();
            }
      }
      onMouseMove={
        logMyTimeView
          ? undefined
          : (e) => {
              if (!scrollWrapperRef.current) return;
              if (!wrapperRef.current) return;

              const { clientX, clientY } = e;
              const { offsetLeft, offsetParent } = wrapperRef.current;

              if (!offsetParent) return;

              const cellY =
                clientY -
                scrollWrapperRef.current.offsetTop -
                (rowGroupTop - scrollWrapperRef.current.scrollTop);

              const rawY =
                // @ts-expect-error Getting this from rowGroup (see useWindow.js)
                Number(wrapperRef.current.offsetParent?.dataset.translateY) +
                cellY;

              actions.trackMouse(
                colIdx,
                rowIdx,
                clientX - offsetLeft + scrollWrapperRef.current.scrollLeft,
                clientY - (offsetParent as HTMLElement).offsetTop,
                cellY,
                offsetLeft,
                rawY,
                clientX,
                clientY,
              );
            }
      }
    >
      <TopCellInner
        dateRange={dateRange}
        dayWidth={dayWidth}
        totalHeight={totalHeight}
        dates={dates}
        colIdx={colIdx}
        cell={cell}
        suvPersonCell={suvPersonCell}
        actions={actions}
        disableTooltips={disableTooltips}
        logTimeView={logTimeView}
        logMyTimeView={logMyTimeView}
        wrapperRef={wrapperRef}
      />
    </div>
  );
}

const ReduxDataRestrictor = React.memo(TopCellComponent, areEqualExtractStyle);

export default ReduxDataRestrictor;
