import React from 'react';

import { calculatePrice } from '@float/common/lib/pricing';
import { Card } from '@float/ui/deprecated/Earhart/Cards';
import { withSnackbar } from '@float/ui/deprecated/Snackbar/withSnackbar';

import Body from '../Body';
import RestrictedMessage from '../RestrictedMessage';
import DownloadCell from '../Table/cell/DownloadCell';
import TagCell from '../Table/cell/TagCell';
import { BillingDetails } from './BillingDetails';
import { StyledInvoicesTable, StyledRow } from './styles';
import { TeamAccountFeatures as TeamAccountFeaturesNew } from './TeamAccountFeaturesNew';
import { getIsEnterprisePlan } from './TeamAccountFeaturesNew/helpers/getIsEnterprisePlan';
import { getIsProPlan } from './TeamAccountFeaturesNew/helpers/getIsProPlan';
import { getIsStarterPlan } from './TeamAccountFeaturesNew/helpers/getIsStarterPlan';

class Billing extends React.Component {
  componentDidMount() {
    if (this.props.location?.query?.trial_upgrade) {
      this.props.showSnackbar('Account updated.', { id: 'trialUpgrade' });
    }
  }

  componentWillUnmount() {
    const queryParams = new URLSearchParams(this.props.location?.search);
    if (queryParams.has('trial_upgrade')) {
      // Removing qs on navigating away so that 3rd party scripts
      // (like GA) get enough time to track page views on page load.
      queryParams.delete('trial_upgrade');
      this.props.history.replace({ search: queryParams.toString() });
    }
  }

  getSubHeaderMessage() {
    const { peopleCount, peopleLimit } = this.props;

    return `You are currently scheduling ${peopleCount} ${
      peopleCount == 1 ? 'person' : 'people'
    }. You can schedule up to ${peopleLimit} people on your current plan.`;
  }

  getTotalPayment() {
    const {
      peopleLimit,
      hasPlusPack,
      plusPack,
      hasTimeTracking,
      timeTracking,
      billingInfo,
      isPaidPlan,
      isProTrial,
      proTrialEnd,
    } = this.props;
    const {
      plan: { is_monthly: isMonthly, plan_id: planId },
    } = billingInfo;

    const hasBothAddons = hasTimeTracking && hasPlusPack;
    const isEnterprisePlan = getIsEnterprisePlan(planId, {
      hasBothAddons,
      isAnnualPlan: !isMonthly,
      isPaidPlan,
    });

    const isProPlan = getIsProPlan(planId, {
      isAnnualPlan: !isMonthly,
      isPaidPlan,
      isProTrial,
      plusPack,
      proTrialEnd,
      timeTracking,
    });

    // Note: Despite the new plans, prices and their calculations remain unchanged. Therefore, these calculations
    // still include references to legacy features such as time tracking and plus pack add-ons."
    return calculatePrice({
      peopleLimit,
      hasTimeTracking: isEnterprisePlan || isProPlan,
      hasPlusPack: isEnterprisePlan,
      isMonthly,
      plans: {}, // only calculates new plan prices
    });
  }

  render() {
    const {
      userCanSee,
      isPaidPlan,
      peopleLimit,
      peopleCount,
      placeholderLimit,
      placeholderCount,
      hasTimeTracking,
      hasPlusPack,
      timeTracking,
      plusPack,
      updateBillingPlan,
      updateCompanyPrefs,
      billingInfo,
      isProTrial,
      proTrialEnd,
    } = this.props;
    const {
      plan,
      trial_days: trialDays,
      trial_days_left: trialDaysRemaining,
      date_next_bill: dateNextBill,
      bill_cc_masked: billCCMasked,
    } = billingInfo;
    const { is_monthly: isMonthly, plan_id: planId } = plan;

    const pricing = this.getTotalPayment();

    const hasBothAddons = hasTimeTracking && hasPlusPack;
    const isEnterprisePlan = getIsEnterprisePlan(planId, {
      hasBothAddons,
      isAnnualPlan: !isMonthly,
      isPaidPlan,
    });
    const isProPlan = getIsProPlan(planId, {
      isAnnualPlan: !isMonthly,
      isPaidPlan,
      isProTrial,
      plusPack,
      proTrialEnd,
      timeTracking,
    });
    const isStarterPlan = getIsStarterPlan(planId, {
      isPaidPlan,
      isProTrial,
      plusPack,
      proTrialEnd,
      timeTracking,
    });

    return userCanSee.billing ? (
      <Body
        header="Plans &amp; billing"
        subheader="Review your billing details, plan type and view your history"
      >
        <BillingDetails
          isTrial={!isPaidPlan}
          isPlanBilledMonthly={isMonthly}
          maskedCardNumber={billCCMasked}
          nextChargeDate={dateNextBill}
          isEnterprisePlan={isEnterprisePlan}
          isProPlan={isProPlan}
          isStarterPlan={isStarterPlan}
          totalPayment={pricing.total}
          totalSeats={peopleLimit}
          usedSeats={peopleCount}
          placeholderSeats={placeholderLimit}
          placeholderUsedSeats={placeholderCount}
          trialDays={trialDays}
          trialDaysRemaining={trialDaysRemaining}
          updateBillingPlan={updateBillingPlan}
          updateCompanyPrefs={updateCompanyPrefs}
        />

        <TeamAccountFeaturesNew />

        {isPaidPlan && (
          <Card>
            <StyledRow>
              <h4>Billing history</h4>
            </StyledRow>

            <StyledRow>
              <StyledInvoicesTable
                recordNames={['invoice', 'invoices']}
                displayRowCount={false}
                headNames={[
                  'Number',
                  'Billed on',
                  'Due on',
                  'Status',
                  'Total',
                  'Download',
                ]}
                headNameKeys={[
                  'invoice_id',
                  'billed_on',
                  'due_on',
                  'state',
                  'total',
                  'download_link',
                ]}
                cellRenderers={[null, null, null, TagCell, null, DownloadCell]}
                rowActions={[]}
              />
            </StyledRow>
          </Card>
        )}
      </Body>
    ) : (
      <RestrictedMessage />
    );
  }
}

export default withSnackbar(Billing);
