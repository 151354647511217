import * as Comlink from 'comlink';

import { config } from '@float/libs/config';
import { featureFlags } from '@float/libs/featureFlags';
import { FeatureFlags } from '@float/libs/featureFlags/FeatureFlags.class';

import SearchWorker from './worker/SearchWorker.worker?worker';
import type { SearchWorker as SearchWorkerClass } from './worker/SearchWorker.class';

// @test-export
export async function addSearchWorkerFeatureFlagChangeListener(
  worker: SearchWorkerClass,
  featureFlags: FeatureFlags,
) {
  featureFlags.addChangeHandler(() =>
    worker.updateSerializedFeatureFlags(
      featureFlags.getSerializedFeatureFlags(),
    ),
  );
}

export async function spawnSearchWorker() {
  const worker = Comlink.wrap<SearchWorkerClass>(
    new SearchWorker(),
    // Using a typecast here to remove what Comlink.wrap adds to SearchWorkerInstanceApiLayer
    // and keep this type valid on all the platform (comlink is mocked on timer and mobile)
  ) as unknown as SearchWorkerClass;

  await worker.isReady();

  await worker.initializeEnvironment(
    config.serialize(),
    featureFlags.getSerializedFeatureFlags(),
    featureFlags.getOverrides(),
  );

  addSearchWorkerFeatureFlagChangeListener(worker, featureFlags);

  return worker;
}
