import type { PartialRecord } from '@float/types/generics';
import type { Person } from '@float/types/person';
import type { Phase } from '@float/types/phase';
import type { Project } from '@float/types/project';
import type { Role } from '@float/types/role';

export const getProjectHasPeopleWithMissingCostRates = (
  project: Project | undefined,
  phases: Phase[] | undefined,
  people: PartialRecord<Person['people_id'], Person>,
  roles: PartialRecord<Role['id'], Role>,
) => {
  if (!project) {
    return false;
  }

  const projectIdsPeople = project.people_ids ?? [];
  const projectIdsPhasesPeople =
    phases?.map((phase) => phase.people_ids).flat() ?? [];

  const projectIdsAllPeople = new Set([
    ...projectIdsPeople,
    ...projectIdsPhasesPeople,
  ]);

  const hasMissingCostRates = Array.from(projectIdsAllPeople).some(
    (personId) => {
      const person = people[personId];
      const personRoleId = person?.role_id;
      const personRole =
        typeof personRoleId === 'number' ? roles[personRoleId] : null;

      const hasPersonCostRate =
        typeof person?.cost_rate === 'string' && person.cost_rate.trim() !== '';

      const hasPersonRoleRate =
        typeof personRole?.cost_rate === 'string' &&
        personRole.cost_rate.trim() !== '';

      return !hasPersonCostRate && !hasPersonRoleRate;
    },
  );

  return hasMissingCostRates;
};
