import { endOfMonth, format, startOfMonth } from 'date-fns';

import { formatToFloatDate, parseFloatDate } from '@float/libs/dates';

export type buildMonthsParams = {
  dates: DatesManager;
  startDate: DateString;
  endDate: DateString;
  numDaysDisplayedInWeek: number;
  baseColOffset: number;
  colWidth: number;
  dayWidth: number;
};

export const buildMonths = ({
  dates,
  startDate,
  endDate,
  numDaysDisplayedInWeek,
  baseColOffset,
  colWidth,
  dayWidth,
}: buildMonthsParams) => {
  const months = [];

  const endDateDate = parseFloatDate(endDate);

  const operativeDate = parseFloatDate(startDate);

  // If start date is after the start of the month, skip it
  if (operativeDate.getDate() > 1) {
    // Move to the next month
    operativeDate.setMonth(operativeDate.getMonth() + 1);
    operativeDate.setDate(1);
  }

  while (operativeDate <= endDateDate) {
    const currentMonthStartDate = startOfMonth(operativeDate);
    const currentMonthEndDate = endOfMonth(operativeDate);

    const currentMonthStartDateString = formatToFloatDate(
      currentMonthStartDate,
    );
    const currentMonthEndDateString = formatToFloatDate(currentMonthEndDate);

    const currentMonthStartDescriptor = dates.toDescriptor(
      currentMonthStartDateString,
    );
    const currentMonthEndDescriptor = dates.toDescriptor(
      currentMonthEndDateString,
    );

    const currentMonthStartWeekDescriptor = currentMonthStartDescriptor[0];
    const currentMonthEndWeekDescriptor = currentMonthEndDescriptor[0];

    const currentMonthStartDayDescriptor = Math.min(
      numDaysDisplayedInWeek,
      currentMonthStartDescriptor[1],
    );
    const currentMonthEndDayDescriptor =
      Math.min(numDaysDisplayedInWeek, currentMonthEndDescriptor[1]) + 1;

    const currentMonthLeft =
      (currentMonthStartWeekDescriptor - baseColOffset) * colWidth +
      currentMonthStartDayDescriptor * dayWidth;

    const currentMonthRight =
      (currentMonthEndWeekDescriptor - baseColOffset) * colWidth +
      currentMonthEndDayDescriptor * dayWidth;

    const currentMonthWidth = currentMonthRight - currentMonthLeft;

    months.push({
      name: format(currentMonthStartDate, 'MMM'),
      startDate: currentMonthStartDate,
      endDate: currentMonthEndDate,
      left: currentMonthLeft,
      width: currentMonthWidth,
    });

    // Move to the next month
    operativeDate.setMonth(operativeDate.getMonth() + 1);
    operativeDate.setDate(1);
  }

  return months;
};
