import React from 'react';
import { Trans } from '@lingui/macro';
import * as HoverCard from '@radix-ui/react-hover-card';
import classNames from 'classnames';

import { CellItem } from '@float/types/cells';

import { ScheduleActions } from '../../../MainCell/types';
import { EntitiesGroupItem } from '../EntitiesGroupItem/EntitiesGroupItem';

import * as styles from './EntitiesGroup.css';

export type EntitiesGroupProps = {
  name: string;
  items: CellItem<'task' | 'timeoff'>[];
  overtime: number;
  actions: ScheduleActions;
};

export const EntitiesGroup = (props: EntitiesGroupProps) => {
  const { name, items, overtime, actions } = props;

  const hasItems = items.length > 0;
  const hasOvertime = overtime > 0;

  // No entities on this day
  if (!hasItems) return null;

  return (
    <HoverCard.Root>
      <HoverCard.Trigger asChild>
        <button
          className={styles.wrapper}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
        >
          <div
            className={classNames(
              styles.wrapperDot,
              hasOvertime && styles.wrapperDotWithOvertime,
            )}
          ></div>
        </button>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          className={styles.hoverCardWrapper}
          sideOffset={5}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
        >
          <header className={styles.hoverCardHeader}>{name}</header>

          <dl className={styles.entitiesList}>
            {items.map((item) => (
              <EntitiesGroupItem
                key={item.entityId}
                item={item}
                actions={actions}
              />
            ))}
          </dl>

          {hasOvertime && (
            <footer className={styles.hoverCardFooterOvertime}>
              <Trans>
                <span>Overschedule</span>
                <span>{overtime}h</span>
              </Trans>
            </footer>
          )}

          <HoverCard.Arrow className={styles.hoverCardArrow} />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
