import { keyBy, map } from 'lodash';
import { createSelector } from 'reselect';

import { DEFAULT_STATUS_COLOR } from '@float/constants/colors';

import { StatusesState } from '../reducers/statuses';
import { StatusTypesState } from '../reducers/statusTypes';

export const getStatusTypes = (state: { statusTypes: StatusTypesState }) =>
  state.statusTypes.statusTypes;

export const getStatuses = createSelector(
  [
    (state: { statuses: StatusesState }) => state.statuses.statuses,
    getStatusTypes,
  ],
  (statuses, statusTypes) => {
    const newStatuses = map(statuses, (status) => {
      const statusType = statusTypes[status.status_type_id] || {};
      return {
        ...status,
        status_type_name: statusType.status_type_name,
        color: statusType.color || DEFAULT_STATUS_COLOR,
      };
    });
    return keyBy(newStatuses, 'status_id');
  },
);
