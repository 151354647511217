import type { SearchState } from '@float/common/reducers/search';

/**
 * @deprecated This selector should only be used by the modules inside
 * "@float/common/search" and by useReportsFetchUrl (web app)
 *
 * The derived context will be removed when fully releasing Search beyond limits
 */
export const getSearchDerivedContext = (state: { search: SearchState }) =>
  state.search._derivedContext;
