import {
  SearchAutocompleteParams,
  SearchAutocompleteResultItem,
  SearchAutocompleteResults,
} from '@float/common/search/selectors/getSearchAutocompleteResults';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { getIsAnyModifierKeyActive } from '@float/libs/utils/events/getIsAnyModifierKeyActive';
import { FilterToken, SearchAutocompleteCategory } from '@float/types/view';

import { AddFilter } from '../components/SearchFilterDropdown/types';
import { getSubCategoryConfig } from '../components/SearchFilters';
import {
  AutocompleteResultsPaginationState,
  SearchState,
} from './Search.types';

export const getEmptyFilteredContext = (): SearchState['filteredContext'] => ({
  result: [],
  categoryIndices: {},
  categorySizes: {},
  input: '',
});

export function getSearchPlaceholder(
  expandedCategory: SearchAutocompleteCategory,
  count?: number,
) {
  if (count) {
    const subCategoryConfig = getSubCategoryConfig(expandedCategory);
    const label = subCategoryConfig.label;
    if (label) {
      if (count === 1) return `Search 1 ${label}`;
      const plural = subCategoryConfig.plural;
      if (plural) return `Search ${count} ${plural}`;
      return `Search ${count} ${label}s`;
    }
  }

  const { label, plural } = getSubCategoryConfig(expandedCategory);

  if (plural) {
    return `Search ${plural}`;
  }

  return `Search ${label}s`;
}

export function getSearchTotalResultsCount(
  categorySizes: Record<string, { shown: number; total: number }>,
  expandedCategory?: string,
) {
  if (expandedCategory) {
    const size = categorySizes?.[expandedCategory];
    return size?.shown;
  }

  return Object.values(categorySizes).reduce(
    (acc, size) => acc + size.shown,
    0,
  );
}

export function getKeydownHandler(
  isLoading: boolean,
  highlightedIndex: number | undefined,
  filteredContextResults: SearchAutocompleteResultItem[],
  setSearchState: React.Component['setState'],
  addFilter: AddFilter,
  closeDropdown: () => void,
) {
  return (event: React.KeyboardEvent) => {
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault(); // Otherwise tab changes tabIndex and we lose focus

      if (isLoading) return;

      if (typeof highlightedIndex === 'undefined') return;

      addFilter(filteredContextResults[highlightedIndex]);

      return;
    }

    if (event.key === 'Escape') {
      closeDropdown();
      return;
    }

    if (getIsAnyModifierKeyActive(event)) {
      return;
    }

    const maxIndex = filteredContextResults.length - 1;

    if (event.key === 'ArrowDown') {
      event.preventDefault();

      setSearchState(({ highlightedIndex: curIndex = -1 }: SearchState) => ({
        highlightedIndex: (curIndex + 1) % (maxIndex + 1),
      }));
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSearchState((ps: SearchState) => ({
        highlightedIndex: Math.max(
          0,
          (ps.highlightedIndex || maxIndex + 1) - 1,
        ),
      }));
    }
  };
}

export const fetchSearchAutocompleteResults = async (
  currentInput: string,
  expandedCategory: SearchAutocompleteCategory | undefined,
  operator: FilterToken['operator'] | undefined,
  lastAutocompleteInputRef: { current: string },
  lastAutocompleteCategoryRef: {
    current: SearchAutocompleteCategory | undefined;
  },
  autocompleteResultsPaginationStateRef: {
    current: AutocompleteResultsPaginationState | undefined;
  },
  updateState: React.Component['setState'],
  setIsLoading: (isLoading: boolean) => void,
  getSearchAutocompleteResults: (params: SearchAutocompleteParams) => Promise<{
    filteredContext: SearchAutocompleteResults;
    hasNextPage: () => boolean;
    fetchNextPage: () => Promise<SearchAutocompleteResults>;
  }>,
) => {
  if (
    featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits) &&
    !currentInput &&
    !expandedCategory
  ) {
    updateState({
      filteredContext: getEmptyFilteredContext(),
      expandedCategory: undefined,
    });
    return;
  }

  // We only truncate the results if search term is less than 3 characters
  // https://linear.app/float-com/issue/EXP-436
  const truncateResults = currentInput.length < 3;

  lastAutocompleteInputRef.current = currentInput;
  lastAutocompleteCategoryRef.current = expandedCategory;

  // Reset to ensure no next page is fetched from the previous search
  autocompleteResultsPaginationStateRef.current = undefined;

  setIsLoading(true);

  const { filteredContext, hasNextPage, fetchNextPage } =
    await getSearchAutocompleteResults({
      rawInput: currentInput,
      expandedCategory,
      excludeDraftStatus: false,
      myProjectsItem: false,
      subDepartments: true,
      truncateResults,
      isLogTimeView: window.location.pathname.startsWith('/log-time'),
      containsItem: !window.location.pathname.startsWith('/report'),
    });

  // Avoid concurrency issues
  if (
    lastAutocompleteCategoryRef.current !== expandedCategory ||
    lastAutocompleteInputRef.current !== currentInput
  ) {
    return;
  }

  autocompleteResultsPaginationStateRef.current = {
    hasNextPage,
    fetchNextPage,
    currentInput,
    expandedCategory,
  };

  const newState: Pick<SearchState, 'filteredContext' | 'expandedCategory'> = {
    filteredContext: {
      ...filteredContext,
      operator,
    },
    expandedCategory,
  };

  if (
    expandedCategory === 'savedSearches' &&
    !filteredContext.categorySizes?.savedSearches?.total
  ) {
    newState.expandedCategory = undefined;
  }

  updateState(newState);
  setIsLoading(false);
};
