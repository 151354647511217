import { createAction } from '@reduxjs/toolkit';
import { isEmpty, isEqual, isNil, uniqBy } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import type { SearchWorkerReduxState } from '@float/common/search/service/worker/searchStore';
import type { BaseFilterToken, FilterToken } from '@float/types';

import * as actions from '../../actions';
import { VIEW_APPLIED } from '../../actions/views/views';
import { getFiltersFromQueryString } from '../../search/helpers';
import { LoadState } from '../lib/types';
import { getDerivedContext } from './getDerivedContext';
import type { ViewAppliedAction } from '../../actions/views/views';
import type { RehydratePartialStateAction } from '../lib/types';

export type SearchState = {
  filters: BaseFilterToken[];
  removedFilters: BaseFilterToken[];
  /**
   * @deprecated This property should only be used by the functionalities that
   * are going to be moved under a service API after Search beyond limits.
   *
   * The derived context will be removed when fully releasing Search beyond limits
   */
  _derivedContext: ReturnType<typeof getDerivedContext>;
  placeholder: string;
  contextState: LoadState;
  contextLoaded: boolean;
  navProject: number | undefined;
};

const REDUCER_NAME = 'search';

/**
 * NOTE: This needs to be a function to as getFiltersFromQueryString depends on
 * FeatureFlags being initialized.
 */
export const getDefaultSearchState = (): SearchState => {
  const filters =
    typeof location !== 'undefined'
      ? getFiltersFromQueryString(location.search)
      : [];

  return {
    filters,
    removedFilters: [],
    _derivedContext: {
      accountManagedPeople: {},
      accurateNameByAccountId: {},
      clients: [],
      departments: [],
      jobTitles: [],
      managers: [],
      people: [],
      peopleTasks: {},
      peopleTaskStatuses: {},
      peopleWithProjects: new Set(),
      personTags: [],
      personTypes: [],
      phases: [],
      projectAllPeople: {},
      projectOwners: [],
      projectPhaseIds: {},
      projects: [],
      projectStatuses: [],
      projectTags: [],
      projectsWithTasks: new Set(),
      projectTaskIds: {},
      savedSearches: [],
      tasks: [],
      taskStatuses: [],
      timeoffs: [],
      timeoffStatuses: [],
    },
    placeholder: '',
    contextState: LoadState.UNLOADED,
    contextLoaded: false,
    navProject: undefined,
  };
};

const normalize = (filters: BaseFilterToken[]) => {
  return uniqBy(filters, 'key');
};

export const searchServiceReady = createAction('SEARCH_SERVICE_READY');

export type SearchContextSentToWorkerAction = ReturnType<
  typeof searchServiceReady
>;

export type SearchActions =
  | {
      type: typeof actions.UNMOUNT_SETTINGS_v2;
    }
  | {
      type: typeof actions.SEARCH_CONTEXT_LOAD_START;
    }
  | {
      type: typeof actions.SEARCH_CONTEXT_LOAD_FAILED;
    }
  | {
      type: typeof actions.SEARCH_DERIVE_CONTEXT;
      fullState: SearchWorkerReduxState;
    }
  | {
      type: typeof actions.SEARCH_ADD_FILTERS;
      filters: BaseFilterToken[];
      editingFilterIndex?: number;
    }
  | {
      type: typeof actions.SEARCH_SET_FILTERS;
      filters: BaseFilterToken[];
      removedFilters: BaseFilterToken[];
    }
  | {
      type: typeof actions.SEARCH_UPDATE_FILTER;
      key: string;
      value: string;
    }
  | ViewAppliedAction
  | {
      type: typeof actions.SEARCH_REMOVE_FILTER;
      filter: BaseFilterToken;
    }
  | {
      type: typeof actions.SEARCH_REMOVE_ALL_FILTERS;
    }
  | {
      type: typeof actions.SEARCH_CLEAR_REMOVED_FILTERS;
    }
  | {
      type: typeof actions.SEARCH_SET_PLACEHOLDER;
      placeholder: string;
    }
  | {
      type: typeof actions.SEARCH_CONTEXT_LOAD_FINISH;
    }
  | {
      type: typeof actions.SEARCH_SET_NAV_PROJECT;
      navProject: number;
    }
  | SearchContextSentToWorkerAction;

function pickFilterPropsForEqualityCheck(filter: FilterToken) {
  return {
    type: filter.type,
    val: Array.isArray(filter.val) ? filter.val : [filter.val],
    operator: filter.operator,
  };
}

function areFiltersEqual(a: FilterToken, b: FilterToken) {
  return isEqual(
    pickFilterPropsForEqualityCheck(a),
    pickFilterPropsForEqualityCheck(b),
  );
}

export const search = (
  state = getDefaultSearchState(),
  action:
    | SearchActions
    | RehydratePartialStateAction<SearchState, typeof REDUCER_NAME>,
): SearchState => {
  switch (action.type) {
    case actions.UNMOUNT_SETTINGS_v2: {
      // Settings v2 is self-contained until we merge it into the main reducers.
      // Therefore, we need to reload data when the user navigates away from
      // settings v2 in case they made changes there.
      return {
        ...state,
        contextState: LoadState.UNLOADED,
        contextLoaded: false,
      };
    }

    case actions.SEARCH_CONTEXT_LOAD_FAILED: {
      return {
        ...state,
        contextState: LoadState.LOAD_FAILED,
      };
    }

    case actions.SEARCH_DERIVE_CONTEXT: {
      return {
        ...state,
        _derivedContext: getDerivedContext(action),
      };
    }

    case actions.SEARCH_ADD_FILTERS: {
      if (isEmpty(action.filters)) {
        return state;
      }

      let filters: BaseFilterToken[];

      const { editingFilterIndex } = action;
      if (isNil(editingFilterIndex)) {
        filters = [...state.filters, ...action.filters];
      } else {
        filters = state.filters.map((filter, i) =>
          i === editingFilterIndex ? action.filters[0] : filter,
        );
      }

      filters = filters.map((f, i) => ({
        type: f.type,
        val: f.val,
        operator: f.operator,
        key: `${i}-${f.type}-${f.val}`,
      }));

      return {
        ...state,
        filters,
      };
    }

    case actions.SEARCH_SET_FILTERS: {
      return {
        ...state,
        filters: normalize(action.filters),
        removedFilters: action.removedFilters,
      };
    }

    case actions.SEARCH_UPDATE_FILTER: {
      return {
        ...state,
        filters: state.filters.map((f) =>
          f.type === action.key
            ? {
                ...f,
                val: action.value,
              }
            : f,
        ),
      };
    }

    case VIEW_APPLIED: {
      return {
        ...state,
        filters: [],
      };
    }

    case actions.SEARCH_REMOVE_FILTER: {
      return {
        ...state,
        filters: normalize(
          state.filters.filter((f) => !areFiltersEqual(f, action.filter)),
        ),
      };
    }

    case actions.SEARCH_REMOVE_ALL_FILTERS: {
      return {
        ...state,
        filters: [],
      };
    }

    case actions.SEARCH_CLEAR_REMOVED_FILTERS: {
      return {
        ...state,
        removedFilters: [],
      };
    }

    case actions.SEARCH_SET_PLACEHOLDER: {
      return {
        ...state,
        placeholder: action.placeholder,
      };
    }

    case searchServiceReady.type:
    case actions.SEARCH_CONTEXT_LOAD_FINISH: {
      if (state.contextState === LoadState.LOADED && state.contextLoaded)
        return state;

      return {
        ...state,
        contextState: LoadState.LOADED,
        contextLoaded: true,
      };
    }

    case actions.SEARCH_SET_NAV_PROJECT: {
      return {
        ...state,
        navProject: action.navProject,
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const contextState = payloadState.contextLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        contextState,
      };
    }

    default: {
      return state;
    }
  }
};
