import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { insightsDisabled } from '@float/common/actions';
import { getSideCellWidth } from '@float/common/serena/util/getSideCellWidth';
import { useAppDispatch } from '@float/common/store';
import { SIDE_CELL_WIDTH_SMALL } from '@float/constants/schedule';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { CellItem, DatesManager } from '@float/types';
import IconCloseSmall from '@float/ui/deprecated/Icons/icon-close';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';
import { IconChevronLeft } from '@float/ui/icons/essentials/IconChevronLeft';
import { IconChevronRight } from '@float/ui/icons/essentials/IconChevronRight';

import { ScheduleActions } from '../types';
import { PIN_LEFT_CLSS, PIN_RIGHT_CLSS } from './helpers';
import { usePinnedArrow } from './usePinnedArrow';

import * as styles from './TimeRangeItem.css';

type Props = {
  actions: ScheduleActions;
  dayWidth: number;
  totalHeight?: number;
  dates: DatesManager;
  item: CellItem<'timeRange'>;
  scrollWrapperRef: React.MutableRefObject<HTMLElement | undefined>;
  wrapperRef: React.RefObject<HTMLDivElement>;
  singleUserView?: boolean;
  isSingleProjectView?: boolean;
};

const TimeRangeItem = React.memo((props: Props) => {
  const { item, dayWidth, actions, dates, totalHeight } = props;
  const { x = 0, w, isStart, isEnd } = item;
  const boxRef = useRef<HTMLDivElement>(null);
  const reduxDispatch = useAppDispatch();

  const left = x * dayWidth + (isStart ? 0 : 1);
  const width = item.entity.visibleLength * dayWidth + 1;
  const sideColumnSize =
    window.innerWidth <= 1010
      ? SIDE_CELL_WIDTH_SMALL
      : getSideCellWidth(Boolean(props.isSingleProjectView));

  const pinned = usePinnedArrow({
    isStart: item.isStart,
    scrollWrapperRef: props.scrollWrapperRef,
    boxRef,
    sideColumnSize: props.singleUserView ? 0 : sideColumnSize,
  });

  const parent = props.wrapperRef.current?.parentElement;

  useLayoutEffect(() => {
    if (!parent) return;
    const pinTarget = parent.getElementsByClassName(
      PIN_LEFT_CLSS,
    )[0] as HTMLElement;
    pinTarget.style.pointerEvents = pinned.leftArrow ? 'all' : 'none';
  }, [pinned.leftArrow, parent]);

  useLayoutEffect(() => {
    if (!parent) return;
    const pinTarget = parent.getElementsByClassName(
      PIN_RIGHT_CLSS,
    )[0] as HTMLElement;
    pinTarget.style.pointerEvents = pinned.rightArrow ? 'all' : 'none';
  }, [pinned.rightArrow, parent]);

  const createStickyEl = (el: React.ReactNode, clss: string) => {
    const parent = props.wrapperRef.current!.parentElement as HTMLElement;
    const pinTarget = parent.getElementsByClassName(clss)[0];
    return createPortal(el, pinTarget);
  };

  const scrollTo = useCallback(
    (date: string) => {
      const [colIdx] = dates.toDescriptor(date);
      actions.scrollToCol(colIdx);
    },
    [dates, actions],
  );

  const scrollToRangeStart = useCallback(
    (e: React.MouseEvent) => {
      prevent(e);
      scrollTo(item.entity.start_date);
    },
    [item.entity.start_date, scrollTo],
  );

  const scrollToRangeEnd = useCallback(
    (e: React.MouseEvent) => {
      prevent(e);
      scrollTo(item.entity.end_date);
    },
    [item.entity.end_date, scrollTo],
  );

  const onClose = useCallback(
    (e: React.MouseEvent) => {
      prevent(e);
      actions.onTimeRangeRemove(item.entity);
      reduxDispatch(insightsDisabled());
    },
    [actions, item.entity, reduxDispatch],
  );

  const dragVersion = useMemo(
    () => (
      <div className={classNames(styles.box)} style={{ width }}>
        {(dayWidth !== 27 || w > 1) && (
          <>
            <div className={styles.leftBorder} />
            <div className={styles.rightBorder} />
          </>
        )}
      </div>
    ),
    [dayWidth, w, width],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (event.button !== 0 || event.shiftKey) return;
      if (!boxRef.current) return;

      const { x: offsetX, y: offsetY } = boxRef.current.getBoundingClientRect();

      actions.setDragItem({
        item,
        element: dragVersion,
        offsetX,
        offsetY,
        clientX: event.clientX,
        clientY: event.clientY,
      });
    },
    [actions, dragVersion, item],
  );

  const isEditable = useMemo(() => {
    return actions.isItemEditable(item) as boolean;
  }, [actions, item]);

  const handleRightResize = useCallback(
    (e: React.MouseEvent) => {
      if (e.button !== 0) return prevent(e);
      e.stopPropagation();
      actions.onItemResizeStart(item, 'R');
    },
    [actions, item],
  );

  const handleLeftResize = useCallback(
    (e: React.MouseEvent) => {
      if (e.button !== 0) return prevent(e);
      e.stopPropagation();
      actions.onItemResizeStart(item, 'L');
    },
    [actions, item],
  );

  if (!isStart) return null;

  const leftArrow =
    pinned.leftArrow &&
    createStickyEl(
      <div
        className={styles.arrow}
        data-direction="left"
        onClick={scrollToRangeStart}
      >
        <IconChevronLeft />
      </div>,
      PIN_LEFT_CLSS,
    );
  const rightArrow =
    pinned.rightArrow &&
    createStickyEl(
      <div
        className={styles.arrow}
        data-direction="right"
        onClick={scrollToRangeEnd}
      >
        <IconChevronRight />
      </div>,
      PIN_RIGHT_CLSS,
    );

  return (
    <TextTooltip
      className="hint clear"
      placement="right"
      distance={-32}
      delay={100}
      content={
        <div className={styles.closeArrow} onMouseDown={onClose}>
          <IconCloseSmall color="#fff" />
        </div>
      }
    >
      <div
        ref={boxRef}
        className={classNames(styles.box, `time-range-box`)}
        data-show-left-edge={!pinned.leftArrow}
        data-show-right-edge={!pinned.outsideViewport}
        style={{
          left,
          width,
        }}
        onMouseDown={handleMouseDown}
      >
        {!pinned.leftArrow && (
          <div className={styles.leftBorder} style={{ height: totalHeight }} />
        )}
        {!pinned.outsideViewport && (
          <div className={styles.rightBorder} style={{ height: totalHeight }} />
        )}
        {leftArrow}
        {rightArrow}
        {isEditable && isStart && (
          <div className={styles.leftResize} onMouseDown={handleLeftResize}>
            <div className={styles.resizeInner} />
          </div>
        )}
        {isEditable && isEnd && (
          <div className={styles.rightResize} onMouseDown={handleRightResize}>
            <div className={styles.resizeInner} />
          </div>
        )}
      </div>
    </TextTooltip>
  );
});

export default TimeRangeItem;
