import { getBudgetTypesToLabelMap } from '@float/constants/budgets';
import { BudgetPriority } from '@float/types/project';
import type { BudgetType } from '@float/types/project';

type GetBudgetTypeTextProps = {
  budget_type?: BudgetType;
  budget_priority?: BudgetPriority;
};

export function getBudgetTypeText({
  budget_type,
  budget_priority,
}: GetBudgetTypeTextProps) {
  if (budget_type === undefined) return undefined;

  let budget_priority_key = 0;

  if (budget_priority === BudgetPriority.Phase) budget_priority_key = 0.1;
  if (budget_priority === BudgetPriority.Task) budget_priority_key = 0.2;

  // TODO: Remove this coercion
  // https://linear.app/float-com/issue/PI-275/refactor-budgets-types-to-get-rid-of-numerical-enum
  const key = (budget_type + budget_priority_key) as keyof ReturnType<
    typeof getBudgetTypesToLabelMap
  >;

  return getBudgetTypesToLabelMap()[key];
}
