import { uniq } from 'es-toolkit';

import {
  SEARCH_RESOLVE_WILDCARD_VALUE,
  SearchResolveFilterOperator,
  SearchResolveFilterSpecialValue,
  SearchResolveMatchMode,
} from '@float/common/api3/search.constants';
import { SearchResolveField } from '@float/common/api3/search.types';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import type {
  SearchResolveFieldValues,
  SearchResolveFilter,
} from '@float/common/api3/search.types';
import type { CurrentUser } from '@float/types/account';
import type { BaseFilterToken } from '@float/types/view';

import {
  isNot,
  PERSON_RELATED_TYPES,
  PROJECT_RELATED_TYPES,
  TIMEOFF_RELATED_TYPES,
} from '../../helpers';
import {
  FILTER_CATEGORIES_THAT_SUPPORT_FILTERING_BY_NONE,
  FILTER_TYPE_KEY_TO_REMOTE_ENTITY_TYPE_KEY,
} from '../constants';
import { lookupFilter } from '../lookupFilter';
import { invertSearchResolveQueryOperator } from './invertSearchResolveQueryOperator';

function toArray(val: string | string[]) {
  return Array.isArray(val) ? val : [val];
}

// @test-export
export function getSkipFieldsValue(filter: BaseFilterToken) {
  if (!isNot(filter.operator)) return undefined;

  const skipTypes: SearchResolveFieldValues[] = [];

  // If we're not filtering by timeoff related types
  // we want to be sure to return timeoff values
  if (!TIMEOFF_RELATED_TYPES.includes(filter.type)) {
    skipTypes.push(SearchResolveField.TIME_OFF);
  }

  // If a multi-assign task/timeoff is on both person A and person B,
  // and we have a "NOT A" filter applied, we still want the task to
  // be included for person B. Therefore, we don't apply
  // person-related NOT filter logic to tasks or timeoffs.
  if (PERSON_RELATED_TYPES.includes(filter.type)) {
    skipTypes.push(SearchResolveField.TASK, SearchResolveField.TIME_OFF);
  }

  // If we're searching by an exclusion of project related keys, we don't
  // want to completely filter out people, because we do want to show
  // them on the schedule if present on other projects.
  if (
    !featureFlags.isFeatureEnabled(
      FeatureFlag.SearchBeyondLimitsDisableProjectSkipFieldsForPeopleFiltering,
    ) &&
    PROJECT_RELATED_TYPES.includes(filter.type)
  ) {
    skipTypes.push(SearchResolveField.PEOPLE);
  }

  // If we're searching by an exclusion of person related keys, we don't
  // want to completely filter out projects, because we do want to show
  // them on the schedule with other people if any.
  if (PERSON_RELATED_TYPES.includes(filter.type)) {
    skipTypes.push(SearchResolveField.PROJECT);
  }

  // If we're searching by an exclusion of taskStatus, we don't
  // want to completely filter out projects.
  // E.g. when is filtering by "taskStatus not Completed" we want just to
  // make all the non-completed tasks more evident.
  if (filter.type === 'taskStatus') {
    skipTypes.push(SearchResolveField.PROJECT);
  }

  if (skipTypes.length) {
    return uniq(skipTypes);
  }

  return undefined;
}

export async function convertFilterTokenToSearchResolveQuery(
  filter: BaseFilterToken,
  user: Pick<CurrentUser, 'people_id' | 'account_id'>,
): Promise<SearchResolveFilter[] | null> {
  const values = toArray(filter.val);

  if (filter.type === 'me') {
    if (user.people_id === null) return null;

    return [
      {
        field: 'people',
        operator: SearchResolveFilterOperator.Is,
        value: user.people_id,
      },
    ];
  }

  if (filter.type === 'contains') {
    return values.map((value) => ({
      field: null,
      operator: SearchResolveFilterOperator.Contains,
      value,
    }));
  }

  const field = FILTER_TYPE_KEY_TO_REMOTE_ENTITY_TYPE_KEY[filter.type];
  const operator: SearchResolveFilter['operator'] = isNot(filter.operator)
    ? SearchResolveFilterOperator.IsNot
    : SearchResolveFilterOperator.Is;

  const skipFields = getSkipFieldsValue(filter);
  const filters: SearchResolveFilter[] = [];

  // Handle 'None' filters
  if (
    values.includes(SearchResolveFilterSpecialValue.None) &&
    FILTER_CATEGORIES_THAT_SUPPORT_FILTERING_BY_NONE.includes(filter.type)
  ) {
    return [
      {
        field,
        operator: invertSearchResolveQueryOperator(operator),
        value: SEARCH_RESOLVE_WILDCARD_VALUE,
        matchMode: SearchResolveMatchMode.Wildcard,
      },
    ];
  }

  const ids = new Set<number>();

  if (
    filter.type === 'personType' &&
    operator === SearchResolveFilterOperator.Is &&
    !values.includes('Placeholder')
  ) {
    filters.push({
      field: FILTER_TYPE_KEY_TO_REMOTE_ENTITY_TYPE_KEY.personType,
      operator: SearchResolveFilterOperator.IsNot,
      value: await lookupFilter({ type: 'personType', val: 'Placeholder' }),
      skip: skipFields,
    });
  }

  for (const value of values) {
    if (filter.type === 'projectStatus' && value === 'Mine') {
      filters.push({
        field: 'projectOwner' as const,
        operator,
        value: [user.account_id],
        skip: skipFields,
      });
    } else {
      const filterToLookup = {
        type: filter.type,
        val: value,
      };

      for (const id of await lookupFilter(filterToLookup)) {
        ids.add(id);
      }
    }
  }

  if (ids.size > 0) {
    filters.push({
      field,
      operator,
      value: Array.from(ids),
      skip: skipFields,
    });
  }

  return filters;
}
