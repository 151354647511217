import { t } from '@lingui/macro';

export const getWeekDayNameFromDayNumber = (
  dayNumber: number,
  weekStartsOnMonday: boolean,
): string => {
  const WORK_DAYS = [
    { label: t`Monday` },
    { label: t`Tuesday` },
    { label: t`Wednesday` },
    { label: t`Thursday` },
    { label: t`Friday` },
    { label: t`Saturday` },
  ];

  if (weekStartsOnMonday) {
    WORK_DAYS.push({ label: t`Sunday` });
  } else {
    WORK_DAYS.unshift({ label: t`Sunday` });
  }

  return WORK_DAYS[dayNumber].label;
};
