import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { CurrentUser, Role, RoleOption, RolesState } from '@float/types';

import { getCanCurrentUserSeeBudgets } from '../lib/acl/getCanCurrentUserSeeBudgets';
import { isCostRateFeatureActiveForUser } from '../lib/rates/rates';
import { getUser } from './currentUser';

export const getRoles = (state: { roles: RolesState }): Record<string, Role> =>
  state.roles.roles;

export const getRolesOptions = createSelector(
  [getRoles, getUser],
  (roles: Record<number, Role>, user: CurrentUser): RoleOption[] => {
    const canViewCostRate = isCostRateFeatureActiveForUser(user);
    const canViewBillRate = getCanCurrentUserSeeBudgets(user);

    const results = Object.values(roles).map((role: Role) => {
      const result: RoleOption = {
        value: role.id,
        label: role.name,
      };

      const { cost_rate: costRate, default_hourly_rate: billRate } = role;

      if (canViewBillRate) {
        result.billRate = billRate ?? null;
      }

      if (canViewCostRate) {
        result.costRate = costRate ?? null;
      }

      return result;
    });

    return sortBy(results, (role) => role.label.toLowerCase());
  },
);
