import * as Intercom from '@intercom/messenger-js-sdk';

import { updateUserTrackingData as updateUserTrackingDataInAmplitude } from '@float/common/lib/amplitude';
import { config } from '@float/libs/config';
import { promiseWithResolvers } from '@float/libs/utils/promiseWithResolvers';
import { PageData } from '@float/types/pageData';

const intercomReady = promiseWithResolvers<void>();

// TODO: Consolidate these functions into a Analytics service
// https://linear.app/float-com/issue/PI-356/implement-a-configurable-analytics-service

export function trackEvent(eventName: string, metaData?: object) {
  intercomReady.promise.then(() => {
    Intercom.trackEvent(eventName, metaData);
  });
}

export function trackCompanyData(key: string, value: unknown, cid: number) {
  intercomReady.promise.then(() => {
    Intercom.update({
      companies: [{ company_id: cid, [key]: value }],
    });
  });
}

export function updateUserTrackingData(
  key: string,
  value: string | number | boolean,
) {
  updateUserTrackingDataInAmplitude(key, value);

  intercomReady.promise.then(() => {
    Intercom.update({ [key]: value });
  });
}

export function logout() {
  Intercom.shutdown();
}

/**
 * Triggers the Intercom checklist for users after successful signup.
 * This function is used as part of the light onboarding feature to
 * display a specific checklist only when the relevant feature flag is enabled.
 * The checklist ID used here is associated with a tailored onboarding flow
 * for Account Owners set up in Intercom.
 *
 * @see https://linear.app/float-com/issue/ACT-98
 */
export function startIntercomCheckList() {
  Intercom.startChecklist('43563853');
}

export const getIntercomAppId = () => {
  const appId = config.isProdlikeDeploymentEnvironment
    ? 'c74jyb7j'
    : 'zek44tcg';

  return appId;
};

export const initIntercom = (pageData: PageData, hash: string | undefined) => {
  const user = pageData.user.user;
  const cp = pageData.user.company_prefs;

  const settings: Parameters<typeof Intercom.Intercom>[0] = {
    api_base: 'https://api-iam.intercom.io',
    app_id: getIntercomAppId(),
    user_id: String(user.admin_id),
    user_hash: hash,
    email: user.email,
    name: user.name,
    created_at: user.created as unknown as number,
    plan: cp.plan_name,
    account_type: user.account_type_name,
    hide_default_launcher: true,
    companies: [
      {
        company_id: user.cid,
        name: cp.company_name,
        created_at: cp.company_epoch,
        plan: cp.plan_name,
        co_uuid: cp.uuid,
        monthly_spend: cp.monthly_spend,
        pay_status: cp.pay_status,
        people_limit: cp.p_limit,
        people_count: cp.people_count,
        plan_id: cp.plan_id,
        plan_type: cp.plan_type,
        time_tracking: cp.time_tracking,
        plus_pack: cp.plus_pack,
      },
    ],
  };

  Intercom.Intercom(settings);

  // We need to wait for intercom to send the first ping before we can track events
  // or do updates
  // Otherwise the messanger will break and won't show up when prompted
  //
  // Unfortunately there is no way to check if intercom is ready
  // so we have to rely on setTimeout
  setTimeout(() => {
    intercomReady.resolve();
  }, 2_000);
};

export default { logout, startIntercomCheckList };
