import React, { useRef } from 'react';

import {
  EditableFilterEntity,
  FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING,
} from '@float/constants/search';
import { useRect } from '@float/libs/web/hooks/useRect';
import { ScrollArea } from '@float/ui/primitives/ScrollArea';
import { useScrollAreaState } from 'components/SearchFilterDropdown/hooks/useScrollAreaState';

import { SearchFilterDropdownData as data } from '../../data';
import { filterSearchCategories } from '../../helpers/filterSearchCategories';
import {
  ActivateCategory,
  ExpandableSearchFilterDropdownCategoryItem,
} from '../../types';
import { CategoryItem } from './components/CategoryItem';
import { SeparatorItem } from './components/SeparatorItem';

import * as styles from './styles.css';

export type SearchFilterListProps = {
  currentCategoryType?: EditableFilterEntity | '';
  isMeFilterVisible: boolean;
  isPeopleFilterVisible: boolean;
  isSavedSearchesFilterVisible: boolean;
  onClick: ActivateCategory;
};

export type CategoryProps = Pick<
  SearchFilterListProps,
  'currentCategoryType' | 'onClick'
> & {
  category: ExpandableSearchFilterDropdownCategoryItem;
};

const Category = ({
  currentCategoryType,
  category,
  onClick,
}: CategoryProps) => {
  const isSelected = Boolean(
    currentCategoryType &&
      FILTER_TYPE_TO_SEARCH_CATEGORY_MAPPING[currentCategoryType] ===
        category.value,
  );

  return (
    <>
      <CategoryItem
        className={styles.item}
        category={category}
        onClick={onClick}
        selected={isSelected}
      />

      {category.showSeparatorAfter && (
        <SeparatorItem className={styles.separatorItem} />
      )}
    </>
  );
};

const SearchFilterList = ({
  isMeFilterVisible,
  isPeopleFilterVisible,
  isSavedSearchesFilterVisible,
  currentCategoryType,
  onClick,
}: SearchFilterListProps) => {
  const categories = filterSearchCategories(data, {
    me: isMeFilterVisible,
    people: isPeopleFilterVisible,
    savedSearches: isSavedSearchesFilterVisible,
  });

  const listRef = useRef<HTMLDivElement>(null);

  const rect = useRect(listRef);
  const scrollAreaState = useScrollAreaState(rect);

  return (
    <ScrollArea.Root
      className={styles.scrollAreaRoot}
      data-scrollbar-state={scrollAreaState.scrollbarState}
      style={{ height: scrollAreaState.height }}
      type="auto"
    >
      <ScrollArea.Content>
        <div ref={listRef} className={styles.list} role="list">
          {categories.map((value, index) => (
            <Category
              key={index}
              category={value}
              onClick={onClick}
              currentCategoryType={currentCategoryType}
            />
          ))}
        </div>
      </ScrollArea.Content>
    </ScrollArea.Root>
  );
};

export { SearchFilterList };
