import { isNil } from 'es-toolkit';

import { normalizeRateString } from '@float/common/lib/rates/rates';
import {
  CostRateFormData,
  CostRateHistoryPayload,
  CostRatePayload,
} from '@float/types/costRate';

export function getCostRateHistoryPayload(
  formData: CostRateFormData = {},
): CostRateHistoryPayload {
  const result: CostRateHistoryPayload = {};

  const { cost_rate_effective_date: effective_date } = formData;

  if (!isNil(effective_date)) {
    const rate = normalizeRateString(formData.cost_rate);
    result.add = [{ rate, effective_date }];
  }

  const isRemoving = Boolean(formData.cost_rate_removed_dates?.length);

  if (isRemoving) {
    result.del = formData.cost_rate_removed_dates;
  }

  return result;
}

export function getCostRatePayload(
  formData: CostRateFormData,
): CostRatePayload {
  const isAdding = Boolean(formData.cost_rate_effective_date);
  const isRemoving = Boolean(formData.cost_rate_removed_dates?.length);

  if (isAdding || isRemoving) {
    return {
      cost_rate_history: getCostRateHistoryPayload(formData),
    };
  }

  // BE also accepts a simple { cost_rate: '123' } field, and defaults the
  // effective date to 1970. However, currently there is no UI/X to allow
  // users to save cost rates in this way. To avoid the risk of accidentally
  // saving a cost rate with an incorrect effective date, we only include cost
  // rate in the payload if an effective date is provided.
  return {};
}
