import React from 'react';
import { Trans } from '@lingui/macro';

import { RateInput } from '@float/common/components/Rate';
import { useEditBillRate } from '@float/common/hooks/useEditBillRate';
import { getBillRateLabel } from '@float/common/lib/rates/rates';
import { Field } from '@float/ui/primitives/Field/Field';
import { FieldLabel } from '@float/ui/primitives/FieldLabel/FieldLabel';
import type { Role } from '@float/types/role';

import * as styles from './BulkEditBillRate.styles.css';

type BillRate = Role['default_hourly_rate'];

type Props = {
  value: BillRate;
  onChange: (args: BillRate) => void;
};

export function getBulkEditBillRateConfig() {
  return {
    label: getBillRateLabel(),
    render: BulkEditBillRate,
  };
}

export function BulkEditBillRate(props: Props) {
  const { value, onChange } = props;
  const { handleBillRateChange } = useEditBillRate(onChange);
  const billRateLabel = getBillRateLabel().toLowerCase();

  return (
    <div className={styles.wrapper}>
      <Field className={styles.fieldInput}>
        <FieldLabel>
          <Trans>New {billRateLabel}</Trans>
        </FieldLabel>
        <RateInput
          autoFocus
          required
          value={value}
          onChange={handleBillRateChange}
        />
      </Field>
    </div>
  );
}
