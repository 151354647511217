import React from 'react';
import { Trans } from '@lingui/macro';

import { formatAmount, toCents } from '@float/common/lib/budget';
import { getHasProjectBudgetAccess } from '@float/web/reports/helpers/getHasProjectBudgetAccess';

import { getIsMoneyBudgetType } from '../../../helpers/getIsMoneyBudgetType';
import {
  ItemHeader,
  ItemLabel,
  ItemSubLabel,
  ReportsTotalsCard,
  ReportsTotalsContainer,
  ReportsTotalsItem,
} from '../../../styles';
import type { SummaryBarProps } from '../types';

import * as summaryBarStyles from '../SummaryBar.css';

export function SummaryBarLoggedVsScheduled({
  project,
  projectBudget,
  totals,
  hasBudgetsAccess,
  hasCostsAccess,
}: SummaryBarProps) {
  const { budget_type, non_billable } = project;

  const isMoneyBudgetType = getIsMoneyBudgetType(budget_type);
  const hasProjectBudgetAccess = getHasProjectBudgetAccess(
    hasBudgetsAccess,
    budget_type,
  );

  const scheduled_hours = non_billable ? totals.nonbillable : totals.billable;
  const scheduled_budget_used = isMoneyBudgetType
    ? totals.feeCents / 100
    : scheduled_hours;

  const logged_hours = non_billable
    ? totals.logged.nonbillable
    : totals.logged.billable;
  const logged_budget_used = isMoneyBudgetType
    ? totals.logged.feeCents / 100
    : logged_hours;

  const difference_budget_used = isMoneyBudgetType
    ? (toCents(scheduled_budget_used) - toCents(logged_budget_used)) / 100
    : scheduled_budget_used - logged_budget_used;
  const difference_hours = scheduled_hours - logged_hours;

  const difference = {
    budget_used: difference_budget_used,
    hours: difference_hours,
    is_over: difference_hours < 0,
  };

  return (
    <ReportsTotalsContainer data-testid="summary-bar-logged-vs-scheduled">
      <ReportsTotalsCard borderRight flexGrow={1}>
        {isMoneyBudgetType && hasProjectBudgetAccess ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Logged</Trans>
            </ItemLabel>
            <ItemHeader>
              {formatAmount(budget_type, logged_budget_used)}
              <ItemSubLabel>{formatAmount(1, logged_hours)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Logged</Trans>
            </ItemLabel>
            <ItemHeader>{formatAmount(1, logged_hours)}</ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      <ReportsTotalsCard borderRight flexGrow={1}>
        {isMoneyBudgetType && hasProjectBudgetAccess ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Scheduled</Trans>
            </ItemLabel>
            <ItemHeader>
              {formatAmount(budget_type, scheduled_budget_used)}
              <ItemSubLabel>{formatAmount(1, scheduled_hours)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Scheduled</Trans>
            </ItemLabel>
            <ItemHeader>{formatAmount(1, scheduled_hours)}</ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>

      <ReportsTotalsCard flexGrow={1}>
        {isMoneyBudgetType && hasProjectBudgetAccess ? (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Difference</Trans>
            </ItemLabel>
            <ItemHeader>
              <span
                className={
                  difference.is_over ? summaryBarStyles.valueCritical : ''
                }
              >
                {formatAmount(budget_type, difference.budget_used)}
              </span>
              <ItemSubLabel>{formatAmount(1, difference.hours)}</ItemSubLabel>
            </ItemHeader>
          </ReportsTotalsItem>
        ) : (
          <ReportsTotalsItem width="200px">
            <ItemLabel>
              <Trans>Difference</Trans>
            </ItemLabel>
            <ItemHeader>
              <span
                className={
                  difference.is_over ? summaryBarStyles.valueCritical : ''
                }
              >
                {formatAmount(1, difference.hours)}
              </span>
            </ItemHeader>
          </ReportsTotalsItem>
        )}
      </ReportsTotalsCard>
    </ReportsTotalsContainer>
  );
}
