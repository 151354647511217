import { FAR_FUTURE_DATE, FAR_PAST_DATE } from '@float/constants/dates';
import { moment } from '@float/libs/moment';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

export const getLoggedTimeBoundary = ({
  timeTrackingEnabled,
  comparisonMode,
}: {
  timeTrackingEnabled: boolean;
  comparisonMode: ChartComparisonModes;
}) => {
  let domLoggedTimeBoundary = FAR_PAST_DATE;
  let csvLoggedTimeBoundary = FAR_PAST_DATE;

  if (timeTrackingEnabled) {
    domLoggedTimeBoundary = FAR_FUTURE_DATE;
    csvLoggedTimeBoundary = FAR_FUTURE_DATE;

    // If the comparison mode is combined (aka "Past logged + Future scheduled"),
    // we need to set the logged time boundary to the current date only for the DOM chart,
    // the CSV export will always use the entire date range.
    if (comparisonMode === ChartComparisonModes.COMBINED) {
      domLoggedTimeBoundary = moment().format('YYYY-MM-DD');
    }
  }

  return { domLoggedTimeBoundary, csvLoggedTimeBoundary };
};
