import { config } from '@float/libs/config';

import {
  GOOGLE_ANALYTICS_ID,
  GTM_CONTAINER_ID_DEVELOPMENT,
  GTM_CONTAINER_ID_STAGING_AND_PRODUCTION,
} from './tracking.constants';

// @test-export
export const getGooleTagManagerContainerId = (
  isProdlikeDeploymentEnvironment: boolean,
) => {
  return isProdlikeDeploymentEnvironment
    ? GTM_CONTAINER_ID_STAGING_AND_PRODUCTION
    : GTM_CONTAINER_ID_DEVELOPMENT;
};

const loadScript = (src: string) => {
  const script = document.createElement('script');
  script.async = true;
  script.src = src;
  document.head.appendChild(script);
};

// Install GoogleTagManager
// See: Get started with Tag Manager – 2. Install a web container
// https://support.google.com/tagmanager/answer/14847097?hl=en&ref_topic=15191151&sjid=7889930994185647503-NA
export const initGoogleTagManager = () => {
  if (config.isDevBuildMode) {
    return;
  }

  const isProdlikeDeploymentEnvironment =
    config.isProdlikeDeploymentEnvironment;
  const gooleTagManagerContainerId = getGooleTagManagerContainerId(
    isProdlikeDeploymentEnvironment,
  );

  // GTM container initialization
  pushValueToGTM({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  loadScript(
    `https://www.googletagmanager.com/gtm.js?id=${gooleTagManagerContainerId}`,
  );

  pushValueToGTM('js');
  pushValueToGTM(new Date());
  pushValueToGTM('config');
  pushValueToGTM(gooleTagManagerContainerId);

  // Google Analytics setup
  sendToGoogleAnalytics('config', GOOGLE_ANALYTICS_ID);
};

export function pushValueToGTM(value: unknown) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(value);
}

export function sendToGoogleAnalytics(...args: unknown[]): void {
  // Google analytics requires us to use the arguments object
  // eslint-disable-next-line prefer-rest-params
  pushValueToGTM(arguments);
}
