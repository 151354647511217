import React, { forwardRef } from 'react';
import type { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import type { ComponentPropsWithRef } from 'react';

import { SearchAutocompleteResultItem } from '@float/common/search/selectors/getSearchAutocompleteResults';
import { CategoryIndices } from '@float/common/search/selectors/getSearchAutocompleteResults/types';
import { SearchFilterItem } from 'components/SearchFilterDropdown/components/SearchFilterItem';
import {
  ActivateCategory,
  AddFilter,
  AddValue,
  DeleteSavedSearch,
  GetIsValueDisabled,
  RemoveValue,
  ReplaceValue,
} from 'components/SearchFilterDropdown/types';
import type { SearchAutocompleteCategory } from '@float/types';

import * as styles from './styles.css';

export type ResultsListProps = {
  addFilter: AddFilter;
  addValue: AddValue;
  categoryIndices: CategoryIndices;
  data: (SearchAutocompleteResultItem | undefined)[];
  deleteSavedSearch: DeleteSavedSearch;
  disableMultiSelect: boolean;
  activateCategory: ActivateCategory;
  expandedCategory: SearchAutocompleteCategory | undefined;
  highlightedIndex: number | undefined;
  measureElement: ReturnType<typeof useVirtualizer>['measureElement'];
  removeValue: RemoveValue;
  replaceValue: ReplaceValue;
  selectedValues: string[];
  setHighlightedIndex: (index: number) => void;
  virtualItems: VirtualItem[];
  isValueDisabled: GetIsValueDisabled;
} & Pick<ComponentPropsWithRef<'div'>, 'className'>;

export const ResultsList = forwardRef<HTMLUListElement, ResultsListProps>(
  (props, forwardedRef) => {
    const { virtualItems, isValueDisabled } = props;

    const scrollY = virtualItems[0]?.start ?? 0;

    return (
      <ul
        ref={forwardedRef}
        className={styles.scroller}
        style={{ transform: `translateY(${scrollY}px)` }}
      >
        {virtualItems.map((virtualItem) => (
          <SearchFilterItem
            key={virtualItem.key}
            {...props}
            isValueDisabled={isValueDisabled}
            virtualItem={virtualItem}
          />
        ))}
      </ul>
    );
  },
);
