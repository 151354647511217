type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

export function getErrorKey(error: unknown, key: string) {
  if (
    typeof error === 'object' &&
    error !== null &&
    key in error &&
    typeof (error as Record<string, unknown>)[key] === 'string'
  )
    return (error as Record<string, unknown>)[key];

  return '';
}

declare global {
  interface Window {
    reduxStoreDispatch: any;
  }
}

// TODO: Implement a React Native version of an unrecoverable failure modal.
// @see https://linear.app/float-com/issue/PLTAPP-154/implement-a-react-native-version-of-an-unrecoverable-failure-modal
export const handleFail = (
  action: string | null,
  message = `There was an error during ${action}, please refresh the page and try again.`,
  opts: { reloadLabel?: string; title?: string } = {},
) => {
  const { reloadLabel, title } = opts;

  window.reduxStoreDispatch({
    type: 'modalManager_SHOW',
    modalType: 'errorModal',
    modalSettings: {
      error: {
        title: title || 'Error',
        message,
      },
      props: {
        newModal: true,
        submitColor: 'green',
        submitLabel: reloadLabel || 'Reload',
        cancelColor: 'gray',
        cancelLabel: 'Close',
        textSize: 'big',
        onSubmit() {
          return window.location.reload();
        },
      },
    },
  });
};
