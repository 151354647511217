interface RouteState {
  route: string;
  scrollable: boolean;
  isSharedLink?: boolean;
}

export const getRouteState = (pathname: string): RouteState => {
  if (pathname === '/') {
    return { route: 'schedule', scrollable: false };
  } else if (pathname === '/log-time') {
    return { route: 'log-time', scrollable: false };
  } else if (pathname === '/people') {
    return { route: 'people', scrollable: false };
  } else if (pathname === '/projects') {
    return { route: 'projects', scrollable: false };
  } else if (pathname.startsWith('/report')) {
    return { route: 'reports', scrollable: true };
  } else if (pathname.startsWith('/share')) {
    return { route: 'schedule', scrollable: false, isSharedLink: true };
  }
  return { route: '', scrollable: false };
};
