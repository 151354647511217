import { t } from '@lingui/macro';
import { isNil } from 'es-toolkit';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { CurrentUser } from '@float/types/account';

import { getCanCurrentUserSeeCostRates } from '../acl/getCanCurrentUserSeeCostRates';
import { isAmountEmpty } from '../budget';
import { formatAmount } from '../budget/helpers/formatAmount';

export type CurrencyConfig = {
  prefix?: string;
  suffix?: string;
};

export const formatRate = (value: string | null | undefined) => {
  return formatAmount(undefined, value, {});
};

export const formatRateWithCurrencySymbol = (
  value: string | null | undefined,
  currencyConfig: CurrencyConfig,
  options: {
    useSpacing: boolean;
  } = { useSpacing: true },
) => {
  if (!value) return value;

  const valueFormatted = formatRate(value);
  const spacing = options.useSpacing ? ' ' : '';

  if (!currencyConfig.prefix && !currencyConfig.suffix) {
    return valueFormatted;
  } else if (currencyConfig.prefix) {
    return `${currencyConfig.prefix}${spacing}${valueFormatted}`;
  } else if (currencyConfig.suffix) {
    return `${valueFormatted}${spacing}${currencyConfig.suffix}`;
  }
};

export function isCostRateFeatureFlagEnabled() {
  return featureFlags.isFeatureEnabled(FeatureFlag.CostRate);
}

// Function created for extensibility. For example - if feature flag is enabled
// but user is on starter plan, maybe we still want to use more general-purpose
// labels such as "Hourly rate" instead of "Bill rate".
export function isCostRateFeatureActive() {
  return isCostRateFeatureFlagEnabled();
}

export function isCostRateFeatureActiveForUser(currentUser: CurrentUser) {
  return (
    isCostRateFeatureActive() && getCanCurrentUserSeeCostRates(currentUser)
  );
}

export function getBillRateLabel(defaultLabel = t`Hourly rate`) {
  if (isCostRateFeatureActive()) {
    return t`Bill rate`;
  }

  return defaultLabel;
}

export function getRateSuffix() {
  if (isCostRateFeatureActive()) {
    return t`/hr`;
  }

  return undefined;
}

export function areRatesEqual(rate1?: string | null, rate2?: string | null) {
  const normalizedRate1 = normalizeRateString(rate1);
  const normalizedRate2 = normalizeRateString(rate2);

  // If both are null after normalization, they are equal.
  if (normalizedRate1 === null && normalizedRate2 === null) return true;

  // If only one is null after normalization, they are not equal.
  // For example, this would apply when comparing an empty string with '0'.
  if (normalizedRate1 === null || normalizedRate2 === null) return false;

  return formatRate(normalizedRate1) === formatRate(normalizedRate2);
}

export function normalizeRateString(rate?: string | null): string | null {
  if (isNil(rate)) return null;

  const rateString = String(rate).trim();
  if (isAmountEmpty(rateString)) return null;

  return rateString;
}
