import { forEach, get, mapValues, omit } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { Department, Person, Role } from '@float/types';

import * as actions from '../actions';
import { sanitizeFetchedPerson } from '../actions/people/helpers/sanitizeFetchedPerson';
import { TAG_TYPE } from '../selectors/tags';
import { handleRemoveTag, handleUpdateTag } from './lib/applyTagUpdate';
import { LoadState, RehydratePartialStateAction } from './lib/types';

export type PeopleState = {
  people: Record<number, Person>;
  loadState: LoadState;
  peopleLoaded: boolean;
};

const REDUCER_NAME = 'people';
export const DEFAULT_STATE: PeopleState = {
  people: {},
  loadState: LoadState.UNLOADED,
  peopleLoaded: false,
};

export type PeopleAction =
  | {
      type: typeof actions.UNMOUNT_SETTINGS_v2;
    }
  | {
      type: typeof actions.PEOPLE_LOAD_START;
    }
  | {
      type: typeof actions.PEOPLE_LOAD_FINISH;
      people: Record<number, Person>;
    }
  | {
      type: typeof actions.PEOPLE_LOAD_FAILED;
    }
  | {
      type: typeof actions.PEOPLE_UPDATED;
      person: {
        people_id: number;
      } & Partial<Person>;
    }
  | {
      type: typeof actions.PEOPLE_BULK_UPDATED;
      people: Person[];
      fields: Partial<Person>;
    }
  | {
      type: typeof actions.PEOPLE_IMPORTED;
      result: Person[];
    }
  | {
      type: typeof actions.PEOPLE_DELETED;
      person: Person;
    }
  | {
      type: typeof actions.PEOPLE_BULK_DELETED;
      ids: string[];
    }
  | {
      type: typeof actions.DELETE_DEPARTMENT_SUCCESS;
      id?: string;
      payload?: Department;
    }
  | {
      type: typeof actions.SETTINGS_UPDATE_AUTO_EMAIL;
      auto_email: number;
    }
  | {
      type: typeof actions.PERSON_UPDATE_AUTO_EMAIL;
      personId: number;
      auto_email?: number;
    }
  | {
      type: typeof actions.TAGS_UPDATED;
    }
  | {
      type: typeof actions.TAGS_DELETED;
    }
  | {
      type: typeof actions.UPDATE_ROLE_SUCCESS;
      payload: Role;
    };

export const people = (
  state = DEFAULT_STATE,
  action:
    | PeopleAction
    | RehydratePartialStateAction<PeopleState, typeof REDUCER_NAME>,
): PeopleState => {
  switch (action.type) {
    case actions.UNMOUNT_SETTINGS_v2: {
      // Settings v2 is self-contained until we merge it into the main reducers.
      // Therefore, we need to reload data when the user navigates away from
      // settings v2 in case they made changes there.
      return {
        ...state,
        loadState: LoadState.UNLOADED,
        peopleLoaded: false,
      };
    }

    case actions.PEOPLE_LOAD_START: {
      return {
        ...state,
        loadState: LoadState.LOADING,
      };
    }

    case actions.PEOPLE_LOAD_FINISH: {
      return {
        ...state,
        loadState: LoadState.LOADED,
        peopleLoaded: true,
        people: action.people,
      };
    }

    case actions.PEOPLE_LOAD_FAILED: {
      return {
        ...state,
        loadState: LoadState.LOAD_FAILED,
        people: {},
      };
    }

    case actions.PEOPLE_UPDATED: {
      const { people_id } = action.person;

      const newState = {
        ...state,
        people: {
          ...state.people,
          [people_id]: {
            ...state.people[people_id],
            ...action.person,
          },
        },
      };
      return newState;
    }

    case actions.PEOPLE_BULK_UPDATED: {
      const { people, fields } = action;
      const newPeople = { ...state.people };
      forEach(people, (person) => {
        const id = person.people_id;

        if (newPeople[id]) {
          const newPerson = { ...newPeople[id] };

          forEach(fields, (value, field) => {
            const skip = ['access'].includes(field);
            if (skip) return;

            if (field === 'tags') {
              const { add, del } = value as {
                add?: string[];
                del?: string[];
              };

              if (del && del.length) {
                newPerson[field] = newPerson[field].filter(
                  (x) => !del.includes(x.name),
                );
              }

              if (add && add.length) {
                const existingTags = newPerson[field].map((x) => x.name);
                add.forEach((t) => {
                  if (!existingTags.includes(t)) {
                    // label does not exist on type definition and is probably excessive, but keeping it
                    // for not to accidentaly introduce breaking changes
                    // @ts-expect-error
                    newPerson[field].push({ label: t, name: t, type: 1 });
                  }
                });
              }
            } else {
              // @ts-expect-error
              newPerson[field] = value;
            }
          });

          newPeople[id] = newPerson;
        }
      });
      return {
        ...state,
        people: newPeople,
      };
    }

    case actions.PEOPLE_IMPORTED: {
      const { result } = action;
      if (!result || !result.length) {
        return state;
      }
      const newPeople = { ...state.people };
      result.forEach((p) => {
        newPeople[p.people_id] = sanitizeFetchedPerson(p);
      });
      return {
        ...state,
        people: newPeople,
      };
    }

    case actions.PEOPLE_DELETED: {
      const id = get(action, 'person.people_id');
      if (!id) return state;

      return {
        ...state,
        people: omit(state.people, id),
      };
    }

    case actions.PEOPLE_BULK_DELETED: {
      const { ids } = action;
      if (!ids || !ids.length) return state;

      return {
        ...state,
        people: omit(
          state.people,
          ids.map((id) => parseInt(id)),
        ),
      };
    }

    case actions.DELETE_DEPARTMENT_SUCCESS: {
      const departmentId = action.id || get(action, 'payload.department_id');

      return {
        ...state,
        people: mapValues(state.people, (person) => {
          if (person.department_id === departmentId) {
            return {
              ...person,
              department_id: undefined,
            } as Person;
          }

          return person;
        }),
      };
    }

    case actions.SETTINGS_UPDATE_AUTO_EMAIL: {
      return {
        ...state,
        people: mapValues(state.people, (person) => {
          const personHasNotUnsubscribed = person.auto_email > -2;
          if (personHasNotUnsubscribed) {
            return {
              ...person,
              auto_email: action.auto_email,
            };
          }
          return person;
        }),
      };
    }

    case actions.PERSON_UPDATE_AUTO_EMAIL: {
      const { personId } = action;
      const person = personId && state.people[personId];
      if (!person) {
        return state;
      }

      if (typeof action.auto_email !== 'undefined') {
        return {
          ...state,
          people: {
            ...state.people,
            [personId]: {
              ...person,
              auto_email: action.auto_email,
            },
          },
        };
      }

      return state;
    }

    case actions.TAGS_UPDATED:
      return handleUpdateTag(state, action, {
        key: 'people',
        id: 'people_id',
        type: TAG_TYPE.PEOPLE,
      });

    case actions.TAGS_DELETED:
      return handleRemoveTag(state, action, {
        key: 'people',
        id: 'people_id',
        type: TAG_TYPE.PEOPLE,
      });

    case actions.UPDATE_ROLE_SUCCESS: {
      const {
        id: roleId,
        name: roleName,
        cost_rate: roleDefaultCostRate,
        default_hourly_rate: roleDefaultHourlyRate,
      } = action.payload;

      return {
        ...state,
        people: mapValues(state.people, (person) => {
          if (person.role_id !== roleId) return person;

          return {
            ...person,
            job_title: roleName,
            role: {
              role_id: roleId,
              name: roleName,
              cost_rate: roleDefaultCostRate,
              default_hourly_rate: roleDefaultHourlyRate,
            },
          };
        }),
      };
    }

    case REHYDRATE: {
      const payloadState = action.payload?.[REDUCER_NAME];
      if (!payloadState) {
        return state;
      }

      // Ensure that the rehydrated load states are either loaded or unloaded
      // to prevent the app from starting in a loading state.
      const loadState = payloadState.peopleLoaded
        ? LoadState.LOADED
        : LoadState.UNLOADED;

      return {
        ...state,
        ...payloadState,
        loadState,
      };
    }

    default: {
      return state;
    }
  }
};

export default people;
