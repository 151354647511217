import { useCallback, useState } from 'react';

import { useListenForWindowResize } from '@float/libs/media';

export const DROPDOWN_BOTTOM_GAP = 32;

export const useScrollAreaState = (
  scrollAreaRect: Partial<DOMRect> | undefined,
) => {
  const [currentHeight, setCurrentHeight] = useState<number>(0);

  const { top, height } = scrollAreaRect || {};

  const needsScrollbar = height && height > currentHeight;
  const scrollbarState = needsScrollbar ? 'visible' : 'hidden';

  const update = useCallback(() => {
    const { innerHeight } = window;
    const maxHeight = innerHeight - top! - DROPDOWN_BOTTOM_GAP;
    const nextHeight = height! > maxHeight ? maxHeight : height;

    setCurrentHeight(nextHeight ?? 0);
  }, [height, top]);

  useListenForWindowResize(update, { runOnMount: true });

  return {
    height: currentHeight,
    update,
    scrollbarState,
  };
};
